import React, { useEffect, useState } from 'react';
import { compose } from 'recompose'
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Row, Col, Grid } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from "react-toastify"

import * as FeatherIcon from 'react-feather';


//import AddressAutocomplete from '../LoadSearchBar/AddressAutocompleteV2LoadSearch';
import AddressAutocomplete from '../../LoadSearchBar/AddressAutocompleteLocationAdd';
import TruckTypeSelectWerner from './WernerTruckTypeSelect';
import DaySelectWerner from './WernerDaySelect';
import ZoneSelect from './ZoneSelect';
import MultiStateSelect from './MultiStateSelect'
import { KeyboardDatePicker } from "@material-ui/pickers";
import LoadingOverlay from '../../LoadingOverlay'
import isMobile from '../../../../HOC/Mobile.js'
import FA from 'react-fontawesome'

import { WernerServices } from '../../../customers/a98db973/services';
import {useAuthenticationHook} from '../../../hooks/authentication-hook';
import { checkValueExists } from '../../../../lib/check-value-exists';
import {INPUT_BACKGOUND_COLOR,INPUT_BORDER_COLOR , LIGHT_GREY, WHITE} from '../../../../CarrierPortal/palette'

const AddPreferenceModal = (props) => {
    const LANE_SELECT_BOX_HEIGHT_DESKTOP = 120
    const LANE_SELECT_BOX_HEIGHT_MOBILE = 120
    
    const { muiTheme, selectedLocationId, renderedTab, onClose, editItem = null, preferenceType = null, updatePreferencesData ,  isMobile = false,lanePrefType=null} = props

    const useStyles = makeStyles((theme) => ({
        closeIconButton: {
            position: 'relative',
            bottom: '15px',
            left: '30px',
            marginBottom: '-20px',
            float: 'right',
            '& .MuiSvgIcon-root':{
                fontSize: '2.5rem'
            }     
        },
        paper: {
            position: 'absolute',
            width: isMobile ? 'auto' : 1050,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        paperLaneSelect: {
            position: 'absolute',
            width: isMobile ? 'auto' : 680,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        paperLanePref: {
            position: 'absolute',
            width: isMobile ? 'auto' : 800,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        dayButton: {
            color: "black"
        },
        textField: {
            border: '0.5px solid '+INPUT_BORDER_COLOR,
            backgroundColor: INPUT_BACKGOUND_COLOR,
            width: isMobile ? '100%' : '100%',
            //backgroundColor: 'white',
            marginTop:0,
            paddingLeft: '10px',
            paddingRight: '10px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize:'1rem',
            borderTop:'0px',
            borderLeft:'0px',
            borderRight:'0px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            '& p': {
                fontSize: '14px !important'
            },
            '& label': {
                top: '10px !important',
                left: '10px !important'
            }
        },
        dateInputField: {
            zIndex: 2,
            border: '0.5px solid '+INPUT_BORDER_COLOR,
            height: '99%',
            width: isMobile ? '100%' : '100%',
            margin: 0,
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize:'1rem',
            borderTop:'0px',
            borderLeft:'0px',
            borderRight:'0px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            '& p': {
                fontSize: '14px !important'
            },
            '& label': {
                top: '5px !important',
            },
        },
        label: {
            fontSize: 16,
        },
        labelMobile: {
            fontSize: 12,
            paddingRight: 6
        },
        calendarIcon: {
            '& svg': {
                height: '2em',
                width: '2em',
                position: 'absolute',
                top: -12
            },
        },
        selectBox:{
            padding:isMobile ? theme.spacing(0, 0, 0) : theme.spacing(0, 2, 0),
        },
        laneTypeSelectBox: {
            backgroundColor: theme.palette.background.paper,
            border: '0px solid #000',
            boxShadow: theme.shadows[2,3,3],
            padding: theme.spacing(1.5),
            margin: isMobile ? theme.spacing(1, 0, 1) : theme.spacing(0, 0, 0),
            borderRadius:5,
            cursor:'pointer',
            height:isMobile ? 120:240,
            width:'100%',
            "&:hover":{
                backgroundColor : LIGHT_GREY
            }
        },
        activeLaneSelectCard:{
            backgroundColor : LIGHT_GREY
        },
        mainText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            lineHeight: '18px',
            color: 'black',
            margin: 0
        },
        secondaryText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            lineHeight: '21px',
            color: 'grey'
        },
        mobileValueText: {
            marginRight: 10,
            display: 'inline-block',
            float: 'right',
            color: 'black'
        },
        mobileLabelText: {
            fontWeight: 'bold',
            display: 'inline-block',
            color: 'black'
        },
        alignedRowText:{
            justifyContent: isMobile?'flex-start':'center',
            display: 'flex'
        },
        laneTypeSelectTextBox:{
            marginTop:-20
        },
        laneTypeSelectImageBox:{
            height:120,
            display:'flex',
            alignItems:isMobile ? 'unset' : 'center',
            justifyContent:isMobile?'flex-end':'center'
        },
        laneImage:{
            width: isMobile?115: 140,
            marginTop:isMobile?0:20
        },
        capitalizedText:{
            textTransform: 'capitalize',
        },
        buttonWrapper:{
            width:'100%',
            display:isMobile?'flex':'block',
            justifyContent:'space-between',
            alignItems:'center'
        },
        MuiCalendarPopover : {
            '& .MuiPaper-root':{
                '& .MuiTypography-body1':{
                fontSize:16
                },
                '& .MuiTypography-body2':{
                fontSize:16
                },
                '& .MuiPickersCalendarHeader-dayLabel':{
                fontSize:16
                },
                '& .MuiSvgIcon-root':{
                width: 30,
                height: 30
                }
            }
        },
    }));

    function getModalStyle() {

        return {
            top: isMobile ? '50%' : `40%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            height: isMobile ? '100%' : 'unset',
            width: isMobile ? '100%' : '910',
            overflow: isMobile ? 'auto' : 'unset'
        };
    }

    const [modalStyle] = useState(getModalStyle);

    const classes = useStyles()

    const isEditing = editItem !== null ? true : false

    const [rateType, setRateType] = useState('FLAT');
    const [destType, setDestType] = useState('SpecificLocation');

    const [originLocation, setOriginLocation] = useState(null);
    const [destinationLocation, setDestinationLocation] = useState(null);
    const [destinationZone, setDestinationZone] = useState(null);
    const [rate, setRate] = useState("");
    const [truckType, setTruckType] = useState(null);
    const [date, setDate] = useState(moment())
    const [openDateSelector, setOpenDateSelector] = useState(false);

    const [availableDays, setAvailableDays] = useState([]);

    const [origins, setOrigins] = useState([])
    const [destinations, setDestinations] = useState([])
    const [isApiCalling, setIsApiCalling] = useState(false);
    const [laneType, setLaneType] = useState(null);
    const [laneTypePreSelect, setLaneTypePreSelect] = useState(null);

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    useEffect(()=> {
        if(!isMobile){
            require('./date-picker.css')
        }
    },[])

    const handleDateChange = (date) => {
        setDate(date);
    };

    const handleDateChangeEvent = (event) => {
        let dateMoment;
        if(event.target.value === '' || event.target.value.length === 0){
            // toast.error("Clearing disabled, input required.")
            dateMoment = typeof(date) === 'string' ? date : date.format('YYYY-MM-DD')
        }else{
            dateMoment = moment(event.target.value, "YYYY-MM-DD");
        }  
        setDate(dateMoment);
    };

    const handleRateChange = (value) => {
        if(value.split('.').length > 2 ){
            return
        }
        setRate(value)   
    }

    const handleRateKey = (value, keyCode) => {

        let validEntries = '0123456789.';
        const backSpaceKeyCode = 8
        const deleteKeyCode = 46
        const leftArrowKeyCode = 37
        const rightArrowKeyCode = 39
        if (validEntries.includes(value) || 
            keyCode == backSpaceKeyCode || 
            keyCode == deleteKeyCode    ||
            keyCode == leftArrowKeyCode ||
            keyCode == rightArrowKeyCode
        ){ 
            return false// return false will result in update
        }else{
            return true
        }
        
    }

    const handleSelectAvailableDay = (weekdays) => {
        setAvailableDays(weekdays);
    };

    const handleRateTypeSelection = (event) => {
        setRateType(event.target.value);
    };

    const handleDestTypeSelection = (event) => {
        setDestType(event.target.value);
    };

    const handleSetPickupLocation = (value) => {
        setOriginLocation(value);
    };

    const handleSetDeliveryLocation = (value) => {
        setDestinationLocation(value);
    };

    const handleSetTruckType = (value) => {
        setTruckType(value);
    };

    const handleClose = () => {
        onClose()
    };

    const addAvailableCapacity = () => {
        // add validation
        if (originLocation === null || !checkValueExists(originLocation, "city")) {
            toast.error("Origin location is required. Please enter a city & state.")
            return false
        } else if (destType === "SpecificLocation" &&
            (destinationLocation === null || !checkValueExists(destinationLocation, "city"))) {
            toast.error("Destination location is required. Please enter a city & state.")
            return false
        } else if (destType === "SpecificZone" && (destinationZone === null)) {
            toast.error("Destination is required. Please select a zone from the dropdown.")
            return false
        } else if (truckType === null) {
            toast.error("Equipment type is required. Please select from the drop down.")
            return false
        // } else if (rate === "") {
        //     toast.error("Please enter a rate.")
        //     return false
        // } 
        // else if (rate !== "" && isNaN(rate)) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        // } else if (rate !== "" && Number(rate) <=0) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        } else if (date === null) {
            toast.error("Please select a an available date.")
            return false
        }

        let destObj = null
        if (destType === "Anywhere") {
            destObj = { state: "ANY", country: "USA" }
        } else if (destType === "SpecificLocation") {
            destObj = {
                city: destinationLocation.city,
                state: destinationLocation.state,
                country: destinationLocation.country
            }
        } else if (destType === "SpecificZone") {
            destObj = {
                zone: destinationZone.abbr
            }
        }

        let params = {
            ecpCarrierIdent: user.carrier.externalId,
            dateAvailable: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
            origin: {
                city: originLocation.city,
                state: originLocation.state,
                country: originLocation.country
            },
            destinationType: destType, //SpecificLocation, Anywhere, SpecificZone
            destination: destObj,
            equipment: truckType,
            source: "TT", // todo maybe change to CE
            user: user.username,
            rate: rate === "" ? 0 : Number(rate),
            addressId: selectedLocationId
        }

        

        // console.log("PARAMS: ", params)
        setIsApiCalling(true)
        const apiServices = new WernerServices()
        apiServices.addAvailableCapacity(params)
            .then(response => {
                setIsApiCalling(false)
                // console.log("RESPONSE: ", response)
                if (!checkValueExists(response, "message")) {
                    toast.success("Capacity added successfully. The page has been refreshed.")
                    updatePreferencesData()
                    onClose()
                } else {
                    toast.error("There was an issue with your request, please try again.")
                }
            })
            .catch(error => {
                setIsApiCalling(false)
                toast.error("There was an issue with your request, please try again.")
                onClose()
            })
    }

    const editAvailableCapacity = () => {
        // add validation
        if (originLocation === null || !checkValueExists(originLocation, "city")) {
            toast.error("Origin location is required. Please enter a city & state.")
            return false
        } else if (destType === "SpecificLocation" &&
            (destinationLocation === null || !checkValueExists(destinationLocation, "city"))) {
            toast.error("Destination location is required. Please enter a city & state.")
            return false
        } else if (destType === "SpecificZone" && (destinationZone === null)) {
            toast.error("Destination is required. Please select a zone from the dropdown.")
            return false
        } else if (truckType === null) {
            toast.error("Equipment type is required. Please select from the drop down.")
            return false
        // } else if (rate !== "" && isNaN(rate)) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        // } else if (rate !== "" && Number(rate) <=0) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        } else if (date === null) {
            toast.error("Please select a an available date.")
            return false
        }

        let destObj = null
        if (destType === "Anywhere") {
            destObj = { state: "ANY", country: "USA" }
        } else if (destType === "SpecificLocation") {
            destObj = {
                city: destinationLocation.city,
                state: destinationLocation.state,
                country: destinationLocation.country ? destinationLocation.country : "United States"
            }
        } else if (destType === "SpecificZone") {
            destObj = {
                zone: destinationZone.abbr
            }
        }
    
        let params = {
            carrierCapacityId: editItem.availableCapacityId,
            ecpCarrierIdent: user.carrier.externalId,
            dateAvailable: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
            origin: {
                city: originLocation.city,
                state: originLocation.state,
                country: originLocation.country ? originLocation.country : "United States" // todo check logic
            },
            destinationType: destType,
            destination: destObj,
            equipment: truckType,
            source: "TT", // todo maybe change to CE
            user: user.username,
            rate: rate === "" ? 0 : Number(rate),
            addressId: selectedLocationId
        }
       
        // console.log("PARAMS: ", params)

        setIsApiCalling(true)
        const apiServices = new WernerServices()
        apiServices.editAvailableCapacity(params)
            .then(response => {
                setIsApiCalling(false)
                // console.log("RESPONSE: ", response)
                if (checkValueExists(response, "code")) {
                    toast.success("Capacity edited successfully. The page has been refreshed.")
                    updatePreferencesData()
                    onClose()
                } else {
                    toast.error("There was an issue with your request, please try again.")
                }
            })
            .catch(error => {
                setIsApiCalling(false)
                toast.error("There was an issue with your request, please try again.")
                onClose()
            })
    }

    const addLanePreference = () => {
        // add validation
        if (origins === null || !Array.isArray(origins) || origins.length <= 0) {
            toast.error("Origin location is required. Please select one or more origin states.")
            return false
        } else if (destinations === null || !Array.isArray(destinations) || destinations.length <= 0) {
            toast.error("Destination location is required. Please select one or more destination states.")
            return false
        } else if (origins && Array.isArray(origins) && origins.length > 1 &&
            destinations && Array.isArray(destinations) && destinations.length > 1) {
            toast.error("You cannot select multiple origins and multiple destinations. Please reduce either origin or destination down to a single state.")
            return false
        }else if (laneType === "SM" && destinations && Array.isArray(destinations) && destinations.length < 2) {
            toast.error("Please enter multiple destinations for the selected lane type.")
            return false
        }else if (laneType === "MS" && origins && Array.isArray(origins) && origins.length < 2) {
            toast.error("Please enter multiple origins for the selected lane type.")
            return false
        // }else if (rate === "") {
        //     toast.error("Please enter a rate.")
        //     return false
        // } else if (rate !== "" && Number(rate) <=0) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        // } else if (rate !== "" && isNaN(rate)) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        } else if (availableDays === null || !Array.isArray(availableDays) || availableDays.length <= 0) {
            toast.error("Please select available days for this lane preference.")
            return false
        }

        let originArr = []
        origins.forEach((state => {
            let stateObj = {
                state: state,
                country: "USA"
            }
            originArr.push(stateObj)
        }))
        let destArr = []
        destinations.forEach((state => {
            let stateObj = {
                state: state,
                country: "USA"
            }
            destArr.push(stateObj)
        }))


        let params = {
            ecpCarrierIdentifier: user.carrier.externalId,
            daysOfWeek: (availableDays && availableDays.length >= 7) ? ["ALL"] : availableDays,
            origin: originArr,
            destination: destArr,
            source: "TT", // todo maybe change to CE
            user: user.username,
            addressId: selectedLocationId
        }

        if (rate !== "" && !isNaN(rate)) {
            params.price = Number(rate)
            params.priceType = rateType
        }else{
            params.price = 0
            params.priceType = 'FLAT'
        }
        if (rate !== "") {
            params.price = Number(rate)
            params.priceType = rateType
        }

        // console.log("PARAMS: ", params)

        setIsApiCalling(true)
        const apiServices = new WernerServices()
        apiServices.addLanePreference(params)
            .then(response => {
                setIsApiCalling(false)
                // console.log("RESPONSE: ", response)
                if (!checkValueExists(response, "message")) {
                    toast.success("Lane preference added successfully. The page has been refreshed.")
                    updatePreferencesData()
                    onClose()
                } else {
                    toast.error("There was an issue with your request, please try again.")
                }
            })
            .catch(error => {
                setIsApiCalling(false)
                toast.error("There was an issue with your request, please try again.")
                onClose()
            })
    }

    const editLanePreference = () => {
        // add validation
        if (origins === null || (Array.isArray(origins) && origins.length <= 0)) {
            toast.error("Origin location is required. Please select one or more origin states.")
            return false
        } else if (destinations === null || (Array.isArray(destinations) && destinations.length <= 0)) {
            toast.error("Destination location is required. Please select one or more destination states.")
            return false
        } else if (origins && Array.isArray(origins) && origins.length > 1 &&
            destinations && Array.isArray(destinations) && destinations.length > 1) {
            toast.error("You cannot select multiple origins and multiple destinations. Please reduce either origin or destination down to a single state.")
            return false
        // } else if (rate === "") {
        //     toast.error("Please enter a rate.")
        //     return false
        // } else if (rate !== "" && isNaN(rate)) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        // } else if (rate !== "" && Number(rate) <=0) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        // } else if (rate !== "" && rateType === null) {
        //     toast.error("Please select a rate type.")
        //     return false
        }else if (availableDays === null || !Array.isArray(availableDays) || availableDays.length <= 0) {
            toast.error("Please select available days for this lane preference.")
            return false
        }

        let originObj = {
            state: Array.isArray(origins) ? origins[0] : origins,
            country: "USA"
        }
        
        let destObj = {
            state: Array.isArray(destinations) ? destinations[0] : destinations,
            country: "USA"
        }

        let params = {
            lanePreferenceId: editItem.lanePreferenceId,
            lanePrefernceId: editItem.lanePreferenceId,
            ecpCarrierIdentifier: user.carrier.externalId,
            daysOfWeek: (availableDays && availableDays.length >= 7) ? ["ALL"] : availableDays,
            origin: originObj,
            destination: destObj,
            source: "TT", // todo maybe change to CE
            user: user.username,
            addressId: selectedLocationId
        }

        if (rate !== "" && !isNaN(rate)) {
            params.price = Number(rate)
            params.priceType = rateType
        }else{
            params.price = 0
            params.priceType = 'FLAT'
        }
        if (rate !== "") {
            params.price = Number(rate)
            params.priceType = rateType
        }
        // console.log("PARAMS: ", params)

        setIsApiCalling(true)
        const apiServices = new WernerServices()
        apiServices.editLanePreference(params)
            .then(response => {
                setIsApiCalling(false)
                // console.log("RESPONSE: ", response)
                if (checkValueExists(response, "code")) {
                    toast.success("Lane preference edited successfully. The page has been refreshed.")
                    updatePreferencesData()
                    onClose()
                } else {
                    toast.error("There was an issue with your request, please try again.")
                }
            })
            .catch(error => {
                setIsApiCalling(false)
                toast.error("There was an issue with your request, please try again.")
                onClose()
            })
    }

    const addPreference = () => {
        if (renderedTab === 0) {
            if (isEditing) {
                editAvailableCapacity()
            } else {
                addAvailableCapacity()
            }
        } else if (renderedTab === 1) {
            if (isEditing) {
                editLanePreference()
            } else {
                addLanePreference()
            }
        }
    }

    const mapZone = (zoneAbbr) => {
        let zones = [
            { id: 66, name: "Zone 0 (CT, ME, MA, NH, NJ, RI, VT)", abbr: "Z0", isSelected: false },
            { id: 67, name: "Zone 1 (DE, NY, PA)", abbr: "Z1", isSelected: false },
            { id: 68, name: "Zone 2 (MD, NC, SC, VA, WV)", abbr: "Z2", isSelected: false },
            { id: 69, name: "Zone 3 (AL, FL, GA, MS, TN)", abbr: "Z3", isSelected: false },
            { id: 70, name: "Zone 4 (IN, KY, MI, OH)", abbr: "Z4", isSelected: false },
            { id: 71, name: "Zone 5 (IA, MN, MT, ND, SD, WI)", abbr: "Z5", isSelected: false },
            { id: 72, name: "Zone 6 (IL, KS, MO, NE)", abbr: "Z6", isSelected: false },
            { id: 73, name: "Zone 7 (AR, LA, OK, TX)", abbr: "Z7", isSelected: false },
            { id: 74, name: "Zone 8 (AZ, CO, ID, NV, NM, UT, WY)", abbr: "Z8", isSelected: false },
            { id: 75, name: "Zone 9 (CA, OR, WA, AK)", abbr: "Z9", isSelected: false },
            { id: 87, name: "Zone E (NB, NL, NS, PE)", abbr: "ZE", isSelected: false },
            { id: 88, name: "Zone C (ON, PQ)", abbr: "ZC", isSelected: false },
            { id: 89, name: "Zone W (AB, BC, MB, SK)", abbr: "ZW", isSelected: false },
            { id: 90, name: "Zone M Mexico", abbr: "ZM", isSelected: false }
        ];
        let obj = zones.find(zone => zone.abbr === zoneAbbr);
        return obj
    }

    useEffect(() => {
        if (editItem !== null) {
            // prepopulated existing values for edit
            if (preferenceType === "capacity") {
                setOriginLocation(editItem.origin)
                if (editItem.destination.state === "ANY") {
                    setDestType("Anywhere")
                } else if (editItem.destination.zone) {
                    setDestType("SpecificZone")
                    let zoneObj = mapZone(editItem.destination.zone)
                    // console.log("SpecificZone: ", zoneObj, editItem.destination.zone)
                    setDestinationZone(zoneObj)
                }
                setDestinationLocation(editItem.destination)
                setDate(moment(editItem.dateAvailable))
                setTruckType(editItem.equipmentType)
                setRate(editItem.rate)
            } else if (preferenceType === "lanePref") {
                if (editItem.daysOfWeek.includes("ALL")) {
                    setAvailableDays(["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"])
                } else {
                    setAvailableDays(editItem.daysOfWeek)
                }
                setOrigins([editItem.origin.state])
                setDestinations([editItem.destination.state])
                setRate(editItem.rate)
                setRateType(editItem.rateType && editItem.rateType!==''?editItem.rateType:'RPM')
                setLaneType('SS')
                setLaneTypePreSelect('SS')
            }
        }
    }, [])

    const addCapacityDesktopBody = (
        <div>
            <Row style={{ display: "flex" ,width:'100%'}}>
                <Col style={{ marginRight: 15,width: '49%' ,paddingTop:38}}>
                        <AddressAutocomplete 
                                needsRedux={false} 
                                handleSetPickupLocation={handleSetPickupLocation} 
                                existingLocation={originLocation} 
                                onChange={() => console.log('on change')} 
                                placeholder="Origin Location *" 
                                helperText={"Enter where you are looking to pick up a load"} 
                                //onChange={(event)=>handleSetPickupLocation(event)} 
                                //value={addressLine1Field} 
                                clearData={()=>console.log('on clear Data')} 
                                variant="filled" 
                                fullWidth 
                                required
                                name={"pickup1"}
                                //error={isAddressLine1FieldInValid}
                            />
                </Col>
                <Col style={{width: '51%'}} >
                    <RadioGroup row aria-label="uploadType" name="uploadType1" value={destType} onChange={handleDestTypeSelection}>
                        <FormControlLabel classes={{label: classes.label}} value="SpecificLocation" control={<Radio style={{ color: muiTheme.secondary }} />} label="Select City & State"/>
                        <FormControlLabel classes={{label: classes.label}} value="SpecificZone" control={<Radio style={{ color: muiTheme.secondary }} />} label="Select Zone"/>
                        <FormControlLabel classes={{label: classes.label}} value="Anywhere" control={<Radio style={{ color: muiTheme.secondary }} />} label="Anywhere"/>
                    </RadioGroup>
                    {destType === "SpecificLocation" && (
                            <AddressAutocomplete 
                                    needsRedux={false} 
                                    handleSetPickupLocation={handleSetDeliveryLocation} 
                                    existingLocation={destinationLocation} 
                                    onChange={() => console.log('on change')} 
                                    placeholder="Destination Location *" 
                                    helperText={"Enter where you are looking to drop off a load"} 
                                    //onChange={(event)=>handleSetPickupLocation(event)} 
                                    //value={addressLine1Field} 
                                    clearData={()=>console.log('on clear Data')} 
                                    variant="filled" 
                                    fullWidth 
                                    required
                                    name={"destination1"}
                                    //error={isAddressLine1FieldInValid}
                                />
                    )}
                    {destType === "SpecificZone" && (
                        <ZoneSelect
                            handleSetSelectedZone={(value) => {
                                setDestinationZone(value)
                            }}
                            existingZone={destinationZone}
                            inputTransparentStyle={false}
                        />
                    )}
                    {destType === "Anywhere" && (
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label={<span style={{ fontSize: 16 }}>Destination Location *</span>}
                            //label="Destination Location"
                            variant="filled"
                            InputProps={{
                            style: {
                                fontSize: 16
                            }
                        }}
                            value={"Anywhere"}
                        />
                    )}
                </Col>
            </Row>
            <Row style={{ display: "flex", marginTop: 20 }}>
                <Col style={{ width: "48%",marginRight:15 }}>
                    <TruckTypeSelectWerner handleSetTruckType={handleSetTruckType} existingTruckType={truckType} />
                </Col>
                <Col style={{width: '50%', display: "flex", position:'relative', justifyContent:'flex-end' }}>
                    {/* <TextField
                        id="filled-basic"
                        label={<span style={{ fontSize: 16 }}>Rate *</span>}
                        variant="filled"
                        style={{ width: '50%', marginRight: 15 }}
                        value={rate}
                        onKeyDown={ (e) => handleRateKey(e.key, e.keyCode) && e.preventDefault() }
                        onChange={(e) => handleRateChange(e.target.value)}
                        InputProps={{
                            style: {
                                fontSize: 16
                            }
                        }}
                    /> */}
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        // format="MM/dd/yyyy"
                        format="MM/DD/YYYY"
                        disablePast
                        margin="normal"
                        className={classes.textField}
                        id="date-picker-inline"
                        label="Available Date *"
                        value={date}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            className: classes.calendarIcon,
                            'aria-label': 'change date',
                            onFocus: e => {
                                setOpenDateSelector(true);
                            }
                        }}
                        InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontSize: 16,
                                }
                            }}
                        InputProps={{
                            style: {
                                textAlign: 'center',
                                fontSize: 16,
                                padding: '6px 0px 0px',
                            },
                            disableUnderline: true,
                            onFocus: () => {
                                setOpenDateSelector(true);
                            }
                        }}
                        PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                                setOpenDateSelector(false);
                            },
                            className: classes.MuiCalendarPopover  
                        }}
                        open={openDateSelector}
                    />
                </Col>
            </Row>
        </div>
    )

    const addCapacityMobileBody = (
        <div>
            <Row style={{width:'100%'}}>
                <Col style={{ marginRight: 15,width: '100%' ,paddingTop:15}}>
                        <AddressAutocomplete 
                                needsRedux={false} 
                                handleSetPickupLocation={handleSetPickupLocation} 
                                existingLocation={originLocation} 
                                onChange={() => console.log('on change')} 
                                placeholder="Origin Location *" 
                                helperText={"Enter where you are looking to pick up a load"} 
                                //onChange={(event)=>handleSetPickupLocation(event)} 
                                //value={addressLine1Field} 
                                clearData={()=>console.log('on clear Data')} 
                                variant="filled" 
                                fullWidth 
                                required
                                name={"pickup1"}
                                //error={isAddressLine1FieldInValid}
                            />
                </Col>
                <Col style={{width: '100%', paddingTop: 15}} >
                    <RadioGroup row aria-label="uploadType" name="uploadType1" value={destType} onChange={handleDestTypeSelection}>
                        <FormControlLabel classes={{label: classes.labelMobile}} value="SpecificLocation" control={<Radio style={{ color: muiTheme.secondary }} />} label="Select City & State"/>
                        <FormControlLabel classes={{label: classes.labelMobile}} value="SpecificZone" control={<Radio style={{ color: muiTheme.secondary }} />} label="Select Zone"/>
                        <FormControlLabel classes={{label: classes.labelMobile}} value="Anywhere" control={<Radio style={{ color: muiTheme.secondary }} />} label="Anywhere"/>
                    </RadioGroup>
                    {destType === "SpecificLocation" && (
                            <AddressAutocomplete 
                                    needsRedux={false} 
                                    handleSetPickupLocation={handleSetDeliveryLocation} 
                                    existingLocation={destinationLocation} 
                                    onChange={() => console.log('on change')} 
                                    placeholder="Destination Location *" 
                                    helperText={"Enter where you are looking to drop off a load"} 
                                    //onChange={(event)=>handleSetPickupLocation(event)} 
                                    //value={addressLine1Field} 
                                    clearData={()=>console.log('on clear Data')} 
                                    variant="filled" 
                                    fullWidth 
                                    required
                                    name={"destination1"}
                                    //error={isAddressLine1FieldInValid}
                                />
                    )}
                    {destType === "SpecificZone" && (
                        <ZoneSelect
                            handleSetSelectedZone={(value) => {
                                setDestinationZone(value)
                            }}
                            existingZone={destinationZone}
                            inputTransparentStyle={false}
                            mobileView={isMobile}
                        />
                    )}
                    {destType === "Anywhere" && (
                        <TextField
                            fullWidth
                            id="filled-basic"
                            label={<span style={{ fontSize: 16 }}>Destination Location *</span>}
                            //label="Destination Location"
                            variant="filled"
                            InputProps={{
                            style: {
                                fontSize: 16
                            }
                        }}
                            value={"Anywhere"}
                        />
                    )}
                </Col>
            </Row>
            <Row style={{ display: "flex", marginTop: 20 }}>
                <Col style={{ width: "100%",marginRight:'1%' }}>
                    <TruckTypeSelectWerner handleSetTruckType={handleSetTruckType} existingTruckType={truckType} />
                </Col>
                {/* <Col style={{ width: "50%", display: "flex" }}>
                <TextField
                        id="filled-basic"
                        label={<span style={{ fontSize: 16 }}>Rate *</span>}
                        variant="filled"
                        style={{ width: '50%', marginRight: 15 }}
                        value={rate}
                        onKeyDown={ (e) => handleRateKey(e.key, e.keyCode) && e.preventDefault() }
                        onChange={(e) => handleRateChange(e.target.value)}
                        InputProps={{
                            style: {
                                fontSize: 16
                            }
                        }}
                    />
                </Col> */}
            </Row>
            <Row style={{ marginTop: 20 }}>
                {/* <p style={{ marginBottom: 10 }}>Available Date<span style={{ color: muiTheme.ttRed }}>*</span></p> */}
                <Col style={{ width: "100%", display: "flex"}}>
                    <form noValidate style={{width:'calc(100%)'}}>
                        <TextField
                            id="date"
                            readOnly
                            variant="filled"
                            required="required"
                            label="Available Date"
                            type="date"
                            onChange={handleDateChangeEvent}
                            value={ typeof(date) === 'string' ? date : date.format('YYYY-MM-DD') }
                            className={classes.dateInputField}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    fontSize: 16,
                                }
                            }}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    fontSize: 16,
                                },
                                
                            }}
                            inputProps={{ min: typeof(date) === 'string' ? date : date.format('YYYY-MM-DD')}}
                        />
                    </form>
                </Col>
            </Row>
        </div>
    )

    const laneTypeUI = (type,textOnBottom = false)=>{
            
            let lineOne = '';
            let lineTwo = '';
            let svg = null
            
            switch (type) {
                case 'SS':
                    lineOne = 'Single origin';
                    lineTwo = 'Single destination';
                    svg = (<img className={classes.laneImage} style={{marginTop:textOnBottom||isMobile?0:20}} src={require('../../../customers/' + muiTheme.customerUniqueId + '/theme/laneTypeSS.svg') }  />)
                    break;
                case 'SM':
                    lineOne = 'Single origin';
                    lineTwo = 'Multiple destinations';
                    svg = (<img className={classes.laneImage} style={{marginTop:textOnBottom||isMobile?0:20}} src={require('../../../customers/' + muiTheme.customerUniqueId + '/theme/laneTypeSM.svg') }  />)
                    break;
            
                case 'MS':
                    lineOne = 'Multiple origins';
                    lineTwo = 'Single destination';
                    svg = (<img className={classes.laneImage} style={{marginTop:textOnBottom||isMobile?0:20}} src={require('../../../customers/' + muiTheme.customerUniqueId + '/theme/laneTypeMS.svg') }  />)
                    break;
                
                default:
                    break;
            }
            if(isMobile){
                textOnBottom = false
            }

            let UI = (
                    <Col  className={classes.buttonWrapper} >
                        {!textOnBottom && (
                            <Row className={classes.laneTypeSelectTextBox} >
                                <div style={{paddingBottom: 20}}>
                                    <div className={[classes.mainText,classes.alignedRowText,classes.capitalizedText].join(' ')} >{lineOne}</div>
                                    <div className={[classes.mainText,classes.alignedRowText,classes.capitalizedText].join(' ')} >{lineTwo}</div>
                                </div>
                            </Row>
                        )}
                        <Row  className={[classes.mainText,classes.alignedRowText,classes.laneTypeSelectImageBox].join(' ')} style={{height:textOnBottom?'auto':isMobile?LANE_SELECT_BOX_HEIGHT_MOBILE:LANE_SELECT_BOX_HEIGHT_DESKTOP}} >
                                {svg}
                        </Row>
                        {textOnBottom && (
                            <Row className={classes.laneTypeSelectTextBox} style={{marginTop:15}} >
                                <div>
                                    <div className={[classes.mainText,classes.alignedRowText,classes.capitalizedText].join(' ')} >{lineOne}</div>
                                    <div className={[classes.mainText,classes.alignedRowText,classes.capitalizedText].join(' ')} >{lineTwo}</div>
                                </div>
                            </Row>
                        )}
                    </Col>
                )
            return UI
    }

    const addLanePreferenceBody = (
        <Row style={{ display: isMobile?'block':"flex", marginTop: 10 }}>
            <Col style={{display:'flex',alignItems:'center',justifyContent:'center'}}   md={3} xs={12} style={{padding:isMobile?'0px 0px 10px 0px':25}}>
                {laneTypeUI(laneType,true)}
            </Col>

            <Col  style={{display:'flex',alignItems:'center',justifyContent:'center'}} md={9} xs={12}>
                    <div style={{width:'100%'}} >
                        <Row >
                            <Col  md={6} xs={12} style={{paddingRight:isMobile?0:20}} >
                                <MultiStateSelect
                                    handleSelectedStates={(value) => {
                                        setOrigins(value)
                                    }}
                                    existingStates={origins}
                                    inputTransparentStyle={false}
                                    placeholderMsg={"Origin"}
                                    isEditing={isEditing}
                                    allowMultiSelect={laneType=="MS"}
                                    mobileView={isMobile}
                                />
                                {!isMobile &&(
                                    <Col style={{ width: "3%", alignItems: "center",position: 'absolute', right: '-5px', top: '15px' }}>
                                        <FA name='arrow-right' />
                                    </Col>
                                )}
                                
                            </Col>
                            <Col  md={6} xs={12}  style={{paddingLeft:isMobile?0:20, marginTop:isMobile?20:0}} >
                                <MultiStateSelect
                                    handleSelectedStates={(value) => {
                                        setDestinations(value)
                                    }}
                                    existingStates={destinations}
                                    inputTransparentStyle={false}
                                    placeholderMsg={"Destination"}
                                    isEditing={isEditing}
                                    allowMultiSelect={laneType=="SM"}
                                    mobileView={isMobile}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col   md={6} xs={12}   style={{paddingRight:isMobile?0:20,marginTop:isMobile?5:0}} >
                                {/* <p style={{ marginBottom: 10 }}>Available Days <span style={{ color: muiTheme.ttRed }}>*</span></p> */}
                                <DaySelectWerner handleSetDays={handleSelectAvailableDay} existingDays={availableDays} mobileView={isMobile}/>
                            </Col>
                            <Col   md={6} xs={12}  style={{}}  style={{paddingLeft:isMobile?0:20,marginTop:isMobile?25:0}} >
                                {/* Temportary Stubbed Out Rate */}
                                {/* <TextField
                                    id="filled-basic"
                                    label={<span style={{ fontSize: 16 }}>Rate *</span>}
                                    variant="filled"
                                    style={{ width: '50%', marginRight: 15 }}
                                    value={rate}
                                    onKeyDown={ (e) => handleRateKey(e.key, e.keyCode) && e.preventDefault() }
                                    onChange={(e) => handleRateChange(e.target.value)}
                                    InputProps={{
                                        style: {
                                            fontSize: 16
                                        }
                                    }}
                                /> */}
                                {/* <RadioGroup row aria-label="uploadType" name="uploadType1" value={rateType} onChange={handleRateTypeSelection}>
                                    <FormControlLabel classes={{label: classes.label}}  value="RPM" control={<Radio size='medium' style={{ color: muiTheme.secondary }} />} label="Per Mile"/>
                                    <FormControlLabel classes={{label: classes.label}}  value="FLAT" control={<Radio size='medium' style={{ color: muiTheme.secondary }} />} label="Flat Rate"/>
                                </RadioGroup> */}
                            </Col>
                        </Row>
                    </div>
            </Col>
        </Row>
        
    )

    const buttonsToSave = (
        <Row style={{ width: "80%", marginTop: 40, marginLeft: "10%", marginRight: "10%", display: "flex", justifyContent: "center" }}>
                <Col style={{ marginRight: 10 }}>
                    <Button
                        // variant={"contained"}
                        size={"small"}
                        style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "transparent" }}
                        onClick={() => {
                            if(renderedTab==1){
                                setLaneType(null)
                                setOrigins([])
                                setDestinations([])
                            } else{
                                handleClose()
                            }
                        }}
                        disabled={isApiCalling}
                    >
                        BACK
                    </Button>
                </Col>
                <Col style={{ marginLeft: 10 }}>
                    <Button
                        variant={"contained"}
                        size={"small"}
                        style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor }}
                        onClick={() => addPreference()}
                        disabled={isApiCalling}
                    >
                        SAVE
                        </Button>
                </Col>
            </Row>
    )
    
    const body = (
        <div style={modalStyle} className={renderedTab === 1 ? classes.paperLanePref:classes.paper}>
            <IconButton
                className={classes.closeIconButton} 
                aria-label="close" 
                onClick={()=>handleClose()
            }>
                <CloseIcon />
            </IconButton>
            <h3 style={{ marginBottom: 0, textTransform: "uppercase" }}>{isEditing ? "Edit" : "Add"} {renderedTab === 0 ? "Capacity" : "Lane Preference"}</h3>
            {renderedTab === 1 &&(<h4 style={{ marginBottom: 10,fontSize:16,color:INPUT_BORDER_COLOR }}>{"Select Details"}</h4>) }
            <Row>
                {renderedTab === 0 && isMobile && addCapacityMobileBody}                
                {renderedTab === 0 && !isMobile && addCapacityDesktopBody}

                {renderedTab === 1 && addLanePreferenceBody }
            </Row>
            {renderedTab === 0 && buttonsToSave}
            {renderedTab === 1 && buttonsToSave}
        </div>
    );



    

    const selectLanePreferenceBody = ()=>{
        let ui = (
            <Row style={{ marginTop: 20 }}>
                <Col md={4} xs={12}  className={classes.selectBox}  >
                    <Button className={[classes.laneTypeSelectBox , laneTypePreSelect=="SS" ? classes.activeLaneSelectCard:null]} onClick={() => setLaneTypePreSelect('SS')} >
                        {laneTypeUI('SS')}
                    </Button>
                </Col>
                <Col md={4} xs={12}  className={classes.selectBox}  >
                    <Button className={[classes.laneTypeSelectBox , laneTypePreSelect=="SM" ? classes.activeLaneSelectCard:null]} onClick={() => setLaneTypePreSelect('SM')} >
                        {laneTypeUI('SM')}
                    </Button>
                </Col>
                <Col md={4} xs={12}  className={classes.selectBox}  >
                    <Button className={[classes.laneTypeSelectBox , laneTypePreSelect=="MS" ? classes.activeLaneSelectCard:null]} onClick={() => setLaneTypePreSelect('MS')} >
                        {laneTypeUI('MS')}
                    </Button>
                </Col>
            </Row>
        )
        return ui
    }

    const buttonsToChooseLaneType = (
        <Row style={{ width: "80%", marginTop: 40, marginLeft: "10%", marginRight: "10%", display: "flex", justifyContent: "center" }}>
                <Col style={{ marginRight: 10 }}>
                    <Button
                        // variant={"contained"}
                        size={"small"}
                        style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "transparent" }}
                        onClick={() => handleClose()}
                        disabled={isApiCalling}
                    >
                        BACK
                        </Button>
                </Col>
                <Col style={{ marginLeft: 10 }}>
                    <Button
                        variant={"contained"}
                        size={"small"}
                        style={{ fontSize: 14, color: "white", backgroundColor: laneTypePreSelect?muiTheme.actionColor:INPUT_BACKGOUND_COLOR }}
                        onClick={() => setLaneType(laneTypePreSelect)}
                        disabled={!laneTypePreSelect}
                    >
                        NEXT
                    </Button>
                </Col>
            </Row>

    )

    const bodyToSelectLaneType = (
        <div style={modalStyle} className={classes.paperLaneSelect }>
            <IconButton
                className={classes.closeIconButton} 
                aria-label="close" 
                onClick={()=>handleClose()
            }>
                <CloseIcon />
            </IconButton>
            <h3 style={{ marginBottom: 0, textTransform: "uppercase" }}>
                {isEditing ? "Edit" : "Add"} {renderedTab === 0 ? "Capacity" : "Lane Preference"}
            </h3>
            <h4 style={{ marginBottom: 10,fontSize:16,color:INPUT_BORDER_COLOR }}>{"Select lane type"}</h4>
            <Row>
                {!laneType && selectLanePreferenceBody() }
            </Row>
            { !laneType && buttonsToChooseLaneType}
        </div>
    );

    // console.log("BG logs - laneType: ", laneType)

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <span>
            
                {isApiCalling && <LoadingOverlay />}
                {renderedTab === 0 && body}
                {renderedTab === 1 && laneType && body}
                {renderedTab === 1 && !laneType && bodyToSelectLaneType}
            </span>
        </Modal>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(AddPreferenceModal)