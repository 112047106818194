import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../HOC/Mobile.js'

import { Row, Col, Grid } from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import LoadingOverlay from '../../components/LoadingOverlay'
import Profile from "../../profile"
import ProfileWide from "../../profile/index-old"

import EquipmentForm from "../components/profile/equipment-form"
import LocationSelector from '../components/preferences/components/LocationSelector';
import LocationList from '../components/profile/locations/location-list';
import AddNewLocation from '../components/profile/locations/add-new-location';
import AddNewContact from '../components/profile/contacts/add-new-contact';
import ContactList from '../components/profile/contacts/contact-list'

import { WernerServices } from '../customers/a98db973/services';
import { checkValueExists } from '../../lib/check-value-exists';
import { useAuthenticationHook } from '../hooks/authentication-hook.js';


const ProfileScreen = (props) => {

    const { muiTheme, isMobile } = props

    const useStyles = makeStyles({
        container: {
            height: '100%',
            width: '100%'
        },
        breadcrumbLink: {
            textDecoration: "underline",
            marginRight: 15,
            color: "black",
            "&:hover": {
                color: muiTheme.actionColor,
            }
        },
        activeBreadcrumbLink: {
            color: muiTheme.primary,
            textDecoration: "underline",
            "&:hover": {
                color: muiTheme.primary,
            }
        },
        header: {
            width: "25%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderRadius: 0,
            textTransform: "none",
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        headerText: {
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 16,
            fontWeight: 'bold',
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        }
    });

    const classes = useStyles()

    const PAGES = {
        profilePage: 0,
        locationsPage: 1,
        equipmentPage: 2,
        contactsPage: 3
    }

    const [renderedPage, setRenderedPage] = useState(PAGES["profilePage"])
    const [isLoading, setIsLoading] = useState(false)
    const [locationsList, setLocationsList] = useState([])
    const [selectedLocationId, setSelectedLocationId] = useState(null)
    const [equipmentList, setEquipmentList] = useState([])
    const [contactList, setContactList] = useState([])

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()
    const userRole = (user && user.role && user.role !== "") ? user.role : null

    const getLocations = () => {
        const apiServices = new WernerServices()
        apiServices.getLocations()
            .then(response => {
                if (response && checkValueExists(response, "addresses")) {
                    setLocationsList(response.addresses)
                    let defaultLocation = response.addresses.filter(obj => {
                        return obj.addressType === "MAIN"
                    })
                    if (defaultLocation.length > 1) {
                        setSelectedLocationId(defaultLocation[0].addressId)
                    } else {
                        setSelectedLocationId(response.addresses[0].addressId)
                    }
                }
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
            })
    }

    const updateProfileDataBasedOnLocation = () => {
        const apiServices = new WernerServices()
        apiServices.getEquipments(selectedLocationId)
            .then(response => {
                if (response) {
                    setEquipmentList(response)
                }
            })
            .catch(error => {
            })

        apiServices.getContacts(selectedLocationId)
            .then(response => {
                if (response) {
                    setContactList(response)
                }
            })
            .catch(error => {
            })

        setIsLoading(false)
    }

    useEffect(() => {
        if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && process.env.REACT_APP_ENVIRONMENT === "staging") {
            setIsLoading(true)
            getLocations()
        }
    }, [])

    useEffect(() => {
        if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && process.env.REACT_APP_ENVIRONMENT === "staging") {
            if (selectedLocationId != null) {
                setIsLoading(true)
                updateProfileDataBasedOnLocation()
            }
        }
    }, [selectedLocationId])

    const _onLocationAddComplete = (flag) => {
        if (flag) {
            if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
                setIsLoading(true)
                getLocations()
            }
        }
    }

    const _onContactAddComplete = (flag) => {
        if (flag) {
            if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
                setIsLoading(true)
                updateProfileDataBasedOnLocation()
            }
        }
    }
    const refreshLocationsAfterDeletion = (flag) => {
        if (flag) {
            getLocations()
        }
    }
    const refreshContactAfterDeletion = (flag) => {
        if (flag) {
            _onContactAddComplete(true)
        }
    }


    const refreshContactAfterEdit = (flag) => {
        if (flag) {
            _onContactAddComplete(true)
        }
    }

    const refreshLocationAfterEdit = (flag) => {
        if (flag) {
            getLocations(true)
        }
    }


    return (
        <div style={{ height: '100%' }}>
            {isLoading && <LoadingOverlay />}
            {process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && process.env.REACT_APP_ENVIRONMENT === "staging" ? (
                <div className={classes.container}>
                    <Row style={{ height: '5%', width: "100%", display: "flex", justifyContent: "center", marginTop: 25 }}>
                        <Col style={{ width: "70%", display: "flex", justifyContent: "center" }}>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedPage == 0 ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => setRenderedPage(0)}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedPage == 0 ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Profile</p>
                            </Button>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedPage == 1 ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => setRenderedPage(1)}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedPage == 1 ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Locations</p>
                            </Button>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedPage == 2 ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => setRenderedPage(2)}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedPage == 2 ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Equipment</p>
                            </Button>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedPage == 3 ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => setRenderedPage(3)}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedPage == 3 ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Contacts</p>
                            </Button>
                        </Col>
                    </Row>
                    {renderedPage !== 0 && renderedPage !== 1 && (
                        <Row style={{ height: '10%', marginTop: "1%", display: 'flex', justifyContent: 'center' }}>
                            <LocationSelector
                                locationsList={locationsList}
                                selectedLocationId={selectedLocationId}
                                setSelectedLocationId={setSelectedLocationId}
                            />
                        </Row>
                    )}

                    {selectedLocationId && locationsList && locationsList.length > 0 && renderedPage == 3 && (
                        <Row style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }}>
                            <AddNewContact onComplete={(flag) => _onContactAddComplete(flag)} locationsList={locationsList} initialValues={{ addressId: selectedLocationId }} />
                        </Row>
                    )}


                    {renderedPage == 1 && (
                        <Row style={{ marginTop: 15 }}>
                            <AddNewLocation onComplete={(flag) => _onLocationAddComplete(flag)} />
                        </Row>
                    )}



                    <Row style={{ height: (renderedPage == 3) ? '60%' : (renderedPage !== 0) ? '76%' : '81%', paddingTop: 15 }}>
                        {renderedPage == 0 && (
                            <Grid style={{ height: "100%", width: "100%" }}>
                                <div style={{ maxHeight: "100%", overflowX: "hidden", overflowY: "auto" }}>
                                    <Profile {...props} />
                                </div>
                            </Grid>
                        )}
                        {renderedPage == 1 && (
                            <LocationList {...props} locationsList={locationsList} onDelete={(flag) => refreshLocationsAfterDeletion(flag)} onEdit={(flag) => refreshLocationAfterEdit(flag)} />
                        )}
                        {renderedPage == 2 && (
                            <EquipmentForm {...props} data={equipmentList} />
                        )}
                        {renderedPage == 3 && (
                            <ContactList {...props} contactList={contactList} onDelete={(flag) => refreshContactAfterDeletion(flag)} locationsList={locationsList} onEdit={(flag) => refreshContactAfterEdit(flag)} />
                        )}
                    </Row>
                </div>

            ) : (
                <div className={classes.container}>
                    <Row style={{ height: '100%' }}>
                        <div style={{ height: "100%", width: "100%" }}>
                            <div style={{ height: isMobile ? '100%' : "95%", overflow: "hidden", paddingTop: isMobile ? 0 : '5%' }}>
                                <ProfileWide {...props} userRole={userRole} />
                            </div>
                        </div>
                    </Row>
                </div>
            )}
        </div>
    )
}


export default compose(
    muiThemeable(),
    isMobile(),
)(ProfileScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}