import {BACKEND_URL} from './constants.js'
export function getOutofnetworkbroker(){

  var requestUrl=BACKEND_URL+'/getOutOfNetworkBrokers' ;
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function inviteNewBrokers(invitingBroker){
  console.log('inviteNewBrokers');
  console.log(invitingBroker);
  var requestUrl=BACKEND_URL+'/createInterestWithBrokers';


  var postBody        =     { brokerIds : [invitingBroker.id]};
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                            body:JSON.stringify(postBody),


                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function joinBrokerNetworks(brokerIds){
  console.log('inviteNewBrokers');
  var requestUrl=BACKEND_URL+'/createInterestWithBrokers';


  var postBody        =     { brokerIds : brokerIds};
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                            body:JSON.stringify(postBody),


                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
