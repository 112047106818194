import React, { useEffect, useState } from 'react';
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row } from "react-bootstrap"
import TextField from '@material-ui/core/TextField';
import FlatButton from 'material-ui/FlatButton';
import { toast } from 'react-toastify';
import CurrencyInput from '../../../../components/Quote/CurrencyInput'
import moment from 'moment'
import { saveQuoteAction } from '../../../../components/Quote/actions'
import { LogActionsActivity } from '../../../../CarrierPortal/apis/index'
import useGtagHook from "../../../../gtag-hook"
import {useAuthenticationHook} from '../../../hooks/authentication-hook';
import * as RateNegotiationsApi from '../../../../api/rateNegotiations'
import { titleCaseFormatter } from "../../../../lib/title-case-formatter"


const QuoteForm = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        carriers, brokers, availabilities,
        muiTheme,
        isSaving,
        _onClose,
        _forceHistoryRefresh,
        callRefreshCard=()=>{}
    } = props

    const myAuthService = useAuthenticationHook()    
    const user = myAuthService.getUser()
    const [sendGtagEvent] = useGtagHook()

    const [quoteValue, updateQuoteValue] = useState(0)
    const [quoteMessage, updateQuoteMessage] = useState("")

    useEffect(()=>{
        if(load.isPreviewLoad)
            updateQuoteMessage('I am interested in your load from '+titleCaseFormatter(load.stops[0].city)+", "+load.stops[0].state+" to "+titleCaseFormatter(load.stops[load.stops.length-1].city)+", "+load.stops[load.stops.length-1].state+" on "+moment(load.stops[0].scheduledAt,'MM/DD/YYYY HH:mm:ss').format('dddd, MMM Do @hh:mma'))
    },[])

    function onValueChange(value) {
        updateQuoteValue(value);
    }

    const getIcon = (market, type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type == "origin") {
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    backgroundColor: muiTheme.ttUnselectGrey,
                    width: 12,
                    height: 12,
                }}
            />
        }

        // if (checkValueExists(market, key)) {
        //     if (market[key] >= 80) {
        //         return (<FA name='fire' color='#9A1F25' />)
        //     } else if (market[key] >= 65 && market[key] < 80) {
        //         return (<FA name='fire' color='#D96C1E' />)
        //     } else if (market[key] >= 50 && market[key] < 65) {
        //         return (<FA name='fire' color='#FFB400' />)
        //     } else if (market[key] >= 35 && market[key] < 50) {
        //         return (<FA name='snowflake' color='#5DBFFF' />)
        //     } else if (market[key] > 20 && market[key] < 35) {
        //         return (<FA name='snowflake' color='#0086E1' />)
        //     } else if (market[key] <= 20) {
        //         return (<FA name="snowflake" color={'#002F81'} />)
        //     }
        // }

        return icon
    }

    const makeActivityLogCall = (_rate = null, _notes = null) => {

        if(!myAuthService.isAuthenticated()) return

        // console.log("LOAD: ", load)
        let actionType = "QUOTE"
        let params = {
            loadId: load.loadId, // TODO: key i think will change to loadId with new api
            loadIdArr: [],
            brokerId: load.brokerId,
            actionName: actionType,
            callingPhoneNumber: null,
            notes: _notes,
            rate: _rate,
            matchIndex: index,
            screenName: window.location.pathname
        }
        LogActionsActivity(params, muiTheme.customerUniqueId)
    }

    const submitQuoteNegotiation = ( quoteValue = null, quoteMessage = null) => {
        if(quoteValue <= 0){
            toast.error("Please enter quote value greater than zero")
            return 
        }
        let params = {
            amount: quoteValue,
            load_id: load.loadId,
            broker_id: load.brokerId,
            carrier_id: user.carrier.carrierId, 
            source: "smart_capacity",
            accepted: false,
            rejected: false,
            message: quoteMessage,
            showInterest: load.isPreviewLoad ? true : false
        }

        RateNegotiationsApi.SubmitQuoteNegotiation(params).then(response => {
            if (response.status && response.data) {
                if(load.quotes == null)
                    load['quotes'] = []
                load.quotes.push(response.data)
                toast.success('Your quote successfully saved')
                props._onClose()
                callRefreshCard()

            } else {
                let errorMessage = "Error: There was a problem submitting your request."
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch(() => {
            let errorMessage = "Error: There was a problem submitting your request."
            toast.error(errorMessage)
        })
    }

    return (
        <div>
            <div style={{ width: '100%', padding: 10, backgroundColor: "white" }} key={'quoteInputFor' + load.id}>
                <p style={{ fontFamily: 'Arial !important', fontSize: 24, color: 'black', fontWeight: 'bold', marginBottom: 20 }}>{load.isPreviewLoad ? "Show Interest":"Quote"}</p>
                <div style={{ display: "flex", marginBottom: 25 }}>
                    <Col style={{ width: "3%", marginRight: "2%", flexDirection: "col", alignItems: "center", }}>
                        <Row className={classes.centerRowContent} style={{ padding: '5px', alignItems: 'center' }}>
                            <Row>
                                {getIcon(load, "origin")}
                            </Row>
                        </Row>
                        <Row className={classes.centerRowContent} style={{ alignItems: 'center' }}>
                            <div style={{ width: 1, height: 55, backgroundColor: "black" }} />
                        </Row>
                        <Row className={classes.centerRowContent} style={{ padding: '5px', alignItems: 'center' }}>
                            <Row>
                                {getIcon(load, "destination")}
                            </Row>
                        </Row>
                    </Col>
                    <Col style={{ width: "52%", marginRight: "3%" }}>
                        <Row className={classes.row}>
                            <p className={classes.mainText} >
                                {titleCaseFormatter(pickup.city)}, {pickup.state}
                            </p>
                        </Row>
                        <Row className={classes.row} style={{ marginBottom: 10 }}>
                            <p className={classes.secondaryText}>
                                {moment(pickup.scheduledAt).format('ll')}
                            </p>
                            <p className={classes.secondaryText}>
                                {moment(pickup.scheduledAt).format('HH:mm')}
                            </p>
                        </Row>
                        <Row className={classes.row}>
                            <p className={classes.mainText} >
                                {titleCaseFormatter(delivery.city)}, {delivery.state}
                            </p>
                        </Row>
                        <Row className={classes.row}>
                            <p className={classes.secondaryText}>
                                {moment(delivery.scheduledAt).format('ll')}
                            </p>
                            <p className={classes.secondaryText}>
                                {moment(delivery.scheduledAt).format('HH:mm')}
                            </p>
                        </Row>
                    </Col>
                    <Col style={{ width: "35%" }}>
                        <p className={classes.secondaryText}>Quote</p>
                        <CurrencyInput onValueChange={onValueChange} value={quoteValue} />
                    </Col>
                </div>
                {muiTheme.customerUniqueId != "a98db973" && (
                    <div style={{ width: '100%', padding: 10, paddingBottom: 25, backgroundColor: "white" }} key={'quoteMessageInputFor' + load.id}>
                        <p className={classes.mainText}>Additional Message (Optional)</p>
                        <p className={classes.secondaryText}>Message</p>
                        <TextField
                            inputProps={{ style: { fontSize: 16, padding: 5, lineHeight: 'normal' } }}
                            key="quote_message_input"
                            fullWidth={true}
                            value={quoteMessage}
                            multiline
                            rows={4}
                            variant="outlined"
                            onChange={(event) => updateQuoteMessage(event.target.value)}
                        />
                    </div>
                )}
            </div>
            <div style={{ width: '100%', display: 'inline-block', backgroundColor: "white", padding: 10, paddingBottom: 15 }} key={'ActionsFor' + load.id}>
                <div style={{ width: '33%', display: 'inline-block', backgroundColor: "white" }}>
                    <FlatButton
                        fullWidth={true}
                        label={isSaving ? <FA name='spinner' spin /> : "Close"}
                        disableTouchRipple={true}
                        disableFocusRipple={true}
                        primary={true}
                        style={{ marginRight: 12, color: muiTheme.actionColor, borderColor: muiTheme.actionColor }}
                        onClick={() => {
                            props._onClose()
                        }}
                    />
                </div>
                {/* <div style={{ width: showSaveButton ? '62%' : '100%', display: 'inline-block', marginRight: showSaveButton ? '5%' : 0, backgroundColor: "white" }}> */}
                <div style={{ width: '62%', display: 'inline-block', marginRight: 0, backgroundColor: "white" }}>
                    <FlatButton
                        fullWidth={true}
                        disabled={isSaving || (!quoteMessage && !quoteValue)}
                        label={isSaving ? <FA name='spinner' spin /> : "Submit"}
                        disableTouchRipple={true}
                        disableFocusRipple={true}
                        secondary={true}
                        style={{ marginRight: 12, backgroundColor: muiTheme.actionColor, color: "white" }}
                        onClick={() => {
                            submitQuoteNegotiation( (quoteValue/100).toFixed(2) , quoteMessage)
                            makeActivityLogCall(quoteValue, quoteMessage)
                            sendGtagEvent("submit_quote_request", {loadId: load.loadId, brokerId: load.brokerId})
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        saveQuoteAction: saveQuoteAction,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable(),
)(QuoteForm)

const useStyles = makeStyles({
    mainText: {
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold'
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '21px',
        color: 'grey'
    },
    row: {
        padding: 0,
        margin: 0
    },
    centerRowContent: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    }
});