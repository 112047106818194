
import React from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Col, Row } from "react-bootstrap"
import moment from 'moment'
import FA from 'react-fontawesome'
import { validLoadDetails } from "../../../../../../../lib/valid-load-details"

import CounterOfferButton from '../../../CounterOfferButton'
import BinButton from "../../../../bin/binButton"


const RateNegotiationsListItemMobile = (props) => {

    const { 
        load,
        quote, 
        quoteStatus,
        showBinBtn, 
        showCounterButton,
        latestOffer,
        submitQuoteNegotiation 
    } = props
    const classes = useStyles();


    const getIcon = () => {
        if (quote.message_type === "QUOTE_B") {
            if (quote.rejected) {
                return (
                    <div key="reject-icon" style={{height: 'min-content', justifyContent: 'center'}}>
                        <FA name='minus' style={{ fontSize: 18, color: props.muiTheme.ttRed }} />
                    </div>
                )
            }
            else if (quote.accepted) {
                return (
                    <div key="accept-icon" style={{height: 'min-content', justifyContent: 'center'}}>
                        <FA name='check' style={{ fontSize: 18, color: 'green' }} />
                    </div>
                )
            }
            else {
                return (
                    <div key="recieve-icon" style={{height: 'min-content', justifyContent: 'center'}}>
                        <FA name='arrow-left' style={{ fontSize: 18, color: 'green', transform: 'rotate(-45deg)' }} />
                    </div>
                )
            }
        }
        else if (quote.message_type === "QUOTE_D" || quote.message_type === "QUOTE_C") {
            return (
                <div key="sent-icon" style={{height: 'min-content', justifyContent: 'center'}}>
                    <FA name='arrow-right' style={{ fontSize: 18, color: '#4068B0', transform: 'rotate(-45deg)' }} />
                </div>
            )
        }
        else if (quote.message_type === "BOOKITNOW") {
            return (
                <div key="accept-icon" style={{height: 'min-content', justifyContent: 'center'}}>
                    <FA name='check' style={{ fontSize: 18, color: 'green' }} />
                </div>
            )
        }
    }

    return (
        <div style={{ display: 'flex', width: '100%' }}>

            <Col style={{ width: "12%", display: 'inline-flex', paddingRight: 15 }}>

                {quote.message_type &&
                    <Col style={{ paddingLeft: 15, justifyContent: 'flex-start', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                        {getIcon()}
                    </Col>
                }
            </Col>
            <Col style={{ width: "88%" ,paddingRight: 15 }}>
                {quote.quote_value ?
                    <p className={classes.mainText}>${parseFloat(quote.quote_value).toFixed(2)}<span className={classes.secondaryText}> - {quoteStatus}</span></p>
                    :
                    <p className={classes.mainText}> <i>Unknown Offer Amount</i></p>
                }
                <p style={{wordBreak: 'break-word'}}>
                    {quote.message}
                </p>
                
                <p className={classes.secondaryText} style={{textAlign: 'right'}}>
                    {moment.tz(quote.updated_at, "America/New_York").local().format('ll HH:mm')}
                </p>

            </Col>

            {latestOffer &&
                <Col style={{ position: 'absolute', width: '100%', backgroundColor: 'white', bottom: 0, justifyContent:'center', paddingTop: 10, paddingBottom:35 , zIndex: 1 }}>

                    {quote.quote_value ?
                        <div style={{display:'block', width: '100%', textAlign: 'center'}}>
                            <p className={classes.mainText}>${parseFloat(quote.quote_value).toFixed(2)}<span className={classes.secondaryText}> - {quoteStatus}</span></p>
                        </div>
                        :
                        <p className={classes.mainText}> <i>Unknown Offer Amount</i></p>
                    }

                    <div style={{display:'flex', justifyContent: 'center'}}>
                    {showBinBtn &&  validLoadDetails(load, ["weight"]) &&
                        
                        <BinButton
                            key={"BIN_button"}
                            carrierId={props.availability.carrier_id}
                            availabilityId={props.availability.id}
                            load={load}
                            index={0}
                            pickup={props.pickup}
                            delivery={props.delivery}
                            quoteValue={quote.quote_value}
                            onQuotesTab={true}
                            {...props}
                        />
                        
                    }

                    {showCounterButton &&
                        <CounterOfferButton
                            key={props.index}
                            load={load}
                            submitQuoteNegotiation={submitQuoteNegotiation}
                            quoteValue={quote.quote_value}
                            {...props}
                        />
                    }
                    </div>
                </Col>
            }
            
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiationsListItemMobile)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        justifyContent: 'center'
    },
    button: {
        fontSize: 14,
        color: "white",
        margin: 5
    }
});