
import React, { useState, useEffect } from 'react';
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"

import muiThemeable from 'material-ui/styles/muiThemeable';

import Popover from '@material-ui/core/Popover';

import ProtectedButton from "../../../authentication/protected-button"
import QuoteForm from "./quoteForm"

import moment from "moment"
import isMobile from '../../../../HOC/Mobile.js'

import { getQuoteHistory } from '../../../../components/Quote/actions'

import useGtagHook from "../../../../gtag-hook"
import RateNegotiations from './RateNegotiations';

const QuoteButton = (props) => {
    const {
        load,
        index,
        loadList,
        muiTheme,
        key,
        updateQuoteHistory,
        isMobile = false,
        removeLoadFromList,
        isAuthenticated,
        callRefreshCard,
        handleMobileDetailsTabChange = () => { },
    } = props

    const [showQuoteHistory, setShowQuoteHistory] = useState(false)
    const [showAddQuoteBtn, setShowAddQuoteBtn] = useState(true)

    const [quoteAnchorEl, setQuoteAnchorEl] = useState(null)
    const [quotePage, setQuotePage] = useState("form")
    const [called, setCalled] = useState("")
    const [forceRefresh, setForceRefresh] = useState("")

    const [hasQuoteHistory, setHasQuoteHistory] = useState(false)
    const [sendGtagEvent] = useGtagHook()

    useEffect(() => {
        checkForQuoteHistory()
    }, [loadList])

    useEffect(() => {
        if ((showQuoteHistory && !called) || forceRefresh) {
            setCalled(true)
            updateQuoteHistory()
            setForceRefresh(false)
        }
    }, [showQuoteHistory, forceRefresh])

    const checkForQuoteHistory = () => {
        if (load.quotes && load.quotes.length > 0)
            setHasQuoteHistory(true)
    }

    const getLatestStatus = () => {

        let latestOffer = getLatestActiveOffer(load.quotes)

        if (latestOffer) {
            if (latestOffer.message_type === "QUOTE_C" || latestOffer.message_type === "QUOTE_D") {
                return (
                    <p style={{ fontWeight: 'bold' }}>
                        You Offered ${latestOffer.quote_value}
                    </p>
                )

            }
            else if (latestOffer.message_type === "QUOTE_B" && latestOffer.accepted) {
                return (
                    <p style={{ fontWeight: 'bold', color: 'green' }}>
                        Broker Accepted ${latestOffer.quote_value}
                    </p>
                )
            }
            else if (latestOffer.message_type === "QUOTE_B" && latestOffer.rejected) {
                return (
                    <p style={{ fontWeight: 'bold', color: muiTheme.ttRed }}>
                        Broker Rejected ${latestOffer.quote_value}
                    </p>
                )
            }
            else if (latestOffer.message_type === "QUOTE_B") {
                return (
                    <p style={{ fontWeight: 'bold' }}>
                        Broker Offered ${latestOffer.quote_value}
                    </p>
                )
            }
            else
                return null
        }

    }

    const getLatestActiveOffer = (quoteList) => {
        quoteList = sortOfferHistory(quoteList)
        let tmpQuoteList = []
        tmpQuoteList = quoteList.filter(quote => {
            return quote.is_active == 1
        })
        return tmpQuoteList[tmpQuoteList.length - 1]
    }

    const sortOfferHistory = (offerList) => {
        let sortedOfferHistory = offerList.sort((a, b) => {
            return moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
        })
        return sortedOfferHistory
    }

    const getKey = () => {
        if(load.quotes && load.quotes.lenght)
            return "quote_section_" + index + load.quotes.length
        else
            return "quote_section_" + index
    }

    return (
        <div key={getKey()}>
            { hasQuoteHistory && isAuthenticated &&

                getLatestStatus()

            }
            <ProtectedButton
                key={"quote_protected_btn" + key}
                id='quoteButton'
                popoverHeaderText={""}
                popoverBodyText={muiTheme.loginMsg}
                fromPath={"/loads"}
                redirectObj={!isMobile ? null : {
                    pathname: '/loadDetails',
                    state: {
                        load: props.load,
                        // setOpenLoadDetails: setOpenLoadDetails,
                        pickup: props.pickup,
                        delivery: props.delivery,
                        availability: props.availability,
                        isMyLoadsScreen: false, // todo
                        myLoadsRenderedTab: 0, // todo
                        loadDetailsRenderedTab: 2,
                        // removeLoadFromList: removeLoadFromList, // todo
                        latestQuote: props.latestQuote,
                        latestQuoteShowBIN: props.latestQuoteShowBIN,
                        // callRefreshCard: callRefreshCard,
                    }
                }}
                buttonText={hasQuoteHistory && isAuthenticated ? "View Details" : load.isPreviewLoad ? "Show Interest":"Quote"}
                isSmall={true}
                variantContained={false}
                buttonStyle={{
                    color: muiTheme.actionColor,
                    fontSize: 14,
                    padding: 5,
                    textDecoration: 'underline',
                    backgroundColor: 'transparent'
                }}
                callback={(anchor) => {
                    if (isMobile) {
                        if (props.onMobileDetailsTab) {
                            handleMobileDetailsTabChange(true, 2)
                        }
                        else {
                            props.history.push({
                                pathname: '/loadDetails',
                                state: {
                                    load: props.load,
                                    pickup: props.pickup,
                                    delivery: props.delivery,
                                    availability: props.availability,
                                    isMyLoadsScreen: false, // todo
                                    myLoadsRenderedTab: 0, // todo
                                    loadDetailsRenderedTab: 2,
                                    latestQuote: props.latestQuote,
                                    latestQuoteShowBIN: props.latestQuoteShowBIN,
                                }
                            })
                        }
                    }
                    else {
                        setShowQuoteHistory(true)
                        setForceRefresh(true)
                        setQuoteAnchorEl(anchor)
                        if (hasQuoteHistory) {
                            setQuotePage("history")
                        } else {
                            setQuotePage("form")
                        }
                    }

                    sendGtagEvent("view_quote_history", { loadId: load.loadId, brokerId: load.brokerId })
                }}
            />
                <Popover
                id="quote_history"
                open={showQuoteHistory}
                anchorEl={quoteAnchorEl}
                onClose={() => {
                    setQuoteAnchorEl(null);
                    setShowQuoteHistory(false);
                    if (hasQuoteHistory) {
                        setQuotePage("history")
                    } else {
                        setQuotePage("form")
                    }
                    setCalled(false)
                    callRefreshCard()
                }}
                PaperProps={{ style: { width: isMobile ? '100%' : null } }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div style={{ padding: 15, width: isMobile ? '100%' : quotePage == "history" && !showAddQuoteBtn ? 930 : 400, maxHeight: 550, overflow: quotePage == "history" ? 'hidden' : 'auto' }}>

                    {quotePage == "history" && (
                        <RateNegotiations
                            load={load}
                            setShowAddQuoteBtn={setShowAddQuoteBtn}
                            showAddQuoteBtn={showAddQuoteBtn}
                            setQuotePage={setQuotePage}
                            removeLoadFromList={removeLoadFromList}
                            callRefreshCard={callRefreshCard}
                            {...props}
                        />
                    )}

                    {quotePage == "form" && (
                        <QuoteForm
                            _onClose={() => {
                                setQuoteAnchorEl(null)
                                setShowQuoteHistory(false)
                                if (hasQuoteHistory) {
                                    setQuotePage("history")
                                } else {
                                    setQuotePage("form")
                                }
                            }}
                            _forceHistoryRefresh={setForceRefresh}
                            callRefreshCard={callRefreshCard}
                            {...props}
                        />
                    )}
                </div>
            </Popover>
        </div>

    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
    quotesHistory: state.QuoteReducer.get.payload,
    isQuotesLoading: state.QuoteReducer.get.requesting,
    loadList: state.CarrierPortal.search.loadList

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuoteHistory: getQuoteHistory
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    withHandlers({
        updateQuoteHistory: props => () => {
            props.getQuoteHistory([props.load.id], props.carriers, false, props.brokers, props.availabilities) // dont have all of these props
        }
    }),
    withRouter,
    isMobile(),
    muiThemeable(),
)(QuoteButton)