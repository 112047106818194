/*global google*/
import {
  CALL_TMSPARTNER_DATA,
  CALL_TMSPARTNER_DATA_SUCCESS,
  CALL_TMSPARTNER_DATA_ERROR,
  UPDATE_TMSPARTNER_DATA,
  UPDATE_TMSPARTNER_DATA_SUCCESS,
  UPDATE_TMSPARTNER_DATA_ERROR,
} from './constants'

const initialState = {
  requesting: false,
  successful: false,
  payload: [],
  updateResponse: [],
  messages: [],
  errors: [],
  UPDATE_TMSPARTNER_DATA_SUCCESS: false,
}

const reducer = function tmspartnerDataReducer (state = initialState, action) {

  switch (action.type) {
    case CALL_TMSPARTNER_DATA:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Getting your TMS Partner data...', time: new Date() }],
        errors: [],
      }

    case UPDATE_TMSPARTNER_DATA:
      return {
        ...state,
        requesting: true,
        successful: false,
        payload: action.data,
        messages: [{ body: 'Updating your TMS Partner data...', time: new Date() }],
        errors: [],
      }

    case CALL_TMSPARTNER_DATA_SUCCESS:
      return {
        payload: action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,

      }

    case UPDATE_TMSPARTNER_DATA:
      return {
        updateResponse : action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        updateSuccess: true,

      }




    case UPDATE_TMSPARTNER_DATA_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        updateSuccess: false,
      }

    case CALL_TMSPARTNER_DATA_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        successful: false,
      }

      default : return state

  }
}

export default reducer
