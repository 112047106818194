import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import * as Constants from './constants'
import {getLoadUpdates } from '../../api/shipperIntegration'

// This will be run when the LOADUPDATES_REQUESTING
// Action is found by the watcher
function* loadUpdatesFlow (action) {
  try {
    yield put(showLoading())
    const { loadId, eventType, pageNumber, pageSize } = action 
    // here until the API async function, is complete!
    const response = yield call(getLoadUpdates, loadId, eventType, pageNumber, pageSize);
    const { logs } = response;
    const { data, total } = logs || {};
    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADUPDATES_SUCCESS with
    // the successful response.
    yield put({ type: Constants.LOADUPDATES_SUCCESS, loadupdates: data || [], total: total || 0 })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    //TODO

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the self service action types
// WITH the action we dispatched
function* shipperIntegrationLoadUpdateWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(Constants.LOADUPDATES_REQUESTING, loadUpdatesFlow);  
}

export default shipperIntegrationLoadUpdateWatcher