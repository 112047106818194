import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../HOC/Mobile.js'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'

const PaymentInformation = (props) => {
    const classes = useStyles();
    const {
        paymentHistory,
        muiTheme
    } = props



    const formatWord = (param) => {
        if (param && typeof param === "string") {
            param = param.replace("_", " ")

            let words = param.split(" ");

            param = words.map((word) => {
                return word[0].toUpperCase() + word.substring(1);
            }).join(" ");

            return param
        }
        return null
    }

    return (


        <Row key={"payment-history"} style={{ paddingLeft: 25 }}>
            <Row style={{ display: 'flex' }}>
                <h3 style={{margin: 0}}>Payment History</h3>
            </Row>
            {paymentHistory && paymentHistory.length > 0 &&
                <Row>
                    {paymentHistory.map((item, index) =>
                        <Row>
                            <Row style={{ display: 'flex', paddingLeft: 15, paddingRight: 25 }}>

                                <p className={classes.secondaryText} style={{ width: 'min-content', whiteSpace: 'nowrap', overflow: 'visible', marginTop: 16 }}>
                                    ${parseFloat(item.amount).toFixed(2)}
                                </p>

                                <hr style={{ width: '100%', borderTop: '1px dotted grey', marginTop: 32 }} />

                                <p className={classes.secondaryText} style={{ width: 'min-content', whiteSpace: 'nowrap', overflow: 'visible', marginTop: 16 }}>
                                    {moment(item.payment_date).format('ll HH:mm')}
                                </p>
                            </Row>
                           

                        </Row>
                    )}
                </Row>
            }

        </Row>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(PaymentInformation)

const useStyles = makeStyles((theme) => ({

    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        margin: 0,
        marginBottom: -5,
    }


}));