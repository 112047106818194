import { CLIENT_SET, CLIENT_UNSET, PUSHER_CONNECT } from './constants'

export function setClient (token) {  
  return {
    type: CLIENT_SET,
    token    
  }
}

export function unsetClient () {  
  return {
    type: CLIENT_UNSET,
  }
}

export function setPusherConnect (windowEcho) {  
  return {
    type: PUSHER_CONNECT,    
    windowEcho
  }
}