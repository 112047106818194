import React, { Component } from 'react';
import { getELDIntegration, authToken, createELDIntegration, updateELDIntegration,} from './components/api'
import './style.css';
import Button from 'react-bootstrap/lib/Button'
import LoadingOverlay from '../LoadingOverlay'
import { Modal } from 'react-bootstrap';
import queryString from 'query-string';

const BASEURL = 'https://www.truckertools.com'
const isMobile = window.innerWidth <= 500;

export default class keeptruckinConfig extends Component {

  constructor(props) {
    super(props);

    let url = props.location.search;
    let params = queryString.parse(url);

    this.state = {
      loading: true,
      code: params.code?params.code.trim():"",
      paramsCount: Object.keys(params).length,
      isUpdate: false,
      isCreate: false,
      successBoxTxt: '',
      errorBoxTxt: ''
    };

    this.doClick = this.doClick.bind(this);

  }

  componentDidMount() {
    if(this.state.paramsCount == 1 && this.state.code){
      
      const payloadItems = JSON.parse(localStorage.getItem('payloadItems'));

      if(this.state.code && payloadItems && payloadItems.client=="KeepTrucking")
      {
        //var carrierName= payloadItems.carrierName?payloadItems.carrierName.trim():"";
        var carrierMCNumber= payloadItems.carrierMCNumber?"MC"+payloadItems.carrierMCNumber.replace(/[^0-9]/g, ''):"";
        var client= payloadItems.client?payloadItems.client.trim():"";
        //var carrierEmail= payloadItems.carrierEmail?payloadItems.carrierEmail.trim():"";
        //var eldProviderEmail= payloadItems.eldProviderEmail?payloadItems.client.trim():"";
        

          if (client) {

            getELDIntegration(carrierMCNumber).then(resp => {
        
              if (resp.status) {
                this.setState({
                  isUpdate: true,
                  isCreate: false,
                }, () => {
                  document.getElementById("button").click()
                })
              } 
              else {
                this.setState({
                  isUpdate: false,
                  isCreate: true,
                }, () => {
                  document.getElementById("button").click()
                })
              }
              
            }).catch(error => {
              this.setState(state => ({
                errorBoxTxt: <span>Internal system error. Please try again later.</span>,
                loading: false,
              }))
            })

          }
          else { 
            this.setState(state => ({
              errorBoxTxt: <span>Session expired. Please try again.</span>,
              loading: false,
            }))
          }
        
      }
       
    }
    
  }

  doClick() {

    if(this.state.isUpdate || this.state.isCreate){

        if (this.state.code) {

          var payload = JSON.parse(localStorage.getItem('payloadItems'));
          Object.keys(payload).forEach(key => {
            payload[key] = payload[key].trim()
          })
          payload['token'] = this.state.code;

          if (this.state.isUpdate) {

              updateELDIntegration(payload).then(respELD => {
                if (respELD.status)
                  this.setState(state => ({
                    successBoxTxt: <span>Congratulations! 
                    <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
                    <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
                    loading: false,
                  }))
                else
                this.setState(state => ({
                  errorBoxTxt: <span>KeepTruckin Integration error. Please try again later.</span>,
                  loading: false,
                }))
              }).catch(() => {
                this.setState(state => ({
                  errorBoxTxt: <span>Internal system error. Please try again later.</span>,
                  loading: false,
                }))
              })
      
          } else if (this.state.isCreate) {
      
              createELDIntegration(payload).then(respELD => {
                if (respELD.status){
                  this.setState(state => ({
                    successBoxTxt: <span>Congratulations! 
                    <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
                    <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
                    loading: false,
                  }))
                }else{
                  this.setState(state => ({
                    errorBoxTxt: <span>KeepTruckin Integration error. Please try again later.</span>,
                    loading: false,
                  }))
                }
              }).catch(() => {
                this.setState(state => ({
                  errorBoxTxt: <span>Internal system error. Please try again later.</span>,
                  loading: false,
                }))
              })
      
          }

        } 
        else {
          this.setState(state => ({
            errorBoxTxt: <span>KeepTruckin Auth Code is missing/invalid.</span>,
            loading: false,
          }))
        }
    }
    else {
      this.setState(state => ({
        errorBoxTxt: <span>Internal system error. Please try again later.</span>,
        loading: false,
      }))
    }
    
  }

  render() {

    return (

      <div className="wrapper">
        <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'></link>

        {this.state.loading && <LoadingOverlay />}

        {this.state.paramsCount>0 && <header className="navbarMenu">
          <span className="headingLogo" style={{ marginTop: this.state.paramsCount == 0? '40px': '0px'}}><img src={BASEURL + "/wp-content/uploads/2016/11/TT-logo-250x44-1.png"} alt="TruckerTools" /></span>
          <span className="headingTitle" style={{ marginTop: this.state.paramsCount == 0? '40px': '0px'}}>ELD Integration</span>
        </header>}
        <div className="input-group-wrapper center">
            <div className="input-group-all">
              <div className="successBox" style={{ display: this.state.successBoxTxt != ""? 'block': 'none'}}>
              {this.state.successBoxTxt}
              </div>
              <div className="errorBox" style={{ display: this.state.errorBoxTxt != ""? 'block': 'none'}}>
                {this.state.errorBoxTxt}
              </div>
              {(this.state.successBoxTxt=="" && this.state.errorBoxTxt=="") &&<div>
                Loading...
              </div>}
              <button style={{display: 'none'}} id="button" onClick={this.doClick}>&nbsp;</button>  
            </div>
        </div>
        {this.state.paramsCount>0 && <div className="new_login_page" style={{marginTop: '-60px'}}>
				<div className="body_wrap">

					<div className="">

						<div className="page_content_wrap">

							{/* <footer className="site_footer_wrap scheme_dark">
								<div className="footer_wrap widget_area">
									<div className="footer_wrap_inner widget_area_inner">
										<div className="content_wrap">
											<div className="columns_wrap">
												<aside className="column-1_4 widget Social_Widget">
													<a href={BASEURL} id="sc_layouts_logo_96810308" className="sc_layouts_logo sc_layouts_logo_default"><img className="logo_image" src={BASEURL + "/wp-content/uploads/2019/05/TT-logo-white-250w.png"} width="250" height="44" style={{ maxWidth: '100%', height: 'auto' }} /></a>
												</aside>
												<aside className="column-1_4 widget widget_nav_menu">
													<h5 className="widget_title">Browse</h5>
													<div className="menu-footer-container">
														<ul id="menu-footer" className="menu">
															<li id="menu-item-2948" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2948"><a href={BASEURL + "/about-us/"}>About Us</a></li>
															<li id="menu-item-1692" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1692"><a href="https://marketing.truckertools.com/Request-a-Demo">Request a Demo</a></li>
															<li id="menu-item-1690" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1690"><a href={BASEURL + "/resources/"}>Resources</a></li>
															<li id="menu-item-1691" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1691"><a href={BASEURL + "/blog/"}>Blog</a></li>
															<li id="menu-item-2949" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2949"><a href={BASEURL + "/blog/news/"}>News</a></li>
															<li id="menu-item-1708" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1708"><a href={BASEURL + "/carriers"}>Download Mobile App</a></li>
														</ul>
													</div>
												</aside>
												<aside id="acx-social-icons-widget-2" className="column-1_4 widget acx-smw-social-icon-desc"><h5 className="widget_title">Follow Us</h5>
													<div id="acurax_si_widget_simple" className="acx_smw_float_fix widget-acx-social-icons-widget-2-widget" style={{ textAlign: 'left' }}><a href="https://www.facebook.com/TruckerTools/" target="_blank" title="Visit Us On Facebook"><img src={BASEURL + "/wp-content/plugins/acurax-social-media-widget/images/themes/30/facebook.png"} alt="Visit Us On Facebook" /></a><a href="https://www.youtube.com/channel/UC7eM8cNSN6Y0qS8Dx6kj5qQ" target="_blank" title="Visit Us On Youtube"><img src={BASEURL + "/wp-content/plugins/acurax-social-media-widget/images/themes/30/youtube.png"} alt="Visit Us On Youtube" /></a><a href="https://www.linkedin.com/company/trucker-tools/?originalSubdomain=in" target="_blank" title="Visit Us On Linkedin"><img src={BASEURL + "/wp-content/plugins/acurax-social-media-widget/images/themes/30/linkedin.png"} alt="Visit Us On Linkedin" /></a></div>
												</aside>
												<aside className="column-1_4 widget Social_Widget">
													<h5 className="widget_title">Contact Us</h5>
													<div className="textwidget"><p>P. O. Box 3309 Reston, VA 20195<br />
								703 955 3560</p>
														<p>&nbsp;</p>
														<h5 style={{ textAlign: 'left', color: '#ffffff' }}><a style={{ color: '#ffffff' }} href={"/termsConditions/"}>Terms and Conditions</a></h5>
													</div>
												</aside>
											</div>

										</div>

									</div>

								</div>


								<div className="copyright_wrap  scheme_">
									<div className="copyright_wrap_inner">
										<div className="content_wrap">
											<div className="copyright_text">Trucker Tools &copy; 2021. All rights reserved.</div>
										</div>
									</div>
								</div>

							</footer> */}


						</div>


					</div>


				</div>
			</div>}
      </div>
    );
  }
}