import { WernerAuthService } from "../customers/a98db973/authentication/auth-service"
import { BlueGraceAuthService } from "../customers/ju9agnj4/authentication/auth-service"
import { EnglandLogisticsAuthService } from "../customers/dd4udt1b/authentication/auth-service"
import { ReedTMSAuthService } from "../customers/r85xb5y8/authentication/auth-service"
import { RyderMSAuthService } from "../customers/srukfk5e/authentication/auth-service"
import { KingsgateLogisticsAuthService } from "../customers/k6g3f58q/authentication/auth-service"
import { WorldwideExpressAuthService } from "../customers/wesog299/authentication/auth-service"
import { SmartLogisticsAuthService } from "../customers/32m9lbe4/authentication/auth-service"
import { CHRobinsonAuthService } from "../customers/ch7h4g68r/authentication/auth-service"

import {BaseAuthService} from "../authentication/check-auth"


export const useAuthenticationHook = () =>{
    switch (process.env.REACT_APP_ADVANTAGE_ID) {
        case "a98db973":
            return WernerAuthService()
            break
        case "ju9agnj4":
            return BlueGraceAuthService()
            break
        case "dd4udt1b":
            return EnglandLogisticsAuthService()
            break
        case "r85xb5y8":
            return ReedTMSAuthService()
            break
        case "srukfk5e":
            return RyderMSAuthService()
            break
        case "k6g3f58q":
            return KingsgateLogisticsAuthService()
            break
        case "wesog299":
            return WorldwideExpressAuthService()
            break
        case "32m9lbe4":
            return SmartLogisticsAuthService()
            break
        case "ch7h4g68r":
            return CHRobinsonAuthService()
            break
        default:
            return BaseAuthService()
            break
    }
}