// WORLDWIDE EXPRESS THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#1c9dd4',
    secondary: '#f56600',
    actionColor: "#1c9dd4",
    lightContrastText: '#fff',
    darkContrastText: '#000',
    useDarkContrastText: false,
    tagLine:'Carriers, Capacity, Convenience, Check!',
    menuHighlightColor: "rgba(0, 0, 0, 0.3)",
    useWhiteBackgroundBehindLogo: false,
    customerUniqueId: "wesog299",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Roboto",
    loginMsg: "Please login in order to use this feature",
    source: "worldwide_express"
});
//console.log(theme);
export default theme