import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Rating from '@material-ui/lab/Rating';

import { API_PROD_URL } from "../../../../../api/constants"
// import { mockRestaurants } from "./mock-restaurants"
import { Row, Col } from 'react-bootstrap';
import { checkValueExists } from '../../../../../lib/check-value-exists';

const Restaurants = (props) => {

    const { item, muiTheme, isMobile = false } = props
    const useStyles = makeStyles({
        container: {
            width: "100%",
            height: !isMobile ? 180 : "max-content",
            border: '1px solid grey',
            padding: 10,
            overflowX: "auto",
            overflowY: "auto"
        },
        mainText: {
            fontSize: '14px',
            lineHeight: '20px',
            color: 'black',
            wordBreak: 'break-word'
        },
        secondaryText: {
            fontSize: '13px',
            lineHeight: '20px',
            color: 'grey',
            wordBreak: 'break-word'
        },
        labelText: {
            fontSize: '14px',
            color: 'grey'
        },
        valueText: {
            wordBreak: 'break-word',
            fontSize: '14px',
            color: 'black'
        },
    });
    const classes = useStyles();

    const [restaurantData, setRestaurantData] = useState(null)

    // FEATHER
    // const getIcon = (description) => {
    //     switch (description) {
    //         case 'sky is clear':
    //             return <FeatherIcon.Sun color={"orange"} size={30} />
    //         case 'clear sky':
    //             return <FeatherIcon.Sun color={"orange"} size={30} />
    //         case 'few clouds':
    //             return <FeatherIcon.Cloud color={"darkgrey"} size={30} />
    //         case 'overcast clouds':
    //             return <FeatherIcon.Cloud color={"darkgrey"} size={30} />
    //         case 'scattered clouds':
    //             return <FeatherIcon.Cloud color={"darkgrey"} size={30} />
    //         case 'broken clouds':
    //             return <FeatherIcon.Cloud color={"darkgrey"} size={30} />
    //         case 'shower rain':
    //             return <FeatherIcon.CloudRain color={"steelblue"} size={30} />
    //         case 'rain':
    //             return <FeatherIcon.CloudRain color={"steelblue"} size={30} />
    //         case 'light rain':
    //             return <FeatherIcon.CloudRain color={"steelblue"} size={30} />
    //         case 'thunderstorm':
    //             return <FeatherIcon.CloudLightning color={"darkgrey"} size={30} />
    //         case 'snow':
    //             return <FeatherIcon.CloudSnow color={"lightskyblue"} size={30} />
    //         case 'mist':
    //             return <FeatherIcon.CloudRain color={"steelblue"} size={30} />
    //     }
    // }

    const getDestinationRestaurants = async () => {
        let radius = 500
        try {
            const GET_RESTAURANTS_URL = "/poi/getRestaurants?latitude=" + item.latitude + "&longitude=" + item.longitude + "&radius=" + radius
            let url = API_PROD_URL + GET_RESTAURANTS_URL
            let requestObject = {
                method: 'GET',
            }
            fetch(url, requestObject)
                .then(response => {
                    if (!response.ok) {
                        const error = new Error(response.statusText);
                        error.response = response;
                        throw error;
                    }
                    response.json().then(jsonData => {
                        // console.log("RESTAURANTS: ", jsonData)
                        setRestaurantData(jsonData.results)
                        // return jsonData
                    })
                })
                .catch(error => {
                    // console.log("RESTAURANTS ERROR: ", error)
                })
        }
        catch (e) {
            // console.log("weather error: ", e)
        }
    }

    useEffect(() => {
        getDestinationRestaurants()
    }, [])

    const restaurantRow = (restaurant, index) => {
        return (
            <div style={{ paddingBottom: 5, borderBottom: index != restaurantData.length - 1 ? '1px solid grey' : 'none', marginBottom: 5, display: "flex" }} key={restaurant.place_id}>
                <Col style={{ width: "15%" }} >
                    <img src={restaurant.icon} width={30} height={30} />
                </Col>
                <Col style={{ width: "85%" }} >
                    <p>{restaurant.name}</p>
                    <p>{restaurant.vicinity}</p>
                    {checkValueExists(restaurant, "opening_hours") &&
                        checkValueExists(restaurant.opening_hours, "open_now") &&
                        restaurant.opening_hours.open_now == true && (
                            <p>OPEN NOW</p>
                        )}
                    {checkValueExists(restaurant, "rating") && (
                        <div style={{ display: "flex" }}>
                            <p>{restaurant.rating}</p>
                            <span style={{ display: "flex", alignSelf: "center", marginLeft: 5 }}>
                                <Rating name="half-rating-read" defaultValue={restaurant.rating} precision={0.5} readOnly />
                            </span>
                        </div>
                    )}
                </Col>
            </div>
        )
    }

    if (restaurantData == null || (Array.isArray(restaurantData) && restaurantData.length == 0)) {
        return null
    } else {
        return (
            <div onClick={(event) => event.stopPropagation()}>
                <div style={{ height: 15, marginBottom: 5 }}>
                    <p className={classes.labelText} style={{ textAlign: "center" }}>Restaurants</p>
                </div>
                <div className={classes.container}>
                    {restaurantData.map((restaurant, index) => (
                        restaurantRow(restaurant, index)
                    ))}
                </div>
            </div>
        )
    }
}

export default compose(
    connect(),
    muiThemeable()
)(Restaurants)