import React from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row, Grid } from "react-bootstrap"
import Button from '@material-ui/core/Button';

import moment from 'moment'

import FuelStopExpandedContent from "./expanded-content/fuel-stop-expanded-content"

import { checkValueExists } from "../../../../lib/check-value-exists"

import {useAuthenticationHook} from '../../../hooks/authentication-hook';

const FuelStopCardDesktop = (props) => {
    const classes = useStyles();
    const {
        item,
        index,
        dropDownIcon,
        isExpanded,
        toggleExpanded,
        searchLocation
    } = props

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    // const user = myAuthService.getUser()

    const getDropDownIcon = () => {
        return <FA name={dropDownIcon} size='2x' />
    }

    const getImgSrc = () => {
        switch (item.franchise_name) {
            case "TA":
                return require("../fuel-stop-icons/ta-map-pin.svg") 
            case "Loves":
                return require("../fuel-stop-icons/loves-map-pin.svg") 
            default:
                return require("../fuel-stop-icons/default-map-pin.svg") 
        }
    }


    return (
        <div style={{ width: "100%" }}>
            <Row style={{ display: 'flex' }}>
                <Col style={{ width: '6%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                    <Button
                        key={dropDownIcon + index}
                        onClick={toggleExpanded}
                        style={{ backgroundColor: 'transparent', justifyContent: 'flex-start' }}
                        disableRipple={true}
                    >
                        {getDropDownIcon()}
                    </Button>
                </Col>
                <Col style={{ width: '6%', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                    <img src={getImgSrc()} height={60} />
                </Col>
                <Col style={{ width: '5%' }} />
                <Col style={{ width: "20%", justifyContent: "center", alignItems: "flex-start" }}>
                    <Row>
                        <p className={classes.mainText} >
                            {item.name}
                        </p>
                    </Row>
                    <Row>
                        <p className={classes.secondaryText}>
                            {item.address}
                        </p>
                    </Row>
                    <Row>
                        <p className={classes.secondaryText}>
                            {item.city + ", " + item.state}
                        </p>
                    </Row>
                </Col>
                <Col style={{ width: "18%", justifyContent: "center", alignItems: "flex-start" }}>
                    <Row style={{ display: "flex" }}>
                        <p className={classes.secondaryText} style={{ marginRight: 5 }}>
                            Retail Price:
                        </p>
                        <p className={classes.mainText} >
                            {parseFloat(item.metadata.price.diesel).toFixed(2)}
                        </p>
                    </Row>
                    {isAuthenticated && checkValueExists(item, "discountedFuelPrice") && (
                        <Row style={{ display: "flex", marginTop: 10 }}>
                            <p className={classes.secondaryText} style={{ marginRight: 5 }}>
                                EL Price:
                            </p>
                            <p className={classes.discountText} >
                                {parseFloat(item.discountedFuelPrice).toFixed(2)}
                            </p>
                        </Row>
                    )}
                    <p className={classes.secondaryText} style={{fontSize: 12, fontStyle:'italic'}}>
                        <span style={{fontWeight:'bold'}}>Last Updated:</span> { (item.metadata.price.price_date && item.metadata.price.price_date != "") ? moment(item.metadata.price.price_date).format('ll HH:mm') : 'N/A'}
                    </p>
                </Col>
                <Col style={{ width: "15%", display: 'flex', justifyContent: "center", alignItems: "flex-start" }}>
                    {/* <p className={classes.secondaryText}>
                        Pumps:
                    </p>
                    <p className={classes.mainText} >
                        {item.metadata.price.diesel}
                    </p> */}
                </Col>
                <Col style={{ width: "15%", display: 'flex', justifyContent: "center", alignItems: "flex-start" }}>
                    {/* <p className={classes.secondaryText}>
                        Parking:
                    </p>
                    <p className={classes.mainText} >
                        {item.metadata.price.diesel}
                    </p> */}
                </Col>
                <Col style={{ width: "15%", justifyContent: "center", alignItems: "flex-start" }}>
                    <Row>
                        <p className={classes.secondaryText}>
                            Distance:{" "}
                        </p>
                    </Row>
                    <Row>
                        <p className={classes.secondaryText} >
                            approx {item.metadata.distance} mi
                    </p>
                    </Row>
                </Col>
            </Row>
            {isExpanded &&
                <FuelStopExpandedContent
                    item={item}
                    index={index}
                    searchLocation={searchLocation}
                    icon={getImgSrc()}
                />
            }
        </div>
    );
}


export default compose(
    muiThemeable()
)(FuelStopCardDesktop)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '18px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '18px',
        color: 'grey',
        margin: 0
    },
    discountText: {
        fontSize: '18px',
        lineHeight: '18px',
        color: 'green',
        fontWeight: 'bold',
        margin: 0
    },
});