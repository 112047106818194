
import React, { useState } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Col, Row, Grid } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import { toast } from 'react-toastify';
import Popover from '@material-ui/core/Popover';

import RoleSelect from "./role-selector"


const EditRoleBtn = (props) => {
    const classes = useStyles();
    const { muiTheme, item, onEditComplete, mobile = false } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [updatedRole, setUpdatedRole] = useState(null);


    const handleClick = (event, tag) => {
        setShowPopup(true)
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setShowPopup(false)
    };

    const handleSaveChanges = () => {
        if (updatedRole == null || updatedRole == item.role) {
            toast.error("Please select a new role for " + item.email)
        } else {
            onEditComplete(updatedRole)
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'edit-user-role-popover' : undefined;

    return (
        <div style={{ display: "block" }}>
            <Button
                aria-describedby={id}
                onClick={handleClick}
            >
                <FeatherIcon.Edit2 color={muiTheme.ttRed} size={mobile ? 22 : 14} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                // onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div style={{ padding: 15 }}>    
                
                    { (item.firstName && item.lastName) && 
                        <p className={classes.mainText}>{item.firstName + " " + item.lastName}</p>}
                    { (item.email) && 
                        <p className={classes.mainText}>{item.email}</p>}

                    <div style={{ marginTop: 20, marginBottom: 25, display: "flex", alignItems: "flex-end" }}>
                        <p className={classes.mainText} style={{ marginRight: 10, marginBottom: 5 }}>Role: </p>
                        <RoleSelect existingRole={item.role} handleSetRole={(role) => setUpdatedRole(role)} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <Button
                            size="small"
                            style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "transparent", maxHeight: '40px' }}
                            onClick={() => { handleClose() }}
                        >
                            CANCEL
                            </Button>
                        <Button
                            variant="contained"
                            size="small"
                            style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, maxHeight: '40px' }}
                            onClick={() => { handleSaveChanges() }}
                        >
                            SAVE CHANGES
                            </Button>
                    </div>
                </div>
            </Popover>
        </div>
    )
}


export default compose(
    muiThemeable()
)(EditRoleBtn)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        // fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '11px',
        lineHeight: '22px',
        color: 'grey'
    }
});