const initialState = {
  requesting: false,
  successful: false,
  payload:[],
  messages: [],
  errors: [],
}

const reducer = function loginReducer (state = initialState, action) {
  switch (action.type) {
    case 'CALL_CARRIER_MATCHES_AND_AVAILABILITIES':
      var retValue = {
        ...state,
        requesting:true,
        successful:false
      }
      return retValue;
    case 'CALL_CARRIER_MATCHES_AND_AVAILABILITIES_SUCCESS':
      var retValue = {
        ...state,
        requesting:false,
        successful:true
      }
      return retValue;
    case 'CALL_CARRIER_MATCHES_AND_AVAILABILITIES_ERROR':
      var retValue = {
        ...state,
        requesting:false,
        successful:false
      }
      return retValue;

    default:
      return state
  }
}

export default reducer
