import * as Constants from '../constants'

const reducer =  (state, action) => { 
      switch(action.type) {        
        case Constants.EDIT_INTEGRATION_SUCCESS:
          return state;       
        default:
          return state;
      }    
}

export default reducer