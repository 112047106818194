import { faKey } from "@fortawesome/free-solid-svg-icons";
import { checkValueExists } from "./check-value-exists";

const ALL_ADVANTAGE_IDS = [
    "2zr5ys19", // general
    "a98db973", // werner
    "32m9lbe4", // smart logistics
    "r85xb5y8", // reed tms
    "k6g3f58q", // kingsgate
    "ju9agnj4", // blue grace
    "dd4udt1b", // england
    "c0419cm1", // crst
    "ch7h4g68r", // ch robinson
    "srukfk5e", // ryder ms
    "wesog299" // wwex
]

/**
 * Function used initially to check if 'weight' exists to determine if the BIN button should be displayed.
 *  Now expanded for multiple data elements 
 * 
 * @param {*} data The load details themself
 * @param {*} keys list of load details by key to determine if each data element is valid
 * @returns true or false determining whether the BIN button should be displayed
 */

export const validLoadDetails = (data, keys) => {

    let validityCheck = true;

    let checkDataAdvantageIds = [ "a98db973" ]
    if (checkDataAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {

        keys.forEach(key => {
            if(!checkValueExists(data, key)){
                validityCheck = false
            }
        });

    }

    return validityCheck

}