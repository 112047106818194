import {BACKEND_URL} from './constants.js'
import moment from 'moment'
export function createLoadInterest(loadId,matchId){
  var requestUrl=BACKEND_URL+'/api/createInterest'
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                            body:JSON.stringify({loadId:loadId,matchId:matchId})
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function getOutOfNetworkLoads(availabilityId){
  var requestUrl = BACKEND_URL+'/carrier/getPriorityLoads?availabilityId='+availabilityId+'&includeOutOfNetwork=true&includeInNetwork=false'
  return fetch(requestUrl,{
                      method: 'GET',
                      headers: {
                        'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                          'Content-Type': 'application/json',
                      }
                    })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function getLoadDetails(loadId){
  var requestUrl = BACKEND_URL+'/load/'+loadId
  return fetch(requestUrl,{
                      method: 'GET',
                      headers: {
                        'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                          'Content-Type': 'application/json',
                      }
                    })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function getLoads(marketId,withAvails,start,end,highConfidenceOnly){
  var requestUrl = BACKEND_URL+'/broker/loads?'
  if(marketId){requestUrl+="&marketId="+marketId}
  if(withAvails){requestUrl+="&withAvailabilities="+withAvails}
  if(start){requestUrl+="&start="+moment(start).format('MM/DD/YYYY')}
  if(end){requestUrl+="&end="+moment(end).format('MM/DD/YYYY')}
  if(highConfidenceOnly!=undefined){requestUrl+="&highConfidenceOnly="+highConfidenceOnly}
  return fetch(requestUrl,{
                      method: 'GET',
                      headers: {
                        'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                          'Content-Type': 'application/json',
                      }
                    })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}
