import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import * as Constants from './constants'
import {getCredentials, credentialsSave } from '../../api/shipperIntegration'

// This will be run when the CREDENTIALS_REQUESTING
// Action is found by the watcher
function* credentialsGetFlow (action) {
  try {
    yield put(showLoading())
    const { brokerId } = action 
    // here until the API async function, is complete!
    const response = yield call(getCredentials, brokerId);
    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    yield put({ type: Constants.CREDENTIALS_SUCCESS, response  })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    //TODO

  }
  finally{
    yield put(hideLoading())
  }
}

// This will be run when the CREDENTIALS_SAVE_REQUESTING
// Action is found by the watcher
function* credentialsSaveFlow (action) {
  try {
    yield put(showLoading())
    const { isAdd, formValues } = action 
    // here until the API async function, is complete!
    const response = yield call(credentialsSave, isAdd, formValues);    
    const { lt_customer_id } = response;
    const {integrationType } = formValues;
    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    yield put({ type: Constants.CREDENTIALS_SAVE_SUCCESS, lt_customer_id: lt_customer_id || formValues.lt_customer_id, integrationType, isAdd })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    //TODO

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the self service action types
// WITH the action we dispatched
function* credentialsWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(Constants.CREDENTIALS_REQUESTING, credentialsGetFlow);  
  yield takeLatest(Constants.CREDENTIALS_SAVE_REQUESTING, credentialsSaveFlow);  
}

export default credentialsWatcher