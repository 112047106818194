import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
//COMPONENTS//
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import HelpWrapper from '../../HelpWrapper'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {useAuthenticationHook} from '../../../hooks/authentication-hook';
//ACTIONS//

const BACKEND_URL = `${process.env.REACT_APP_SC_URL}`; // move this to call to sagas file
// const BACKEND_URL = 'https://scapi-staging.truckertools.com' // temp data url from Gairik 


export const canShowBrokers = () => {
  let visible = false;
  let visibilityAdvantageIds = [ "2zr5ys19" ]
  if( visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID) ){
    visible = true
  }
  return visible
}

const BrokerSelect = ({
  //PROPS FROM PARENT//
  value = [], showErrorOnEmpty = true,
  hintText = 'Pick any truck brokers you are interested in ',
  floatingLabelText = 'Truck Brokers',
  needsRedux = true,
  handleSetBroker,
  clearData,
  existingBrokers = null,
  //REDUX//
  selectedBrokers,
  updateSearchbox,
  //STATE
  //brokersList,
  //OTHER
  muiTheme,
  mobileView = false
}) => {

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.08,
      margin: 10
    },
    wrapper: {
      display: 'contents',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    placeholder: {
      color: '#757575',
    },
    menuItem: {
      fontSize: '16px'
    },
    selectfieldStyle: {
      border: '1px solid #ccc',
      height: '45px',
      width: '100%',
      maxWidth: mobileView ? null : '200px',
      minWidth: '200px',
      margin: '0px',
      overflow: 'visible',
      backgroundColor: 'white',
      // outline: '1px solid black',
      paddingLeft: '10px',
      fontSize: '16px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
      textAlign: 'right'
    },
    chips: {
      fontSize: '12px'
    },
    removePadding: {
      paddingRight: '0 !important'
    }
  });
  const classes = useStyles();

  const [hasError, setErrors] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [finalSelectedBrokersList, setFinalSelectedBrokersList] = useState([]); // Array of selected truck brokers
  const [menuOpen, setMenuOpen] = useState(false);
  const isMounted = useRef(false);

  const myAuthService = useAuthenticationHook()

  useEffect(() => {
    getBrokers();

    if (existingBrokers) {
      let tmpList = existingBrokers.map(broker => { return broker.brokerId })
      setFinalSelectedBrokersList(tmpList)
    }
    return () => {
      setBrokers([]);
    }
  }, []);

  useEffect(() => {
    if (existingBrokers) {
      let tmpList = existingBrokers.map(broker => { return broker.brokerId })
      setFinalSelectedBrokersList(tmpList)
    }
  }, [existingBrokers]);

  useEffect(() => {
    if (isMounted.current) {
      setFinalSelectedBrokersList([])
      handleSetBroker([])
    } else {
      isMounted.current = true
    }

  }, [clearData])

  async function getBrokers() {
    
    let url = '/api/carrier/getBrokers'
    var requestUrl = BACKEND_URL + url
    let requestObject = {
      method: 'GET',
    }
    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

    const res = await fetch(requestUrl, newRequestObject);

    res.json().then(response => {
      if (response.status) {
        let brokersRes = response.brokers
        setBrokers(brokersRes);
      }
    }).catch(error => setErrors(error));
    
  }


  function handleChange(event) {

    const { value } = event.target;
    let chosenBrokers = value
    finalSelectedBrokersList.forEach(broker => {
      if (broker.brokerId != null)
        chosenBrokers.push(broker.brokerId)
    })

    let brokersSelected = brokers.filter((broker) => {
      return chosenBrokers.indexOf(broker.brokerId) >= 0
    })

    let finalSelection = []
    brokersSelected.forEach(broker => {
      if (broker.brokerId != null)
        finalSelection.push(broker.brokerId)
    })
    setFinalSelectedBrokersList(finalSelection);
    handleSetBroker(brokersSelected)

  };

  function renderSelectedBrokers(selected) {

    let result = idsToNames(selected)

    return (
      (selected.length === 0) ?
        <div className={classes.placeholder}>Select Equipment</div> :
        // <div className={classes.wrapper}>
        //   {result}
        // </div>
        result.split(",").map((value) => (
          <Chip 
            variant="outlined" 
            key={value}
            label={value}
            className={classes.chips}
          />
        )) 
    )
  }

  function idsToNames(ids) {
    let brokerArr = brokers.filter((broker) => ids.includes(broker.brokerId));

    let brokerNamesArr = brokerArr.map(broker => broker.name)

    if (brokerNamesArr) {
      return brokerNamesArr.join(",");
    }
    return null;
  }

  function getBrokerNames(brokers) {

    let tmpList = []
    brokers.forEach(broker => {
      if (broker['brokerId'] != null)
        tmpList.push(broker.brokerId)
      else if (typeof  broker === 'string')
        tmpList.push(broker)
    })

    let brokerArr = brokers.filter((broker) => tmpList.includes(broker.brokerId));

    let brokerNamesArr = brokerArr.map(broker => broker.name)
    if (brokerNamesArr) {
      return brokerNamesArr.join(", ");
    }
    return null;
  }
 
  return (
    <HelpWrapper text='Select your brokers so we can find loads that match. (Click anywhere outside once finished)' location='bottom' zIndex={1350} enabled={!menuOpen} isMultiSelect={true}>
      { brokers &&
        <FormControl variant="filled" className={classes.formControl} fullWidth required >
        <InputLabel style={{fontSize:16}} id="location">Select Brokers</InputLabel>
        <Select
          labelId="select-label"
          multiple
          value={finalSelectedBrokersList}
          onChange={(event) => handleChange(event)}
          renderValue={(selected) => renderSelectedBrokers(selected)}
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
        >
          {brokers && brokers.map((broker) => {
            return (
              <MenuItem
                key={broker.brokerId}
                className={classes.menuItem}
                value={broker.brokerId}
              >
                {/* {broker.name} */}
                <Checkbox checked={finalSelectedBrokersList.indexOf(broker.brokerId) > -1} />
                {broker.name}
              </MenuItem>
            )
          })}
        </Select>
        </FormControl>
      }
    </HelpWrapper>
  );
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

const connected = connect(mapStateToProps, matchDispatchToProps)(BrokerSelect);

export default connected;
