import * as MESSAGES from '../constants'

const initialState = {
  requesting: false,
  successful: false,
  data:false,
  filteredCapacity:[]
}

const MatchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_AVAILABILITY_REQ':
      var retValue = {
        ...state,
        requesting:true
      }
      return retValue
    case MESSAGES.CARRIER_MATCHES_REQ:
      var retValue = {
        ...state,
        requesting:true
      }
      return retValue
      case 'CARRIER_MATCHES_SUC':
        //console.log(action)
        var retValue = {
          ...state,
          data:action.payload,
          requesting:false,
          successful:true
        }
        return retValue
      case MESSAGES.CARRIER_MATCHES_ERR:
        var retValue = {
          ...state,
          requesting:false,
          successful:false
        }
        return retValue
      // case "UPDATE_AVAILABILITY_SUC":
      //   var retValue={
      //     ...state
      //   }
      //   var availabilityId = action.updateValues.availabilityId
      //   var newDrivers = []
      //   retValue.data.drivers.map((driver)=>{
      //     var newDriver = driver
      //     var newDriverAvailabilities = []
      //     driver.driver_availabilities.map((availability)=>{
      //       if(availability.id!=availabilityId){
      //         newDriverAvailabilities.push(availability)
      //       }else{
      //         var editedAvailability = Object.assign(availability,action.updateValues)
      //         newDriverAvailabilities.push(editedAvailability)
      //       }
      //     })
      //     newDriver.driver_availabilities=newDriverAvailabilities
      //     newDrivers.push(newDriver)
      //   })

      case 'UPDATE_FILTERED_DRIVER':
        var retValue = {
          ...state,
          filteredCapacity:action.values,
          requesting:false,
          successful:true
        }
        return retValue

    default:
      return state
  }
}

export default MatchesReducer




export function updateFilterDrivers(componentName,values){
  return{
    type:'UPDATE_FILTERED_DRIVER',
    componentName,
    values
  }
}
