const initialState = {
  requesting: false,
  successful: false,
  payload:{},
  messages: [],
  errors: [],
}

const reducer = (state = initialState, action)=>{
  switch (action.type) {
    case 'CARRIER_SIGNUP_REQ':
      var retValue = {
        ...state,
        requesting:true,
        successful:false,
        payload:{},
      }
      return retValue
    case 'CARRIER_SIGNUP_SUC':
      var retValue = {
        ...state,
        requesting:true,
        successful:false,
        payload:{},
      }
      return retValue
    case 'CARRIER_SIGNUP_ERR':
      var retValue = {
        ...state,
        requesting:true,
        successful:false,
        payload:{},
      }
      return retValue

    default:
      return state
  }
}

export default reducer


export function carrierSignup(firstName,lastName,companyName,mc,dot,email,password,favouriteBrokers){
	return{
		type: 'CARRIER_SIGNUP_REQ',
    firstName,
    lastName,
    companyName,
    mc,
    dot,
    email,
    password,
    favouriteBrokers,
	}
}
