import {BACKEND_URL} from './constants.js'




export function postConfirmRegistration(){
  console.log('postConfirmRegistration');

  var requestUrl=BACKEND_URL+'/carrier/confirmRegistration';

  
  return fetch(requestUrl,{
                            method: 'PUT',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },


                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
