// import {useAuthenticationHook} from '../CarrierPortal/hooks/authentication-hook.js';
import {BACKEND_URL} from './constants.js'
export function getTruckTypes(){
  // const myAuthService = useAuthenticationHook()
  let requestObject = {
    method: 'GET'
  }
  // let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
  var requestUrl=BACKEND_URL+'/api/getTruckTypes'
  return fetch(requestUrl,requestObject)
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function getTruckCodes(){
  var requestUrl=BACKEND_URL+'/api/getTruckCodes'
  return fetch(requestUrl,{
                            method: 'GET'
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
