import React from 'react';
import {bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import {withState,compose} from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import '../../components/style.css'
//COMPONENTS//
//HOC//
  import Mobile from '../../HOC/Mobile'

//ACTIONS//




const COMPONENT_NAME = ({
  //PROPS FROM PARENT//
  
  //REDUX//

  //STATE

  //OTHER
  muiTheme,
  isMobile
}) => {
    return (
      <div className={'LoadingOverlay_full'}>
        <span style={{fontSize:24}}>Loading...</span>
        <br/>
        <i className="fa fa-spinner fa-5x fa-fw fa-spin" aria-hidden="true"></i>
      </div>
    )
}

COMPONENT_NAME.propTypes={
  //label:PropTypes.string.isRequired
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch){
  return  bindActionCreators({
  },dispatch)
}

export default compose(
  connect(mapStateToProps,matchDispatchToProps),
  muiThemeable(),
  Mobile(),
  //withState('showSearchbox','toggleSearchbox',true)
)(COMPONENT_NAME)
