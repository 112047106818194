//SYSTEM
  import { call, put, takeLatest } from 'redux-saga/effects'

//APIs
  import {carrierLaneHistoryApi} from '../../api/carrierLaneHistory'

function* carrierHistoryFlow(action){
  try {

    const response = yield call(carrierLaneHistoryApi, action.carrierId)
    if(response.status){
      yield put({type:"CARRIER_HISTORY_SUC",payload:response.data})
    }

  } catch (error) {
      yield put({type:"CARRIER_HISTORY_ERR", message:error})
      console.log(error)

  }
}

// Define what messages the saga should listen to and what function to call when the message is heard. Function names should end with "flow" term.
function* templateWatcher () {
  yield takeLatest("CARRIER_HISTORY_REQ", carrierHistoryFlow)
}

export default templateWatcher
