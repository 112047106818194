/*global google*/
import React, {Component } from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {withState,compose} from 'recompose';
  import PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete';
import TextField from '@material-ui/core/TextField';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import FA from 'react-fontawesome';


// Copied from src\components\GoogleAddressAutoComplete.js for Carrier Portal
class GoogleAddressAutoComplete extends Component {
  constructor(props) {
      super(props);
      this.state = {
          value : this.props.value?this.props.value.trim():'',
          floatingLabelText : this.props.floatingLabelText?this.props.floatingLabelText:'',
          helpText : this.props.helpText?this.props.helpText:'Enter address here',
          helpTextLocation : this.props.helpTextLocation?this.props.helpTextLocation:'right',
          maxSearchResults : this.props.maxSearchResults?this.props.maxSearchResults:4,
          errorText : this.props.errorText?this.props.errorText:'',
          fullWidth : this.props.fullWidth?this.props.fullWidth:true,
          onBlurPredict : this.props.onBlurPredict?this.props.onBlurPredict:false,
          showHelpText : this.props.showHelpText?this.props.showHelpText:false,
          predictAddresses : [],
          types : this.props.types?this.props.types:[],
          restrictions : this.props.restrictions?this.props.restrictions:{},
          hintText : this.props.hintText?this.props.hintText:'Address',
          needHintText : this.props.needHintText?this.props.needHintText:false,
          textFieldStyle:this.props.textFieldStyle?this.props.textFieldStyle:{},
          placeholder:this.props.placeholder?this.props.placeholder:""

      }
      this.callPrdictions = this.callPrdictions.bind(this);
      this.updateSearchText = this.updateSearchText.bind(this);
      this.callbackAddressList = this.callbackAddressList.bind(this);
      this.handleAddressSelect = this.handleAddressSelect.bind(this);
  };

  componentDidMount(nextProps=null){
    let newState=this.state
    try{
      if(nextProps.value != this.props.value){
        newState.value=nextProps.value
        this.callPrdictions(newState.value)
      }

      if(nextProps.errorText != this.props.errorText){
        newState.errorText=nextProps.errorText
      }
    }catch(e){
      console.log('GoogleAddressAutoComplete nextProps exception',e);
    }
    this.setState(newState)
  }

  componentWillReceiveProps(nextProps){
    let newState=this.state
    try{
      if(nextProps.value != this.props.value){
        newState.value=nextProps.value
        this.callPrdictions(newState.value)
      }

      if(nextProps.errorText != this.props.errorText){
        newState.errorText=nextProps.errorText
      }
    }catch(e){
      console.log('GoogleAddressAutoComplete nextProps exception',e);
    }
    this.setState(newState)
  }

  updateSearchText = (value) => {
    this.setState({value:value})
    this.props.onChangeCallBack(value)
  };


  callbackAddressList = (predictions,status )=>{
    //console.log('GoogleAddressAutoComplete predictions',predictions);
    if(status === "OK"){
      //this.setState({predictAddresses:[]})
      var arryTmp = []
      for(var i=0;i<predictions.length;i++){
        var obj  = {
          description:predictions[i].description,
          place:predictions[i],
        }
        arryTmp.push(obj)
      }
      this.setState({predictAddresses:arryTmp})
    }
    else{
      this.setState({predictAddresses:[]})
    }
  }




  callPrdictions = (typedAddress) => {
    if(typedAddress !== null && typedAddress !== ''){
      var google = window.google
      var service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({
                                    types :  this.state.types,
                                    componentRestrictions : this.state.restrictions ,
                                    input: typedAddress
                                  }, this.callbackAddressList);
    }
    else{
      this.setState({predictAddresses:[]})
    }
  };


   handleAddressSelect = (selectedAddress,isSelected) => {
      this.setState({value:selectedAddress.description})
      var payload ={
          address:false,
          city:false,
          state:false,
          zipCode:false,
          country:false,
          formattedAddress:this.state.value,
          formattedAddressOriginal:selectedAddress.description?selectedAddress.description:false,
          lat:null,
          lon:null,
          place:selectedAddress.place?selectedAddress.place:false,
        }
      let placeId = selectedAddress.place.place_id;
      var google = window.google
      var geocoder = new google.maps.Geocoder
      geocoder.geocode(
        {'placeId': placeId},
        (location)=>{
          if(location.length>0){
              //console.log(location,location[0].geometry.location.lat(),location[0].geometry.location.lng())

              var vicinity     = location[0].vicinity?location[0].vicinity:'';

              var city = location[0].address_components.filter(
                  (component)=>{
                    if(component.types.indexOf("locality")>=0)
                      return component.short_name
                  }
                );
              if(city.length===0){
                var city = location[0].address_components.filter(
                  (component)=>{
                    if(component.types.indexOf("administrative_area_level_3")>=0)
                      return component.long_name
                  }
                );
              }


              if(city.length===0){
                var city = location[0].address_components.filter(
                  (component)=>{
                    if(component.types.indexOf("sublocality_level_1")>=0)
                      return component.long_name
                  }
                );
              }

              var state = location[0].address_components.filter(
                  (component)=>{
                    if(component.types.indexOf("administrative_area_level_1")>=0)
                      return component.short_name
                  }
                );

              var country = location[0].address_components.filter(
                (component)=>{
                  if(component.types.indexOf("country")>=0)
                    return component.long_name
                }
              );

              var payload = {
                city:city.length>0?city[0].long_name:(vicinity !==''?vicinity:false),
                state:state.length>0?state[0].short_name:false,
                country:country.length>0?country[0].long_name:false,
                lat:location[0].geometry.location.lat()?location[0].geometry.location.lat():null,
                lon:location[0].geometry.location.lng()?location[0].geometry.location.lng():null,
                formattedAddressOriginal:location[0].formatted_address?location[0].formatted_address:false,
                formattedAddress:this.state.value,
              }
              var zipCodeAddressComponent =
                location[0].address_components.filter(
                  (component)=>{
                    if(component.types.indexOf("postal_code")>=0)
                      return component.short_name
                  }
                )
              if(zipCodeAddressComponent.length)
                payload.zipCode = zipCodeAddressComponent[0].short_name

              var streetNumberAddressComponent =
                  location[0].address_components.filter(
                    (component)=>{
                      if(component.types.indexOf("street_number")>=0)
                        return component.short_name
                    }
                  )
              var streetNameAddressComponent =
                  location[0].address_components.filter(
                    (component)=>{
                      if(component.types.indexOf("route")>=0)
                        return component.short_name
                    }
                  )
              var address=''
              if(streetNumberAddressComponent.length)
                address+=streetNumberAddressComponent[0].short_name+" "
              if(streetNameAddressComponent.length)
                address+=streetNameAddressComponent[0].short_name
              if(address=='')
                address=null
              payload.address=address

          }
          else{
            var payload ={
              address:false,
              city:false,
              state:false,
              zipCode:false,
              country:false,
              formattedAddressOriginal:false,
              formattedAddress:this.state.value,
              lat:null,
              lon:null,
              place:selectedAddress.place?selectedAddress.place:'',
            }
          }

            if(isSelected){
              this.props.onSelectCallback(payload)
            }
            else if (this.state.onBlurPredict){
              this.props.onPredictCallBack(payload)
            }

        }
      );
    }

  render() {
        const tooltip = (
                          <Tooltip id="tooltip" style={{zIndex: 1350}}>
                          {this.state.helpText}
                          </Tooltip>
                        )
        /*const menuProps = {
          desktop: true,
          disableAutoFocus: true,
        };*/
        //this.init()
        const inputBox = (
              <AutoComplete
                  id='street-address-field'
                  underlineStyle={{visibility: 'hidden'}}
                  hintText={this.state.needHintText?this.state.hintText:""}
                  // floatingLabelFixed
                  floatingLabelText={this.state.floatingLabelText}
                  floatingLabelStyle={{
                    top: 23,
                    left: -2,
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontWeight: 400,
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: 16,
                    transform: 'scale(1) translate(0px, -12px)'
                  }}
                  dataSource={this.state.predictAddresses}
                 // menuProps={menuProps}
                  maxSearchResults={this.state.maxSearchResults}
                  openOnFocus={true}
                  fullWidth={this.state.fullWidth}
                  searchText={this.state.value}
                  onUpdateInput = {(e)=>{
                    var value = e
                    // console.log({'value':value});
                    this.updateSearchText(value);
                    this.callPrdictions(value);
                    }}
                  onNewRequest = {(chosenRequest, index)=>{
                    if(chosenRequest.place != null)
                      this.handleAddressSelect(chosenRequest,true)
                    }}
                  onClose={()=>{
                    if(this.state.predictAddresses.length>0 && this.state.onBlurPredict && this.state.value !==''){
                      //console.log('GoogleAddressAutoComplete onClose',this.state.predictAddresses[0]);
                      this.handleAddressSelect(this.state.predictAddresses[0],false)
                    }else{
                      this.updateSearchText("");
                    }
                  }}
                  errorText={this.state.errorText}
                  dataSourceConfig = {{ 'text': 'description', 'value': 'place',}}
                  //onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}
                  filter={AutoComplete.fuzzyFilter}
                  textFieldStyle={{...this.state.textFieldStyle}}
                  popoverProps={{ style: {width: 'auto'} }}
                  inputStyle={{
                      width: '100%', 
                      minWidth: 0,
                      whiteSpace: 'nowrap',
                      marginTop: -5
                  }}
                  placeholder={this.state.placeholder}
                
                />)
        
        if(this.state.showHelpText === true){
          const body =  (
                <OverlayTrigger
                placement={this.state.helpTextLocation}
                overlay={tooltip}
                delayShow={300}
              >
                {inputBox}
              </OverlayTrigger>
            )
            return (
                  <div style={{padding: 0, paddingRight:'10px', alignItems: "center"}}>
                    {body}
                    {this.state.value != '' && (
                      <span onClick={()=>this.updateSearchText('')} style={{alignItems: "center", justifyContent: "center"}}>
                        <FA name='times' />
                      </span>
                    )}
                  </div>
              );

        }
        else{
          const body =  inputBox
          return (
                  <div>
                    {body}
                  </div>
              );
        }





  }
}


export default compose()(GoogleAddressAutoComplete)
