/*global google*/
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment';
import { handleApiErrors } from '../lib/api-errors'
import {toast} from 'react-toastify'
import {
  CALL_TMSBROKER_DATA,
  CALL_TMSBROKER_DATA_SUCCESS,
  CALL_TMSBROKER_DATA_ERROR,
  UPDATE_TMSBROKER_DATA,
  UPDATE_TMSBROKER_DATA_SUCCESS,
  UPDATE_TMSBROKER_DATA_ERROR,
} from './constants'
import {LOGIN_REQUESTING,} from '../login/constants'
import {getTMSBroker, updateTMSBroker} from '../api/updateTMSBroker'

function* getTMSBrokerDataFlow (action) {
  try {
    yield put(showLoading())
    const response = yield call(getTMSBroker)
    yield put({ type: CALL_TMSBROKER_DATA_SUCCESS, response })
  } catch (error) {
    yield put({ type: CALL_TMSBROKER_DATA_ERROR, error })
  }
  finally{
    yield put(hideLoading())
  }
}


function* updateTMSBrokerDataFlow (action) {
  try {
    const {data}= action;
    yield put(showLoading())

    const response = yield call(updateTMSBroker)
    if(response.status){
        let email = response.email
        let password = response.hint
        yield put({type: LOGIN_REQUESTING,  email, password})
      //  yield put({ type: GET_PAYMENT_INIT_DATA })
      //  toast.success(response.message) //commenting this to not to show the message on UI
    }

    yield put({ type: UPDATE_TMSBROKER_DATA_SUCCESS, response })

    action.updateCallback()
  } catch (error) {
    yield put({ type: UPDATE_TMSBROKER_DATA_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}

function* getTMSBrokerWatcher () {
//  yield takeLatest(CALL_TMSBROKER_DATA, getTMSBrokerDataFlow)
  yield takeLatest(UPDATE_TMSBROKER_DATA, updateTMSBrokerDataFlow)
}

export default getTMSBrokerWatcher
