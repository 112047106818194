import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../HOC/Mobile.js'
import { makeStyles } from '@material-ui/core/styles';

const StopInformationDesktop = (props) => {
    const classes = useStyles();

    const {
        load,
        arrivalTime,
        departureTime,
        updateDepartureTime,
        updateArrivalTime,
    } = props

    const getStopType = (stopIndex) => {
        if (stopIndex === 0)
            return 'Pickup'
        else if (stopIndex === load.stops.length - 1)
            return 'Delivery'
        else
            return 'Interim'
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {load.stops.map((stop, index) =>
                <div >
                    <Row style={{ display: 'flex', textAlign: 'center' }}>
                        <Col style={{ width: '5%' }}>
                            <p className={classes.mainText} style={{textDecoration: 'underline', whiteSpace: 'nowrap'}}>
                                Stop {stop.sequence}
                            </p>
                        </Col>
                        <Col style={{ width: '45%' }}>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Type:
                                    </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {getStopType(index)}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Company Name:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {stop.name}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Address:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {stop.address}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        City, State:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {stop.city}, {stop.state}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Zip:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {stop.zipCode}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ width: '50%' }}>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Reference:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {stop.stopId}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Miles:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {load.distance && load.distance > 0 ? load.distance + " mi" : null}
                            </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Weight:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {load.weight && load.weight > 0 ? load.weight + " lbs" : null}
                            </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Pieces:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {load.quantity}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.stopCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Commodity:
                            </p>
                                </Col>
                                <Col className={classes.stopCol} style={{ textAlign: 'left' }}>
                                    <p className={classes.secondaryText}>
                                        {load.commodityId ? load.commodityId : load.commodity ? load.commodity : null}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                        <Col style={{ width: '50%' }}>
                            <Row>
                                <Col className={classes.timeCol} style={{ textAlign: 'right' }}>
                                    <p className={classes.mainText}>
                                        Scheduled Arrival:
                            </p>
                                </Col>
                                <Col className={classes.timeCol} style={{ paddingLeft: 15 }}>
                                    <p className={classes.secondaryText}>
                                        {stop.scheduledAt}
                                    </p>
                                </Col>
                            </Row>
                            {/* <Row>
                            <Col className={classes.timeCol} style={{ textAlign: 'right' }}>
                                <p className={classes.mainText}>
                                    Appt Close:
                            </p>
                            </Col>
                            <Col className={classes.timeCol} style={{ paddingLeft: 15 }}>
                                <p className={classes.secondaryText}>
                                    ***Need Data
                            </p>
                            </Col>
                        </Row> */}
                        </Col>
                        {/* <Col style={{ width: '50%' }}>
                        <Row>
                            <Col className={classes.timeCol} style={{ textAlign: 'right', width: 75 }}>
                                <p className={classes.mainText}>
                                    Arrival:
                            </p>
                            </Col>
                            <Col className={classes.timeCol} style={{ textAlign: 'right', paddingRight: 40 }}>
                                <DateTimePicker
                                    onChange={(dateTime) => updateArrivalTime(dateTime)}
                                    value={arrivalTime}
                                    autoSize
                                    allowNullDate
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className={classes.timeCol} style={{ textAlign: 'right', width: 75 }}>
                                <p className={classes.mainText}>
                                    Departure:
                            </p>
                            </Col>
                            <Col className={classes.timeCol} style={{ textAlign: 'right', paddingRight: 40 }}>
                                <DateTimePicker
                                    onChange={(dateTime) => updateDepartureTime(dateTime)}
                                    value={departureTime}
                                    autoSize
                                    allowNullDate
                                />
                            </Col>
                        </Row>
                    </Col> */}
                    </Row>
                    {load.stops.length - 1 != index &&
                        <hr style={{ width: '80%' }} />
                    }
                </div>
            )}
        </div>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(StopInformationDesktop)

const useStyles = makeStyles({
    stopCol: {
        display: 'inline-block',
        width: '50%',
        paddingLeft: 15
    },
    timeCol: {
        display: 'inline-block',
        width: '50%',

    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        margin: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: -5,
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    fileUpload: {
        opacity: 0,
        position: 'absolute',
        zIndex: - 1
    },
    fileUploadLabel: {
        cursor: 'pointer',
        backgroundColor: 'lightgray',
        height: 'min-content',
        padding: 5,
        borderRadius: 5,
    }
});