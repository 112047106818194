import { BACKEND_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'


// const BACKEND_URL = "http://scapi-dev.truckertools.com/api" //TODO: connect to prod
const POST_REMOTE_LOG_URL = "/api/carrier/remoteLog"


export const PostRemoteLog = (params) => {

  let myAuthService = useAuthenticationHook()

  const url = BACKEND_URL + POST_REMOTE_LOG_URL
  let requestObject = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  }
  let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
  return fetch(url, newRequestObject)
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

