import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../HOC/Mobile.js'
import { makeStyles } from '@material-ui/core/styles';

const StopInformationMobile = (props) => {
    const classes = useStyles();

    const {
        load,
        arrivalTime,
        departureTime,
        updateDepartureTime,
        updateArrivalTime,
    } = props

    const getStopType = (stopIndex) => {
        if (stopIndex === 0)
            return 'Pickup'
        else if (stopIndex === load.stops.length - 1)
            return 'Delivery'
        else
            return 'Interim'
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {load.stops.map((stop, index) => (
                <div key={stop.stopId} style={{ marginBottom: 10 }}>

                    <div>
                        <Row className={classes.row}>
                            <p className={classes.mainText}>
                                Stop {index + 1}. {process.env.REACT_APP_ADVANTAGE_ID !== "dd4udt1b" ? stop.address : null}
                            </p>
                        </Row>
                        <Row className={classes.row}>
                            <p className={classes.secondaryText} style={{ fontSize: 14 }}>
                                {stop.city}, {stop.state} {stop.zipCode}
                            </p>
                        </Row>
                    </div>

                </div>
            ))}
        </div>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(StopInformationMobile)

const useStyles = makeStyles({
    stopCol: {
        display: 'inline-block',
        width: '50%',
        paddingLeft: 15
    },
    timeCol: {
        display: 'inline-block',
        width: '50%',

    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        margin: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: -5,
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    fileUpload: {
        opacity: 0,
        position: 'absolute',
        zIndex: - 1
    },
    fileUploadLabel: {
        cursor: 'pointer',
        backgroundColor: 'lightgray',
        height: 'min-content',
        padding: 5,
        borderRadius: 5,
    }
});