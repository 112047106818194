import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import muiThemeable from 'material-ui/styles/muiThemeable';

import List from '@material-ui/core/List';

import FuelStopItem from "./fuel-stops-cards/fuel-stop-item"
// import TruckCapacitiesListHeader from './truck-capacities-list-header';
// import TruckCapacityItem from './truck-capacity-card/TruckCapacityItem';

// import { LogActionsActivity } from '../../../apis/index'
import { checkValueExists } from '../../../lib/check-value-exists';


const FuelStopsList = (props) => {

    const {
        itemList,
        muiTheme,
        fuelStopFilters,
        searchLocation
    } = props

    const isMountedOne = useRef(false);
    const isMountedTwo = useRef(false);

    const [filteredList, setFilteredList] = useState(itemList)

    const canShowFuelStop = (fuelStop) => {
        let canShowItem = true
        if (fuelStopFilters.deadheadThresholds.length > 0 &&
            checkValueExists(fuelStop, "metadata") &&
            checkValueExists(fuelStop.metadata, "distance") &&
            canShowItem) {
            if (fuelStop['metadata']['distance'] < fuelStopFilters.deadheadThresholds[0] || fuelStop['metadata']['distance'] > fuelStopFilters.deadheadThresholds[1]) {
                canShowItem = false
            }
        }

        if (fuelStopFilters.dieselThresholds.length > 0 &&
            checkValueExists(fuelStop, "metadata") &&
            checkValueExists(fuelStop.metadata, "price") &&
            checkValueExists(fuelStop.metadata.price, "diesel") &&
            canShowItem) {
            if ((fuelStop['metadata']['price']['diesel']*1000) < fuelStopFilters.dieselThresholds[0] || (fuelStop['metadata']['price']['diesel']*1000) > fuelStopFilters.dieselThresholds[1]) {
                canShowItem = false
            }
        }

        if (fuelStopFilters.filteredOutFranchises.length > 0 && canShowItem) {
            if (fuelStopFilters.filteredOutFranchises.indexOf(fuelStop.franchise_name) > -1) {
                canShowItem = false
            }
        }

        // todo
        // if (fuelStopFilters.filteredOutAmenities.length > 0 && canShowItem) {
        //     if (fuelStopFilters.filteredOutAmenities.indexOf(fuelStop.metadata) > -1) {
        //         canShowItem = false
        //     }
        // }

        return canShowItem
    }

    const filterLoads = (list) => {
        if (list == []) return
        let retData = []
        if (fuelStopFilters.lastAction != null) {

            list.forEach(fuelStop => {
                let canShowItem = canShowFuelStop(fuelStop)
                if (canShowItem) {
                    retData.push(fuelStop)
                }
            })

        } else {
            retData = list
        }
        // if (setCurrentLoadListCount != null) {
        //     setCurrentLoadListCount(retData.length)
        // }
        setFilteredList(retData)

        // if (retData.length > 0) {
        //     useGtagHook(
        //         "search_results_returned",
        //         {
        //             screen: isRecentActivity ? "recent_activity" : isMyLoadsScreen ? "my_loads" : isHome ? "home" : "find_and_book_loads",
        //             listLength: retData.length,
        //             type: "loads",
        //             after_filter: true
        //         }
        //     )
        // }

    }

    useEffect(() => {
        if (isMountedOne.current && itemList.length > 0) {
            filterLoads(itemList)
        } else {
            isMountedOne.current = true;
        }

    }, [itemList])

    useEffect(() => {
        if (isMountedTwo.current && itemList.length > 0 && fuelStopFilters.lastAction != null) {
            filterLoads(itemList)
        } else {
            isMountedTwo.current = true;
        }
    }, [fuelStopFilters])

    return (
        <div style={{ height: '100%' }}>
            <List style={{ height: '100%', width: '100%', maxHeight: '100%', overflowX: 'hidden', overflowY: "auto", paddingTop: 0 }}>

                {filteredList.length > 0 ?
                    filteredList.map((item, index) => {

                        return (
                            <FuelStopItem
                                key={item.id}
                                item={item}
                                itemIndex={index}
                                searchLocation={searchLocation}
                            />
                        )

                    })
                    :
                    <div style={{ paddingTop: 25 }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <h3>No fuel discounts found</h3>
                        </div>
                    </div>

                }

            </List>
        </div>
    )

}


const mapStateToProps = state => ({
    fuelStopFilters: state.CarrierPortal.fuelStopFilters
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(FuelStopsList)