import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';

import GeneralLoadDetailsTab from './generalLoadDetailsTab'
import MyLoadsDetailsTab from '../../../../../my-loads/werner/load-list/mobile/myLoadsDetailsTab';
import WernerGeneralLoadDetailsTab from './WernerGeneralLoadDetailsTab';

const DetailsTab = (props) => {

    const {
        load,
        pickup,
        delivery,
        availability,
        googleMapsDistance,
        googleMapsDuration,
        isMyLoadsScreen = false,
        myLoadsRenderedTab = 0,
        handleTabChange,
        latestQuote = null,
        latestQuoteShowBIN = false,
        callRefreshCard,
        onInvoicesScreen,
        isHome=false
    } = props

    return (
        <div style={{ display: 'contents' }}>
            { isMyLoadsScreen && process.env.REACT_APP_ADVANTAGE_ID === "a98db973" ? 
                    <MyLoadsDetailsTab //this file is specific to Werner.
                        load={load} 
                        myLoadsRenderedTab={myLoadsRenderedTab}
                    />
                    : process.env.REACT_APP_ADVANTAGE_ID == "a98db973" ?  
                        <WernerGeneralLoadDetailsTab 
                            load={load} 
                            pickup={pickup} 
                            delivery={delivery} 
                            availability={availability} 
                            googleMapsDistance={googleMapsDistance}
                            googleMapsDuration={googleMapsDuration}
                            handleTabChange={handleTabChange}
                            latestQuote={latestQuote}
                            latestQuoteShowBIN={latestQuoteShowBIN}
                            callRefreshCard={callRefreshCard}
                            isMyLoadsScreen={isMyLoadsScreen}
                            myLoadsRenderedTab={myLoadsRenderedTab}
                            onInvoicesScreen={onInvoicesScreen}
                        />
                        :
                        <GeneralLoadDetailsTab 
                            load={load} 
                            pickup={pickup} 
                            delivery={delivery} 
                            availability={availability} 
                            googleMapsDistance={googleMapsDistance}
                            googleMapsDuration={googleMapsDuration}
                            handleTabChange={handleTabChange}
                            latestQuote={latestQuote}
                            latestQuoteShowBIN={latestQuoteShowBIN}
                            callRefreshCard={callRefreshCard}
                            isMyLoadsScreen={isMyLoadsScreen}
                            myLoadsRenderedTab={myLoadsRenderedTab}
                            onInvoicesScreen={onInvoicesScreen}
                        />
            }
        </div>
    );
}


export default compose(
    connect(),
    muiThemeable(),
)(DetailsTab)