import React from 'react';
import TextField from 'material-ui/TextField';
import {withState,compose} from 'recompose';
import PropTypes from 'prop-types';
import {OverlayTrigger,Tooltip} from 'react-bootstrap'

const HelpWrapper = ({text,location='top',enabled = true, isMultiSelect=false, ...props}) => {
	const tooltip = (
	  <Tooltip id="tooltip" style={{zIndex: props.zIndex, visibility: enabled?'visible':'hidden'}}>
		{text}
		{isMultiSelect && 
			<p style={{fontWeight:900, fontSize:12}}>- Select all that apply -</p>
		}
	  </Tooltip>
	)
    return (
      <OverlayTrigger
      	placement={location}
      	overlay={tooltip}
      	delayShow={300}
      >
      	{props.children}
      </OverlayTrigger>
    )
}

HelpWrapper.propTypes={
  text:PropTypes.string.isRequired
}

export default HelpWrapper
