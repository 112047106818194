import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import moment from 'moment'

import { bindActionCreators } from 'redux';

import LoadingOverlay from '../../../../components/LoadingOverlay'

import isMobile from '../../../../HOC/Mobile.js'
// import useGtagHook from '../../gtag-hook';
import { checkValueExists } from '../../../../lib/check-value-exists';

import { WernerServices } from '../../../customers/a98db973/services';
import WernerPreferencesDesktopUI from './desktop-ui'
import WernerPreferencesMobileUI from './mobile-ui'
import { GetLatitudeLongitudes } from '../../../../lib/geocoder'
import { updateLoadSearchBar } from '../../../../CarrierPortal/ActionReducers/components';
import { getNearbyLoads } from '../../../../CarrierPortal/actions';
import { toast } from 'react-toastify';
import { US_STATES, ZONES_FORMATTED_STATES } from '../../LoadSearchBar/zones';

const BACKEND_URL = `${process.env.REACT_APP_SC_URL}`;


const WernerPreferences = (props) => {
    const { isMobile, updateLoadSearchBar, getNearbyLoads } = props

    const PAGES = {
        capacity: 0,
        preferences: 1,
    }

    const [truckCapacityList, setTruckCapactiyList] = useState([])
    const [lanePreferencesList, setLanePreferencesList] = useState([])
    const [locationsList, setLocationsList] = useState([])
    const [selectedLocationId, setSelectedLocationId] = useState(null)
    const [renderedTab, setRenderedTab] = useState(PAGES["capacity"])
    const [isLoading, setIsLoading] = useState(true)
    const [showAddModal, setShowAddModal] = useState(false)

    const [truckTypes, setTruckTypes] = useState([]);


    const getLocations = () => {
        const apiServices = new WernerServices()
        apiServices.getLocations()
            .then(response => {
                if (response && checkValueExists(response, "addresses")) {
                    setLocationsList(response.addresses)
                    let defaultLocation = response.addresses.filter(obj => {
                        return obj.addressType === "MAIN"
                    })
                    if (defaultLocation.length > 1) {
                        setSelectedLocationId(defaultLocation[0].addressId)
                    } else {
                        setSelectedLocationId(response.addresses[0].addressId)
                    }
                }
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
            })
    }

    async function fetchTruckTypes() {
        // TODO move this to sagas
        let url = '/api/getTruckCodes'
        var requestUrl = BACKEND_URL + url
        let requestObject = {
            method: 'GET',
        }
        // let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        const res = await fetch(requestUrl, requestObject);

        res.json().then(response => {
            if (response.status) {
                let truckTypesRes = response.truckCodes
                setTruckTypes(truckTypesRes);
            }
        })
    }

    const updatePreferencesData = (id, type, locationId) => {
        // console.log("2: ", selectedLocationId)
        setIsLoading(true);
        const apiServices = new WernerServices()

        apiServices.getLanePreferences(selectedLocationId)
            .then(response => {
                if (response) {
                    setLanePreferencesList(response)
                    setIsLoading(false)
                    if (id != null && type === "lane") {
                        handleLaunchFromEmail(id, "lanePreferenceId", response)
                    }
                }
            })
            .catch(error => {
                setIsLoading(false)
            })

        apiServices.getAvailableCapacity(selectedLocationId)
            .then(response => {
                if (response) {
                    setIsLoading(false)
                    setTruckCapactiyList(response)
                    if (id != null && type === "capacity") {
                        handleLaunchFromEmail(id, "availableCapacityId", response)
                    }
                }
            })
            .catch(error => {
                setIsLoading(false)
            })

        setIsLoading(false)
    }

    const handleLaunchFromEmail = (id, key, searchesList) => {
        // get search itwm based on search id
        var result = searchesList.filter(searchItem => {

            return searchItem[key] == id
        })
        // call view loads automatically
        if (result.length != 0) {
            handleViewLoads(result[0])
            props.history.push("/loads")
        }
    }

    const handleViewLoads = (item) => {

        GetLatitudeLongitudes(item.origin).then(resp => {
            if (resp && item.origin.lat == null && item.origin.lon == null) {
                let locObj = {
                    lat: parseFloat(resp.latitude),
                    lon: parseFloat(resp.longitude)
                }
                createSearchPayload(item, locObj)
            } else if (item.origin.lat && item.origin.lon) {
                createSearchPayload(item, item.origin)
            } else {
                toast.error("There was an error with your request.")
            }
        }).catch(() => {
            toast.error("There was an error with your request.")
        })


    }

    const getDestinationPayload = (item) => {

        let structuredDestinations = []

        if( item.destination && item.destination.state && item.destination.state != 'ANY') {
            let matchedState = US_STATES.filter(state => state.abbr==item.destination.state)
            if(matchedState != null){
                structuredDestinations = matchedState
            }
        }  else if (checkValueExists(item, "destination") && checkValueExists(item.destination, "zone")) {

            let selectedZones = ZONES_FORMATTED_STATES.filter(zone => {
                if (item.destination.zone === zone.abbr)
                    return true
                else
                    return false
            })

            selectedZones.forEach(zone => {
                zone.states.forEach(state => {
                    structuredDestinations.push(state)
                })
            })
        }       
        return structuredDestinations
    }

    const createSearchPayload = (item, originCoords) => {

        let searchDate = null
        if (checkValueExists(item, "daysOfWeek")) {
            let structuredWeekdays = []

            Object.keys(item.daysOfWeek).forEach(key => {
                structuredWeekdays.push(item.daysOfWeek[key])
            })

            searchDate = nextWeekdayDate(structuredWeekdays)
        }

        let equipmentType = null
        if (item.equipmentType != null) {
            equipmentType = Object.values(truckTypes).filter(type => {
                if (type.name.toUpperCase() === item.equipmentType.toUpperCase())
                    return true
                return false
            })
        }

        let searchPayload = {
            lat: originCoords.lat,
            lon: originCoords.lon,
            pickupDateFrom: moment(item.dateAvailable).format('MM/DD/YYYY'),
            pickupTimeFrom: moment(item.dateAvailable).format('HH:mm'),
            destinations: getDestinationPayload(item),
            truckTypes: equipmentType ? equipmentType : [],
            // brokerIds: brokerIds.length > 0 ? brokerIds : [], //TODO
            state: item.origin.state ? item.origin.state : '',
            zipCode: item.origin.zipCode ? item.origin.zipCode : '',
            city: item.origin.city ? item.origin.city : '',
            country: 'United States',
            advantageId: (process.env.REACT_APP_ADVANTAGE_ID === "c0419cm1" || process.env.REACT_APP_ADVANTAGE_ID === "ch7h4g68r") ? "32m9lbe4" : process.env.REACT_APP_ADVANTAGE_ID
        }


        updateLoadSearchBar(searchPayload.destinations, 'lane_preferences')
        let combinedDateTime = moment(searchPayload.pickupDateFrom + " " + searchPayload.pickupTimeFrom);
        updateLoadSearchBar(moment(combinedDateTime).format("YYYY-MM-DDTHH:mm"), 'available_at_start')
        updateLoadSearchBar(equipmentType ? equipmentType : false, 'truckTypes')
        updateLoadSearchBar({
            address: false,
            city: item.origin.city,
            state: item.origin.state,
            country: 'United States',
            zipCode: item.origin.zipCode,
            formattedAddress: item.origin.city + ", " + item.origin.state,
            lat: originCoords.lat,
            lon: originCoords.lon,
        })

        getNearbyLoads(searchPayload);
        props.history.push("/loads")
    }

    function nextWeekdayDate(weekdays) {

        let weekNum = new Array(7);
        weekNum["SUN"] = 0;
        weekNum["MON"] = 1;
        weekNum["TUE"] = 2;
        weekNum["WED"] = 3;
        weekNum["THU"] = 4;
        weekNum["FRI"] = 5;
        weekNum["SAT"] = 6;

        let minDate = null
        weekdays.forEach(day_in_week => {
            if (moment().day() == weekNum[day_in_week])
                minDate = moment().valueOf()

            let ret = new Date();
            ret.setDate(ret.getDate() + (weekNum[day_in_week] - 1 - ret.getDay() + 7) % 7 + 1);
            minDate = minDate == null ? ret : (ret < minDate ? ret : minDate)
        });

        return (moment(minDate).format('LL'))
    }

    // const fetchLanePreferences = () => {
    //     // console.log("fetching lane pref...", tag)
    //     setIsLoading(true)
    //     setTimeout(() => {
    //         const apiServices = new WernerServices()
    //         console.log("3: ", selectedLocationId)
    //         apiServices.getLanePreferences(selectedLocationId)
    //             .then(response => {
    //                 if (response) {
    //                     setLanePreferencesList(response)
    //                 }
    //             })
    //             .catch(error => {
    //                 setIsLoading(false)
    //             })
    //         setIsLoading(false)
    //     }, 1000);
    // }

    // const fetchTruckCapacity = () => {
    //     // console.log("fetching truck capacity...")
    //     setIsLoading(true)
    //     setTimeout(() => {
    //         const apiServices = new WernerServices()
    //         console.log("2: ", selectedLocationId)
    //         apiServices.getAvailableCapacity(selectedLocationId)
    //             .then(response => {
    //                 if (response) {
    //                     setTruckCapactiyList(response)
    //                 }
    //             })
    //             .catch(error => {
    //                 setIsLoading(false)
    //             })
    //         setIsLoading(false)
    //     }, 1000);
    // }

    const handlePageChange = (pageName) => {

        switch (pageName) {
            case "preferences":
                setRenderedTab(PAGES["preferences"])
                // renderedTab !== PAGES["preferences"] ? fetchLanePreferences() : null
                break;
            case "capacity":
                setRenderedTab(PAGES["capacity"])
                // renderedTab !== PAGES["capacity"] ? fetchTruckCapacity() : null
                break;
        }

    }

    // const handleAddLanePreference = (item) => {
    //     console.log(item)
    //     //TODO
    //     //pass new lane preference to api
    //     //fetch list again
    // }

    // const handleAddTruckCapacity = (item) => {
    //     console.log(item)
    //     //TODO
    //     //pass new truck capacity to api
    //     //fetch list again
    // }

    // const handleLoctionChange = (location) => {
    //     setSelectedLocationId(location)
    // }

    useEffect(() => {
        getLocations()
        fetchTruckTypes()
        // let url = props.location.search;
        // let params = queryString.parse(url);
        // // let url = String(window.location.search)
        // if (params.id && params.type && params.locationId) {
        //     updatePreferencesData(params.id, params.type, params.locationId)
        // } 

    }, [])

    useEffect(() => {
        if (selectedLocationId != null) {
            // console.log("renderedTab: ", renderedTab)
            // console.log("1: ", selectedLocationId)
            // switch (renderedTab) {
            //     case PAGES["capacity"]:
            //         fetchTruckCapacity()
            //         break
            //     case PAGES["preferences"]:
            //         fetchLanePreferences()
            //         break
            // }
            setIsLoading(true)
            updatePreferencesData()
        }
    }, [selectedLocationId])

    return (
        <div style={{ height: '100%' }}>
            {isLoading && <LoadingOverlay />}
            {!isMobile ?
                <WernerPreferencesDesktopUI
                    locationsList={locationsList}
                    selectedLocationId={selectedLocationId}
                    setSelectedLocationId={setSelectedLocationId}
                    isLoading={isLoading}
                    lanePreferencesList={lanePreferencesList}
                    updatePreferencesData={updatePreferencesData}
                    truckCapacityList={truckCapacityList}
                    renderedTab={renderedTab}
                    handlePageChange={handlePageChange}
                    showAddModal={showAddModal}
                    setShowAddModal={setShowAddModal}
                    handleViewLoads={handleViewLoads}
                    PAGES={PAGES}
                    setIsLoading={setIsLoading}
                />
                :
                <WernerPreferencesMobileUI
                    locationsList={locationsList}
                    selectedLocationId={selectedLocationId}
                    setSelectedLocationId={setSelectedLocationId}
                    isLoading={isLoading}
                    lanePreferencesList={lanePreferencesList}
                    updatePreferencesData={updatePreferencesData}
                    truckCapacityList={truckCapacityList}
                    renderedTab={renderedTab}
                    handlePageChange={handlePageChange}
                    showAddModal={showAddModal}
                    setShowAddModal={setShowAddModal}
                    handleViewLoads={handleViewLoads}
                    PAGES={PAGES}
                    setIsLoading={setIsLoading}
                />
            }
        </div>
    )
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getNearbyLoads: getNearbyLoads,
        updateLoadSearchBar: updateLoadSearchBar,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(WernerPreferences)