import {pusherSubscribe, pusherUnsubscribe} from "../pusher"

const getCarrierChannelName = (userObj) => {
    // const token = JSON.parse(localStorage.getItem("token"));
    // const {carrierId} = token;
    return `CarrierPortal.${userObj.carrier.carrierId}`
}

const getCarrierUserChannelName = (userObj) => {
    // const token = JSON.parse(localStorage.getItem("token"));
    // const {userId, carrierId} = token;
    return `CarrierPortal.${userObj.carrier.carrierId}.${userObj.userId}`
}

export const subscribePreference = (preferenceNotificationCallback, userObj) => {
    const channelName = getCarrierUserChannelName(userObj);
    const eventName = 'PreferencePusherEvent';
    pusherSubscribe(channelName, eventName, preferenceNotificationCallback)
}

export const subscribeBrokerCarrierCommunication = (callback, userObj) => {
    const channelName = getCarrierChannelName(userObj);
    const eventName = 'BrokerCarrierCommunicationPusherEvent';
    pusherSubscribe(channelName, eventName, callback)
}

export const unsubscribeSavedSearch = () => {
    pusherUnsubscribe(getCarrierChannelName());
}