import React, { useRef, useEffect, useState } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'

//COMPONENTS
import FuelSearchBarMobile from './FuelSearchBarMobile';


const FuelSearchMobileWrapper = (props) => {

    const { onHomePage = false, buttonColor = false, searchLocation, setSearchLocation, handleSearch } = props

    const useStyles = makeStyles({
        container: {

        }
    });
    const classes = useStyles()

    const [showFuelSearchPopover, setShowFuelSearchPopover] = useState(false)
    const [searchAnchorEL, setSearchAnchorEl] = useState(null)


    return (
        <div>
        <Button 
            onClick={(anchor) => {
                    setShowFuelSearchPopover(true)
                    setSearchAnchorEl(anchor)
                }}
                style={{ paddingLeft: 4, backgroundColor: buttonColor ? buttonColor : null }}
                disableRipple={false}
            >
            
              <FA name="search" size='2x' style={{color: buttonColor ? 'white' : 'black'}}/>
              <p style={{ fontSize: 14, paddingLeft: 5, color: buttonColor ? 'white' : 'black' }}>Search</p>
            </Button>
        <Popover
            id="search_popover"
            PaperProps={{style:{width: '90%'}}}
            open={showFuelSearchPopover}
            anchorEl={searchAnchorEL}
            onClose={() => { setSearchAnchorEl(null); setShowFuelSearchPopover(false) }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <FuelSearchBarMobile 
                searchLocation={searchLocation}
                setSearchLocation={setSearchLocation}
                handleSearch={handleSearch}
                setShowFuelSearchPopover={setShowFuelSearchPopover}
            />
        </Popover>
        </div>
    )
}

export default FuelSearchMobileWrapper
