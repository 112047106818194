import React, { useEffect, useState, Suspense } from 'react';

import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import './customBS/bootstrap.css';
import 'react-dates/lib/css/_datepicker.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';

import './customBS/bootstrap.css';
import './customBS/flexslider.css';
import './customBS/animsition.min.css';
import './customBS/animate.css';
import './customBS/style.css';
import './customBS/magnific-popup.css';
import './customBS/font-awesome.min.css';

import truckerToolsTheme from './components/materialUi/theme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify'

import CarrierPortalDashboard from './carrierPortalV2/index'
import customWernerTheme from './CarrierPortal/customers/a98db973/theme/theme'
import customRyderMSTheme from './CarrierPortal/customers/srukfk5e/theme/theme'
import customBlueGraceTheme from './CarrierPortal/customers/ju9agnj4/theme/theme'
import customELTheme from './CarrierPortal/customers/dd4udt1b/theme/theme'
import customReedTMSTheme from './CarrierPortal/customers/r85xb5y8/theme/theme'
import customTtTheme from './CarrierPortal/customers/2zr5ys19/theme/theme'
import customSmartLogisticsTheme from './CarrierPortal/customers/32m9lbe4/theme/theme'
import customKingsgateTheme from './CarrierPortal/customers/k6g3f58q/theme/theme'
import customCRSTTheme from './CarrierPortal/customers/c0419cm1/theme/theme'
import customWorldwideExpressTheme from './CarrierPortal/customers/wesog299/theme/theme'
import customCHRobinsonTheme from './CarrierPortal/customers/ch7h4g68r/theme/theme'
import CarouselModal from './CarrierPortal/components/carousel-modal';

import { TourProvider } from '@reactour/tour'

import usePageTracking from "./page-tracking-hook"

import { Auth0Provider } from "@auth0/auth0-react"
import isMobile from './HOC/Mobile.js'
import { isoToUTCString, OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import ConditionalWrapper from "./CarrierPortal/components/conditionalWrapper"
import { useAuthenticationHook } from './CarrierPortal/hooks/authentication-hook';


import DefaultFont from './custom-css-provider'
import EnglandLogisticsFont from './CarrierPortal/customers/dd4udt1b/theme/custom-css-provider.js'
import ScrollTheme from './carrierPortalV2/theme/scroll-theme'

const ThemeSelector = ({ children }) => {
  const advantageId = process.env.REACT_APP_ADVANTAGE_ID
  return (
    <div style={{ height: '100%' }}>

      {advantageId === "dd4udt1b" ? <EnglandLogisticsFont /> : <DefaultFont />}
      {/* Mobile screens get defualt scroll styling */}
      {(typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1) ? null : <ScrollTheme />}

      {children}
    </div>
  )
}

const App = (props) => {

  usePageTracking()

  // console.log("LOCATION: ", window.location.origin)
  const [showCarouselModal, setShowCarouselModal] = useState(false);
  const [tutorialCloseTrigger, setTutorialCloseTrigger] = useState(false)

  const myAuthService = useAuthenticationHook()
  const isAuthenticated = myAuthService.isAuthenticated()
  let pendoWatcher

  useEffect(()=>{

    if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973")
      setShowCarouselModal( ( sessionStorage.getItem('updates_Seen_Popup_1') || localStorage.getItem('disable_Updates_Popup_1')) ? false : true )
    else if (process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19")
      setShowCarouselModal( localStorage.getItem('TruckerTools_Seen_Popup') ? false : true )

    if(process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19"){
      pendoWatcher = setInterval(() => {
        if(window.pendo){
          if(localStorage.getItem('token')){
            const userObj = myAuthService.getUser()
            if(userObj){
              window.pendo.initialize({
                visitor: {
                    id: `${process.env.REACT_APP_ENVIRONMENT}-${userObj.userId}`,   // Required if user is logged in
                    customer_account_id: userObj.customerAccountId,
                    user_id: userObj.userId,
                    role: userObj.role,
                    // userType: accountInfo.userType,
                    email: userObj.username,
                    full_name: userObj.fullName,
                    first_name: userObj.firstName,
                    last_name: userObj.lastName
                    // email:        // Recommended if using Pendo Feedback, or NPS Email
                    // full_name:    // Recommended if using Pendo Feedback
                    // role:         // Optional

                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },
                account: {
                    id: `${process.env.REACT_APP_ENVIRONMENT}-${userObj.customerAccountId}`, // Required if using Pendo Feedback
                    type: 'carrier',
                    company_name: userObj.carrier.companyName,
                    mc: userObj.carrier.mc,
                    dot: userObj.carrier.dot,
                    // externalId: userObj.carrier.externalId,
                    services: userObj.carrier.services,
                    carrier_id: userObj.carrier.carrierId
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                }
              })
            }else{
              window.pendo.initialize()
            }
          }else{
            window.pendo.initialize()
          }
          clearInterval(pendoWatcher)
        }
        
      }, 3000);

    }

    // if(process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && window.pendo){

    // }

    return() => {sessionStorage.removeItem('updates_Seen_Popup'); clearInterval(pendoWatcher)}
  },[])

  let theme = customTtTheme
  let config = null
  let oktaAuth = null
  let displayModalCaurousel = false
  let steps = []

  if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
    // displayModalCaurousel = true
    theme = customWernerTheme
    config = {
      oidc: {
        clientId: "0oavcyw2lnbnVnmYX0h7", //prod "0oa1k4g1mruqpunip0h8", //Werner staging "0oavcyw2lnbnVnmYX0h7", //TT "0oa18gh9aqn6Io7qY5d6",
        issuer: "https://ssoext-preview.werner.com/oauth2/ausvtw5748twtmiCO0h7",//Prod "https://ssoext.werner.com/oauth2/aus1k4g600fShCj8a0h8", // staging "https://ssoext-preview.werner.com/oauth2/ausvtw5748twtmiCO0h7", //TT "https://dev-6163546.okta.com/oauth2/default", "https://dev-6163546.okta.com/oauth2/aus2kqgha3IuZTbZi5d6",
        redirectUri: window.location.origin + '/login/callback',
        scopes: ['openid'],
        pkce: true,
        disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false,
      },
      resourceServer: {
        messagesUrl: window.location.origin + '/api/messages',
      },
    }
    oktaAuth = new OktaAuth(config.oidc);
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b") {
    theme = customELTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "ju9agnj4") {
    theme = customBlueGraceTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "r85xb5y8") {
    theme = customReedTMSTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "srukfk5e") {
    theme = customRyderMSTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "k6g3f58q") {
    theme = customKingsgateTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "c0419cm1") {
    theme = customCRSTTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "wesog299") {
    theme = customWorldwideExpressTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "32m9lbe4") {
    theme = customSmartLogisticsTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "ch7h4g68r") {
    theme = customCHRobinsonTheme
  } else if (process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && !props.isMobile) {
    
    displayModalCaurousel = true
  }

  const fontTheme = createTheme(adaptV4Theme({
    typography: {
      fontFamily: [
        theme.primaryFontFamily,
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
    },
  }));

  if (!isMobile) {
    steps = [
      {
        selector: '[data-tut="reactour_LoadSearchBar"]',
        content: 'To search for loads, enter your criteria here.',

      },
      {
        selector: '[data-tut="reactour_DrawerMenue"]',
        content: 'Hover over the sidebar to expand the menu.'
      },
      {
        selector: '[data-tut="reactour_DrawerMenue_Expanded"]',
        content: 'Here is the navigation menu. Clicking on a link will take you to that page.'
      },
      {
        selector: '[data-tut="reactour_HomeMenueItem"]',
        content: 'Click "Home" to see the 10 best matched loads for you.',
      },
      {
        selector: '[data-tut="reactour_PreferencesMenueItem"]',
        content: 'Click "Preferences" to manage truck capacity and lane preferences',
      },
      {

        selector: '[data-tut="reactour_F&BLoadsMenueItem"]',
        content: 'Click "Find & Book Loads" to view most recent search results.',
      },
      {
        selector: '[data-tut="reactour_RecentActivityMenueItem"]',
        content: 'Click "Recent Activity" to revisit loads you\'ve viewed or quoted',
      },
      {
        selector: '[data-tut="reactour_BookedLoadsMenueItem"]',
        content: 'Click "Booked Loads" to see the loads you\'ve booked.',
      },
      {
        selector: '[data-tut="reactour_ProfileMenueItem"]',
        content: 'Edit your profile under the "Profile" tab.',
      },
      {
        selector: '[data-tut="reactour_ProfileMenueItem"]',
        content: 'This Concludes the tour.',
        action: () => setTutorialCloseTrigger(true)
      }
    ]
  } else {
    steps = [
      {
        selector: '[data-tut-mobile="reactour_mobile_LoadSearchBar"]',
        content: 'To search for loads, tap here and enter your criteria.'
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_MenuButton"]',
        content: 'Tap here to expand the menu.'
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_ExpandedMenu"]',
        content: 'Here is the navigation menu. Tapping on an item will take you to that page.',
        position: [0, 400]
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_HomeMenuItem"]',
        content: 'Tap "Home" to see the 10 best matched loads for you.'
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_PreferencesMenuItem"]',
        content: 'Tap "Preferences" to manage truck capacity and lane preferences.'
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_F&BLoadsMenuItem"]',
        content: 'Tap "Find & Book Loads" to view most recent search results'
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_RecentActivityMenuItem"]',
        content: 'Tap "Recent Activity" to revisit loads you\'ve viewed or quoted.'
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_BookedLoadsMenuItem"]',
        content: 'Tap "Booked Loads" to see the loads you\'ve booked.'
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_ProfileMenuItem"]',
        content: 'Edit your profile under the "Profile" tab.'
      },
      {
        selector: '[data-tut-mobile="reactour_mobile_ProfileMenuItem"]',
        content: 'This Concludes the tour.',
        action: () => setTutorialCloseTrigger(true)
      }
    ]
  }

  if (isAuthenticated)
    steps.splice(8, 0,
      isMobile ?
        {
          selector: '[data-tut-mobile="reactour_mobile_UserManagementMenuItem"]',
          content: 'Tap "User Management" to add or manage users on your account.'
        }
        :
        {
          selector: '[data-tut="reactour_UserManagementMenueItem"]',
          content: 'Click "User Management" to add or manage users on your account.',
        }
    )

  const stylesObj = {
    // arrow: base => ({
    //   ...base,
    //   height: '25px !important',
    //   width: '32px !important'
    // }),
    maskWrapper: base => ({
      ...base,
      zIndex: 0,
    }),
    popover: base => ({
      ...base,
      zIndex: 1299,
    }),
  }

  const handleOutsideClick = () => {
    setTutorialCloseTrigger(true)
  }

  return (
    <div style={{ height: '100%' }}>
      <ThemeSelector>
        <ToastContainer
          autoClose={5000}
          style={{ zIndex: 2000000 }}
        />
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider muiTheme={truckerToolsTheme}>
            <ThemeProvider theme={fontTheme}>
              <ConditionalWrapper
                condition={process.env.REACT_APP_USE_AUTH0 == "true"}
                wrapper={children =>
                  <Auth0Provider
                    domain={process.env.REACT_APP_AUTH0_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                    redirectUri={window.location.origin + "/login"}
                    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                    scope={"read:current_user"}
                  >{children}</Auth0Provider>
                }
              >
                <ConditionalWrapper
                  condition={process.env.REACT_APP_ADVANTAGE_ID == "a98db973"}
                  wrapper={children => <Security oktaAuth={oktaAuth}>{children}</Security>}
                >
                  <MuiThemeProvider muiTheme={theme}>
                    <TourProvider
                      showCloseButton={true}
                      disableKeyboardNavigation
                      styles={stylesObj}
                      disableFocusLock
                      disableDotsNavigation
                      disableInteraction={true}
                      showBagde={false}
                      steps={steps}
                      close={false}
                      onClickMask={handleOutsideClick}>
                      <CarrierPortalDashboard {...props} setShowCarouselModal={setShowCarouselModal}/>
                      {displayModalCaurousel && showCarouselModal &&(
                        <CarouselModal onClose={() => { setShowCarouselModal(false) }}/>
                      )}
                    </TourProvider>
                  </MuiThemeProvider>
                </ConditionalWrapper>
              </ConditionalWrapper>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </ThemeSelector>
    </div>
  );
}

export default isMobile()(App);
