import {BACKEND_URL} from './constants.js';

export function getIntegrations (brokerId) {    
    const requestUrl=BACKEND_URL+'/shipperIntegration/viewIntegrations?brokerId='+brokerId;
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                                'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                                'Content-Type': 'application/json',
                              },                              
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function addIntegration (formValues) {    
    const requestUrl=BACKEND_URL+'/shipperIntegration/addIntegration';
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                                'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                                'Content-Type': 'application/json',
                              },         
                            body: JSON.stringify(formValues),                     
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function editIntegration (formValues) {    
  const requestUrl=BACKEND_URL+'/shipperIntegration/editIntegration';
return fetch(requestUrl,{
                          method: 'PUT',
                          headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type': 'application/json',
                            },         
                          body: JSON.stringify(formValues),                     
                        })
        .then(response => {
          if (!response.ok) {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
          }
          return response.json();
        });
}

export function getShipperLoads (customerId, shipper_identifier, pageNumber, pageSize) {
  const requestUrl=BACKEND_URL+'/shipperIntegration/viewShipperLoads?customer_id='+customerId+'&shipper_identifier='+shipper_identifier+'&pageNumber='+pageNumber+
  '&pageSize='+pageSize;
return fetch(requestUrl,{
                          method: 'GET',
                          headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type': 'application/json',
                            },                              
                        })
        .then(response => {
          if (!response.ok) {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
          }
          return response.json();
        });
}

export function getLoadUpdates(loadid, eventType, pageNumber, pageSize) {
  const requestUrl=BACKEND_URL+'/shipperIntegration/viewLoadUpdatesLog?loadid='+loadid+'&eventType='+eventType+'&pageNumber='+pageNumber+
  '&pageSize='+pageSize;
return fetch(requestUrl,{
                          method: 'GET',
                          headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type': 'application/json',
                            },                              
                        })
        .then(response => {
          if (!response.ok) {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
          }
          return response.json();
        });
}

export function getCredentials(brokerId) {
  const requestUrl=`${BACKEND_URL}/shipperIntegration/getIntegrationCredentials?brokerId=${brokerId}`;
    return fetch(requestUrl,{
                          method: 'GET',
                          headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type': 'application/json',
                            },                              
                        })
        .then(response => {
          if (!response.ok) {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
          }
          return response.json();
        });
}

export function credentialsSave (isAdd, formValues) {
  const action = isAdd ? 'add' : 'edit';    
  const requestUrl=`${BACKEND_URL}/shipperIntegration/${action}IntegrationCredentials`;
    return fetch(requestUrl,{
                              method: 'POST',
                              headers: {
                                  'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                                  'Content-Type': 'application/json',
                                },         
                              body: JSON.stringify(formValues),                     
                            })
            .then(response => {
              if (!response.ok) {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
              }
              return response.json();
            });
}

