import { 	
			CALL_PROFILE_DATA, 
			UPDATE_PROFILE_DATA, 
		} from './constants'

export function getProfileDetail () {
  return {
    type: CALL_PROFILE_DATA
  }
}
export function updateProfile (data,updateCallback) {
  return {
    type: UPDATE_PROFILE_DATA,
    data,
    updateCallback:updateCallback
  }
}