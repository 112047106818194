import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';

import useGtagHook from '../../../../gtag-hook';

import TireFilterCheckbox from './tire-filter-checkbox';

const TireFilterMenuDesktop = (props) => {

    const {
        muiTheme,
        paddingTop,
        // updateLoadFilters,
        mobileView,

        //default values
        brandList,
        positionList,
        modelList,
        sizeList,
        plyList,

        //current states
        selectedBrands,
        selectedPositions,
        selectedModels,
        selectedSizes,
        selectedPlys,

        // //change handlers
        resetFilter,
        deselectAllCheckboxes,
        handleSelectBrand,
        handleSelectPosition,
        handleSelectModel,
        handleSelectSize,
        handleSelectPly,
        resetAllFilters,

        resetCheckBoxStates
    } = props

    const { height, width } = useWindowDimensions();

    const useStyles = makeStyles((theme) => ({
        filterHeader: {
            fontSize: 14,
            fontWeight: "bold",
            marginBottom: 10,
            display: 'inline-block',
            color: 'black'
        },
        filterLabel: {
            fontSize: 14,
            lineHeight: "24px",
        },
        filterSection: {
            display: "flex",
            marginBottom: 10
        },
        resetFilter: {
            fontSize: 13,
            float: "right",
            // textDecoration: 'underline',
            textAlign: "right",
            color: muiTheme.actionColor,
            padding: 0,
            cursor: 'pointer',
            textTransform: "none",
            '&:hover': {
                color: muiTheme.actionColor,
                textDecoration: 'underline !important',
            }
        },
        selectDeselectAll: {
            fontSize: 13,
            textAlign: "right",
            color: muiTheme.actionColor,
            padding: 0,
            cursor: 'pointer',
            '&:hover': {
                color: muiTheme.actionColor,
                textDecoration: 'underline !important',
            }
            // textTransform: "none"
        }
    }));
    const classes = useStyles();
    const FILTER_HEADER_HEIGHT = 75

    const [resetBrand, setResetBrand] = useState(false)
    const [resetPosition, setResetPosition] = useState(false)
    const [resetModel, setResetModel] = useState(false)
    const [resetSize, setResetSize] = useState(false)
    const [resetPly, setResetPly] = useState(false)

    const [deselectBrand, setDeselectBrand] = useState(false)
    const [deselectPosition, setDeselectPosition] = useState(false)
    const [deselectModel, setDeselectModel] = useState(false)
    const [deselectSize, setDeselectSize] = useState(false)
    const [deselectPly, setDeselectPly] = useState(false)

    useEffect(()=>{
        resetLocally()
    },[resetCheckBoxStates])

    const _onCheckboxSelect = (type, isSelected, item) => {
        switch (type) {
            case "brand":
                handleSelectBrand(isSelected, item)
                break
            case "position":
                handleSelectPosition(isSelected, item)
                break
            case "model":
                handleSelectModel(isSelected, item)
                break
            case "size":
                handleSelectSize(isSelected, item)
                break
            case "ply":
                handleSelectPly(isSelected, item)
                break
            default:
                break
        }
    }

    const resetLocally = () => {
        setResetBrand(!resetBrand) 
        setResetPosition(!resetPosition) 
        setResetModel(!resetModel)
        setResetSize(!resetSize)
        setResetPly(!resetPly)
    }

    return (
        <div style={{ height: "100%", width: "100%", paddingLeft: 10, paddingRight: 10 }}>
            <div style={{height:50 ,paddingTop: mobileView ? null : 15, paddingBottom: 0, borderBottom: '1px solid lightgrey', position: "relative" }}>
                <p style={{ display: 'inline-block', marginBottom: 10, color: 'black', fontSize: 16, fontWeight: "bold" }}>Filters</p>
                <a className={classes.resetFilter} style={{ position: "absolute", bottom: 13, right: 0 }} onClick={() => { resetAllFilters()}}>Reset All</a>
            </div>
            <div 
            className="hover-scrollbar"
            style={{ 
                paddingTop: 20, 
                paddingBottom: 20, 
                paddingRight: 10, 
                overflowX: "hidden", 
                overflowY: 'auto', 
                maxHeight: '100%',
                height: "calc(100% - 50px)"
            }}>
                {(brandList.length > 1 || (brandList.length == 1 && brandList[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Brand</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('brand'); setDeselectBrand(!deselectBrand) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('brand'); setResetBrand(!resetBrand) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {brandList.map(brand => (
                                <TireFilterCheckbox
                                    key={brand}
                                    checked={selectedBrands.indexOf(brand) > -1 ? false : true}
                                    type={"brand"}
                                    item={brand}
                                    reset={resetBrand}
                                    deselect={deselectBrand}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {(positionList.length > 1 || (positionList.length == 1 && positionList[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Position</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('position'); setDeselectPosition(!deselectPosition) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('position'); setResetPosition(!resetPosition) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {positionList.map(position => (
                                <TireFilterCheckbox
                                    key={position}
                                    checked={selectedPositions.indexOf(position) > -1 ? false : true}
                                    type={"position"}
                                    item={position}
                                    reset={resetPosition}
                                    deselect={deselectPosition}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {(modelList.length > 1 || (modelList.length == 1 && modelList[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Model</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('model'); setDeselectModel(!deselectModel) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('model'); setResetModel(!resetModel) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {modelList.map(model => (
                                <TireFilterCheckbox
                                    key={model}
                                    checked={selectedModels.indexOf(model) > -1 ? false : true}
                                    type={"model"}
                                    item={model}
                                    reset={resetModel}
                                    deselect={deselectModel}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {(sizeList.length > 1 || (sizeList.length == 1 && sizeList[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Size</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('size'); setDeselectSize(!deselectSize) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('size'); setResetSize(!resetSize) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {sizeList.map(size => (
                                <TireFilterCheckbox
                                    key={size}
                                    checked={selectedSizes.indexOf(size) > -1 ? false : true}
                                    type={"size"}
                                    item={size}
                                    reset={resetSize}
                                    deselect={deselectSize}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {(plyList.length > 1 || (plyList.length == 1 && plyList[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Ply</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('ply'); setDeselectPly(!deselectPly) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('ply'); setResetPly(!resetPly) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {plyList.map(ply => (
                                <TireFilterCheckbox
                                    key={ply}
                                    checked={selectedPlys.indexOf(ply) > -1 ? false : true}
                                    type={"ply"}
                                    item={ply}
                                    reset={resetPly}
                                    deselect={deselectPly}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    /*countryWideSearch: state.CarrierPortal.search.countryWideSearch*/
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(TireFilterMenuDesktop)


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}