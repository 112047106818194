import React from 'react';
import { FormControl} from 'react-bootstrap';
import '../App.css'

const BootstrapFieldInput = ({ input, meta, type, className,placeholder, min, max ,...props}) => {
    if(className!=undefined){var additionalClasses=className}else{var additionalClasses=''}
    return (
        <FormControl
        	className={'cleanInput mb-15 '+additionalClasses}
            type={type}
            placeholder={placeholder}
            min={min}
            max={max}
            value={input.value}
            onChange={input.onChange}
            {...props} />
    )
}

export default BootstrapFieldInput