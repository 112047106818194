import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';

import Checkbox from '@material-ui/core/Checkbox';

const FilterCheckbox = (props) => {

    const { type, item, onCheckboxSelect, reset, deselect, muiTheme, checked } = props

    const [value, setValue] = useState(checked)
    const [width, setWidth] = useState('auto')
    const isMountedForReset = useRef(false)
    const isMountedForDeselect = useRef(false)


    useEffect(() => {
        setWidth(type === 'destination' || type === 'origin' ? '50%' : 'auto')
    }, [])

    useEffect(()=>{
        if(isMountedForReset.current)
            setValue(true)
        else
            isMountedForReset.current = true
    }, [reset])

    useEffect(()=>{
        if(isMountedForDeselect.current)
            setValue(false)
        else
            isMountedForDeselect.current = true
    }, [deselect])

    const handleChange = (isSelected, item) => {
        setValue(isSelected)
        if (onCheckboxSelect) {
            onCheckboxSelect(type, isSelected, item)
        }
    }

    return (
        <div style={{ display: "flex", alignItems: "center", width: width }}>
            <Checkbox
                key={item + "_" + value}
                style={{ color: muiTheme.secondary, transform: "scale(1.5)" }}
                color="default"
                checked={value}
                onChange={(event) => handleChange(event.target.checked, item)}
            />
            <p style={{ fontSize: 14, margin: 0 }}>{item == null || item === "" ? "Unknown Equipment Type" : item}</p>
        </div>
    )
}


export default compose(
    muiThemeable()
)(FilterCheckbox)