import React, { useEffect, useState } from 'react';
import {bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import muiThemeable from 'material-ui/styles/muiThemeable';
import moment from 'moment'
import {compose} from 'recompose'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from "react-toastify"
import {INPUT_BACKGOUND_COLOR,INPUT_BORDER_COLOR,INPUT_GREY} from '../../CarrierPortal/palette'
import isMobile from '../../HOC/Mobile.js'

const TimePicker = ({muiTheme,onChange,timeValue=null,status,isMobile=false,label='select time',...props}) => {
    

    const useStyles = makeStyles((theme) => ({
        
        dateInutField: {
            border: '0.5px solid '+INPUT_BORDER_COLOR,
            //backgroundColor: INPUT_BACKGOUND_COLOR,
            height: '50px',
            width: isMobile ? '100%' : '100%',
            margin: '0px',
            //backgroundColor: 'white',
            paddingLeft: '0px',
            paddingRight: '0px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize:'1rem',
            borderTop:'0px',
            borderLeft:'0px',
            borderRight:'0px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            '& label': {
                top: '5px !important',
                //left: '10px !important',
            },
       
        }
    }));
    const classes = useStyles();

    const [value, setValue] = useState(moment());
    
    useEffect(() => {
        setValue(timeValue)
    }, [])

    const handleChangeTimeEvent = (event) => {
        let timeMoment;
        if(event.target.value === '' || event.target.value.length === 0){
            // toast.error("Clearing disabled, input required.")
            timeMoment = (typeof(value) === 'string' ? value : value.format("HH:mm"))
        } else {
            timeMoment = moment(event.target.value, "HH:mm");        
        }
        setValue(timeMoment)
        onChange(timeMoment)
    }

    return (
        <div>
            <form noValidate>
                <TextField
                    id="time"
                    variant="filled"
                    required="required"
                    required
                    label={label}
                    type="time"
                    value={ typeof(value) === 'string' ? value : value.format("HH:mm") }
                    className={classes.dateInutField}
                    onChange={handleChangeTimeEvent}
                    InputLabelProps={{
                        shrink: true,
                        style:{
                            fontSize: 16
                        }
                    }}
                    inputProps={{
                        style:{
                            fontSize: 16
                        }       
                    }}
                />
            </form>
        </div>
    )
}
const mapStateToProps = state => ({
  status:state.CarrierPortal.components.searchBoxSettings.error.date
})

function matchDispatchToProps(dispatch){
  return  bindActionCreators({
  },dispatch)
}

TimePicker.propTypes={
  value:PropTypes.string,//YYYY-MM-DD HHmm
  onChange:PropTypes.func//Returns Value of DatePicker
}
export default compose(
  connect(mapStateToProps,matchDispatchToProps),
  muiThemeable(),
  isMobile()
)(TimePicker)
