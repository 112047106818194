import { BACKEND_URL } from '../../../../api/constants'

export function getELDIntegration(MCNumber) {

  const url = BACKEND_URL + '/ELDIntegration/' + MCNumber;
  return fetch(url, {
    method: 'GET',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function authToken(code) {
  const url = BACKEND_URL + '/api/keeptruckin';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({code: code})
  })
    .then(response => {
      return response.json();
    });
}

export function createELDIntegration(payload) {
  const url = BACKEND_URL + '/ELDIntegration'
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function updateELDIntegration(payload) {
  const url = BACKEND_URL + '/ELDIntegration/' + payload.carrierMCNumber
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}