import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../HOC/Mobile.js'
import { withRouter } from "react-router-dom"

import InvoiceActionButton from './invoice-action-button';
import InvoiceModal from './invoice-modal';

const InvoiceActionContainer = (props) => {

    const {
        load,
        refreshInvoices,
        isMobile,
        muiTheme
    } = props

    const [currentStatus, setCurrentStatus] = useState(null)
    const [buttonText, setButtonText] = useState("View Invoice")
    const [allowEdit, setAllowEdit] = useState(false)
    const [allowSubmit, setAllowSubmit] = useState(false)
    const [statusFormatted, setStatusFormatted] = useState(null)
    const [statusMessageColor, setStatusMessageColor] = useState("black")
    const [statusMessage, setStatusMessage] = useState(null)


    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {

        //TODO: here is where we would check if a load# is being passed from the screen component
        //to open up an invoice form. This would happen from an email link. If there is a load# and 
        //it matches the one in this comp then we'll call handleOpenForm right away.
        //Desktop is just a modal but Mobile is a seperate screen because of the keyboard overlay problem.

        if (load.invoice && load.invoice.invoice_number){
            setStatusLogic(load.invoice.status)
            let message = null
            if( load.invoice.invoice_status && load.invoice.invoice_status.length > 0 )
                message = load.invoice.invoice_status[load.invoice.invoice_status.length - 1].notes
            setStatusMessage(message)  
        }
        else 
            setStatusLogic("created")

    }, [])

    const setStatusLogic = (status) => {

        switch (status) {
            case "created":
                setStatusFormatted("create")
                setAllowEdit(true)
                setAllowSubmit(true)
                setButtonText("Create Invoice")
                setCurrentStatus("created")
                break;
            case "save":
                setStatusMessageColor("green")
                setStatusFormatted("Invoice Saved")
                setAllowEdit(true)
                setAllowSubmit(true)
                setButtonText("View Invoice")
                setCurrentStatus("save")
                break;
            case "submitted":
                setStatusMessageColor("green")
                setStatusFormatted("Invoice Submitted")
                setAllowEdit(true)
                setAllowSubmit(true)
                setButtonText("View Invoice")
                setCurrentStatus("submitted")
                break;
            case "rejected":
                setStatusMessageColor("red")
                setStatusFormatted("Invoice Rejected")
                setAllowEdit(true)
                setAllowSubmit(true)
                setButtonText("Edit Invoice")
                setCurrentStatus("rejected")
                break;
            case "accepted":
                setStatusMessageColor("green")
                setStatusFormatted("Invoice Accepted")
                setAllowEdit(false)
                setAllowSubmit(false)
                setButtonText("View Invoice")
                setCurrentStatus("accepted")
                break;
            case "pending":
                setStatusMessageColor("green")
                setStatusFormatted("Payment Pending")
                setAllowEdit(false)
                setAllowSubmit(false)
                setButtonText("View Invoice")
                setCurrentStatus("pending")
                break;
            case "paid":
                setStatusMessageColor("green")
                setStatusFormatted("Paid in Full")
                setAllowEdit(false)
                setAllowSubmit(false)
                setButtonText("View Invoice")
                setCurrentStatus("paid")
                break;
        }
    }

    const handleOpenForm = () => {
        if(isMobile){
            props.history.push({
                pathname: '/invoiceFormMobile',
                state: { 
                    load: load,
                    currentStatus: currentStatus,
                    statusFormatted: statusFormatted,
                    statusMessage: statusMessage,
                    allowEdit: allowEdit,
                    allowSubmit: statusFormatted,
                    actionColor: muiTheme.actionColor,
                 }
              })
        }
        else {
            setOpenModal(true);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div>
            <Col style={{ height: '100%' }}>
                <Row>
                    <p style={{ color: statusMessageColor, fontWeight: 'bold', fontSize: 14, visibility: statusFormatted === "create" ? "hidden" : "visible", textAlign: 'center' }}>
                        {statusFormatted}
                    </p>
                </Row>
                <Row>
                    <InvoiceActionButton buttonText={buttonText} handleOpenForm={handleOpenForm} />
                </Row>
            </Col>
            {openModal &&
                <InvoiceModal
                    openModal={true}
                    handleCloseModal={handleCloseModal}
                    load={load}
                    currentStatus={currentStatus}
                    statusFormatted={statusFormatted}
                    statusMessage={statusMessage}
                    allowEdit={allowEdit}
                    allowSubmit={allowSubmit}
                    refreshInvoices={refreshInvoices}

                />
            }

        </div>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile(),
    withRouter
)(InvoiceActionContainer)