import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import isMobile from '../../../../../../HOC/Mobile.js'

import TruckCapacityCard from './TruckCapacityCard';
import TruckCapacityCardMobile from './TruckCapacityCardMobile';
import AddPreferenceModal from "../../werner-add-edit-modal"

// import useGtagHook from '../../../gtag-hook';

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
}));

const TruckCapacityItem = (props) => {

    const classes = useStyles()

    const { muiTheme, item, itemIndex, toggleSavedSearch, deleteSavedSearch, editSavedSearch, handleViewLoads, isMobile = false, updatePreferencesData, selectedLocationId } = props

    const [showEditModal, setShowEditModal] = useState(false)
    const [availability, setAvailability] = useState(item.visibility === 1 ? true : false);
    const [notificationsOn, setNotificationsOn] = useState(false);


    useEffect(() => {
        setNotificationsOn(item.receiveNotifications)
    }, [])

    const handleToggleNotification = (event) => {
        setNotificationsOn(event.target.checked)

        //todo: call api

        // useGtagHook(
        //     "toggle_notifications",
        //     {
        //         action: event.target.checked ? "TOGGLE_ON" : "TOGGLE_OFF",
        //         location: item.pickupCity + ", " + item.pickupState,
        //         isRecurring: checkValueExists(item, "pickupWeekDay"),
        //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
        //         pickupTime: item.availableTime,
        //         destinations: item.destinations,
        //         equipments: item.truckTypes
        //     }
        // )
    };

    const toggleVisibility = () => {
        setAvailability(!availability)
    }

    return (
        <div>
            <div className={classes.searchCardRow}>
                {!isMobile ?
                    <TruckCapacityCard
                        item={item}
                        handleViewLoads={handleViewLoads}
                        availability={availability}
                        notificationsOn={notificationsOn}
                        setShowEditModal={setShowEditModal}
                        handleToggleNotification={handleToggleNotification}
                        toggleVisibility={toggleVisibility}
                    />
                    :
                    <TruckCapacityCardMobile 
                        item={item}
                        handleViewLoads={handleViewLoads}
                        availability={availability}
                        notificationsOn={notificationsOn}
                        setShowEditModal={setShowEditModal}
                        handleToggleNotification={handleToggleNotification}
                        toggleVisibility={toggleVisibility}
                    />
                }
            </div>
            {showEditModal && (
                <AddPreferenceModal selectedLocationId={selectedLocationId} renderedTab={0} onClose={() => { setShowEditModal(false) }} editItem={item} preferenceType={"capacity"} updatePreferencesData={updatePreferencesData} />
            )}
        </div>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile()
)(TruckCapacityItem)