import {BACKEND_URL} from './constants.js';

export function saveOfferedLoad (formValues) {    
    const requestUrl=BACKEND_URL+'/api/postLoads';
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                                'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                                'Content-Type': 'application/json',
                              },         
                            body: JSON.stringify(formValues),                     
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}