import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { withStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { toast } from 'react-toastify';
import Popover from '@material-ui/core/Popover';

import NotificationsList from "./notification-list"

import * as NotificationsApi from '../../apis/api-notifications';
import {useAuthenticationHook} from '../../hooks/authentication-hook';
import isMobile from '../../../HOC/Mobile.js'


const NotificationIcon = (props) => {
    const { muiTheme, newNotificationItem, isMobile } = props

    const myAuthService = useAuthenticationHook()

    const StyledBadge = withStyles((theme) => ({
        badge: {
            right: !isMobile ? 3 : null,
            top: !isMobile ? 5 : null,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '6px 6px',
            backgroundColor: muiTheme.actionColor,
            fontSize: 12
        },
    }))(Badge);

    const [isLoading, setIsLoading] = useState(false)
    const [notificationsList, setNotificationsList] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [badgeCount, setBadgeCount] = useState(0)


    useEffect(() => {
        if (myAuthService.isAuthenticated()) {
            // fetch unread count and call setBadgeCount
            fetchNotificationList()
        }
        // else {
        //     // // TESTING ONLY
        //     setNotificationsList(mockResp.notifications) // TODO set back to empy - only set to mockResp for testing purposes
        //     setIsLoading(false)
        // }
    }, [])

    useEffect(() => {
        if (newNotificationItem) {
            setBadgeCount(badgeCount + 1);
            setNotificationsList([newNotificationItem, ...notificationsList])
            // console.log("HERE", notificationsList)
        }
    }, [newNotificationItem])

    const fetchNotificationList = () => {
        setIsLoading(true)

        // // TESTING ONLY
        // setNotificationsList(mockResp.notifications) // TODO set back to empy - only set to mockResp for testing purposes
        // setIsLoading(false)

        NotificationsApi.FetchNotifications(muiTheme.customerUniqueId).then(response => {
            if (response.status) {
                if (response.notifications && response.notifications.length > 0) {
                    setNotificationsList(response.notifications)
                    fetchUnreadNotificationCount()
                    setIsLoading(false)
                } else {
                    // no subs found - open new subs screen
                    setNotificationsList([])
                    setIsLoading(false)
                }

            } else {
                // no subs found - open new subs screen
                setNotificationsList([])
                setIsLoading(false)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem fetching your notifications."
            setNotificationsList([])
            setIsLoading(false)
            toast.error(errorMessage)
        })
    }

    const clearAllNotifications = () => {
        let IDs = []
        notificationsList.forEach(notification => {
            IDs.push(notification.notificationId)
        })
        setNotificationsList([])

        IDs.forEach(id => callDeleteNotificationApi(id))
    }

    const clearSingleNotification = (notificationId, index) => {
        notificationsList.splice(index, 1)
        setNotificationsList(notificationsList)
        callDeleteNotificationApi(notificationId)
    }

    const callDeleteNotificationApi = (notificationId) => {
        if (notificationId == null) return
        let params = {
            notificationId: notificationId,
            advantageId: muiTheme.customerUniqueId
        }
        NotificationsApi.DeleteNotification(params, (response) => {
            if (response.ok) {
                if (response.data.status) {
                    //if success do nothing
                } else {
                    let errorMessage = response.errorMsg ? response.errorMsg : "There was a problem deleting your notification"
                    toast.error(errorMessage)
                }
            } else {
                let errorMessage = response.errorMsg ? response.errorMsg : "There was a problem deleting your notification"
                toast.error(errorMessage)
            }
        }).catch((error) => {
            let errorMessage = "There was a problem deleting your notification"
            toast.error(errorMessage)
        })
    }

    const markNotificationsRead = () => {
        // loop through notifications and call readNotifications api with each notification ID
        let notificationIdArray = []
        notificationsList.forEach(notification => {
            notificationIdArray.push(notification.notificationId)
        })

        NotificationsApi.PostNotificationSeen({ notificationId: notificationIdArray, advantageId: muiTheme.customerUniqueId }, (response) => {
            if (response.ok) {

            }
        }).catch(error => {

        })

        setBadgeCount(0)
    }

    const fetchUnreadNotificationCount = () => {
        NotificationsApi.FetchUNreadNotification(muiTheme.customerUniqueId, (response) => {
            if (response.ok) {
                if (response.data.status) {
                    if (response.data.count) {
                        setBadgeCount(response.data.count)
                    }
                }
            }
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        markNotificationsRead()
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNotificationClick = (type, itemId = null) => {
        // navigate to preferences screen and pass ID and type as navigation parameter to trigger useEffect to redirect to Find & Book Loads
        // console.log("notification item click")
        switch (type) {
            case "capacity":
                props.history.push("/preferences?type=capacity&id=" + itemId)
                break
            case "lane":
                props.history.push("/preferences?type=lane&id=" + itemId)
                break
            case "broker_carrier_communication":
                props.history.push("/load?id=" + itemId)
                break
            default:
                break
        }



    }

    const open = Boolean(anchorEl);
    const id = open ? 'notification-list-popover' : undefined;

    return (
        <div>
            <IconButton aria-label="bell" onClick={handleClick} style={{ padding: isMobile ? 0 : null }}>
                <StyledBadge badgeContent={badgeCount} color="secondary">
                    <FA name='bell' size={isMobile ? 'lg' : '2x'} color={isMobile ? "white" : "black"} />
                </StyledBadge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div style={{ width: isMobile ? null : 400, height: 260 }}>
                    {isLoading && (
                        <p>Fetching your notifications</p>
                    )}
                        <NotificationsList
                            notificationList={notificationsList}
                            onNotificationClick={(type, itemId) => handleNotificationClick(type, itemId)}
                            clearAll={() => { clearAllNotifications() }}
                            clearSingleNotification={(id, index) => { clearSingleNotification(id, index) }}
                        />
                </div>
            </Popover>
        </div>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(NotificationIcon)
