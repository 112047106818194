import React, { useRef, useEffect, useState } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'

//COMPONENTS
import LoadSearchBar from '.';


const LoadSearchMobileWrapper = (props) => {

    const { onHomePage = false, buttonColor = false } = props

    const useStyles = makeStyles({
        container: {

        }
    });
    const classes = useStyles()

    const [showLoadSearchPopover, setShowLoadSearchPopover] = useState(false)
    const [searchAnchorEL, setSearchAnchorEl] = useState(null)


    return (
        <div>
            <Button
                onClick={(event) => {
                    setShowLoadSearchPopover(true)
                    setSearchAnchorEl(event.currentTarget)
                }}
                style={{ paddingLeft: 4, backgroundColor: buttonColor ? buttonColor : null }}
                disableRipple={false}
            >

                <FA name="search" size='2x' style={{ color: buttonColor ? 'white' : 'black' }} />
                <p style={{ fontSize: 14, paddingLeft: 5, color: buttonColor ? 'white' : 'black' }}>Search</p>
            </Button>
            <Popover
                id="search_popover"
                PaperProps={{ style: { width: '90%' } }}
                open={showLoadSearchPopover}
                anchorEl={searchAnchorEL}
                onClose={() => { setSearchAnchorEl(null); setShowLoadSearchPopover(false) }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >

                <LoadSearchBar
                    mobileView={true}
                    setShowLoadSearchPopover={setShowLoadSearchPopover}
                    onHomePage={onHomePage}
                />


            </Popover>
        </div>
    )
}

export default LoadSearchMobileWrapper
