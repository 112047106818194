import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'

import FilterUI from "./filter-ui"

import useGtagHook from '../../../../gtag-hook';

import {
    setFuelFranchiseFilter,
    setFuelAmentityFilter,
    setFuelDeadheadFilter,
    setFuelDieselFilter,
    resetAllFuelFilters,
} from "../../../actions"
import { checkValueExists } from '../../../../lib/check-value-exists';

const FilterMenu = (props) => {

    const {
        mobileView = false,
        muiTheme,
        deadHeadMin,
        deadHeadMax,
        dieselMin,
        dieselMax,
    } = props
    const [showFilterPopover, setShowFilterPopover] = useState(false)
    const [filterAnchorEL, setFilterAnchorEl] = useState(null)

    const [selectedFranchises, setSelectedFranchises] = useState([])
    const [selectedAmenities, setSelectedAmenities] = useState([])

    const [deadheadRange, setDeadheadRange] = React.useState([Math.ceil(deadHeadMin), Math.ceil(deadHeadMax)]);
    const [dieselRange, setDieselRange] = React.useState([dieselMin, dieselMax]);
    const [resetFilterComp, setResetFilterComp] = useState(false)

    const [sendGtagEvent] = useGtagHook()

    useEffect(() => {
        resetAllFilters(false, true)
        setResetFilterComp(!resetFilterComp)
    }, [props.fuelStopList])

    const updateFilters = (type, payload) => {
        switch (type) {
            case "franchise":
                props.setFuelFranchiseFilter(payload)
                break
            case "amenity":
                props.setFuelAmentityFilter(payload)
                break
            case "deadhead":
                props.setFuelDeadheadFilter(payload)
                break
            case "diesel":
                props.setFuelDieselFilter(payload)
                break
            default:
                break
        }
    }


    const resetAllFilters = (shouldLog = true, onMount = false) => {
        props.resetAllFuelFilters(onMount)

        let tmpDiesel = [dieselMin, dieselMax]
        setDieselRange(tmpDiesel);

        let tmpDeadhead = [Math.floor(deadHeadMin), Math.ceil(deadHeadMax)]
        setDeadheadRange(tmpDeadhead)

        props.allFranchises.forEach(franchise => handleSelectFranchise(true, franchise, false))

        props.allAmenities.forEach(amenity => handleSelectAmenity(true, amenity, false))

        if (shouldLog) {
            sendGtagEvent(
                "filter_fuel_stops_reset",
                {
                    category: "ALL"
                }
            )
        }
    }

    const resetFilter = (type, shouldLog = true) => {
        let newValue
        switch (type) {
            case 'diesel':
                let tmpDiesel = [dieselMin, dieselMax]
                updateFilters("diesel", tmpDiesel)
                setDieselRange(tmpDiesel);
                newValue = tmpDiesel
                break
            case 'deadhead':
                let tmpDeadhead = [Math.floor(deadHeadMin), Math.ceil(deadHeadMax)]
                updateFilters("deadhead", tmpDeadhead)
                setDeadheadRange(tmpDeadhead)
                newValue = tmpDeadhead
                break
            case 'franchise':
                updateFilters("franchise", [])
                props.allFranchises.forEach(franchise => handleSelectFranchise(true, franchise))
                newValue = []
                break
            case 'amenity':
                updateFilters("amenity", [])
                props.allAmenities.forEach(amenity => handleSelectAmenity(true, amenity))
                newValue = []
                break
            default:
                break
        }
        if (shouldLog) {
            sendGtagEvent(
                "filter_fuel_stops_reset",
                {
                    category: type,
                    value: newValue
                }
            )
        }
    }


    const deselectAllCheckboxes = (type, shouldLog = true) => {
        let newValue
        switch (type) {
            case 'franchise':
                updateFilters("franchise", props.allFranchises)
                props.allFranchises.forEach(franchise => handleSelectFranchise(false, franchise))
                newValue = props.allFranchises
                break
            case 'amenity':
                updateFilters("amenity", props.allAmenities)
                props.allAmenities.forEach(amenity => handleSelectAmenity(false, amenity))
                newValue = props.allAmenities
                break
            default:
                break
        }
        if (shouldLog) {
            sendGtagEvent(
                "filter_fuel_stops_deselect_all_checkboxes",
                {
                    category: type,
                    value: newValue
                }
            )
        }
    }

    const handleDeadheadChange = (event, newValue) => {
        setDeadheadRange(newValue);
    };

    const handleDieselChange = (event, newValue) => {
        setDieselRange(newValue);
    };

    const handleSelectFranchise = (isSelected, franchise, callRedux = true) => {
        let prevVal = selectedFranchises

        if (isSelected) {
            prevVal.splice(prevVal.indexOf(franchise), 1)
        } else {
            if (prevVal.indexOf(franchise) < 0) {
                prevVal.push(franchise)
            }
        }
        setSelectedFranchises(prevVal)

        if (callRedux)
            updateFilters("franchise", prevVal)

        sendGtagEvent(
            "filter_fuel_stops_franchise",
            {
                category: "franchise",
                type: "checkbox",
                value: prevVal,
                valueType: "exclusive"
            }
        )
    }

    const handleSelectAmenity = (isSelected, amenity, callRedux = true) => {
        let prevVal = selectedAmenities

        if (isSelected) {
            prevVal.splice(prevVal.indexOf(amenity), 1)
        } else {
            if (prevVal.indexOf(amenity) < 0) {
                prevVal.push(amenity)
            }
        }
        setSelectedAmenities(prevVal)

        if (callRedux)
            updateFilters("amenity", prevVal)

        sendGtagEvent(
            "filter_fuel_stops_amenity",
            {
                category: "amenities",
                type: "checkbox",
                value: prevVal,
                valueType: "exclusive"
            }
        )
    }

    return (
        <div>

            { !mobileView ?
                <div>
                    <FilterUI
                        key={resetFilterComp}
                        updateFuelStopFilters={(type, payload) => updateFilters(type, payload)}
                        allFranchises={props.allFranchises}
                        allAmenities={props.allAmenities}
                        deadHeadMin={props.deadHeadMin}
                        deadHeadMax={props.deadHeadMax}
                        dieselMin={props.dieselMin}
                        dieselMax={props.dieselMax}
                        paddingTop={props.paddingTop}
                        handleSelectFranchise={handleSelectFranchise}
                        selectedFranchises={selectedFranchises}
                        handleSelectAmenity={handleSelectAmenity}
                        selectedAmenities={selectedAmenities}
                        resetAllFilters={resetAllFilters}
                        resetFilter={resetFilter}
                        deselectAllCheckboxes={deselectAllCheckboxes}
                        handleDieselChange={handleDieselChange}
                        handleDeadheadChange={handleDeadheadChange}
                        deadheadRange={deadheadRange}
                        dieselRange={dieselRange}
                    />

                </div>
                :
                <div>
                    <Button
                        onClick={(anchor) => {
                            setShowFilterPopover(true)
                            setFilterAnchorEl(anchor)
                        }}
                        style={{ paddingLeft: 4 }}
                        disableRipple={false}
                    >
                        <p style={{ fontSize: 14, paddingLeft: 5 }}>Filters</p>
                        <FA name="filter" size='2x' style={{ color: 'black' }} />
                    </Button>
                    <Popover
                        id="filter_popover"
                        PaperProps={{ style: { width: '90%', display: '' } }}
                        open={showFilterPopover}
                        anchorEl={filterAnchorEL}
                        onClose={() => { setFilterAnchorEl(null); setShowFilterPopover(false) }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >

                        <Button
                            onClick={(anchor) => {
                                setShowFilterPopover(false)
                                setFilterAnchorEl(null)
                            }}
                            style={{ width: '100%', justifyContent: 'flex-end' }}
                            disableRipple={true}
                        >

                            <FA name="times" size='2x' />
                        </Button>

                        <FilterUI
                            key={resetFilterComp}
                            updateFuelStopFilters={(type, payload) => updateFilters(type, payload)}
                            allFranchises={props.allFranchises}
                            allAmenities={props.allAmenities}
                            deadHeadMin={props.deadHeadMin}
                            deadHeadMax={props.deadHeadMax}
                            dieselMin={props.dieselMin}
                            dieselMax={props.dieselMax}
                            paddingTop={props.paddingTop}
                            handleSelectFranchise={handleSelectFranchise}
                            selectedFranchises={selectedFranchises}
                            handleSelectAmenity={handleSelectAmenity}
                            selectedAmenities={selectedAmenities}
                            resetAllFilters={resetAllFilters}
                            resetFilter={resetFilter}
                            deselectAllCheckboxes={deselectAllCheckboxes}
                            handleDieselChange={handleDieselChange}
                            handleDeadheadChange={handleDeadheadChange}
                            deadheadRange={deadheadRange}
                            dieselRange={dieselRange}
                            mobileView={true}
                        />
                    </Popover>
                </div>





            }
        </div>
    )
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setFuelFranchiseFilter: setFuelFranchiseFilter,
        setFuelAmentityFilter: setFuelAmentityFilter,
        setFuelDeadheadFilter: setFuelDeadheadFilter,
        setFuelDieselFilter: setFuelDieselFilter,
        resetAllFuelFilters: resetAllFuelFilters
    }, dispatch)
}

export default compose(
    muiThemeable(),
    connect(mapStateToProps, matchDispatchToProps),
    withProps(props => {
        let franchiseListTmp = []
        let amenityListTmp = []
        let deadHeadListTmp = []
        let dieselListTmp = []

        if (props.fuelStopList) {
            props.fuelStopList.forEach((fuelStop) => {
                if (franchiseListTmp.indexOf(fuelStop.franchise_name) < 0) {
                    franchiseListTmp.push(fuelStop.franchise_name)
                }

                // TODO
                // if (amenityListTmp.indexOf(fuelStop.metadata) < 0) {
                //     amenityListTmp.push(fuelStop.metadata)
                // }

                if (checkValueExists(fuelStop, "metadata") &&
                    checkValueExists(fuelStop.metadata, "distance")) {
                    deadHeadListTmp.push(fuelStop['metadata']['distance'])
                }

                if (checkValueExists(fuelStop, "metadata") &&
                    checkValueExists(fuelStop.metadata, "price") &&
                    checkValueExists(fuelStop.metadata.price, "diesel")) {
                    dieselListTmp.push(fuelStop['metadata']['price']['diesel']*1000)
                }

            })
        }

        return ({
            ...{
                allFranchises: franchiseListTmp,
                allAmenities: amenityListTmp,
                deadHeadMin: deadHeadListTmp.length > 0 ? Math.min.apply(null, deadHeadListTmp) : null,
                deadHeadMax: deadHeadListTmp.length > 0 ? Math.max.apply(null, deadHeadListTmp) : null,
                dieselMin: dieselListTmp.length > 0 ? Math.min.apply(null, dieselListTmp) : null,
                dieselMax: dieselListTmp.length > 0 ? Math.max.apply(null, dieselListTmp) : null
            },
            ...props
        })
    }),
)(FilterMenu)