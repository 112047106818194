import React from 'react';
import WernerPreferences from "../components/preferences/werner"
import GeneralPreferences from "../components/preferences/general"


const PreferencesScreen = (props) => {

    return (
        <div style={{ height: '100%' }}>
            {process.env.REACT_APP_ADVANTAGE_ID == "a98db973" ?
                <WernerPreferences {...props} />
                :
                <GeneralPreferences {...props} />
            }
        </div>
    )
}

export default PreferencesScreen