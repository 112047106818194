
import React, { useState, useEffect } from 'react';
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import isMobile from '../../../../../../HOC/Mobile.js'
import muiThemeable from 'material-ui/styles/muiThemeable';

import QuoteForm from "../../../quotes/quoteForm"
import { getQuoteHistory } from '../../../../../../components/Quote/actions'
import RateNegotiations from '../../../quotes/RateNegotiations';


const QuotesTab = (props) => {
    const {
        load,
        quotePage,
        setQuotePage,
        updateQuoteHistory,
        removeLoadFromList,
        callRefreshCard
    } = props


    const [showQuoteHistory, setShowQuoteHistory] = useState(true)
    const [showAddQuoteBtn, setShowAddQuoteBtn] = useState(true)

    const [called, setCalled] = useState("")
    const [forceRefresh, setForceRefresh] = useState(true)

    useEffect(() => {
        if ((showQuoteHistory && !called) || forceRefresh) {
            setCalled(true)
            updateQuoteHistory()
            setForceRefresh(false)
        }
    }, [showQuoteHistory, forceRefresh])

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {quotePage == "history" && (
                
                <RateNegotiations
                    load={load}
                    setShowAddQuoteBtn={setShowAddQuoteBtn}
                    showAddQuoteBtn={showAddQuoteBtn}
                    setQuotePage={setQuotePage}
                    removeLoadFromList={removeLoadFromList}
                    callRefreshCard={callRefreshCard}
                    {...props}
                />
            )}
            {quotePage == "form" && (
                <QuoteForm
                    _onClose={() => {
                        setShowQuoteHistory(false)
                        setQuotePage("history")
                    }}
                    _forceHistoryRefresh={setForceRefresh}
                    callRefreshCard={callRefreshCard}
                    {...props}
                />
            )}
        </div>

    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
    quotesHistory: state.QuoteReducer.get.payload,
    isQuotesLoading: state.QuoteReducer.get.requesting,

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuoteHistory: getQuoteHistory
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    withHandlers({
        updateQuoteHistory: props => () => {
            props.getQuoteHistory([props.load.id], props.carriers, false, props.brokers, props.availabilities) // dont have all of these props
        }
    }),
    isMobile(),
    muiThemeable(),
)(QuotesTab)