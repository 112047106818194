
import React from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Col, Row } from "react-bootstrap"
import FA from 'react-fontawesome'
import useGtagHook from "../../../../../../gtag-hook"
import Button from '@material-ui/core/Button';
import RateNegotiationsListItem from './RateNegotiationsListItem';


const RateNegotiationsListDesktop = (props) => {

    const { 
        quoteList,
        load, 
        notInterested,
        setQuotePage, 
        showAddQuoteBtn, 
        fetchAllQuotes,
        buildingList,
        isLoading, 
        userProfile,
        muiTheme, 
    } = props

    const classes = useStyles();
    const [sendGtagEvent] = useGtagHook()

    return (
        <Col style={{height: '100%'}}>

            <Col style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                <Col style={{ height: showAddQuoteBtn ? 'auto' : '15%', width: '100%' }}>
                    <Col style={{ width: showAddQuoteBtn ? '100%' : '70%', display: 'inline-flex' }}>
                        <p className={classes.mainText} style={{ width: showAddQuoteBtn ? '100%' : '70%', fontSize: 16 }}>Offer History - Load #{load.externalId}</p>
                        {!isLoading && !buildingList && quoteList.length > 0 &&
                            <p className={classes.mainText} style={{ width: '30%', float: 'right', fontSize: 16, textAlign: 'right', paddingRight: 35 }}>
                                Last Offer ${quoteList[0].quote_value}
                            </p>
                        }
                    </Col>
                    {/* { !showAddQuoteBtn && quoteList.length > 0 &&
                        <Col style={{ width: '30%', display: 'inline-flex', flexDirection: 'column' }}>
                            <Button
                                variant="contained"
                                style={{
                                    color: muiTheme.actionColor,
                                    backgroundColor: 'transparent',
                                    borderColor: muiTheme.actionColor,
                                    border: '1px solid',
                                    fontSize: 14
                                }}
                                onClick={() => {
                                    notInterested()
                                    //todo: add GA event log
                                }}
                            >Not Interested
                            </Button>
                        </Col>
                    } */}
                </Col>
                {showAddQuoteBtn &&
                    <Col style={{height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 25}}>
                        <Button
                            variant="contained"
                            style={{
                                color: "white",
                                backgroundColor: muiTheme.actionColor,
                                borderColor: muiTheme.actionColor,
                                fontSize: 14
                            }}
                            onClick={() => {
                                setQuotePage("form");
                                sendGtagEvent("open_quote", { loadId: load.loadId, brokerId: load.brokerId })
                            }}
                        >Add A Quote
                        </Button>
                    </Col>
                }
                {!isLoading && !buildingList ?
                    <Col style={{ height: '85%' }}>
                        {quoteList.length > 0 && 
                            <Col style={{ height: '100%', width: '100%' }}>
                                <Row style={{ height: '10%', display: 'flex', borderBottom: '2px solid black', paddingTop: 15, paddingLeft: 5, paddingRight: 5 }}>
                                    <Col style={{ width: "19%" }}>
                                        <p>Latest Offer</p>
                                    </Col>
                                    <Col style={{ width: "27%" }}>
                                        <p>Latest Status</p>
                                    </Col>
                                    <Col style={{ width: "27%" }}>
                                        <p>Message</p>
                                    </Col>
                                    <Col style={{ width: "27%" }}>
                                        <p style={{ textAlign: 'center' }}>Actions</p>
                                    </Col>
                                </Row>

                                <Col style={{overflowY: 'auto', overflowX: 'hidden', maxHeight: 350}}>
                                    {
                                        quoteList.map((quote, index) => {
                                            if (quote.quote_value != null) {
                                                return (

                                                    <Row style={{ display: 'flex', borderBottom: index !== quoteList.length - 1 ? '1px dashed black' : 'none', paddingTop: 5, paddingLeft: 5, paddingRight: 5 }}>
                                                        <RateNegotiationsListItem
                                                            quote={quote}
                                                            load={load}
                                                            userProfile={userProfile}
                                                            latestOffer={index === 0 ? true : false}
                                                            fetchAllQuotes={fetchAllQuotes}
                                                            {...props}
                                                        />
                                                    </Row>
                                                )
                                            } else
                                                return null

                                        })
                                    }
                                </Col>
                            </Col>
                           
                        }
                    </Col>
                    :
                    <Col style={{ width: '100%', paddingTop: 25 }}>

                        <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                            <FA name='spinner' spin />
                        </p>

                    </Col>
                }
            </Col>

        </Col>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiationsListDesktop)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    }
});