import {BACKEND_URL} from '../../api/constants.js'
export function updateAvailContact(availabilityId, phone, email){
    var requestUrl = BACKEND_URL+'/api/carrier/updateCarrierContact'
    var payload = {
        contactPhone:phone,
        contactEmail:email,
        availabilityId:availabilityId
    }
   return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                                'Content-Type': 'application/json',
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
