import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Row, Col } from 'react-bootstrap';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import { Card } from 'material-ui/Card';
import { makeStyles } from '@material-ui/core/styles';
import { checkValueExists } from "../../../lib/check-value-exists"
import moment from "moment"

const SEARCH_CARD_HEIGHT = 60

const useStyles = makeStyles((style) => ({
    container: {
        height: SEARCH_CARD_HEIGHT,
        margin: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer"
    },
    card: {
        width: "100%",
        display: "flex",
        height: SEARCH_CARD_HEIGHT,
        flexDirection: "column",
        paddingLeft: 10,
        paddingRight: 10
    },
    bodyText: {
        display: "inline-block",
        textOverflow: "ellipsis",
        wordWrap: "break-word",
        overflow: "hidden",
        lineHeight: '16px', /* fallback */
        maxHeight: '32px', /* fallback */
        maxLines: 2,
        webkitLineClamp: 2, /* number of lines to show */
        webkitBoxOrient: "vertical",
    }
}));

const NotificationsListItem = (props) => {

    const classes = useStyles()

    const { muiTheme, item, index, onNotificationClick, onDelete } = props

    const getNotificationTimeText = () => {
        let returnText = ""
        if (item.timeStamp.date) {
            returnText = moment.tz(item.timeStamp.date, "America/New_York").local().fromNow()
        }
        return returnText
    }

    const getCardBody = () => {
        if (item.body == null) return
        let startIndex = item.body.indexOf("'")
        let endIndex = item.body.indexOf(".")
        let firstTxt = item.body.slice(0, startIndex)
        let cityState = item.body.slice(startIndex + 1, endIndex - 1)
        let secondTxt = item.body.slice(endIndex + 1)

        return (
            <Row>
                <p className={classes.bodyText}>{ item.savedSearchId ? firstTxt + "\n" + cityState + "\n" + secondTxt : item.body}</p>
            </Row>
        )

    }

    const deleteNotification = () => {
        onDelete(item.notificationId, index)
    }
    
    const handleClick = () => {
        onNotificationClick(item.notificationType, item.itemId)
    }

    return (
        <div className={classes.container} onClick={() => handleClick()} >
            <Card style={{ width: "100%", padding: 0 }}>
                <Col className={classes.card}>
                    <Row style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                        <p style={{ textAlign: "right", paddingBottom: 5, fontWeight: 'bold' }}>received {getNotificationTimeText()}</p>
                    </Row>
                    {getCardBody()}
                </Col>
            </Card>
        </div>
    )
}

export default compose(
    connect(),
    muiThemeable()
)(NotificationsListItem)