import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose, withProps } from 'recompose'
import isMobile from '../../../../HOC/Mobile.js'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Col, Row} from 'react-bootstrap';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import momentTz from 'moment-timezone'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TimePicker from '../../../components/time-picker'
import TextField from '@material-ui/core/TextField';
import { toast } from "react-toastify"
import Grid from '@material-ui/core/Grid';
import { checkValueExists } from '../../../../lib/check-value-exists';

import {INPUT_BORDER_COLOR,INPUT_GREY} from '../../../../CarrierPortal/palette'

function getUserTzName(){
    var tzGuess = momentTz.tz.guess()
    var abbr  = momentTz.tz(tzGuess).zoneAbbr()
    return abbr
}

function getTime (value){

    return    moment(
                        value,
                        'HH:mm'
                    ).format('HH:mm')//+getUserTzName()
}


function getDate (value){

    return    moment(
                        value,
                    ).format('YYYY-MM-DD')
}

export const  allRange = {
    start:'00:00' ,
    end:'23:59' ,
}
export const  morningRange = {
    start:'04:00' ,
    end:'11:00' ,
}
export const  afternoonRange = {
    start:'11:01' ,
    end:'15:00' ,
}
export const  eveningRange ={
    start:'15:01' ,
    end:'21:00' ,

}

const  defaultData = {
    specificTimeRange:['all'],
    specificTimeRangeValues:allRange,
    isCustomeTimeRange:false,
    customeTimeRangeStart:moment(),//.add(2, 'hours'),
    customeTimeRangeEnd:moment(),//.add(8, 'hours'),
    dateAvailable:moment(),
    daysOfWeek:[]
}


export function defaultDataDate (){

    let payload = defaultData
    if(checkValueExists(payload,'customeTimeRangeStart')){
        payload.customeTimeRangeStart = (payload.customeTimeRangeStart)
    }
    if(checkValueExists(payload,'customeTimeRangeEnd')){
        payload.customeTimeRangeEnd = (payload.customeTimeRangeEnd)
    }
    if(checkValueExists(payload,'dateAvailable')){
        payload.dateAvailable = getDate(payload.dateAvailable)
    }

    return payload
}

export const getTimeRangeText = (date)=>{
    let specificTimeRangeTxt = '';
    if(date.timeFrom == allRange.start && date.timeTo == allRange.end){
        specificTimeRangeTxt = 'All day'
    }else if(date.timeFrom == morningRange.start && date.timeTo == morningRange.end){
        specificTimeRangeTxt = 'Morning'
    }else if(date.timeFrom == afternoonRange.start && date.timeTo == afternoonRange.end){
        specificTimeRangeTxt = 'Afternoon'
    }else if(date.timeFrom == eveningRange.start && date.timeTo == eveningRange.end){
        specificTimeRangeTxt = 'Evening'
    }else if(date.timeFrom == morningRange.start && date.timeTo == afternoonRange.end){
        specificTimeRangeTxt = 'Morning - Afternoon'
    }else if(date.timeFrom == afternoonRange.start && date.timeTo == eveningRange.end){
        specificTimeRangeTxt = 'Afternoon - Evening'
    }
    return specificTimeRangeTxt
} 

export function resetInitValue (date){
    if(date){
        let isCustomeTimeRange = false
        let specificTimeRange = ['all']
        if(!date.date){
            date.date = moment().format('YYYY-MM-DD')
        }
        if(date.timeFrom == null){
            date.timeFrom = moment().format('HH:mm')
        }
        if(date.timeTo == null){
            date.timeTo = moment().format('HH:mm')
        }

        if(date.timeFrom == allRange.start && date.timeTo == allRange.end){
            isCustomeTimeRange = false
            specificTimeRange = ['all']
        }else if(date.timeFrom == morningRange.start && date.timeTo == morningRange.end){
            isCustomeTimeRange = false
            specificTimeRange = ['morning']
        }else if(date.timeFrom == afternoonRange.start && date.timeTo == afternoonRange.end){
            isCustomeTimeRange = false
            specificTimeRange = ['afternoon']
        }else if(date.timeFrom == eveningRange.start && date.timeTo == eveningRange.end){
            isCustomeTimeRange = false
            specificTimeRange = ['evening']
        }else if(date.timeFrom == morningRange.start && date.timeTo == afternoonRange.end){
            isCustomeTimeRange = false
            specificTimeRange = ['morning','afternoon']
        }else if(date.timeFrom == afternoonRange.start && date.timeTo == eveningRange.end){
            isCustomeTimeRange = false
            specificTimeRange = ['afternoon','evening']
        }else{
            isCustomeTimeRange = true
            specificTimeRange = ['all']
        }
        date.specificTimeRangeValues = {start:date.timeFrom,end:date.timeTo}

        date.specificTimeRange = specificTimeRange
        date.isCustomeTimeRange = isCustomeTimeRange
        
        date.customeTimeRangeStart =date.timeFrom// moment(date.date+' '+date.timeFrom)//.add(2, 'hours'),
        date.customeTimeRangeEnd =date.timeTo // moment(date.date+' '+date.timeTo)//.add(8, 'hours'),
        date.dateAvailable = date.date
        date.daysOfWeek  = date.weekDays?date.weekDays : (date.daysOfWeek?date.daysOfWeek: [])
    }
    return date
}

const AvailableDateTimePicker = (props) => {

    const { muiTheme, isMobile,  initialValues=null,type='CAPACITY' , onSetCallBack=null } = props

    const useStyles = makeStyles((theme) => ({
        dateInputField: {
            border: '0.5px solid '+INPUT_BORDER_COLOR,
            height: '50px',
            width: isMobile ? '100%' : '100%',
            margin: 0,
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize:'1rem',
            borderTop:'0px',
            borderLeft:'0px',
            borderRight:'0px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            '& p': {
                fontSize: '14px !important'
            },
            '& label': {
                top: '5px !important',
            },
        },
        dayButton: {
            fontSize: 16
        }
    }));


    const classes = useStyles()


    const [date, setDate] = useState(moment())
    const [isCustomRange, setIsCustomRange] = useState(false);
    const [specificTimeRangeValues, setSpecificTimeRangeValues] = useState(defaultData.specificTimeRangeValues);
    const [specificTimeRange, setSpecificTimeRange] = useState(defaultData.specificTimeRange);
    const [rangeStart,setRangeStart] = useState(defaultData.customeTimeRangeStart);
    const [rangeEnd,setRangeEnd] = useState(defaultData.customeTimeRangeEnd);
    const [availableDays, setAvailableDays] = useState([]);

    const [marginTopValue, setMarginTopValue] = useState(0);
    const [leftPaddingValue, setLeftPaddingValue] = useState(0);
    const [spacingPadding, setSpacingPadding] = useState(5);

    const [formPayload, setFormPayload] = useState(defaultData);

    useEffect(() => {
        setMarginTopValue(isMobile?10:0)
        setSpacingPadding(isMobile?0:5)
        setLeftPaddingValue(5)
        
    }, [isMobile])

    useEffect(() => {
        if (initialValues) {
            setDate(initialValues.dateAvailable)
            setRangeStart(initialValues.customeTimeRangeStart)
            setRangeEnd(initialValues.customeTimeRangeEnd)
            setAvailableDays(initialValues.daysOfWeek)
            setSpecificTimeRangeValues(initialValues.specificTimeRangeValues)
            setSpecificTimeRange(initialValues.specificTimeRange)
            setIsCustomRange(initialValues.isCustomeTimeRange)
            setFormPayload(initialValues)
        }
    }, [initialValues])

    const handleDateChangeEvent = (event) => {
        let dateMoment;
        if(event.target.value === '' || event.target.value.length === 0){
            // toast.error("Clearing disabled, input required.")
            dateMoment = typeof(date) === 'string' ? date : date.format('YYYY-MM-DD')
        }else{
            dateMoment = moment(event.target.value, "YYYY-MM-DD");
        }  
        setDate(dateMoment);
        popuateFormPayload({dateAvailable:dateMoment})
    };

    const handleTimeRangeType = () => {
        setIsCustomRange(!isCustomRange);
        popuateFormPayload({isCustomeTimeRange:!isCustomRange})
    };


    const handleSpecificTimeRangeValues = (event, newValues) => {
        if (newValues.length) {
            if(newValues[newValues.length-1] ==="all"){
                newValues = ["all"]
            }
            else if(newValues[newValues.length-1] !=="all" && newValues.includes('all') ){
                newValues.splice(newValues.indexOf('all'), 1)
            }
            else if( newValues.includes('morning') && newValues.includes('afternoon') && newValues.includes('evening') ){
                newValues = ["all"]
            }


            let selectedRange = null
            if(newValues.length==1){
                if(newValues.includes('all')){
                    selectedRange = allRange
                }
                if(newValues.includes('evening')  ){
                    selectedRange = eveningRange
                }else if(newValues.includes('afternoon')){
                    selectedRange = afternoonRange
                }else if(newValues.includes('morning')){
                    selectedRange = morningRange
                }
            }else if(newValues.length==2){
                if(newValues.includes('morning')  && newValues.includes('afternoon')){
                    selectedRange = {
                                    start:morningRange.start,
                                    end:afternoonRange.end
                                }
                }
                if(newValues.includes('evening')  && newValues.includes('afternoon')){
                    selectedRange = {
                                    start:afternoonRange.start,
                                    end:eveningRange.end
                                }
                }
            }
            setSpecificTimeRange(newValues);
            setSpecificTimeRangeValues(selectedRange);
            popuateFormPayload({specificTimeRange:newValues,specificTimeRangeValues:selectedRange})
        }
    }




  


    const handleRangeStart = (time)=>{
        setRangeStart(time)
        popuateFormPayload({customeTimeRangeStart:time})
    }

    const handleRangeEnd = (time)=>{
        setRangeEnd(time)
        popuateFormPayload({customeTimeRangeEnd:time})
    }


    const handleSelectAvailableDay = (event, newValues) => {
        setAvailableDays(newValues);
        popuateFormPayload({daysOfWeek:newValues})
    };


    const popuateFormPayload = (incomingData)=>{
        let payload = {...formPayload,...incomingData}
        if(checkValueExists(payload,'specificTimeRangeValues')){
            payload.specificTimeRangeValues.start = payload.specificTimeRangeValues.start
            payload.specificTimeRangeValues.end = payload.specificTimeRangeValues.end
        }

        if(checkValueExists(payload,'customeTimeRangeStart')){
            payload.customeTimeRangeStart = getTime(payload.customeTimeRangeStart)
        }
        if(checkValueExists(payload,'customeTimeRangeEnd')){
            payload.customeTimeRangeEnd = getTime(payload.customeTimeRangeEnd)
        }
        if(checkValueExists(payload,'dateAvailable')){
            payload.dateAvailable = getDate(payload.dateAvailable)
        }

        setFormPayload(payload)
        
        if(onSetCallBack){
            onSetCallBack(payload)
        }
    }



    // const showTZ = (
    //     <span  >Timezone : {getUserTzName()}</span>
    // )


    const showTZ = null

    const toggleRangeTypeUI1 = (

        <Col style={{margin:0,justifyContent:'flex-end',alignSelf:'flex-end',height: 50, display: 'flex'}} md={6} xs={6} >
            <FormControlLabel
                control={<Switch color="primary" style={{ color: isCustomRange?muiTheme.secondary:INPUT_GREY  }}  onChange={handleTimeRangeType}  checked={isCustomRange} size="small" />}
                label={!isCustomRange?"Specific Time Range":"Custom Time Range"}
                labelPlacement="bottom"
                style={{margin:0,justifyContent:'flex-end', fontSize:16}}
            />
        </Col>
    )

    const toggleRangeTypeUI2 = (
        <Row style={{margin:0,justifyContent:'flex-start',alignSelf:'flex-end',height: 50, display: 'flex'}} md={6} xs={12} >
        <Col style={{margin:0,justifyContent:'flex-start',alignSelf:'flex-end',height: 50, display: 'flex'}} md={6} xs={12} >
            <FormControlLabel
                control={<Switch color="primary" style={{ color: isCustomRange?muiTheme.secondary:INPUT_GREY  }}  onChange={handleTimeRangeType}  checked={isCustomRange} size="small" />}
                label={!isCustomRange?"Specific Time Range":"Custom Time Range"}
                labelPlacement="left"
                style={{margin:0,justifyContent:'flex-end', fontSize:16}}
            />
        </Col>
        <Col style={{margin:0,justifyContent:'flex-end',alignItems:'center',height: 50, display: 'flex'}} md={6} xs={12} >
            {type=='LANE' && showTZ}
        </Col>
        </Row>
    )
    

    const dateUI = (
        <Col xs={12} md={6} style={{ paddingRight: spacingPadding,marginTop:marginTopValue  }}>
            <Col  md={6}  xs={6}>
                <form noValidate>
                    <TextField
                        id="date"
                        readOnly
                        variant="filled"
                        required="required"
                        label="Available Date"
                        type="date"
                        onChange={handleDateChangeEvent}
                        value={ typeof(date) === 'string' ? date : date.format('YYYY-MM-DD') }
                        className={classes.dateInputField}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                fontSize: 16,
                            }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            style: {
                                fontSize: 16
                            },
                            
                        }}
                        inputProps={{ min: typeof(date) === 'string' ? date : date.format('YYYY-MM-DD')}}
                    />
                </form>
            </Col>
            {type=='CAPACITY' &&  toggleRangeTypeUI1}
        </Col>
    )


    const availableDayUI = (
        <Col xs={12} md={6} style={{paddingRight:spacingPadding,marginTop:marginTopValue,paddingLeft:leftPaddingValue}}    >
            <p style={{ marginBottom: 10 }}>Available Days <span style={{ color: muiTheme.ttRed }}>*</span></p>
            <ToggleButtonGroup style={{width:"100%"}} size="large" value={availableDays} onChange={handleSelectAvailableDay} aria-label="text formatting">
                <ToggleButton style={{width:"100%"}} value="SUN" aria-label="Su">
                    <p className={classes.dayButton}>Su</p>
                </ToggleButton>
                <ToggleButton style={{width:"100%"}} value="MON" aria-label="M">
                    <p className={classes.dayButton}>M</p>
                </ToggleButton>
                <ToggleButton style={{width:"100%"}} value="TUE" aria-label="Tu">
                    <p className={classes.dayButton}>Tu</p>
                </ToggleButton>
                <ToggleButton style={{width:"100%"}} value="WED" aria-label="W">
                    <p className={classes.dayButton}>W</p>
                </ToggleButton>
                <ToggleButton style={{width:"100%"}} value="THU" aria-label="Th">
                    <p className={classes.dayButton}>Th</p>
                </ToggleButton>
                <ToggleButton style={{width:"100%"}} value="FRI" aria-label="F">
                    <p className={classes.dayButton}>F</p>
                </ToggleButton>
                <ToggleButton style={{width:"100%"}} value="SAT" aria-label="Sa">
                    <p className={classes.dayButton}>Sa</p>
                </ToggleButton>
            </ToggleButtonGroup>
        </Col>
    )


    const dateRangeUI =(
        <Col xs={12} md={6} style={{paddingLeft:!isMobile?spacingPadding:leftPaddingValue,marginTop:marginTopValue}}    >
                {type=='LANE' &&  toggleRangeTypeUI2} 
                {type=='CAPACITY' && showTZ}
                {!isCustomRange &&(
                    <Grid item>
                        <ToggleButtonGroup size="small" value={specificTimeRange}  onChange={handleSpecificTimeRangeValues} >
                        <ToggleButton value="all">
                            <span style={{textTransform: "capitalize" }}>
                                All Day
                                <br/>
                                {allRange.start} - {allRange.end}
                            </span>
                        </ToggleButton>
                        <ToggleButton value="morning" disabled={specificTimeRange.includes('evening')}>
                            <span style={{textTransform: "capitalize" }}>
                                Morning
                                <br/>
                                {morningRange.start} - {morningRange.end}
                            </span>
                        </ToggleButton>
                        <ToggleButton value="afternoon">
                            <span style={{textTransform: "capitalize" }}>Afternoon
                                <br/>
                                {afternoonRange.start} - {afternoonRange.end}
                            </span>
                        </ToggleButton>
                        <ToggleButton value="evening" disabled={specificTimeRange.includes('morning')} >
                            <span style={{textTransform: "capitalize" }}>
                                Evening
                                <br/>
                                {eveningRange.start} - {eveningRange.end}
                            </span>
                        </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    
                )}

                {isCustomRange &&(
                    <Grid  direction="row"  style={{width: "100%", justifyContent:!isMobile?'flex-start': "space-between" ,display: "flex",}}     >
                        <div style={{width:150}} >
                            <TimePicker onChange={(time)=>{handleRangeStart(time)}} timeValue={rangeStart} label="From" />
                        </div>
                        <div style={{width:150,marginLeft:10}} >
                            <TimePicker onChange={(time)=>{handleRangeEnd(time)}} timeValue={rangeEnd}  label="To" style={{width:'45%'}} />
                        </div>
                    </Grid>
                )}
        </Col>
    )

    return (<Row style={{display: isMobile?'block':'flex', alignItems: 'flex-end',width:'100%'}}  >
                {type=='CAPACITY' && dateUI}
                {type=='LANE' && availableDayUI}
                {dateRangeUI}
            </Row>)
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(AvailableDateTimePicker)


/*

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

*/