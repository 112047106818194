const initialState = {
  requesting: false,
  successful: false,
  payload:[],
  messages: [],
  errors: [],
}

const reducer = function loginReducer (state = initialState, action) {
  switch (action.type) {
    case 'PARSE_EMAIL_TEXT_REQ':
      var retValue = {
        ...state,
        requesting:true
      }
      return retValue
    case 'PARSE_EMAIL_TEXT_SUC':
      var retValue = {
        ...state,
        requesting:false,
        successful:true,
        payload:action.payload
      }
      return retValue
    case 'PARSE_EMAIL_TEXT_ERR':
      var retValue = {
        ...state,
        requesting:false,
        successful:false
      }
      return retValue

    default:
      return state
  }
}

export default reducer
