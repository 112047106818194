// ENGLAND LOGISTICS THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#000000', // '#191919'
    secondary: '#2185e3',
    actionColor: "#2185e3",
    lightContrastText: '#fff',
    darkContrastText: '#000000', // '#191919'
    useDarkContrastText: false,
    tagLine:'MOVING LOGISTICS FORWARD',
    menuHighlightColor: "rgba(255, 255, 255, 0.3)",
    useWhiteBackgroundBehindLogo: false,
    customerUniqueId: "dd4udt1b",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Helvetica",
    loginMsg: "You've selected a feature that requires a secure login. Please login or sign up.",
    source: "englandlogistics_trucker_tools"
});
//console.log(theme);
export default theme