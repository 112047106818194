import { PostRemoteLog } from './api-remote-log'
import { API_PROD_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'

const GET_NEARBY_POIS_URL = "/poi/getPoisNearby"

const distanceBetweenTwoPoints = (startPoint, endPoint, isInKM = false) => {
    let distance = 0.00;
    try {
        if (startPoint && endPoint) {
            var lat1 = Number(startPoint.latitude);
            var lon1 = Number(startPoint.longitude);
            var lat2 = Number(endPoint.latitude);
            var lon2 = Number(endPoint.longitude);

            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var radlon1 = Math.PI * lon1 / 180;
            var radlon2 = Math.PI * lon2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            distance = dist * 60 * 1.1515;

        }

    } catch (error) {
        // not sure why this would fail... even reading the RN docs I'm unclear
        console.log('error =>', error);
    }
    //callback(prev);
    return distance;
}

const getFormattedDistanceBetweenTwoPoints = (item, origin) => {
    const startPoint = { latitude: origin.lat, longitude: origin.lon };
    const endPoint = { latitude: item.latitude, longitude: item.longitude };
    return distanceBetweenTwoPoints(startPoint, endPoint).toFixed(2);
}

export const GetFuelStops = async(dynamicParams) => {
    const search_lat = dynamicParams.lat
    const search_lon = dynamicParams.lng
    const search_loc = {
        lat: search_lat,
        lon: search_lon
    }

    let myAuthService = useAuthenticationHook()
    const url = API_PROD_URL + GET_NEARBY_POIS_URL
    let requestParameters = {
        type: "tsg"
    }
    if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b") {
        requestParameters.filters = { "franchiseName": ["TA", "Loves"] }
    }
    const params = { ...dynamicParams, ...requestParameters }
    let promise = new Promise((resolve, reject) => {
        try {
            let requestObject = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${process.env.REACT_APP_POI_API_KEY}`
                },
                body: JSON.stringify(params)
            }
            let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

            // console.log("GetFuelStops: ", newRequestObject)
            return fetch(url, newRequestObject)
                .then(res => {
                    if (!res.ok) {
                        const error = new Error(res.statusText);
                        error.res = res;
                        throw error;
                    }
                    res.json().then(jsonData => {
                        const data = [];
                        // console.log("jsonData: ", jsonData)
                        if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
                            data.push(...jsonData);
                        }
                        data.forEach(element => {
                            element.metadata = JSON.parse(element.metadata) || {};
                            element.metadata.distance = getFormattedDistanceBetweenTwoPoints(element, search_loc);
                        });
                        resolve(data.filter(item => item.metadata.distance < 100))
                    })
                })
                .catch(error => {
                    reject(error)
                    let payload = {
                        source: "URL: " + GET_NEARBY_POIS_URL,
                        message: {
                            error: JSON.stringify(error)
                        },
                        data: JSON.stringify(params)
                    }
                    PostRemoteLog(payload).then(response => {
                        // console.log("REMOTE LOG RESPONSE: ", response)
                    }).catch((error) => {
                    })
                })
        } catch (error) {
            reject(error)
            let payload = {
                source: "URL: " + GET_NEARBY_POIS_URL,
                message: {
                    error: JSON.stringify(error)
                },
                data: JSON.stringify(params)
            }
            PostRemoteLog(payload).then(response => {
                // console.log("REMOTE LOG RESPONSE: ", response)
            }).catch((error) => {
            })
        }
    })

    let returnPromise = await promise
    return returnPromise
}
