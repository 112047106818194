/*global google*/
import {
  CALL_OUT_OF_NETWORK_BROKER_ERROR,
  CALL_OUT_OF_NETWORK_BROKER_SUCCESS,
  INVITE_BROKER_SUCCESS,
  INVITE_BROKER_ERROR,
} from './constants'

const initialState = {
  requesting: false,
  successful: false,
  brokerList: [],
  invitingResponse: [],
  messages: [],
  errors: [],
}

const reducer = function outOfNetworkBrokerReducer (state = initialState, action) {
  switch (action.type) {


    case CALL_OUT_OF_NETWORK_BROKER_SUCCESS:
      console.log(action);
      return {
        brokerList: action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        
      }

    case INVITE_BROKER_SUCCESS:
      console.log('INVITE_BROKER_SUCCESS');
      console.log(action);
      return {
        invitingResponse : action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        
      }


    
    
    case INVITE_BROKER_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }
    
    case CALL_OUT_OF_NETWORK_BROKER_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }
      
      default : return state
    
  }
}

export default reducer