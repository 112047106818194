//MESSAGING
  import {
    CALL_TMSBROKER_DATA,
    CALL_TMSBROKER_DATA_SUCCESS,
    CALL_TMSBROKER_DATA_ERROR,
    UPDATE_TMSBROKER_DATA,
    UPDATE_TMSBROKER_DATA_SUCCESS,
    UPDATE_TMSBROKER_DATA_ERROR,
  } from './constants'

  const initialState = {
    requesting: false,
    successful: false,
    payload:[],
    messages: [],
    errors: [],
    UPDATE_TMSBROKER_DATA_SUCCESS: false,
  }

  const reducer = function contractReducer (state = initialState, action) {
    switch (action.type) {
      case CALL_TMSBROKER_DATA:
        return {
          ...state,
          requesting: true,
          successful: false,
          messages: [{ body: 'Getting your TMS Broker data...', time: new Date() }],
          errors: [],
        }

      case UPDATE_TMSBROKER_DATA:
        return {
          ...state,
          requesting: true,
          successful: false,
          payload: action.data,
          messages: [{ body: 'Updating your TMS Broker data...', time: new Date() }],
          errors: [],
        }

      case CALL_TMSBROKER_DATA_SUCCESS:
        return {
          payload: action.response,
          errors: [],
          messages: [],
          requesting: false,
          successful: true,
      }
      case UPDATE_TMSBROKER_DATA:
        return {
          updateResponse : action.response,
          errors: [],
          messages: [],
          requesting: false,
          successful: true,
          updateSuccess: true,

        }


      case UPDATE_TMSBROKER_DATA_ERROR:
        return {
          ...state,
          errors: state.errors.concat([{
            body: action.error.toString(),
            time: new Date(),
          }]),
          messages: [],
          requesting: false,
          successful: false,
          updateSuccess: false,
        }

        case CALL_TMSBROKER_DATA_ERROR:
          return {
            ...state,
            errors: state.errors.concat([{
              body: action.error.toString(),
              time: new Date(),
            }]),
            messages: [],
            requesting: false,
            successful: false,
            successful: false,
          }
        default : return state

    }
  }

  export default reducer
