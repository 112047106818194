import { BACKEND_URL } from '../../../../api/constants'

export function getELDIntegration(MCNumber) {

  const url = BACKEND_URL + '/ELDIntegration/' + MCNumber;
  return fetch(url, {
    method: 'GET',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}


export function createELDIntegration(payload) {
  const url = BACKEND_URL + '/ELDIntegration'
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function updateELDIntegration(payload) {
  const url = BACKEND_URL + '/ELDIntegration/' + payload.carrierMCNumber
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function testConnection(payload) {
  console.log(payload)
  const url = BACKEND_URL + '/ELDIntegration/connection/test'
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function addNewClient(payload) {
  const url = BACKEND_URL + '/sendELDEmail'
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function sendEldEmail(payload) {
  const url = BACKEND_URL + '/sendELDEmail'
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function uploadFile(payload,file){
  const url = BACKEND_URL + '/sendELDEmail'
  const formData = new FormData();
  formData.append('file',file)
  formData.append('payload',JSON.stringify(payload))
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
    },
    body: formData
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function createELDAction(payload) {
  const url = BACKEND_URL + '/ELDIntegration/actions/action'
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function sendSiteEldDataToSupport(payload) {
  const url = BACKEND_URL + '/sendSiteEldDataToSupport'
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}