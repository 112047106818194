
import React, { useState , useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import GoogleMapReact from 'google-map-react';
import { Col, Row } from "react-bootstrap"
import { Card } from 'material-ui/Card';


import { PureComponent } from 'react';
import FA from 'react-fontawesome'

import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../ConfirmDialog'
import LoadingOverlay from '../../LoadingOverlay'
import EditLocationModal from './add-new-location-modal'
import { WernerServices } from '../../../customers/a98db973/services';

class MapMarker extends PureComponent {
    render() {
        return (
            // <img src={require("./red-marker.svg")} height={22} />
            <FA name={"map-marker"} size="2x" color={"red"} />
        )
    }
}

const LocationItem = (props) => {
    const classes = useStyles();
    const { muiTheme, item  ,  onDelete=null , onEditComplete=null} = props

    // const classes = useStyles();

    // const [mapLoaded, setMapLoaded] = useState(false)
    // const [map, updateMap] = useState(null)
    // const [maps, updateMaps] = useState(null)
    const [processOnProgress, updateProcessOnProgress] = useState(false)
    // const [delay, updateForceDelay] = useState(false)
    const [cardHovered, updateCardHovered] = useState(false)
   
    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false)
    const [isApiCalling, setIsApiCalling] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [initData, setInitData] = useState(item);


    useEffect(() => {
        if(item){
            item.addressL1 = item.street+ ', ' +item.city+ ', ' +item.state + ' ' + item.zip+ ', ' + item.country
            item.address1 = item.addressL1
            setInitData(item)
        }
    }, [item]);
    
    const removeImportedFont = () => {
        var elements = document.querySelectorAll('link[href*="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700"]')
        if (elements.length > 0)
            elements[0].parentNode.removeChild(elements[0]);
    }

    const camelize = (str) => {
        if (str) {
            const wordArray = str.split(" ")
            let returnStr = ""
            wordArray.forEach(word => {
                const firstChar = word.slice(0, 1)
                const endOfWord = word.slice(1, word.length)
                returnStr = returnStr + " " + firstChar.toUpperCase() + endOfWord.toLowerCase()
            });
            return returnStr
        }
        return ""
    }
//
    const deleteItemProcess = (flag)=>{
        setIsDeleteConfirmationShow(false)
        if(flag){
            let params = {
                addressId : item.addressId
            }
            callDeleteApi(params)
        }
    }


    const callDeleteApi = (params)=>{
        const apiServices = new WernerServices()
        setIsApiCalling(true)
        apiServices.deleteLocation(params)
            .then(response => {
                setIsApiCalling(false)
                if (response ) {
                    if(onDelete){
                        onDelete(item)
                    }
                }
            })
            .catch(error => {
                setIsApiCalling(false)
            })
    }

    const actionButtons = ()=>{
        let UI = (
                <div  style={{display: 'flex', justifyContent: 'flex-end', position: 'relative', width:'100%' , background:'#00000040' ,transition: '.5s ease' }}>
                        <Button
                            onClick={() => { setShowModal(true)  }}
                        >
                            <FeatherIcon.Edit color={"black"} size={25} />
                        </Button>
                        <Button
                            onClick={() => { setIsDeleteConfirmationShow(true) }}
                        >
                            <FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />
                        </Button>
                </div>
        )
        if(cardHovered){
            return UI
        }else{
            return null
        }
    }


    const closeEditContactDialog = (flag)=>{
        setShowModal(false)
        if(onEditComplete){
            onEditComplete(flag)
        }
    }


    const hasLatLon = ()=>{
        let hasLatLonFlag = false
        if(item.latitude && item.longitude){
            hasLatLonFlag = true
        }
        return hasLatLonFlag
    }

    const addressDetailsUI = (ifShownForDelete = false)=>{
        let ui = (
            <div style={{ width: '100%', overflowY: 'auto', overflowX: "auto", padding: 5 }}>
                 {ifShownForDelete && (
                    <p className={classes.mainText} style={{ marginBottom: 20 }}>Are you sure you would like to delete this location?</p>
                )}
                <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: 20 }}>{camelize(item.addressType)} - {camelize(item.name)}</p>
                <p className={classes.locationText}>{item.street}</p>
                <p className={classes.locationText}>{camelize(item.city) + ", " + item.state + " " + item.zip}</p>
                <p className={classes.locationText}>{item.country}</p>
            </div>
        )

        return ui
    }

    return (
        <Card >
            <div style={{ display: 'flex', marginBottom: 20}}
                onMouseEnter={() => updateCardHovered(true)}
                onMouseLeave={() => updateCardHovered(false)}
            >
                {isApiCalling && <LoadingOverlay />}
                <ConfirmDialog open={isDeleteConfirmationShow} onClose={(flag)=>deleteItemProcess(flag)} header={'Delete Location'} bodyComp={addressDetailsUI(true)} body={''} acceptButtonText='YES' />
                {showModal && (<EditLocationModal onClose={(event)=>closeEditContactDialog(event)} initialValues={initData} isEdit={true} />)}
                <div style={{ display: 'flex',width:'100%' }}>
                    {hasLatLon() && (

                        <div style={{ position: 'relative', height: '200px', width: "50%" }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                                center={{ lat: item.latitude, lng: item.longitude }}
                                defaultZoom={8}
                                onGoogleApiLoaded={({ map, maps }) => {
                                    // updateMap(map);
                                    // updateMaps(maps);
                                    // setMapLoaded(true);
                                    removeImportedFont();
                                }}
                                yesIWantToUseGoogleMapApiInternals={true}
                                options={{ fullscreenControl: false }}
                            >
                                <MapMarker lat={item.latitude} lng={item.longitude} />
                            </GoogleMapReact>
                        </div>
                    )}
                    {!hasLatLon() && (

                        <div style={{ position: 'relative', height: '200px', width: "50%" }}>
                            {/* <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                                center={{ lat: item.latitude, lng: item.longitude }}
                                defaultZoom={8}
                                onGoogleApiLoaded={({ map, maps }) => {
                                    removeImportedFont();
                                }}
                                yesIWantToUseGoogleMapApiInternals={true}
                                options={{ fullscreenControl: false }}
                            >
                                <MapMarker lat={item.latitude} lng={item.longitude} />
                            </GoogleMapReact> */}
                        </div>
                    )}

                    <div style={{ height: '200px', width: "50%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {addressDetailsUI()}
                        <div style={{ width: "100%" }}>
                            {processOnProgress && (<div style={{ padding: 1 }} > looking for direction ... </div>)}
                        </div>
                        {actionButtons()}
                    </div>
                </div>
            </div>
        </Card >
    );
}

export default compose(
    connect(),
    muiThemeable()
)(LocationItem)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word'
    },
    locationText: {
        fontSize: '16px'
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '14px',
        color: 'grey'
    },
    valueText: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'black'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
});