
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'
import { bindActionCreators } from 'redux';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadCardExpandedContent from './LoadCardExpandedContent'
import WernerLoadCardExpandedContent from "./WernerLoadCardExpandedContent"
import QuoteButton from "../quotes/quoteButton"
import BinButton from "../bin/binButton"
import BookedActionButton from "../../booked-loads/action-button"
import ReloadsLink from './reloadsLink'
import LoginButton from "../../../authentication/login/Auth0-Univeral-Login/login-button"

import { checkValueExists } from "../../../../lib/check-value-exists"
import { validLoadDetails } from "../../../../lib/valid-load-details"
import { useAuthenticationHook } from '../../../hooks/authentication-hook';
import InvoiceActionContainer from '../../invoices/invoice-action-container';
import { titleCaseFormatter } from "../../../../lib/title-case-formatter"
import { setWindowLocation } from "../../../actions"

import LcDataWrap from "./LoadCardDataWrapper"

library.add(
    faBiohazard
);

const LoadCard = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        isExpanded,
        muiTheme,
        key,
        pushReloads,
        dropDownIcon,
        reloadsOpen,
        removeLoadFromList,
        isBookedLoads,
        latestQuote = null,
        latestQuoteShowBIN = false,
        callRefreshCard,
        isHome = false,
        setWindowLocation
    } = props

    const [isHazmat, setIsHazmat] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [failToLoadImage, setFailToLoadImage] = useState(false)

    const TT_LOGO_ASSET_URL = 'http://assets.truckertools.com/images/logos/';


    const open = Boolean(anchorEl);
    const id = open ? 'rate-info-popover' : undefined;

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const user = myAuthService.getUser()

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1 || load.hazmat === 1)
                setIsHazmat(true)
        }
    }, [])

    const handleClick = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getIcon = (market, type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type == "origin") {
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    backgroundColor: muiTheme.ttUnselectGrey,
                    width: 12,
                    height: 12,
                }}
            />
        }

        // if (checkValueExists(market, key)) {
        //     if (market[key] >= 80) {
        //         return (<FA name='fire' color='#9A1F25' />)
        //     } else if (market[key] >= 65 && market[key] < 80) {
        //         return (<FA name='fire' color='#D96C1E' />)
        //     } else if (market[key] >= 50 && market[key] < 65) {
        //         return (<FA name='fire' color='#FFB400' />)
        //     } else if (market[key] >= 35 && market[key] < 50) {
        //         return (<FA name='snowflake' color='#5DBFFF' />)
        //     } else if (market[key] > 20 && market[key] < 35) {
        //         return (<FA name='snowflake' color='#0086E1' />)
        //     } else if (market[key] <= 20) {
        //         return (<FA name="snowflake" color={'#002F81'} />)
        //     }
        // }

        return icon
    }

    const getDropDownIcon = () => {
        return <FA name={dropDownIcon} size='2x' />
    }

    const showBIN = () => {

        if (latestQuote) { //if there are quotes then BIN is dependent on them and not what's on the load
            return latestQuoteShowBIN
        }
        else if (!load.isPreviewLoad && load.hasBookItNow === 1 && checkValueExists(load, "targetPay") && Number(load.targetPay) > 0)
            return true

        return false
    }

    const getDisplayRate = () => {
        let tmpRate = null
        if (latestQuote) {
            tmpRate = latestQuoteShowBIN || isBookedLoads ? latestQuote.quote_value : null
        }
        else if (load.targetPay && (load.targetPay != "NULL" && load.targetPay > 0)) {
            tmpRate = load.targetPay
        }

        if (tmpRate && tmpRate > 0) {
            return (
                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    ${parseFloat(tmpRate).toFixed(2)}
                </p>
            )
        }

        return null

    }



    return (

        <div>

            <Row style={{ display: 'flex' }}>

                <Col style={{ display: 'flex', width: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '1.3%' : "4.3%" }}>
                    <div style={{ textAlign: 'center', display: 'flex' }}>
                        <Button
                            key={key + dropDownIcon + index}
                            style={{ backgroundColor: 'transparent', justifyContent: 'flex-start' }}
                            disableRipple={true}
                        >
                            {getDropDownIcon()}
                        </Button>
                    </div>
                </Col>

                {process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && (
                    <Col style={{ width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', alignItems: "center", }}>
                        <div style={{ display: "flex", width: '100%' }}>
                            {!failToLoadImage ?
                                <img
                                    style={{ width: 'inherit', height: 'fit-content' }}
                                    src={TT_LOGO_ASSET_URL + load.brokerId + '/default.png'}
                                    onError={() => setFailToLoadImage(true)}
                                /> :
                                <p className={classes.mainText}>{load.companyName}</p>
                            }
                        </div>
                    </Col>
                )}

                <Col style={{ width: "40.7%" }}>
                    {muiTheme.customerUniqueId == "srukfk5e" &&
                        <Row style={{ paddingBottom: 15 }}>
                            <LcDataWrap>
                                <p className={classes.mainText} style={{ display: 'inline-block', paddingRight: 5 }}>
                                    Load# {load.loadHash}
                                </p>
                                {isHazmat && <FontAwesomeIcon icon={['fas', 'biohazard']} style={{ color: '#000' }} />}
                            </LcDataWrap>
                        </Row>
                    }
                    <Row style={{ display: 'inline-flex', width: '100%', justifyContent: 'center' }}>

                        <Col md={4} className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <LcDataWrap>
                                <Row>
                                    <p className={classes.mainText} >
                                        {reloadsOpen ?
                                            titleCaseFormatter(pickup.city) + ', ' + pickup.state
                                            :
                                            titleCaseFormatter(load.originCity) + ', ' + load.originState
                                        }
                                    </p>
                                </Row>
                                {!load.pickupDateTimeLatest && (
                                    <Row>
                                        <p className={classes.secondaryText}>
                                            {moment(load.pickupDateTime).format('ll')}
                                        </p>
                                        <p className={classes.secondaryText}>
                                            {moment(load.pickupDateTime).format('HH:mm')}
                                        </p>
                                    </Row>
                                )}
                                {load.pickupDateTimeLatest && (
                                    <Row>
                                        <p className={classes.secondaryText}>
                                            {moment(load.pickupDateTime).format('ll')} {moment(load.pickupDateTime).format('HH:mm')} -
                                        </p>
                                        <p className={classes.secondaryText}>
                                            {moment(load.pickupDateTimeLatest).format('ll')} {moment(load.pickupDateTimeLatest).format('HH:mm')}
                                        </p>
                                    </Row>
                                )}
                                {isAuthenticated && process.env.REACT_APP_ADVANTAGE_ID === "a98db973" &&
                                    load.stops[0].stop_type && load.stops[0].stop_type != "NULL" &&

                                    <Row>
                                        <p className={classes.secondaryText}>
                                            {load.stops[0].stop_type}
                                        </p>
                                    </Row>

                                }
                                {(load.deadHead && load.deadHead > 0 && load.deadHead != "NULL" && !isHome) ?
                                    (
                                        <Row>
                                            <p className={classes.secondaryText}>
                                                {"Approx " + load.deadHead + " mi from search"}
                                            </p>
                                        </Row>
                                    ) : null
                                }
                            </LcDataWrap>
                        </Col>


                        <Col style={{ padding: '5px', paddingLeft: 15, alignItems: 'center' }}>
                            <Row style={{ marginTop: 0 }}>
                                {getIcon(load, "origin")}
                            </Row>
                        </Col>

                        <Col style={{ alignItems: 'center', padding: "5px", paddingRight: 15, paddingLeft: 15, width: '30%' }}>

                            <hr style={{ marginTop: 6, marginBottom: 10, width: '100%', border: '1px solid black' }} />
                            <Row>
                                <LcDataWrap>
                                    {(load.stops.length - 2) > 0 ?
                                        <p className={classes.secondaryText} style={{ textAlign: 'center' }}>
                                            {load.stops.length - 2} Stops
                                        </p>
                                        : checkValueExists(load, "numberOfStops") && ((load.numberOfStops - 2) > 0) ?
                                            <p className={classes.secondaryText} style={{ textAlign: 'center' }}>
                                                {load.numberOfStops - 2} Interim Stops
                                            </p>
                                            : null
                                    }
                                </LcDataWrap>
                            </Row>
                        </Col>

                        <Col style={{ padding: '5px', paddingRight: 15, alignItems: 'center' }}>
                            <Row style={{ marginTop: 0 }}>
                                {getIcon(load, "destination")}
                            </Row>
                        </Col>




                        <Col md={4} className={classes.centerColContent} style={{ alignItems: 'left' }}>
                            <LcDataWrap>
                                <Row>
                                    <p className={classes.mainText} >
                                        {reloadsOpen ?
                                            titleCaseFormatter(delivery.city) + ', ' + delivery.state
                                            :
                                            titleCaseFormatter(load.destinationCity) + ', ' + load.destinationState
                                        }
                                    </p>
                                </Row>
                                {!load.deliveryDateTimeLatest && (
                                    <Row>
                                        <p className={classes.secondaryText}>
                                            {moment(load.deliveryDateTime).format('ll')}
                                        </p>
                                        <p className={classes.secondaryText}>
                                            {moment(load.deliveryDateTime).format('HH:mm')}
                                        </p>
                                    </Row>
                                )}
                                {load.deliveryDateTimeLatest && (
                                    <Row>
                                        <p className={classes.secondaryText}>
                                            {moment(load.deliveryDateTime).format('ll')} {moment(load.deliveryDateTime).format('HH:mm')} -
                                        </p>
                                        <p className={classes.secondaryText}>
                                            {moment(load.deliveryDateTimeLatest).format('ll')} {moment(load.deliveryDateTimeLatest).format('HH:mm')}
                                        </p>
                                    </Row>
                                )}
                                {isAuthenticated && process.env.REACT_APP_ADVANTAGE_ID === "a98db973" &&
                                    load.stops[load.stops.length - 1].stop_type && load.stops[load.stops.length - 1].stop_type != "NULL" &&

                                    <Row>
                                        <p className={classes.secondaryText}>
                                            {load.stops[load.stops.length - 1].stop_type}
                                        </p>
                                    </Row>

                                }
                            </LcDataWrap>
                        </Col>

                    </Row>
                </Col>



                <Col style={{ width: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? "50%" : "55%" }}>
                    <Col md={3} className={classes.centerColContent} style={{ alignItems: 'center' }}>
                        <LcDataWrap>
                            {!props.onInvoicesScreen &&
                                <ReloadsLink
                                    key={key + "_reloads_button"}
                                    load={load}
                                    delivery={delivery}
                                    pushReloads={pushReloads}
                                />
                            }
                        </LcDataWrap>
                    </Col>

                    <Col md={3} className={classes.centerColContent} style={{ textAlign: 'left' }}>
                        <LcDataWrap>
                            <Row style={{ width: '100%' }}>
                                <p className={classes.mainText} style={{ fontWeight: "normal", display: 'inline-block', paddingRight: 5 }}>
                                    {load.truckTypes}
                                </p>
                                {isHazmat && <FontAwesomeIcon icon={['fas', 'biohazard']} style={{ color: '#000' }} />}
                            </Row>
                            {load.weight && (load.weight != "NULL" && load.weight > 0) &&
                                <Row style={{ width: '100%' }}>
                                    <p className={classes.mainText} style={{ fontWeight: "normal" }}>
                                        {parseFloat(load.weight).toLocaleString('en')} lbs
                                    </p>
                                </Row>}
                        </LcDataWrap>
                    </Col>

                    {isAuthenticated ? (
                        <Col md={3} className={classes.centerColContent} style={{ alignItems: 'flex-start', paddingLeft: 15 }}>
                            <LcDataWrap>
                                <Row>
                                    {getDisplayRate()}
                                </Row>
                                <Row>
                                    <div style={{ overflow: 'hidden' }}>

                                        {load.ratePer && (load.ratePer != "NULL" && load.ratePer > 0) &&
                                            <p className={classes.secondaryText} style={{ float: 'left' }}>
                                                ${parseFloat(load.ratePer).toFixed(2)}/mi
                                            </p>
                                        }
                                        {load.distance && load.distance > 0 &&
                                            <p className={classes.secondaryText} style={{ float: 'right', marginLeft: 8 }}>
                                                {parseFloat(load.distance).toFixed(0)}mi
                                            </p>
                                        }
                                    </div>
                                </Row>
                            </LcDataWrap>
                        </Col>
                    ) : (
                        <Col md={3} className={classes.centerColContent} style={{ alignItems: 'flex-start', paddingLeft: 15 }}>
                            <LcDataWrap>
                                <FeatherIcon.Info color={"black"} size={20} onMouseDown={(e) => { e.stopPropagation(); handleClick(e) }} />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    onMouseDown={(event) => event.stopPropagation()}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div style={{ padding: 10, width: 150 }}>
                                        {process.env.REACT_APP_USE_AUTH0 === "true" ?
                                            <p><LoginButton
                                                useATag={true}
                                                btnStyle={{ color: muiTheme.actionColor }}
                                                btnText={"Log in"}
                                                handleClick={() => { setWindowLocation(JSON.stringify({ pathname: "/loads" })) }}
                                            /> for rate and other information</p>
                                            :
                                            <p><a href={"/login"} style={{ color: muiTheme.actionColor }}>Log in</a> for rate and other information</p>
                                        }
                                    </div>
                                </Popover>
                            </LcDataWrap>
                        </Col>
                    )}




                    <Col
                        onMouseDown={(event) => event.stopPropagation()}
                        md={3}
                        className={classes.centerColContent}
                        style={{ alignItems: 'center', textAlign: 'center' }}>
                        {!isBookedLoads ?
                            <div style={{ display: 'contents' }}>
                                {props.onInvoicesScreen ?
                                    <InvoiceActionContainer
                                        load={load}
                                        refreshInvoices={props.refreshInvoices}
                                    />
                                    :
                                    <div style={{ display: 'contents' }}>
                                        {checkValueExists(load, "covered") && load.covered == 1 && checkValueExists(load, "selfCovered") && load.selfCovered == 1 && (
                                            <Col testID={"fullCard CR 1"} style={{ width: '90%', marginHorizontal: "5%", justifyContent: 'center' }}>
                                                <p style={{ color: 'grey', textAlign: "right", fontSize: 14 }}>Covered by you.</p>
                                            </Col>
                                        )}
                                        {checkValueExists(load, "covered") && load.covered == 1 && (!checkValueExists(load, "selfCovered") || (checkValueExists(load, "selfCovered") && load.selfCovered == 0)) && (
                                            <Col testID={"fullCard CR 2"} style={{ width: '90%', marginHorizontal: "5%", justifyContent: 'center' }}>
                                                <p style={{ color: 'grey', textAlign: "right", fontSize: 14 }}>Covered by someone else.</p>
                                            </Col>
                                        )}

                                        {(!checkValueExists(load, "covered") || (checkValueExists(load, "covered") && load.covered === 0)) && (
                                            <Row
                                                key={key + "_action_row"}
                                                style={{ display: (!user || (user && user.role !== "" && user.role.toLowerCase() !== "finance" && user.role.toLowerCase() !== "loadviewer")) ? "block" : "none" }}>

                                                {showBIN() && validLoadDetails(load, ["weight"]) &&
                                                    <BinButton
                                                        key={key + "_BIN_button"}
                                                        carrierId={availability.carrier_id}
                                                        availabilityId={availability.id}
                                                        load={load}
                                                        index={index}
                                                        pickup={pickup}
                                                        delivery={delivery}
                                                        quoteValue={latestQuote ? latestQuote.quote_value : load.targetPay}
                                                        {...props}
                                                    />
                                                }
                                                {!load.isPreviewLoad &&
                                                    <QuoteButton
                                                        carriers={[availability.carrier_id]}
                                                        brokers={[load.brokerId]}
                                                        availabilities={[{ availability_id: availability.id, carrier_id: availability.carrier_id }]}
                                                        removeLoadFromList={removeLoadFromList}
                                                        isAuthenticated={isAuthenticated}
                                                        callRefreshCard={callRefreshCard}
                                                        {...props}
                                                    />
                                                }
                                            </Row>
                                        )}
                                    </div>
                                }
                            </div>
                            :
                            <Row key={key + "booked_action_row"} >
                                {checkValueExists(load, "ltStatusLink") && checkValueExists(load, "ltLink") && (
                                    <BookedActionButton {...props} />
                                )}
                            </Row>
                        }
                    </Col>

                </Col>

            </Row>


            {isExpanded && process.env.REACT_APP_ADVANTAGE_ID != "a98db973" &&
                <LoadCardExpandedContent
                    load={load}
                    index={index}
                    pickup={pickup}
                    delivery={delivery}
                    availability={availability}
                    isHome={isHome}
                />
            }
            {isExpanded && process.env.REACT_APP_ADVANTAGE_ID == "a98db973" &&
                <WernerLoadCardExpandedContent
                    load={load}
                    index={index}
                    pickup={pickup}
                    delivery={delivery}
                    availability={availability}
                />
            }

        </div>

    );
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setWindowLocation: setWindowLocation
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable()
)(LoadCard)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    }
});