import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row, Grid } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import { Card } from 'material-ui/Card';
import * as FeatherIcon from 'react-feather';
import moment from 'moment';
import { checkValueExists } from "../../../lib/check-value-exists"

import EditRoleBtn from './edit-role-btn'


const UserCardDesktop = (props) => {
    const classes = useStyles();
    const { 
        muiTheme, 
        item, 
        camelize,
        formatPhoneNumber,
        deleteUser,
        activateUser,
        editUser
     } = props

     const getUserRoleDisplayText = (role) => {
        switch (role) {
            case "admin":
                return <p className={classes.mainText}>ADMIN</p>
            case "dispatch":
                return <p className={classes.mainText}>DISPATCHER</p>
            case "loadviewer":
                return <p className={classes.mainText}>LOAD VIEWER</p>
            case "finance":
                return <p className={classes.mainText}>FINANCE</p>
            default:
                return <p className={classes.mainText} style={{fontStyle: "italic", fontWeight: 100}}>NO ROLE SELECTED</p>
        }
    }

    return (
        <Card className={classes.root}>
            <Grid style={{ width: "100%", height: 90, display: "flex", margin: 0, paddingLeft: 20, paddingRight: 20 }}>
                <Col className={classes.col} style={{ width: "18%" }} >
                    <p className={classes.mainText}>{camelize(item.firstName) + " " + camelize(item.lastName)}</p>
                </Col>
                <Col className={classes.col} style={{ width: "25%" }} >
                    {checkValueExists(item, "email") &&
                        <p className={classes.mainText}>{item.email}</p>
                    }
                </Col>
                <Col className={classes.col} style={{ width: "15%" }} >
                    {checkValueExists(item, "phoneNumber") &&
                        <p className={classes.mainText}>{formatPhoneNumber(item.phoneNumber)}</p>
                    }
                </Col>
                <Col className={classes.col} style={{ width: "9%" }}>
                    {checkValueExists(item, "role") &&
                        getUserRoleDisplayText(item.role)
                    }
                </Col>
                <Col className={classes.col} style={{ width: "5%" }}>
                    {!checkValueExists(item, "expiresAt") &&
                        <EditRoleBtn item={item} onEditComplete={(newRole) => editUser(newRole)} />
                    }
                </Col>
                <Col className={classes.col} style={{ width: "18%" }} >
                    {checkValueExists(item, "expiresAt") &&
                        <div>
                            <p className={classes.mainText}>Carrier Membership: <span style={{ fontWeight: "bold" }}>Pending</span></p>
                            <p className={classes.mainText} style={{ fontSize: 12 }}>Expires on {moment(item.expiresAt).format("MMM DD, YYYY HH:mmA")}</p>
                        </div>
                    }
                </Col>
                <Col className={classes.col} style={{ width: "10%", alignItems: "center" }}>
                    {checkValueExists(item, "expiresAt") ? (
                        <div style={{ display: "contents", alignItems: "center" }}>
                            <Button
                                onClick={() => { activateUser() }}
                            >
                                <p className={classes.mainText} style={{ color: "green" }}>APPROVE</p>
                            </Button>
                            <Button
                                onClick={() => { deleteUser() }}
                            >
                                <p className={classes.mainText} style={{ color: muiTheme.ttRed }}>REJECT</p>
                            </Button>
                        </div>
                    ) : (
                        <Button
                            onClick={() => { deleteUser() }}
                        >
                            <FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />
                        </Button>
                    )}
                </Col>
            </Grid>
        </Card>

    );
}


export default compose(
    muiThemeable()
)(UserCardDesktop)

const useStyles = makeStyles({
    root: {
        backgroundColor: "white",
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    col: {
        height: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start"
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        // fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '11px',
        lineHeight: '22px',
        color: 'grey'
    }
});