//SYSTEM
  import { call, put, takeLatest } from 'redux-saga/effects'
  import {filterByHighConfidence} from '../../components/IsHighConfidence'
//APIs
  import {searchAvailabilities} from '../../api/driverAvailability'
  import {getCarrierSourcingAvails} from '../../api/driverAvailability'
  import {carrierLocationHistory} from '../../api/carrierLaneHistory'

function* searchAvailsFlow(action){
  try {
    const {pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes,historyDate} = action
    const response = yield call(searchAvailabilities, pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes)
    if(response.status){
      yield put({type:"SEARCH_AVAILS_SUC",availabilities:filterByHighConfidence(response.availabilities),carrierHistory:false})
    }

  } catch (error) {
      yield put({type:"SEARCH_AVAILS_ERR", message:error})
      console.log(error)

  }
}
function* searchAvailsFlowHistory(action){
  try {
    const {pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes,historyDate} = action
    const carrierHistory = yield call(carrierLocationHistory,pickupCity,pickupState,radius,pickupLat,pickupLon,5,truckTypes,historyDate)
    if(carrierHistory.status){
      yield put({type:"SEARCH_AVAILS_SUC",availabilities:false,carrierHistory:normalizeCarrierHistory(carrierHistory.carrierAvailableHistory,5)})
    }

  } catch (error) {
      yield put({type:"SEARCH_AVAILS_ERR", message:error})
      console.log(error)

  }
}
function* carrierSourcingFlow(action){
  try {
    const response = yield call(getCarrierSourcingAvails,action.code,action.start,action.end)
    if(response.status){
      yield put({type:"CARRIER_SOURCING_SUC",availabilities:filterByHighConfidence(response.availabilities.filter((avail)=>avail.carrier!=null)),carriers:response.carriers})
    }
  } catch (error) {
    console.log(error)
    yield put({type:"CARRIER_SOURCING_ERR"})
  }
}

// Define what messages the saga should listen to and what function to call when the message is heard. Function names should end with "flow" term.
function* templateWatcher () {
  yield takeLatest("SEARCH_AVAILS_REQ", searchAvailsFlow)
  yield takeLatest("SEARCH_AVAILS_REQ", searchAvailsFlowHistory)
  yield takeLatest('CARRIER_SOURCING_REQ',carrierSourcingFlow)
}
export default templateWatcher

function normalizeCarrierHistory(history,minNoOfPosting){
  var filteredHistory = history.filter((item)=>item.noOfPostings>=minNoOfPosting)
  return filteredHistory.map((item)=>{
    var returnItem = {...item}
    var carrier = {}
    carrier.companyName = item.carrierCompanyName
    carrier.id = item.carrier_id
    carrier.mc = item.mc
    carrier.lane_history = item.preferred_destinations
    var contactInfo = {}
    contactInfo.contactPhone = item.contactPhone
    contactInfo.contactEmail = item.contactEmail

    returnItem.inNetwork = item.isInNetwork
    returnItem.contact_info = contactInfo
    returnItem.carrier = carrier
    returnItem.mc=false
    returnItem.lane_history = item.preferred_destinations
    returnItem.id=item.carrier_id
    return returnItem
  })
}
