
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import moment from 'moment'
import { toast } from 'react-toastify';
import isMobile from '../../../../../../../HOC/Mobile.js'

import * as RateNegotiationsApi from '../../../../../../../api/rateNegotiations'
import RateNegotiationsListItemDesktop from './rateNegotiationsListItemDesktop';
import RateNegotiationsListItemMobile from './rateNegotiationsListItemMobile';
import { canShowBinTradeMark } from '../../../../../../helpers/permissions';


const RateNegotiationsListItem = (props) => {

    const { 
        quote, 
        load, 
        latestOffer, 
        fetchAllQuotes,
        callRefreshCard=()=>{},
        isMobile
    } = props

    const [showBinBtn, setShowBinBtn] = useState(false)
    const [showCounterButton, setShowCounterButton] = useState(true)

    const [quoteStatusDesktop, setQuoteStatusDesktop] = useState("")    
    const [quoteStatusMobile, setQuoteStatusMobile] = useState("")    


    useEffect(() => {
        checkQuoteStatus()
    }, [])

    const checkQuoteStatus = () => {
        let localTimeFromNow = moment.tz(quote.updated_at, "America/New_York").local().fromNow()
        if (quote.message_type === "QUOTE_D" || quote.message_type === "QUOTE_C") {
            setShowBinBtn(false)
            setShowCounterButton(true)

            setQuoteStatusDesktop("Carrier has made an offer " + localTimeFromNow)
            setQuoteStatusMobile("You Offered")
        }
        else if (quote.message_type === "QUOTE_B" && quote.accepted) {
            setShowBinBtn(true)
            setShowCounterButton(true)

            setQuoteStatusDesktop("Broker accepted offer " + localTimeFromNow)
            setQuoteStatusMobile("Broker Accepted")

        }
        else if (quote.message_type === "QUOTE_B" && quote.rejected) {
            setShowBinBtn(false)
            setShowCounterButton(true)

            setQuoteStatusDesktop("Broker rejected offer " + localTimeFromNow)
            setQuoteStatusMobile("Broker Rejected")

        }
        else if (quote.message_type === "QUOTE_B") {
            setShowBinBtn(true)
            setShowCounterButton(true)

            setQuoteStatusDesktop("Broker made an offer " + localTimeFromNow)
            setQuoteStatusMobile("Broker Offered")

        }
        else if (quote.message_type === "BOOKITNOW") {
            setShowBinBtn(false)
            setShowCounterButton(false)

            setQuoteStatusDesktop("Book-It-Now" + canShowBinTradeMark() ? '\xAE' : '' +  " submitted " + localTimeFromNow)
            setQuoteStatusMobile("Book-It-Now" + canShowBinTradeMark() ? '\xAE' : '' +  " submitted")

        }

    }

    const submitQuoteNegotiation = ( counterValue = null, quoteMessage=null) => {

        let params = {
            amount: counterValue,
            load_id: quote.load_id,
            broker_id: quote.broker_id,
            carrier_id: quote.carrier_id,
            source: "smart_capacity",
            accepted: false,
            rejected: false,
            message: quoteMessage
        }
        RateNegotiationsApi.SubmitQuoteNegotiation(params).then(response => {
            if (response.status && response.data) {
                if(load.quotes == null)
                    load['quotes'] = []
                load.quotes.push(response.data)
                callRefreshCard()
                fetchAllQuotes(load.id)
            } else {
                let errorMessage = "Error: There was a problem submitting your request."
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch(() => {
            let errorMessage = "Error: There was a problem submitting your request."
            toast.error(errorMessage)
        })
    }

    return (
        <div style={{display: 'contents'}}>
        { isMobile ?
            <RateNegotiationsListItemMobile 
                quote={quote}
                load={load}
                latestOffer={latestOffer}
                showBinBtn={showBinBtn}
                showCounterButton={showCounterButton}
                submitQuoteNegotiation={submitQuoteNegotiation}
                quoteStatus={quoteStatusMobile}
                {...props}
            />
            :
            <RateNegotiationsListItemDesktop 
                quote={quote}
                load={load}
                latestOffer={latestOffer}
                showBinBtn={showBinBtn}
                showCounterButton={showCounterButton}
                quoteStatus={quoteStatusDesktop}
                submitQuoteNegotiation={submitQuoteNegotiation}
                {...props}
            />
        
        }
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(RateNegotiationsListItem)