/*global google*/
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment';
import { handleApiErrors } from '../lib/api-errors'
import {
  CALL_OUT_OF_NETWORK_BROKER,
  CALL_OUT_OF_NETWORK_BROKER_SUCCESS,
  CALL_OUT_OF_NETWORK_BROKER_ERROR,
  INVITE_BROKER,
  INVITE_BROKER_SUCCESS,
  INVITE_BROKER_ERROR,
} from './constants'
import {getOutofnetworkbroker,inviteNewBrokers} from '../api/outofnetworkbroker'




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* getBrokerList (action) {
  try {


    yield put(showLoading())

    // here until the API async function, is complete!
    const response = yield call(getOutofnetworkbroker)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    
    yield put({ type: CALL_OUT_OF_NETWORK_BROKER_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: CALL_OUT_OF_NETWORK_BROKER_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* inviteBroker (action) {
  try {

    const {eachBroker }= action; 
    yield put(showLoading())

    // here until the API async function, is complete!
    const response = yield call(inviteNewBrokers,eachBroker)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    console.log('INVITE_BROKER_SUCCESS',response)
    if(response.status){
      console.log('INVITE_BROKER_SUCCESS');
      
    }
    else{
      yield put({ type: INVITE_BROKER_ERROR, error:response.message })
    }

    action.updateCallback()
    
    //yield put({ type: INVITE_BROKER_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: INVITE_BROKER_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the LOADS_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* getBrokerWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(CALL_OUT_OF_NETWORK_BROKER, getBrokerList)
  yield takeLatest(INVITE_BROKER, inviteBroker)
}

export default getBrokerWatcher