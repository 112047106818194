import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Row, Col } from 'react-bootstrap';
import FA from 'react-fontawesome'
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import moment from 'moment'
import ListPagination from '../components/ListPagination';

import CarrierLoadList from '../components/load-list'
import FilterMenu from "../components/filter-menu/index"
import LoadingOverlay from '../../components/LoadingOverlay'

import * as RecentActivityApi from '../components/recent-activity/api-recent-activity'
import useGtagHook from '../../gtag-hook';
import isMobile from '../../HOC/Mobile.js'


const RecentActivityScreen = ({ availability, muiTheme, isMobile }) => {

    const useStyles = makeStyles({
        container: {
            height: '100%',
            width: '100%'
        },
        badge: {
            height: 20,
            width: 20,
            borderRadius: 50,
            display: "flex",
            justifyContent: "center",
            backgroundColor: muiTheme.secondary
        },
        breadcrumbLink: {
            cursor: 'pointer',
            marginRight: 15,
            color: "black", // TODO: change??
            "&:hover": {
                color: muiTheme.actionColor,
                textDecoration: "underline"
            }
        },
        activeBreadcrumbLink: {
            color: muiTheme.primary,
            fontWeight: 'bold',
            textDecoration: "underline",
            "&:hover": {
                color: muiTheme.primary,
            }
        },
        secondaryText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            color: 'grey'
        },
        header: {
            width: "25%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderRadius: 0,
            textTransform: "none",
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        headerText: {
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 16,
            fontWeight: 'bold',
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        formControl: {
            minWidth: isMobile ? 'unset' : 200,
        },
        menuItem: {
            fontSize: '16px',
            textAlign: 'center'
        },
        removePadding: {
            paddingBottom: 0
        },
        selectfieldStyleOutline: {
            backgroundColor: 'white',
            // border: '1px solid #ccc',
            height: '45px',
            width: '180px',
            maxWidth: '180px',
            // margin: '0px',
            // backgroundColor: 'white',
            paddingLeft: '10px',
            paddingRight: '0px',
            paddingBottom: '0px',
            fontSize: '16px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            paddingLeft: '10px',
        }
    });

    const classes = useStyles()
    const [sendGtagEvent] = useGtagHook()

    const PAGES = {
        viewedTab: 0,
        quotedTab: 1,
        bookingInterestTab: 2,
        allTab: 3
    }

    const [renderedTab, setRenderedTab] = useState(PAGES["allTab"])

    const [loadsStack, setLoadsStack] = useState([])
    const [currentLoadList, setCurrentLoadList] = useState([])
    const [reloadCrumbs, setReloadCrumbs] = useState([])
    const [reloadsOpen, setReloadsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const sortSelection = { "LOAD_VIEW": 0, "QUOTE": 1, "BOOKING_INTEREST": 2, "ALL": 3 }
    const [selectedSort, setSelectedSort] = useState(PAGES["allTab"])
    const [refreshList, setRefreshList] = useState(false)

    const [viewTabPageNumber, setViewTabPageNumber] = useState(1)
    const [quotedTabNumber, setQuotedTabNumberNumber] = useState(1)
    const [bookingInterestTabPageNumber, setBookingInterestTabPageNumber] = useState(1)
    const [allTabPageNumber, setAllTabPageNumber] = useState(1)

    const [numOfPaginationPages, setNumOfPaginationPages] = useState(1)
    const itemsPerPage = 20


    const FILTER_PADDING_TOP = 83

    useEffect(() => {

        let params = {
            advantageId: (muiTheme.customerUniqueId === "c0419cm1" || muiTheme.customerUniqueId === "ch7h4g68r") ? "32m9lbe4" : muiTheme.customerUniqueId //todo: hardcoded for werner
        }
        // make api call to fetch recent activity
        RecentActivityApi.FetchRecentActivity(params).then(response => {
            if (response.status) {
                if (response.loads && response.loads.length > 0) {
                    let loadList = sortMostRecentActivity(response.loads)
                    setLoadsStack([loadList])
                    setCurrentLoadList(loadList)
                }
                setIsLoading(false)
                sendGtagEvent(
                    "search_results_returned",
                    {
                        screen: "recent_activity",
                        listLength: response.loads.length,
                        type: "loads",
                    }
                )
            } else {
                let errorMessage = "Error: There was a problem fetching your recent activity."
                // setLoadsStack([])
                // setCurrentLoadList([])
                setIsLoading(false)
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem fetching your recent activity."
            // setLoadsStack([])
            // setCurrentLoadList([])
            setIsLoading(false)
            toast.error(errorMessage)
        })
    }, [])

    useEffect(() => {
        if (reloadCrumbs.length <= 0)
            setReloadsOpen(false)
        else
            setReloadsOpen(true)
    }, [reloadCrumbs])

    useEffect(() => {
        if (!reloadsOpen)
            onSelectSort(selectedSort)
    }, [reloadsOpen])

    useEffect(() => {
        if (loadsStack[loadsStack.length - 1] != null)
            setCurrentLoadList(loadsStack[loadsStack.length - 1])
    }, [loadsStack])

    useEffect(() => {
        setNumOfPaginationPages(Math.ceil(currentLoadList.length / itemsPerPage))
    }, [currentLoadList])

    const getListHeaderText = () => {
        switch (renderedTab) {
            case PAGES["viewedTab"]:
                return "Viewed Loads"
            case PAGES["quotedTab"]:
                return "Quoted Loads"
            case PAGES["bookingInterestTab"]:
                return "Loads"
            case PAGES["allTab"]:
                return "Loads"
        }
    }

    const getEmptyListHeaderText = () => {
        switch (renderedTab) {
            case PAGES["viewedTab"]:
                return "No viewing of load details recently"
            case PAGES["quotedTab"]:
                return "No load offers made recently"
            case PAGES["bookingInterestTab"]:
                return "No booking interest shown recently"
            case PAGES["allTab"]:
                return "No recent activity found"
        }
    }

    const pushReloads = (parentLoad, reloads) => {
        //todo: push another param for date besides relying on scheduledAt to be in stops
        let tmpLoadOrigin = {
            ...parentLoad.stops[0],
            date: parentLoad.pickupDateTimeLatest ? parentLoad.pickupDateTimeLatest : parentLoad.pickupDateTime
        }
        let tmpLoadDestination = {
            ...parentLoad.stops[parentLoad.stops.length - 1],
            date: parentLoad.deliveryDateTime
        }
        if (reloadCrumbs.length <= 0) {
            setReloadCrumbs([
                tmpLoadOrigin,
                tmpLoadDestination
            ])
        } else {
            setReloadCrumbs([
                ...reloadCrumbs,
                tmpLoadDestination
            ])
        }
        setLoadsStack([
            ...loadsStack,
            reloads
        ])
        sendGtagEvent(
            "search_results_returned",
            {
                screen: "recent_activity",
                listLength: reloads.length,
                type: "reloads"
            }
        )
    }

    const popToIndex = (index) => {
        let newLoadsStack = loadsStack.slice(0, index + 1)
        let newReloadCrumbs = reloadCrumbs.slice(0, index + 1)
        setLoadsStack(newLoadsStack)
        if (index === 0)
            setReloadCrumbs([])
        else
            setReloadCrumbs(newReloadCrumbs)
    }

    const sortMostRecentActivity = (loadList = []) => {
        loadList.sort((a, b) => {
            let aTime = moment(a.activities[0].created_at).valueOf()
            let bTime = moment(b.activities[0].created_at).valueOf()
            if (aTime < bTime)
                return 1
            else if (aTime > bTime)
                return -1
            else
                return 0
        })
        return loadList
    }

    const onSelectSort = (value) => {
        let tempLoads = []
        setSelectedSort(value)
        if (loadsStack.length > 0) {
            if (value === sortSelection.ALL) {
                setSelectedSort(sortSelection.ALL)
                tempLoads = loadsStack[0]
            } else if (value === sortSelection.LOAD_VIEW) {
                setSelectedSort(sortSelection.LOAD_VIEW)
                tempLoads = loadsStack[0].filter(load => {
                    let activityMatches = load.activities.filter(activity => {
                        if (activity.action_name === "LOAD_VIEW")
                            return true
                    });
                    if (activityMatches.length > 0) {
                        return true
                    }
                })
            } else if (value === sortSelection.QUOTE) {
                setSelectedSort(sortSelection.QUOTE)
                tempLoads = loadsStack[0].filter(load => {
                    let activityMatches = load.activities.filter(activity => {
                        if (activity.action_name === "QUOTE")
                            return true
                    });
                    if (activityMatches.length > 0) {
                        return true
                    }
                })
            } else if (value === sortSelection.BOOKING_INTEREST) {
                setSelectedSort(sortSelection.BOOKING_INTEREST)
                tempLoads = loadsStack[0].filter(load => {
                    let activityMatches = load.activities.filter(activity => {
                        if (activity.action_name === "BOOK_IT_NOW")
                            return true
                    });
                    if (activityMatches.length > 0) {
                        return true
                    }
                })
            }
        }
        setCurrentLoadList(tempLoads)
        setTimeout(() => setRefreshList(!refreshList), 0)

    }

    return (
        <div style={{ height: '100%' }}>
            {isLoading && <LoadingOverlay />}
            {!isMobile &&
                <div className={classes.container}>
                    <Row style={{ height: '5%', width: "100%", display: "flex", justifyContent: "center", marginTop: 25 }}>
                        <Col style={{ width: "70%", display: "flex", justifyContent: "center" }}>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["viewedTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => { setRenderedTab(PAGES["viewedTab"]); onSelectSort(0) }}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["viewedTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Viewed Loads</p>
                            </Button>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["quotedTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => { setRenderedTab(PAGES["quotedTab"]); onSelectSort(1) }}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["quotedTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Quoted Loads</p>
                            </Button>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["bookingInterestTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => { setRenderedTab(PAGES["bookingInterestTab"]); onSelectSort(2) }}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["bookingInterestTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Interested in booking?</p>
                            </Button>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["allTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => { setRenderedTab(PAGES["allTab"]); onSelectSort(3) }}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["allTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>All</p>
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ height: '92%', width: "100%", display: "flex", }}>
                        {reloadsOpen &&
                            <Col md={2} style={{ paddingTop: FILTER_PADDING_TOP, backgroundColor: 'white', height: '100%' }}>
                                <FilterMenu loadList={currentLoadList} paddingTop={FILTER_PADDING_TOP} />
                            </Col>
                        }
                        <Col md={reloadsOpen ? 10 : 12}>
                            {currentLoadList.length > 0 && (
                                <Row style={{ width: "100%", marginTop: 15, marginBottom: 0 }}>
                                    <Col style={{ display: 'inline-block' }}>
                                        <h2 style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 15, }}>
                                            {currentLoadList.length + " " + getListHeaderText()}
                                        </h2>
                                    </Col>
                                </Row>
                            )}

                            {reloadsOpen &&
                                <Row>
                                    <div style={{ paddingLeft: 15, paddingTop: 25 }}>
                                        {
                                            reloadCrumbs.map((crumb, index) => {
                                                return (
                                                    <span>

                                                        {(index < reloadCrumbs.length - 1) &&
                                                            <span>
                                                                <div style={{ display: 'inline-block' }}>
                                                                    <span className={classes.breadcrumbLink} onClick={() => popToIndex(index)}>
                                                                        {crumb.city + ', ' + crumb.state}
                                                                    </span>
                                                                    <p className={classes.secondaryText}>
                                                                        {moment(crumb.date).format('MM/DD')}
                                                                    </p>
                                                                </div>
                                                                <FA name='chevron-right' size="lg" color={"black"} style={{ marginRight: 15, verticalAlign: 'top' }} />

                                                            </span>
                                                        }

                                                        {index === reloadCrumbs.length - 1 &&

                                                            <div style={{ display: 'inline-block' }}>
                                                                <span className={classes.activeBreadcrumbLink} onClick={() => popToIndex(index)}>
                                                                    {crumb.city + ', ' + crumb.state}
                                                                </span>
                                                                <p className={classes.secondaryText}>
                                                                    {moment(crumb.date).format('MM/DD')}
                                                                </p>
                                                            </div>


                                                        }
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </Row>
                            }

                            {(!isLoading && currentLoadList && currentLoadList.length > 0) ?
                                <Row style={{ height: `calc(${numOfPaginationPages > 1 ? '80%' : '100%'} - ${reloadsOpen ? '80px' : '55px'})` }}>
                                    <CarrierLoadList
                                        key={refreshList}
                                        unfilterLoadList={currentLoadList}
                                        availability={availability}
                                        pushReloads={pushReloads}
                                        isRecentActivity={true}
                                        reloadsOpen={reloadsOpen}
                                        recentActivityActionType={
                                            renderedTab == PAGES["viewedTab"] ? "LOAD_VIEW" :
                                                renderedTab == PAGES["quotedTab"] ? "QUOTE" :
                                                    renderedTab == PAGES["bookingInterestTab"] ? "BOOK_IT_NOW" :
                                                        "ALL"
                                        }
                                        autoHeight
                                        isPaginationEnabled={true}
                                        setPageNumber={
                                            renderedTab == PAGES["viewedTab"] ? setViewTabPageNumber :
                                                renderedTab == PAGES["quotedTab"] ? setQuotedTabNumberNumber :
                                                    renderedTab == PAGES["bookingInterestTab"] ? setBookingInterestTabPageNumber :
                                                        setAllTabPageNumber
                                        }
                                        pageNumber={
                                            renderedTab == PAGES["viewedTab"] ? viewTabPageNumber :
                                                renderedTab == PAGES["quotedTab"] ? quotedTabNumber :
                                                    renderedTab == PAGES["bookingInterestTab"] ? bookingInterestTabPageNumber :
                                                        allTabPageNumber
                                        }
                                        itemsPerPage={itemsPerPage}
                                        isMobile={false}
                                    />
                                </Row>
                                :
                                <div style={{ paddingTop: 50 }}>
                                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                        {getEmptyListHeaderText()}
                                    </p>
                                </div>
                            }
                            {!isLoading && currentLoadList && currentLoadList.length > 0 && numOfPaginationPages > 1 && (
                                <Row style={{ height: '15%', marginTop: '25px' }}>
                                    <ListPagination
                                        numberOfPages={numOfPaginationPages}
                                        setPageNumber={
                                            renderedTab == PAGES["viewedTab"] ? setViewTabPageNumber :
                                                renderedTab == PAGES["quotedTab"] ? setQuotedTabNumberNumber :
                                                    renderedTab == PAGES["bookingInterestTab"] ? setBookingInterestTabPageNumber :
                                                        setAllTabPageNumber
                                        }
                                        pageNumber={
                                            renderedTab == PAGES["viewedTab"] ? viewTabPageNumber :
                                                renderedTab == PAGES["quotedTab"] ? quotedTabNumber :
                                                    renderedTab == PAGES["bookingInterestTab"] ? bookingInterestTabPageNumber :
                                                        allTabPageNumber
                                        }
                                    />
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            }


            {isMobile &&
                <div style={{ height: '100%' }}>
                    <Row style={{ height: 75, width: "100%", marginBottom: 0, display: "flex", justifyContent: "center" }}>
                        <Col style={{ display: 'inline-block', marginBottom: 10, marginTop: 10, float: 'right' }}>
                            <FormControl>
                                {/* <FormHelperText style={{ fontSize: 12 }}>showing</FormHelperText> */}
                                <Select
                                    className={classes.selectfieldStyleOutline}
                                    onChange={(event) => { onSelectSort(event.target.value) }}
                                    displayEmpty
                                    disableUnderline={true}
                                    style={{ minWidth: 215 }}
                                    value={selectedSort}
                                    inputProps={{
                                        classes: {
                                            icon: classes.icon
                                        }
                                    }}
                                >
                                    {/* <MenuItem className={classes.sortSelectItem} value={0}>No sort applied</MenuItem> */}
                                    <MenuItem className={classes.menuItem} value={3}>All</MenuItem>
                                    <MenuItem className={classes.menuItem} value={1}>Quoted Loads</MenuItem>
                                    <MenuItem className={classes.menuItem} value={2}>Interested in booking?</MenuItem>
                                    <MenuItem className={classes.menuItem} value={0}>Viewed Loads</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    {(!isLoading && currentLoadList && currentLoadList.length > 0) ?
                        <Row style={{ height: `calc(${numOfPaginationPages > 1 ? '85%' : '100%'} - 75px` }}>
                            <CarrierLoadList
                                key={refreshList}
                                unfilterLoadList={currentLoadList}
                                availability={availability} //todo: may not need this
                                pushReloads={pushReloads}
                                reloadsOpen={reloadsOpen}
                                isRecentActivity={true}
                                recentActivityActionType={
                                    renderedTab == PAGES["viewedTab"] ? "LOAD_VIEW" :
                                        renderedTab == PAGES["quotedTab"] ? "QUOTE" :
                                            renderedTab == PAGES["bookingInterestTab"] ? "BOOK_IT_NOW" :
                                                "ALL"
                                }
                                isPaginationEnabled={true}
                                setPageNumber={
                                    renderedTab == PAGES["viewedTab"] ? setViewTabPageNumber :
                                        renderedTab == PAGES["quotedTab"] ? setQuotedTabNumberNumber :
                                            renderedTab == PAGES["bookingInterestTab"] ? setBookingInterestTabPageNumber :
                                                setAllTabPageNumber
                                }
                                pageNumber={
                                    renderedTab == PAGES["viewedTab"] ? viewTabPageNumber :
                                        renderedTab == PAGES["quotedTab"] ? quotedTabNumber :
                                            renderedTab == PAGES["bookingInterestTab"] ? bookingInterestTabPageNumber :
                                                allTabPageNumber
                                }
                                itemsPerPage={itemsPerPage}
                                isMobile={true}
                            />
                        </Row>
                        :
                        <div style={{ paddingTop: 50 }}>
                            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                No Recent Activity Found
                            </p>
                        </div>
                    }
                    {!isLoading && currentLoadList && currentLoadList.length > 0 && numOfPaginationPages > 1 && (
                        <Row style={{ height: '15%', marginTop: '25px' }}>
                            <ListPagination
                                numberOfPages={numOfPaginationPages}
                                setPageNumber={
                                    renderedTab == PAGES["viewedTab"] ? setViewTabPageNumber :
                                        renderedTab == PAGES["quotedTab"] ? setQuotedTabNumberNumber :
                                            renderedTab == PAGES["bookingInterestTab"] ? setBookingInterestTabPageNumber :
                                                setAllTabPageNumber
                                }
                                pageNumber={
                                    renderedTab == PAGES["viewedTab"] ? viewTabPageNumber :
                                        renderedTab == PAGES["quotedTab"] ? quotedTabNumber :
                                            renderedTab == PAGES["bookingInterestTab"] ? bookingInterestTabPageNumber :
                                                allTabPageNumber
                                }
                                isMobile
                            />
                        </Row>
                    )}
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    loads: state.AvailabilityLoads.payload.loads,
    availability: state.AvailabilityLoads.payload.availability,
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(RecentActivityScreen)