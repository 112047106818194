// CHRobinson THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#0071c7', 
    secondary: '#003057', 
    actionColor: "#0071c7", 
    lightContrastText: '#fff',
    darkContrastText: '#000',
    useDarkContrastText: false,
    menuHighlightColor: "rgba(0, 0, 0, 0.3)",
    useWhiteBackgroundBehindLogo: false,
    tagLine:'C.H.Robinson accelerates your advantage',
    customerUniqueId: "ch7h4g68r",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Sans-Serif",
    loginMsg: "You've selected a feature that requires a secure login. Please login.",
    source: "CHRobinson_advantage_portal"
});
//console.log(theme);
export default theme
