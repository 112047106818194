import {BACKEND_URL} from './constants.js'


export function carrierLaneHistoryApi(carrierId){
    var requestUrl = BACKEND_URL+'/carrier/laneHistory?carrierId='+carrierId

   return fetch(requestUrl,{
                            method: 'get',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                                'Content-Type': 'application/json',
                            },
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}


export function laneSeachApi(postData){
  var requestUrl=BACKEND_URL+'/laneSearch'
  var method = 'POST'

  var payload        =     postData;
  return fetch(requestUrl,{
                            method: method,
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload),
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}


export function historySeachApi(postData){
  var requestUrl=BACKEND_URL+'/historySearch'
  var method = 'POST'

  var payload        =     {
                                city:postData.city,
                                state:postData.state,
                                radius:postData.radius,
                                equipmentTypeIds:postData.equipmentTypeIds,
                                minNoOfPosting:postData.minNoOfPosting,
                                historyDate:postData.historyDate,

  };
  return fetch(requestUrl,{
                            method: method,
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload),
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function carrierLocationHistory(city=false,state=false,radius=false,lat=false,lon=false,minNoOfPosting=false,truckTypes=false,historyDate=false){
  var requestUrl=BACKEND_URL+'/broker/locationHistory'
  var method = 'POST'
  var payload={}
  if(city){payload.city=city}
  if(state){payload.state=state}
  if(radius){payload.radius=radius}
  if(lat){payload.lat=lat}
  if(lon){payload.lon=lon}
  if(minNoOfPosting){payload.minNoOfPostings=minNoOfPosting}
  if(truckTypes){payload.truckTypes=truckTypes}
  if(historyDate){payload.historyDate=historyDate}
  return fetch(requestUrl,{
                            method: method,
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload),
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
