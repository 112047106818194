// BLUEGRACE MANAGED SERVICES THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#272a59',
    secondary: '#1f9bde',
    actionColor: "#f36c2a",
    lightContrastText: '#fff',
    darkContrastText: '#000',
    useDarkContrastText: false,
    tagLine:'Passion for Logistics.',
    menuHighlightColor: "rgba(0, 0, 0, 0.3)",
    useWhiteBackgroundBehindLogo: true,
    customerUniqueId: "ju9agnj4",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Roboto",
    loginMsg: "Please login in order to use this feature",
    source: "blue_grace"
});
//console.log(theme);
export default theme