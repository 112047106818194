import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import {FetchNearByLoads} from '../../../../../apis/api-load-search'
import {pickValue} from '../../../../../../lib/objectRead'
import { canUseGetNearbyLoadsV3 } from '../../../../../helpers/permissions';
import moment from 'moment'
import FA from 'react-fontawesome'
import CarrierLoadList from '../../../index'


const ReloadsTab = (props) => {

    const { load, delivery, pickup, availability, muiTheme } = props

    const [ reloadsList, setReloadsList ] = useState([])
    const [ errorText, setErrorText ] = useState("")
    const [ showError, setShowError ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(true)


    useEffect(()=>{

        let params
        if(canUseGetNearbyLoadsV3()){
            params = {
                "city": pickValue(delivery,'city')||null,
                "state": pickValue(delivery,'state')||null,
                "zipCode": pickValue(delivery,'zipCode')||null,
                "country": pickValue(delivery, 'country') || 'United States',
                "lat": pickValue(delivery,'lat')||null,
                "lon": pickValue(delivery,'lon')||null,
                "pickupDateFrom": moment(load.deliveryDateTime).format('MM/DD/YYYY'),
                "pickupTimeFrom": moment(load.deliveryDateTime).format('HH:mm'),
                "truckTypes": load.truckTypes && load.truck_type_code ? [{name: load.truckTypes, code: load.truck_type_code}] : [],
                "advantageId":(muiTheme.customerUniqueId === "c0419cm1" || muiTheme.customerUniqueId === "ch7h4g68r") ? "32m9lbe4" : muiTheme.customerUniqueId
            }
            if(params.advantageId === '2zr5ys19'){
                delete params.advantageId
            }
        }else{
            params = {
                "city": pickValue(delivery,'city')||null,
                "state": pickValue(delivery,'state')||null,
                "zipCode": pickValue(delivery,'zipCode')||null,
                "lat": pickValue(delivery,'lat')||null,
                "lon": pickValue(delivery,'lon')||null,
                "pickupDate": moment(load.deliveryDateTime).format('MM/DD/YYYY'),
                "pickupTime": moment(load.deliveryDateTime).format('HH:mm'),
                "truckTypes": load.truckTypes && load.truck_type_code ? [{name: load.truckTypes, code: load.truck_type_code}] : [],
                "advantageId":(muiTheme.customerUniqueId === "c0419cm1" || muiTheme.customerUniqueId === "ch7h4g68r") ? "2zr5ys19" : muiTheme.customerUniqueId
            }
        }

        // make api call to fetch recent activity
        FetchNearByLoads(params).then(response => {
            if (canUseGetNearbyLoadsV3() ? response : response.status) {
                if (canUseGetNearbyLoadsV3() ? response.data && response.data.length > 0 : response.loadList && response.loadList.length > 0) {
                    setReloadsList(canUseGetNearbyLoadsV3() ? response.data : response.loadList)
                }
                setIsLoading(false)
               
            } else {
                let errorMessage = "No Reloads Found"
                setErrorText(response.message ? response.message : errorMessage)
                setShowError(true)
                setIsLoading(false)
            } 
        }).catch((error) => {
            let errorMessage = "There was a problem fetching reloads."
            setErrorText(errorMessage)
            setShowError(true)
            setIsLoading(false)
        })
    },[])

    


    return (
        <div style={{height: '100%'}}>
            { isLoading && 
                <div style={{ paddingTop: 45 }}>
                    <div style={{ width: '100%', textAlign: 'center'}}>
                        <FA name='spinner' size="3x" spin />
                    </div>
                </div>
            }
            { !isLoading &&
                <div style={{height: '100%'}}>
                    { showError ? 
                        <div style={{ paddingTop: 25 }}>
                            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                {errorText}
                            </p>
                        </div>
                        :
                        <div style={{height: '100%'}}>
                        { reloadsList.length > 0 ?
                            <CarrierLoadList
                                {...props}
                                unfilterLoadList={reloadsList}
                                availability={availability}
                                onReloadsTab={true}
                                isMobile={true}
                            />
                            :
                            <div style={{ paddingTop: 25 }}>
                                <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                    No Reloads Found
                                </p>
                            </div>
                        }
                        </div>
                    }
                </div>
            }
            
        </div>
    );
}

const mapStateToProps = state => ({
    values: state.CarrierPortal.components.loadSearchBar,
    availability: state.AvailabilityLoads.payload.availability,
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
)(ReloadsTab)
