import React from 'react';
import { compose, withProps } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col, Grid } from 'react-bootstrap';
import { Card } from 'material-ui/Card';

const EquipmentForm = (props) => {
    const {
        muiTheme,
        data,
        containers,
        flatbeds,
        RGNs,
        tankers,
        tractors,
        hoppers,
        heatedTrailers,
        stepDecks,
        TCUs,
        vans
    } = props

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            height: 'unset',
            outline: 'none',
            padding: theme.spacing(2, 4, 3),
        },
        header: {
            margin: 0
        },
        row: {
            marginBottom: 20
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            // width: '25ch',
        },
        locationCount: {
            height: 55,
            width: 180,
            padding: 10,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            borderBottomWidth: 1,
            borderBottomColor: "black",
            borderBottomStyle: "solid",
            backgroundColor: "rgba(0, 0, 0, 0.1)"
        },
        totalCount: {
            height: 55,
            width: 50,
            padding: 10,
        },
        label: {
            color: muiTheme.ttUnselectGrey,
            fontSize: 16
        },
        value: {
            color: "black",
            fontSize: 16
        }
    }));
    const classes = useStyles()

    return (
        <Grid style={{ height: "100%", width: '100%', display: "flex", justifyContent: "center" }}>

            <div style={{ maxHeight: "100%", overflowX: "hidden", overflowY: "auto" }}>
                <Card style={{ width: "100%" }}>
                    <div style={{ marginTop: 15, width: "100%", display: "flex", justifyContent: "center" }}>
                        <div className={classes.paper}>
                            <h3 style={{ marginBottom: 10, textTransform: "uppercase" }}>Equipment</h3>
                            <Row style={{ display: "flex" }}>
                                <Col style={{ width: "35%" }}>
                                    <div className={classes.root}>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>Container</p>
                                            <p className={classes.value}>{containers.locationCount} </p>
                                        </Row>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>Flatbed</p>
                                            <p className={classes.value}>{flatbeds.locationCount} </p>
                                        </Row>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>RGN</p>
                                            <p className={classes.value}>{RGNs.locationCount} </p>
                                        </Row>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>Tanker</p>
                                            <p className={classes.value}>{tankers.locationCount} </p>
                                        </Row>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>Tractor</p>
                                            <p className={classes.value}>{tractors.locationCount} </p>
                                        </Row>
                                    </div>
                                </Col>
                                <Col style={{ width: "15%" }}>
                                    <div className={classes.root}>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{containers.totalCount} </p>
                                        </Row>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{flatbeds.totalCount} </p>
                                        </Row>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{RGNs.totalCount} </p>
                                        </Row>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{tankers.totalCount} </p>
                                        </Row>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{tractors.totalCount} </p>
                                        </Row>
                                    </div>
                                </Col>
                                <Col style={{ width: "35%" }}>
                                    <div className={classes.root}>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>Dry Bulk/Hopper</p>
                                            <p className={classes.value}>{hoppers.locationCount} </p>
                                        </Row>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>Heated Trailer</p>
                                            <p className={classes.value}>{heatedTrailers.locationCount} </p>
                                        </Row>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>Stepdeck</p>
                                            <p className={classes.value}>{stepDecks.locationCount} </p>
                                        </Row>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>TCU</p>
                                            <p className={classes.value}>{TCUs.locationCount} </p>
                                        </Row>
                                        <Row className={classes.locationCount}>
                                            <p className={classes.label}>Van</p>
                                            <p className={classes.value}>{vans.locationCount} </p>
                                        </Row>
                                    </div>
                                </Col>
                                <Col style={{ width: "15%" }}>
                                    <div className={classes.root}>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{hoppers.totalCount} </p>
                                        </Row>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{heatedTrailers.totalCount} </p>
                                        </Row>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{stepDecks.totalCount} </p>
                                        </Row>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{TCUs.totalCount} </p>
                                        </Row>
                                        <Row className={classes.totalCount}>
                                            <p className={classes.label}>Total</p>
                                            <p className={classes.value}>{vans.totalCount} </p>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {/* <div style={{ marginTop: 15, width: "100%", display: "flex", justifyContent: "center" }}>
                <Button
                    id='saveButton'
                    size="small"
                    variant="contained"
                    style={{
                        backgroundColor: muiTheme.actionColor,
                        borderColor: muiTheme.actionColor,
                        color: "white",
                        fontSize: 14,
                        margin: 5
                    }}
                    onClick={(event) => { }}
                >
                    Save
				</Button>
            </div> */}
                </Card>
            </div>
        </Grid>

    )
}

export default compose(
    muiThemeable(),
    withProps(props => {
        const defaultEmpty = { locationCount: 0, totalCount: 0 }
        let containers = defaultEmpty
        let flatbeds = defaultEmpty
        let RGNs = defaultEmpty
        let tankers = defaultEmpty
        let tractors = defaultEmpty
        let hoppers = defaultEmpty
        let heatedTrailers = defaultEmpty
        let stepDecks = defaultEmpty
        let TCUs = defaultEmpty
        let vans = defaultEmpty

        if (props.data) {
            props.data.forEach(equipment => {
                switch (equipment.description) {
                    case "CONTAINER":
                        containers = equipment
                        break
                    case "FLATBED":
                        flatbeds = equipment
                        break
                    case "RGN":
                        RGNs = equipment
                        break
                    case "TANKER":
                        tankers = equipment
                        break
                    case "TRACTORS":
                        tractors = equipment
                        break
                    case "DRY BULK/HOPPER":
                        hoppers = equipment
                        break
                    case "HEATED TRAILER":
                        heatedTrailers = equipment
                        break
                    case "STEPDECK":
                        stepDecks = equipment
                        break
                    case "TCU":
                        TCUs = equipment
                        break
                    case "VAN":
                        vans = equipment
                        break
                }
            });
        }

        return ({
            ...{
                containers,
                flatbeds,
                RGNs,
                tankers,
                tractors,
                hoppers,
                heatedTrailers,
                stepDecks,
                TCUs,
                vans
            },
            ...props
        })
    }),
)(EquipmentForm)