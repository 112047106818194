import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../../HOC/Mobile.js'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import * as InvoiceAPI from '../../../../apis/api-invoice'

import ChargeItem from './charge-item'


const LineItemCharges = (props) => {
    const classes = useStyles();
    const {
        allowEdit,
        stops,
        lineItems,
        addLineItem,
        updateLineItem,
        removeLineItem,
        onFileUpload,
        refresh,
        editModeFlag,
        setEditModeFlag,
        muiTheme
    } = props

    const [localLineItems, setLocalLineItems] = useState(lineItems)
    const [allowedChargeTypes, setAllowedChargeTypes] = useState(["line_haul", "lumper", "detention", "fuel_surcharge", "other"])

    useEffect(() => {
        InvoiceAPI.FetchLineItems({ advantageId: props.muiTheme.customerUniqueId }).then(response => {
            if (response.status) {
                if (response.lineItemTypes && response.lineItemTypes.length > 0) {
                    let types = []
                    response.lineItemTypes.forEach(item => types.push(item.name))
                    setAllowedChargeTypes(types)
                }

            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem fetching allowed line items."
            // toast.error(errorMessage)
            // setIsLoading(false)
        })



    }, [])

    useEffect(() => {
        setLocalLineItems(lineItems)
    }, [lineItems])

    const createTmpLineItem = () => {
        let newLineItem = {
            type: null,
            amount: null,
            addNew: true,
            uniqueTimestamp: Date.now()
        }
        let tmpLineItems = [...localLineItems, newLineItem]
        setLocalLineItems(tmpLineItems)
    }

    const cancelAddNew = (itemIndex) => {
        let tmpLineItems = JSON.parse(JSON.stringify(localLineItems));
        tmpLineItems.splice(itemIndex, 1)
        setLocalLineItems(tmpLineItems)
    }

    const hasValidLineItems = () => {
        let returnVal = false
        localLineItems.forEach( item => {
            if(!item.isDeleted && !returnVal) {
                returnVal = true
                return 
            }
        })
        return returnVal
    }

    const checkIfLastItem = (itemIndex) => {
        for( let i = localLineItems.length - 1; i >= 0 ; i--) {
            if(!localLineItems[i].isDeleted && i === itemIndex){
                return true
            }
            return false
        }
    }

    return (

        <Row key={"line_item_charges_" + refresh} style={{ paddingLeft: 25 }}>
            <Row style={{ display: 'flex' }}>
                <h3 style={{ margin: isMobile ? 0 : 'unset' }}>Charges</h3>
                {!editModeFlag && allowEdit &&
                    <Button
                        aria-describedby={"invoice_open_charge"}
                        variant={"text"}
                        onClick={() => createTmpLineItem()}
                        style={{
                            fontSize: 14,
                            color: muiTheme.actionColor,
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            margin: 0,
                            paddingTop: 8,
                            paddingLeft: 25,
                            height: 'min-content'
                        }}
                        size={"medium"}
                    >
                        <FA name='plus' />
                        <span style={{ paddingLeft: 5, lineHeight: 'normal' }}>Add Charge</span>
                    </Button>
                }
            </Row>
            {localLineItems && localLineItems.length > 0 && hasValidLineItems() &&
                <Row style={{ paddingLeft: 5 }}>
                    {!isMobile &&
                        <Row style={{ display: 'flex' }}>
                            <Col style={{ width: '55%' }}>
                                <p className={classes.mainText}>
                                    Description
                                </p>
                            </Col>

                            <Col>
                                <p className={classes.mainText}>
                                    Total
                                </p>
                            </Col>
                        </Row>
                    }

                    {localLineItems.map((item, itemIndex) => {
                        if (!item.isDeleted) {
                            return (
                                <ChargeItem
                                    key={item.line_item_type + "_" + item.amount + "_" + itemIndex + "_" + item.uniqueTimestamp}
                                    allowEdit={allowEdit}
                                    allowedChargeTypes={allowedChargeTypes}
                                    stops={stops}
                                    lineItems={localLineItems}
                                    item={item}
                                    itemIndex={itemIndex}
                                    isLastItem={checkIfLastItem(itemIndex)}
                                    addLineItem={addLineItem}
                                    updateLineItem={updateLineItem}
                                    removeLineItem={removeLineItem}
                                    onFileUpload={onFileUpload}
                                    editModeFlag={editModeFlag}
                                    setEditModeFlag={setEditModeFlag}
                                    cancelAddNew={cancelAddNew}
                                />
                            )
                        } else {
                            return null
                        }
                    }

                    )}
                </Row>
            }

        </Row>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(LineItemCharges)

const useStyles = makeStyles({
    stopCol: {
        display: 'inline-block',
        width: '50%',
        paddingLeft: 15
    },
    timeCol: {
        display: 'inline-block',
        width: '50%',

    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        margin: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: -5,
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    fileUpload: {
        opacity: 0,
        position: 'absolute',
        zIndex: - 1
    },
    fileUploadLabel: {
        cursor: 'pointer',
        backgroundColor: 'lightgray',
        height: 'min-content',
        padding: 5,
        borderRadius: 5,
    }
});