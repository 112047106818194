import moment from 'moment'
export function Sort(prop, arr) {
    //console.log(prop,arr);
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (a, b) {
        var i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        if (a < b) {
            return 1;
        } else if (a > b) {
            return -1;
        } else {
            return 0;
        }
    });
    return arr;
};

export function sortDriverAvailsByDate(drivers){
  //console.log(drivers)
  var arrOfAvails = []
  drivers.map((driver)=>{
    //console.log(driver);
    driver.driver_availabilities.map((avail)=>{
      //console.log(avail);
      avail.unix=moment(avail.postedDate).unix()
      var element={}
      element.driver = driver
      element.availability=avail
      arrOfAvails.push(element)
    })
  })
  //console.log(arrOfAvails);
  var retValue = Sort('availability.unix',arrOfAvails)
  //console.log(retValue);
  return retValue
}
