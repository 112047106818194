
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Row, Col, Grid } from 'react-bootstrap';

import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import AddNewContactModal from './add-new-contact-modal'

const AddNewContact = (props) => {

    const { onComplete = null, locationsList = [], initialValues = null, muiTheme } = props

    const useStyles = makeStyles({
        headerText: {
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 16,
            fontWeight: 'bold',
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        }
    });

    const classes = useStyles()


    const defaultInitValues = {}
    const [showModal, setShowModal] = useState(false);
    const [initData, setInitData] = useState(defaultInitValues);

    useEffect(() => {
        if (initialValues) {
            setInitData(initialValues)
        }
    }, [initialValues]);


    const openAddNewContactDialog = () => {
        setShowModal(true)
    }
    const closeAddNewContactDialog = (flag) => {
        setShowModal(false)
        if (onComplete) {
            onComplete(flag)
        }
    }

    return (

        <Row style={{ display: 'flex', justifyContent: 'center' }} >
            {showModal && (<AddNewContactModal locationsList={locationsList} onClose={(event) => closeAddNewContactDialog(event)} initialValues={initData} />)}

            <Button
                id='addNewContactButton'
                size="small"
                style={{
                    backgroundColor: muiTheme.actionColor,
                    height: 40,
                    padding: 10
                }}
                onClick={(event) => { openAddNewContactDialog(event) }}
                startIcon={<FeatherIcon.Plus color='white' />}
            >
                <p className={classes.headerText} style={{ textTransform: "uppercase", color: "white", fontSize: 16 }}>Add New Contact</p>
            </Button>
        </Row>

    );
}

export default compose(
    muiThemeable()
)(AddNewContact)