
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Col, Row } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';
import { toast } from 'react-toastify'
import IconButton from '@material-ui/core/IconButton';
import SimpleLineIcon from 'react-simple-line-icons';
import Box from '@material-ui/core/Box';

import moment from 'moment'
import { checkValueExists } from '../../../../../lib/check-value-exists'

import { titleCaseFormatter } from "../../../../../lib/title-case-formatter"

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
import { get } from 'jquery';
// import {useAuthenticationHook} from '../../../../hooks/authentication-hook';


library.add(
    faBiohazard
);

const MyLoadCard = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        isExpanded,
        muiTheme,
        key,
        pushReloads,
        reloadsOpen,
    } = props

    const [isHazmat, setIsHazmat] = useState(false)

    // const myAuthService = useAuthenticationHook()
    // const isAuthenticated = myAuthService.isAuthenticated()
    // const user = myAuthService.getUser()

    const [anchorEl, setAnchorEl] = useState(null);
    const openRateBreakdown = Boolean(anchorEl);
    const open = Boolean(anchorEl);
    const id = openRateBreakdown ? 'rate-info-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRateOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleRateClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                setIsHazmat(true)
        }
    }, [])

    const getExpenseRate = (breakDown) => {
        if (breakDown.length === 1) {
            return breakDown[0].ledgerAmount
        } else {
            let total = 0;
            breakDown.forEach(element => {
                total += element.ledgerAmount
            });
            return total
        }
    }

    return (

        <div>

            <Row style={{ display: 'flex' }}>

                <Col style={{ width: '11%', display: 'flex' }}>
                    {checkValueExists(load, "loadHash") && (
                        <Col className={classes.centerColContent}>
                            <p className={classes.mainText}>{load.loadHash}</p>
                        </Col>
                    )}
                </Col>

                <Col style={{ width: '15%', display: 'flex' }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>


                        <p className={classes.mainText} >
                            {titleCaseFormatter(load.originCity) + ', ' + titleCaseFormatter(load.originState)}
                        </p>

                    </Col>
                </Col>

                <Col style={{ width: '12%', display: 'flex' }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>

                        <p className={classes.mainText}>
                            {moment(load.pickupDate).format('ll')}
                        </p>
                        <p className={classes.mainText}>
                            {moment(load.pickupTime, "hh:mm a").format('HH:mm')}
                        </p>

                    </Col>
                </Col>

                <Col style={{ width: '15%', display: 'flex' }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>


                        <p className={classes.mainText} >
                            {titleCaseFormatter(load.destinationCity) + ', ' + titleCaseFormatter(load.destinationState)}
                        </p>

                    </Col>
                </Col>

                <Col style={{ width: '20%', display: 'flex' }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>

                        <p className={classes.mainText}>
                            {moment(load.deliveryDate).format('ll')}
                        </p>
                        <p className={classes.mainText}>
                            {moment(load.deliveryTime, "hh:mm a").format('HH:mm')}
                        </p>

                    </Col>
                </Col>

                <Col style={{ width: "5%", display: 'flex' }}>
                    {/* 
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <p className={classes.headerText}>
                            {checkValueExists(load,'carrierTotal') ? 
                                `N/A`
                            }
                        </p>
                    </Col> */}
                    {/* Temporarily hiding rate until my-loads is at the user/driver level */}
                </Col>

                <Col style={{ width: "18%", display: 'flex' }}>

                    {/* <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left', paddingLeft: 15 }}>
                        <p className={classes.headerText}>{load.current_status}</p>
                    </Col> */}

                </Col>



                <Col style={{ width: "5%", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {/* <div>
                        <IconButton aria-describedby={id} onClick={handleClick}>
                            <SimpleLineIcon name={'options'} color={'black'} style={{ fontSize: 30 }} />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box p={1}>
                                <Button
                                    onClick={() => {
                                        // setShowEditModal(true)
                                        handleClose()
                                        // useGtagHook(
                                        //     "open_edit_saved_search",
                                        //     {
                                        //         location: item.pickupCity + ", " + item.pickupState,
                                        //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                        //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                        //         pickupTime: item.availableTime,
                                        //         destinations: item.destinations,
                                        //         equipments: item.truckTypes
                                        //     }
                                        // )                                   
                                    }}
                                    // startIcon={<FeatherIcon.Edit color={"black"} size={25} />}
                                    style={{ width: '100%', display: "flex", justifyContent: "left" }}
                                >
                                    <p className={classes.buttonText} style={{ textTransform: 'none' }}> UPLOAD DOCUMENT </p>
                                </Button>
                                <Button
                                    onClick={() => {
                                        // handleDeleteLanePreference()
                                        handleClose()
                                        // useGtagHook(
                                        //     "delete_saved_search",
                                        //     {
                                        //         location: item.pickupCity + ", " + item.pickupState,
                                        //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                        //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                        //         pickupTime: item.availableTime,
                                        //         destinations: item.destinations,
                                        //         equipments: item.truckTypes
                                        //     }
                                        // )
                                    }}
                                    // startIcon={<FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />}
                                    style={{ width: '100%', display: "flex", justifyContent: "left" }}
                                >
                                    <p className={classes.buttonText} style={{ textTransform: 'none' }}> CREATE INVOICE </p>
                                </Button>
                            </Box>

                        </Popover>
                    </div> */}
                </Col>

            </Row>

        </div>

    );
}


export default compose(
    connect(),
    muiThemeable()
)(MyLoadCard)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        margin: 0
    },
    buttonText: {
        fontSize: '16px',
        color: 'black',
        margin: 0,
        textAlign:'center',
        width:'inherit'
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    }
});