
import React from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Col, Row, Grid } from "react-bootstrap"
// import { Card } from 'material-ui/Card';


const UserListHeader = (props) => {
    const classes = useStyles();
    const { muiTheme } = props

    return (
        <div className={classes.root}>
            <Grid style={{ width: "100%", display: "flex", margin: 0, paddingLeft: 20, paddingRight: 20 }}>
                <Col className={classes.col} style={{ width: "18%" }} >
                    <p className={classes.mainText}>Name</p>
                </Col>
                <Col className={classes.col} style={{ width: "25%" }} >
                    <p className={classes.mainText}>Email</p>
                </Col>
                <Col className={classes.col} style={{ width: "15%" }} >
                    <p className={classes.mainText}>Phone</p>
                </Col>
                <Col className={classes.col} style={{ width: "9%" }} >
                    <p className={classes.mainText}>Role</p>
                </Col>
                <Col className={classes.col} style={{ width: "5%" }} >
                </Col>
                <Col className={classes.col} style={{ width: "18%" }} >
                </Col>
                <Col className={classes.col} style={{ width: "10%" }} >
                </Col>
            </Grid>
        </div>
    );
}


export default compose(
    muiThemeable()
)(UserListHeader)

const useStyles = makeStyles({
    root: {
        backgroundColor: "transparent",
        margin: 10,
        paddingBottom: 5,
        borderBottom: '1px solid lightgrey'
    },
    col: {
        height: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start"
    },
    mainText: {
        fontSize: '12px',
        lineHeight: '14px',
        color: 'black',
        // fontWeight: 'bold',
        margin: 0
    }
});