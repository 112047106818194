//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'
  import { showLoading, hideLoading } from 'react-redux-loading-bar'

//MESSAGING
  import * as TYPE from './constants'

  //APIs

  import {sendMessage,scheduleDemo} from '../api/contactUs'


 function* sendMessageCall (action) {
  try {
  const {postData}= action;
  yield put(showLoading())
  //console.log('importCapacityCall')

  // here until the API async function, is complete!
  const response = yield call(sendMessage,postData)


  if(response.status){
    action.successCallback()
  }
  else{
     action.failureCallback()
  }
  /**/

  yield put({ type: TYPE.CALL_SEND_CONTACT_MESSAGE_SUCCESS, response })


  } catch (error) {
  // if the api call fails, it will "put" the LOADS_ERROR
  // into the dispatch along with the error.
  yield put({ type: TYPE.CALL_SEND_CONTACT_MESSAGE_ERROR, error })

  }
  finally{
  yield put(hideLoading())
  }
}

 function* sendScheduleDemo (action) {
  try {
  const {postData}= action;
  yield put(showLoading())
  //console.log('importCapacityCall')

  // here until the API async function, is complete!
  const response = yield call(scheduleDemo,postData)


  if(response.status){
    action.successCallback()
  }
  else{
     action.failureCallback()
  }
  /**/

  yield put({ type: TYPE.CALL_SEND_CONTACT_MESSAGE_SUCCESS, response })


  } catch (error) {
  // if the api call fails, it will "put" the LOADS_ERROR
  // into the dispatch along with the error.
  yield put({ type: TYPE.CALL_SEND_CONTACT_MESSAGE_ERROR, error })

  }
  finally{
  yield put(hideLoading())
  }
}


function* sendMessageWatcher () {
  yield takeLatest(TYPE.CALL_SEND_CONTACT_MESSAGE, sendMessageCall)
  yield takeLatest(TYPE.CALL_SEND_SCHEDULE_DEMO, sendScheduleDemo)
}

export default sendMessageWatcher
