function gtag() { window.dataLayer.push(arguments); }
const GA_MEASUREMENT_ID = 'G-T99V8CFHKM'

export default function GoogleAnalyticsManager () {
    return {
        setUserProperties: (userObj) => {
            let properties = {
                mc: userObj.carrier.mc,
                dot: userObj.carrier.dot,
                company_name: userObj.carrier.companyName,
                external_id: userObj.carrier.externalId,
                name: userObj.fullName,
                role: userObj.role,
                user_id: String(userObj.userId),
                // user_type: userObj.userType,
                customer_account_id: userObj.customerAccountId
            }
            // console.log("PROPERTIES: ", properties)
            gtag('set', 'user_properties', properties)
        },
        getMeasurementId: () => {
            if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
                // Werner Carrier Edge
                return "G-YS11DJVRBH"
            } else if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b") {
                // England Logistics
                return "G-X1BFH99P8W"
            } else if (process.env.REACT_APP_ADVANTAGE_ID == "ju9agnj4") {
                // Blue Grace Logistics
                return "G-HGS4CF25X9"
            } else if (process.env.REACT_APP_ADVANTAGE_ID == "r85xb5y8") {
                // Reed TMS
                return "G-DJKLQCD332"
            } else if (process.env.REACT_APP_ADVANTAGE_ID == "srukfk5e") {
                // Ryder Managed Services
                return "G-0DBPXQSEHK"
            } else if (process.env.REACT_APP_ADVANTAGE_ID == "k6g3f58q") {
                //Kingsgate Logistics
                return "G-ZVB7BJZYKQ"
            } else {
                return GA_MEASUREMENT_ID
            }
        }
    }

}