
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Col, Row, Grid } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import { Card } from 'material-ui/Card';
import * as FeatherIcon from 'react-feather';
import SimpleLineIcon from 'react-simple-line-icons';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import { toast } from 'react-toastify';

import ConfirmDialog from '../../ConfirmDialog'
import LoadingOverlay from '../../LoadingOverlay'
import { WernerServices } from '../../../customers/a98db973/services';
import EditContactModal from './add-new-contact-modal'
import { checkValueExists } from "../../../../lib/check-value-exists"



const ContactItem = (props) => {
    const classes = useStyles();
    const { muiTheme, item, onDelete = null, onEditComplete = null } = props
    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false)
    const [isApiCalling, setIsApiCalling] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [initData, setInitData] = useState(item);

    /* --- Popover state control --- */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    /* ----------------------------- */

    useEffect(() => {
        if (item) {
            item.otherEmail = null
            item.faxFull = item.faxVo
            item.role = item.roleType
            setInitData(item)
        }
    }, [item]);

    const camelize = (str) => {
        if (str) {
            const wordArray = str.split(" ")
            let returnStr = ""
            wordArray.forEach(word => {
                const firstChar = word.slice(0, 1)
                const endOfWord = word.slice(1, word.length)
                returnStr = returnStr + " " + firstChar.toUpperCase() + endOfWord.toLowerCase()
            });
            return returnStr
        }
        return ""
    }

    const formatPhoneNumber = (phone) => {
        let returnStr = ""
        // add country code
        if (phone.country !== "USA") {
            returnStr = returnStr + phone.countryCode + "-"
        }
        let number = phone.phoneNumber
        var cleaned = ('' + phone.phoneNumber).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            number = [match[2], '-', match[3], '-', match[4]].join('');
        }
        returnStr = returnStr + number
        // add ext
        if (phone.extension) {
            returnStr = returnStr + " ext. " + phone.extension
        }
        return returnStr
    }


    const deleteItemProcess = (flag) => {
        console.log(item)
        setIsDeleteConfirmationShow(false)
        if (flag) {
            let params = {
                addressId: item.addressId,
                contactId: item.contactId
            }
            callDeleteApi(params)
        }
    }


    const callDeleteApi = (params) => {
        const apiServices = new WernerServices()
        setIsApiCalling(true)
        apiServices.deleteContact(params)
            .then(response => {
                setIsApiCalling(false)
                if (response) {
                    if (onDelete) {
                        onDelete(item)
                    }
                }
            })
            .catch(error => {
                setIsApiCalling(false)
            })
    }

    const contactDetailsUI = (ifShownForDelete = false) => {
        let ui = (
            <div style={{ width: '100%', overflowY: 'auto', overflowX: "auto", padding: 5 }}>
                {ifShownForDelete && (
                    <p className={classes.mainText} style={{ marginBottom: 20 }}>Are you sure you would like to delete this contact?</p>
                )}
                <dl className={classes.descriptionList} style={{width:400}}>
                    {checkValueExists(item, "name") && item.name !== "" &&
                        <div> 
                            <dt className={classes.descriptorTag}>
                                <p className={classes.secondaryText}>Name</p>
                            </dt>
                            <dd className={classes.descriptorDetail}>
                                <p className={classes.mainText}>{camelize(item.name)}</p>
                            </dd>
                        </div>
                    }
                    {checkValueExists(item, "title") && item.title !== "" &&
                        <div> 
                            <dt className={classes.descriptorTag}>
                                <p className={classes.secondaryText}>Title</p>
                            </dt>
                            <dd className={classes.descriptorDetail}>
                                <p className={classes.mainText}>{camelize(item.title)}</p>
                            </dd>
                        </div>
                    }
                    {checkValueExists(item, "contactType") && item.contactType !== "" &&
                        <div> 
                            <dt className={classes.descriptorTag}>
                                <p className={classes.secondaryText}>Contact Type</p>
                            </dt>
                            <dd className={classes.descriptorDetail}>
                                <p className={classes.mainText}>{camelize(item.contactType)} </p>
                            </dd>
                        </div>
                    }
                    {checkValueExists(item, "roleType") && item.roleType !== "" &&
                        <div> 
                            <dt className={classes.descriptorTag}>
                                <p className={classes.secondaryText}>Role Type</p>
                            </dt>
                            <dd className={classes.descriptorDetail}>
                                <p className={classes.mainText}>{camelize(item.roleType)}</p>
                            </dd>
                        </div>
                    }
                    {checkValueExists(item, "email") && item.email !== "" &&
                        <div> 
                            <dt className={classes.descriptorTag}>
                                <p className={classes.secondaryText}>Email</p>
                            </dt>
                            <dd className={classes.descriptorDetail}>
                                <p className={classes.mainText}>{String(item.email).toLowerCase()}</p>
                            </dd>
                        </div>
                    }
                </dl>
                {checkValueExists(item, "phoneList") && item.phoneList.length > 0 && 
                    <div style={{paddingTop: 10}}>
                        <p className={classes.secondaryText}> Phone List </p>
                        <dl className={classes.descriptionList} style={{marginLeft:5}}> 
                            {item.phoneList.map(phone => {
                                if (checkValueExists(phone, "phoneNumber") && phone.phoneNumber !== "") {
                                    return (
                                        <div>
                                            {/* <p className={classes.secondaryText} style={{ display: "inline", marginRight: 5 }}>{camelize(phone.type)}</p>
                                            <p className={classes.mainText} style={{ display: "inline" }}>{formatPhoneNumber(phone)}</p> */}
                                            <dt className={classes.descriptorTag}>
                                                <p className={classes.secondaryText} style={{ display: "inline", marginRight: 5 }}>{camelize(phone.type)}</p>
                                            </dt>
                                            <dd className={classes.descriptorDetail}>
                                                <p className={classes.mainText} style={{ display: "inline" }}>{formatPhoneNumber(phone)}</p>
                                            </dd>
                                        </div>
                                    )
                                }
                            })}
                        </dl>
                    </div>
                }
            </div>
        )
        return ui
    }


    const closeEditContactDialog = (flag) => {
        setShowModal(false)
        if (onEditComplete) {
            onEditComplete(flag)
        }
    }

    return (
        <Card className={classes.root}>
            {isApiCalling && <LoadingOverlay />}
            {showModal && (<EditContactModal locationsList={props.locationsList} onClose={(event) => closeEditContactDialog(event)} initialValues={initData} isEdit={true} />)}
            <ConfirmDialog open={isDeleteConfirmationShow} onClose={(flag) => deleteItemProcess(flag)} header={'Delete Contact'} bodyComp={contactDetailsUI(true)} body={''} acceptButtonText='YES' />
            <Grid style={{ width: "100%", height: 90, display: "flex", margin: 0, paddingLeft: 20, paddingRight: 20 }}>
                <Col className={classes.col} style={{ width: "15%" }} >
                    <p className={classes.mainText}>{camelize(item.name)}</p>
                </Col>
                <Col className={classes.col} style={{ width: "15%" }} >
                    <p className={classes.mainText}>{camelize(item.title)}</p>
                </Col>
                <Col className={classes.col} style={{ width: "15%" }} >
                    <p className={classes.mainText}>{camelize(item.contactType)}</p>
                </Col>
                <Col className={classes.col} style={{ width: "11%" }} >
                    <p className={classes.mainText}>{camelize(item.roleType)}</p>
                </Col>
                <Col className={classes.col} style={{ width: "20%" }} >
                    <dl className={classes.descriptionList}> 
                        {item.phoneList && item.phoneList.length > 0 && (
                            item.phoneList.map(phone => {
                                if (checkValueExists(phone, "phoneNumber") && phone.phoneNumber !== "") {
                                    return (
                                        <div>
                                            {/* <p className={classes.secondaryText} style={{ display: "inline", marginRight: 5 }}>{camelize(phone.type)}</p>
                                            <p className={classes.mainText} style={{ display: "inline" }}>{formatPhoneNumber(phone)}</p> */}
                                            <dt className={classes.descriptorTag}>
                                                <p className={classes.secondaryText} style={{ display: "inline", marginRight: 5 }}>{camelize(phone.type)}</p>
                                            </dt>
                                            <dd className={classes.descriptorDetail}>
                                                <p className={classes.mainText} style={{ display: "inline" }}>{formatPhoneNumber(phone)}</p>
                                            </dd>
                                        </div>
                                    )
                                }
                            })
                        )}
                    </dl>
                </Col>
                <Col className={classes.col} style={{ width: "18%" }} >
                    {checkValueExists(item, "email") &&
                        <p className={classes.mainText}>{String(item.email).toLowerCase()}</p>
                    }
                </Col>
                {/* <Col className={classes.col} style={{ width: "3%" }}>
                    <Button
                        onClick={() => { setShowModal(true) }}
                    >
                        <FeatherIcon.Edit color={"black"} size={25} />
                    </Button>
                </Col>
                <Col className={classes.col} style={{ width: "3%" }}>
                    <Button
                        onClick={() => { setIsDeleteConfirmationShow(true) }}
                    >
                        <FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />
                    </Button>
                </Col> */}
                <Col className={classes.col} style={{ width: "6%", display: "flex", flexDirection: "column", alignItems: "center", height: "100%" }}>
                    <IconButton aria-describedby={id} onClick={handleClick}>
                        <SimpleLineIcon name={'options'} color={'black'} style={{ fontSize: 30 }} />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box p={1}>
                            <Button
                                onClick={() => {
                                    setShowModal(true)
                                    handleClose()
                                }}
                                startIcon={<FeatherIcon.Edit color={"black"} size={25} />}
                                style={{ width: '100%', display: "flex", justifyContent: "left" }}
                            >
                                <p className={classes.mainText} style={{ textTransform: 'none' }}> Edit </p>
                            </Button>
                            <br />
                            <Button
                                onClick={() => {
                                    setIsDeleteConfirmationShow(true)
                                    handleClose()
                                }}
                                startIcon={<FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />}
                                style={{ width: '100%', display: "flex", justifyContent: "left" }}
                            >
                                <p className={classes.mainText} style={{ textTransform: 'none' }}> Delete </p>
                            </Button>
                        </Box>
                    </Popover>
                </Col>
            </Grid>
        </Card>

    );
}


export default compose(
    muiThemeable()
)(ContactItem)

const useStyles = makeStyles({
    root: {
        backgroundColor: "white",
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    col: {
        height: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start"
    },
    mainText: {
        fontSize: '16px',
        lineHeight: '20px',
        color: 'black',
        // fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '16px',
        lineHeight: '20px',
        color: 'grey',
        fontWeight: 'normal'
    },
    descriptionList: {
        width: '250px',
        overflow: 'hidden',
        padding: 0,
        margin: 0
    },
    descriptorTag: {
        float: 'left',
        width: '25%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetail: {
        float: 'left',
        width: '75%;',
        padding: 0,
        margin: 0,
        display: 'inline-block'
    },
});