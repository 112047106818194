import {BACKEND_URL} from './constants.js'
export function getAvailabilityLoads(availabilityId){
  var requestUrl = BACKEND_URL+'/carrier/getPriorityLoads?availabilityId='+availabilityId
  return fetch(requestUrl,{
                      method: 'GET',
                      headers: {
                        'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                          'Content-Type': 'application/json',
                      }
                    })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function getAvailabilityLoadsV2(availabilityId){

  var requestUrl = BACKEND_URL+'/carrier/getPriorityLoads?availabilityId='+availabilityId+'&includeOutOfNetwork=true&includeInNetwork=true'
  
  return fetch(requestUrl,{
                      method: 'GET',
                      headers: {
                        'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                          'Content-Type': 'application/json',
                      }
                    })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}