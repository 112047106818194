export const ZONE_0 = [
    { id: 8, name: "Connecticut", abbr: "CT", isSelected: false },
    { id: 21, name: "Maine", abbr: "ME", isSelected: false },
    { id: 23, name: "Massachusetts", abbr: "MA", isSelected: false },
    { id: 31, name: "New Hampshire", abbr: "NH", isSelected: false },
    { id: 32, name: "New Jersey", abbr: "NJ", isSelected: false },
    { id: 41, name: "Rhode Island", abbr: "RI", isSelected: false },
    { id: 47, name: "Vermont", abbr: "VT", isSelected: false }
  ]
  
  export const ZONE_1 = [
    { id: 9, name: "Delaware", abbr: "DE", isSelected: false },
    { id: 34, name: "New York", abbr: "NY", isSelected: false },
    { id: 40, name: "Pennsylvania", abbr: "PA", isSelected: false }
  ]
  
  export const ZONE_2 = [
    { id: 22, name: "Maryland", abbr: "MD", isSelected: false },
    { id: 35, name: "North Carolina", abbr: "NC", isSelected: false },
    { id: 42, name: "South Carolina", abbr: "SC", isSelected: false },
    { id: 48, name: "Virginia", abbr: "VA", isSelected: false },
    { id: 50, name: "West Virginia", abbr: "WV", isSelected: false }
  ]
  
  export const ZONE_3 = [
    { id: 2, name: "Alabama", abbr: "AL", isSelected: false },
    { id: 11, name: "Florida", abbr: "FL", isSelected: false },
    { id: 12, name: "Georgia", abbr: "GA", isSelected: false },
    { id: 26, name: "Mississippi", abbr: "MS", isSelected: false },
    { id: 44, name: "Tennessee", abbr: "TN", isSelected: false }
  ]
  
  export const ZONE_4 = [
    { id: 16, name: "Indiana", abbr: "IN", isSelected: false },
    { id: 19, name: "Kentucky", abbr: "KY", isSelected: false },
    { id: 24, name: "Michigan", abbr: "MI", isSelected: false },
    { id: 37, name: "Ohio", abbr: "OH", isSelected: false }
  ]
  
  export const ZONE_5 = [
    { id: 17, name: "Iowa", abbr: "IA", isSelected: false },
    { id: 25, name: "Minnesota", abbr: "MN", isSelected: false },
    { id: 28, name: "Montana", abbr: "MT", isSelected: false },
    { id: 36, name: "North Dakota", abbr: "ND", isSelected: false },
    { id: 43, name: "South Dakota", abbr: "SD", isSelected: false },
    { id: 51, name: "Wisconsin", abbr: "WI", isSelected: false }
  ]
  
  export const ZONE_6 = [
    { id: 15, name: "Illinois", abbr: "IL", isSelected: false },
    { id: 19, name: "Kansas", abbr: "KS", isSelected: false },
    { id: 27, name: "Missouri", abbr: "MO", isSelected: false },
    { id: 29, name: "Nebraska", abbr: "NE", isSelected: false }
  ]
  
  export const ZONE_7 = [
    { id: 5, name: "Arkansas", abbr: "AR", isSelected: false },
    { id: 20, name: "Louisiana", abbr: "LA", isSelected: false },
    { id: 38, name: "Oklahoma", abbr: "OK", isSelected: false },
    { id: 45, name: "Texas", abbr: "TX", isSelected: false }
  ]
  
  export const ZONE_8 = [
    { id: 4, name: "Arizona", abbr: "AZ", isSelected: false },
    { id: 7, name: "Colorado", abbr: "CO", isSelected: false },
    { id: 14, name: "Idaho", abbr: "ID", isSelected: false },
    { id: 30, name: "Nevada", abbr: "NV", isSelected: false },
    { id: 33, name: "New Mexico", abbr: "NM", isSelected: false },
    { id: 46, name: "Utah", abbr: "UT", isSelected: false },
    { id: 52, name: "Wyoming", abbr: "WY", isSelected: false }
  ]
  
  export const ZONE_9 = [
    { id: 6, name: "California", abbr: "CA", isSelected: false },
    { id: 39, name: "Oregon", abbr: "OR", isSelected: false },
    { id: 49, name: "Washington", abbr: "WA", isSelected: false },
    { id: 3, name: "Alaska", abbr: "AK", isSelected: false }
  ]

  export const ZONE_E = [
    { id: 76, name: "New Brunswick", abbr: "NB", isSelected: false },
    { id: 77, name: "New Foundland", abbr: "NL", isSelected: false },
    { id: 78, name: "Nova Scotia", abbr: "NS", isSelected: false },
    { id: 79, name: "Prince Edward Island", abbr: "PE", isSelected: false }
  ]

  export const ZONE_C = [
    { id: 80, name: "Ontario", abbr: "ON", isSelected: false },
    { id: 81, name: "Quebec", abbr: "PQ", isSelected: false },
  ]

  export const ZONE_W = [
    { id: 82, name: "Alberta", abbr: "AB", isSelected: false },
    { id: 83, name: "British Columbia", abbr: "BC", isSelected: false },
    { id: 84, name: "Manitoba", abbr: "MB", isSelected: false },
    { id: 85, name: "Saskatchewan", abbr: "SK", isSelected: false }
  ]

  export const ZONE_M = [
    { id: 86, name: "Mexico", abbr: "Mexico", isSelected: false },
  ]

  export const US_STATES = [
    {name: "Alabama",abbr: "AL",isSelected:false},
    {name: "Alaska",abbr: "AK",isSelected:false},
    {name: "Arizona",abbr: "AZ",isSelected:false},
    {name: "Arkansas",abbr: "AR",isSelected:false},
    {name: "California",abbr: "CA",isSelected:false},
    {name: "Colorado",abbr: "CO",isSelected:false},
    {name: "Connecticut",abbr: "CT",isSelected:false},
    {name: "Delaware",abbr: "DE",isSelected:false},
    {name: "District Of Columbia",abbr: "DC",isSelected:false},
    {name: "Florida",abbr: "FL",isSelected:false},
    {name: "Georgia",abbr: "GA",isSelected:false},
    {name: "Hawaii",abbr: "HI",isSelected:false},
    {name: "Idaho",abbr: "ID",isSelected:false},
    {name: "Illinois",abbr: "IL",isSelected:false},
    {name: "Indiana",abbr: "IN",isSelected:false},
    {name: "Iowa",abbr: "IA",isSelected:false},
    {name: "Kansas",abbr: "KS",isSelected:false},
    {name: "Kentucky",abbr: "KY",isSelected:false},
    {name: "Louisiana",abbr: "LA",isSelected:false},
    {name: "Maine",abbr: "ME",isSelected:false},
    {name: "Maryland",abbr: "MD",isSelected:false},
    {name: "Massachusetts",abbr: "MA",isSelected:false},
    {name: "Michigan",abbr: "MI",isSelected:false},
    {name: "Minnesota",abbr: "MN",isSelected:false},
    {name: "Mississippi",abbr: "MS",isSelected:false},
    {name: "Missouri",abbr: "MO",isSelected:false},
    {name: "Montana",abbr: "MT",isSelected:false},
    {name: "Nebraska",abbr: "NE",isSelected:false},
    {name: "Nevada",abbr: "NV",isSelected:false},
    {name: "New Hampshire",abbr: "NH",isSelected:false},
    {name: "New Jersey",abbr: "NJ",isSelected:false},
    {name: "New Mexico",abbr: "NM",isSelected:false},
    {name: "New York",abbr: "NY",isSelected:false},
    {name: "North Carolina",abbr: "NC",isSelected:false},
    {name: "North Dakota",abbr: "ND",isSelected:false},
    {name: "Ohio",abbr: "OH",isSelected:false},
    {name: "Oklahoma",abbr: "OK",isSelected:false},
    {name: "Oregon",abbr: "OR",isSelected:false},
    {name: "Pennsylvania",abbr: "PA",isSelected:false},
    {name: "Rhode Island",abbr: "RI",isSelected:false},
    {name: "South Carolina",abbr: "SC",isSelected:false},
    {name: "South Dakota",abbr: "SD",isSelected:false},
    {name: "Tennessee",abbr: "TN",isSelected:false},
    {name: "Texas",abbr: "TX",isSelected:false},
    {name: "Utah",abbr: "UT",isSelected:false},
    {name: "Vermont",abbr: "VT",isSelected:false},
    {name: "Virginia",abbr: "VA",isSelected:false},
    {name: "Washington",abbr: "WA",isSelected:false},
    {name: "West Virginia",abbr: "WV",isSelected:false},
    {name: "Wisconsin",abbr: "WI",isSelected:false},
    {name: "Wyoming",abbr: "WY",isSelected:false},
    {name: "Alberta",abbr: "AB",isSelected:false},
    {name: "British Columbia",abbr: "BC",isSelected:false},
    {name: "Manitoba",abbr: "MB",isSelected:false},
    {name: "New Brunswick",abbr: "NB",isSelected:false},
    {name: "Newfoundland",abbr: "NF",isSelected:false},
    {name: "Northwest Territories",abbr: "NT",isSelected:false},
    {name: "Nova Scotia",abbr: "NS",isSelected:false},
    {name: "Nunavut",abbr: "NU",isSelected:false},
    {name: "Ontario",abbr: "ON",isSelected:false},
    {name: "Prince Edward Island",abbr: "PE",isSelected:false},
    {name: "Quebec",abbr: "PQ",isSelected:false},
    {name: "Saskatchewan",abbr: "SK",isSelected:false},
    {name: "Yukon",abbr: "YT",isSelected:false},
  ]


  export const zonesData = [
    { id: 66, name: "Zone 0 (CT, ME, MA, NH, NJ, RI, VT)", abbr: "Z0", isSelected: false , states:['CT', 'ME', 'MA', 'NH', 'NJ', 'RI', 'VT'] },
    { id: 67, name: "Zone 1 (DE, NY, PA)", abbr: "Z1", isSelected: false , states:['DE', 'NY', 'PA']},
    { id: 68, name: "Zone 2 (MD, NC, SC, VA, WV)", abbr: "Z2", isSelected: false , states:['MD', 'NC', 'SC', 'VA', 'WV']},
    { id: 69, name: "Zone 3 (AL, FL, GA, MS, TN)", abbr: "Z3", isSelected: false , states:['AL', 'FL', 'GA', 'MS', 'TN']},
    { id: 70, name: "Zone 4 (IN, KY, MI, OH)", abbr: "Z4", isSelected: false , states:['IN', 'KY', 'MI', 'OH'] },
    { id: 71, name: "Zone 5 (IA, MN, MT, ND, SD, WI)", abbr: "Z5", isSelected: false , states:['IA', 'MN', 'MT', 'ND', 'SD', 'WI'] },
    { id: 72, name: "Zone 6 (IL, KS, MO, NE)", abbr: "Z6", isSelected: false , states:['IL', 'KS', 'MO', 'NE'] },
    { id: 73, name: "Zone 7 (AR, LA, OK, TX)", abbr: "Z7", isSelected: false , states:['AR', 'LA', 'OK', 'TX'] },
    { id: 74, name: "Zone 8 (AZ, CO, ID, NV, NM, UT, WY)", abbr: "Z8", isSelected: false , states:['AZ', 'CO', 'ID', 'NV', 'NM', 'UT', 'WY'] },
    { id: 75, name: "Zone 9 (CA, OR, WA, AK)", abbr: "Z9", isSelected: false , states:['CA', 'OR', 'WA', 'AK'] },
    { id: 87, name: "Zone E (NB, NL, NS, PE)", abbr: "ZE", isSelected: false , states:['NB', 'NL', 'NS', 'PE'] },
    { id: 88, name: "Zone C (ON, PQ)", abbr: "ZC", isSelected: false , states:['ON', 'PQ'] },
    { id: 89, name: "Zone W (AB, BC, MB, SK)", abbr: "ZW", isSelected: false , states:['AB', 'BC', 'MB', 'SK'] },
    { id: 90, name: "Zone M Mexico", abbr: "ZM", isSelected: false , states:['ZM'] }
  ];

  export const ZONES_FORMATTED_STATES = [
    { id: 66, name: "Zone 0 (CT, ME, MA, NH, NJ, RI, VT)", abbr: "Z0", isSelected: false , states:[{name: 'Connecticut', abbr: 'CT'}, {name: 'Maine', abbr: 'ME'}, {name: 'Massachusetts', abbr: 'MA'}, {name: 'New Hampshire', abbr: 'NH'}, {name: 'New Jersey', abbr: 'NJ'}, {name: 'Rhode Island', abbr: 'RI'}, {name: 'Vermont', abbr: 'VT'}] },
    { id: 67, name: "Zone 1 (DE, NY, PA)", abbr: "Z1", isSelected: false , states:[{name: 'Delaware', abbr: 'DE'}, {name: 'New York', abbr: 'NY'}, {name: 'Pennsylvania', abbr: 'PA'}] },
    { id: 68, name: "Zone 2 (MD, NC, SC, VA, WV)", abbr: "Z2", isSelected: false , states:[{name: 'Maryland', abbr: 'MD'}, {name: 'North Carolina', abbr: 'NC'}, {name: "South Carolina", abbr: 'SC'}, {name: 'Virginia', abbr: 'VA'}, {name: 'West Virginia', abbr: 'WV'}] },
    { id: 69, name: "Zone 3 (AL, FL, GA, MS, TN)", abbr: "Z3", isSelected: false , states:[{name: 'Alabama', abbr: 'AL'}, {name: 'Florida', abbr: 'FL'}, {name: 'Georgia', abbr: 'GA'}, {name: 'Mississippi', abbr: 'MS'}, {name: 'Tennessee', abbr: 'TN'}] },
    { id: 70, name: "Zone 4 (IN, KY, MI, OH)", abbr: "Z4", isSelected: false , states:[{name: 'Indiana', abbr: 'IN'}, {name: 'Kentucky', abbr: 'KY'}, {name: 'Michigan', abbr: 'MI'}, {name: 'Ohio', abbr: 'OH'}] },
    { id: 71, name: "Zone 5 (IA, MN, MT, ND, SD, WI)", abbr: "Z5", isSelected: false , states:[{name: 'Iowa', abbr: 'IA'}, {name: 'Minnesota', abbr: 'MN'}, {name: 'Montana', abbr: 'MT'}, {name: 'North Dakota', abbr: 'ND'}, {name: 'South Dakota', abbr: 'SD'}, {name: 'Wisconsin', abbr: 'WI'}] },
    { id: 72, name: "Zone 6 (IL, KS, MO, NE)", abbr: "Z6", isSelected: false , states:[{name: 'Illinois', abbr: 'IL'}, {name: 'Kansas', abbr: 'KS'}, {name: 'Missouri', abbr: 'MO'}, {name: 'Nebraska', abbr: 'NE'}] },
    { id: 73, name: "Zone 7 (AR, LA, OK, TX)", abbr: "Z7", isSelected: false , states:[{name: 'Arkansas', abbr: 'AR'}, {name: 'Louisiana', abbr: 'LA'}, {name: 'Oklahoma', abbr: 'OK'}, {name: 'Texas', abbr: 'TX'}] },
    { id: 74, name: "Zone 8 (AZ, CO, ID, NV, NM, UT, WY)", abbr: "Z8", isSelected: false , states:[{name: 'Arizona', abbr: 'AZ'}, {name: 'Colorado', abbr: 'CO'}, {name: 'Idaho', abbr: 'ID'}, {name: 'Nevada', abbr: 'NV'}, {name: 'NM', abbr: 'NM'}, {name: 'Utah', abbr: 'UT'}, {name: 'Wyoming', abbr: 'WY'}] },
    { id: 75, name: "Zone 9 (CA, OR, WA, AK)", abbr: "Z9", isSelected: false , states:[{name: 'California', abbr: 'CA'}, {name: 'Oregon', abbr:'OR'}, {name: 'Washington', abbr:'WA'}, {name: 'Alaska', abbr:'AK'}] },
    { id: 87, name: "Zone E (NB, NL, NS, PE)", abbr: "ZE", isSelected: false , states:[{name: 'New Brunswick', abbr: 'NB'}, {name: 'New Foundland', abbr: 'NL'}, {name: 'Nova Scotia', abbr:'NS'}, {name: 'Prince Edward Island', abbr:'PE'}] },
    { id: 88, name: "Zone C (ON, PQ)", abbr: "ZC", isSelected: false , states:[{name: 'Ontario', abbr: 'ON'}, {name: 'Quebec', abbr: 'PQ'}] },
    { id: 89, name: "Zone W (AB, BC, MB, SK)", abbr: "ZW", isSelected: false , states:[{name: 'Alberta', abbr: 'AB'}, {name: 'British Columbia', abbr: 'BC'}, {name: 'Manitoba', abbr: 'MB'}, {name: 'Saskatchewan', abbr: 'SK'}] },
    { id: 90, name: "Zone M Mexico", abbr: "ZM", isSelected: false , states:['ZM'] }
  ];


  export const anyWhereZone =    { id: -1, name: "ANYWHERE", abbr: "ANYWHERE", isSelected: false , states:[] }
  export const mapZone = (zoneAbbr) => {
    let zones = zonesData;
    let obj = zones.find(zone => zone.abbr === zoneAbbr);
    if(!obj){
      obj = anyWhereZone
    }
    return obj
}