import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { clientFields } from './components/clientFields'
import { FormFields } from './components/formFields'
import { GetProfile } from '../../../api/profiledata'
import { getELDIntegration, createELDIntegration, updateELDIntegration, testConnection, addNewClient, uploadFile, sendEldEmail, createELDAction, sendSiteEldDataToSupport } from './components/api'
import './style.css';
import SimpleMap from './components/SimpleMap/SimpleMap.js';
import Button from 'react-bootstrap/lib/Button'
import LoadingOverlay from '../LoadingOverlay'
import { Modal } from 'react-bootstrap';
import queryString from 'query-string';

const BASEURL = 'https://www.truckertools.com'
const isMobile = window.innerWidth <= 500;

export default class EldSetup extends Component {

  constructor(props) {
    super(props);

    let url = props.location.search;
    let params = queryString.parse(url);

    this.state = {
      brokerName: params.broker,
      items: {},
      existingData: null,
      payload: {},
      file: null,
      client: '',
      linkArray: [],
      userProfile: {},
      showDoc: false,
      showEmailModal: false,
      docSrc: null,
      isUpdate: false,
      isCreate: false,
      isEmail: false,
      isAdd: false,
      isUpload: false,
      hasCurrentProvider: false,
      loading: true,
      lat: null,
      lng: null,
      fname: params.fname?params.fname.trim():"",
      lname: params.lname?params.lname.trim():"",
      wemail: params.wemail?params.wemail.trim():"",
      wphone: params.wphone?params.wphone.trim():"",
      eprovider: params.eprovider?params.eprovider.trim():"",
      cname: params.cname?params.cname.trim():"",
      mcnum: params.mcnum?"MC"+params.mcnum.replace(/[^0-9]/g, ''):"",
      lead: params.lead?params.lead.trim():"",
      paramsCount: Object.keys(params).length,
      showKeepTruckingAPIForm: false,
      showVerizonAPIForm: false,
      showOnlyConfirmationBox: false,
      showELDRiderAPIForm: false,
      eldProviderFullname: ''
    };

    this.handleClientChange = this.handleClientChange.bind(this);
    this.checkExisting = this.checkExisting.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleTestConnection = this.handleTestConnection.bind(this)
    this.handleFormFieldChange = this.handleFormFieldChange.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.displayMsg = this.displayMsg.bind(this)
    this.validation = this.validation.bind(this)
    this.checkSubmissionType = this.checkSubmissionType.bind(this)
    this.createPayload = this.createPayload.bind(this)
    this.renderDoc = this.renderDoc.bind(this)
    this.hideDoc = this.hideDoc.bind(this)
    this.renderEmailPrompt = this.renderEmailPrompt.bind(this)
    this.sendTeletracEmail = this.sendTeletracEmail.bind(this)
    this.callClientChangeFromSite = this.callClientChangeFromSite.bind(this)
    this.callKeepTruckingAPIForm = this.callKeepTruckingAPIForm.bind(this) 
    this.callVerizonAPIForm = this.callVerizonAPIForm.bind(this) 
    this.callELDRiderAPIForm = this.callELDRiderAPIForm.bind(this) 
    


  }

  componentDidMount() {
    if(this.state.paramsCount !== 0){
      localStorage.removeItem('token');
      // localStorage.clear();
      if(this.state.fname !== "" && this.state.lname !== "" && this.state.wemail !== "" && this.state.wphone !== "" && this.state.eprovider !== "" && this.state.cname !== "" && this.state.mcnum !== "" && this.state.lead !== "")
      {
        //console.log('CARRIER QUERY-STR DATA FOUND');
        var eprovider = this.state.eprovider
        var clientSupported = Object.keys(clientFields).includes(eprovider);
        if(clientSupported){
          var mcnum = this.state.mcnum
          var recivedCarrierObj = {
            carrierEmail:this.state.wemail, 
            carrierPhone:this.state.wphone, 
            carrierName:this.state.cname, 
            companyName:this.state.cname,
            contactName:this.state.fname+' '+this.state.lname,
            carrierMCNumber:mcnum,
            client:eprovider,
            lead:this.state.lead,
          }
          if (mcnum) {
            this.checkExistingWithoutLogin(mcnum)
            this.setState(state => ({
              eldProviderFullname: clientFields[eprovider].fullName
            }))
            this.callClientChangeFromSite(eprovider,recivedCarrierObj)
          } else {
            toast.error("No MC# is provided")
            setTimeout(() => {
              window.location.replace(BASEURL+'/carriers/eld-carrier-integration')
            }, 5000)
          }
        } else {
          toast.error("Wrong ELD information.")
          setTimeout(() => {
            window.location.replace(BASEURL+'/carriers/eld-carrier-integration')
          }, 5000)
        }
      }
      else {
        toast.error("Missing carrier information. Please contact support@truckertools.com.")
        setTimeout(() => {
          window.location.replace(BASEURL+'/carriers/eld-carrier-integration')
        }, 5000)
      }
    }
    else{
      GetProfile().then(resp => {
        this.setState({ loading: false, userProfile: resp })
        if (resp.mc) {
          this.checkExisting(resp.mc)
        } else {
          toast.error("There is no MC# associated with this account.")
          setTimeout(() => {
            this.props.history.goBack()
          }, 5000)
        }
      }).catch(error => {
        toast.error("Error retrieving user profile.")
        setTimeout(() => {
          this.props.history.goBack()
        }, 5000)
      })
    }
  }

  checkExistingWithoutLogin(mcNum) {
    this.setState(() => ({ loading: true }))

    if(this.state.eprovider == 'addNewProvider'){
      this.setState(state => ({
        existingData: null,
        isUpdate: false,
        isAdd: true,
        isCreate: false,
        loading: false
  }))
    }
    else if (clientFields.emailClients.includes(this.state.eprovider)) {
      if (this.state.eprovider === 'peopleNet') {
        this.setState(state => ({
          isUpdate: false,
          isAdd: false,
          isCreate: false,
          isEmail: false,
          isUpload: true,
          loading: false
        }))
      }
      else {
        this.setState(state => ({
          isUpdate: false,
          isAdd: false,
          isCreate: false,
          isEmail: true,
          isUpload: false,
          loading: false
        }))
      }
    }
    else{
      getELDIntegration(mcNum).then(resp => {

      
        if (resp.status){
            this.setState(state => ({
                existingData: resp.data,
                isUpdate: true,
                isAdd: false,
                isCreate: false,
                loading: false
          }))
      }else{
        this.setState(state => ({
              existingData: null,
              isUpdate: false,
              isAdd: false,
              isCreate: true,
              loading: false
        }))
      }
        
      }).catch(error => {
        toast.error("Internal system error. Please try again later.")
        setTimeout(() => {
          this.props.history.goBack()
        }, 5000)
      })
    }

  }

  checkExisting(mcNum) {

    this.setState(() => ({ loading: true }))
    getELDIntegration(mcNum).then(resp => {

      let clientSupported = false
      if (resp.status)
        clientSupported = Object.keys(clientFields).includes(resp.data.client)

      if (resp.status && clientSupported) {
        this.setState(state => ({
          items: clientFields[resp.data.client],
          client: resp.data.client,
          linkArray: clientFields.links[resp.data.client],
          existingData: resp.data,
          payload: resp.data,
          mcNum: mcNum,
          hasCurrentProvider: true,
          isUpdate: true,
          isAdd: false,
          isCreate: false,
          loading: false
        }))
      } else if (resp.status && !clientSupported) {
        let payload = this.createPayload('addNewProvider')
        this.setState(state => ({
          items: clientFields['addNewProvider'],
          client: 'addNewProvider',
          existingData: payload,
          payload: payload,
          mcNum: mcNum,
          hasCurrentProvider: true,
          isUpdate: false,
          isAdd: true,
          isCreate: false,
          loading: false
        }))
      }
      else {
        this.setState(state => ({
          mcNum: mcNum,
          hasCurrentProvider: false,
          isUpdate: false,
          isAdd: false,
          isCreate: true,
          loading: false
        }))
      }
    }).catch(error => {
      toast.error("Internal system error. Please try again later.")
      setTimeout(() => {
        this.props.history.goBack()
      }, 5000)
    })

  }

  checkSubmissionType(clientName) {

    if (clientName === 'addNewProvider') {
      this.setState(state => ({
        isUpdate: false,
        isAdd: true,
        isCreate: false,
        isEmail: false,
        isUpload: false
      }))
    }
    else if (clientFields.emailClients.includes(clientName)) {
      if (clientName === 'peopleNet') {
        this.setState(state => ({
          isUpdate: false,
          isAdd: false,
          isCreate: false,
          isEmail: false,
          isUpload: true
        }))
      }
      else {
        this.setState(state => ({
          isUpdate: false,
          isAdd: false,
          isCreate: false,
          isEmail: true,
          isUpload: false
        }))
      }
    }
    else if (this.state.existingData) {
      this.setState(state => ({
        isUpdate: true,
        isAdd: false,
        isCreate: false,
        isEmail: false,
        isUpload: false
      }))
    }
    else {
      this.setState(state => ({
        isUpdate: false,
        isAdd: false,
        isCreate: true,
        isEmail: false,
        isUpload: false
      }))
    }
  }

  createPayload(clientName) {
    //creating empty data set based in client fields 
    let isProd = process.env.REACT_APP_ENVIRONMENT === 'production'
    let payload = {}
    let fieldObj = clientFields[clientName]
    Object.keys(fieldObj).forEach(key => {
      if (key !== 'instruction') {
        switch (key) {
          case 'url':
            payload['url'] = clientFields[clientName].url.defaultValue
            break
          case 'eldProviderEmail':
            payload['eldProviderEmail'] = isProd ? clientFields[clientName].eldProviderEmail.defaultValue : 'jverne@truckertools.com;tiru@truckertools.com'
            break
          case 'asset_type':
            payload['asset_type'] = clientFields[clientName].asset_type.defaultValue
            break
          default:
            payload[fieldObj[key].apiName] = ""
        }
      }
    })

    payload['client'] = clientName
    payload['carrierMCNumber'] = this.state.userProfile.mc
    payload['carrierEmail'] = this.state.userProfile.email    
    payload['companyName'] = this.state.userProfile.companyName
    payload['carrierName'] = this.state.userProfile.companyName
    return payload
  }

  createPayloadFromCarrierObj(clientName,recivedCarrierObj) {
    //creating empty data set based in client fields 
    let isProd = process.env.REACT_APP_ENVIRONMENT === 'production'
    let payload = {}
    let fieldObj = clientFields[clientName]
    Object.keys(fieldObj).forEach(key => {
      if (key !== 'instruction') {
        switch (key) {
          case 'url':
            payload['url'] = clientFields[clientName].url.defaultValue
            break
          case 'eldProviderEmail':
            payload['eldProviderEmail'] = isProd ? clientFields[clientName].eldProviderEmail.defaultValue : 'jverne@truckertools.com;tiru@truckertools.com'
            break
          case 'asset_type':
            payload['asset_type'] = clientFields[clientName].asset_type.defaultValue
            break
          default:
            payload[fieldObj[key].apiName] = ""
        }
      }
    })

    payload['client'] = clientName
    payload['carrierMCNumber'] = recivedCarrierObj.carrierMCNumber
    payload['carrierEmail'] = recivedCarrierObj.carrierEmail
    payload['companyName'] = recivedCarrierObj.companyName
    payload['carrierName'] = recivedCarrierObj.carrierName
    payload['contactName'] = recivedCarrierObj.contactName
    payload['carrierPhone'] = recivedCarrierObj.carrierPhone
    payload['lead'] = recivedCarrierObj.lead
    
    this.setState(state => ({
      userProfile : {
        mc:recivedCarrierObj.carrierMCNumber,
        email:recivedCarrierObj.carrierEmail,
        companyName:recivedCarrierObj.companyName,
        contactName:recivedCarrierObj.contactName,
        carrierPhone:recivedCarrierObj.carrierPhone,
        lead:recivedCarrierObj.lead,
      }
    }))

    return payload
  }

  handleClientChange(event) {
    const target = event.target
    const clientName = target.value

    document.getElementById("eld-signup-form").reset();

    if (this.state.existingData && clientName === this.state.existingData.client) {
      //navigating back to existing eld client info
      this.checkSubmissionType(clientName)
      this.setState(state => ({
        items: clientFields[clientName],
        client: clientName,
        linkArray: clientFields.links[clientName],
        payload: state.existingData,
        hasCurrentProvider: true,
        file: null,
        lat: null,
        lng: null
      }))

    } else {

      this.checkSubmissionType(clientName)
      let payload = this.createPayload(clientName)
      this.setState(state => ({
        items: clientFields[clientName],
        client: clientName,
        linkArray: clientFields.links[clientName],
        hasCurrentProvider: false,
        file: null,
        payload: payload,
        lat: null,
        lng: null
      }))
      // store provider selection
      createELDAction(payload);      
    }
  }

  callClientChangeFromSite(clientName,recivedCarrierObj) {

    document.getElementById("eld-signup-form").reset();

      this.checkSubmissionType(clientName)
      let payload =  this.createPayloadFromCarrierObj(clientName,recivedCarrierObj)
      this.setState(state => ({
        items: clientFields[clientName],
        client: clientName,
        linkArray: clientFields.links[clientName],
        hasCurrentProvider: false,
        file: null,
        payload: payload,
        lat: null,
        lng: null
      }))
      // store provider selection
      createELDAction(payload);   
      sendSiteEldDataToSupport(payload);  
      var autoSubmitConfirmation = clientFields['autoSubmitConfirmation'].indexOf(clientName);
      if(autoSubmitConfirmation != -1)
      {
        this.setState({showOnlyConfirmationBox: true})
        sendEldEmail(payload);
      }  
    
  }

  handleFormFieldChange(value, id) {
    const payload = this.state.payload
    const newPayload = {
      ...payload,
      [id] : value
    }
    this.setState({ payload:newPayload })
  }

  displayMsg(resp, type) {
    if (resp.status) {
      switch (type) {
        case 'update':
          toast.success("Update successful!")
          break;
        case 'create':
          toast.success("Creation successful!")
          break;
        case 'add':
          toast.success("Success! Our team is working on it.")
          break;
        case 'email':
          toast.success("Success!")
      }
    } else {
      toast.error(resp.message ? resp.message : "Internal system error. Please try again later.")
    }
  }

  handleTestConnection() {
    if (!this.state.payload['vehicle_identity']) {
      toast.error('Vehichle# is required to test connection.')
      return;
    }
    let exitStatus = false
    Object.keys(this.state.payload).forEach(key => {
      if (key === ('password' || 'token')) {
        if (this.state.items[key]) {
          if (this.state.payload[key] === (null || "")) {
            toast.error(this.state.items[key].displayName + ' is required to test connection.')
            exitStatus = true
            return;
          }
        }
      }
    })

    if (exitStatus) { return }

    //reseting map
    this.setState({
      loading: true,
      lat: null,
      lng: null
    })

    testConnection(this.state.payload).then(resp => {
      if (resp.status) {
        toast.success("Test successful!")

        this.setState({
          loading: false,
          lat: resp.data ? parseFloat(resp.data.latitude) : null,
          lng: resp.data ? parseFloat(resp.data.longitude) : null
        })
      } else {
        this.setState({ loading: false })
        toast.error(resp.message)
      }
    }).catch(error => {
      toast.error("Internal system error. Please try again later.")
    })
  }

  handleFileUpload(event) {
    this.setState({ file: event.target.files[0] })
  }


  renderDoc(event) {
    event.preventDefault()
    const target = event.target

    let docType = null
    switch (target.name) {
      case 'instructionsLink':
        docType = 'instruction'
        break;
      case 'dataShareAgreementDoc':
        docType = 'dataShareAgreement'
        break;
    }

    let docSrc = clientFields.links[this.state.client][docType].path
    if (docSrc !== (null || undefined)) {
      this.setState({
        docSrc: docSrc,
        showDoc: true
      })
    }
  }

  hideDoc(event) {
    this.setState({
      showDoc: false,
      showEmailModal: false
    })
  }

  renderEmailPrompt() {
    this.setState({
      showEmailModal: true
    })
  }

  sendTeletracEmail() {

    let params = {
      client: "teletrac",
      eldProviderEmail: "us.customersupport@teletracnavman.com",
      companyName: this.state.userProfile.companyName,
      carrierMCNumber: this.state.userProfile.mc,
      contactName: this.state.userProfile.fullname,
      carrierEmail: this.state.userProfile.email
    }

    this.setState({ loading: true }, () => {
      sendEldEmail(params).then(resp => {

        this.setState({ loading: false })
        this.displayMsg(resp, 'email')

      }).catch(() => {
        this.setState({ loading: false })
        toast.error("Internal system error. Please try again later.")
      })
    })

  }

  toReadable = (text) => {
    let result = text.replace(/([A-Z])/g, " $1");
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
  }

  validation() {

    let valid = true
    let obj = this.state.payload
    if (Object.entries(obj).length === 0 && obj.constructor === Object) {
      toast.error("Please select a client.")
      valid = false;
      return
    }

    Object.keys(this.state.items).forEach(item => {
      let errors = false
      if (this.state.items[item].required) {
        let data = this.state.payload[item]
        if (data === "") {
          toast.error(this.state.items[item].displayName + " is required.")
          errors = true
          valid = false
          return
        }
      }

      if (this.state.items[item].editable && item.includes('Email')) {
        let data = this.state.payload[item]
        if (data === "" && !this.state.items[item].required) {
          return
        }
        else {
          let emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          errors = errors ? true : !emailRegEx.test(data)
        }
        if (errors) {
          toast.error(this.state.items[item].displayName + " is invalid.")
          valid = false
          return
        }
      }

      let data = this.state.payload[item]
      // if(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(data) && this.state.items[item].show){
      //   toast.error(this.state.items[item].displayName + " cannot contain special characters.")
      //   errors = true
      //   valid = false
      //   return
      // }

      if (typeof data === 'string' && this.state.items[item].show) {
        if (data.length > this.state.items[item].maxLength) {
          toast.error(this.state.items[item].displayName + " cannot be greater than " + this.state.items[item].maxLength + " characters long.")
          errors = true
          valid = false
          return
        }
      }


    })

    /*if (this.state.client === 'peopleNet' && this.state.file === null) {
      toast.error("Please upload a signed DSA.")
      valid = false
    }*/

    return valid
  }

  eldTargetHref = () => {
    if(clientFields.links[this.state.eprovider]['eldTargetHref']){
    var ahref = <a href={clientFields.links[this.state.eprovider]['eldTargetHref'].path} target="_blank">{this.state.eldProviderFullname}</a>
    return ahref;
    }else{
      return this.state.eldProviderFullname
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    const payload = this.state.payload
    Object.keys(payload).forEach(key => {
      payload[key] = payload[key].trim()
    })

    localStorage.setItem('payloadItems', JSON.stringify(payload));
    console.log('payloadItems',localStorage.getItem('payloadItems'));

    ///// Redirection for this.state.client=="KeepTrucking" /////
    
    if(this.state.client=="KeepTrucking")
    {
      window.location.href = "https://keeptruckin.com/oauth/authorize?client_id=89c090556c90f6c4a9a5c9432afcd9f21e7fde8789880f7bb0fe689751cba519&redirect_uri=https%3A%2F%2Fbookaload.truckertools.com%2Fkeeptruckin%2Fconfiguration%2F&response_type=code&scope=freight_visibility_vehicle_locations.manage";
      return false
    }
    else{
      if (!this.validation()) { return }
    }



    if (this.state.isUpdate) {

      this.setState({ loading: true }, () => {
        updateELDIntegration(payload).then(resp => {
          this.setState({ loading: false })
          this.displayMsg(resp, 'update')
          if (resp.status && this.state.paramsCount)
            this.setState({ showOnlyConfirmationBox: true})
          if (resp.status && this.state.hasCurrentProvider)
            this.checkExisting(this.state.mcNum)
          if (resp.status && !this.state.hasCurrentProvider && !this.state.paramsCount){
            this.setState({ showOnlyConfirmationBox: true})
            this.setState({ client: this.state.client!="KeepTrucking"?this.state.client:"KeepTruckingFinal"})
            this.setState({ client: this.state.client!="ELDRider"?this.state.client:"ELDRiderFinal"})
            this.setState({ client: this.state.client!="Verizon"?this.state.client:"VerizonFinal"})
          }
        }).catch(() => {
          this.setState({ loading: false })
          toast.error("Internal system error. Please try again later.")
        })
      });

    } else if (this.state.isCreate) {

      this.setState({ loading: true }, () => {
        createELDIntegration(payload).then(resp => {
          this.setState({ loading: false })
          this.displayMsg(resp, 'create')
          if (resp.status && this.state.paramsCount)
            this.setState({ showOnlyConfirmationBox: true})
          if (resp.status && this.state.hasCurrentProvider)
            this.checkExisting(this.state.mcNum)
          if (resp.status && !this.state.hasCurrentProvider && !this.state.paramsCount){
            this.setState({ showOnlyConfirmationBox: true})
            this.setState({ client: this.state.client!="KeepTrucking"?this.state.client:"KeepTruckingFinal"})
            this.setState({ client: this.state.client!="ELDRider"?this.state.client:"ELDRiderFinal"})
            this.setState({ client: this.state.client!="Verizon"?this.state.client:"VerizonFinal"})
          }
        }).catch(() => {
          this.setState({ loading: false })
          toast.error("Internal system error. Please try again later.")
        })
      });

    } else if (this.state.isAdd) {

      this.setState({ loading: true }, () => {
        addNewClient(payload).then(resp => {
          this.setState({ loading: false })
          this.displayMsg(resp, 'add')
          if (resp.status && this.state.hasCurrentProvider)
            this.checkExisting(this.state.mcNum)
        }).catch((e) => {
          this.setState({ loading: false })
          toast.error("Internal system error. Please try again later.")
        })
      })

    } else if (this.state.isEmail) {

      this.setState({ loading: true }, () => {
        sendEldEmail(payload).then(resp => {
          this.setState({ loading: false })
          this.displayMsg(resp, 'email')
          if (resp.status && this.state.paramsCount)
            this.setState({ showOnlyConfirmationBox: true })
          if (resp.status && this.state.hasCurrentProvider)
            this.checkExisting(this.state.mcNum)
          if (resp.status && !this.state.hasCurrentProvider && !this.state.paramsCount)
            this.setState({ showOnlyConfirmationBox: true})
        }).catch(() => {
          this.setState({ loading: false })
          toast.error("Internal system error. Please try again later.")
        })
      })

    } else if (this.state.isUpload) {

      this.setState({ loading: true }, () => {
        uploadFile(payload, this.state.file).then(resp => {
          this.setState({ loading: false })
          this.displayMsg(resp, 'email')
          if (resp.status && this.state.paramsCount)
            this.setState({ showOnlyConfirmationBox: true })
          if (resp.status && this.state.hasCurrentProvider)
            this.checkExisting(this.state.mcNum)
          if (resp.status && !this.state.hasCurrentProvider && !this.state.paramsCount)
            this.setState({ showOnlyConfirmationBox: true})
        }).catch(() => {
          this.setState({ loading: false })
          toast.error("Internal system error. Please try again later.")
        })
      })
    }
  }

  callKeepTruckingAPIForm(){

    document.getElementById("eld-signup-form").reset();
    var clientName  = 'KeepTruckingAPIForm'
    var actualClientName = 'KeepTrucking'
    this.state.payload.client = actualClientName //// setup client name as 'KeepTruckingAPIForm'
    this.state.payload.carrierPhone = this.state.wphone //// setup carrierPhone if it's available in state
    this.state.payload.contactName = this.state.fname+' '+this.state.lname

    this.checkSubmissionType(clientName)
      this.setState(state => ({
        items: clientFields[clientName],
        client: (this.state.paramsCount>0) ? clientName : actualClientName,
        linkArray: clientFields[clientName],
        hasCurrentProvider: false,
        file: null,
        payload: this.state.payload,
        lat: null,
        lng: null,
        showKeepTruckingAPIForm: true,
        showOnlyConfirmationBox: false
      }))
      // store provider selection
      createELDAction(this.state.payload); 
      sendSiteEldDataToSupport(this.state.payload); 
  }

  callELDRiderAPIForm(){

    document.getElementById("eld-signup-form").reset();
    var clientName  = 'ELDRiderAPIForm'
    var actualClientName = 'ELDRider'
    this.state.payload.client = actualClientName //// setup client name as 'ELDRiderAPIForm'
    this.state.payload.carrierPhone = this.state.wphone //// setup carrierPhone if it's available in state
    this.state.payload.contactName = this.state.fname+' '+this.state.lname

    this.checkSubmissionType(clientName)
      this.setState(state => ({
        items: clientFields[clientName],
        client: (this.state.paramsCount>0) ? clientName : actualClientName,
        linkArray: clientFields[clientName],
        hasCurrentProvider: false,
        file: null,
        payload: this.state.payload,
        lat: null,
        lng: null,
        showELDRiderAPIForm: true,
        showOnlyConfirmationBox: false
      }))
      // store provider selection
      createELDAction(this.state.payload); 
      sendSiteEldDataToSupport(this.state.payload); 
  }

  callVerizonAPIForm(){

    document.getElementById("eld-signup-form").reset();
    var clientName  = 'VerizonAPIForm'
    var actualClientName = 'Verizon'
    this.state.payload.client = actualClientName //// setup client name as 'VerizonAPIForm'
    this.state.payload.carrierPhone = this.state.wphone //// setup carrierPhone if it's available in state
    this.state.payload.contactName = this.state.fname+' '+this.state.lname

    this.checkSubmissionType(clientName)
      this.setState(state => ({
        items: clientFields[clientName],
        client: (this.state.paramsCount>0) ? clientName : actualClientName,
        linkArray: clientFields[clientName],
        hasCurrentProvider: false,
        file: null,
        payload: this.state.payload,
        lat: null,
        lng: null,
        showVerizonAPIForm: true,
        showOnlyConfirmationBox: false
      }))
      // store provider selection
      createELDAction(this.state.payload); 
      sendSiteEldDataToSupport(this.state.payload); 
  }


  render() {

    let displayFields = null
    displayFields =
      <div>
        {this.state.items.instruction && !this.state.hasCurrentProvider &&
          <div style={{marginTop: '1.0em'}}>{this.state.client!='omnitrac' && this.state.client!='TruckX' && this.state.client!='KeepTrucking' && this.state.client!='Verizon' && <span>{this.state.items.instruction}</span>}
            {/*<label style={{marginTop: '1.0em',}} htmlFor="instructions-list">Instructions</label>*/}
            {(this.state.client=='omnitrac' || this.state.client=='TruckX' || this.state.client=='KeepTrucking' || this.state.client=='Verizon') && <div name="instructions-list" id="instructions-list" className="instructList">
              {this.state.items.instruction.map((item, index) => {
                if (item.includes('!')) {
                  let instructionArray = item.split('!')
                  let link = <a href="#" onClick={this.renderDoc}>here</a>
                  if (instructionArray[1].includes('eldTargetHref')) {
                    link = <a target="_blank" href={clientFields.links[this.state.client]['eldTargetHref'].path}>{clientFields.links[this.state.client]['eldTargetHref'].path}</a>
                  } else if (instructionArray[1].includes('instruction')) {

                    link = <a href="#" name="instructionsLink" onClick={this.renderDoc}>video</a>

                  } else if (instructionArray[1].includes('DSA')) {

                    link = <a href="#" name="dataShareAgreementDoc" onClick={this.renderDoc}>here</a>
                  } else if (instructionArray[1].includes('email')) {
                    link = <a href="#" name="emailPrompt" onClick={this.renderEmailPrompt}>here</a>
                  }
                  return (<span key={index}>{instructionArray[0]}{link}{instructionArray[2]}<br/><br/></span>)
                } else {
                  return (<span key={index}>{item}<br/><br/></span>)
                }
              }
              )}
            </div>} 
          </div>
        }

        {this.state.hasCurrentProvider && this.state.client !== 'addNewProvider' && <div>
          <center style={{ padding: '1.0em', fontWeight: 'bold', textDecoration: 'underline' }}>Our records show you have already set up ELD tracking with {this.toReadable(clientFields[this.state.client].fullName)}</center>
            {/*!clientFields.testRestricted.includes(this.state.client) && <li>If you'd like to test an ELD device please enter a vehicle# and click 'Test'</li>*/}
            If you'd like to update your ELD information to a different provider you can select one from the drop down menu.
        </div>}

        {this.state.hasCurrentProvider && this.state.client === 'addNewProvider' && <div>
          <center style={{ padding: '1.0em', fontWeight: 'bold', textDecoration: 'underline' }}>Our records show you have already set up ELD tracking with us but it is not supported for this page.</center>
          <ul className="instructList">
            <li>Please enter in your ELD Providers details and click 'Submit'</li>
            <li>Our support team will adress this issue promptly.</li>
          </ul>
        </div>}

        <div>
          {this.state.client === 'peopleNet' && !this.state.hasCurrentProvider &&
            <div>
              <br/>If you have any questions, please contact <a href='mailto:eldsetup@truckertools.com'>eldsetup@truckertools.com</a>
            </div>
          }
          {/*(this.state.client === 'KeepTrucking' && !this.state.hasCurrentProvider && !this.state.showKeepTruckingAPIForm && !this.state.paramsCount) &&
            <Button style={{marginTop: '10px'}} className="submitBtn" type="submit" onClick={this.callKeepTruckingAPIForm} disabled={this.state.loading} bsStyle='light' >Already have API key</Button>
        */}
          {/*(this.state.client === 'ELDRider' && !this.state.hasCurrentProvider && !this.state.showELDRiderAPIForm && !this.state.paramsCount) &&
              <Button style={{marginTop: '10px'}} className="submitBtn" type="submit" onClick={this.callELDRiderAPIForm} disabled={this.state.loading} bsStyle='light' >Already have API key</Button>
      */}
          {/*(this.state.client === 'Verizon' && !this.state.hasCurrentProvider && !this.state.showVerizonAPIForm && !this.state.paramsCount) &&
              <Button style={{marginTop: '10px'}} className="submitBtn" type="submit" onClick={this.callVerizonAPIForm} disabled={this.state.loading} bsStyle='light' >Already have credentials</Button>
        */}
        </div>

        <div className="formELD"><FormFields
          items={this.state.items}
          payload={this.state.payload}
          emailClients={clientFields.emailClients}
          onChange={this.handleFormFieldChange}
          handleTestConnection={this.handleTestConnection}
          disableTest={this.state.loading}
          hasCurrentProvider={this.state.hasCurrentProvider}
          paramsCount={this.state.paramsCount}
          links={clientFields.links}
        />
        </div>
      </div>

    return (

      <div className="wrapper">
        <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'></link>

        {this.state.loading && <LoadingOverlay />}

        <Modal
          show={this.state.showDoc}
          onHide={this.hideDoc}
          bsSize="lg"
          backdrop={false}
          centered>

          <Modal.Header closeButton />


          <Modal.Body style={{ padding: '0px', height: '85vh' }}>
            <iframe src={this.state.docSrc}
              width="100%"
              height="100%"
              id="link-modal"
              allowFullScreen></iframe>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showEmailModal}
          onHide={this.hideDoc}
          backdrop={false}
          size="sm"
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
              Would you like Trucker Tools to send an email on your behalf to Teletrac Navman?
            </div>
            <br />
            <div>
              <label htmlFor='teletrac-modal-email'>Email:</label>
              <span> {this.state.userProfile.email}</span>
            </div>
            <div>
              <label htmlFor='teletrac-modal-company'>Company:</label>
              <span> {this.state.userProfile.companyName}</span>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button style={{ color: 'white', backgroundColor: 'lightgrey' }} onClick={this.hideDoc}>
              Cancel
          </Button>
            <Button bsStyle='success' onClick={this.sendTeletracEmail}>
              Send
          </Button>
          </Modal.Footer>
        </Modal>
        {this.state.paramsCount>0 && <header className="navbarMenu">
          <span className="headingLogo" style={{ marginTop: this.state.paramsCount == 0? '40px': '0px'}}><img src={BASEURL + "/wp-content/uploads/2016/11/TT-logo-250x44-1.png"} alt="TruckerTools" /></span>
          <span className="headingTitle" style={{ marginTop: this.state.paramsCount == 0? '40px': '0px'}}>ELD Integration</span>
        </header>}
        <div className="input-group-wrapper center">
        <form id="eld-signup-form" name="eld-signup-form" onSubmit={this.handleSubmit}>
            {!this.state.paramsCount && <div className="headingTitle_inner" style={{ marginTop: this.state.paramsCount == 0? '40px': '0px'}}>ELD Integration</div>}
            {this.state.showOnlyConfirmationBox && <div className="input-group-all">
              <div className="successBox">
                {clientFields[this.state.client].confiramtionText}
                {/*this.state.client == 'keller' && <span>{this.state.eldProviderFullname} will reach out to you, via the email you've provided, to sign a DSA(Data Sharing Agreement). {this.state.eldProviderFullname} would email ELDSet up after receiving DSA from you.</span>*/}
              </div>
            </div>}
            {!this.state.showOnlyConfirmationBox && <div className="input-group-all">
              <div className="input-group">
                <span style={{ float: 'right', paddingRight: '17px', color: '#e04562' }}><strong style={{fontWeight: '700', fontSize: '17px'}}>*</strong> indicates a required field</span>
                <label htmlFor="client">Provider: {!this.state.paramsCount && <span style={{ color: '#e04562', fontWeight: '700', fontSize: '17px', verticalAlign: 'top', display: 'inline-block' }}>*</span>}</label><span style={{display: 'inline-block' }}>{this.state.paramsCount? this.eldTargetHref() : '' }</span>
                {!this.state.paramsCount && <div><select name="client" className="select-client" placeholder="client" value={this.state.client} onChange={this.handleClientChange} required>
                <option value="" disabled defaultValue hidden>Please choose a provider</option>
                  <option value="2BroELD">2 Bro ELD</option>
                  <option value="3MD">3MD Solutions</option>
                  <option value="AirIQ">AirIQ GPS</option>
                  <option value="ALS">ALS</option>
                  <option value="Akal">Akal</option>
                  <option value="Anytrek">Anytrek</option>
                  <option value="ArionTech">ArionTech</option>
                  <option value="AwareGPS">Aware GPS</option>
                  <option value="Azuga">Azuga</option>
                  <option value="bigRoad">Big Road</option>
                  <option value="BIT">Blue ink Tech</option>
                  <option value="ClutchELD">ClutchELD</option>
                  <option value="Coretex">Coretex</option>
                  <option value="CyntrX">CyntrX</option>
                  <option value="ELDMandate">ELD Mandate</option>
                  <option value="ELDOne">ELD One</option>
                  <option value="ELDRider">ELD Rider</option>
                  <option value="ELDSolutions">ELD Solutions</option>
                  <option value="ERoad">ERoad</option>
                  <option value="EZLogz">EZLogz</option>
                  <option value="FlexFleet">Flex Fleet</option>
                  <option value="FTSGPS">FTSGPS</option>
                  <option value="geotab">Geotab</option>
                  <option value="Geowiz">Geowiz</option>
                  <option value="GPSTrackit">GPS Trackit</option>
                  <option value="Gorilla">Gorilla Safety</option>
                  <option value="GPSInsight">GPS Insight</option>
                  <option value="GPSTab">GPSTab</option>
                  <option value="Hutch">Hutch System</option>
                  <option value="InTouchGPS">InTouch GPS</option>
                  <option value="Isaac">ISAAC Instruments</option>
                  <option value="keller">JJ Keller</option>
                  <option value="KeepTrucking">Keep Truckin</option>
                  <option value="LinxUp">LinxUp</option>
                  <option value="m2m">M2M</option>
                  <option value="MasterELD">Master ELD</option>
                  <option value="Nextraq">Nextraq</option>
                  <option value="omnitrac">Omnitracs</option>
                  <option value="Pedigree">Pedigree Technologies</option>
                  <option value="peopleNet">PeopleNet</option>
                  <option value="PowerFleet">PowerFleet</option>
                  <option value="RandMcNally">Rand McNally</option>
                  <option value="RealELD">Real ELD</option>
                  <option value="Rigbot">Rigbot</option>
                  <option value="RightTrucking">Right Trucking</option>
                  <option value="Rhino">Rhino Fleet Tracking</option>
                  <option value="Roadstar">Roadstar</option>
                  <option value="Samsara">Samsara</option>
                  <option value="Spireon">Spireon</option>
                  <option value="TeletracNavman">Teletrac Navman</option>
                  <option value="TFMELD">TFM ELD</option>
                  <option value="ThinAir">ThinAir Telematics</option>
                  <option value="TitanGPS">Titan GPS</option>
                  <option value="TopTracking">Top Tracking</option>
                  <option value="TrackEnsure">TrackEnsure</option>
                  <option value="TRACKSMART">TrackSmart</option>
                  <option value="TrackYourTruck">Track Your Truck</option>
                  <option value="Transflo">Transflo</option>
                  <option value="TruckX">TruckX</option>
                  <option value="TXT">TXT</option>
                  <option value="Verizon">Verizon</option>
                  <option value="Vistracks">Vistracks</option>
                  <option value="Zonar">Zonar</option>
                  <option value="addNewProvider">Other</option>
                </select>
                </div>}
                {displayFields}
              </div>
              <div className="input-group">
                <div className="input-buttons">
                  { !this.state.hasCurrentProvider && this.state.client !== 'omnitrac' && this.state.client !== 'TruckX' && this.state.client !== 'Verizon' &&
                    <Button className="submitBtn" type="submit" onClick={this.handleSubmit} disabled={this.state.loading} bsStyle='success' >Submit</Button>}
                </div>
              </div>
              {!this.state.showOnlyConfirmationBox && <div className="input-group"><div style={{ paddingTop: '1.0em' }}>
              <span style={{ color: '#e04562' }}>Need Help? </span>
              <span>please either call us at
              <a href="tel:703-955-3560" style={{ color: '#b40a12' }}> 703-955-3560 </a>
                or email at <a href="email:eldsetup@truckertools.com" style={{ color: '#b40a12' }}> eldsetup@truckertools.com </a>
              </span>
              </div>
              <div style={{ paddingTop: '1.0em' }}> <span style={{ color: '#e04562' }}>Note:</span> <span style={{ fontWeight: 'bold' }}>Your ELD account details are FULLY CONFIDENTIAL and WILL NOT be shared with anyone. We will ONLY pull the location of the device while you're on a load that's being tracked through the ELD device. </span></div></div>}
            </div>}
          
          {/*(this.state.lat!=null && this.state.lng!=null) &&
            <SimpleMap lat={this.state.lat} lng={this.state.lng} center={{ lat: this.state.lat, lng: this.state.lng }}></SimpleMap>*/}
        </form>
        </div>
        {this.state.paramsCount>0 && <div className="new_login_page" style={{marginTop: '-60px'}}>
				<div className="body_wrap">

					<div className="">

						<div className="page_content_wrap">

							{/* <footer className="site_footer_wrap scheme_dark">
								<div className="footer_wrap widget_area">
									<div className="footer_wrap_inner widget_area_inner">
										<div className="content_wrap">
											<div className="columns_wrap">
												<aside className="column-1_4 widget Social_Widget">
													<a href={BASEURL} id="sc_layouts_logo_96810308" className="sc_layouts_logo sc_layouts_logo_default"><img className="logo_image" src={BASEURL + "/wp-content/uploads/2019/05/TT-logo-white-250w.png"} width="250" height="44" style={{ maxWidth: '100%', height: 'auto' }} /></a>
												</aside>
												<aside className="column-1_4 widget widget_nav_menu">
													<h5 className="widget_title">Browse</h5>
													<div className="menu-footer-container">
														<ul id="menu-footer" className="menu">
															<li id="menu-item-2948" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2948"><a href={BASEURL + "/about-us/"}>About Us</a></li>
															<li id="menu-item-1692" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1692"><a href="https://marketing.truckertools.com/Request-a-Demo">Request a Demo</a></li>
															<li id="menu-item-1690" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1690"><a href={BASEURL + "/resources/"}>Resources</a></li>
															<li id="menu-item-1691" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1691"><a href={BASEURL + "/blog/"}>Blog</a></li>
															<li id="menu-item-2949" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2949"><a href={BASEURL + "/blog/news/"}>News</a></li>
															<li id="menu-item-1708" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1708"><a href={BASEURL + "/carriers"}>Download Mobile App</a></li>
														</ul>
													</div>
												</aside>
												<aside id="acx-social-icons-widget-2" className="column-1_4 widget acx-smw-social-icon-desc"><h5 className="widget_title">Follow Us</h5>
													<div id="acurax_si_widget_simple" className="acx_smw_float_fix widget-acx-social-icons-widget-2-widget" style={{ textAlign: 'left' }}><a href="https://www.facebook.com/TruckerTools/" target="_blank" title="Visit Us On Facebook"><img src={BASEURL + "/wp-content/plugins/acurax-social-media-widget/images/themes/30/facebook.png"} alt="Visit Us On Facebook" /></a><a href="https://www.youtube.com/channel/UC7eM8cNSN6Y0qS8Dx6kj5qQ" target="_blank" title="Visit Us On Youtube"><img src={BASEURL + "/wp-content/plugins/acurax-social-media-widget/images/themes/30/youtube.png"} alt="Visit Us On Youtube" /></a><a href="https://www.linkedin.com/company/trucker-tools/?originalSubdomain=in" target="_blank" title="Visit Us On Linkedin"><img src={BASEURL + "/wp-content/plugins/acurax-social-media-widget/images/themes/30/linkedin.png"} alt="Visit Us On Linkedin" /></a></div>
												</aside>
												<aside className="column-1_4 widget Social_Widget">
													<h5 className="widget_title">Contact Us</h5>
													<div className="textwidget"><p>P. O. Box 3309 Reston, VA 20195<br />
								703 955 3560</p>
														<p>&nbsp;</p>
														<h5 style={{ textAlign: 'left', color: '#ffffff' }}><a style={{ color: '#ffffff' }} href={"/termsConditions/"}>Terms and Conditions</a></h5>
													</div>
												</aside>
											</div>

										</div>

									</div>

								</div>


								<div className="copyright_wrap  scheme_">
									<div className="copyright_wrap_inner">
										<div className="content_wrap">
											<div className="copyright_text">Trucker Tools &copy; 2021. All rights reserved.</div>
										</div>
									</div>
								</div>

							</footer> */}


						</div>


					</div>


				</div>
			</div>}
      </div>
    );
  }
}