import getMuiTheme from 'material-ui/styles/getMuiTheme';


const primary={
  lighter:'#b1cddb',
  light: '#819ca9',
  main: '#546e7a',
  dark: '#29434e',
  contrastText: '#eceff1',
}
const secondary={
  light: '#d1524d',
  main: '#9A1F25',
  dark: '#650000',
  contrastText: '#eceff1',
}
const backgrounds={
  lighter:'#ededed',
  light: '#e1e1e1',
  main: '#c7c7c7',
  dark: '#969696',
  contrastText: '#000000',
}


const theme = getMuiTheme({
  palette: {
    primary: primary,
    secondary: secondary,
    backgrounds:backgrounds,
    markerBlue:	'#1AA0E0',
    markerRed: 	'#9C1F25',
    markerPurple: 	'#7E3690',
    markerGreen: 	'#84BD53',
    markerGray: 	'#8C8D90',
    markerYellow:	'#FFD700',
    markerBlueDark:'#202164',
    ttRed:'#ad2228',
  },
  tabs: {
    backgroundColor:primary.light,
    selectedTextColor:primary.contrastText,
    textColor:primary.contrastText,
  },
  inkBar:{
    backgroundColor:secondary.main,
  },
  appBar:{
    color:primary.dark
  },
  raisedButton:{
    backgroundColor:secondary.main,
    color:secondary.contrastText,
    disabledColor:"rgb(229, 229, 229)",
    disabledTextColor:"rgba(0, 0, 0, 0.3)",
    primaryColor:primary.main,
    primaryTextColor:primary.contrastText,
    secondaryColor:secondary.main,
    secondaryTextColor:secondary.contrastText,
    textColor:"rgba(0, 0, 0, 0.87)",
  },
  floatingActionButton:{
    backgroundColor:secondary.main,
    color:secondary.contrastText,
    disabledColor:"rgb(229, 229, 229)",
    disabledTextColor:"rgba(0, 0, 0, 0.3)",
    primaryColor:primary.main,
    primaryTextColor:primary.contrastText,
    secondaryColor:secondary.main,
    secondaryTextColor:secondary.contrastText,
    textColor:"rgba(0, 0, 0, 0.87)",
  },
  toggle:{
    thumbOnColor:secondary.main,
    trackOnColor:secondary.light
  }
});
//console.log(theme);
export default theme
