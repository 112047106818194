import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../HOC/Mobile.js'

import { Row, Col, } from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import { useTour } from '@reactour/tour'

const TutorialCloseModal = (props) => {
  
    const { muiTheme, setTutorialCloseTrigger, tutorialCloseTrigger,  isMobile } = props

    const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour()

    const getModalStyle = () => {
        return {
            top: isMobile ? '50%' : `40%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            height: isMobile ? '50%' : 'unset',
            width: isMobile ? '90%' : '800',
            overflow: isMobile ? 'auto' : 'unset'
        };
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 400,
            // display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),

        },
    }));

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);

    const handleClose = (isExit) => {
        if(isExit){
            setCurrentStep(0)
            setIsOpen(false)
            setTutorialCloseTrigger(false);
        }else{
            setTutorialCloseTrigger(false);
        }
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Exiting Tour</h2>
            <p>You can re-open the tour by clicking the "Find & Book Tour" link at the bottom of the navigation menu.</p>
            <Row style={{ width: "80%", marginTop: 40, marginLeft: "10%", marginRight: "10%", display: "flex", justifyContent: "center" }}>
                <Col style={{ marginRight: 10 }}>
                    <Button
                        // variant={"contained"}
                        size={"small"}
                        style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "transparent" }}
                        onClick={() => handleClose(false)}
                    >
                        Return
                    </Button>
                </Col>
                <Col style={{ marginLeft: 10 }}>
                    <Button
                        variant={"contained"}
                        size={"small"}
                        style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor }}
                        onClick={() => handleClose(true)}
                    >
                        Exit Tour
                    </Button>
                </Col>
            </Row>
        </div>
    );

    return (
        <Modal
            open={true}
            onClose={()=>handleClose(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
        {body}
        </Modal>
    );
}

export default compose(
    muiThemeable(),
    isMobile()
)(TutorialCloseModal)