import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { handleApiErrors } from '../../lib/api-errors'
import {toast} from 'react-toastify'
import * as Constants from './constants'
import { saveOfferedLoad } from '../../api/offeredLoad'
import { getTruckCodes } from '../../api/truckTypes'

function* truckCodesFlow (action) {
  try {
    yield put(showLoading())    
    // here until the API async function, is complete!
    const response = yield call(getTruckCodes);    
    const { truckCodes } = response;        
    yield put({ type: Constants.TRUCK_CODES_SUCCESS, truckCodes: truckCodes || [] })
  } catch (error) {    
    //TODO
  }
  finally{
    yield put(hideLoading())
  }
}

function* saveOfferedLoadFlow (action) {
  try {
    yield put(showLoading())
    const {isAdd, request} = action;
    // here until the API async function, is complete!
    const response = yield call(saveOfferedLoad, request);    
    const { status, message, details } = response;
    const {message: cancelMessage, loadNumber} = details[0] 
    if (status) {      
      
      if (cancelMessage && cancelMessage.includes("Canceled") ) {
        //Successfully Canceled
        yield put({ type: Constants.CANCEL_OFFERED_LOAD_SUCCESS, message:`Load canceled`, loadNumber});
      } 
      else {
        yield put({ type: Constants.SAVE_OFFERED_LOAD_SUCCESS, load: details[0], message:message});
      }
    }
    else {
      yield put({ type: Constants.SAVE_OFFERED_LOAD_ERROR, message: message });
    }
    
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    //TODO
  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the self service action types
// WITH the action we dispatched
function* offeredLoadsWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(Constants.TRUCK_CODES_REQUESTING, truckCodesFlow);  
  yield takeLatest(Constants.SAVE_OFFERED_LOAD_REQUESTING, saveOfferedLoadFlow);
 
}

export default offeredLoadsWatcher