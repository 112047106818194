const initialState = {
  requesting: false,
  successful: false,
  payload:{loads:[],availability:{}},
  messages: [],
  errors: [],
  emailSend:{
    requesting:false,
    successful:false,
    payload:false,
  }
}

const reducer = (state = initialState, action)=>{
  switch (action.type) {
    case 'UPDATE_AVAILABILITY_LOADS_REQ':
      var retValue = {
        ...state,
        requesting:true,
        successful:false,
        payload:{loads:[],availability:{}},
      }
      return retValue
    case 'UPDATE_AVAILABILITY_LOADS_REQ_V2':
      var retValue = {
        ...state,
        requesting:true,
        successful:false,
        payload:{loads:[],availability:{}},
      }
      return retValue

    case 'UPDATE_AVAILABILITY_LOADS_SUC':
      var retValue = {
        ...state,
        requesting:false,
        successful:true,
        payload:action.payload,
      }
      return retValue

    case 'UPDATE_AVAILABILITY_LOADS_ERR':
      var retValue = {
        ...state,
        requesting:false,
        successful:false,
      }
      return retValue
    case 'SEND_INTEREST_EMAIL_REQ':
      var retValue = {
        ...state,
        emailSend:{
          ...state.emailSend,
          requesting:true,
          successful:false,
        }
      }
      return retValue
    case 'SEND_INTEREST_EMAIL_SUC':
      var retValue = {
        ...state,
        emailSend:{
          ...state.emailSend,
          requesting:false,
          successful:true,
        }
      }
      return retValue
    case 'SEND_INTEREST_EMAIL_ERR':
      var retValue = {
        ...state,
        emailSend:{
          ...state.emailSend,
          requesting:false,
          successful:false,
        }
      }
    case "REST_EMAIL_SEND_REDUCER":
      var retValue = {
        ...state,
        emailSend:{
          ...initialState.emailSend
        }
      }
      return retValue
    default:
      return state
  }
}

export default reducer
