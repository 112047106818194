import React, { useState, useEffect } from 'react';
import {
    Link
} from 'react-router-dom';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setActiveNavigationTab } from "../../actions"

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import SimpleLineIcon from 'react-simple-line-icons';

const DrawerMenuItem = (props) => {

    const { muiTheme, name, identifier, iconName, route, forceOpen, isDrawerOpen, _setIsDrawerOpen, activeTab, _setActiveTab, customIcon = null, useFontAwesomeIcon = false, useFeatherIcon = false, featherIcon = null } = props

    const useStyles = makeStyles({
        li: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" ? 50 : 70,
            paddingLeft: 10,
            paddingRight: 10,
            "&:hover": {
                backgroundColor: muiTheme.menuHighlightColor,
            }
        },
        menu_icon: {
            width: 40,
            flexDirection: "row",
            textAlign: "center",
            verticalAlign: "center"
        },
        menu_link_style: {
            display: "flex",
            alignItems: "center",
            height: 70,
            width: 200,
            fontSize: 16,
            color: muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white",
            "&:hover": {
                color: muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"
            },
            "&:visited": {
                color: muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"
            },
            "&:active": {
                color: muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"
            }
        }
    });

    const classes = useStyles();

    const [localActiveState, setLocalActiveState] = useState(activeTab == name)

    const active_tab_style = {
        backgroundColor: muiTheme.menuHighlightColor,
    }

    useEffect(() => {
        if (activeTab == identifier) {
            setLocalActiveState(true)
        } else {
            setLocalActiveState(false)
        }
    }, [activeTab])

    const onPress = () => {
        _setActiveTab(identifier)
        setLocalActiveState(true)
    }

    return (
        <li
            key={identifier}
            className={classes.li}
            style={localActiveState ? active_tab_style : {}}
            onMouseOver={() => { _setIsDrawerOpen(true) }}
        >
            <a
                className={classes.menu_icon}
                style={{ marginRight: (forceOpen || (!forceOpen && isDrawerOpen)) ? 10 : 0 }}
                href={route}
                onClick={() => { onPress() }}
            >
                {customIcon ?
                    customIcon :
                    <SimpleLineIcon name={iconName} color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} />
                }
            </a>
            {(forceOpen || (!forceOpen && isDrawerOpen)) && (
                <Link
                    to={route}
                    className={classes.menu_link_style}
                    onClick={() => { onPress() }}
                >
                    {name}
                </Link>
            )}
        </li>
    )
}

const mapStateToProps = state => ({
})

// function handleHidePaymentToast() {
//     document.getElementById('Bar').style.display = "none";
// }

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        changeTab: setActiveNavigationTab
    }, dispatch)
}


export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(DrawerMenuItem)

