import {BACKEND_URL} from './constants.js'
import moment from 'moment'
export function assignDriverToAvailability(driverId,availability){
  var requestUrl=BACKEND_URL+'/driverAvailabilityV2/'+availability.id
  //console.log(newDetails)
  var payload = {
    driverId:driverId,
    address: availability.address,
    city: availability.city,
    state: availability.state,
    zipCode: availability.zipCode,
    lat:availability.lat,
    lon:availability.lon,
    availableTime: moment(availability.availableTime,'YYYY-MM-DD HH:mm:ss').isValid()?moment(availability.availableTime,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss Z'):moment().add(5,'minutes').format('YYYY-MM-DD HH:mm:ss Z'),
  }
  console.log('Updating availability: '+availability.id+' '+payload);
  return fetch(requestUrl,{
                            method: 'PUT',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
export function putDriverAvailabilityDetails(newDetails){
  console.log(newDetails)
  var requestUrl=BACKEND_URL+'/driverAvailabilityV2/'+newDetails.driver_availabilities[0].id
  //console.log(newDetails)
  var availableTime = moment().format('YYYY-MM-DD HH:mm:ss Z');
  if(moment(newDetails.availableTime,'YYYY-MM-DD HH:mm:ss Z').isValid()){
    availableTime = moment(newDetails.availableTime,'YYYY-MM-DD HH:mm:ss Z').format('YYYY-MM-DD HH:mm:ss Z')
  }else if(moment(newDetails.availableTime,'MMM Do, h:mm a').isValid()){
    availableTime = moment(newDetails.availableTime,'MMM Do, h:mm a').format('YYYY-MM-DD HH:mm:ss Z')
  }else{
    availableTime = moment().format('YYYY-MM-DD HH:mm:ss Z');
  }
  var payload = {
    address: newDetails.driver_availabilities[0].address,
    city: newDetails.driver_availabilities[0].city,
    state: newDetails.driver_availabilities[0].state,
    zipCode: newDetails.driver_availabilities[0].zipCode,
    availableTime: availableTime,
    driverId:newDetails.id
  }
  //console.log('in the api 2')
  return fetch(requestUrl,{
                            method: 'PUT',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}


export function postDriverAvailabilityDetails(newDetails){
  var requestUrl=BACKEND_URL+'/driverAvailabilityV2/create'
  //console.log(newDetails)
  var payload = {
    address: newDetails.driver_availabilities[0].address,
    city: newDetails.driver_availabilities[0].city,
    state: newDetails.driver_availabilities[0].state,
    zipCode: newDetails.driver_availabilities[0].zipCode,
    availableTime: moment(newDetails.availableTime).isValid()?moment(newDetails.availableTime).format('YYYY-MM-DD HH:mm:ss Z'):moment().format('YYYY-MM-DD HH:mm:ss Z'),
    driverId:newDetails.id
  }
  //console.log('in the api 2')
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function createSearchOrCapacity(payload){
  var requestUrl=BACKEND_URL+'/driverAvailabilityV2/create'
  //console.log(newDetails)
  payload.availableTime=payload.availableAtStart
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function deleteDriverAvailability(availabilityId){
  var requestUrl=BACKEND_URL+'/driverAvailabilityV2/'+availabilityId
  return fetch(requestUrl,{
                            method: 'DELETE',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            }
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}


export function addCarrierCapacity(payload){
  var requestUrl=BACKEND_URL+'/api/broker/addCapacity'

  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}


export function getCarrierCapacity(){

  //var requestUrl='https://api.myjson.com/bins/s85md'
  //var requestUrl='https://api.myjson.com/bins/19wy05'
  //var requestUrl='http://api.jsonbin.io/b/5a98697fa121bc097fe76ad1'
  var requestUrl=BACKEND_URL+'/api/broker/getCapacity'
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            }
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function importCarriersAvailabilities(formdata){
    //console.log("importCarriersAvailabilities api");
    //console.log(formdata);
  var requestUrl=BACKEND_URL+'/api/broker/addCapacityFromCSV' ;

  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
							body:formdata,
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function editAvailability(updateValues){
  console.log(updateValues)
  var requestUrl=BACKEND_URL+'/driverAvailabilityV2/'+updateValues.availabilityId
  //console.log('in the api 2')
  return fetch(requestUrl,{
                            method: 'PUT',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(updateValues)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function publishAvailability(id,visibility){
  var requestUrl=BACKEND_URL+'/publishAvailability/'+id
  var payload={visibility:visibility?1:0}
  return fetch(requestUrl,{
                            method: 'PUT',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function importCarriersAvailabilitiesCarrierPortal(formdata){
    //console.log("importCarriersAvailabilitiesCarrierPortal api");
    //console.log(formdata);
  var requestUrl=BACKEND_URL+'/api/importCapacityCarrierPortal' ;

  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
							body:formdata,
    })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function postCarrierEmailResponse(forwardingParams){
  var requestUrl=BACKEND_URL+'/driverAvailability/carrierResponse'+forwardingParams
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            }
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function availabilityUnavailableAPI(availabilityId,reason, input){
    var requestUrl = BACKEND_URL+'/markAvailabilityAsUnavailable'
    var payload = {
        availabilityId : availabilityId,
        unavailableReason : reason,
        input:input
    }
    return fetch(requestUrl,{
        method: 'Post',
        headers: {
            'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(payload)
    })
        .then(response => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
            return response.json();
        });
}

export function emailAvailabilities(availabilityIds,content){
    var requestUrl = BACKEND_URL+'/sendEmailForAvailabilities'
    var payload = {
        message : content,
        availabilityIds:availabilityIds
    }
    return fetch(requestUrl,{
        method: 'Post',
        headers: {
            'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(payload)
    })
        .then(response => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
            return response.json();
        });
}

export function searchAvailabilities(pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes){
    var requestUrl = BACKEND_URL+'/broker/availabilities'
    var payload = {
        pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes
    }
    return fetch(requestUrl,{
        method: 'Post',
        headers: {
            'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(payload)
    })
        .then(response => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
            return response.json();
        });
}

export function searchMyCapacity(pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes){
    var requestUrl = BACKEND_URL+'/broker/myCapacities'
    var payload = {
        pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes
    }
    return fetch(requestUrl,{
        method: 'Post',
        headers: {
            'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(payload)
    })
        .then(response => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
            return response.json();
        });
}
export function searchEmailCapacity(pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes){
    var requestUrl = BACKEND_URL+'/broker/myEmailCapacities'
    var payload = {
        pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes
    }
    return fetch(requestUrl,{
        method: 'Post',
        headers: {
            'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(payload)
    })
        .then(response => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
            return response.json();
        });
}

export function searchActiveDibs(pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes){
    var requestUrl = BACKEND_URL+'/broker/activeDibs'
    var payload = {
        pickupCity,pickupState,pickupLat,pickupLon,deliveryState,radius,startDatetime,endDatetime,truckTypes
    }
    return fetch(requestUrl,{
        method: 'Post',
        headers: {
            'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(payload)
    })
        .then(response => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
            return response.json();
        });
}



export function getCarrierSourcingAvails(code=false,start=false,end=false){
  var requestUrl=BACKEND_URL+'/broker/availabilities/carrierSourcing'
  if(code){
    requestUrl+='?sourcingCode='+code
  }
  if(start && end){
    requestUrl+='&startDatetime='+moment(start).format('MM/DD/YYYY')+'&endDatetime='+moment(end).format('MM/DD/YYYY')
  }
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            }
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
