import * as Constants from './constants'

const initialState = {
  requesting: false,
  successful: false,
  integrations: [],
  shippers: [],
  loads:[],
  loadUpdates:[],
  id: 0,
  selectedIntegration: {}
}

const reducer = function selfServiceReducer (state = initialState, action) {
  //console.log('reducer',action.type)
  switch (action.type) {

    case Constants.INTEGRATIONS_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Looking up your integrations...', time: new Date() }],
        errors: [],
      }

    case Constants.INTEGRATIONS_SUCCESS:
      return {
        ...state,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        integrations: action.integrations,        
      }
      case Constants.ADD_INTEGRATION_REQUESTING:
        return {
          ...state,          
          requesting: true,
        }       
      case Constants.ADD_INTEGRATION_SUCCESS:
        return {
          ...state,          
          requesting: false,
          id: action.id,
          integrations: [...state.integrations,action.formValues]          
        }
      
        case Constants.EDIT_INTEGRATION_REQUESTING:
          return {
            ...state,          
            requesting: true,          
          }       
        case Constants.EDIT_INTEGRATION_SUCCESS:
          return {
            ...state,          
            requesting: false,    
            integrations: state.integrations.map((item, index) => {
              if (item.id === action.formValues.id) {
                return action.formValues;
              }
              return item;
            })         
          }
        case Constants.EDIT_INTEGRATION_INITIALISE:
          return {
            ...state,          
            selectedIntegration: action.selectedRow,          
          }
       

    default:
      return state
  }
}

export default reducer