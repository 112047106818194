import {BACKEND_URL} from './constants.js'


export function getTMSPartner(integrationId, mcBroker){
//  console.log('...In line 5',integrationId);
//  console.log('...In line 5',mcBroker);
  var requestUrl=BACKEND_URL+'/partnerAuto?integrationId='+integrationId+'&mcBroker='+mcBroker;
  //console.log('...in line 9',requestUrl);
  return fetch(requestUrl,{
                            method: 'GET'
                          })
          .then(response => {
              //console.log('...in line 13',response);
              //console.log('...in line 14',response.ok);

            if (!response.ok) {
            //  console.log('........in line 17', response.statusText);
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          //  console.log('...in line 18',response);
            return response.json();
          });
}

export function updateTMSPartner(data){
//  console.log("data in line 28 in API....", data);
  //var requestUrl=BACKEND_URL+'/partnerAuto';
  var requestUrl=BACKEND_URL+'/updateTMSPartner';
//  console.log('URL Backend is ......', requestUrl);
  //Sample Payload send to db
//  props.updateTMSPartnerDetails(props.newtmspartnerdetails,()=>props.getTMSPartnerDetails
//  (props.contact_email, props.contact_password, props.contact_first_name, props.contact_last_name,
//    props.newtmspartnerdetails.broker.mc,props.newtmspartnerdetails.broker.company_name,props.type,props.contact_phone);

//console.log('In line 37 in API ......', data.contact_email);

  var postBody        =     {
                                firstName:data.contact_first_name,
                                lastName:data.contact_last_name,
                                email:data.contact_email,
                                phone:parseInt(data.contact_phone),
                                password:data.contact_password,
                                mcNumber:data.newtmspartnerdetails.broker.mc,
                                companyName:data.newtmspartnerdetails.broker.company_name,
                                companyType:data.cType,
                            };
  //console.log('In line 50 in API ......', postBody);
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body:JSON.stringify(postBody),

                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
