import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withState, compose, lifecycle, withHandlers } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';

//COMPONENTS//
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//ACTIONS//
import { getPreferencesDetail, updatePreferences } from './actions.js'
//Fucntions
import queryString from 'query-parse';

const COMPONENT_NAME = ({
  //PROPS FROM PARENT//
  label = 'Test',
  //REDUX//
  getPreferencesDetail, updatePreferences, preferences,

  //STATE
  newpreferences, updateNewpreferences,
  receiveEmails, setReceiveEmails,
  invitationEmails, setInvitationEmails,
  allowDrivers, setAllowDrivers,
  allowDriversBookItNow, setAllowDriversBookItNow,
  allowDriversOffer, setAllowDriversOffer,

  //RECOMPOSE
  handlePreferencesChange,

  //OTHER
  muiTheme, location, ...props
}) => {
  if (location) {
    var parsed = queryString.toObject(location.search.substring(1, location.search.length));
    var unsubscribedEmail = parsed.unsubscribedEmail;
  }
  if (preferences && preferences.payload && ('userEmail' in preferences.payload) && !newpreferences) {
    updateNewpreferences(preferences.payload);
    setAllowDrivers(preferences.payload.allowDrivers)
    setAllowDriversBookItNow(preferences.payload.allowDriversBookItNow)
    setAllowDriversOffer(preferences.payload.allowDriversOffer)
    setInvitationEmails(preferences.payload.invitationEmails)
    setReceiveEmails(preferences.payload.receiveEmails)

    //console.log("preferences...".preferences.payload);
  }
  return (
    <div>
      {/* <PaperStack> */}
      <h3>Current Preferences</h3>
      <div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                checked={receiveEmails}
                onChange={(event) => {
                  updateNewpreferences({ ...newpreferences, receiveEmails: event.target.checked })
                  setReceiveEmails(event.target.checked)
                }}
              />
            }
            label={<p style={{ fontSize: 14 }}>{(newpreferences.userEmail) ? "Receive Emails of Matching Loads (" + newpreferences.userEmail + ")" : "Receive Emails of Matching Loads "}</p>}
          />
          {process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" &&
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                  checked={invitationEmails}
                  onChange={(event) => {
                    updateNewpreferences({ ...newpreferences, invitationEmails: event.target.checked })
                    setInvitationEmails(event.target.checked)
                  }}
                />
              }
              label={<p style={{ fontSize: 14 }}>{(newpreferences.userEmail) ? "Receive Invitation Emails from Brokers (" + newpreferences.userEmail + ")" : "Receive Invitation Emails from Brokers"}</p>}
            />
          }
          {/* <FormControlLabel
            control={
              <Checkbox
                style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                checked={newpreferences.isPrimary ? newpreferences.publishCapacity : true}
                disabled={newpreferences.isPrimary ? false : true}
                onChange={(event) => {
                  updateNewpreferences({ ...newpreferences, publishCapacity: event.target.checked })
                }}
              />
            }
            label={<p style={{ fontSize: 14 }}>Publish My Capacity</p>}
          /> */}


          {props.userRole && props.userRole === "admin" && (
            <div style={{ display: "contents" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                    checked={newpreferences.isPrimary ? allowDrivers : true}
                    disabled={newpreferences.isPrimary ? false : true}
                    onChange={(event) => {
                      updateNewpreferences({ ...newpreferences, allowDrivers: event.target.checked })
                      setAllowDrivers(event.target.checked)
                    }}
                  />
                }
                label={<p style={{ fontSize: 14 }}>Allow My Drivers to See Loads</p>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                    checked={newpreferences.isPrimary ? allowDriversOffer : true}
                    disabled={newpreferences.isPrimary ? false : true}
                    onChange={(event) => {
                      updateNewpreferences({ ...newpreferences, allowDriversOffer: event.target.checked })
                      setAllowDriversOffer(event.target.checked)
                    }}
                  />
                }
                label={<p style={{ fontSize: 14 }}>Allow My Drivers to Make Offers on Loads</p>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                    checked={newpreferences.isPrimary ? allowDriversBookItNow : true}
                    disabled={newpreferences.isPrimary ? false : true}
                    onChange={(event) => {
                      updateNewpreferences({ ...newpreferences, allowDriversBookItNow: event.target.checked })
                      setAllowDriversBookItNow(event.target.checked)
                    }}
                  />
                }
                label={<p style={{ fontSize: 14 }}>Allow My Drivers to Book Loads</p>}
              />
            </div>
          )}
        </FormGroup>
      </div>
      <div
        style={{ marginTop: 15 }}
      >
        <Button
          id='cancelButton'
          size="small"
          variant="contained"
          style={{
            backgroundColor: muiTheme.ttUnselectGrey,
            borderColor: muiTheme.ttUnselectGrey,
            color: "white",
            fontSize: 14,
            margin: 5
          }}
          onClick={(e) => { updateNewpreferences(preferences.payload) }}
        >
          CANCEL
        </Button>
        <Button
          id='cancelButton'
          size="small"
          variant="contained"
          style={{
            backgroundColor: muiTheme.actionColor,
            borderColor: muiTheme.actionColor,
            color: "white",
            fontSize: 14,
            margin: 5
          }}
          onClick={(e) => { handlePreferencesChange(newpreferences) }}
        >
          SAVE PREFERENCES
        </Button>
      </div>
      {/* </PaperStack> */}
    </div>

  )
}

COMPONENT_NAME.propTypes = {
  //label:PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  preferences: state.preferencesReducer
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    getPreferencesDetail: getPreferencesDetail,
    updatePreferences: updatePreferences,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  muiThemeable(),
  lifecycle({
    componentWillMount() {
      var parsed = queryString.toObject(this.props.location.search.substring(1, this.props.location.search.length));
      var unsubscribedEmail = parsed.unsubscribedEmail ? parsed.unsubscribedEmail : '';
      //console.log("...",unsubscribedEmail);
      this.props.getPreferencesDetail(unsubscribedEmail);
      //console.log('Updating table component');
    }
  }),
  withHandlers({
    handlePreferencesChange: props => newPreferences => {
      //console.log('in line 77...', newPreferences);
      var parsed = queryString.toObject(props.location.search.substring(1, props.location.search.length));
      var unsubscribedEmail = parsed.unsubscribedEmail ? parsed.unsubscribedEmail : '';
      props.updatePreferences(newPreferences, () => props.getPreferencesDetail(unsubscribedEmail));
    }
  }),
  withState('newpreferences', 'updateNewpreferences', false),
  withState('receiveEmails', 'setReceiveEmails', false),
  withState('invitationEmails', 'setInvitationEmails', false),
  withState('allowDrivers', 'setAllowDrivers', false),
  withState('allowDriversBookItNow', 'setAllowDriversBookItNow', false),
  withState('allowDriversOffer', 'setAllowDriversOffer', false)


)(COMPONENT_NAME)
