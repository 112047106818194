
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';
import { toast } from 'react-toastify'

import moment from 'moment'
import { checkValueExists } from "../../../../../../lib/check-value-exists"

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
// import useAuthenticationHook from '../../../../hooks/authentication-hook';
// import ReloadsLink from '../../../../load-list/desktop/reloadsLink'
import LoadCardExpandedContent from './LoadCardExpandedContent'

import { titleCaseFormatter } from "../../../../../../lib/title-case-formatter"


library.add(
    faBiohazard
);

const LoadCard = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        isExpanded,
        dropDownIcon,
        muiTheme,
        key,
        pushReloads,
        reloadsOpen,
        carrierCommunications
    } = props

    const [isHazmat, setIsHazmat] = useState(false)

    // const myAuthService = useAuthenticationHook()
    // const isAuthenticated = myAuthService.isAuthenticated()
    // const user = myAuthService.getUser()

    const [anchorEl, setAnchorEl] = useState(null);
    const openRateBreakdown = Boolean(anchorEl);
    const id = openRateBreakdown ? 'rate-info-popover' : undefined;

    const handleRateOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleRateClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    const handleTrackingHelperOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleTrackingHelperClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    const getIcon = (type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type == "origin") {
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    backgroundColor: muiTheme.ttUnselectGrey,
                    width: 12,
                    height: 12,
                }}
            />
        }

        return icon
    }

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                setIsHazmat(true)
        }
    }, [])

    const getDropDownIcon = () => {
        return <FA name={dropDownIcon} size='2x' />
    }

    const getTrackingHelperPopover = (id, openRateBreakdown, anchorEl) => {
        return (
            <Popover
                onClick={(e)=>e.stopPropagation()}
                id={id}
                open={openRateBreakdown}
                anchorEl={anchorEl}
                PaperProps={{onMouseLeave: handleTrackingHelperClose}}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                // style={{ pointerEvents: 'none' }}
                disableRestoreFocus
            >
                <div style={{ padding: 15, width: 220, maxHeight: 300 }}>
                    <p className={classes.valueText}> Contact <a href={"tel:1-800-000-0000"}style={{ color: muiTheme.actionColor }}>1-800-000-0000</a><br/>to update tracking details.</p> 
                </div>    
            </Popover>
        )
    }

    const formatPhoneNumberWithHyphens = (phone) => {
        var s2 = ("" + phone).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
    }

    const checkWhiteSpace = (textInput) => {
        let status = true;
        if(textInput){
            if(textInput.trim().length === 0)
                status = false
        };
        return status
    }

    return (

        <div>

            <Row style={{ display: 'flex' }}>

                <Col style={{ display: 'flex', width: '5%' }}>
                    <div style={{ textAlign: 'center', display: 'flex' }}>
                        <Button
                            key={key + dropDownIcon + index}
                            style={{ backgroundColor: 'transparent', justifyContent: 'flex-start' }}
                            disableRipple={true}
                        >
                            {getDropDownIcon()}
                        </Button>
                    </div>
                </Col>

                <Col style={{ display: 'flex', width: "14%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <Row>
                            <p className={classes.mainText} >
                                {reloadsOpen == true ?
                                    titleCaseFormatter(pickup.city) + ', ' + pickup.state
                                    :
                                    titleCaseFormatter(load.originStop.city) + ', ' + load.originStop.state
                                }
                            </p>
                        </Row>
                        {!load.originStop.earliestDate && (
                            <Row>
                                <p className={classes.secondaryText}>
                                    {moment(load.originStop.latestDate).format('ll')}
                                </p>
                                <p className={classes.secondaryText}>
                                    {moment(load.originStop.latestDate).format('HH:mm')}
                                </p>
                            </Row>
                        )}
                        {load.originStop.earliestDate && (
                            <Row>
                                <p className={classes.secondaryText}>
                                    {moment(load.originStop.earliestDate).format('ll')} {moment(load.originStop.earliestDate).format('HH:mm')} -
                                    </p>
                                <p className={classes.secondaryText}>
                                    {moment(load.originStop.latestDate).format('ll')} {moment(load.originStop.latestDate).format('HH:mm')}
                                </p>
                            </Row>
                        )}
                    </Col>
                </Col>

                <Col style={{ width: "9%" }}>
                    <Row>
                        <Col md={1} className={classes.centerColContent} style={{ paddingRight: '10px', paddingTop: '5px', alignItems: 'center' }}>
                            <Row style={{ marginTop: 0 }}>
                                {getIcon("origin")}
                                {/* <FA name={"map-marker-alt"} color={"black"} /> */}
                            </Row>
                        </Col>
                        <Col md={7} style={{ alignItems: 'center', padding: "5px" }}>
                            <hr style={{ marginTop: 6, marginBottom: 10, width: '100%', border: '1px dashed grey' }} />
                            <Row>
                                {checkValueExists(load, "tourStops") && load.tourStops > 0 &&
                                    (<p className={classes.secondaryText} style={{textAlign:'center'}}>
                                        {load.tourStops} stops
                                    </p>)
                                }    
                            </Row>
                        </Col>
                        <Col md={1} className={classes.centerColContent} style={{ paddingLeft: '10px', paddingTop: '5px', alignItems: 'center' }}>
                            <Row style={{ marginTop: 0 }}>
                                {getIcon("destination")}
                                {/* <FA name={"map-marker-alt"} color={"black"} /> */}
                            </Row>
                        </Col>
                    </Row>
                </Col>


                <Col style={{ width: "14%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <Row>
                            <p className={classes.mainText} >
                                {reloadsOpen ?
                                    titleCaseFormatter(delivery.city) + ', ' + delivery.state
                                    :
                                    titleCaseFormatter(load.destStop.city) + ', ' + load.destStop.state
                                }
                            </p>
                        </Row>

                        {!load.destStop.earliestDate && (
                            <Row>
                                <p className={classes.secondaryText}>
                                    {moment(load.destStop.latestDate).format('ll')}
                                </p>
                                <p className={classes.secondaryText}>
                                    {moment(load.destStop.latestDate).format('HH:mm')}
                                </p>
                            </Row>
                        )}
                        {load.destStop.earliestDate && (
                            <Row>
                                <p className={classes.secondaryText}>
                                    {moment(load.destStop.earliestDate).format('ll')} {moment(load.destStop.earliestDate).format('HH:mm')} -
                                    </p>
                                <p className={classes.secondaryText}>
                                    {moment(load.destStop.latestDate).format('ll')} {moment(load.destStop.latestDate).format('HH:mm')}
                                </p>
                            </Row>
                        )}
                    </Col>
                </Col>
                <Col style={{ width: "10%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        {/* <ReloadsLink
                            key={key + "_reloads_button"}
                            load={load}
                            delivery={delivery}
                            pushReloads={pushReloads}
                        /> */}
                    </Col>
                </Col>
                <Col style={{ width: "11%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        {checkValueExists(load, "equipmentType") ?
                            <p className={classes.secondaryText} style={{ float: 'left' }}>{load.equipmentType}</p>
                            :
                            <p className={classes.secondaryText} style={{ float: 'left' }}>Unknown Equipment Type</p>}
                    </Col>
                </Col>
                <Col style={{ width: "10%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'flex-start' }}>
                        {/* {load.targetPay && (load.targetPay != "NULL" && load.targetPay > 0) &&
                            <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                ${parseFloat(load.targetPay).toFixed(2)}
                            </p>}
                        {load.ratePer && (load.ratePer != "NULL" && load.ratePer > 0) &&
                            <p className={classes.secondaryText} style={{ float: 'left' }}>
                                ${parseFloat(load.ratePer).toFixed(2)}/mi
                                </p>
                        } */}
                        {load.miles &&
                            <p className={classes.secondaryText}>
                                {load.miles}mi
                                </p>
                        }
                    </Col>
                </Col>
                <Col style={{ width: "17%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        {carrierCommunications &&
                            checkValueExists(carrierCommunications, "carrierCommunication") &&
                            checkValueExists(carrierCommunications.carrierCommunication, "vendorName") &&
                            carrierCommunications.carrierCommunication.vendorName == "MACROPOINT" ? (
                            <div>
                                <dl className={classes.descriptionList}>
                                    <dt className={classes.descriptorTag}>
                                        <p className={classes.mainText} style={{whiteSpace:'nowrap'}}>Tractor #</p>
                                    </dt>
                                    <dd className={classes.descriptorDetail}>
                                        <p className={classes.secondaryText} style={{position:'relative'}}>
                                            {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "tractor") && checkWhiteSpace(load.carrierTrackingInfo.tractor) && load.carrierTrackingInfo.tractor != "" ? load.carrierTrackingInfo.tractor 
                                                : <span className={classes.missing}> MISSING </span>}
                                            <FeatherIcon.Info
                                                style={{position:'absolute', bottom:-8, right: 35}}
                                                color={"black"}
                                                size={20}
                                                onMouseEnter={handleTrackingHelperOpen}
                                                // onMouseLeave={handleTrackingHelperClose}
                                            />
                                            {getTrackingHelperPopover(id, openRateBreakdown, anchorEl)}
                                        </p>
                                    </dd>
                                    <dt className={classes.descriptorTag}>
                                        <p className={classes.mainText} style={{whiteSpace:'nowrap'}}>Trailer &nbsp;#</p>
                                    </dt>
                                    <dd className={classes.descriptorDetail}>
                                        <p className={classes.secondaryText}>{checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trailer") && checkWhiteSpace(load.carrierTrackingInfo.trailer) && load.carrierTrackingInfo.trailer != "" ? load.carrierTrackingInfo.trailer 
                                            : <span className={classes.missing}> MISSING </span>}</p>
                                    </dd>
                                </dl>
                            </div>
                        ) : carrierCommunications &&
                            checkValueExists(carrierCommunications, "carrierCommunication") &&
                            checkValueExists(carrierCommunications.carrierCommunication, "vendorName") &&
                            carrierCommunications.carrierCommunication.vendorName == "TRUCKER_TOOLS" ? (
                            <div>
                                <dl className={classes.descriptionList} style={{width: 280}}>
                                    <dt className={classes.descriptorTag}>
                                        <p className={classes.mainText} style={{ marginRight: 5 }}>Driver Name</p>
                                    </dt>
                                    <dd className={classes.descriptorDetail}>
                                        <p className={classes.secondaryText} style={{position:'relative'}}>
                                            {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverName") ? load.carrierTrackingInfo.driverName : <span className={classes.missing}> MISSING </span>}
                                            <FeatherIcon.Info
                                                    onClick={(e)=> {e.stopPropagation()}}
                                                    style={{position:'absolute', bottom:-8, right: 80}}
                                                    color={"black"}
                                                    size={20}
                                                    onMouseEnter={handleTrackingHelperOpen}
                                                    // onMouseLeave={handleTrackingHelperClose}
                                                />
                                            {getTrackingHelperPopover(id, openRateBreakdown, anchorEl)}
                                        </p>
                                    </dd>
                                    <dt className={classes.descriptorTag}>
                                        <p className={classes.mainText} style={{ marginRight: 5 }}>Driver Phone</p>
                                    </dt>
                                    <dd className={classes.descriptorDetail}>
                                        <p className={classes.secondaryText}>{checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverPhoneNumber" ) ? formatPhoneNumberWithHyphens(load.carrierTrackingInfo.driverPhoneNumber) : <span className={classes.missing}> MISSING </span>}</p>
                                    </dd>
                                </dl>
                            </div>
                        ) : null}
                    </Col>
                </Col>
                <Col style={{ width: "9%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <div>
                            <Row key={key + "booked_action_row"} >
                                {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trackingStatus") && (
                                    <p className={classes.valueText}>{load.carrierTrackingInfo.trackingStatus}</p>
                                )}
                            </Row>
                        </div>
                    </Col>
                </Col>

            </Row>


            { isExpanded &&
                <LoadCardExpandedContent
                    load={load}
                    index={index}
                />
            }

        </div>

    );
}


export default compose(
    connect(),
    muiThemeable()
)(LoadCard)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '16px',
        lineHeight: '22px',
        color: 'black',
        margin: 0,
        fontWeight: "bold"
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'grey'
    },
    valueText: {
        wordBreak: 'break-word',
        fontSize: '16px',
        color: 'black'
    },
    missing: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    },
    descriptionList: {
        width: '200px',
        overflow: 'hidden',
        padding: 0,
        margin: 0
    },
    descriptorTag: {
        float: 'left',
        width: '40%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetail: {
        float: 'left',
        width: '60%;',
        padding: 0,
        margin: 0,
        display:'inline-block'
    }
});