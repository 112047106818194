import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row, Grid } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import { Card } from 'material-ui/Card';
import * as FeatherIcon from 'react-feather';
import moment from 'moment';
import { checkValueExists } from "../../../lib/check-value-exists"

import EditRoleBtn from './edit-role-btn'


const UserCardMobile = (props) => {
    const classes = useStyles();
    const {
        muiTheme,
        item,
        camelize,
        formatPhoneNumber,
        deleteUser,
        activateUser,
        editUser
    } = props

    const getUserRoleDisplayText = (role) => {
        switch (role) {
            case "admin":
                return <p className={classes.mobileValueText}>ADMIN</p>
            case "dispatch":
                return <p className={classes.mobileValueText}>DISPATCHER</p>
            case "loadviewer":
                return <p className={classes.mobileValueText}>LOAD VIEWER</p>
            case "finance":
                return <p className={classes.mobileValueText}>FINANCE</p>
            default:
                return null
        }
    }

    return (
        <Card style={{ width: "100%", flexDirection: "column", justifyContent: "center", borderRadius: 15, marginBottom: 15 }}>

            <div style={{ padding: 15 }}>

                <Row>
                    <p className={classes.mobileLabelText}>
                        Name:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                        {camelize(item.firstName) + " " + camelize(item.lastName)}
                    </p>
                </Row>
                {checkValueExists(item, "phoneNumber") && (

                    <Row>
                        <p className={classes.mobileLabelText}>
                            Phone Number:{" "}
                        </p>
                        <p className={classes.mobileValueText}>
                            {formatPhoneNumber(item.phoneNumber)}
                        </p>
                    </Row>

                )}

                <Row style={{ flexWrap: 'wrap' }}>
                    <p className={classes.mobileLabelText}>
                        Role:{" "}
                    </p>
                    {checkValueExists(item, "role") &&
                        getUserRoleDisplayText(item.role)
                    }
                </Row>
                <Row style={{ flexWrap: 'wrap', textAlign: 'center', paddingTop: 10 }}>
                    {checkValueExists(item, "expiresAt") &&
                        <div>
                            <p className={classes.mainText}>Carrier Membership: <span style={{ fontWeight: "bold" }}>Pending</span></p>
                            <p className={classes.mainText} style={{ fontSize: 12 }}>Expires on {moment(item.expiresAt).format("MMM DD, YYYY HH:mmA")}</p>
                        </div>
                    }
                </Row>

            </div>



            <Row style={{
                display: 'flex',
                width: '100%',
                height: '28%',
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: '#F6F6F6',
                margin: 0
            }}>

                <Col className={classes.col} style={{ width: "100%", alignItems: "center" }}>
                    {checkValueExists(item, "expiresAt") ? (
                        <Row style={{ width: '100%', display: "flex", alignItems: "center", textAlign: 'center' }}>
                            <Col style={{ width: '50%' }}>
                                <Button
                                    onClick={() => { activateUser() }}
                                >
                                    <p className={classes.mainText} style={{ color: "green" }}>APPROVE</p>
                                </Button>
                            </Col>
                            <Col style={{ width: '50%' }}>
                                <Button
                                    onClick={() => { deleteUser() }}
                                >
                                    <p className={classes.mainText} style={{ color: muiTheme.ttRed }}>REJECT</p>
                                </Button>
                            </Col>
                        </Row>
                    ) : (

                        <Row style={{ width: '100%', display: "flex", alignItems: "center", textAlign: 'center' }}>
                            <Col style={{ width: '50%' }}>
                                <Button
                                    onClick={() => { deleteUser() }}
                                >
                                    <FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />
                                </Button>
                            </Col>
                            <Col style={{ width: '50%' }}>
                                <EditRoleBtn item={item} onEditComplete={(newRole) => editUser(newRole)} mobile/>
                            </Col>
                        </Row>


                    )}
                </Col>


            </Row>
        </Card>

    );
}


export default compose(
    muiThemeable()
)(UserCardMobile)

const useStyles = makeStyles({

    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    card: {
        display: "flex",
        height: 'auto',
        flexDirection: "row",
        alignItems: "center",
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height: 100
    },
    margin: {
        margin: 5,
    },
    mainText: {
        fontFamily: 'Arial !important',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        margin: 0
    },
    secondaryText: {
        fontFamily: 'Arial !important',
        fontSize: '14px',
        lineHeight: '21px',
        color: 'grey'
    },
    mobileValueText: {
        marginRight: 10,
        display: 'inline-block',
        float: 'right',
        color: 'black'
    },
    mobileLabelText: {
        fontWeight: 'bold',
        display: 'inline-block',
        color: 'black'
    }
});