/*global google*/
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment';
import {Popover, OverlayTrigger, Button} from 'react-bootstrap'
import * as CONSTANTS from './constants'

import {
  timeSince,handleApiErrors
} from '../../lib/time-since'
import {toast} from 'react-toastify'
import PhoneNumberFormatter from '../phoneNumberFormatter'

import {laneSeachApi,historySeachApi} from '../../api/carrierLaneHistory'

// The url derived from our .env file

function* searchCarrierByLaneFlow (action) {
  try {
    const { payload } = action

    //console.log(" loadId " + loadId);
    //console.log(" client " + client);

    yield put(showLoading())

    // here until the API async function, is complete!
    const response = yield call(laneSeachApi, payload)
    var cleanResponse = {} //Strip out lanes that have future haul dates
    cleanResponse.status = response.status
    cleanResponse.ownLaneHistory = response.ownLaneHistory.filter((lane)=>moment(lane.lastHauled,'YYYY-MM-DD').isBefore(moment()))
    cleanResponse.allLaneHistory = response.allLaneHistory.filter((lane)=>moment(lane.lastHauled,'YYYY-MM-DD').isBefore(moment()))
    /*if(response.status == true && response.data){
      yield put({ type: CARRIE_LANE_HISTORY_SUCC, response })
    }
    else{
      var error = 'Could Not Retrieve Lane History'
      yield put({ type: CARRIE_LANE_HISTORY_ERR, error })
    }*/
    cleanResponse.payload = payload
    yield put({ type: CONSTANTS.SEARCH_CARRIER_BY_LANE_SUCC, response:cleanResponse  })

    action.updateCallback(payload,cleanResponse)
    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.

  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
   // yield put({ type: CARRIE_LANE_HISTORY_ERR, error })
   console.log(error)
   var response = {status:false,error:error}
   action.updateCallback(cleanResponse)
  }
  finally{
    yield put(hideLoading())
  }
}

function* searchCarrierByHistoryFlow (action) {
  try {
    const { payload } = action

    //console.log(" loadId " + loadId);
    //console.log(" client " + client);

    yield put(showLoading())

    // here until the API async function, is complete!
    const response = yield call(historySeachApi, payload)
    /*if(response.status == true && response.data){
      yield put({ type: CARRIE_LANE_HISTORY_SUCC, response })
    }
    else{
      var error = 'Could Not Retrieve Lane History'
      yield put({ type: CARRIE_LANE_HISTORY_ERR, error })
    }*/
    var cleanResponse = {} //Strip out lanes that have future haul dates
    cleanResponse.status = response.status
    cleanResponse.carrierAvailableHistory = response.carrierAvailableHistory.filter((lane)=>moment(lane.lastHauled,'YYYY-MM-DD').isBefore(moment()))
    cleanResponse.payload = payload
    yield put({ type: CONSTANTS.SEARCH_CARRIER_BY_HISTORY_SUCC, response:cleanResponse  })

    action.updateCallback(payload,cleanResponse)
    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.

  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
   // yield put({ type: CARRIE_LANE_HISTORY_ERR, error })
   console.log(error)
   var response = {status:false,error:error}
   action.updateCallback(cleanResponse)
  }
  finally{
    yield put(hideLoading())
  }
}




// Watches for the LOADS_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* lanehistorySearchWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(CONSTANTS.SEARCH_CARRIER_BY_LANE_REQ, searchCarrierByLaneFlow)
  yield takeLatest(CONSTANTS.SEARCH_CARRIER_BY_HISTORY_REQ, searchCarrierByHistoryFlow)
}

export default lanehistorySearchWatcher
