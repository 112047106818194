import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Button from '@material-ui/core/Button';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FA from 'react-fontawesome'

import LoadingOverlay from '../../../../components/LoadingOverlay'
import LocationSelector from '../components/LocationSelector';
import TruckCapacity from '../components/werner-truck-capacity';
import LanePreferences from '../components/werner-lane-preferences';
import AddPreferenceModal from "../components/werner-add-edit-modal"

import isMobile from '../../../../HOC/Mobile.js'
// import useGtagHook from '../../gtag-hook';
import { checkValueExists } from '../../../../lib/check-value-exists';

import { WernerServices } from '../../../customers/a98db973/services';

const WernerPreferencesMobileUI = (props) => {
    const {
        muiTheme,
        locationsList,
        selectedLocationId,
        setSelectedLocationId,
        isLoading,
        setIsLoading,
        lanePreferencesList,
        updatePreferencesData,
        truckCapacityList,
        renderedTab,
        handlePageChange,
        showAddModal,
        setShowAddModal,
        PAGES
    } = props

    const { height, width } = useWindowDimensions();

    const useStyles = makeStyles({
        container: {
            padding: 5,
            height: height,
            width: '100%'
        },
        header: {
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderRadius: 0,
            textTransform: "none",
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        headerText: {
            textAlign: "center",
            fontSize: 16,
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        }
    });

    const classes = useStyles()

    return (
        <div className={classes.container}>
            
            <Row style={{ height: '8%', marginTop: 25, marginBottom: 25 }}>
                <LocationSelector
                    locationsList={locationsList}
                    selectedLocationId={selectedLocationId}
                    setSelectedLocationId={setSelectedLocationId}
                />
            </Row>

            <Row style={{ height: '8%', width: "100%", display: "flex", justifyContent: "center" }}>
                <Col style={{ width: "100%", display: "flex", justifyContent: "center", paddingLeft: 25, paddingRight: 25 }}>
                    <Button
                        className={classes.header}
                        style={{ borderBottomColor: renderedTab == PAGES["capacity"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                        onClick={() => handlePageChange("capacity")}
                    >
                        <p className={classes.headerText} style={{ color: renderedTab == PAGES["capacity"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Capacity</p>
                    </Button>
                    <Button
                        className={classes.header}
                        style={{ borderBottomColor: renderedTab == PAGES["preferences"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                        onClick={() => handlePageChange("preferences")}
                    >
                        <p className={classes.headerText} style={{ color: renderedTab == PAGES["preferences"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Lane Preferences</p>
                    </Button>
                </Col>
                <Col style={{ position: 'absolute', bottom: 10, right: 10, zIndex: 50 }}>
                    <Button
                        size="small"
                        style={{
                            backgroundColor: muiTheme.actionColor,
                            height: 45,
                            borderRadius: 25,
                            margin: 0,
                            minWidth: 45,
                            width: 45
                        }}
                        onClick={() => { setShowAddModal(true) }}
                    >
                        <FA name='plus' style={{color: 'white'}} size='2x' />
                    </Button>
                </Col>
            </Row>

            <Row style={{ height: 'calc(84% - 90px)' }}>
                {renderedTab == PAGES["capacity"] && !isLoading && selectedLocationId &&
                    <TruckCapacity truckCapacityList={truckCapacityList} selectedLocationId={selectedLocationId} updatePreferencesData={updatePreferencesData} handleViewLoads={props.handleViewLoads} />
                }
                {renderedTab == PAGES["preferences"] && !isLoading && selectedLocationId &&
                    <LanePreferences lanePreferencesList={lanePreferencesList} selectedLocationId={selectedLocationId} updatePreferencesData={updatePreferencesData} setIsLoading={setIsLoading} handleViewLoads={props.handleViewLoads} />
                }
                {showAddModal && (
                    <AddPreferenceModal selectedLocationId={selectedLocationId} renderedTab={renderedTab} onClose={() => { setShowAddModal(false) }} updatePreferencesData={updatePreferencesData} />
                )}
            </Row>
        </div>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
)(WernerPreferencesMobileUI)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}