// WERNER THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#163762',
    secondary: '#f5b42a',
    actionColor: "#3369b4",
    lightContrastText: '#fff',
    darkContrastText: '#1B365D',
    useDarkContrastText: false,
    menuHighlightColor: "rgba(0, 0, 0, 0.3)",
    useWhiteBackgroundBehindLogo: false,
    tagLine:'Critical supplies for an ever evolving world.',
    customerUniqueId: "a98db973",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Roboto",
    source: "carrier_edge"
});
//console.log(theme);
export default theme
