
import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../../../HOC/Mobile.js'
import { GetProfile } from '../../../../../../api/profiledata'
import * as RateNegotiationsApi from '../../../../../../api/rateNegotiations'
import { toast } from 'react-toastify';
import RateNegotiationsListDesktop from './rateNegotiationsListDesktop';
import RateNegotiationsListMobile from './rateNegotiationsListMobile';


const RateNegotiationsList = (props) => {

    const {
        originalQuoteList,
        load,
        isLoading,
        setQuotePage,
        showAddQuoteBtn,
        setShowAddQuoteBtn,
        removeLoadFromList,
        fetchAllQuotes,
        callRefreshCard,
        isMobile
    } = props

    const userProfile = useRef(null);

    const [quoteList, setQuoteList] = useState(originalQuoteList)
    const [buildingList, setBuildingList] = useState(true)


    useEffect(() => {
        setBuildingList(true)
        GetProfile().then(resp => {
            userProfile.current = resp
        })

        let tmpQuoteList = removeRescindedOffer()
        if (tmpQuoteList.length <= 0)
            setShowAddQuoteBtn(true)
        else
            setQuoteList(tmpQuoteList)

        setBuildingList(false)
    }, [])

    const notInterested = () => {

        let latestOffer = quoteList[0]
        let params = {
            quote_id: latestOffer.id,
            load_id: load.loadId,
            broker_id: load.brokerId,
            carrier_id: latestOffer.carrier_id,
            source: "smart_capacity",
            amount: latestOffer.quote_value,
            accepted: false,
            rejected: false,
        }

        RateNegotiationsApi.SubmitQuoteNegotiation(params).then(response => {
            if (response.status && response.data) {
                //todo: this needs to also remove it from the loads returned from activity api
                removeLoadFromList(load.loadId)

            } else {
                let errorMessage = "Error: There was a problem submitting your request."
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch(() => {
            let errorMessage = "Error: There was a problem submitting your request."
            toast.error(errorMessage)
        })
    }

    const removeRescindedOffer = () => {
        let tmpQuoteList = quoteList.filter(quote => {
            return quote.is_active === 1
        })
        return tmpQuoteList
    }

    return (
        <div style={{ height: '100%' }}>
            {isMobile ?
                <RateNegotiationsListMobile
                    quoteList={quoteList}
                    load={load}
                    notInterested={notInterested}
                    setQuotePage={setQuotePage}
                    showAddQuoteBtn={showAddQuoteBtn}
                    fetchAllQuotes={fetchAllQuotes}
                    buildingList={buildingList}
                    isLoading={isLoading}
                    userProfile={userProfile}
                    callRefreshCard={callRefreshCard}
                    {...props}
                />
                :
                <RateNegotiationsListDesktop
                    quoteList={quoteList}
                    load={load}
                    notInterested={notInterested}
                    setQuotePage={setQuotePage}
                    showAddQuoteBtn={showAddQuoteBtn}
                    fetchAllQuotes={fetchAllQuotes}
                    buildingList={buildingList}
                    isLoading={isLoading}
                    userProfile={userProfile}
                    callRefreshCard={callRefreshCard}
                    {...props}
                />
            }
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(RateNegotiationsList)