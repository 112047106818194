/*global google*/
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment';
import { handleApiErrors } from '../lib/api-errors'
import {
  REG_CARRIER,
  REG_CARRIER_SUCCESS,
  REG_CARRIER_ERROR,
  GET_REG_CARRIER,
  GET_REG_CARRIER_SUCCESS,
  GET_REG_CARRIER_ERROR,
  
} from './constants'
import {postConfirmRegistration} from '../api/confirmregistration'





// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* getDataFlow (action) {
  try {

  
    yield put(showLoading())

    // here until the API async function, is complete!
    
    //const response = yield call(getConfirmRegistration)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    
    yield put({ type: GET_REG_CARRIER_SUCCESS })
    
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: GET_REG_CARRIER_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}


// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* confirmRegFlow (action) {
  try {

    
    yield put(showLoading())

    // here until the API async function, is complete!
    const response = yield call(postConfirmRegistration)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    
    //yield put({ type: REG_CARRIER_SUCCESS, response })
    action.updateCallback(response);
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: REG_CARRIER_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the LOADS_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* confirmRegWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(GET_REG_CARRIER, getDataFlow)
  yield takeLatest(REG_CARRIER, confirmRegFlow)
}

export default confirmRegWatcher