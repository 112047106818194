import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import isMobile from '../../HOC/Mobile.js'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import LoadingOverlay from '../../components/LoadingOverlay'

import { Col, Row } from "react-bootstrap"
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';

import QuoteButton from "../components/load-list/quotes/quoteButton"
import BinButton from "../components/load-list/bin/binButton"
import BookedActionButton from "../components/booked-loads/action-button"
import LoadCardExpandedContent from '../components/load-list/desktop/LoadCardExpandedContent'
import LoadDetailsMobileContent from '../components/load-list/mobile/loadDetailsMobile/loadDetailsMobileContent';
import WernerLoadCardExpandedContent from '../components/load-list/desktop/WernerLoadCardExpandedContent'
import LoginButton from '../authentication/login/Auth0-Univeral-Login/login-button';

import moment from 'moment'
import { checkValueExists } from "../../lib/check-value-exists"
import { validLoadDetails } from "../../lib/valid-load-details"
import {useAuthenticationHook} from '../hooks/authentication-hook';
import { GetLoadDetails, GetLoadDetailsByLoadNumber } from '../apis/api-load-search';

const LoadScreen = (props) => {

    const { height, width } = useWindowDimensions();
    const { muiTheme, isMobile, availability } = props

    const TT_LOGO_ASSET_URL = 'http://assets.truckertools.com/images/logos/';

    const useStyles = makeStyles({
        container: {
            height: height,
            width: '100%',
            display: "flex",
            justifyContent: "center",
            paddingTop: isMobile ? 0 : 60
        },
        card: {
            minWidth: 275,
            width: '100%',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.08,
            marginTop: 10,
            marginLeft: 15,
            marginRight: 10,
            marginBottom: 0
        },
        mainText: {
            fontSize: '14px',
            lineHeight: '22px',
            color: 'black',
            fontWeight: 'bold',
            margin: 0
        },
        secondaryText: {
            fontSize: '13px',
            lineHeight: '20px',
            color: 'grey'
        },
        centerColContent: {
            height: '100%',
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'center',
            // alignItems: 'center',
            textAlign: 'left'
        },
        showInterestBtn: {
            height: 'auto',
            width: '100%',
            // color: muiTheme.secondary, 
            backgroundColor: '#ad2228 !important',
            // borderColor: muiTheme.primary
        },
        labelText: {
            fontSize: '14px',
            color: 'grey'
        },
        valueText: {
            wordBreak: 'break-word',
            fontSize: '14px',
            color: 'black'
        },
    });

    const classes = useStyles()

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const user = myAuthService.getUser()

    const [isLoading, setIsLoading] = useState(true)
    const [details, setDetails] = useState(null)
    const [failToLoadImage, setFailToLoadImage] = useState(false)
    const [pickup, setPickup] = useState(null)
    const [delivery, setDelivery] = useState(null)
    const [isHazmat, setIsHazmat] = useState(false)
    const [latestQuote, setLatestQuote] = useState(null)
    const [latestQuoteShowBIN, setLatestQuoteShowBIN] = useState(false)
    const [refreshCard, setRefreshCard] = useState(false)
    const [key, setKey] = useState(props.key)
    const [anchorEl, setAnchorEl] = useState(null)

    const [autoBinForm, setAutoBinForm] = useState(false)

    const open = Boolean(anchorEl);
    const id = open ? 'rate-info-popover' : undefined;

    const reloadsOpen = false

    const setLatestQuoteStatus = (quote) => {
        if (quote.message_type === "QUOTE_D" || quote.message_type === "QUOTE_C") {
            setLatestQuoteShowBIN(false)
        }
        else if (quote.message_type === "QUOTE_B" && quote.accepted) {
            setLatestQuoteShowBIN(true)

        }
        else if (quote.message_type === "QUOTE_B" && quote.rejected) {
            setLatestQuoteShowBIN(false)

        }
        else if (quote.message_type === "QUOTE_B") {
            setLatestQuoteShowBIN(true)

        }
        else if (quote.message_type === "BOOKITNOW") {
            setLatestQuoteShowBIN(false)
        }

    }

    const sortQuotes = (quoteList) => {
        let sortedOfferHistory = quoteList.sort((a, b) => {
            return moment(a.updated_at).valueOf() - moment(b.updated_at).valueOf()
        })
        return sortedOfferHistory
    }

    const getLatestActiveQuote = (quoteList) => {
        let tmpQuoteList = []
        tmpQuoteList = quoteList.filter(quote => {
            return quote.is_active == 1
        })
        tmpQuoteList = sortQuotes(tmpQuoteList)
        return tmpQuoteList[0]
    }


    const fetchLoadDetails = (loadId) => {


        let params = {
            "scLoadId": loadId
        }

        GetLoadDetails(params).then(response => {
            handleApiResp(response)
        }).catch((error) => {
            setDetails(null)
            setIsLoading(false)
        })


    }

    const fetchLoadDetailsByLoadNumber = (loadNumber) => {

        GetLoadDetailsByLoadNumber(loadNumber, muiTheme.customerUniqueId).then(response => {
            handleApiResp(response)
        }).catch((error) => {
            setDetails(null)
            setIsLoading(false)
        })


    }

    const handleApiResp = (response) => {
        if (response.status && response.load) {
            let tmpPickup = {}
            let tmpDelivery = {}

            const load = response.load

            if (load.stops) {

                tmpPickup = {
                    ...load.stops[0],
                    scheduledAt: load.stops[0].scheduledAt ? load.stops[0].scheduledAt : load.stops[0].scheduled_at
                }

                tmpDelivery = {
                    ...load.stops[load.stops.length - 1],
                    scheduledAt: load.stops[load.stops.length - 1].scheduledAt ? load.stops[load.stops.length - 1].scheduledAt : load.stops[load.stops.length - 1].scheduled_at
                }

            }
            else {

                tmpPickup = {
                    city: load.start_city,
                    state: load.start_state,
                    scheduledAt: load.start_time
                }

                tmpDelivery = {
                    city: load.end_city,
                    state: load.end_state,
                    scheduledAt: load.end_time
                }

            }


            if (load.truckTypes) {
                if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                    setIsHazmat(true)
            }

            if (load.quotes && load.quotes.length > 0) {
                let tmpQuote = getLatestActiveQuote(load.quotes)
                setLatestQuote(tmpQuote)
                setLatestQuoteStatus(tmpQuote)
                setKey(key + "load_card_" + load.quotes.length)
            } else {
                setKey(key + "load_card")
            }

            setPickup(tmpPickup)
            setDelivery(tmpDelivery)

            setDetails(load)
            setIsLoading(false)
        } else {
            setDetails(null)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        let url = String(window.location.search)
        let params = new URLSearchParams(url);
        
        let loadId = params.get('id')
        let loadNumber = params.get('loadNumber')
        let bin = params.get('bin')
        if (loadId) {
            fetchLoadDetails(loadId)
        } else if (loadNumber) {
            fetchLoadDetailsByLoadNumber(loadNumber)
            // show toast and redirect to find and book loads
        } else {
            setDetails(null)
            setIsLoading(false)
            // Route to home for invalid query keys
            window.location = "/home"
        }
        if(bin=='1'){
            setAutoBinForm(true)
            
        }
    }, [])

    useEffect(() => {
        if (details && details.quotes && details.quotes.length > 0) {
            let tmpQuote = getLatestActiveQuote(details.quotes)
            setLatestQuote(tmpQuote)
            setLatestQuoteStatus(tmpQuote)
            setKey(key + "load_card_" + details.quotes.length)
        } else {
            setKey(key + "load_card")
        }

    }, [refreshCard])

    const handleClick = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showBIN = () => {

        if (latestQuote) { //if there are quotes then BIN is dependent on them and not what's on the load
            return latestQuoteShowBIN
        }
        else if (!details.isPreviewLoad && details.hasBookItNow === 1 && checkValueExists(details, "targetPay") && Number(details.targetPay) > 0)
            return true

        return false
    }

    const getDisplayRate = () => {
        let tmpRate = null
        if (latestQuote) {
            tmpRate = latestQuoteShowBIN ? latestQuote.quote_value : null
        }
        else if (details.targetPay && (details.targetPay != "NULL" && details.targetPay > 0)) {
            tmpRate = details.targetPay
        }

        if (tmpRate && tmpRate > 0) {
            return (
                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    ${parseFloat(tmpRate).toFixed(2)}
                </p>
            )
        }

        return null

    }

    const callRefreshCard = () => {
        setRefreshCard(!refreshCard)
    }

    const getIcon = (market, type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type == "origin") {
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    backgroundColor: muiTheme.ttUnselectGrey,
                    width: 12,
                    height: 12,
                }}
            />
        }

        // if (checkValueExists(market, key)) {
        //     if (market[key] >= 80) {
        //         return (<FA name='fire' color='#9A1F25' />)
        //     } else if (market[key] >= 65 && market[key] < 80) {
        //         return (<FA name='fire' color='#D96C1E' />)
        //     } else if (market[key] >= 50 && market[key] < 65) {
        //         return (<FA name='fire' color='#FFB400' />)
        //     } else if (market[key] >= 35 && market[key] < 50) {
        //         return (<FA name='snowflake' color='#5DBFFF' />)
        //     } else if (market[key] > 20 && market[key] < 35) {
        //         return (<FA name='snowflake' color='#0086E1' />)
        //     } else if (market[key] <= 20) {
        //         return (<FA name="snowflake" color={'#002F81'} />)
        //     }
        // }

        return icon
    }

    return (
        <div className={classes.container}>
            {isLoading && <LoadingOverlay />}
            {!isLoading && !details &&
                <div style={{ paddingTop: 25, textAlign: 'center' }}>
                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                        This load does not exist
                    </p>
                    <Button
                        aria-describedby={"go_back"}
                        variant={"outlined"}
                        onClick={() => window.location = "/home"}
                        style={{
                            fontSize: 16,
                            color: muiTheme.lightContrastText,
                            backgroundColor: muiTheme.actionColor,
                            borderColor: muiTheme.actionColor,
                            border: '1px solid',
                            margin: 5,
                            marginTop: 40,
                            width: 200
                        }}
                        size={"medium"}
                        disableRipple={false}
                    >
                        View More Loads
                    </Button>
                </div>
            }
            {!isLoading && details && !isMobile && (
                <div style={{ display: 'flex', flexDirection:'column', width: "90%", alignItems:'center'}}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Row style={{ display: 'flex' }}>

                                <Col style={{ display: 'flex', width: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '1.3%' : "4.3%" }}>
                                </Col>

                                {process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && (
                                    <Col style={{ width: '8%', paddingLeft: 10, display: 'flex', alignItems: "center", }}>
                                        <div style={{ display: "flex", width: 30, height: 25 }}>
                                            {!failToLoadImage ?
                                                <img
                                                    width={"auto"}
                                                    height={"auto"}
                                                    src={TT_LOGO_ASSET_URL + details.brokerId + '/default.png'}
                                                    onError={() => setFailToLoadImage(true)}
                                                /> :
                                                <p className={classes.mainText}>{details.companyName}</p>
                                            }
                                        </div>
                                    </Col>
                                )}

                                <Col style={{ width: "40.7%" }}>
                                    <Row>

                                        <Col md={4} className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                                            <Row>

                                                <p className={classes.mainText} >
                                                    {reloadsOpen ?
                                                        pickup.city + ', ' + pickup.state
                                                        :
                                                        details.originCity + ', ' + details.originState
                                                    }
                                                </p>
                                            </Row>
                                            {!details.pickupDateTimeLatest && (
                                                <Row>
                                                    <p className={classes.secondaryText}>
                                                        {moment(details.pickupDateTime).format('ll')}
                                                    </p>
                                                    <p className={classes.secondaryText}>
                                                        {moment(details.pickupDateTime).format('HH:mm')}
                                                    </p>
                                                </Row>
                                            )}
                                            {details.pickupDateTimeLatest && (
                                                <Row>
                                                    <p className={classes.secondaryText}>
                                                        {moment(details.pickupDateTime).format('ll')} {moment(details.pickupDateTime).format('HH:mm')} -
                                                    </p>
                                                    <p className={classes.secondaryText}>
                                                        {moment(details.pickupDateTimeLatest).format('ll')} {moment(details.pickupDateTimeLatest).format('HH:mm')}
                                                    </p>
                                                </Row>
                                            )}
                                            {isAuthenticated && process.env.REACT_APP_ADVANTAGE_ID === "a98db973" &&
                                                details.stops[0].stop_type && details.stops[0].stop_type != "NULL" &&

                                                <Row>
                                                    <p className={classes.secondaryText}>
                                                        {details.stops[0].stop_type}
                                                    </p>
                                                </Row>

                                            }
                                        </Col>

                                        <Col md={1} className={classes.centerColContent} style={{ padding: '5px', alignItems: 'center' }}>
                                            <Row style={{ marginTop: 0 }}>
                                                {getIcon(details, "origin")}
                                            </Row>
                                        </Col>

                                        <Col md={2} style={{ alignItems: 'center', padding: "5px" }}>

                                            <hr style={{ marginTop: 6, marginBottom: 10, width: '100%', border: '1px solid black' }} />

                                            <Row>
                                                {checkValueExists(details, "stops") && (details.stops.length - 2) > 0 ?
                                                    <p className={classes.secondaryText} style={{ textAlign: 'center' }}>
                                                        {details.stops.length - 2} Stops
                                                    </p>
                                                    : checkValueExists(details, "numberOfStops") && ((details.numberOfStops - 2) > 0) ?
                                                        <p className={classes.secondaryText} style={{ textAlign: 'center' }}>
                                                            {details.numberOfStops - 2} Interim Stops
                                                        </p>
                                                        : null
                                                }
                                            </Row>
                                        </Col>

                                        <Col md={1} className={classes.centerColContent} style={{ padding: '5px', alignItems: 'center' }}>
                                            <Row style={{ marginTop: 0 }}>
                                                {getIcon(details, "destination")}
                                            </Row>
                                        </Col>

                                        <Col md={4} className={classes.centerColContent} style={{ alignItems: 'left' }}>
                                            <Row>
                                                <p className={classes.mainText} >
                                                    {reloadsOpen ?
                                                        delivery.city + ', ' + delivery.state
                                                        :
                                                        details.destinationCity + ', ' + details.destinationState
                                                    }
                                                </p>
                                            </Row>
                                            {!details.deliveryDateTimeLatest && (
                                                <Row>
                                                    <p className={classes.secondaryText}>
                                                        {moment(details.deliveryDateTime).format('ll')}
                                                    </p>
                                                    <p className={classes.secondaryText}>

                                                        {moment(details.deliveryDateTime).format('HH:mm')}
                                                    </p>
                                                </Row>
                                            )}
                                            {details.deliveryDateTimeLatest && (
                                                <Row>
                                                    <p className={classes.secondaryText}>
                                                        {moment(details.deliveryDateTime).format('ll')} {moment(details.deliveryDateTime).format('HH:mm')} -
                                                    </p>
                                                    <p className={classes.secondaryText}>
                                                        {moment(details.deliveryDateTimeLatest).format('ll')} {moment(details.deliveryDateTimeLatest).format('HH:mm')}
                                                    </p>
                                                </Row>
                                            )}
                                            {isAuthenticated && process.env.REACT_APP_ADVANTAGE_ID === "a98db973" &&
                                                details.stops[details.stops.length - 1].stop_type && details.stops[details.stops.length - 1].stop_type != "NULL" &&

                                                <Row>
                                                    <p className={classes.secondaryText}>
                                                        {details.stops[details.stops.length - 1].stop_type}
                                                    </p>
                                                </Row>

                                            }
                                        </Col>

                                    </Row>
                                </Col>

                                <Col style={{ width: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? "50%" : "55%" }}>
                                    <Col md={3} className={classes.centerColContent} style={{ alignItems: 'center' }}>
                                        {/* {!props.onInvoicesScreen &&
                                        <ReloadsLink
                                            key={key + "_reloads_button"}
                                            load={details}
                                            delivery={delivery}
                                            pushReloads={pushReloads}
                                        />
                                    } */}
                                    </Col>

                                    <Col md={3} className={classes.centerColContent} style={{ alignItems: 'flex-end', textAlign: 'left' }}>
                                        <Row style={{ width: '100%' }}>
                                            <p className={classes.mainText} style={{ fontWeight: "normal", display: 'inline-block', paddingRight: 5 }}>
                                                {details.truckTypes}
                                            </p>
                                            {isHazmat && <FontAwesomeIcon icon={['fas', 'biohazard']} style={{ color: '#000' }} />}
                                        </Row>
                                        {details.weight && (details.weight != "NULL" && details.weight > 0) &&
                                            <Row style={{ width: '100%' }}>
                                                <p className={classes.mainText} style={{ fontWeight: "normal" }}>
                                                    {parseFloat(details.weight).toLocaleString('en')} lbs
                                                </p>
                                            </Row>}
                                    </Col>

                                    {isAuthenticated ? (
                                        <Col md={3} className={classes.centerColContent} style={{ alignItems: 'flex-start', paddingLeft: 15 }}>
                                            <Row>
                                                {getDisplayRate()}
                                            </Row>
                                            <Row>
                                                <div style={{ overflow: 'hidden' }}>

                                                    {details.ratePer && (details.ratePer != "NULL" && details.ratePer > 0) &&
                                                        <p className={classes.secondaryText} style={{ float: 'left' }}>
                                                            ${parseFloat(details.ratePer).toFixed(2)}/mi
                                                        </p>
                                                    }
                                                    {details.distance && details.distance > 0 &&
                                                        <p className={classes.secondaryText} style={{ float: 'right', marginLeft: 8 }}>
                                                            {details.distance}mi
                                                        </p>
                                                    }
                                                </div>
                                            </Row>
                                        </Col>
                                    ) : (
                                        <Col md={3} className={classes.centerColContent} style={{ alignItems: 'flex-start', paddingLeft: 15 }}>
                                            <FeatherIcon.Info color={"black"} size={20} onClick={handleClick} />
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <div style={{ padding: 10, width: 150 }}>
                                                    {process.env.REACT_APP_USE_AUTH0 === "true" ?
                                                        <p><LoginButton
                                                            useATag={true}
                                                            btnStyle={{ color: muiTheme.actionColor, cursor: "pointer" }}
                                                            btnText={"Log in"}
                                                            handleClick={() => { }}
                                                        /> for rate and other information</p>
                                                        :
                                                        <p><a href={"/login"} style={{ color: muiTheme.actionColor }}>Log in</a> for rate and other information</p>
                                                    }
                                                </div>
                                            </Popover>
                                        </Col>
                                    )}

                                    <Col md={3} className={classes.centerColContent} style={{ alignItems: 'center', textAlign: 'center' }}>
                                        <div style={{ display: 'contents' }}>

                                            <div style={{ display: 'contents' }}>
                                                {checkValueExists(details, "covered") && details.covered == 1 && checkValueExists(details, "selfCovered") && details.selfCovered == 1 && (
                                                    <Col testID={"fullCard CR 1"} style={{ width: '90%', marginHorizontal: "5%", justifyContent: 'center' }}>
                                                        <p style={{ color: 'grey', textAlign: "right", fontSize: 14 }}>Covered by you.</p>
                                                    </Col>
                                                )}
                                                {checkValueExists(details, "covered") && details.covered == 1 && (!checkValueExists(details, "selfCovered") || (checkValueExists(details, "selfCovered") && details.selfCovered == 0)) && (
                                                    <Col testID={"fullCard CR 2"} style={{ width: '90%', marginHorizontal: "5%", justifyContent: 'center' }}>
                                                        <p style={{ color: 'grey', textAlign: "right", fontSize: 14 }}>Covered by someone else.</p>
                                                    </Col>
                                                )}

                                                {(!checkValueExists(details, "covered") || (checkValueExists(details, "covered") && details.covered === 0)) && (
                                                    <Row key={key + "_action_row"} style={{ display: (!user || (user && user.role!==""  && user.role.toLowerCase() !== "finance" && user.role.toLowerCase() !== "loadviewer")) ? "block" : "none" }}>

                                                        {showBIN() && validLoadDetails(details, ["weight"]) &&
                                                            <BinButton
                                                                key={key + "_BIN_button"}
                                                                carrierId={availability.carrier_id}
                                                                availabilityId={availability.id}
                                                                load={details}
                                                                index={1}
                                                                pickup={pickup}
                                                                delivery={delivery}
                                                                quoteValue={latestQuote ? latestQuote.quote_value : details.targetPay}
                                                                autoBinForm={autoBinForm}
                                                                {...props}
                                                            />
                                                        }
                                                        {!details.isPreviewLoad &&
                                                            <QuoteButton
                                                                load={details}
                                                                pickup={pickup}
                                                                delivery={delivery}
                                                                carriers={[availability.carrier_id]}
                                                                brokers={[details.brokerId]}
                                                                availabilities={[{ availability_id: availability.id, carrier_id: availability.carrier_id }]}
                                                                isAuthenticated={isAuthenticated}
                                                                callRefreshCard={callRefreshCard}
                                                                {...props}
                                                            />
                                                        }
                                                    </Row>
                                                )}
                                            </div>
                                        </div>
                                    </Col>

                                </Col>

                            </Row>
                            {process.env.REACT_APP_ADVANTAGE_ID != "a98db973" &&
                                <LoadCardExpandedContent
                                    load={details}
                                    index={0}
                                    pickup={pickup}
                                    delivery={delivery}
                                    // availability={availability}
                                    availability={null}
                                />
                            }
                            {process.env.REACT_APP_ADVANTAGE_ID == "a98db973" &&
                                <WernerLoadCardExpandedContent
                                    load={details}
                                    index={0}
                                    pickup={pickup}
                                    delivery={delivery}
                                    // availability={availability}
                                    availability={null}
                                />
                            }
                        </CardContent>
                    </Card>
                    <Button
                        aria-describedby={"go_back"}
                        variant={"outlined"}
                        onClick={() => window.location = "/home"}
                        style={{
                            fontSize: 16,
                            color: muiTheme.lightContrastText,
                            backgroundColor: muiTheme.actionColor,
                            borderColor: muiTheme.actionColor,
                            border: '1px solid',
                            margin: 5,
                            marginTop: 40,
                            width: 200
                        }}
                        size={"medium"}
                        disableRipple={false}
                    >
                        View More Loads
                    </Button>        
                </div>
            )}
            {!isLoading && details && isMobile && (
                <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                    <LoadDetailsMobileContent
                        load={details}
                        pickup={pickup}
                        delivery={delivery}
                        availability={availability}
                        onPreviewScreen
                    />
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    availability: state.AvailabilityLoads.payload.availability
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
)(LoadScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}