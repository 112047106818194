import {BACKEND_URL} from './constants.js'


export function addUser(payload){
  var requestUrl=BACKEND_URL+'/user/createUserForCustomerAccount'
  var payload=JSON.stringify(payload)
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json',
                            },
                            body:payload
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}


export function getUser(){

  //var requestUrl='http://api.jsonbin.io/b/5aa679da7417a517644f4887'
  var requestUrl=BACKEND_URL+'/user/getUsersForCustomerAccount'
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            }
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
//api.jsonbin.io/b/5aa679da7417a517644f4887
