/*global google*/
import {
  CALL_PROFILE_DATA,
  CALL_PROFILE_DATA_SUCCESS,
  CALL_PROFILE_DATA_ERROR,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_DATA_SUCCESS,
  UPDATE_PROFILE_DATA_ERROR,
} from './constants'

const initialState = {
  requesting: false,
  successful: false,
  profileData: [],
  updateResponse: [],
  messages: [],
  errors: [],
  UPDATE_PROFILE_DATA_SUCCESS: false,
}

const reducer = function profileDataReducer (state = initialState, action) {
  switch (action.type) {

    case CALL_PROFILE_DATA:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Getting your profile data...', time: new Date() }],
        errors: [],
      }
      
    case UPDATE_PROFILE_DATA:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Updating your profile data...', time: new Date() }],
        errors: [],
      }

    case CALL_PROFILE_DATA_SUCCESS:
      // console.log(action);
      return {
        profileData: action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        
      }

    case UPDATE_PROFILE_DATA:
      console.log('UPDATE_PROFILE_DATA');
      console.log(action);
      return {
        updateResponse : action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        updateSuccess: true,
        
      }


    
    
    case UPDATE_PROFILE_DATA_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        updateSuccess: false,
      }
    
    case CALL_PROFILE_DATA_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        successful: false,
      }
      
      default : return state
    
  }
}

export default reducer