import React from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Col, Row, Grid } from "react-bootstrap"
import Button from '@material-ui/core/Button';

import { checkValueExists } from "../../../../lib/check-value-exists"
import { camelize } from "../../../helpers/services"

const TireCardMobile = (props) => {

    const {
        item,
        cardWidth,
        muiTheme,
        imageUrl,
        onAdd,
        isCartVersion
    } = props

    const useStyles = makeStyles({
        card: {
            backgroundColor: 'white',
            borderRadius: 15,
            zIndex: 1,
            elevation: 3,
            shadowRadius: 5,
            shadowColor: 'black',
            //shadowOffset: {width: 0, height: 5},
            shadowOpacity: 0.08,
        },
        rowStyle: {
            marginBottom: 10,
            alignItems: "flex-start"
        },
        labelText: {
            fontSize: 11,
            lineHeight: "17px"
        },
        mainText: {
            fontSize: 15,
            fontWeight: "bold",
            lineHeight: "17px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    });

    const classes = useStyles()

    return (
        <Col style={{ display: 'flex', width: '100%', paddingLeft: 5, paddingRight: 5 }}>
            <Col style={{ justifyContent: 'space-around', width: '100%', height: '100%', position: 'relative' }}>
                <Row style={{ height: '100%', width: '100%', padding: '2%', marginTop: 10, display: 'inline-flex' }}>
                    <Col style={{ width: '23%', alignItems: 'flex-start', paddingLeft: 5 }}>
                        {checkValueExists(item, "name") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.labelText}>Name</p>
                            </Row>
                        }
                        {checkValueExists(item, "model") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.labelText}>Model</p>
                            </Row>
                        }
                        {isCartVersion && (
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.labelText}>Brand</p>
                            </Row>
                        )}
                        {!isCartVersion && checkValueExists(item, "size") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.labelText}>Size</p>
                            </Row>
                        }
                        {!isCartVersion && checkValueExists(item, "position") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.labelText}>Position</p>
                            </Row>
                        }
                        {!isCartVersion && checkValueExists(item, "ply") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.labelText}>Ply</p>
                            </Row>
                        }
                    </Col>
                    <Col style={{ width: !isCartVersion ? '49%' : '74%', marginRight: "3%", alignItems: 'flex-start' }}>
                        {checkValueExists(item, "name") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.mainText}>{item.name}</p>
                            </Row>
                        }
                        {checkValueExists(item, "model") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.mainText}>
                                    {item.model}
                                </p>
                            </Row>
                        }
                        {isCartVersion && (
                            <Row className={classes.rowStyle}>
                                <img src={imageUrl} alt={item.brand + " logo"} width={100} />
                            </Row>
                        )}
                        {!isCartVersion && checkValueExists(item, "size") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.mainText}>
                                    {item.size}
                                </p>
                            </Row>
                        }
                        {!isCartVersion && checkValueExists(item, "position") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.mainText}>
                                    {camelize(item.position)}
                                </p>
                            </Row>
                        }
                        {!isCartVersion && checkValueExists(item, "ply") &&
                            <Row className={classes.rowStyle}>
                                <p numberOfLines={1} className={classes.mainText}>
                                    {item.ply}
                                </p>
                            </Row>
                        }
                    </Col>
                    {!isCartVersion &&
                        <Col style={{ width: '25%' }}>
                            <Row style={{ height: "50%" }}>
                                <img src={imageUrl} alt={item.brand + " logo"} width={75} />
                            </Row>
                            <Row style={{ height: "50%" }}>
                                <Button
                                    style={{
                                        backgroundColor: muiTheme.actionColor,
                                        color: "#fff",
                                        fontWeight: "bold",
                                        fontSize: 11
                                    }}
                                    onClick={onAdd}
                                >Add</Button>
                            </Row>
                        </Col>
                    }
                </Row>
            </Col>
        </Col>
    )
}
export default compose(
    muiThemeable()
)(TireCardMobile)