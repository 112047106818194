import {BACKEND_URL} from '../../api/constants.js'
export function validateMc(mc,brokerId){
  var requestUrl=BACKEND_URL+'/carrier/validateMC?mc='+mc+'&brokerId='+brokerId
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                              'Content-Type':'application/json'
                            },
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
