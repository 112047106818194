import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import List from '@material-ui/core/List';
import muiThemeable from 'material-ui/styles/muiThemeable';
import LoadListItemMobile from './LoadListItemMobile'
import { WernerServices } from '../../../../../customers/a98db973/services';

const LoadListMobile = (props) => {

  const {
    filteredLoadList = [],
    availability,
    myLoadsRenderedTab = 0
  } = props

  const [carrierCommunications, setCarrierCommunications] = useState(null)


  useEffect(() => {
    if (myLoadsRenderedTab == 0) {
      const apiServices = new WernerServices()
      apiServices.getCommunications()
        .then(response => {
          // console.log("response: ", response)
          if (response) {
            setCarrierCommunications(response)
          }
          // setIsLoading(false)
        })
        .catch(error => {
          // console.log("ERROR 2: ", error)
          // let errorMessage = "Error: There was a problem fetching your booked loads."
          // setIsLoading(false)
          // toast.error(errorMessage)
        })
    }
  }, [])

  return (
      <List style={{ overflowX: 'hidden', overflowY: 'auto', height: '100%', maxHeight: '100%', padding: 10, paddingBottom: 50 }}>

        {filteredLoadList.length > 0 ?
          filteredLoadList.map((load, index) => {
              return (
                <LoadListItemMobile
                  {...props}
                  key={load.id + "_" + index}
                  load={load}
                  availability={availability}
                  index={index}
                  myLoadsRenderedTab={myLoadsRenderedTab}
                  carrierCommunications={carrierCommunications}
                />
              )
          })
          :
          <div style={{ paddingTop: 25 }}>
            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
              No Loads Found
              </p>
          </div>

        }

      </List>
  )

}

export default compose(
  connect(),
  muiThemeable()
)(LoadListMobile)