import {BACKEND_URL} from './constants.js'

export function getTMSBroker(){
  var requestUrl=BACKEND_URL+'/gettmsBrokerDetails';
  return fetch(requestUrl,{
                method: 'GET',
                headers: {
                  'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                },
          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function updateTMSBroker(){
  var requestUrl=BACKEND_URL+'/updateTMSBroker';
  return fetch(requestUrl,{
                method: 'GET',
                headers: {
                  'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                },
          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
