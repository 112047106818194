
import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { Col, Row } from "react-bootstrap"

const ActiveLoadCardHeader = (props) => {

    const { myLoadsRenderedTab = 0, isMyLoadsScreen = false } = props

    const classes = useStyles();

    const getActionColumnText = () => {
        switch (myLoadsRenderedTab) {
            case 0:
                return ""
            case 1:
                return "Quote"
            case 2:
                return ""
        }
    }

    return (

        <div>

            <Row style={{ display: 'flex', marginLeft: 0 }}>

                <Col style={{ width: '5%', display: 'flex' }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        
                     </Col>
                </Col>

                <Col style={{ width: "13%", display: 'flex' }}>    
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <p className={classes.headerText}>Origin Location</p>
                     </Col>
                 
                </Col>

                <Col style={{ width: "10%", display: 'flex' }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                    </Col>
                </Col>

                <Col style={{ width: "14%", display: 'flex' }}>
                
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}>Destination Location</p>
                        </Col>
                  
                </Col>

                <Col style={{ width: "5%", display: 'flex'}}>
                {/* <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                       <p className={classes.headerText}>Rate</p>
                   </Col> */}
                   {/* Temporarily hiding rate until my-loads is at the user/driver level */}
               </Col>

                <Col style={{ width: "15%", display: 'flex', justifyContent:'center' }}>
                   
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <p className={classes.headerText}>Reloads</p>
                    </Col>

                </Col>

                <Col style={{ width: "11%", display: 'flex' }}>
                    
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}> Equipment Type </p>
                        </Col>
               
                </Col>
                <Col style={{ width: "12%", display: 'flex' }}>
                    {/* <Col className={classes.centerColContent} style={{ alignItems: 'flex-start' }}>
                    <p className={classes.headerText}> Assignment Status </p>
                    </Col> */}
                </Col>
                <Col style={{ width: "10%", display: 'flex' }}>
                 
                        {/* <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}>Tracking Status</p>
                        </Col> */}
           
                </Col>
                <Col style={{ width: "5%", display: 'flex' }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
          
                            {/* <p className={classes.headerText}>Actions</p> */}
                        
                    </Col>
                </Col>

            </Row>

        </div>
    );
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(ActiveLoadCardHeader)

const useStyles = makeStyles({
    headerText: {
        // fontFamily: 'Arial !important',
        fontSize: '13px',
        color: 'black',
        margin: 0
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    }
});