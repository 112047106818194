import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { Card } from 'material-ui/Card';
import Switch from '@material-ui/core/Switch';
import * as FeatherIcon from 'react-feather';

import { checkValueExists } from "../../../../../../lib/check-value-exists"

const LanePreferencesCardMobile = (props) => {

    const classes = useStyles()

    const {
        muiTheme,
        item,
        handleViewLoads,
        availability,
        notificationsOn,
        setShowEditModal,
        handleToggleNotification,
        handleDeleteLanePreference
    } = props

    const getDaysOfWeek = (selectedDays) => {
        let returnArray = []
        if (selectedDays.length === 1 && selectedDays[0] === "ALL") {
            return selectedDays[0]
        }

        selectedDays.forEach(day => {
            switch (day) {
                case "SUN":
                    returnArray.push("SUN")
                    break
                case "MON":
                    returnArray.push("MON")
                    break
                case "TUE":
                    returnArray.push("TUE")
                    break
                case "WED":
                    returnArray.push("WED")
                    break
                case "THU":
                    returnArray.push("THU")
                    break
                case "FRI":
                    returnArray.push("FRI")
                    break
                case "SAT":
                    returnArray.push("SAT")
                    break
            }

        })

        return returnArray.join(", ")

    }

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: muiTheme.secondary,
                    borderColor: muiTheme.secondary,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const handleCaps = (textInput) => {
        if(textInput)
            return textInput.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    return (
        <Card style={{ width: "100%", flexDirection: "column", justifyContent: "center", borderRadius: 15 }}>

            <div style={{ padding: 15 }}>

                {/* <Row>
                    <p className={classes.mobileLabelText}>
                        Notifications:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                        <AntSwitch checked={notificationsOn} onChange={handleToggleNotification} />
                    </p>
                </Row> */}

                {checkValueExists(item, "daysOfWeek") && (

                    <Row>
                        <p className={classes.mobileLabelText}>
                            Pickup Day:{" "}
                        </p>
                        <p className={classes.mobileValueText}>
                            {getDaysOfWeek(item.daysOfWeek)}
                        </p>
                    </Row>

                )}

                <Row>
                    <p className={classes.mobileLabelText}>
                        Pickup location:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                        {item.origin.city ? handleCaps(item.origin.city) + ", " : ""}{item.origin.state}
                    </p>
                </Row>

                <Row style={{ display: 'flex'  }}>
                    <p className={classes.mobileLabelText}>
                        Preferred destinations:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                    {item.destination.city ? handleCaps(item.destination.city) + ", " : ""}{item.destination.state}
                    </p>
                </Row>
                <Row style={{ display: 'flex'  }}>
                {/* Temporarily removing rate */}
                    {/* <p className={classes.mobileLabelText}>
                        Rate:{" "}
                    </p>
                    {checkValueExists(item, "rate") && (
                        <p className={classes.mobileValueText}>${item.rate}{checkValueExists(item, "rateType") && item.rateType == "RPM" ? "/mile" : ""}</p>
                    )} */}
                </Row>

            </div>



            <Row style={{
                display: 'flex',
                width: '100%',
                height: '28%',
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: '#F6F6F6',
                margin: 0
            }}>

                <Button
                    style={{ width: '10%', textAlign: 'right' }}
                    onClick={() => handleDeleteLanePreference()}
                >
                    <FeatherIcon.Trash2 color={"black"} size={25} />
                </Button>

                <div style={{ width: '80%', textAlign: 'center' }}>
                {/* Button disabled for werner  */}
                    {/* <Button
                        variant={"contained"}
                        size={"small"}
                        className={classes.margin}
                        style={{ width: '80%', fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor }}
                        onClick={() => toggleVisibility()}
                    >
                        {availability ? "UNPUBLISH" : "PUBLISH"}
                    </Button> */}
                </div>

                {/* <div style={{ width: '80%', textAlign: 'center' }}>
                    <Link to={"/loads"}
                        onClick={() => {
                            handleViewLoads(item)
                            useGtagHook(
                                "saved_search_find_loads",
                                {
                                    location: item.pickupCity + ", " + item.pickupState,
                                    isRecurring: checkValueExists(item, "pickupWeekDay"),
                                    pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                    pickupTime: item.availableTime,
                                    destinations: item.destinations,
                                    equipments: item.truckTypes
                                }
                            )
                        }}
                    >
                        <Button
                            variant={"contained"}
                            size={"small"}
                            className={classes.margin}
                            style={{ width: '80%', fontSize: 14, color: "white", backgroundColor: muiTheme.secondary }}
                        >
                            Search
                        </Button>
                    </Link>
                </div> */}

                <Button
                    style={{ width: '10%', textAlign: 'right' }}
                    onClick={() => setShowEditModal(true)}
                >
                    <FeatherIcon.Edit color={"black"} size={25} />
                </Button>
            </Row>
        </Card>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(LanePreferencesCardMobile)

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    card: {
        display: "flex",
        height: 'auto',
        flexDirection: "row",
        alignItems: "center",
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height: 100
    },
    margin: {
        margin: 5,
    },
    mobileValueText: {
        width: 'calc(100% - 165px)',
        display: 'inline-block',
        float: 'right',
        color: 'black',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'right'
    },
    mobileLabelText: {
        fontWeight: 'bold',
        display: 'inline-block',
        color: 'black',
        width: 165,

    }
}));