import * as Constants from './constants'

const initialState = {
  requesting: false,
  successful: false,
  truckCodes: [],  
  id: 0,
  selectedLoad: {},
  offeredLoads: [],
  showToast: false,
}

const reducer = function offeredLoadReducer (state = initialState, action) {
  //console.log('reducer',action.type)
  switch (action.type) {
    
    case Constants.TRUCK_CODES_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Looking up your truck type codes...', time: new Date() }],
        errors: [],
        showToast: false
      }

    case Constants.TRUCK_CODES_SUCCESS:
      return {
        ...state,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        truckCodes: action.truckCodes,                
      }
      case Constants.SAVE_OFFERED_LOAD_REQUESTING:
        return {
          ...state,          
          requesting: true,
          successful: false,
          messages: [],
          showToast: false
        }       
      case Constants.SAVE_OFFERED_LOAD_SUCCESS:
        return {
          ...state,          
          requesting: false,          
          successful: true,  
          messages: [action.message],
          showToast: true       
        }
      case Constants.CANCEL_OFFERED_LOAD_SUCCESS:
        return {
          ...state,          
          requesting: false,          
          successful: true,  
          messages: [action.message],
          showToast: true       
        }
      case Constants.SAVE_OFFERED_LOAD_ERROR:
        return {
          ...state,          
          requesting: false,         
          successful: false,  
          messages: [action.message],
          showToast: true       
        }

    default:
      return state
  }
}

export default reducer