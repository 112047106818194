import { CLIENT_SET, CLIENT_UNSET, PUSHER_CONNECT } from './constants'

const initialSate = {  
  id: null,
  token: null,
  windowEcho: null
}

const reducer = function clientReducer (state = initialSate, action) {  
  switch (action.type) {
    case CLIENT_SET:     
      return Object.assign({}, state, {
        id: action.token.userId,
        token: action.token        
      });

    case CLIENT_UNSET:
      return {
        id: null,
        token: null,
        windowEcho:null
      }

      case PUSHER_CONNECT:            
       return Object.assign({}, state, {         
         windowEcho: action.windowEcho
       });
    default:
      return state
  }
}

export default reducer  