//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'
  import {toast} from 'react-toastify'

//APIs
  import {saveBrokerCommunication,getCommunications} from '../../api/communications'

function* saveQuoteFlow(action){
  try {
    const {loadId, carriers, quoteValue, message, sendEmail, brokers, availabilities, source} = action
    console.log(quoteValue);
    const response = yield call(saveBrokerCommunication,loadId, carriers, quoteValue, message, sendEmail, brokers, availabilities, source)
    if(action.callback && typeof action.callback === "function")
      action.callback(response)
    if(response.status){
      yield put({type:'SAVE_QUOTE_SUC',payload:response.quotes})
      toast.success('Your quote successfully saved')
      document.body.click();
    }
    else{
      yield put({type:'SAVE_QUOTE_ERR'})
      toast.error('We were unable to complete this action. Please try again. Contact Trucker Tools support@truckertools.com for help. Error Code: quoteSaga01')
    }

  } catch (error) {
    console.log(error)
    yield put({type:'SAVE_QUOTE_ERR'})
    toast.error('We were unable to complete this action. Please try again. Contact Trucker Tools support@truckertools.com for help. Error Code: quoteSaga02')

  }
}

function* getQuotesFlow(action){
  try {
    const {loadIds, carriers, ids, brokers, availabilities} = action
    const response = yield call(getCommunications,loadIds, carriers, ids, brokers, availabilities)
    if(response.status){
      yield put({type:'GET_QUOTE_SUC',payload:processQuotesResponse(response),response:response})
    }

  } catch (error) {
    console.log(error)

  }
}

function* templateWatcher () {
  yield takeLatest('SAVE_QUOTE_REQ', saveQuoteFlow)
  yield takeLatest('GET_QUOTE_REQ', getQuotesFlow)
}

export default templateWatcher

function processQuotesResponse(response){
  const {quotes,brokers,carriers,users,loads} = response
  var indexedBrokers = {}
  brokers.map((broker)=>indexedBrokers[broker.id]=broker)
  var indexedCarriers = {}
  carriers.map((carrier)=>indexedCarriers[carrier.id]=carrier)
  var indexedUsers = {}
  users.map((user)=>indexedUsers[user.id]=user)
  var indexedLoads = {}
  loads.map((load)=>indexedLoads[load.id]=load)
  var composedQuotes = []
  quotes.map((quote)=>{
    var thisQuote = JSON.parse(JSON.stringify({...quote}))
    thisQuote.broker=indexedBrokers[quote.broker_id]
    thisQuote.carrier=indexedCarriers[quote.carrier_id]
    thisQuote.user=indexedUsers[quote.user_id]
    thisQuote.load=indexedLoads[quote.load_id]
    composedQuotes.push(thisQuote)
  })
  return composedQuotes
}
