import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
    Link
} from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SimpleLineIcon from 'react-simple-line-icons';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import { Card } from 'material-ui/Card';
import { toast } from 'react-toastify';
import * as FeatherIcon from 'react-feather';
import FA from 'react-fontawesome'

// import EditSearchModal from "./edit-search-modal"

import { checkValueExists } from "../../../../../../lib/check-value-exists"
// import useGtagHook from '../../../gtag-hook';


const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    card: {
        display: "flex",
        height: 'auto',
        flexDirection: "row",
        alignItems: "center",
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height: 100
    },
    margin: {
        margin: 5,
    },
    mainText: {
        fontFamily: 'Arial !important',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        margin: 0
    }
}));

const LanePreferencesCard = (props) => {

    const classes = useStyles()

    const {
        muiTheme,
        item,
        handleViewLoads,
        availability,
        notificationsOn,
        setShowEditModal,
        handleToggleNotification,
        handleDeleteLanePreference

    } = props

    /* --- Popover state control --- */
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    /* ----------------------------- */

    const getDaysOfWeek = (selectedDays) => {
        let returnArray = []
        if (selectedDays.length === 1 && selectedDays[0] === "ALL") {
            return selectedDays[0]
        }

        selectedDays.forEach(day => {
            switch (day) {
                case "SUN":
                    returnArray.push("SUN")
                    break
                case "MON":
                    returnArray.push("MON")
                    break
                case "TUE":
                    returnArray.push("TUE")
                    break
                case "WED":
                    returnArray.push("WED")
                    break
                case "THU":
                    returnArray.push("THU")
                    break
                case "FRI":
                    returnArray.push("FRI")
                    break
                case "SAT":
                    returnArray.push("SAT")
                    break
            }

        })

        return returnArray.join(", ")

    }

    const handleCaps = (textInput) => {
        if (textInput)
            return textInput.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    return (
        <Card style={{ width: "100%", padding: 15, flexDirection: "column", justifyContent: "center" }}>
            <Row className={classes.card}>
                {/* <Col className={classes.col} style={{ width: "7%", alignItems: "center" }}>
                    <Checkbox
                        key="notification_checkbox"
                        style={{ color: muiTheme.secondary, transform: "scale(1.5)" }}
                        color="default"
                        checked={notificationsOn}
                        onClick={handleToggleNotification}
                    />
                </Col>
                <Col className={classes.col} style={{ width: "7%", alignItems: "center" }}>
                    <Checkbox
                            key="availability_checkbox"
                            style={{ color: muiTheme.secondary, transform: "scale(1.5)" }}
                            color="default"
                            checked={availability}
                            onClick={() => {
                                toggleVisibility()
                                // useGtagHook(
                                //     "toggle_availability",
                                //     {
                                //         action: availability ? "UNPUBLISH" : "PUBLISH",
                                //         location: item.pickupCity + ", " + item.pickupState,
                                //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                //         pickupTime: item.availableTime,
                                //         destinations: item.destinations,
                                //         equipments: item.truckTypes
                                //     }
                                // )
                            }}
                    />
                </Col> */}
                <Col className={classes.col} style={{ width: "23%", alignItems: "center" }}>
                    {checkValueExists(item, "daysOfWeek") && (
                        <Row>
                            <p className={classes.mainText}>
                                {getDaysOfWeek(item.daysOfWeek)}
                            </p>
                        </Row>
                    )}
                </Col>
                <Col className={classes.col} style={{ width: "7%", alignItems: "flex-start" }}>
                </Col>
                <Col className={classes.col} style={{ width: "20%", alignItems: "flex-start" }}>
                    <p className={classes.mainText}>{item.origin.city ? handleCaps(item.origin.city) + ", " : ""}{item.origin.state}</p>
                </Col>
                <Col className={classes.col} style={{ width: "7%", alignItems: "flex-start" }}>
                    <FA name='arrow-right' />
                </Col>
                <Col className={classes.col} style={{ width: "20%", alignItems: "flex-start" }}>
                    <p className={classes.mainText}>{item.destination.city ? handleCaps(item.destination.city) + ", " : ""}{item.destination.state}</p>
                </Col>
                <Col className={classes.col} style={{ width: "7%", alignItems: "flex-start" }}>
                {/* Temporarily removing rate */}
                    {/* {checkValueExists(item, "rate") && (
                        <p className={classes.mainText}>${item.rate}{checkValueExists(item, "rateType") && item.rateType == "RPM" ? "/mile" : ""}</p>
                    )} */}
                </Col>
                {/* <Col className={classes.col} style={{ width: "19%", alignItems: "flex-start" }}>
                    <p className={classes.mainText}>
                        {item.truckTypes && Object.keys(item.truckTypes).length > 0 ?
                            Object.keys(item.truckTypes).map((key, index) => index === Object.keys(item.truckTypes).length - 1 ? key : key + ", ") :
                            'No preference'}
                    </p>
                </Col> */}
                <Col className={classes.col} style={{ width: "13%", alignItems: "center" }}>
                    {/* <Button
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: muiTheme.actionColor, marginRight: 10, color: "#fff", fontSize: 14 }}
                        onClick={() => {
                            handleViewLoads(item)
                        }}
                    >
                        VIEW LOADS
                    </Button> */}
                </Col>
                <Col className={classes.col} style={{ width: "3%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", height: "100%", paddingTop: 15, paddingBottom: 15 }}>
                    <IconButton aria-describedby={id} onClick={handleClick} style={{ marginRight: 80 }}>
                        <SimpleLineIcon name={'options'} color={'black'} style={{ fontSize: 30 }} />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box p={1}>
                            <Button
                                onClick={() => {
                                    setShowEditModal(true)
                                    handleClose()
                                    // useGtagHook(
                                    //     "open_edit_saved_search",
                                    //     {
                                    //         location: item.pickupCity + ", " + item.pickupState,
                                    //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                    //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                    //         pickupTime: item.availableTime,
                                    //         destinations: item.destinations,
                                    //         equipments: item.truckTypes
                                    //     }
                                    // )
                                }}
                                startIcon={<FeatherIcon.Edit color={"black"} size={25} />}
                                style={{ width: '100%', display: "flex", justifyContent: "left" }}
                            >
                                <p className={classes.mainText} style={{ textTransform: 'none' }}> Edit </p>
                            </Button>
                            <br />
                            <Button
                                onClick={() => {
                                    handleDeleteLanePreference()
                                    handleClose()
                                    // useGtagHook(
                                    //     "delete_saved_search",
                                    //     {
                                    //         location: item.pickupCity + ", " + item.pickupState,
                                    //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                    //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                    //         pickupTime: item.availableTime,
                                    //         destinations: item.destinations,
                                    //         equipments: item.truckTypes
                                    //     }
                                    // )
                                }}
                                startIcon={<FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />}
                                style={{ width: '100%', display: "flex", justifyContent: "left" }}
                            >
                                <p className={classes.mainText} style={{ textTransform: 'none' }}> Delete </p>
                            </Button>
                        </Box>
                    </Popover>
                </Col>
            </Row>
        </Card>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(LanePreferencesCard)