//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'
  import { showLoading, hideLoading } from 'react-redux-loading-bar'

//MESSAGING
  import * as TYPE from './constants'

//APIs
  import {getTruckTypes} from '../api/truckTypes'

  import {addCarrierCapacity,getCarrierCapacity,deleteDriverAvailability,importCarriersAvailabilities} from '../api/driverAvailability'

  import {getStates} from '../api/states'

  function* importCapacityCall (action) {
	  try {

		const {importdata}= action;
		yield put(showLoading())
		//console.log('importCapacityCall')

		// here until the API async function, is complete!
		const response = yield call(importCarriersAvailabilities,importdata)


		yield put({ type: TYPE.CALL_IMPORT_CARRIER_CAPACITY_SUCCESS, response })


	  } catch (error) {
		// if the api call fails, it will "put" the LOADS_ERROR
		// into the dispatch along with the error.
		yield put({ type: TYPE.CALL_IMPORT_CARRIER_CAPACITY_ERROR, error })

	  }
	  finally{
		yield put(hideLoading())
	  }
 }


 function* addCapacityCall (action) {
  try {
  const {postData}= action;
  yield put(showLoading())
  //console.log('importCapacityCall')

  // here until the API async function, is complete!
  const response = yield call(addCarrierCapacity,postData)
  if(response.status){
    action.successCallback()
  }
  else{
     action.failureCallback()
  }


  yield put({ type: TYPE.CALL_ADD_CARRIER_CAPACITY_SUCCESS, response })


  } catch (error) {
  // if the api call fails, it will "put" the LOADS_ERROR
  // into the dispatch along with the error.
  yield put({ type: TYPE.CALL_ADD_CARRIER_CAPACITY_ERROR, error })

  }
  finally{
  yield put(hideLoading())
  }
}

function* getCapacityCall(action){
  try {

    yield put(showLoading())
    const response = yield call(getCarrierCapacity)

    yield put({type:TYPE.CALL_GET_CARRIER_CAPACITY_SUCCESS,response})

  } catch (error) {
      console.log(error)
      yield put({type:TYPE.CALL_GET_CARRIER_CAPACITY_ERROR ,  error:error })
  }
  finally{
    yield put(hideLoading())
  }
}


function* getStateCall(action){
  try {

    yield put(showLoading())
    const response = yield call(getStates)

    yield put({type:TYPE.CALL_STATE_LIST_SUCCESS,response})

  } catch (error) {
      console.log(error)
      yield put({type:TYPE.CALL_STATE_LIST_ERROR ,  error:error })
  }
  finally{
    yield put(hideLoading())
  }
}


function* deleteCapacityCall (action) {
  try {
    const { availabilityId} = action

    yield put(showLoading())

    // here until the API async function, is complete!
    var response
    response = yield call(deleteDriverAvailability, availabilityId)

    if(response.status){
      action.deleteCallback()
    }

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    yield put({ type: TYPE.CALL_DELETE_CAPACITY_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: TYPE.CALL_DELETE_CAPACITY_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}


function* addCapacityWatcher () {
  yield takeLatest(TYPE.CALL_IMPORT_CARRIER_CAPACITY, importCapacityCall)
  yield takeLatest(TYPE.CALL_ADD_CARRIER_CAPACITY, addCapacityCall)
  yield takeLatest(TYPE.CALL_GET_CARRIER_CAPACITY, getCapacityCall)
  yield takeLatest(TYPE.CALL_STATE_LIST, getStateCall)
  yield takeLatest(TYPE.CALL_DELETE_CAPACITY, deleteCapacityCall)
}

export default addCapacityWatcher
