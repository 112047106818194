
import React, { useState } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import isMobile from '../../../../../HOC/Mobile.js'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import CounterOfferForm from './counterOfferForm'



const CounterOfferButton = (props) => {
    const {
        load,
        muiTheme,
        key,
        quoteValue,
        isMobile,
        submitCounterOffer = () => { }
    } = props

    const classes = useStyles()

    const [showPopover, setShowPopover] = useState(false)
    const [counterAnchorEl, setCounterAnchorEl] = useState(null)

    return (
        <div>
            <Button
                key={"counter_offer_btn_" + key}
                id='counterOfferBtn'
                size="small"
                style={{ textDecoration: 'underline', color: '#3369B4', fontSize: 14, margin: 5 }}
                onClick={(event) => {
                    setCounterAnchorEl(event.currentTarget)
                    setShowPopover(true)
                }}
            >
                Counter
            </Button>
            <Popover
                id="counter_offer_popover"
                open={showPopover}
                anchorEl={counterAnchorEl}
                onClose={() => { setCounterAnchorEl(null); setShowPopover(false) }}
                // PaperProps={{ style: { width: isMobile ? '100%' : null } }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div style={{ padding: 15, width: isMobile ? 'auto' : 400, maxHeight: 800 }}>

                    <CounterOfferForm
                        load={load}
                        submitCounterOffer={submitCounterOffer}
                        _onClose={() => { setCounterAnchorEl(null); setShowPopover(false) }}
                        quoteValue={quoteValue}
                        {...props}
                    />

                </div>
            </Popover>
        </div>

    );
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(CounterOfferButton)

const useStyles = makeStyles({
    row: {
        padding: 0,
        margin: 0
    },
    centerRowContent: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }
});