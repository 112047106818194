import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../HOC/Mobile.js'
import { toast } from 'react-toastify';

import LoadingOverlay from '../../components/LoadingOverlay'
import * as InvoiceAPI from '../apis/api-invoice'
import Invoices from '../components/invoices';

const InvoicesScreen = (props) => {

    const [currentLoadList, setCurrentLoadList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [refreshInvoices, setRefreshInvoices] = useState(false)


    useEffect(() => {

        getAllCoveredLoads()
    }, [])

    const getAllCoveredLoads = () => {
        InvoiceAPI.GetAllCoveredLoads({advantageId: props.muiTheme.customerUniqueId}).then(response => {
            if (response.status) {
                if(response.loads && response.loads.length > 0){
                    
                    setCurrentLoadList(response.loads)
                    setRefreshInvoices(!refreshInvoices)
                }
            } else {
                toast.error(response.message)
            }
            setIsLoading(false)
        }).catch((error) => {
            let errorMessage = "Error: There was a problem fetching your invoices."
            toast.error(errorMessage)
            setIsLoading(false)
        })

    }

 

    return (
        <div style={{ height: "100%", width: "100%", overflow: "hidden", paddingBottom: props.isMobile ? 0 : 50 }}>
            {isLoading && <LoadingOverlay />}
            {(!isLoading && currentLoadList && currentLoadList.length > 0) ?
                <Invoices 
                    key={refreshInvoices}
                    currentLoadList={currentLoadList} 
                    isLoading={isLoading}
                    refreshInvoices={getAllCoveredLoads}
                />
                :
                <div style={{ paddingTop: 50 }}>
                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                        No Completed Loads Found
                    </p>
                </div>
            }
        </div>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(InvoicesScreen)