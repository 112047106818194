
import React , {useEffect,useState} from 'react';
import { compose, withProps } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col, Grid } from 'react-bootstrap';

import LocationItem from './location-item';

const LocationList = (props) => {

    const { locationsList, onDelete=null  , onEdit=null} = props
    const [dividedArray,updateDividedArray] = useState([[],[],[]])
    useEffect(()=>{
        let tmpArray = [...props.locationsList]
        let result = [];
        for (let i = 3; i > 0; i--) {
            result.push(tmpArray.splice(0, Math.ceil(tmpArray.length / i)));
        }
        updateDividedArray(result)
    },[locationsList])



    const processDeleteComplete = (flag)=>{
        if(onDelete && flag){
            onDelete(flag)
        }
    }

    const processEditComplete = (flag)=>{
        if(onEdit && flag){
            onEdit(flag)
        }
    }

    return (

        <Grid style={{ height: "100%", width: '100%', marginTop: 5 }}>
            {(dividedArray[0].length <= 0 && dividedArray[1].length <= 0 && dividedArray[2].length <= 0) ? (
                <div style={{ maxHeight: "100%", overflowX: "hidden", overflowY: "auto", display: "flex", justifyContent: "center" }}>
                    <h3>There are no saved locations associated with your account.</h3>
                </div>
            ) : (
                    <div style={{ maxHeight: "100%", overflowX: "hidden", overflowY: "auto", display: "flex" }}>
                        <Col style={{ width: "33%", height: "100%", marginLeft: 80, marginRight: 10 }}>
                            {dividedArray[0].map((loc) => {
                                return (
                                    <LocationItem key={loc.addressId} item={loc} onDelete={(flag)=>processDeleteComplete(flag)}  onEditComplete={(flag)=>processEditComplete(flag)} />
                                )
                            })}
                        </Col>
                        <Col style={{ width: "33%", height: "100%", marginLeft: 10, marginRight: 10 }}>
                            {dividedArray[1].map((loc) => {
                                return (
                                    <LocationItem key={loc.addressId} item={loc} onDelete={(flag)=>processDeleteComplete(flag)}  onEditComplete={(flag)=>processEditComplete(flag)} />
                                )
                            })}
                        </Col>
                        <Col style={{ width: "33%", height: "100%", marginLeft: 10, marginRight: 80 }}>
                            {dividedArray[2].map((loc) => {
                                return (
                                    <LocationItem key={loc.addressId} item={loc} onDelete={(flag)=>processDeleteComplete(flag)}  onEditComplete={(flag)=>processEditComplete(flag)}  />
                                )
                            })}
                        </Col>
                    </div>
                )}
        </Grid>
    );
}

export default compose(
    muiThemeable()
)(LocationList)