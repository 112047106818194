import React, { useCallback } from "react";
import TextField from '@material-ui/core/TextField';
import FA from 'react-fontawesome'

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;
const CurrencyInput = ({
  className = "",
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  invoiceForm = false,
  style = {},
  value
}) => {
  const valueAbsTrunc = Math.trunc(Math.abs(value));
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    console.log(`invalid value property`);
  }

  String.prototype.countDecimals = function () {
    return this.toString() !== "" ? this.toString().split(".")[1].length : 0; 
  }
  const handleChange = useCallback(
    e => {
      
      let key = e.target.value.charAt(e.target.value.length - 1)
      let keyCode = key.charCodeAt(0)
     
      if(e.target.value.replace(/,/g, '').countDecimals() === 1 || e.target.value.replace(/,/g, '').countDecimals() === 0 || e.target.value == 0){
        key = "Backspace"
        keyCode = 8
      }

      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onValueChange(nextValue);
    },
    [max, onValueChange, value]
  );
  let valueDisplay = (value / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
  valueDisplay = valueDisplay.replace("$","")
  return (

<div style={{position: 'relative', display: 'inline-block', width:'100%'}}>
        <FA name='dollar-sign' style={{position: 'absolute', left: 0, top: 5, width: 20, height: 20}}/>
        <TextField
          inputProps={{ style: { textIndent: 25, fontSize: 16, border: '1px solid #ccc' , height: invoiceForm ? 20 : 'unset' } }}
          fullWidth={true}
          value={valueDisplay}
          onChange={handleChange}
        />
      </div>

  );
};

export default CurrencyInput;
