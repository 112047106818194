//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'
  import {toast} from 'react-toastify';
//APIs
  import {getOutOfNetworkLoads} from '../../../api/loads'

function* getOutOfNetworkLoadsFlow(action){
  try {
    const response = yield call(getOutOfNetworkLoads,action.availabilityId)
    if(response.status){
      yield put({type:'UPDATE_OUT_OF_NETWORK_LOADS_SUC',payload:response})
    }else {
      yield put({type:'UPDATE_OUT_OF_NETWORK_LOADS_ERR',payload:response})
    }

  } catch (error) {
    console.log(error);
    yield put({type:'UPDATE_OUT_OF_NETWORK_LOADS_ERR',error:error})

  }
}

function* templateWatcher () {
  yield takeLatest('UPDATE_OUT_OF_NETWORK_LOADS_REQ', getOutOfNetworkLoadsFlow)
}

export default templateWatcher;
