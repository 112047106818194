import React from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Paper from '@material-ui/core/Paper';

import {
    isAndroid,
    isIOS,
    isSafari,
    isChrome,
    isSamsungBrowser
} from "react-device-detect"

const LocationSettingsScreen = (props) => {

    const { muiTheme } = props

    const useStyles = makeStyles({
        container: {
            padding: 10,
            height: '100%',
            width: '100%'
        },
        title: {
            textAlign: "left",
            color: "black"
        },
        instruction: {
            textAlign: "left",
            marginTop: 25,
            color: "black"
        },
        textLink: {
            color: muiTheme.actionColor
        }

    });
    const classes = useStyles()

    const androidBody = (
        <div>
            {/* {!isChrome && (
                <div style={{ marginBottom: 15 }}>
                    <p className={classes.title} style={{ fontSize: 16, color: muiTheme.ttRed }}>For Android devices, you must use the <span style={{ fontWeight: "bold" }}>Chrome</span> browser app to create a shortcut to <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a> on your mobile home screen.</p>
                </div>
            )} */}
            <p className={classes.title}>By allowing <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a> to access your location we will be able to provide you with features and information such as nearby available loads, nearby fuel stops, accurate deadhead calculations and much more!</p>
            <p className={classes.title} style={{ marginTop: 15 }}>Follow these simple steps to set your location settings for EL Atlas Now.</p>
            <p className={classes.instruction}>Step 1. Open the settings application and select 'Apps' from the menu options</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/location-settings/android-step-1.jpg")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>Step 2. Scroll down until you find {isChrome ? "the 'Chrome' app" : isSamsungBrowser ? "the 'Samsung Internet' app" : "your browser app"} and select it</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    {isSamsungBrowser ? (
                        <img src={require("../../assets/location-settings/android-step-2-samsung.jpg")} width={"100%"} />
                    ) : (
                        <img src={require("../../assets/location-settings/android-step-2-chrome.jpg")} width={"100%"} />
                    )}
                </Paper>
            </div>
            <p className={classes.instruction}>Step 3. Select 'Permissions' from the menu options</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    {isSamsungBrowser ? (
                        <img src={require("../../assets/location-settings/android-step-3-samsung.jpg")} width={"100%"} />
                    ) : (
                        <img src={require("../../assets/location-settings/android-step-3-chrome.jpg")} width={"100%"} />
                    )}
                </Paper>
            </div>
            <p className={classes.instruction}>Step 4. Select 'Location' from the menu options</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    {isSamsungBrowser ? (
                        <img src={require("../../assets/location-settings/android-step-4-samsung.jpg")} width={"100%"} />
                    ) : (
                        <img src={require("../../assets/location-settings/android-step-4-chrome.jpg")} width={"100%"} />
                    )}
                </Paper>
            </div>
            <p className={classes.instruction}>Step 5. Select 'Allow only while using the app' from the menu options</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    {isSamsungBrowser ? (
                        <img src={require("../../assets/location-settings/android-step-5-samsung.jpg")} width={"100%"} />
                    ) : (
                        <img src={require("../../assets/location-settings/android-step-5-chrome.jpg")} width={"100%"} />
                    )}
                </Paper>
            </div>
            <p className={classes.instruction}>You’re all done! You have completed the how to for location settings while using <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a></p>
        </div>
    )

    const iosBody = (
        <div>
            {/* {!isSafari && (
                <div style={{ marginBottom: 15 }}>
                    <p className={classes.title} style={{ fontSize: 16, color: muiTheme.ttRed }}>For iPhone devices, you must use the <span style={{ fontWeight: "bold" }}>Safari</span> app in order to create a shortcut to <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a> on your mobile home screen.</p>
                </div>
            )} */}
            <p className={classes.title}>By allowing <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a> to access your location we will be able to provide you with features and information such as nearby available loads, nearby fuel stops, accurate deadhead calculations and much more!</p>
            <p className={classes.title} style={{ marginTop: 15 }}>Follow these simple steps to set your location settings for EL Atlas Now.</p>
            <p className={classes.instruction}>Step 1. Open the settings application and select 'Privacy' from the menu options</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/location-settings/iOS-step-1.png")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>Step 2. Ensure that you have 'Location Services' turned ON then select it</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/location-settings/iOS-step-2.png")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>Step 3. Scroll down until you find {isSafari ? "'Safari Websites'" : isChrome ? "the 'Chrome' app" : "your browser app"} and select it</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    {isChrome ? (
                        <img src={require("../../assets/location-settings/iOS-step-3-chrome.png")} width={"100%"} />
                    ) : (
                        <img src={require("../../assets/location-settings/iOS-step-3-safari.png")} width={"100%"} />
                    )}
                </Paper>
            </div>
            <p className={classes.instruction}>Step 4. Select 'While Using the App' from the menu options</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    {isChrome ? (
                        <img src={require("../../assets/location-settings/iOS-step-4-chrome.png")} width={"100%"} />
                    ) : (
                        <img src={require("../../assets/location-settings/iOS-step-4-safari.png")} width={"100%"} />
                    )}
                </Paper>
            </div>
            {isSafari && (
                <div>
                    <p className={classes.instruction}>Step 5. Return to the main Settings page and scroll down until you see the 'Safari' app and select it</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                            <img src={require("../../assets/location-settings/iOS-step-5.png")} width={"100%"} />
                        </Paper>
                    </div>
                    <p className={classes.instruction}>Step 6. Select 'Location' from the menu options listed under 'SETTINGS FOR WEBSITES'</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                            <img src={require("../../assets/location-settings/iOS-step-6.png")} width={"100%"} />
                        </Paper>
                    </div>
                    <p className={classes.instruction}>Step 7. Select 'englandlogistics.truckertools.com' from the menu options</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                            <img src={require("../../assets/location-settings/iOS-step-7.png")} width={"100%"} />
                        </Paper>
                    </div>
                    <p className={classes.instruction}>Step 8. Select 'Allow' from the menu options</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                            <img src={require("../../assets/location-settings/iOS-step-8.png")} width={"100%"} />
                        </Paper>
                    </div>
                </div>
            )}
            <p className={classes.instruction}>You’re all done! You have completed the how to for location settings while using <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a></p>
        </div>
    )

    return (
        <div className={classes.container}>
            <div style={{ height: '100%', overflowY: "scroll" }}>
                {isAndroid ? androidBody : isIOS ? iosBody : null}
            </div>
        </div>
    )
}

export default compose(
    muiThemeable()
)(LocationSettingsScreen)