export const TRUCK_TYPES_REQUESTING = 'TRUCK_TYPES_REQUESTING'


export const GET_NEARBY_LOADS = 'GET_NEARBY_LOADS'
export const GET_LOAD_BY_LOAD_NUMBER = 'GET_LOAD_BY_LOAD_NUMBER'
export const CLEAR_LOAD_LIST = 'CLEAR_LOAD_LIST'
export const RESET_LOAD_LIST = 'RESET_LOAD_LIST'

export const LOAD_SEARCH_REQ = 'LOAD_SEARCH_REQ'
export const LOAD_SEARCH_SUC = 'LOAD_SEARCH_SUC'
export const LOAD_SEARCH_ERR = 'LOAD_SEARCH_ERR'

export const CARRIER_MATCHES_REQ = 'CARRIER_MATCHES_REQ'
export const CARRIER_MATCHES_SUC = 'CARRIER_MATCHES_SUC'
export const CARRIER_MATCHES_ERR = 'CARRIER_MATCHES_ERR'

export const UPDATE_AVAILABILITY_REQ = 'UPDATE_AVAILABILITY_REQ'
export const UPDATE_AVAILABILITY_SUC = 'UPDATE_AVAILABILITY_SUC'
export const UPDATE_AVAILABILITY_ERR = 'UPDATE_AVAILABILITY_ERR'

export const TOGGLE_MAP_LOADING = 'TOGGLE_MAP_LOADING'
export const TOGGLE_SEARCH_BOX = 'TOGGLE_SEARCH_BOX'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const UPDATE_SEARCH_VALUES = 'UPDATE_SEARCH_VALUES'

export const CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL = 'CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL'
export const CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_SUCCESS = 'CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_SUCCESS'
export const CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_ERROR = 'CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_ERROR'

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'

export const SET_BOOK_IT_NOW_FILTER = 'SET_BOOK_IT_NOW_FILTER'
export const SET_EQUIPMENT_FILTER = 'SET_EQUIPMENT_FILTER'
export const SET_BROKER_FILTER = 'SET_BROKER_FILTER'
export const SET_DESTINATION_FILTER = 'SET_DESTINATION_FILTER'
export const SET_REVENUE_CODE_FILTER = 'SET_REVENUE_CODE_FILTER'
export const SET_ORIGIN_FILTER = 'SET_ORIGIN_FILTER'
export const SET_DEADHEAD_FILTER = 'SET_DEADHEAD_FILTER'
export const SET_RELOADS_FILTER = 'SET_RELOADS_FILTER'
export const SET_WEIGHT_FILTER = 'SET_WEIGHT_FILTER'
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS'

export const SET_FUEL_FRANCHISE_FILTER = 'SET_FUEL_FRANCHISE_FILTER'
export const SET_FUEL_AMENITY_FILTER = 'SET_FUEL_AMENITY_FILTER'
export const SET_FUEL_DEADHEAD_FILTER = 'SET_FUEL_DEADHEAD_FILTER'
export const SET_FUEL_DIESEL_FILTER = 'SET_FUEL_DIESEL_FILTER'
export const RESET_ALL_FUEL_FILTERS = 'RESET_ALL_FUEL_FILTERS'

export const SET_WINDOW_LOCATION = 'SET_WINDOW_LOCATION'

export const SET_BRAND_FILTER = 'SET_BRAND_FILTER'
export const SET_POSITION_FILTER = 'SET_POSITION_FILTER'
export const SET_MODEL_FILTER = 'SET_MODEL_FILTER'
export const SET_SIZE_FILTER = 'SET_SIZE_FILTER'
export const SET_PLY_FILTER = 'SET_PLY_FILTER'
export const RESET_ALL_TIRE_FILTERS = 'RESET_ALL_TIRE_FILTERS'