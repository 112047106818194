/*global google*/
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment';
import { handleApiErrors } from '../lib/api-errors'
import {toast} from 'react-toastify'
import {
  CALL_PREFERENCES_DATA,
  CALL_PREFERENCES_DATA_SUCCESS,
  CALL_PREFERENCES_DATA_ERROR,
  UPDATE_PREFERENCES_DATA,
  UPDATE_PREFERENCES_DATA_SUCCESS,
  UPDATE_PREFERENCES_DATA_ERROR,
} from './constants'
import {GetPreferences,UpdatePreferences} from '../api/preferencesdata'




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* getPreferencesDataFlow (action) {
  try {
    yield put(showLoading())
    //console.log('...',action);
    // here until the API async function, is complete!
    const response = yield call(GetPreferences,action.email)
    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.

    yield put({ type: CALL_PREFERENCES_DATA_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: CALL_PREFERENCES_DATA_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* updatePreferencesDataFlow (action) {
  try {

    const {data}= action;
    yield put(showLoading())

    // here until the API async function, is complete!

    const response = yield call(UpdatePreferences,data)
    // console.log("response is...", response);
    if(response.status){
        toast.success(response.message)
    }


    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.

    //yield put({ type: UPDATE_PREFERENCES_DATA_SUCCESS, response })

    action.updateCallback()
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: UPDATE_PREFERENCES_DATA_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the LOADS_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* getPreferencesWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(CALL_PREFERENCES_DATA, getPreferencesDataFlow)
  yield takeLatest(UPDATE_PREFERENCES_DATA, updatePreferencesDataFlow)
}

export default getPreferencesWatcher
