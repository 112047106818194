
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';
import { Col, Row } from "react-bootstrap"
import isMobile from '../../../HOC/Mobile.js'

import InvoiceActionContainer from './invoice-action-container';


const NoLoadFoundCard = (props) => {

    const {
        load,
        isMobile
    } = props

    const useStyles = makeStyles({
        card: {
            minWidth: 275,
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.08,
            marginTop: !isMobile ? 10 : 'unset',
            marginLeft: !isMobile ? 15 : 'unset',
            marginRight: !isMobile ? 10 : 'unset',
            marginBottom: 0
        },
        button: {
            backgroundColor: 'white',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5

        }
    });
    const classes = useStyles();

    return (

        <div style={{ marginBottom: 15 }}>
            <Card className={classes.card} style={{ borderRadius: isMobile ? 15 : 'unset', }}>
                <CardContent style={{padding: isMobile ? 0 : 16}}>

                    {isMobile ?
                        <div>
                            <Row style={{ width: '100%', padding: 5 }}>
                                <p style={{ fontSize: 24, fontWeight: 'bold', fontStyle: 'italic', color: 'grey', textAlign: 'center' }}>
                                    No Load Information Found
                                </p>
                            </Row>
                            <Row style={{ width: '100%', padding: 5, marginBottom: 5 }}>
                                {props.onInvoicesScreen &&
                                    <p style={{ fontSize: 18, fontWeight: 'bold', color: 'grey', textAlign: 'center' }}>
                                        Invoice# {load.invoice.invoice_number}
                                    </p>
                                }
                            </Row>
                            <Row style={{
                                display: 'flex',
                                width: '100%',
                                height: '28%',
                                borderBottomLeftRadius: 15,
                                borderBottomRightRadius: 15,
                                backgroundColor: '#F6F6F6',
                                margin: 0,
                                justifyContent: 'space-between'
                            }}>
                                {props.onInvoicesScreen &&
                                    <InvoiceActionContainer
                                        load={load}
                                    />
                                }
                            </Row>
                        </div>
                        :
                        <Row style={{ display: 'flex' }}>

                                <Col style={{ display: 'flex', width: '4.3%' }}>

                                </Col>

                                <Col style={{ width: "40.7%" }}>
                                    <p style={{ lineHeight: '75px', fontSize: 24, fontWeight: 'bold', fontStyle: 'italic', color: 'grey' }}>
                                        No Load Information Found
                                </p>
                                </Col>



                                <Col style={{ width: "55%" }}>

                                    <Col md={9} className={classes.centerColContent} style={{ alignItems: 'flex-end', textAlign: 'left' }}>
                                        <Row style={{ width: '100%' }}>
                                            {props.onInvoicesScreen &&
                                                <p style={{ lineHeight: '75px', fontSize: 24, fontWeight: 'bold', color: 'grey' }}>
                                                    Invoice #{load.invoice.invoice_number}
                                                </p>
                                            }
                                        </Row>
                                    </Col>

                                    <Col md={3} style={{ alignItems: 'center', textAlign: 'center' }}>
                                        {props.onInvoicesScreen &&
                                            <InvoiceActionContainer
                                                load={load}
                                            />
                                        }
                                    </Col>


                                </Col>

                            </Row>
                    }
                </CardContent>
            </Card>
        </div>
    );
}


export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(NoLoadFoundCard)