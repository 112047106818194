import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import isMobile from '../../../../HOC/Mobile.js'

import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';
import FuelDetailsMobile from '../fuel-stops-cards/expanded-content/details-mobile'

// import { checkValueExists } from "../../../lib/check-value-exists"
import FuelStopCardDesktop from './fuel-stop-card-desktop';
import FuelStopCardMobile from './fuel-stop-card-mobile';

import useGtagHook from '../../../../gtag-hook';

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    card: {
        // minWidth: 275,
        width: "100%",
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        marginTop: 10,
        marginLeft: 15,
        marginRight: 10,
        marginBottom: 0,
        '&:hover': {
            boxShadow: '0 2px 7px rgba(0,0,0,28%), 0 2px 5px rgba(0,0,0,28%) !important'
        },
        cursor: 'pointer'
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    }
}));

const FuelStopItem = (props) => {

    const classes = useStyles()

    const { muiTheme, item, itemIndex, isMobile = false, searchLocation } = props

    const [icon, setIcon] = useState('arrow-down')
    const [isExpanded, setIsExpanded] = useState(false)
    const [openDetails, setOpenDetails] = useState(false)

    const [sendGtagEvent] = useGtagHook()

    useEffect(() => {

        if (isExpanded) {
            setIcon('chevron-down')
        } else
            setIcon('chevron-right')

    }, [isExpanded])

    useEffect(() => {
        if (openDetails) {
            // mobile log
            sendGtagEvent("view_fuel_stop", {
                searchLocation: searchLocation,
                franchiseName: item.franchiseName,
                name: item.name,
                id: item.id
            })
        }
    }, [openDetails])

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded)
        if (!isExpanded === true) {
            // desktop log
            sendGtagEvent("view_fuel_stop", {
                searchLocation: searchLocation,
                franchiseName: item.franchiseName,
                name: item.name,
                id: item.id
            })
        }
    }


    return (
        <div >
            {openDetails &&
                <FuelDetailsMobile
                    item={item}
                    setOpenDetails={setOpenDetails}
                    searchLocation={searchLocation}
                />
            }
            <div className={classes.searchCardRow}>
                {!isMobile ?
                    <Card className={classes.card} onClick={toggleExpanded}>
                        <CardContent>
                            <FuelStopCardDesktop
                                item={item}
                                index={itemIndex}
                                dropDownIcon={icon}
                                isExpanded={isExpanded}
                                toggleExpanded={toggleExpanded}
                                searchLocation={searchLocation}
                            />
                        </CardContent>
                    </Card>
                    :

                    <Card className={classes.card} style={{ borderRadius: 15 }}>
                        <CardContent style={{ padding: 0 }}>
                            <FuelStopCardMobile
                                item={item}
                                index={itemIndex}
                                setOpenFuelStopDetails={setOpenDetails}
                            />
                        </CardContent>
                    </Card>

                }
            </div>
        </div>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(FuelStopItem)