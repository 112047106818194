import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {zonesData,anyWhereZone} from '../../LoadSearchBar/zones';

import HelpWrapper from '../../HelpWrapper'



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ZoneSelect = ({
  muiTheme,
  inputTransparentStyle,
  handleSetSelectedZone,
  existingZone,
  mobileView = false,
  hasAnyLocation = false,
  ...props }) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275,
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.08,
      margin: 10
    },
    wrapper: {
      display: 'contents',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    selectfieldStyleOutline: {
      border: '1px solid #ccc',
      height: '45px',
      width: '100%',
      margin: '0px',
      backgroundColor: 'white',
      paddingLeft: '10px',
      paddingRight: '0px',
      fontSize: '16px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    menuItem: {
      fontSize: '16px'
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem'
    },
    removePadding: {
      padding: '0 !important'
    }
  }));
  const classes = useStyles();

  // let outlined = inputOutlineStyle; // this variable controls the styling of the state select true outlines the input field and adjusts sizing

  // let zonesData = [
  //   { id: 66, name: "Zone 0 (CT, ME, MA, NH, NJ, RI, VT)", abbr: "Z0", isSelected: false },
  //   { id: 67, name: "Zone 1 (DE, NY, PA)", abbr: "Z1", isSelected: false },
  //   { id: 68, name: "Zone 2 (MD, NC, SC, VA, WV)", abbr: "Z2", isSelected: false },
  //   { id: 69, name: "Zone 3 (AL, FL, GA, MS, TN)", abbr: "Z3", isSelected: false },
  //   { id: 70, name: "Zone 4 (IN, KY, MI, OH)", abbr: "Z4", isSelected: false },
  //   { id: 71, name: "Zone 5 (IA, MN, MT, ND, SD, WI)", abbr: "Z5", isSelected: false },
  //   { id: 72, name: "Zone 6 (IL, KS, MO, NE)", abbr: "Z6", isSelected: false },
  //   { id: 73, name: "Zone 7 (AR, LA, OK, TX)", abbr: "Z7", isSelected: false },
  //   { id: 74, name: "Zone 8 (AZ, CO, ID, NV, NM, UT, WY)", abbr: "Z8", isSelected: false },
  //   { id: 75, name: "Zone 9 (CA, OR, WA, AK)", abbr: "Z9", isSelected: false },
  //   { id: 87, name: "Zone E (NB, NL, NS, PE)", abbr: "ZE", isSelected: false },
  //   { id: 88, name: "Zone C (ON, PQ)", abbr: "ZC", isSelected: false },
  //   { id: 89, name: "Zone W (AB, BC, MB, SK)", abbr: "ZW", isSelected: false },
  //   { id: 90, name: "Zone M Mexico", abbr: "ZM", isSelected: false }
  // ];

  // const [hasError, setErrors] = useState(false);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);



  useEffect(() => {

    setZones(zonesData);

    if (existingZone) {
      setSelectedZone(existingZone)
    }


    return () => {
      setZones([]);
      setSelectedZone(null);
    }
  }, []);


  useEffect(() => {
    setZones(zonesData);
    if (existingZone) {
      setSelectedZone(existingZone)
    }
  }, [existingZone]);


  function handleChange(event) {
    // 'value' here represents the truck type ids selected from dropdown as in the value of the event target
    const { value } = event.target;
    let chosenZone = value

    setSelectedZone(chosenZone);
    handleSetSelectedZone(chosenZone)
  };

  function renderSelectedZone(selected) {
    if (selectedZone === null) {
      return (
        <div className={classes.wrapper} style={{ fontSize: 14, color: "#aaa" }}>
          {
            'Select Zone'
          }
        </div>
      )
    }
    else {
      return (
        <div className={classes.wrapper}>
          {
            selectedZone.name
          }
        </div>
      )
    }
  }

  return (
    <HelpWrapper text='Select the destination states you are interested in finding loads toward.(Click anywhere outside once finished)' location={mobileView ? 'bottom' : 'right'} zIndex={1350} enabled={!menuOpen}>
      <FormControl variant="filled" className={classes.formControl} fullWidth required >
          <InputLabel shrink={selectedZone} style={{fontSize:16}} id="location">Select Zone</InputLabel>
              <Select
                //className={classes.selectfieldStyleOutline}
                labelId="select-label"
               // disableUnderline={true}
                //autoWidth={true}
               // displayEmpty
                value={selectedZone}
                onChange={(event) => handleChange(event)}
                // native={false}
                renderValue={(selected) => renderSelectedZone(selected)}
                style={{ minWidth: mobileView ? '100%' : 180 }}
                // MenuProps={{
                //   disableScrollLock: true,
                //   getContentAnchorEl: null,
                //   anchorOrigin: {
                //     vertical: "bottom",
                //     horizontal: "left",
                //   }
                // }}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
              >
                {/* <MenuItem
                  className={classes.menuItem}
                  style={{color: "#aaa"}}
                  value=""
                  disabled>
                  Select Zone
                  </MenuItem> */}
                {hasAnyLocation && (
                        <MenuItem
                            key={anyWhereZone.id}
                            className={classes.menuItem}
                            value={anyWhereZone}
                            >
                            <b>{anyWhereZone.name}</b>
                        </MenuItem>
                    )}
                {zones.map((zone) => {
                  return (
                    <MenuItem
                      key={zone.id}
                      value={zone}
                      className={classes.menuItem}
                    >
                      {zone.name}
                      {/* <Checkbox checked={selectedZone === zone} />
                      <ListItemText classes={{primary:classes.menuItem}} primary={zone.name} /> */}
                    </MenuItem>)
                })}
              </Select>
      </FormControl>
    </HelpWrapper>
  )
}

export default ZoneSelect;
