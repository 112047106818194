import {BACKEND_URL} from './constants.js'
import numeral from 'numeral'
export function saveBrokerCommunication(loadId, carriers, quoteValue, message, sendEmail, brokers, availabilities, source){
  var requestUrl=BACKEND_URL+'/communications'
  var payload = {
    loadId:loadId,
    carriers:carriers,
    value:numeral(quoteValue).format('0[.]00')>0?numeral(quoteValue).format('0[.]00'):null,
    message:message,
    sendEmail:sendEmail,
    brokers:brokers,
    availabilities,
    source
  }
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function getCommunications(loadIds, carriers, ids, brokers, availabilities){
  var requestUrl=BACKEND_URL+'/communications?'
  if(carriers){
    var carriersString = carriers.join(',')
    requestUrl+='carrier='+carriersString+"&"
  }
  if(loadIds){
    var loadsString = loadIds.join(',')
    requestUrl+='load='+loadsString+"&"
  }
  if(ids){
    var idsString = ids.join(',')
    requestUrl+='id='+idsString+"&"
  }
  if(brokers){
    var brokersString = brokers.join(',')
    requestUrl+='broker='+brokersString+"&"
  }
  if(availabilities){
    var availabilitiesString = availabilities.map((avail)=>avail.availability_id).join(',')
    requestUrl+='availability='+availabilitiesString
  }
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
