import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { withRouter } from "react-router-dom"
import InvoiceForm from '../components/invoices/invoice-form'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';


const InvoiceFormScreenMobile = (props) => {
    const classes = useStyles();

    const {
        load,
        allowEdit,
        allowSubmit,
        currentStatus,
        statusFormatted,
        statusMessage,
        actionColor,
    } = props.location.state

    const [modalStyle] = React.useState(getModalStyle);

    const [openModal, setOpenModal] = useState(false)

    const handleGoBack = (onSubmit) => {
        if(onSubmit)
            props.history.goBack()
        else
            setOpenModal(true)
    }

    const confirmationDialog = (

        <div style={{ width: '100%', padding: 15 }}>
            <Col style={{ width: '100%', marginBottom: 10 }}>
                <p style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>Are you sure you want to continue? All unsaved changes will be lost.</p>
            </Col>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button
                    aria-describedby={"invoice_save"}
                    variant={"text"}
                    onClick={() => setOpenModal(false)}
                    style={{
                        fontSize: 14,
                        color: actionColor,
                        backgroundColor: "white",
                        borderColor: actionColor,
                        border: '1px solid',
                        margin: 5
                    }}
                    size={"medium"}
                    disableRipple={false}
                >

                    Cancel
                </Button>
                <Button
                    aria-describedby={"invoice_submit"}
                    variant={"text"}
                    onClick={() => props.history.goBack()}
                    style={{
                        fontSize: 14,
                        color: "white",
                        backgroundColor: actionColor,
                        borderColor: actionColor,
                        margin: 5
                    }}
                    size={"medium"}
                    disableRipple={false}
                >

                    Close
                </Button>
            </div>
        </div>
    )

    return (
        <div style={{ height: "100%", width: "100%", overflow: "hidden", paddingBottom: 0 }}>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="invoice-modal"
            >
                <div style={modalStyle} className={classes.paper}>
                    {confirmationDialog}
                </div>
            </Modal>

            <InvoiceForm
                load={load}
                handleCloseModal={handleGoBack}
                allowEdit={allowEdit}
                allowSubmit={allowSubmit}
                currentStatus={currentStatus}
                statusFormatted={statusFormatted}
                statusMessage={statusMessage}
            />


        </div>
    )
}


export default compose(
    muiThemeable(),
    withRouter
)(InvoiceFormScreenMobile)

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '85%',
        maxWidth: 950,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5]
    }
}));