
import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { Col, Row } from "react-bootstrap"

const LoadCardHeader = (props) => {

    const classes = useStyles();

    return (

        <div>

            <Row style={{ display: 'flex' }}>

                <Col style={{ display: 'flex', width: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '1.3%' : "4.3%" }}>
                    
                </Col>

                {process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && (
                    <Col style={{ width: 100, paddingLeft: 10, display: 'flex', alignItems: "center", }}>
                        
                    </Col>
                )}

                <Col style={{ width: "40.7%" }}>
                    <Row>
                        <Col md={4} className={classes.centerColContent} style={{alignItems:'left', textAlign: 'left'}}>
                            <Row>
                                <p className={classes.headerText}>
                                    Origin Location
                                </p>
                            </Row>
                        </Col>


                        <Col md={4} className={classes.centerColContent} style={{ alignItems: 'center', padding: "5px" }}></Col>

                        {/* <Col md={2} className={classes.centerColContent} style={{ alignItems: 'center', padding: "5px" }}>
                        <hr style={{ marginTop: 6, marginBottom: 10, width: '100%', border: '1px solid black', visibility: 'hidden' }} />
                        </Col>

                        <Col md={1} className={classes.centerColContent} style={{ alignItems: 'center', padding: "5px" }}></Col> */}


                        <Col md={4} className={classes.centerColContent} style={{alignItems:'left', marginLeft: -4}}>
                            <Row>
                                <p className={classes.headerText}>
                                   Destination Location
                                </p>
                            </Row>
                        </Col>
                    </Row>
                </Col>



                <Col style={{ width: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? "50%" : "55%" }}>
                    <Col md={3} className={classes.centerColContent} style={{ alignItems: 'center', marginLeft: -10 }}>
                        { !props.onInvoicesScreen &&
                            <p className={classes.headerText}>
                                Reloads
                            </p>
                        }
                    </Col>

                    <Col md={3} className={classes.centerColContent} style={{alignItems:'flex-end', textAlign: 'left'}}>
                        <Row style={{ width: '100%' }}>
                            <p className={classes.headerText}>
                               Equipment / Weight
                            </p>
                        </Row>
                    </Col>


                    <Col md={3} className={classes.centerColContent} style={{ alignItems:'flex-start', paddingLeft: 15 }}>
                        <Row>
                            <p className={classes.headerText}>
                              Rate
                            </p>
                        </Row>
                    </Col>


                    <Col md={3}>
                        
                    </Col>


                    {/* <Col md={1} className={classes.centerColContent}>

                    </Col> */}


                </Col>

            </Row>

        </div>

    );
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(LoadCardHeader)

const useStyles = makeStyles({
    headerText: {
        // fontFamily: 'Arial !important',
        fontSize: '13px',
        color: 'black',
        margin: 0
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    }
});