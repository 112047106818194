import React, { useState, useContext } from 'react';
import { compose } from 'recompose';
import isMobile from '../../../../HOC/Mobile.js';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import * as FeatherIcon from 'react-feather';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import TireCardDesktop from './tire-card-desktop';
import TireCardMobile from './tire-card-mobile';

import { TireScreenContext } from '../context/index';
import useGtagHook from '../../../../gtag-hook';

const TireItem = (props) => {

    const classes = useStyles()

    const { muiTheme, item, itemIndex, isMobile = false, isCartVersion } = props

    const context = useContext(TireScreenContext)

    const [itemQuantity, setItemQuantity] = useState(item.quantity ? item.quantity : 1);

    const [sendGtagEvent] = useGtagHook()

    const handleToggleSelected = () => {
        context.functions.handleSelectItem({ ...item, ...{ quantity: itemQuantity } })
        sendGtagEvent("tires_add_to_cart", {
            tireName: item.name
        })
    };

    const handleDelete = () => {
        context.functions.handleDeleteItem(item)
        sendGtagEvent("tires_delete_from_cart", {
            tireName: item.name
        })
    }

    const handleSetQuantity = (data) => {
        setItemQuantity(data)
        context.functions.handleQuantityUpdate(item, data)
        sendGtagEvent("tires_update_quantity", {
            tireName: item.name,
            count: data
        })
    }

    const IMG_URL = "https://s3.amazonaws.com/assets.truckertools.com/carrierPortal/dd4udt1b/services/tires/" + item.brand.split(" ").join("") + ".png"

    return (
        <div className={classes.searchCardRow}>
            {!isMobile ?
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ width: isCartVersion ? "85%" : "unset", flex: isCartVersion ? "unset" : 1, paddingLeft: isCartVersion ? 2 : 0 }}>
                        <Card className={classes.card}>
                            <CardContent style={{ padding: 15 }}>
                                <TireCardDesktop
                                    item={item}
                                    index={itemIndex}
                                    imageUrl={IMG_URL}
                                />
                            </CardContent>
                        </Card>
                    </div>
                    {!isCartVersion && (
                        <div style={{ width: "6%", marginLeft: "1%", marginRight: "1%", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 15 }}>
                            <Button
                                style={{ fontSize: 16, color: "white", backgroundColor: muiTheme.actionColor }}
                                variant={"contained"}
                                size="small"
                                onClick={handleToggleSelected}
                            >
                                Add
                            </Button>
                        </div>
                    )}
                    {isCartVersion && (
                        <div style={{ width: "8%", marginLeft: "2%", display: "flex", alignItems: "center" }}>
                            <TextField
                                id="filled-full-width"
                                style={{ margin: 'auto' }}
                                inputProps={{ style: { fontSize: 16, textAlign: "center" } }}
                                margin="normal"
                                variant="filled"
                                size="small"
                                type="Number"
                                value={itemQuantity}
                                onChange={(e) => handleSetQuantity(e.target.value)}
                            />
                        </div>
                    )}
                    {isCartVersion && (
                        <div style={{ width: "5%", display: "flex", alignItems: "center" }}>
                            <IconButton
                                onClick={() => {
                                    handleDelete()
                                }}
                            >
                                <FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />
                            </IconButton>
                        </div>
                    )}
                </div>
                :
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ width: !isCartVersion ? "96%" : "70%" }}>
                        <Card className={classes.card} style={{ borderRadius: 15 }}>
                            <CardContent style={{ padding: 0 }}>
                                <TireCardMobile
                                    item={item}
                                    index={itemIndex}
                                    imageUrl={IMG_URL}
                                    onAdd={handleToggleSelected}
                                    isCartVersion={isCartVersion}
                                // itemQuantity={itemQuantity}
                                // handleSetQuantity={handleSetQuantity}
                                // handleDelete={handleDelete}
                                />
                            </CardContent>
                        </Card>
                    </div>
                    {isCartVersion && (
                        <div style={{ width: "10%", marginLeft: "5%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <TextField
                                id="filled-full-width"
                                style={{ margin: 'auto' }}
                                inputProps={{ style: { fontSize: 16, textAlign: "center" } }}
                                margin="normal"
                                variant="filled"
                                size="small"
                                type="Number"
                                value={itemQuantity}
                                onChange={(e) => handleSetQuantity(e.target.value)}
                            />
                        </div>
                    )}
                    {isCartVersion && (
                        <div style={{ width: "15%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <IconButton
                                onClick={() => {
                                    handleDelete()
                                }}
                            >
                                <FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />
                            </IconButton>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}


export default compose(
    muiThemeable(),
    isMobile()
)(TireItem)

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    card: {
        width: "100%",
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        marginTop: 10,
        marginBottom: 0
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    }
}));