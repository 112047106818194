//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'

//APIs
  import {postAction} from '../../api/actions'

function* actionFlow(action){
  try {

    const response = yield call(postAction, action.payload)
    if(response.status){
      yield put({type:"ACTION_SUC",payload:response})
      action.callback&&action.callback(response)
    }

  } catch (error) {
      yield put({type:"ACTION_ERR", message:error})
      console.log(error)

  }
}

// Define what messages the saga should listen to and what function to call when the message is heard. Function names should end with "flow" term.
function* templateWatcher () {
  yield takeLatest("ACTION_REQ", actionFlow)
}

export default templateWatcher
