import * as CONST from '../constants'

const initialState = {
    filteredOutFranchises: [],
    filteredOutAmenities: [],
    deadheadThresholds: [],
    dieselThresholds: [],
    lastAction: null
}

const FuelStopFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONST.SET_FUEL_FRANCHISE_FILTER:
            return { ...state, ...{ filteredOutFranchises: action.payload, lastAction: action.type } }
        case CONST.SET_FUEL_AMENITY_FILTER:
            return { ...state, ...{ filteredOutAmenities: action.payload, lastAction: action.type } }
        case CONST.SET_FUEL_DEADHEAD_FILTER:
            return { ...state, ...{ deadheadThresholds: action.payload, lastAction: action.type } }
        case CONST.SET_FUEL_DIESEL_FILTER:
            return { ...state, ...{ dieselThresholds: action.payload, lastAction: action.type } }
        case CONST.RESET_ALL_FUEL_FILTERS:
            return { ...initialState, ...{ lastAction: action.payload } }
        default:
            return state
    }
}

export default FuelStopFilterReducer