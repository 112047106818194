import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux';

import muiThemeable from 'material-ui/styles/muiThemeable';

import List from '@material-ui/core/List';

import TruckCapacitiesListHeader from './truck-capacities-list-header';
import TruckCapacityItem from './truck-capacity-card/TruckCapacityItem';

// import { LogActionsActivity } from '../../../apis/index'
import moment from 'moment'
import { getNearbyLoads } from '../../../../actions';
import { updateLoadSearchBar } from '../../../../../CarrierPortal/ActionReducers/components';
import { US_STATES } from "../../../LoadSearchBar/zones"
import isMobile from '../../../../../HOC/Mobile.js'


const TruckCapacityList = (props) => {

  const {
    truckCapacityList,
    muiTheme,
    updatePreferencesData,
    selectedLocationId,
    isMobile
  } = props

  const [itemList, setItemList] = useState(truckCapacityList)
  const [forceReRender, setForceReRender] = useState(false)


  useEffect(() => {
    cleanseList(truckCapacityList)
  }, [truckCapacityList])

  const cleanseList = (list) => {
    let tmpList = list.reverse()
    tmpList = removeOldItems(list)
    tmpList = tmpList.sort((a, b) => {
      if (moment(a['dateAvailable']).valueOf() < moment(b['dateAvailable']).valueOf())
        return 1
      else
        return 0
    })
    setItemList(tmpList)
  }

  const removeOldItems = (list) => {
    let yesterdayDateTime = moment().add(-24, 'hours').format("YYYY-MM-DD HH:mm:ss")
    let tmpList = []
    tmpList = list.filter(item => {
      //check if there's no recurring pickup week day then check if the date is greater than 24 hours old. If true then remove from list
      if (moment(item.dateAvailable).format("YYYY-MM-DD HH:mm:ss").valueOf() < yesterdayDateTime.valueOf()) {
        return false
      }
      return true
    })
    return tmpList
  }

  const deleteItem = (itemId, itemIndex) => {


    itemList.splice(itemIndex, 1)
    setItemList(truckCapacityList)
    callDeleteItem(itemId)
    setForceReRender(!forceReRender)



  }

  const callDeleteItem = (itemId) => {

    let params = {
      itemID: itemId.toString()
    }
    console.log("Calling delete item...", params)

  }

  return (
    <div style={{ height: '100%' }}>
      {itemList.length > 0 && !isMobile && (
        <div style={{ height: '90px', marginBottom: 10, paddingLeft: 15, paddingRight: 15 }}>
          <p style={{ color: 'black', fontSize: 32, fontWeight: 'bold', whiteSpace: 'nowrap' }}>Capacity</p>
          <TruckCapacitiesListHeader />
        </div>
      )}
      <List style={{ height: !isMobile ? 'calc(98% - 90px)' : '100%', maxHeight: !isMobile ? 'calc(98% - 90px)' : '100%', overflow: 'auto', paddingTop: 0, paddingBottom: isMobile ? 50 : 'unset' }}>

        {itemList.length > 0 ?
          itemList.map((item, index) => {

            return (
              <TruckCapacityItem
                item={item}
                key={index + "_available_capacity"}
                itemIndex={index}
                toggleSavedSearch={() => { }}
                deleteSavedSearch={() => { }}
                editSavedSearch={() => { }}
                updatePreferencesData={updatePreferencesData}
                selectedLocationId={selectedLocationId}
                handleViewLoads={props.handleViewLoads}
              />
            )

          })
          :
          <div style={{ paddingTop: 55 }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <h3>There are no capacities saved for this location.</h3>
            </div>
          </div>
        }

      </List>
    </div>
  )

}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    getNearbyLoads: getNearbyLoads,
    updateLoadSearchBar: updateLoadSearchBar,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  muiThemeable(),
  isMobile()
)(TruckCapacityList)