import React, { useState, useContext } from 'react';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';

import { Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Badge from '@material-ui/core/Badge';
import { toast } from 'react-toastify'
import FA from 'react-fontawesome'

import TireList from './tire-list';
import AddressAutocomplete from './AddressAutocompleteTireLocation'

import { TireScreenContext } from './context/index';
import { sendEmail } from '../../apis/api-email-service';
import {useAuthenticationHook} from '../../hooks/authentication-hook'
import useGtagHook from '../../../gtag-hook';

const RequestQuotesButton = (props) => {

    const {
        isMobile,
        muiTheme,
        btnStyle
    } = props

    const useStyles = makeStyles({
        loadSearchButton: {
            backgroundColor: 'white',
            // primaryColor:primary.main,
            justifyContent: 'center',
            // outline: '1px solid black',
            minHeight: '45px',
            maxHeight: '45px',
            minWidth: '45px',
            maxWidth: '45px',
            left: '10%',
            top: '10%',
            border: '1px solid #ccc',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
        },
        cartContainer: {
            // display: "flex",
            position: 'relative',
            marginBottom: 30,
            padding: 10,
            borderStyle: "solid",
            borderWidth: 0.5,
            maxHeight: isMobile ? 260 : 200,
            overflowY: "scroll"
        },
        inputContainer: {
            display: "flex",
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: "space-between",
            marginBottom: isMobile ? 0 : 10,
            paddingLeft: isMobile ? 20 : 0,
            paddingRight: isMobile ? 20 : 0

        },
        miniTxtField: {
            width: isMobile ? '100%' : "15%",
            marginBottom: 10
        },
        smallTxtField: {
            width: isMobile ? '100%' : "32%",
            marginBottom: 10
        },
        mobilePaper: {
            maxWidth: 'unset !important',
            maxHeight: 'unset !important',
        },
        buttonWrapper: {
            display: isMobile ? 'block' : 'contents',
            position: isMobile ? 'absolute' : 'relative',
            bottom: isMobile ? '0px' : 'unset',
            backgroundColor: isMobile ? 'white' : 'unset',
            zIndex: 15000
        }
    });

    const classes = useStyles()

    const context = useContext(TireScreenContext)

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    const recipientEmail = "ecstireslc@englandlogistics.com"
    const logoUrl = "https://s3.amazonaws.com/assets.truckertools.com/carrierPortal/dd4udt1b/logo.png"
    const [firstName, setFirstName] = useState((user && user.firstName) ? user.firstName : "");
    const [lastName, setLastName] = useState((user && user.lastName) ? user.lastName : "");
    const [contactEmail, setContactEmail] = useState((user && user.username) ? user.username : "");
    const [contactPhone, setContactPhone] = useState("");
    const [companyName, setCompanyName] = useState((user && user.carrier && user.carrier.companyName) ? user.carrier.companyName : "")
    const [mcNumber, setMcNumber] = useState((user && user.carrier && user.carrier.mc) ? String(user.carrier.mc).substring(2) : "");
    const [dotNumber, setDotNumber] = useState((user && user.carrier && user.carrier.dot) ? user.carrier.dot : "");
    const [optionalMsg, setOptionalMsg] = useState("");
    const [preferredPickup, setPreferredPickup] = useState(null);

    const [isMakingApiCall, setIsMakingApiCall] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [sendGtagEvent] = useGtagHook()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        sendGtagEvent("tires_open_request_quote")
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const StyledBadge = withStyles((theme) => ({
        badge: {
            right: -10,
            top: -5,
            // border: `2px solid ${theme.palette.background.paper}`,
            border: `2px solid ${muiTheme.actionColor}`,
            padding: '6px 6px',
            color: muiTheme.actionColor,
            backgroundColor: "#fff",
            fontSize: 12,
            fontWeight: "bold"
        }
    }))(Badge);

    const getTireInfo = () => {
        let returnStr = ""
        context.cartData.forEach(tire => {
            returnStr = returnStr + "<br/><br/>Name: " + tire.name +
                "<br/>Model: " + (tire.model ? tire.model : "") +
                "<br/>Brand: " + (tire.brand ? tire.brand : "") +
                "<br/>Size: " + (tire.size ? tire.size : "") +
                "<br/>Position: " + (tire.position ? tire.position : "") +
                "<br/>Ply: " + (tire.ply ? tire.ply : "") +
                "<br/>Quantity: " + (tire.quantity ? tire.quantity : "")
        })

        return returnStr
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const submitEmail = () => {
        setIsMakingApiCall(true)

        // validation
        if ((firstName == null || firstName === "") || (lastName == null || lastName === "")) {
            toast.error("Please provide both first and last name.")
            setIsMakingApiCall(false)
            return
        }
        else if (contactEmail == null || contactEmail === "" || !validateEmail(contactEmail)) {
            toast.error("Please enter a valid email address.")
            setIsMakingApiCall(false)
            return
        }
        else if (((mcNumber == null || mcNumber === "") || isNaN(mcNumber)) && (dotNumber == null || dotNumber === "") || isNaN(dotNumber)) {
            toast.error("A valid MC or DOT number is required.")
            setIsMakingApiCall(false)
            return
        } else if ((companyName == null || companyName === "")) {
            toast.error("Please provide company name.")
            setIsMakingApiCall(false)
            return
        }

        // TODO PICKUP LOCATION
        let msg = (firstName || lastName ? "Contact Name: " + firstName + " " + lastName + "<br/>" : "") +
            (contactEmail ? "Contact Email: " + contactEmail + "<br/>" : "") +
            (contactPhone ? "Contact Phone: " + contactPhone + "<br/>" : "") +
            (companyName ? "Company Name: " + companyName + "<br/>" : "") +
            (mcNumber ? "MC: " + mcNumber + "<br/>" : "") +
            (dotNumber ? "DOT: " + dotNumber + "<br/>" : "") +
            (optionalMsg ? "<br/>Message from user:" + "<br/>" + optionalMsg + "<br/>" : "") +
            (preferredPickup ? "<br/>Preferred Pickup Location: " + preferredPickup.slice(1, -1) + "<br/>" : "") +
            "<br/>Requesting quotes on the following tires:" +
            getTireInfo()

        let params = {
            subject: companyName+" has requested quote for tires",
            reply_to: contactEmail,
            message_body: msg,
            // mc: 555,
            // company_name: "Test Company Name",
            recipient_email: recipientEmail,
            customer_logo_url: logoUrl
        }

        // console.log("message: ", msg)

        sendEmail(params).then(response => {
            sendGtagEvent("tires_submit_request_quote", {
                firstName: firstName,
                lastName: lastName,
                carrierCompanyName: companyName,
                emailAddress: contactEmail,
                phoneNumber: contactPhone,
                carrierMcNumber: mcNumber,
                carrierDotNumber: dotNumber,
                optionalMsg: optionalMsg ? optionalMsg : "",
                preferredPickup: preferredPickup ? preferredPickup.slice(1, -1) : null,
                apiDidError: !response.status
            })
            if (response && response.status) {
                // close form
                // toast that email has been successfully sent
                toast.success("Email seccuessfully sent")
                handleClose()
                context.functions.handleClearCart()
                setIsMakingApiCall(false)
            } else {
                // display error as toast
                // do not clear or close form
                toast.error(response.message ? response.message : "There was a problem making your request. Please check your internet connection and try again")
                setIsMakingApiCall(false)
            }
        }).catch((e) => {
            // display error as toast
            // do not clear or close form
            toast.error("There was a problem making your request. Please check your internet connection and try again")
            setIsMakingApiCall(false)
        })
    }

    return (
        <Col style={{}}>
            <Button
                style={btnStyle}
                variant={"contained"}
                size="small"
                onClick={handleClick}
                disabled={open}
            >
                <StyledBadge badgeContent={context.cartData.length} color="secondary">
                    <p>Request Quotes</p>
                </StyledBadge>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                marginThreshold={isMobile ? 0 : 16}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={isMobile ?
                    {
                        className: classes.mobilePaper,
                        elevation: 0,
                        style: {

                            width: '100%',
                            height: '100%',

                        }
                    }
                    :
                    {}
                }
            >

                {isMobile &&
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{
                                backgroundColor: "transparent",
                                color: muiTheme.actionColor,
                                fontSize: 22,
                                textTransform: 'none',
                                textDecoration: 'underline',
                                margin: 5
                            }}
                            onClick={() => handleClose()}
                        >
                            <FA name={"times"} size='sm' color={'grey'} style={{ marginRight: 5 }} />
                        </Button>
                    </div>
                }

                <div style={{ padding: isMobile ? 0 : 20, width: isMobile ? '100%' : 900, maxWidth: 900 }}>

                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 30, paddingLeft: isMobile ? 20 : 0, paddingRight: isMobile ? 20 : 0 }}>
                        <p style={{ fontSize: 16 }}>Thank you for your interest. Once your request for a quote has been submitted a representative will reach out to you within 1 business day. For immediate inquiries, please call 800-848-7810.</p>
                        {/* <div className={classes.buttonWrapper}> */}
                        {!isMobile &&
                            <Button
                                style={{ fontSize: 16, color: "white", backgroundColor: context.cartData.length == 0 ? "grey" : muiTheme.actionColor }}
                                variant={"contained"}
                                size="small"
                                disabled={(context.cartData.length == 0 || isMakingApiCall)}
                                onClick={() => submitEmail()}
                            >
                                Submit Request
                            </Button>
                        }

                        {/* </div> */}
                    </div>
                    <div className={classes.cartContainer}>
                        <TireList currentTireList={context.cartData} isCartVersion={true} />
                    </div>
                    <div className={classes.inputContainer}>
                        <TextField
                            className={classes.miniTxtField}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            value={firstName}
                            label={"First Name*"}
                            onChange={(e) => setFirstName(e.target.value)}
                            variant={"filled"}
                        />
                        <TextField
                            className={classes.miniTxtField}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            value={lastName}
                            label={"Last Name*"}
                            onChange={(e) => setLastName(e.target.value)}
                            variant={"filled"}
                        />
                        <TextField
                            className={classes.smallTxtField}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            value={contactEmail}
                            label={"Contact Email*"}
                            onChange={(e) => setContactEmail(e.target.value)}
                            variant={"filled"}
                        />
                        <TextField
                            className={classes.smallTxtField}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            value={contactPhone}
                            label={"Contact Phone"}
                            onChange={(e) => setContactPhone(e.target.value)}
                            variant={"filled"}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <TextField
                            className={classes.smallTxtField}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            value={companyName}
                            label={"Company Name*"}
                            onChange={(e) => setCompanyName(e.target.value)}
                            variant={"filled"}
                        />
                        <TextField
                            className={classes.smallTxtField}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            value={mcNumber}
                            label={"MC Number"}
                            onChange={(e) => setMcNumber(e.target.value)}
                            variant={"filled"}
                        />
                        <TextField
                            className={classes.smallTxtField}
                            inputProps={{ style: { fontSize: 16 } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            value={dotNumber}
                            label={"DOT Number"}
                            onChange={(e) => setDotNumber(e.target.value)}
                            variant={"filled"}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10, paddingLeft: isMobile ? 20 : 0, paddingRight: isMobile ? 20 : 0 }}>
                        <TextField
                            fullWidth
                            inputProps={{ style: { fontSize: 16, lineHeight: 'normal' } }}
                            InputLabelProps={{ style: { fontSize: 16 } }}
                            value={optionalMsg}
                            label={"Message (Optional)"}
                            multiline
                            rows={3}
                            variant="filled"
                            onChange={(event) => setOptionalMsg(event.target.value)}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10, paddingLeft: isMobile ? 20 : 0, paddingRight: isMobile ? 20 : 0 }}>
                        {/* <TextField
                            fullWidth
                            inputProps={{ style: { fontSize: 16, lineHeight: 'normal' } }}
                            InputLabelProps={{ style :{ fontSize:16 } } }
                            value={""}
                            label={"Pickup Location (Optional)"}
                            variant="filled"
                        // onChange={(event) => updateQuoteMessage(event.target.value)}
                        /> */}
                        <AddressAutocomplete
                            inputFloatingLabelText={"Pickup Location (Optional)"}
                            value={preferredPickup}
                            onChange={(payload) => { setPreferredPickup(JSON.stringify(payload.formattedAddress)) }}
                        />
                    </div>
                    {isMobile &&
                        <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', marginBottom: 20 }}>
                            <Button
                                style={{
                                    backgroundColor: "transparent",
                                    color: muiTheme.actionColor,
                                    fontSize: 18,
                                    textTransform: 'none',
                                    textDecoration: 'underline',
                                    marginRight: 5
                                }}
                                onClick={() => handleClose()}
                            >
                                {/* <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} /> */}
                                Close
                            </Button>
                            <Button
                                style={{ fontSize: 16, color: "white", backgroundColor: context.cartData.length == 0 ? "grey" : muiTheme.actionColor, marginLeft: 5 }}
                                variant={"contained"}
                                size="small"
                                disabled={(context.cartData.length == 0 || isMakingApiCall)}
                                onClick={() => submitEmail()}
                            >
                                Submit Request
                            </Button>
                        </div>
                    }
                </div>
            </Popover>
        </Col>
    )
}

export default muiThemeable()(RequestQuotesButton)


