import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';

import ActiveLoadsDetails from './activeLoadsDetails';
import HistoricalLoadsDetails from './historicalLoadsDetails';

const MyLoadsDetailsTab = (props) => {

    const {
        load,
        myLoadsRenderedTab = 0
    } = props
    return (

        <div style={{ display: 'contents' }}>
            { myLoadsRenderedTab === 0 ?
                <ActiveLoadsDetails //this file is specific to Werner.
                    load={load}
                />
            :
                <HistoricalLoadsDetails 
                    load={load}
                />
            }
        </div>
    );
}


export default compose(
    connect(),
    muiThemeable(),
)(MyLoadsDetailsTab)