//MESSAGING
  import * as MESSAGES from './constants'

const initialState = {  
  requesting: false,
  successful: false,
  payload:[],
  messages: [],
  errors: [],
}

const mapReducer = function loginReducer (state = initialState, action) {  
  switch (action.type) {
    // case MESSAGES.SALESMAP_SUCCESS:
    //   var retValue = {
    //     ...state,
    //     requesting:true,
    //     payload:action.response
    //   }
    //   return retValue
    
    default:
      return state
  }
}

export default mapReducer  