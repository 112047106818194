import React from 'react';
import { compose } from 'recompose';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Col, Row } from "react-bootstrap";

const TireListHeader = (props) => {

    const classes = useStyles();

    return (
        <div>
            <Row style={{ display: 'flex' }}>
                <Col style={{ width: "100%" }}>
                    <Row>
                        <Col md={2} className={classes.centerColContent} style={{ alignItems: 'left' }}>
                            <p className={classes.headerText}>
                                Name
                            </p>
                        </Col>
                        <Col md={2} className={classes.centerColContent} style={{ alignItems: 'left' }}>
                            <p className={classes.headerText}>
                                Model
                            </p>
                        </Col>
                        <Col md={2} className={classes.centerColContent} style={{ alignItems: 'left' }}>
                            <p className={classes.headerText}>
                                Brand
                            </p>
                        </Col>
                        <Col md={2} className={classes.centerColContent} style={{ alignItems: 'left' }}>
                            <p className={classes.headerText}>
                                Size
                            </p>
                        </Col>
                        <Col md={2} className={classes.centerColContent} style={{ alignItems: 'left' }}>
                            <p className={classes.headerText}>
                                Position
                            </p>
                        </Col>
                        <Col md={2} className={classes.centerColContent} style={{ alignItems: 'center' }}>
                            <p className={classes.headerText}>
                                Ply
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default compose(
    muiThemeable()
)(TireListHeader)

const useStyles = makeStyles({
    headerText: {
        // fontFamily: 'Arial !important',
        fontSize: '13px',
        color: 'black',
        margin: 0
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    }
});