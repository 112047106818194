import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose, withProps } from 'recompose'
import isMobile from '../../../../HOC/Mobile.js'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MultiStateSelect from './MultiStateSelect'
import { Col, } from 'react-bootstrap';
import ZoneSelect from './ZoneSelect';
import AddressAutocomplete from '../../LoadSearchBar/AddressAutocompleteLocationAdd';
import {mapZone} from '../../LoadSearchBar/zones';
import { checkValueExists } from "../../../../lib/check-value-exists"

export const  defaultDataLoc = {
    locationDetails:null,
    locationZone:null,
    locationCustom:null,
    locationSelectType:'SPECIFIC',
}


const AddressPicker = (props) => {

    const { muiTheme, isMobile, locationType='', initialValues=null,showTypeSelectOptions=true ,hasAny=false, onSetCallBack=null , showSpecific=true, showZone=true,showCustom=true , locationSelectTypeAttr="SPECIFIC"} = props

    const useStyles = makeStyles({
        label: {
            fontSize: '16px'
        }
    });

    const classes = useStyles()

    const [locationSelectType, setLocationSelectType] = useState(defaultDataLoc.locationSelectType);
    const [locationDetails, setLocationDetails] = useState(null);
    const [locationZone, setLocationZone] = useState(null);
    const [locationCustom, setLocationCustom] = useState(null);
    const [formPayload, setFormPayload] = useState(defaultDataLoc);
    const [hasAnyLocation, setHasAnyLocation] = useState(hasAny);

    useEffect(() => {
        if (initialValues !== null) {
                if (initialValues.locationSelectType === "CUSTOM") {
                    setLocationSelectType("CUSTOM")
                    if(initialValues.locationCustom!==null && Array.isArray(initialValues.locationCustom) && initialValues.locationCustom.length>0){
                        setLocationCustom(initialValues.locationCustom)
                    }else{
                        setLocationCustom(['ANYWHERE'])
                    }
                    initialValues.locationSelectType = 'CUSTOM'
                } else if (initialValues.locationSelectType === "ZONE") {
                    setLocationSelectType("ZONE")
                    let zoneObj = mapZone(initialValues.locationZone.abbr)
                    setLocationZone(zoneObj)
                    initialValues.locationSelectType = 'ZONE'
                    initialValues.locationZone = zoneObj
                } else if (initialValues.locationSelectType === "SPECIFIC") {
                    setLocationSelectType("SPECIFIC")
                    if(checkValueExists(initialValues.locationDetails,'city')){
                        setLocationDetails(initialValues.locationDetails)
                    }else{
                        setLocationDetails(initialValues.locationDetails.state)
                        setLocationCustom([initialValues.locationDetails.state])
                    }
                    initialValues.locationSelectType = 'SPECIFIC'
                }
                setFormPayload(initialValues)
        }
        else if(initialValues == null){
            let newDefault = formPayload
            newDefault.locationSelectType = locationSelectTypeAttr
            setLocationSelectType(locationSelectTypeAttr)
            setFormPayload(newDefault)
        }

    }, [initialValues])

    useEffect (()=>{
        setHasAnyLocation(hasAny)
    },[hasAny])

    

    const handleLocationTypeSelect = (event) => {
        let value = event.target.value
        setLocationSelectType(value);
        popuateFormPayload({locationSelectType:value})
    };
    const handleSetLocation = (value) => {
        setLocationDetails(value);
        popuateFormPayload({locationDetails:value,locationSelectType:'SPECIFIC'})
    };
    const handleSetZone = (value) => {
        setLocationZone(value);
        if(value.length ==0){
           value = null 
        }
        popuateFormPayload({locationZone:value,locationSelectType:'ZONE'})
    };
    const handleSetCustom = (value) => {
        setLocationCustom(value);
        if(value.length ==0){
           value = null 
        }
        popuateFormPayload({locationCustom:value,locationSelectType:'CUSTOM'})
    };



    const popuateFormPayload = (incomingData)=>{
        let payload = {...formPayload,...incomingData}
        setFormPayload(payload)
        if(onSetCallBack){
            onSetCallBack(payload)
        }
    }


    const UI = (
        <Col style={{width:'100%'}}>
            {showTypeSelectOptions && (
                <RadioGroup row aria-label="uploadType" name="uploadType1" value={locationSelectType} onChange={handleLocationTypeSelect}>
                    {showSpecific && (<FormControlLabel classes={{label: classes.label}} value="SPECIFIC" control={<Radio style={{ color: muiTheme.secondary }} />} label="Select City & State" style={{ fontSize: 14 }} />)}
                    {showZone && (<FormControlLabel classes={{label: classes.label}} value="ZONE" control={<Radio style={{ color: muiTheme.secondary }} />} label="Select Zone" style={{ fontSize: 14 }} />)}
                    {showCustom && (<FormControlLabel classes={{label: classes.label}} value="CUSTOM" control={<Radio style={{ color: muiTheme.secondary }}  />} label="Custom" style={{ fontSize: 14 }} />)}
                </RadioGroup>
            )}
            {locationSelectType === "SPECIFIC" && (
                <AddressAutocomplete 
                    needsRedux={false} 
                    handleSetPickupLocation={handleSetLocation} 
                    existingLocation={locationDetails} 
                    onChange={() => console.log('on change')} 
                    placeholder={(locationType+" Location *").trim() }
                    helperText={"Enter where you are looking to drop off a load"} 
                    //onChange={(event)=>handleSetPickupLocation(event)} 
                    //value={addressLine1Field} 
                    clearData={()=>console.log('on clear Data')} 
                    variant="filled" 
                    fullWidth 
                    required
                    name={"destination1"}
                    hardLoad={true}
                    //error={isAddressLine1FieldInValid}
                />
            )}
            {locationSelectType === "ZONE" && (
                <ZoneSelect
                    handleSetSelectedZone={(value) => {
                        handleSetZone(value)
                    }}
                    existingZone={locationZone}
                    inputTransparentStyle={false}
                    hasAnyLocation={hasAnyLocation}
                />
            )}
            {locationSelectType === "CUSTOM" && (
                <MultiStateSelect
                    handleSelectedStates={(value) => {
                        handleSetCustom(value)
                    }}
                    existingStates={locationCustom}
                    inputTransparentStyle={false}
                    placeholderMsg={"Select "+locationType+"(s)"}
                    //isEditing={isEditing}
                    hasAnyLocation={hasAnyLocation}
                />
            )}
        </Col>
    )

    return UI
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(AddressPicker)


/*

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

*/