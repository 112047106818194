import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom"

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import isMobile from '../../../../../../HOC/Mobile.js'
import { toast } from "react-toastify"

import useGtagHook from '../../../../../../gtag-hook';
import LanePreferencesCard from './LanePreferencesCard'
import LanePreferencesCardMobile from './LanePreferencesCardMobile';
import AddPreferenceModal from "../../general-add-edit-modal"
import { GeneralServices } from '../../../../../../CarrierPortal/customers/general/services';
import ConfirmDialog from '../../../../ConfirmDialog'
import { checkValueExists } from "../../../../../../lib/check-value-exists"
import { getNearbyLoads } from '../../../../../../CarrierPortal/actions';
import {useAuthenticationHook} from '../../../../../hooks/authentication-hook';

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
}));

const LanePreferencesItem = (props) => {

    const classes = useStyles()

    const { muiTheme, item, itemIndex, isMobile = false, updatePreferencesData, getNearbyLoads } = props

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    const [sendGtagEvent] = useGtagHook()

    const [showEditModal, setShowEditModal] = useState(false)
    const [apiCalling, setApiCalling] = useState(false)
    const [availability, setAvailability] = useState(item.visibility === 1 ? true : false);
    const [notificationsOn, setNotificationsOn] = useState(false);
    const [onHold, setOnHold] = useState(false);
    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false);


    useEffect(() => {
        setNotificationsOn(item.receiveNotifications)
        setOnHold(item.onHold)
        setAvailability(item.visibility === 1 ? true : false)
    }, [item])

    const handleToggleNotification = (event) => {

        setNotificationsOn(event.target.checked)
        const apiServices = new GeneralServices()
        let params = {}
        if(event.target.checked){
            setApiCalling(true)
            apiServices.enableNotificationsLanePreference(params,item.id)
                    .then(response => {
                        setApiCalling(false)
                        if (response && response.status) {
                            sendGtagEvent(
                                "preferences_lane_toggle_notifications",
                                {
                                    action: "TOGGLE_ON",
                                    id: item.id,
                                    receiveNotifications: item.receiveNotifications,
                                    visibility: item.visibility,
                                    onHold: item.onHold,
                                    pickupLocation: JSON.stringify(item.origin),
                                    deliveryLocation: JSON.stringify(item.destination),
                                    equipments: JSON.stringify(item.truckTypes),
                                    rate: item.rate.value,
                                    rateType: item.rate.type,
                                    pickupDate: item.availableAt.date,
                                    pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                    availableAt: JSON.stringify(item.availableAt),
                                    brokers: JSON.stringify(item.brokers)
                                }
                            )
                            setNotificationsOn(1)
                        }else{
                            setNotificationsOn(null)
                            toastErrorText(response)
                        }
                    })
                    .catch(error => {
                        setApiCalling(false)
                        setNotificationsOn(null)
                        toastErrorText()
                    })

        }else{
            
            setApiCalling(true)
            apiServices.disableNotificationsLanePreference(params,item.id)
                    .then(response => {
                        setApiCalling(false)
                        if (response && response.status) {
                            sendGtagEvent(
                                "preferences_lane_toggle_notifications",
                                {
                                    action: "TOGGLE_OFF",
                                    id: item.id,
                                    receiveNotifications: item.receiveNotifications,
                                    visibility: item.visibility,
                                    onHold: item.onHold,
                                    pickupLocation: JSON.stringify(item.origin),
                                    deliveryLocation: JSON.stringify(item.destination),
                                    equipments: JSON.stringify(item.truckTypes),
                                    rate: item.rate.value,
                                    rateType: item.rate.type,
                                    pickupDate: item.availableAt.date,
                                    pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                    availableAt: JSON.stringify(item.availableAt),
                                    brokers: JSON.stringify(item.brokers)
                                }
                            )
                            setNotificationsOn(null)
                        }else{
                            setNotificationsOn(1)
                            toastErrorText(response)
                        }
                    })
                    .catch(error => {
                        setApiCalling(false)
                        setNotificationsOn(1)
                        toastErrorText()
                    })
        }
    };

    const handleToggleOnHold = (event) => {
        const apiServices = new GeneralServices()
        setOnHold(event.target.checked)
        let params = {
            onhold : event.target.checked?1:0
        }

        setApiCalling(true)
        apiServices.onHoldLanePreference(params,item.id)
                    .then(response => {
                        setApiCalling(false)
                        if (response && response.status) {
                            sendGtagEvent(
                                "preferences_lane_toggle_on_hold",
                                {
                                    action: onHold ? "TOGGLE_ON" : "TOGGLE_OFF",
                                    id: item.id,
                                    receiveNotifications: item.receiveNotifications,
                                    visibility: item.visibility,
                                    onHold: item.onHold,
                                    pickupLocation: JSON.stringify(item.origin),
                                    deliveryLocation: JSON.stringify(item.destination),
                                    equipments: JSON.stringify(item.truckTypes),
                                    rate: item.rate.value,
                                    rateType: item.rate.type,
                                    pickupDate: item.availableAt.date,
                                    pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                    availableAt: JSON.stringify(item.availableAt),
                                    brokers: JSON.stringify(item.brokers)
                                }
                            )
                            setNotificationsOn(onHold?1:null)
                            setAvailability(onHold?1:null)
                            setOnHold(!onHold)
                        }else{
                            setOnHold(onHold)
                            toastErrorText(response)
                        }

                    })
                    .catch(error => {
                        setApiCalling(false)
                        setOnHold(onHold)
                        toastErrorText()
                    })

    };

    const toggleVisibility = (checked) => {
        setAvailability(checked)
        const apiServices = new GeneralServices()
        let params = {}
        if(checked){
            
            setApiCalling(true)
            apiServices.publishLanePreference(params,item.id)
                    .then(response => {
                        setApiCalling(false)
                        if (response && response.status) {
                            sendGtagEvent(
                                "preferences_lane_toggle_availability",
                                {
                                    action: 'PUBLISH',
                                    id: item.id,
                                    receiveNotifications: item.receiveNotifications,
                                    visibility: item.visibility,
                                    onHold: item.onHold,
                                    pickupLocation: JSON.stringify(item.origin),
                                    deliveryLocation: JSON.stringify(item.destination),
                                    equipments: JSON.stringify(item.truckTypes),
                                    rate: item.rate.value,
                                    rateType: item.rate.type,
                                    pickupDate: item.availableAt.date,
                                    pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                    availableAt: JSON.stringify(item.availableAt),
                                    brokers: JSON.stringify(item.brokers)
                                }
                            )
                            setAvailability(true)
                            item.visibility=1
                        }else{
                            setAvailability(false)
                            item.visibility=null
                            toastErrorText(response)
                        }
                    })
                    .catch(error => {
                        setApiCalling(false)
                        setAvailability(false)
                        item.visibility=null
                        toastErrorText()
                    })

        }else{
        
            setApiCalling(true)
            apiServices.unpublishLanePreference(params,item.id)
                    .then(response => {
                        setApiCalling(false)
                        if (response && response.status) {
                            sendGtagEvent(
                                "preferences_lane_toggle_availability",
                                {
                                    action: 'UNPUBLISH',
                                    id: item.id,
                                    receiveNotifications: item.receiveNotifications,
                                    visibility: item.visibility,
                                    onHold: item.onHold,
                                    pickupLocation: JSON.stringify(item.origin),
                                    deliveryLocation: JSON.stringify(item.destination),
                                    equipments: JSON.stringify(item.truckTypes),
                                    rate: item.rate.value,
                                    rateType: item.rate.type,
                                    pickupDate: item.availableAt.date,
                                    pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                    availableAt: JSON.stringify(item.availableAt),
                                    brokers: JSON.stringify(item.brokers)
                                }
                            )
                            setAvailability(false)
                            item.visibility=null
                        }else{
                            setAvailability(1)
                            item.visibility=1
                            toastErrorText(response)
                        }
                    })
                    .catch(error => {
                        setApiCalling(false)
                        setAvailability(1)
                        item.visibility=1
                        toastErrorText()
                    })
        }
    }

    

    const handleDeleteLanePreference= () => {
        setIsDeleteConfirmationShow(true)
    }


    const deleteItemProcess = (flag) => {

        setIsDeleteConfirmationShow(false)
        if(flag){
            const apiServices = new GeneralServices()
            let params = {
            }
            setApiCalling(true)
            apiServices.deleteLanePreference(params,item.id)
                        .then(response => {
                            setApiCalling(false)
                            if (response && response.status) {
                                sendGtagEvent(
                                    "preferences_delete_lane",
                                    {
                                        id: item.id,
                                        receiveNotifications: item.receiveNotifications,
                                        visibility: item.visibility,
                                        onHold: item.onHold,
                                        pickupLocation: JSON.stringify(item.origin),
                                        deliveryLocation: JSON.stringify(item.destination),
                                        equipments: JSON.stringify(item.truckTypes),
                                        rate: item.rate.value,
                                        rateType: item.rate.type,
                                        pickupDate: item.availableAt.date,
                                        pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                        availableAt: JSON.stringify(item.availableAt),
                                        brokers: JSON.stringify(item.brokers)
                                    }
                                )
                                updatePreferencesData()
                            }else{
                                toastErrorText(response)
                            }
                        })
                        .catch(error => {
                            setApiCalling(false)
                            toastErrorText()
                        })
        }
    }

    const toastErrorText = (response=null)=>{
        let error  = 'There was an issue with your request, please try again later.'
        if(response){
            if (!checkValueExists(response, "errors")) {
                if(response.errors.isArray()){
                    error =    response.errors.join(' ')
                }else{
                    error =  response.errors
                }
            }else if(!checkValueExists(response, "message")){
                error =  response.message
            }
        }
        toast.error(error)
    }


    const card = ()=>{
        let ui = (
            <LanePreferencesCardMobile 
                item={item}
                handleViewLoads={props.handleViewLoads}
                availability={availability}
                notificationsOn={notificationsOn}
                onHold={onHold}
                setShowEditModal={setShowEditModal}
                handleToggleNotification={handleToggleNotification}
                handleDeleteLanePreference={handleDeleteLanePreference}
                toggleVisibility={toggleVisibility}
                handleToggleOnHold={handleToggleOnHold}
                viewOnly={true}
            />
        )
        return ui
    }
    return (
        <div>
            <ConfirmDialog open={isDeleteConfirmationShow} onClose={(flag)=>deleteItemProcess(flag)} header={'Delete Lane Preference'} bodyComp={card()} body={'Are you sure you want to delete this lane preference?'} acceptButtonText='DELETE' />
            <div className={classes.searchCardRow}>
                {!isMobile ?
                    <LanePreferencesCard
                        item={item}
                        handleViewLoads={props.handleViewLoads}
                        availability={availability}
                        notificationsOn={notificationsOn}
                        onHold={onHold}
                        setShowEditModal={setShowEditModal}
                        handleToggleNotification={handleToggleNotification}
                        handleDeleteLanePreference={handleDeleteLanePreference}
                        toggleVisibility={toggleVisibility}
                        handleToggleOnHold={handleToggleOnHold}
                        isLoading={apiCalling}
                    />
                    :
                    <LanePreferencesCardMobile 
                        item={item}
                        handleViewLoads={props.handleViewLoads}
                        availability={availability}
                        notificationsOn={notificationsOn}
                        onHold={onHold}
                        setShowEditModal={setShowEditModal}
                        handleToggleNotification={handleToggleNotification}
                        handleDeleteLanePreference={handleDeleteLanePreference}
                        toggleVisibility={toggleVisibility}
                        handleToggleOnHold={handleToggleOnHold}
                        isLoading={apiCalling}
                    />
                }
            </div>
            {showEditModal && (
                <AddPreferenceModal selectedLocationId={item.addressId} renderedTab={1} onClose={() => { setShowEditModal(false) }} editItem={item} preferenceType={"lanePref"} updatePreferencesData={updatePreferencesData} />
            )}
        </div>
    )
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getNearbyLoads: getNearbyLoads,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable(),
    isMobile(),
    withRouter
)(LanePreferencesItem)