import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'

// const BACKEND_URL = "http://scapi-dev.truckertools.com/api" //TODO: connect to prod

const FETCH_BOOKED_LOADS_URL = "/api/carrier/bookedLoads"
const FETCH_ACTIVE_LOADS_URL = "/api/carrier/getCarrierPortalActiveLoads"
const FETCH_HISTORICAL_LOADS_URL = "/api/carrier/historicalLoads"



export const FetchBookedLoads = (params) => {

    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + FETCH_BOOKED_LOADS_URL

    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + FETCH_BOOKED_LOADS_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + FETCH_BOOKED_LOADS_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

export const FetchActiveLoads = (params) => {

    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + FETCH_ACTIVE_LOADS_URL + `/${params.advantageId}`

    try {
        let requestObject = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + FETCH_ACTIVE_LOADS_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + FETCH_ACTIVE_LOADS_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

export const FetchHistoricalLoads = (params) => {

    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + FETCH_HISTORICAL_LOADS_URL

    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + FETCH_HISTORICAL_LOADS_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + FETCH_HISTORICAL_LOADS_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

