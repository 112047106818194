import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DetailsTab from './details-tab'

const ContentTabs = (props) => {
    const {
        muiTheme,
        item,
    } = props

    const useStyles = makeStyles(() => ({
        root: {
            height: '100%'
        }
    }));

    const classes = useStyles();
    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const TabPanel = (props) => {
        const { children, value, index } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={'details-tabpanel-${index}'}
                style={{ overflow: 'auto', height: 'calc(100% - 48px)' }}
            >
                {value === index && (
                    <Box p={3} style={{ height: '100%', padding: 0, marginBottom: index === 0 ? 45 : null }}>
                        <div style={{height:'100%'}}>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    style={{ backgroundColor: muiTheme.primary }}
                    TabIndicatorProps={{ style: { background: muiTheme.secondary } }}
                >
                    <Tab label="Details" style={{ fontSize: 12 }} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <DetailsTab item={item} />
            </TabPanel>
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
)(ContentTabs)
