import React, { useState, useCallback, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment'
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory , useLocation } from 'react-router-dom';
// import '../../';

//ACTIONS//
import { updateComponent } from '../../../CarrierPortal/ActionReducers/components';
import { loadSearch, getNearbyLoads, getLoadByLoadNumber } from '../../actions';
import {INPUT_BACKGOUND_COLOR,INPUT_BORDER_COLOR} from '../../../CarrierPortal/palette'

//COMPONENTS
import { toast } from 'react-toastify';
import isMobile from '../../../HOC/Mobile.js'
import muiThemeable from 'material-ui/styles/muiThemeable';
import 'react-datepicker/dist/react-datepicker.css'
import useGtagHook from '../../../gtag-hook';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { Row, Col } from 'react-bootstrap';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Button from '@material-ui/core/Button';
import {allRange} from "../preferences/components/AvailableDateTimePicker"
import { GeneralServices } from '../../customers/general/services';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import {defaultUsOriginSearch, allowSearchForPastDates, canUseGetNearbyLoadsV3} from '../../helpers/permissions';

import LoadSearchBarMobile from './LoadSearchBarMobile'
import LoadSearchBarDesktop from './LoadSearchBarDesktop';



const LoadSearchBar = ({
    values,
    updateComponent,
    isMobileView,
    isMobile,
    muiTheme,
    callback,
    lat, lon,
    availabilityId,
    address,
    availableDateTime,
    //truckTypes,
    truckNumber,
    contactEmail,
    contactPhone,
    city,
    state,
    zipCode,
    loadSearch,
    getNearbyLoads,
    getLoadByLoadNumber,
    onHomePage = false,
    loginRequestStatus,
    mobileView = false,
    setShowLoadSearchPopover,
    ...props
}) => {
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiSvgIcon-root' : {
                fontSize: 18
            }
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            width: isMobile ? '100%' : 640,
            height: isMobile ? '100%' : 'unset',
            outline: 'none',
            padding: isMobile ? 5 : theme.spacing(2, 4, 3),
        },
        header: {
            margin: 0
        },
        row: {
            marginBottom: 20
        },
        dayButton : {
            fontSize: 16
        }
    }));
    const classes = useStyles()
    const [sendGtagEvent] = useGtagHook()


    const [destinations, setDestinations] = useState(values.lane_preferences);
    const [pickupLocation, setPickupLocation] = useState(values.formattedAddress);
    const [pickupDateStart, setPickupDateStart] = useState(values.available_at_start);
    const [pickupDateEnd, setPickupDateEnd] = useState(values.available_at_end);
    const [truckTypes, setTruckTypes] = useState(values.truckTypes);
    const [clearData, setClearData] = useState(false);
    const [publishCapacity, setPublishCapacity] = useState(false);
    const [savePrefConfirmDialog,setSavePrefConfirmDialog]= useState(false)
    const [weekDays,setWeekDays] = useState([])
    const [isWeekDays,setIsWeekDays]= useState('SPECIFICDATE')
    const history = useHistory();
    const location = useLocation();



    useEffect(() => {
        window.addEventListener('logout', function (event) { clearSearch() })

        return () => {
            window.removeEventListener('logout', () => {})

        }
    }, [])

    useEffect(()=> {
        setPickupDateStart(values.available_at_start)
    }, [values.available_at_start])

    useEffect(()=> {
        setPickupDateEnd(values.available_at_end)
    }, [values.available_at_end])



    const handleSearch = (savePreference = false) => {

        if ((values.lat == null || values.lat === 0 || values.lon == null || values.lon === 0) && (muiTheme.customerUniqueId !== "a98db973")) {
            updateComponent('searchBoxSettings', { error: { address: { showError: true } } })
            toast.error("Please Select An Origin Location")
            return false;
        }

        if (savePreference && (values.truckTypes == null || values.truckTypes.length <= 0 || !values.truckTypes)) {
            let message = "Please select an equipment type when saving a preference."

            toast.error( message )
            return false;
        }
       
        if (savePreference && (values.state == null || values.state === "" || !values.state || values.city == null || values.city === "" || !values.city) ) {
            let message = "Please select a pickup city & state when saving a preference."
            toast.error( message )
            return false;
        }

        if ( !allowSearchForPastDates() && moment(values.available_at_start).startOf('day').valueOf() < moment().startOf('day').valueOf()) {
            toast.error("Please select a future date time")
            return false;
        } 
        else if ( allowSearchForPastDates() &&  moment(values.available_at_start).startOf('day').valueOf() > moment(values.available_at_end).startOf('day').valueOf()){
            toast.error("Start date must be earlier than end date.")
            return false;
        }

        if(!savePreference){
            searchLoadsApiCall()
        }else{
            setSavePrefConfirmDialog(true)
        }

    }

    const handleSearchByLoadNumber = (loadNumber) => {

        if ( loadNumber == null || loadNumber === ""){
            toast.error("Please enter a valid load number.")
            return
        }
        else if( loadNumber && /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(loadNumber) ){
            toast.error("Load number cannot contain special characters.")
            return
        }
        else if ( loadNumber.indexOf(' ') >= 0 ) {
            toast.error("Load number cannot contain spaces.")
            return
        }

        getLoadByLoadNumber({
            loadNumber: loadNumber,
            advantageId: muiTheme.customerUniqueId
        })

        if(location.pathname !=='/loads'){
            history.push("/loads");
        }

        if(mobileView)
            setShowLoadSearchPopover(false)
    }


    const clearSearch = () => {
        setDestinations([])
        setTruckTypes([])
        setPickupLocation(null)
        setPickupDateStart(moment().format('YYYY-MM-DDTHH:mm'));
        setPickupDateEnd('');
        setClearData(!clearData)

        if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" || process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
            getNearbyLoads({
                advantageId: (muiTheme.customerUniqueId === "c0419cm1" || muiTheme.customerUniqueId === "ch7h4g68r") ? "32m9lbe4" : muiTheme.customerUniqueId,
                lat: 37.09024,
                lon: -95.712891,
                pickupDateFrom: moment().format('MM/DD/YYYY'),
                pickupTimeFrom: moment().format('HH:mm'),
                destinations: [],
                truckTypes: [],
                state: '',
                zipCode: '',
                city: '',
                country: "United States",
                visibility: 0,
                createSavedSearch: false
              })
        }
    }



    const searchLoadsApiCall = () => {

        let defaultUsSearch = false;
        if( defaultUsOriginSearch() && (!values.country) ){
            updateComponent('loadSearchBar', { lat:37.09024  , lon:-95.712891, country: 'United States', formattedAddress: 'United States' })
            setPickupLocation("United States")
            defaultUsSearch = true
        }

        let searchPayload

        if(canUseGetNearbyLoadsV3()){
            searchPayload = {
                lat: defaultUsSearch ? 37.09024 : values.lat,
                lon: defaultUsSearch ? -95.712891 : values.lon,
                pickupDateFrom: moment(values.available_at_start).format('MM/DD/YYYY'),
                pickupTimeFrom: moment(values.available_at_start).format('HH:mm'),
                destinations: !destinations || destinations.length === 0 || (destinations.length === 1 && destinations[0].abbr === 'All') ? [] : destinations,
                truckTypes: values.truckTypes ? values.truckTypes : [],
                advantageId: (muiTheme.customerUniqueId === "c0419cm1" || muiTheme.customerUniqueId === "ch7h4g68r") ? "32m9lbe4" : muiTheme.customerUniqueId,
                state: values.state ? values.state : '',
                zipCode: values.zipCode ? values.zipCode : '',
                city: values.city ? values.city : '',
                country: defaultUsSearch ? 'United States' : values.country,
            }

            if(values.available_at_end && values.available_at_end != 'Invalid date'){
                searchPayload['pickupDateTo'] = moment(values.available_at_end).format('MM/DD/YYYY');
                searchPayload['pickupTimeTo'] = moment(values.available_at_end).format('HH:mm');

                if( moment(values.available_at_start).valueOf() >= moment(values.available_at_end).valueOf()){
                    toast.error('The starting pickup date must come before the ending pickup date.')
                    return
                }
            }
        }else{
            searchPayload = {
                lat: values.lat,
                lon: values.lon,
                pickupDate: moment(values.available_at_start).format('MM/DD/YYYY'),
                pickupTime: moment(values.available_at_start).format('HH:mm'),
                destinations: destinations.length === 0 || (destinations.length === 1 && destinations[0].abbr === 'All') ? null : destinations,
                truckTypes: values.truckTypes,
                advantageId: (muiTheme.customerUniqueId === "c0419cm1" || muiTheme.customerUniqueId === "ch7h4g68r") ? "2zr5ys19" : muiTheme.customerUniqueId,
                state: values.state,
                zipCode: values.zipCode,
                city: values.city,
                country: values.country,
                visibility: publishCapacity ? 1 : 0,
                //createSavedSearch: createSavedSearch
            }
        }

        getNearbyLoads(searchPayload);

        if(location.pathname !=='/loads'){
            history.push("/loads");
        }
        if(mobileView)
            setShowLoadSearchPopover(false)

        sendGtagEvent(
            "find_loads",
            {
                location: values.city + ", " + values.state,
                pickupDate: moment(values.available_at_start).format('MM/DD/YYYY'),
                pickupTime: moment(values.available_at_start).format('HH:mm'),
                destinations: destinations.length === 0 || (destinations.length === 1 && destinations[0].abbr === 'All') ? null : destinations,
                equipments: values.truckTypes
            }
        )
    }


    const cancelSavePreference = () =>{
        setSavePrefConfirmDialog(false)
    }

    const confirmSavePreference = () =>{
            setSavePrefConfirmDialog(false)
            //searchLoadsApiCall(true)
            savePreferenceApiCall()
            if(mobileView){
                setShowLoadSearchPopover(false)
            }

    }

    const savePreferenceApiCall = ()=>{

        let cleanDest = destinations.map( item => item.abbr)
        let params ={
            origin:{
                locationDetails:{
                    city:values.city,
                    state:values.state,
                    zipCode:values.zipCode,
                    country: values.country,
                    lat: values.lat,
                    lon: values.lon
                },
                locationZone:null,
                locationCustom:null,
                locationSelectType:'SPECIFIC'
            },
            destination:{
                locationDetails:null,
                locationZone:null,
                locationCustom:cleanDest.length === 0 || (cleanDest.length === 1 && cleanDest[0].abbr === 'All') ? ['ANYWHERE'] : cleanDest,
                locationSelectType:'CUSTOM'

            },
            truckTypes:values.truckTypes,
            rate:{
                value:'',
                type:'FLAT'
            },
            availableAt:{
                timeFrom:allRange.start,
                timeTo:allRange.end,
            }
        }

        if(isWeekDays == 'WEEKDAYS'){
            params.availableAt.weekDays=weekDays
            addLanePreference(params)
        }else{
            params.availableAt.date=moment(values.available_at_start).format('YYYY-MM-DD')
            addAvailableCapacity(params)
        }
    }


    const addAvailableCapacity = (params) => {
        const apiServices = new GeneralServices()
        apiServices.addTruckCapacity(params)
            .then(response => {
                if (response && response.status) {
                    toast.success("Capacity added successfully.")
                }else{
                toast.error('There was an issue adding capacity.')
                }
            })
            .catch(error => {
                toast.error('There was an issue adding capacity.')
            })
    }
    const addLanePreference = (params) => {
        const apiServices = new GeneralServices()
        apiServices.addLanePreference(params)
            .then(response => {
                if (response && response.status) {
                    toast.success("Lane preference added successfully.")
                }else{
                    toast.error('There was an issue adding lane preference.')
                }
            })
            .catch(error => {
                toast.error('There was an issue adding lane preference.')
            })
    }


    const handleSelectAvailableDay = (event, newValues) => {
        setWeekDays(newValues);
    };

    const handleWeekDaysRadioBox = (event) => {
        setIsWeekDays(event.target.value)
    }
    const preferenceBody = ()=>{
        let UI = (
                <Row>
                    <Col xs={12} md={12} style={{paddingLeft:5}} >
                        <RadioGroup row aria-label="saveType" name="save search Type" value={isWeekDays} onChange={handleWeekDaysRadioBox}>
                            <FormControlLabel value="SPECIFICDATE" control={<Radio style={{ color: muiTheme.secondary }} />} label={<span style={{ fontSize: 14 }}>Save as capacity for {moment(values.available_at_start).format('MM/DD/YYYY')} </span> }/>
                            <FormControlLabel value="WEEKDAYS" control={<Radio style={{ color: muiTheme.secondary }} />} label={<span style={{ fontSize: 14 }} >Save as lane preference with a recurring availability</span>}/>
                        </RadioGroup>
                    </Col>
                    {isWeekDays == 'WEEKDAYS' && (
                        <Col xs={12} md={12} >
                            {/* <p style={{ marginBottom: 10 }}>Available Days <span style={{ color: muiTheme.ttRed }}>*</span></p> */}
                            <ToggleButtonGroup style={{width:"65%"}} size="large" value={weekDays} onChange={handleSelectAvailableDay} aria-label="text formatting">
                                <ToggleButton style={{width:"100%"}} value="SUN" aria-label="Su">
                                    <p className={classes.dayButton}>Su</p>
                                </ToggleButton>
                                <ToggleButton style={{width:"100%"}} value="MON" aria-label="M">
                                    <p className={classes.dayButton}>M</p>
                                </ToggleButton>
                                <ToggleButton style={{width:"100%"}} value="TUE" aria-label="Tu">
                                    <p className={classes.dayButton}>Tu</p>
                                </ToggleButton>
                                <ToggleButton style={{width:"100%"}} value="WED" aria-label="W">
                                    <p className={classes.dayButton}>W</p>
                                </ToggleButton>
                                <ToggleButton style={{width:"100%"}} value="THU" aria-label="Th">
                                    <p className={classes.dayButton}>Th</p>
                                </ToggleButton>
                                <ToggleButton style={{width:"100%"}} value="FRI" aria-label="F">
                                    <p className={classes.dayButton}>F</p>
                                </ToggleButton>
                                <ToggleButton style={{width:"100%"}} value="SAT" aria-label="Sa">
                                    <p className={classes.dayButton}>Sa</p>
                                </ToggleButton>
                            </ToggleButtonGroup>    
                        </Col>
                    )}
                    {isWeekDays == 'WEEKDAYS' && (
                        <Col style={{paddingLeft:5}} >
                            Please select available week day(s)
                        </Col>
                    )}
                </Row>
        )
        return UI
    }


    const savePrefConfirmModalUI = ()=>{
        let ui = (
            <Modal
            aria-labelledby="seach-save-confirm"
            aria-describedby="seach-save-confirm"
            className={classes.modal}
            open={true}
            onClose={()=>cancelSavePreference()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={classes.paper} style={{padding:15}} >
                <h3 id="transition-modal-title" style={{marginBottom:15}} >{'Would you like to save this search?'}</h3>
                <h4 id="transition-modal-title" style={{marginTop:15,marginBottom:0}} >{'Please choose from the following options'}</h4>
                {preferenceBody()}
                <Row  style={{ width: "100%", display: "flex", justifyContent: "flex-end",marginTop:10 }} >
                        <Col >
                            <Button
                                id='cancelConfirm'
                                size="small"
                                variant="link"
                                style={{
                                    color: muiTheme.actionColor,
                                    fontSize: 14,
                                    textTransform:'uppercase',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                                onClick={(event) => { cancelSavePreference() }}
                            >
                               <span >CANCEL</span>
                            </Button>
                        </Col>
                        <Col >
                            <Button
                                id='acceptConfirm'
                                size="small"
                                variant="contained"
                                style={{
                                    backgroundColor: isWeekDays == 'WEEKDAYS' && weekDays.length==0 ? INPUT_BACKGOUND_COLOR : muiTheme.actionColor,
                                    borderColor: muiTheme.actionColor,
                                    color: "white",
                                    fontSize: 14,
                                    textTransform:'uppercase',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginLeft:10
                                }}
                                disabled={isWeekDays == 'WEEKDAYS' && weekDays.length==0}
                                onClick={(event) => { confirmSavePreference() }}
                            >
                               <span style={{width: '100%'}} >PROCEED</span>
                            </Button>
                        </Col>
                    </Row>
            </div>
        </Modal>
        )
        return ui
    }

    return (
        //<div style={{backgroundColor: 'white'}}>
        <div>
            {savePrefConfirmDialog && savePrefConfirmModalUI()}
            {!isMobile &&
                <LoadSearchBarDesktop  
                    pickupLocation={pickupLocation} 
                    destinations={destinations}
                    setDestinations={setDestinations}
                    pickupDateStart={pickupDateStart}
                    pickupDateEnd={pickupDateEnd}
                    truckTypes={truckTypes}
                    clearData={clearData}
                    handleSearch={handleSearch}
                    handleSearchByLoadNumber={handleSearchByLoadNumber}
                    clearSearch={clearSearch}
                    onHomePage={onHomePage}
                />
            }

            {isMobile &&
                <LoadSearchBarMobile  
                    pickupLocation={pickupLocation} 
                    destinations={destinations}
                    setDestinations={setDestinations}
                    pickupDateStart={pickupDateStart}
                    pickupDateEnd={pickupDateEnd}
                    truckTypes={truckTypes}
                    clearData={clearData}
                    handleSearch={handleSearch}
                    handleSearchByLoadNumber={handleSearchByLoadNumber}
                    clearSearch={clearSearch}
                    onHomePage={onHomePage}
                />
            }
        </div>

    )
}


const mapStateToProps = state => ({
    values: state.CarrierPortal.components.loadSearchBar
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loadSearch: loadSearch,
        updateComponent: updateComponent,
        getNearbyLoads: getNearbyLoads,
        getLoadByLoadNumber: getLoadByLoadNumber
    }, dispatch)
}
export default compose(
    muiThemeable(),
    isMobile(),
    connect(mapStateToProps, matchDispatchToProps),
)(LoadSearchBar)