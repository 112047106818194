
import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { Col, Row } from "react-bootstrap"

const ActiveLoadCardHeader = (props) => {

    const { myLoadsRenderedTab = 0, isMyLoadsScreen = false } = props

    const classes = useStyles();

    const getActionColumnText = () => {
        switch (myLoadsRenderedTab) {
            case 0:
                return ""
            case 1:
                return "Quote"
            case 2:
                return ""
        }
    }

    return (

        <div style={{ paddingLeft: 5 }}>

            <Row style={{ display: 'flex' }}>

                <Col style={{ width: '5%', display: 'flex' }}>
                </Col>

                <Col style={{ width: "14%", display: 'flex' }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <Row>
                                <p className={classes.headerText}>Origin Location</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: "9%" }}>
                </Col>

                <Col style={{ width: "14%" }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <Row>
                                <p className={classes.headerText}>Destination Location</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: "10%" }}>
                    {/* <Col className={classes.centerColContent} style={{ alignItems: 'center', textAlign: 'center' }}>
                        <Row>
                            <p className={classes.headerText}>Reloads</p>
                        </Row>
                    </Col> */}
                </Col>

                <Col style={{ width: "11%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <Row>
                            <p className={classes.headerText}>Equipment / Weight</p>
                        </Row>
                    </Col>
                </Col>
                <Col style={{ width: "10%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'flex-start' }}>
                        <Row>
                            {/* <p className={classes.headerText}>Rate</p> */}
                            Distance
                        </Row>
                    </Col>
                </Col>
                <Col style={{ width: "17%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <Row>
                            <p className={classes.headerText}>Tracking Details</p>
                        </Row>
                    </Col>
                </Col>
                <Col style={{ width: "9%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <Row>
                            <p className={classes.headerText}>Tracking Status</p>
                        </Row>
                    </Col>
                </Col>

            </Row>

        </div>
    );
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(ActiveLoadCardHeader)

const useStyles = makeStyles({
    headerText: {
        // fontFamily: 'Arial !important',
        fontSize: '13px',
        color: 'black',
        margin: 0
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    }
});