//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'

//APIs
  import {updateAvailContact} from './api'

function* updateAvailContactFlow(action){
  try {
    const {availabilityId,phone,email} = action;
    const response = yield call(updateAvailContact,availabilityId,phone,email)
    if(response.status){
      yield put({type:"UPDATE_AVAIL_CONTACT_SUC"})
    }else{
      yield put({type:"UPDATE_AVAIL_CONTACT_SUC"})
    }

  } catch (error) {
    console.log(error)

  }
}

function* templateWatcher () {
  yield takeLatest('UPDATE_AVAIL_CONTACT_REQ', updateAvailContactFlow)
}

export default templateWatcher
