import React, { useEffect, useState } from 'react';
import { compose } from 'recompose'
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import useGtagHook from "../../../../gtag-hook"

import Modal from '@material-ui/core/Modal';
import { Row, Col, } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { toast } from "react-toastify"

import LoadingOverlay from '../../LoadingOverlay'

import { GeneralServices } from '../../../customers/general/services';
import {useAuthenticationHook} from '../../../hooks/authentication-hook';
import { checkValueExists } from '../../../../lib/check-value-exists';
import {INPUT_BACKGOUND_COLOR,INPUT_BORDER_COLOR} from '../../../../CarrierPortal/palette'

import CapacityFormUI from './forms/capacity-form'
import LanePreferenceFormUI from './forms/lane-preference-form'
import isMobile from '../../../../HOC/Mobile.js'

const AddPreferenceModal = (props) => {

    const { muiTheme, selectedLocationId, renderedTab, onClose, editItem = null, preferenceType = null, updatePreferencesData ,  isMobile } = props

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: isMobile?'100%':900,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(1, 2, 1),
        },
        dayButton: {
            color: "black"
        },
        textField: {
            border: '0.5px solid '+INPUT_BORDER_COLOR,
            backgroundColor: INPUT_BACKGOUND_COLOR,
            height: '50px',
            width: isMobile ? '100%' : '100%',
            //backgroundColor: 'white',
            paddingLeft: '10px',
            paddingRight: '10px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize:'1rem',
            borderTop:'0px',
            borderLeft:'0px',
            borderRight:'0px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            '& p': {
                fontSize: '14px !important'
            },
            '& label': {
                top: '10px !important',
                left: '10px !important'
            },
        }
    }));

    function getModalStyle() {

        return {
            top: isMobile ? '50%' : `40%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            height: isMobile ? '100%' : 'unset',
            width: isMobile ? '100%' : '800',
            overflow: isMobile ? 'auto' : 'unset'
        };
    }

    const [modalStyle] = useState(getModalStyle);
    const [sendGtagEvent] = useGtagHook()

    const classes = useStyles()

    const isEditing = editItem !== null ? true : false

   

    const [isApiCalling, setIsApiCalling] = useState(false);
    const [capacityInitValue, setCapacityInitValue] = useState(null);
    const [lanePreferenceInitValue, setLanePreferenceInitValue] = useState(null);
    const [formPayload, setFormPayload] = useState(null);
    
    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    useEffect(() => {
        if (editItem !== null) {
            if(checkValueExists(editItem,'rate') && checkValueExists(editItem.rate,'value')){
                editItem.rate.value = parseFloat(editItem.rate.value).toFixed(2)
            }


            let brokerList = []
                if(checkValueExists(editItem, "brokers") && Array.isArray(editItem.brokers) && editItem.brokers.length>0 ){
                    editItem.brokers.map((broker,index) => 
                                            {
                                                    let bObj =    Object.keys(broker).map((key) => 
                                                                                {
                                                                                    let tmpBroker= {
                                                                                        brokerId:parseInt(key),
                                                                                        name:broker[key]
                                                                                        }
                                                                                        brokerList.push(tmpBroker)
                                                                                }
                                                                            )
                                            }
                                        )
                }
                editItem.brokerList = brokerList


                //fix for backend destination state only struckture
                if(checkValueExists(editItem, "destination")){
                    if(editItem.destination.locationSelectType === "SPECIFIC"){
                        if(checkValueExists(editItem.destination, "locationDetails") 
                            && checkValueExists(editItem.destination.locationDetails, "state")
                            && editItem.destination.locationDetails.state !==''
                        ){
                            editItem.destination.locationCustom = [editItem.destination.locationDetails.state]
                        }else{
                            editItem.destination.locationCustom = []
                        }
                        editItem.destination.locationSelectType = "CUSTOM"
                    }
                    
                }


            if(renderedTab==0){
                setCapacityInitValue(editItem) 
            }
            else if(renderedTab==1){
                setLanePreferenceInitValue(editItem) 
            }

            setFormPayload(editItem)
        }
    }, [editItem])

    const handleClose = () => {
        onClose()
    };


    const addOrEditCapacity =()=>{
        let params = validateFormCommonFieldsAndGetParam()
        if(!params){
            return false
        }

        let dateParams = validateFormDateRangeFieldsAndGetParam()
        if(!dateParams){
            return false
        }

        params = {...params,...dateParams}
        // console.log(JSON.stringify(params));
        // return false
        if (isEditing) {
            params.id = editItem.id
            editAvailableCapacity(params)
        } else {
            addAvailableCapacity(params)
        }
    }


    const toastErrorText = (response=null)=>{
        
        let error  = 'There was an issue with your request, please try again later.preference'
        if(response){
            if (checkValueExists(response, "errors")) {
                if(response.errors.isArray()){
                    error =    response.errors.join(' ')
                }else{
                    error =  response.errors
                }
            }
            else if(checkValueExists(response, "message")){
                error =  response.message
            }
            else if(checkValueExists(response,'original') ){
                console.log(response)
                error =  response.original.message
            }
        }
        toast.error(error)
    }

    const addAvailableCapacity = (params) => {      
        setIsApiCalling(true)
        const apiServices = new GeneralServices()
        apiServices.addTruckCapacity(params)
            .then(response => {
                setIsApiCalling(false)
                // console.log("RESPONSE: ", response)
                if (response && response.status) {
                    toast.success("Capacity added successfully. Refreshing list now")
                    sendGtagEvent(
                        "preferences_create_capacity",
                        {
                            pickupLocation: JSON.stringify(params.origin),
                            deliveryLocation: JSON.stringify(params.destination),
                            equipments: JSON.stringify(params.truckTypes),
                            rate: params.rate.value,
                            rateType: params.rate.type,
                            pickupDate: params.availableAt.date,
                            pickupWindow: `${params.availableAt.timeFrom} - ${params.availableAt.timeTo}`,
                            availableAt: JSON.stringify(params.availableAt)  
                        }
                    )
                    finishProcess()
                } 
                else if(checkValueExists(response,'original') &&  response.original.status==true){
                    toast.success("Capacity added successfully. Refreshing list now")
                    sendGtagEvent(
                        "preferences_create_capacity",
                        {
                            pickupLocation: JSON.stringify(params.origin),
                            deliveryLocation: JSON.stringify(params.destination),
                            equipments: JSON.stringify(params.truckTypes),
                            rate: params.rate.value,
                            rateType: params.rate.type,
                            pickupDate: params.availableAt.date,
                            pickupWindow: `${params.availableAt.timeFrom} - ${params.availableAt.timeTo}`,
                            availableAt: JSON.stringify(params.availableAt)  
                        }
                    )
                    finishProcess()
                }
                else {
                    toastErrorText(response)
                }
            })
            .catch(error => {
                setIsApiCalling(false)
                toastErrorText()
                onClose()
            })
    }

    const editAvailableCapacity = (params) => {
        setIsApiCalling(true)
        const apiServices = new GeneralServices()
        apiServices.editTruckCapacity(params)
            .then(response => {
                setIsApiCalling(false)
                // console.log("RESPONSE: ", response)
                if (response && response.status) {
                    toast.success("Capacity edited successfully. Refreshing list now")
                    sendGtagEvent(
                        "preferences_edit_capacity",
                        {
                            pickupLocation: JSON.stringify(params.origin),
                            deliveryLocation: JSON.stringify(params.destination),
                            equipments: JSON.stringify(params.truckTypes),
                            rate: params.rate.value,
                            rateType: params.rate.type,
                            pickupDate: params.availableAt.date,
                            pickupWindow: `${params.availableAt.timeFrom} - ${params.availableAt.timeTo}`,
                            availableAt: JSON.stringify(params.availableAt) 

                        }
                    )
                    finishProcess()
                }
                else if(checkValueExists(response,'original') &&  response.original.status==true){
                    toast.success("Capacity edited successfully. Refreshing list now")
                    sendGtagEvent(
                        "preferences_edit_capacity",
                        {
                            pickupLocation: JSON.stringify(params.origin),
                            deliveryLocation: JSON.stringify(params.destination),
                            equipments: JSON.stringify(params.truckTypes),
                            rate: params.rate.value,
                            rateType: params.rate.type,
                            pickupDate: params.availableAt.date,
                            pickupWindow: `${params.availableAt.timeFrom} - ${params.availableAt.timeTo}`,
                            availableAt: JSON.stringify(params.availableAt)  
                        }
                    )
                    finishProcess()
                }
                 else {
                    toastErrorText(response)
                }
            })
            .catch(error => {
                setIsApiCalling(false)
                toastErrorText()
                onClose()
            })
    }


    const addOrEditLanePreferencey =()=>{
        
        let params = validateFormCommonFieldsAndGetParam()
        if(!params){
            return false
        }

        let dateParams = validateFormDateRangeFieldsAndGetParam()
        if(!dateParams){
            return false
        }

        let publishAvailability = checkValueExists(formPayload, "publishAvailability") ? formPayload.publishAvailability : false
        params.visibility = publishAvailability?publishAvailability:false

        params = {...params,...dateParams}
        // console.log(JSON.stringify(params));
        // return false
        if (isEditing) {
            params.id = editItem.id
            editLanePreference(params)
        } else {
            addLanePreference(params)
        }
    }

    const addLanePreference = (params) => {
        setIsApiCalling(true)
        const apiServices = new GeneralServices()
        apiServices.addLanePreference(params)
            .then(response => {
                setIsApiCalling(false)
                // console.log("RESPONSE: ", response)
                if (response && response.status) {
                    toast.success("Lane preference added successfully. Refreshing list now")
                    sendGtagEvent(
                        "preferences_create_lane",
                        {
                            pickupLocation: JSON.stringify(params.origin),
                            deliveryLocation: JSON.stringify(params.destination),
                            equipments: JSON.stringify(params.truckTypes),
                            rate: params.rate.value,
                            rateType: params.rate.type,
                            pickupDate: params.availableAt.date,
                            pickupWindow: `${params.availableAt.timeFrom} - ${params.availableAt.timeTo}`,
                            availableAt: JSON.stringify(params.availableAt),
                            isPublished: params.visibility  
                        }
                    )
                    finishProcess()
                }
                else if(checkValueExists(response,'original') &&  response.original.status==true){
                    toast.success("Lane preference added successfully. Refreshing list now")
                    sendGtagEvent(
                        "preferences_create_lane",
                        {
                            pickupLocation: JSON.stringify(params.origin),
                            deliveryLocation: JSON.stringify(params.destination),
                            equipments: JSON.stringify(params.truckTypes),
                            rate: params.rate.value,
                            rateType: params.rate.type,
                            pickupDate: params.availableAt.date,
                            pickupWindow: `${params.availableAt.timeFrom} - ${params.availableAt.timeTo}`,
                            availableAt: JSON.stringify(params.availableAt),
                            isPublished: params.visibility    
                        }
                    )
                    finishProcess()
                }
                else {
                    toastErrorText(response)
                }
            })
            .catch(error => {
                setIsApiCalling(false)
                toastErrorText()
                onClose()
            })
    }

    const editLanePreference = (params) => {
        

        setIsApiCalling(true)
        const apiServices = new GeneralServices()
        apiServices.editLanePreference(params)
            .then(response => {
                setIsApiCalling(false)
                // console.log("RESPONSE: ", response)
                if (response && response.status) {
                    toast.success("Lane preference edited successfully. Refreshing list now")
                    sendGtagEvent(
                        "preferences_edit_lane",
                        {
                            pickupLocation: JSON.stringify(params.origin),
                            deliveryLocation: JSON.stringify(params.destination),
                            equipments: JSON.stringify(params.truckTypes),
                            rate: params.rate.value,
                            rateType: params.rate.type,
                            pickupDate: params.availableAt.date,
                            pickupWindow: `${params.availableAt.timeFrom} - ${params.availableAt.timeTo}`,
                            availableAt: JSON.stringify(params.availableAt),
                            isPublished: params.visibility    
                        }
                    )
                    finishProcess()
                }
                else if(checkValueExists(response,'original') &&  response.original.status==true){
                    toast.success("Lane preference edit successfully. Refreshing list now")
                    sendGtagEvent(
                        "preferences_edit_lane",
                        {
                            pickupLocation: JSON.stringify(params.origin),
                            deliveryLocation: JSON.stringify(params.destination),
                            equipments: JSON.stringify(params.truckTypes),
                            rate: params.rate.value,
                            rateType: params.rate.type,
                            pickupDate: params.availableAt.date,
                            pickupWindow: `${params.availableAt.timeFrom} - ${params.availableAt.timeTo}`,
                            availableAt: JSON.stringify(params.availableAt),
                            isPublished: params.visibility    
                        }
                    )
                    finishProcess()
                } else {
                    toastErrorText(response)
                }
            })
            .catch(error => {
                setIsApiCalling(false)
                toastErrorText()
                onClose()
            })
    }

    const finishProcess = ()=>{
        updatePreferencesData()
        onClose()
    }

    const validateFormCommonFieldsAndGetParam =  ()=>{
        if(!formPayload){
            toast.error("Please fillup the form.")
            return false
        }

        let origin = checkValueExists(formPayload, "originLocation") ? formPayload.originLocation : null ;
        let originLocation = origin && checkValueExists(origin, "locationDetails") ? origin.locationDetails : null
        let originType = origin && checkValueExists(origin, "locationSelectType") ? origin.locationSelectType : null
        let originZone = origin && checkValueExists(origin, "locationZone") ? origin.locationZone : null
        let originCustom = origin && checkValueExists(origin, "locationCustom") ? origin.locationCustom : null

        let destination = checkValueExists(formPayload, "destinationLocation") ? formPayload.destinationLocation : null ;
        let destinationLocation = destination && checkValueExists(destination, "locationDetails") ? destination.locationDetails : null
        let destinationType = destination && checkValueExists(destination, "locationSelectType") ? destination.locationSelectType : null
        let destinationZone = destination && checkValueExists(destination, "locationZone") ? destination.locationZone : null
        let destinationCustom = destination && checkValueExists(destination, "locationCustom") ? destination.locationCustom : null
        
        let equipmentType = checkValueExists(formPayload, "equipmentType") ? formPayload.equipmentType : null

        let rateDetail = checkValueExists(formPayload, "rate") ? formPayload.rate : null
        let rate = rateDetail && checkValueExists(rateDetail, "value") ? rateDetail.value : ""
        let rateType = rateDetail && checkValueExists(rateDetail, "type") ? rateDetail.type : null

        
        let receiveNotifications = checkValueExists(formPayload, "receiveNotifications") ? formPayload.receiveNotifications : 1
        let onHold = checkValueExists(formPayload, "onHold") ? formPayload.onHold : null
        

        if (originType === "SPECIFIC" &&
            (originLocation === null || !checkValueExists(originLocation, "city"))) {
                toast.error("Origin location is required. Please enter a city & state.")
                return false
        }
        else if (originType === "ZONE" && (originZone === null)) {
            toast.error("Origin is required. Please select a zone from the dropdown.")
            return false
        }
        else if (originType === "CUSTOM" && (originCustom === null)) {
            toast.error("Origin is required. Please select a states from the dropdown.")
            return false
        }
        else if (destinationType === "SPECIFIC" &&
            (destinationLocation === null || !checkValueExists(destinationLocation, "state"))) {
            toast.error("Destination location is required. Please enter a state.")
            return false
        } else if (destinationType === "ZONE" && (destinationZone === null)) {
            toast.error("Destination is required. Please select a zone from the dropdown.")
            return false
        }
        else if (destinationType === "CUSTOM" && (destinationCustom === null)) {
            toast.error("Destination is required. Please select a states from the dropdown.")
            return false
        } else if (equipmentType === null || equipmentType.length==0) {
            toast.error("Equipment type is required. Please select from the drop down.")
            return false
        }
        
        // else if (rate === "") {
        //     toast.error("Rate is required. Please enter a target flat rate.")
        //     return false
        // } 
        // else if (rate !== "" && isNaN(rate)) {
        //     toast.error("Please enter a valid rate.")
        //     return false
        // }

        let rateValue = ''
        if (rate !== "" && !isNaN(rate)) {
            rateValue = parseFloat(rate).toFixed(2);
        }


        if (originType === "SPECIFIC" &&
            (originLocation !== null && !checkValueExists(originLocation, "zipCode"))) {
              origin.locationDetails.zipCode = null
        }
        
        if (originType === "CUSTOM" ) {
            origin.locationSelectType = "CUSTOM"
            origin.locationDetails = null
            origin.locationZone = null
        }else if (originType === "ZONE" ) {
            origin.locationSelectType = "ZONE"
            origin.locationDetails = null
            origin.locationCustom = null
        }else if (originType === "SPECIFIC" ) {
            origin.locationSelectType = "SPECIFIC"
            origin.locationZone = null
            origin.locationCustom = null
        }
        
        if (destinationType === "CUSTOM" ) {
            destination.locationSelectType = "CUSTOM"
            destination.locationDetails = null
            destination.locationZone = null
        }else if (destinationType === "ZONE" ) {
            destination.locationSelectType = "ZONE"
            destination.locationDetails = null
            destination.locationCustom = null
        }else if (destinationType === "SPECIFIC" ) {
            destination.locationSelectType = "SPECIFIC"
            destination.locationZone = null
            destination.locationCustom = null
        }
        
        let rateData = {
            value:rateValue,
            type:rateType,
        }

        let brokerList = checkValueExists(formPayload, "brokerList") ? formPayload.brokerList : [] ;
        let brokers = []
        if(brokerList.length>0){
            brokers = brokerList.map((broker,index) => broker.brokerId )
        }


        let params ={
            origin : origin,
            destination:destination,
            truckTypes:equipmentType,
            rate:rateData,
        }

        if(brokers.length>0){
            params.brokerIds= brokers
        }

        return params
    }



    const validateFormDateRangeFieldsAndGetParam =  ()=>{
        if(!formPayload){
            toast.error("Please fillup the form.")
            return false
        }

        
        
        
        let date = checkValueExists(formPayload, "date") ? formPayload.date : null
        if (date === null) {
            if(renderedTab==0){
                toast.error("Please select a an available date")
            }
            else if(renderedTab==1){
                toast.error("Please select a an available days of the week")
            }
            return false
        }

        let dateAvailable = checkValueExists(date, "dateAvailable") ? date.dateAvailable : null
        let isCustomeTimeRange = checkValueExists(date, "isCustomeTimeRange") ? date.isCustomeTimeRange : false
        let specificTimeRange = checkValueExists(date, "specificTimeRange") ? date.specificTimeRange : null
        let specificTimeRangeValues = checkValueExists(date, "specificTimeRangeValues") ? date.specificTimeRangeValues : null
        let customeTimeRangeStart = checkValueExists(date, "customeTimeRangeStart") ? date.customeTimeRangeStart : null
        let customeTimeRangeEnd = checkValueExists(date, "customeTimeRangeEnd") ? date.customeTimeRangeEnd : null
        let daysOfWeek = checkValueExists(date, "daysOfWeek") ? date.daysOfWeek : []

        if (moment(dateAvailable).startOf('day').valueOf() < moment().startOf('day').valueOf()) {
            toast.error("Please Select A Future Date Time")
            return false;
        }

        if(specificTimeRangeValues && customeTimeRangeStart && customeTimeRangeEnd && dateAvailable){
            let params = {}

            let dateAvailableAt = {}
            let range = {}

            if(!isCustomeTimeRange){
                dateAvailableAt = {
                    start : dateAvailable + ' '+specificTimeRangeValues.start,
                    end : dateAvailable + ' '+specificTimeRangeValues.end,
                }
                range = {
                    start : specificTimeRangeValues.start,
                    end : specificTimeRangeValues.end,
                }
            }else{
                dateAvailableAt = {
                    start : dateAvailable + ' '+customeTimeRangeStart,
                    end : dateAvailable + ' '+customeTimeRangeEnd,
                }
                range = {
                    start : customeTimeRangeStart,
                    end : customeTimeRangeEnd,
                }
            }


            if (daysOfWeek.length==0 && renderedTab==1 ) {
                    toast.error("Please select available days.")
                    return false
            }

            if(checkValueExists(dateAvailableAt, "start") && checkValueExists(dateAvailableAt, "end")){
                let diff = moment(dateAvailableAt.end,'YYYY-MM-DD HH:mm T').diff(moment(dateAvailableAt.start,'YYYY-MM-DD HH:mm T'), 'minutes')
                if(diff<=0){
                    toast.error("Please select end time heigher than start time.")
                    return false
                }

            }else{
                toast.error("Invalid date time.")
                return false
            }

            params.timeFrom = range.start
            params.timeTo   = range.end


            if(renderedTab==0){
                params.date = dateAvailable
            }
            else if(renderedTab==1){
                params.weekDays = daysOfWeek
            }


            let retObj = {
                availableAt : params
            }


            return retObj

        }else{
            toast.error("Invalid date time.")
            return false
        }

    }



    const addPreference = () => {
        if (renderedTab === 0) {
            addOrEditCapacity()
        } else if (renderedTab === 1) {
            addOrEditLanePreferencey()
        }
    }


    const onCapacityFormCompleteProcess = (formData)=>{
        setFormPayload(formData)
    }

    const onLanePreferenceFormCompleteProcess = (formData)=>{
        setFormPayload(formData)
    }
    

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h3 style={{ marginBottom: 0, textTransform: "uppercase" }}>{isEditing ? "Edit" : "Add"} {renderedTab === 0 ? "Capacity" : "Lane Preference"}</h3>
            <Row>
                {renderedTab === 0 && (<CapacityFormUI initialValues={capacityInitValue} onFormComplete={(value)=>onCapacityFormCompleteProcess(value)} />)}
                {renderedTab === 1 && (<LanePreferenceFormUI initialValues={lanePreferenceInitValue} onFormComplete={(value)=>onLanePreferenceFormCompleteProcess(value)} />)}
            </Row>
            <Row style={{ width: "80%", marginTop: 40, marginLeft: "10%", marginRight: "10%", display: "flex", justifyContent: "center" }}>
                <Col style={{ marginRight: 10 }}>
                    <Button
                        // variant={"contained"}
                        size={"small"}
                        style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "transparent" }}
                        onClick={() => handleClose()}
                        disabled={isApiCalling}
                    >
                        CANCEL
                        </Button>
                </Col>
                <Col style={{ marginLeft: 10 }}>
                    <Button
                        variant={"contained"}
                        size={"small"}
                        style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor }}
                        onClick={() => addPreference()}
                        disabled={isApiCalling}
                    >
                        SAVE
                        </Button>
                </Col>
            </Row>
        </div>
    );

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <span>
                {isApiCalling && <LoadingOverlay />}

            {body}
            </span>
        </Modal>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(AddPreferenceModal)