import { combineReducers } from 'redux'
//CARRIER PORTAL REDUCER//
//MESSAGING
import * as MESSAGES from './constants'

//REDUCERS//
import MatchesReducer from './ActionReducers/matches'
import SearchReducer from './ActionReducers/search'
import ComponentsReducer from './ActionReducers/components'
import BrokerStats from './ActionReducers/brokerStats'
import LoadFilterReducer from "./ActionReducers/loadFilterReducer"
import FuelStopFilterReducer from "./ActionReducers/fuelStopFilterReducer"
import LoginSignupReducer from "./ActionReducers/loginSignupReducer"
import TireFilterReducer from './ActionReducers/tireFilterReducer'

const CarrierPortalReducer = combineReducers({
  matches: MatchesReducer,
  search: SearchReducer,
  components: ComponentsReducer,
  brokers: BrokerStats,
  loadFilters: LoadFilterReducer,
  fuelStopFilters: FuelStopFilterReducer,
  tireFilters: TireFilterReducer,
  loginSignupReducer: LoginSignupReducer
})

export default CarrierPortalReducer
