import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../HOC/Mobile.js'

import CarrierLoadList from '../../components/load-list'

const Invoices = (props) => {

    const {
        currentLoadList, 
        isLoading,
        refreshInvoices
    } = props

    const [refreshList, setRefreshList] = useState(false)

    return (
        <Col style={{ height: '100%' }}>
            {   !props.isMobile &&
                <Row style={{ height: 50, width: "100%", marginTop: 15, marginBottom: 0 }}>
                    <Col style={{ display: 'inline-block' }}>
                        <h2 style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 15, }}>
                            Your Completed Loads
                        </h2>
                    </Col>
                </Row>
            }
            <Row style={{ height: `calc(100% - ${props.isMobile ? '0px' : '50px'})` }}>
                <CarrierLoadList
                    key={refreshList}
                    availability={props.availability}
                    unfilterLoadList={currentLoadList}
                    isMobile={props.isMobile}
                    onInvoicesScreen
                    autoHeight
                    refreshInvoices={refreshInvoices}
                />
            </Row>
        </Col>
    )
}

const mapStateToProps = state => ({
    availability: state.AvailabilityLoads.payload.availability,
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile()
)(Invoices)