import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
//COMPONENTS//
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import HelpWrapper from '../HelpWrapper'
import { InputLabel } from '@material-ui/core';

//ACTIONS//
import { updateLoadSearchBar } from '../../../CarrierPortal/ActionReducers/components'
import {useAuthenticationHook} from '../../hooks/authentication-hook';


const BACKEND_URL = `${process.env.REACT_APP_SC_URL}`; // move this to call to sagas file

const TruckTypeSelect = ({
  //PROPS FROM PARENT//
  value = [], showErrorOnEmpty = true,
  hintText = 'Pick any truck types you are interested in ',
  floatingLabelText = 'Truck Types',
  needsRedux = true,
  handleSetTruckType,
  clearData,
  existingTruckTypes = null,
  //REDUX//
  selectedTruckTypes,
  updateSearchbox,
  updateLoadSearchBar,
  //STATE
  //truckTypesList,
  //OTHER
  muiTheme,
  mobileView = false
}) => {

  const useStyles = makeStyles({
    root: {
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.08,
      margin: 10
    },
    wrapper: {
      width: '85%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      lineHeight: '24px',
    },
    placeholder: {
      color: '#757575',
    },
    menuItem: {
      fontSize: '16px'
    },
    selectfieldStyle: {
      border: '1px solid #ccc',
      height: '45px',
      maxHeight: '45px',
      width: '100%',
      maxWidth: mobileView ? '100%' : '200px',
      minWidth: '250px',
      margin: '0px',
      overflow: 'visible',
      backgroundColor: 'white',
      // outline: '1px solid black',
      paddingLeft: '10px',
      fontSize: '16px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      // whiteSpace: 'nowrap',
      // textOverflow: 'ellipsis',
      // overflow: 'hidden'
    },
    labelText : {
      fontSize: 16,
      top: -5,
      left: -3
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
      textAlign: 'right'
    },
    removePadding: {
      paddingRight: '0 !important',
      marginTop: 14
    },
    chips: {
      fontSize: '12px',
      height: '22px'
    }
  });
  const classes = useStyles();

  const [hasError, setErrors] = useState(false);
  const [truckTypes, setTruckTypes] = useState([]);
  const [finalSelectedTypesList, setFinalSelectedTypesList] = useState([]); // Array of selected truck types
  const [menuOpen, setMenuOpen] = useState(false);
  const isMounted = useRef(false);
  const selectBox = useRef(null)

  const myAuthService = useAuthenticationHook()

  useEffect(() => {
    getTruckTypes();
    if (existingTruckTypes) {
      let tmpList = existingTruckTypes.map(type => { return type.code })
      setFinalSelectedTypesList(tmpList)
    }
    return () => {
      setTruckTypes([]); // NOTE: Issue when using this clean up function
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setFinalSelectedTypesList([])
      updateLoadSearchBar([], 'truckTypes')
    } else {
      isMounted.current = true
    }

  }, [clearData])

  async function getTruckTypes() {
    // TODO move this to sagas
    let url = '/api/getTruckCodes'
    var requestUrl = BACKEND_URL + url
    let requestObject = {
      method: 'GET',
    }
    // let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

    const res = await fetch(requestUrl, requestObject);

    res.json().then(response => {
      if (response.status) {
        let truckTypesRes = response.truckCodes
        setTruckTypes(truckTypesRes);
      }
    }).catch(error => setErrors(error));
  }


  function handleChange(event, modifiedTruckTypes) {

    // 'value' here represents the truck type ids selected from dropdown as in the value of the event target
    //or it equals the modified list after deleting from the chip 'x' button
    let chosenTruckTypes = null
    if (modifiedTruckTypes)
      chosenTruckTypes = modifiedTruckTypes
    else
      chosenTruckTypes = event.target.value

    finalSelectedTypesList.forEach(type => {
      if (type.code != null)
        chosenTruckTypes.push(type.code)
    })

    // Below can be used in case truck type names are needed
    let truckTypesSelected = truckTypes.filter((type) => {
      return chosenTruckTypes.indexOf(type.code) >= 0
    })

    let finalSelection = []
    truckTypesSelected.forEach(type => {
      if (type.code != null)
        finalSelection.push(type.code)
    })

    setFinalSelectedTypesList(finalSelection);

    updateLoadSearchBar(truckTypesSelected, 'truckTypes');

    if (!needsRedux) { handleSetTruckType(truckTypesSelected) }

  };

  const handleChipDelete = (truckTypeToDelete) => () => {

    let modifiedTruckTypes = [...finalSelectedTypesList]
    modifiedTruckTypes = modifiedTruckTypes.filter((truckType) => truckType !== truckTypeToDelete);

    handleChange(null, modifiedTruckTypes)

  };


  function renderSelectedTruckTypes(selected) {

    let chipMax = 2
    if(selectBox.current){
      chipMax = selectBox.current.clientWidth <= 300 ? 2 : selectBox.current.clientWidth <= 400 ? 2 : selectBox.current.clientWidth <= 600 ? 4 : 6
    }

    return (
        <div className={classes.wrapper}>
          {
            selected.map((value, index) => {
              if (mobileView & index >= chipMax)
                return index === chipMax ? <span style={{ margin: 5, fontSize: 18 }}>&hellip;</span> : null
              return (
                <Chip
                  variant="outlined"
                  key={value}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  label={getTypeName(value)}
                  className={classes.chips}
                  onDelete={handleChipDelete(value)}
                />
              )
            })
          }
        </div>
    )
  }

  // Takes in array of trucktype ids and returns comma separate string of trucktype names
  function idsToNames(ids) {
    let truckTypeArr = truckTypes.filter((type) => ids.includes(type.truckTypeId));

    let truckTypeNamesArr = truckTypeArr.map(type => type.name)

    if (truckTypeNamesArr) {
      return truckTypeNamesArr.join(",");
    }
    return null;
  }

  function getTypeName(typeCode) {
    let truckType = truckTypes.find(type => typeCode === type.code)
    if (truckType) {
      return truckType.name
    }
  }

  return (
    <HelpWrapper text='Select your equipment types so we can find loads that match. (Click anywhere outside once finished)' location='bottom' zIndex={1350} enabled={!menuOpen}>
      {truckTypes &&
        <div>
          <InputLabel  
            variant={'filled'}
            id="selected-label"
            className={classes.labelText}
          > 
            Equipment Type 
          </InputLabel>
          <Select
            ref={selectBox}
            className={classes.selectfieldStyle}
            labelId="select-label"
            multiple
            disableUnderline={true}
            autoWidth={false}
            style={{marginTop:0}}
            displayEmpty
            input={<Input id="truck-type" />}
            value={finalSelectedTypesList}
            onChange={(event) => handleChange(event)}
            renderValue={(selected) => renderSelectedTruckTypes(selected)}
            inputProps={{
              classes: {
                icon: classes.icon,
                root: classes.removePadding
              },
            }}
            MenuProps={{
              autoFocus: false,
              disableScrollLock: true,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              }
            }}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
          >
            <MenuItem
              className={classes.menuItem}
              value=""
              disabled>
              Select Equipment
            </MenuItem>
            {truckTypes && truckTypes.map((type) => {
              return (
                <MenuItem
                  key={type.code}
                  className={classes.menuItem}
                  value={type.code}
                >
                  <Checkbox checked={finalSelectedTypesList.indexOf(type.code) > -1} />
                  {type.name}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      }
    </HelpWrapper>
  );
}

// TruckTypeSelect.propTypes={
//   //label:PropTypes.string.isRequired
// }

const mapStateToProps = state => ({
  selectedTruckTypes: state.CarrierPortal.components.loadSearchBar.truckTypes,
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    updateLoadSearchBar: updateLoadSearchBar
  }, dispatch)
}

const connected = connect(mapStateToProps, matchDispatchToProps)(TruckTypeSelect);

export default connected;
