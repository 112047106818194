import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {BaseAuthService as BaseAuthServiceAuth0} from "./CarrierPortal/authentication/check-auth";
import {BaseAuthService} from "./CarrierPortal/authentication/check-auth-old";

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }

    const GA_MEASUREMENT_ID = 'G-T99V8CFHKM'

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-T99V8CFHKM";
        script.async = true;
        document.body.appendChild(script);

        gtag('js', new Date());

        gtag('config', GA_MEASUREMENT_ID);

        setInitialized(true)

    }, []);

    useEffect(() => {
        let baseAuthService
        if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b") {
            baseAuthService = new BaseAuthServiceAuth0()
        } else {
            baseAuthService = new BaseAuthService()
        }

        if (initialized) {
            let page_path = location.pathname + location.search // location.search is nothing
            let measurement_id = process.env.REACT_APP_GA_MEASUREMENT_ID // getMeasurementId()

            let pathname_split = location.pathname.split("/")
            let page_title = pathname_split[pathname_split.length - 1]

            let user = baseAuthService.getUser()
            if (user != null)
                gtag('config', measurement_id, { 'page_path': page_path, 'page_title': page_title, 'user_id': user.userId })
            else
                gtag('config', measurement_id, { 'page_path': page_path, 'page_title': page_title })
        }
    }, [initialized, location]);

    const getMeasurementId = () => {
        // depending on window pathname includes advantage id => dynamically use different trackingIds in intialize
        if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
            // Werner Carrier Edge
            return "G-YS11DJVRBH"
        } else if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b") {
            // England Logistics
            return "G-X1BFH99P8W"
        } else if (process.env.REACT_APP_ADVANTAGE_ID == "ju9agnj4") {
            // Blue Grace Logistics
            return "G-HGS4CF25X9"
        } else if (process.env.REACT_APP_ADVANTAGE_ID == "r85xb5y8") {
            // Reed TMS
            return "G-DJKLQCD332"
        } else if (process.env.REACT_APP_ADVANTAGE_ID == "srukfk5e") {
            // Ryder Managed Services
            return "G-0DBPXQSEHK"
        } else if (process.env.REACT_APP_ADVANTAGE_ID == "k6g3f58q") {
            //Kingsgate Logistics
            return "G-ZVB7BJZYKQ"
        } else {
            return GA_MEASUREMENT_ID
        }
    }
};

export default usePageTracking;