import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import FA from 'react-fontawesome'
import * as FeatherIcon from 'react-feather';
import { Col, Row } from "react-bootstrap"

import { API_PROD_URL } from "../../../../../api/constants"


const Weather = (props) => {

    const { item, muiTheme, isMobile = false } = props
    const classes = useStyles();

    const [weatherData, setWeatherData] = useState(null)


    // // FONT AWESOME 5
    // const iconToWeatherMap = {
    //     'sky is clear': 'cloud-sun', //sun
    //     'clear sky': 'cloud-sun', //sun
    //     'few clouds': 'cloud', //cloud
    //     'overcast clouds': 'cloud',//cloud
    //     'scattered clouds': 'cloud-sun',//cloud
    //     'broken clouds': 'cloud',//cloud
    //     'shower rain': 'cloud-showers-heavy',
    //     'rain': 'cloud-rain',//cloud-rain
    //     'light rain': 'cloud-rain',//cloud-rain
    //     'thunderstorm': 'poo-storm',//cloud-lightning
    //     'snow': 'snowflake',//cloud-snow 
    //     'mist': 'cloud-rain'
    // }

    // FEATHER
    const getIcon = (description) => {
        switch (description) {
            case 'sky is clear':
                return <FeatherIcon.Sun color={"orange"} size={30} />
            case 'clear sky':
                return <FeatherIcon.Sun color={"orange"} size={30} />
            case 'few clouds':
                return <FeatherIcon.Cloud color={"darkgrey"} size={30} />
            case 'overcast clouds':
                return <FeatherIcon.Cloud color={"darkgrey"} size={30} />
            case 'scattered clouds':
                return <FeatherIcon.Cloud color={"darkgrey"} size={30} />
            case 'broken clouds':
                return <FeatherIcon.Cloud color={"darkgrey"} size={30} />
            case 'shower rain':
                return <FeatherIcon.CloudRain color={"steelblue"} size={30} />
            case 'rain':
                return <FeatherIcon.CloudRain color={"steelblue"} size={30} />
            case 'light rain':
                return <FeatherIcon.CloudRain color={"steelblue"} size={30} />
            case 'thunderstorm':
                return <FeatherIcon.CloudLightning color={"darkgrey"} size={30} />
            case 'snow':
                return <FeatherIcon.CloudSnow color={"lightskyblue"} size={30} />
            case 'mist':
                return <FeatherIcon.CloudRain color={"steelblue"} size={30} />
        }
    }

    const getDestinationWeather = () => {
        try {
            const GET_WEATHER_URL = "/poi/getWeather?latitude=" + item.latitude + "&longitude=" + item.longitude
            let url = API_PROD_URL + GET_WEATHER_URL
            let requestObject = {
                method: 'GET',
            }
            fetch(url, requestObject)
                .then(response => {
                    if (!response.ok) {
                        const error = new Error(response.statusText);
                        error.response = response;
                        throw error;
                    }
                    response.json().then(jsonData => {
                        // console.log("HERE: ", jsonData)
                        setWeatherData(jsonData)
                        // return jsonData
                    })
                })
                .catch(error => {
                    // console.log("LOCATIONS ERROR: ", error)
                })
        }
        catch (e) {
            // console.log("weather error: ", e)
        }
    }

    useEffect(() => {
        getDestinationWeather()
    }, [])

    if (weatherData == null) {
        return null
    } else {
        // console.log("weatherData.weather.description: ", weatherData["weather"][0]["description"])
        if (isMobile) {
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
                    <span style={{ marginRight: 10 }}>{getIcon(weatherData["weather"][0]["description"])}</span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p className={classes.valueText}>
                            {Math.round(weatherData.main.temp)}{"\xB0"} {weatherData["weather"][0]["description"]}
                        </p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ width: "100%", height: "100%", display: "flex" }}>
                    {/* <span style={{ marginRight: 10 }}>{getIcon(weatherData["weather"][0]["description"])}</span>
                    <div>
                        <p className={classes.valueText}>{Math.round(weatherData.main.temp)}{"\xB0"}</p>
                        <p className={classes.valueText}>{weatherData["weather"][0]["description"]}</p>
                    </div> */}
                    <Col md={3}>
                        <span style={{}}>{getIcon(weatherData["weather"][0]["description"])}</span>
                    </Col>
                    <Col md={9}>
                        <p className={classes.valueText}>{Math.round(weatherData.main.temp)}{"\xB0"}</p>
                        <p className={classes.valueText}>{weatherData["weather"][0]["description"]}</p>
                    </Col>
                </div>
            )
        }

    }
}

export default compose(
    connect(),
    muiThemeable()
)(Weather)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word'
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '14px',
        color: 'grey'
    },
    valueText: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'black'
    },
});