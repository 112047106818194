/*global google*/
import {
  REG_CARRIER,
  REG_CARRIER_SUCCESS,
  REG_CARRIER_ERROR,
  GET_REG_CARRIER,
  GET_REG_CARRIER_SUCCESS,
  GET_REG_CARRIER_ERROR,
  
} from './constants'

const initialState = {
  requesting: false,
  successful: false,
  
  regResponse: [],
  messages: [],
  errors: [],
  regSucessCall: false,
}

const reducer = function confirmRegistrationReducer (state = initialState, action) {
  switch (action.type) {

    
    case GET_REG_CARRIER_SUCCESS:
      console.log('GET_REG_CARRIER_SUCCESS');
      console.log(action);
      return {
        regResponse : [],
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        regSucessCall: true,
        
      }
       
    
    case GET_REG_CARRIER_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        regSucessCall: false,
      }
    
    
    case REG_CARRIER_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        regSucessCall: false,
      }
    
    case REG_CARRIER_SUCCESS:
      console.log('REG_CARRIER_SUCCESS');
      console.log(action);
      return {
        regResponse : action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        regSucessCall: true,
        
      }
      
      default : return state
    
  }
}

export default reducer