//MESSAGING
  import * as TYPE from './constants'

const initialState = {  
  requesting: false,
  initLoading: false,
  requesting: false,
  successful: false,
  importmessages: [],
  importerrors: [],
}

const reducer = function addCapacityReducer (state = initialState, action) {  
  switch (action.type) {
	  
	case TYPE.CALL_IMPORT_CARRIER_CAPACITY_SUCCESS:
      return {
        importerrors: [],
        importmessages: [action.response],
        requesting: false,
        successful: true,
      }
	  
	case TYPE.CALL_IMPORT_CARRIER_CAPACITY_ERROR:
      return {
        ...state,
        importerrors: [action.error],
        importmessages: [],
        requesting: false,
        successful: false,
      }
	  
	case TYPE.CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_SUCCESS:
      return {
        importerrors: [],
        importmessages: [action.response],
        requesting: false,
        successful: true,
      }
	  
	case TYPE.CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_ERROR:
      return {
        ...state,
        importerrors: [action.response],
        importmessages: [],
        requesting: false,
        successful: false,
      }

    default:
      return state
  }
}



export default reducer  