
import React from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Col } from "react-bootstrap"
import moment from 'moment'
import FA from 'react-fontawesome'
import { validLoadDetails } from "../../../../../../../lib/valid-load-details"

import CounterOfferButton from '../../../CounterOfferButton'
import BinButton from "../../../../bin/binButton"


const RateNegotiationsListItemDesktop = (props) => {

    const { 
        quote, 
        load, 
        latestOffer, 
        showBinBtn, 
        showCounterButton,
        quoteStatus,
        submitQuoteNegotiation 
    } = props
    const classes = useStyles();


    const getIcon = () => {
        if (quote.message_type === "QUOTE_B") {
            if (quote.rejected) {
                return (
                    <div key="reject-icon">
                        <FA name='minus' style={{ fontSize: 16, color: props.muiTheme.ttRed }} />
                    </div>
                )
            }
            else if (quote.accepted) {
                return (
                    <div key="accept-icon">
                        <FA name='check' style={{ fontSize: 16, color: 'green' }} />
                    </div>
                )
            }
            else {
                return (
                    <div key="recieve-icon">
                        <FA name='arrow-left' style={{ fontSize: 16, color: 'green', transform: 'rotate(-45deg)' }} />
                    </div>
                )
            }
        }
        else if (quote.message_type === "QUOTE_D" || quote.message_type === "QUOTE_C") {
            return (
                <div key="sent-icon">
                    <FA name='arrow-right' style={{ fontSize: 16, color: '#4068B0', transform: 'rotate(-45deg)' }} />
                </div>
            )
        }
        else if (quote.message_type === "BOOKITNOW") {
            return (
                <div key="accept-icon">
                    <FA name='check' style={{ fontSize: 16, color: 'green' }} />
                </div>
            )
        }
    }

    return (
        <div style={{ display: 'flex', width: '100%' }}>

            <Col style={{ width: "19%", display: 'inline-flex', paddingRight: 15 }}>
                <Col>
                    {quote.quote_value ?
                        <p className={classes.mainText}>${parseFloat(quote.quote_value).toFixed(2)}</p>
                        :
                        <p className={classes.mainText}> <i>Unknown Offer Amount</i></p>
                    }
                    <p className={classes.secondaryText}>{moment.tz(quote.updated_at, "America/New_York").local().format('ll HH:mm')}</p>
                </Col>
                {quote.message_type &&
                    <Col style={{ paddingLeft: 15, justifyContent: 'flex-start', flexDirection: 'column', display: 'flex' }}>
                        {getIcon()}
                    </Col>
                }
            </Col>
            <Col style={{ width: "27%", paddingRight: 15 }}>
                <p>
                    {quoteStatus}
                </p>
            </Col>
            <Col style={{ width: "27%", paddingRight: 15, wordBreak: 'break-word' }}>
                <p>
                    {quote.message}
                </p>
            </Col>
            <Col className={classes.centerColContent} style={{ width: "27%" }}>
                {showBinBtn && latestOffer && validLoadDetails(load, ["weight"]) &&
                    <BinButton 
                        key={"BIN_button"}
                        carrierId={props.availability.carrier_id}
                        availabilityId={props.availability.id}
                        load={load}
                        index={0}
                        pickup={props.pickup}
                        delivery={props.delivery}
                        quoteValue={quote.quote_value}
                        onQuotesTab={true}
                        {...props}
                    />
                }

                {latestOffer && showCounterButton &&
                    <CounterOfferButton
                        key={props.index}
                        load={load}
                        submitQuoteNegotiation={submitQuoteNegotiation}
                        quoteValue={quote.quote_value}
                        {...props}
                    />
                }

            </Col>
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiationsListItemDesktop)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        justifyContent: 'center'
    },
    button: {
        fontSize: 14,
        color: "white",
        margin: 5
    }
});