// REED TMS THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#274e9b',
    secondary: '#c80a2e',
    actionColor: "#274e9b",
    lightContrastText: '#fff',
    darkContrastText: '#000',
    useDarkContrastText: false,
    menuHighlightColor: "rgba(0, 0, 0, 0.3)",
    useWhiteBackgroundBehindLogo: true,
    tagLine:'Transportation & Logistics Experts',
    customerUniqueId: "r85xb5y8",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Roboto",
    loginMsg: "You've selected a feature that requires a secure login. Please login.",
    source: "reedtms_advantage_portal"
});
//console.log(theme);
export default theme
