
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';

import HistoricalLoadCard from './HistoricalLoadCard'
import ActiveLoadCard from './ActiveLoadCard'


const useStyles = makeStyles({
    activeLoadCard: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        marginTop: 10,
        marginLeft: 15,
        marginRight: 10,
        marginBottom: 0,
        '&:hover': {
            boxShadow: '0 2px 7px rgba(0,0,0,28%), 0 2px 5px rgba(0,0,0,28%) !important'
        },
        cursor: 'pointer'
    },
    historicalLoadCard: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        marginTop: 10,
        marginLeft: 15,
        marginRight: 10,
        marginBottom: 0,
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    }
});

const LoadListItemDesktop = (props) => {

    const { 
        load,
        availability,
        index,
        key,
        myLoadsRenderedTab = 0,
        pushReloads,
        reloadsOpen,
        muiTheme,
        carrierCommunications
    } = props

    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false)
    const [icon, setIcon] = useState('arrow-down')
    const [pickup, setPickup] = useState(null)
    const [delivery, setDelivery] = useState(null)


    useEffect(() => {
        buildPickupDeliveryData()
    }, [])

    useEffect(() => {

        if (isExpanded) {
            setIcon('chevron-down')
        } else
            setIcon('chevron-right')

    }, [isExpanded])

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    const buildPickupDeliveryData = () => {
        let tmpPickup = {}
        let tmpDelivery = {}

        // tmpPickup = {
        //     ...load.originStop,
        //     scheduledAt: load.originStop.earliestDate
        // }

        // tmpDelivery = {
        //     ...load.destStop,
        //     scheduledAt: load.destStop.latestDate
        // }

        setPickup(tmpPickup)
        setDelivery(tmpDelivery)
    }

    return (
        <div>
            { pickup && delivery &&
                <div style={{ marginBottom: 15 }}>
                    <Card className={myLoadsRenderedTab == 0 ? classes.activeLoadCard : classes.historicalLoadCard} onClick={myLoadsRenderedTab == 0 ? toggleExpanded : null}>
                        <CardContent>
                            {myLoadsRenderedTab == 0 ?
                                <ActiveLoadCard
                                    {...props}
                                    key={key + "_load_card"}
                                    load={load}
                                    index={index}
                                    pickup={pickup}
                                    delivery={delivery}
                                    availability={availability}
                                    pushReloads={pushReloads}
                                    reloadsOpen={reloadsOpen}
                                    isExpanded={isExpanded}
                                    dropDownIcon={icon}
                                    carrierCommunications={carrierCommunications}
                                />
                                :
                                <HistoricalLoadCard
                                    {...props}
                                    key={key + "_load_card"}
                                    load={load}
                                    index={index}
                                    pickup={pickup}
                                    delivery={delivery}
                                    availability={availability}
                                    pushReloads={pushReloads}
                                    reloadsOpen={reloadsOpen}
                                />
                            }
                        </CardContent>
                    </Card>
                </div>
            }
        </div>
    );
}


export default compose(
    connect(),
    muiThemeable()
)(LoadListItemDesktop)