import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles'

import { Row, Col } from 'react-bootstrap';
import { Card } from 'material-ui/Card';


const useStyles = makeStyles((style) => ({
    card: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        height: "100%",
        borderBottom: '1px solid lightgrey'
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    headerText: {
        textAlign: 'center',
        fontSize: '13px',
        color: 'black',
        margin: 0
    },
}));

const TruckCapacitiesListHeader = (props) => {

    const classes = useStyles()

    const { } = props

    // TODO: add conditional column for preferred broker

    return (
        <Card style={{ backgroundColor: 'transparent', boxShadow: 'transparent', width: "100%", padding: 15, flexDirection: "column", justifyContent: "center" }}>
            <Row className={classes.card}>
                <Col className={classes.col} style={{ width: "5%", alignItems: "center" }}>
                    <p className={classes.headerText}>Notifications</p>
                </Col>
                <Col className={classes.col} style={{ width: "5%", alignItems: "center" }}>
                    {/* <p className={classes.headerText}>On Hold</p> */}
                </Col>
                <Col className={classes.col} style={{ width: "9%", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Row>
                            <p className={classes.headerText} >
                                Available
                            </p>
                        </Row>
                        <Row>
                            <p className={classes.headerText}>
                                Date
                            </p>
                        </Row>
                    </div>
                </Col>
                <Col className={classes.col} style={{ width: "1%", alignItems: "center" }}>
                </Col>
                <Col className={classes.col} style={{ width: "7%", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Row>
                            <p className={classes.headerText} >
                                Available
                            </p>
                        </Row>
                        <Row>
                            <p className={classes.headerText}>
                                Time
                            </p>
                        </Row>
                    </div>
                </Col>
                <Col className={classes.col} style={{ width: "6%", alignItems: "center" }}>
                </Col>
                <Col className={classes.col} style={{ width: "18%", alignItems: "flex-start" }}>
                    <p className={classes.headerText}>Origin</p>
                </Col>
                <Col className={classes.col} style={{ width: "3%", alignItems: "flex-start" }} />
                <Col className={classes.col} style={{ width: "18%", alignItems: "flex-start" }}>
                    <p className={classes.headerText}>Destination</p>
                </Col>
                <Col className={classes.col} style={{ width: "1%", alignItems: "center" }}>
                    {/* <p className={classes.headerText}>On Hold</p> */}
                </Col>
                <Col className={classes.col} style={{ width: "12%", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Row>
                            <p className={classes.headerText} >
                                Equipment
                            </p>
                        </Row>
                        <Row>
                            <p className={classes.headerText}>
                                Type
                            </p>
                        </Row>
                    </div>
                </Col>
                <Col className={classes.col} style={{ width: "2%", alignItems: "center" }}>
                </Col>
                <Col className={classes.col} style={{ width: "7%", alignItems: "flex-start" }}>
                    <p className={classes.headerText}>Rate</p>
                </Col>
                <Col className={classes.col} style={{ width: "3%", alignItems: "center" }}>
                </Col>
                <Col className={classes.col} style={{ width: "3%" }}>
                </Col>
            </Row>
        </Card>
    )
}

export default compose(
    connect(),
    muiThemeable()
)(TruckCapacitiesListHeader)