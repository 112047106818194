import { PostRemoteLog } from './api-remote-log'
import { API_PROD_URL } from '../../api/constants'

const SEND_EMAIL_URL = "/api/carrier/sendEmail"


export async function sendEmail(params) {

    const url = API_PROD_URL + SEND_EMAIL_URL

    let promise = new Promise((resolve, reject) => {
        try {
            let requestObject = {
                method: 'POST',
                body: JSON.stringify(params)
            }

            // console.log("sendEmail: ", url, requestObject)
            return fetch(url, requestObject)
                .then(res => {
                    if (!res.ok) {
                        const error = new Error(res.statusText);
                        error.res = res;
                        throw error;
                    }
                    res.json().then(jsonData => {
                        // console.log("fields jsonData: ", jsonData)
                        // const data = [];
                        // if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
                        //     data.push(...jsonData);
                        // }
                        // resolve(data.filter(item => item.metadata.distance < 100))
                        resolve(jsonData)
                    })
                })
                .catch(error => {
                    reject(error)
                    let payload = {
                        source: "URL: " + SEND_EMAIL_URL,
                        message: {
                            error: JSON.stringify(error)
                        },
                        // data: JSON.stringify(dynamicParams)
                    }
                    PostRemoteLog(payload).then(response => {
                        // console.log("REMOTE LOG RESPONSE: ", response)
                    }).catch((error) => {
                    })
                })
        } catch (error) {
            reject(error)
            let payload = {
                source: "URL: " + SEND_EMAIL_URL,
                message: {
                    error: JSON.stringify(error)
                },
                // data: JSON.stringify(dynamicParams)
            }
            PostRemoteLog(payload).then(response => {
                // console.log("REMOTE LOG RESPONSE: ", response)
            }).catch((error) => {
            })
        }
    })

    let returnPromise = await promise
    return returnPromise
}