import React, { useEffect } from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import List from '@material-ui/core/List';
import NotificationListItem from "./notification-list-item"

const useStyles = makeStyles({
    container: {
        position: 'relative',
        height: '100%'
    },
});

const NotificationsList = (props) => {

    const classes = useStyles()

    const { muiTheme, notificationList, onNotificationClick, clearAll, clearSingleNotification } = props

    return (
        <div className={classes.container}>
            {notificationList.length <= 0 && (
                <h2 style={{ paddingTop: 100, paddingLeft: 15, paddingRight: 15, margin: 0, textAlign: 'center' }}>You have no new notifications</h2>
            )}
            {notificationList.length > 0 && (
                <div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: 10, marginTop: 15 }}>
                        <p style={{ fontSize: 16, fontWeight: 'bold', textDecoration: 'underline' }}>Notifications</p>
                        <a onClick={() => clearAll()}><p style={{ color: muiTheme.ttRed, textDecoration: "underline" }}>Clear all</p></a>
                    </div>
                    <List style={{ maxHeight: 240, overflow: 'auto' }}>
                        {notificationList.map((notificationItem, index) => (
                            <NotificationListItem
                                key={notificationItem.notificationId ? notificationItem.notificationId : notificationItem.id ? notificationItem.id : "notification_" + index + 1}
                                index={index}
                                item={notificationItem}
                                onNotificationClick={(type, itemId) => { onNotificationClick(type, itemId) }}
                                onDelete={(id, itemIndex) => { clearSingleNotification(id, itemIndex) }}
                            />
                        ))}
                    </List>
                </div>
            )}
        </div>
    )
}


export default compose(
    muiThemeable(),
)(NotificationsList)