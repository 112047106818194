
import React, { useEffect, useState, useRef } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col, Grid } from 'react-bootstrap';

import ContactItem from './contact-item';
import ContactListHeader from './contact-list-header'

const ContactList = (props) => {

    const { contactList , onDelete=null,onEdit=null} = props

    const [isScrollBarPresent, setIsScrollBarPresent] = useState(false);

    const listContainer = useRef(null)

    const processDeleteComplete = (flag)=>{
        if(onDelete && flag){
            onDelete(flag)
        }
    }

    const processEditComplete = (flag)=>{
        if(onEdit && flag){
            onEdit(flag)
        }
    }

    useEffect(()=>{
        setTimeout(() => {
          if(listContainer !== null && listContainer.current !== null){
            if(listContainer.current.clientHeight < listContainer.current.scrollHeight){
              setIsScrollBarPresent(true);
            } 
          }
        }, 100);     
      },[contactList])

    return (
        <Grid style={{ height: "100%", width: '100%', marginTop: 15, paddingLeft: 20, paddingRight: 20 }}>
            {contactList.length <= 0 ? (
                <div style={{ maxHeight: "97%", overflowX: "hidden", overflowY: "auto", display: "flex", justifyContent: "center" }}>
                    <h3>There are no contacts associated with this location.</h3>
                </div>
            ) : (
                    <div style={{ height: "100%" }}>
                        <div style={{marginRight: isScrollBarPresent ? '25px' : '0px'}}>
                            <ContactListHeader isScrollBarPresent={isScrollBarPresent} />
                        </div>
                        <div ref={listContainer} style={{ maxHeight: "92%", overflowX: "hidden", overflowY: "auto" }}>
                            {contactList.map((contact) => {
                                return (
                                    <ContactItem locationsList={props.locationsList} key={contact.contactId} item={contact}  onDelete={(flag)=>processDeleteComplete(flag)} onEditComplete={(flag)=>processEditComplete(flag)}  />
                                )
                            })}
                        </div>
                    </div>
                )}
        </Grid>
    );
}

export default compose(
    muiThemeable(),
)(ContactList)