import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom"

import { toast } from "react-toastify"
import { makeStyles } from '@material-ui/core/styles';
import isMobile from '../../../../../../HOC/Mobile.js'

import useGtagHook from '../../../../../../gtag-hook';
import TruckCapacityCard from './TruckCapacityCard';
import ConfirmDialog from '../../../../ConfirmDialog'
import TruckCapacityCardMobile from './TruckCapacityCardMobile';
import AddPreferenceModal from "../../general-add-edit-modal"
import { GeneralServices } from '../../../../../../CarrierPortal/customers/general/services';
import { checkValueExists } from "../../../../../../lib/check-value-exists"
import { updateLoadSearchBar } from '../../../../../../CarrierPortal/ActionReducers/components';
import { getNearbyLoads } from '../../../../../../CarrierPortal/actions';

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
}));

const TruckCapacityItem = (props) => {

    const classes = useStyles()

    const { item, itemIndex, isMobile = false, updatePreferencesData, selectedLocationId, updateLoadSearchBar, getNearbyLoads } = props

    const [sendGtagEvent] = useGtagHook()

    const [showEditModal, setShowEditModal] = useState(false)
    const [apiCalling, setApiCalling] = useState(false)
    const [availability, setAvailability] = useState(item.visibility === 1 ? true : false);
    const [notificationsOn, setNotificationsOn] = useState(false);
    const [onHold, setOnHold] = useState(false);
    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false);

    const handleToggleNotification = (event) => {
        setNotificationsOn(event.target.checked)
        const apiServices = new GeneralServices()
        let params = {}
        if (event.target.checked) {
            setApiCalling(true)

            apiServices.enableNotificationsTruckCapacity(params, item.id)
                .then(response => {
                    setApiCalling(false)
                    if (response && response.status) {
                        sendGtagEvent(
                            "preferences_capacity_toggle_notifications",
                            {
                                action: "TOGGLE_ON",
                                id: item.id,
                                receiveNotifications: item.receiveNotifications,
                                // visibility: item.visibility,
                                // onHold: item.onHold,
                                pickupLocation: JSON.stringify(item.origin),
                                deliveryLocation: JSON.stringify(item.destination),
                                equipments: JSON.stringify(item.truckTypes),
                                rate: item.rate.value,
                                rateType: item.rate.type,
                                pickupDate: item.availableAt.date,
                                pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                availableAt: JSON.stringify(item.availableAt),
                                brokers: JSON.stringify(item.brokers)
                            }
                        )
                        setNotificationsOn(1)
                    } else {
                        setNotificationsOn(null)
                        toastErrorText(response)
                    }
                })
                .catch(error => {
                    setApiCalling(false)
                    setNotificationsOn(null)
                    toastErrorText()
                })

        } else {

            setApiCalling(true)
            apiServices.disableNotificationsTruckCapacity(params, item.id)
                .then(response => {
                    setApiCalling(false)
                    if (response && response.status) {
                        sendGtagEvent(
                            "preferences_capacity_toggle_notifications",
                            {
                                action: "TOGGLE_OFF",
                                id: item.id,
                                receiveNotifications: item.receiveNotifications,
                                // visibility: item.visibility,
                                // onHold: item.onHold,
                                pickupLocation: JSON.stringify(item.origin),
                                deliveryLocation: JSON.stringify(item.destination),
                                equipments: JSON.stringify(item.truckTypes),
                                rate: item.rate.value,
                                rateType: item.rate.type,
                                pickupDate: item.availableAt.date,
                                pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                availableAt: JSON.stringify(item.availableAt),
                                brokers: JSON.stringify(item.brokers)
                            }
                        )
                        setNotificationsOn(null)
                    } else {
                        setNotificationsOn(1)
                        toastErrorText(response)
                    }
                })
                .catch(error => {
                    setApiCalling(false)
                    setNotificationsOn(1)
                    toastErrorText()
                })
        }
    };

    const handleToggleOnHold = (event) => {
        const apiServices = new GeneralServices()
        setOnHold(event.target.checked)
        let params = {
            onhold: event.target.checked ? 1 : null
        }
        setApiCalling(true)
        apiServices.onHoldTruckCapacity(params, item.id)
            .then(response => {
                setApiCalling(false)
                if (response && response.status) {
                    setOnHold(!onHold)
                } else {
                    setOnHold(onHold)
                    toastErrorText(response)
                }
            })
            .catch(error => {
                setApiCalling(false)
                setOnHold(onHold)
                toastErrorText()
            })

    };



    const toggleVisibility = () => {
        setAvailability(!availability)
    }


    const handleDeleteCapacity = () => {
        setIsDeleteConfirmationShow(true)
    }


    const deleteItemProcess = (flag) => {

        setIsDeleteConfirmationShow(false)
        if (flag) {
            const apiServices = new GeneralServices()
            let params = {
            }
            setApiCalling(true)
            apiServices.deleteTruckCapacity(params, item.id)
                .then(response => {
                    setApiCalling(false)
                    if (response && response.status) {
                        sendGtagEvent(
                            "preferences_delete_capacity",
                            {
                                id: item.id,
                                receiveNotifications: item.receiveNotifications,
                                // visibility: item.visibility,
                                // onHold: item.onHold,
                                pickupLocation: JSON.stringify(item.origin),
                                deliveryLocation: JSON.stringify(item.destination),
                                equipments: JSON.stringify(item.truckTypes),
                                rate: item.rate.value,
                                rateType: item.rate.type,
                                pickupDate: item.availableAt.date,
                                pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                availableAt: JSON.stringify(item.availableAt),
                                brokers: JSON.stringify(item.brokers)
                            }
                        )
                        updatePreferencesData()
                    } else {
                        toastErrorText(response)
                    }
                })
                .catch(error => {
                    setApiCalling(false)
                    toastErrorText()
                })
        }
    }



    const toastErrorText = (response = null) => {
        let error = 'There was an issue with your request, please try again later.'
        if (response) {
            if (!checkValueExists(response, "errors")) {
                if (response.errors.isArray()) {
                    error = response.errors.join(' ')
                } else {
                    error = response.errors
                }
            } else if (!checkValueExists(response, "message")) {
                error = response.message
            }
        }
        toast.error(error)
    }



    const card = () => {
        let ui = (
            <TruckCapacityCardMobile
                item={item}
                handleViewLoads={props.handleViewLoads}
                availability={availability}
                notificationsOn={notificationsOn}
                onHold={onHold}
                setShowEditModal={setShowEditModal}
                handleToggleNotification={handleToggleNotification}
                handleToggleOnHold={handleToggleOnHold}
                toggleVisibility={toggleVisibility}
                handleDeleteCapacity={handleDeleteCapacity}
                viewOnly={true}
            />
        )
        return ui
    }

    useEffect(() => {
        setNotificationsOn(item.receiveNotifications)
        setOnHold(item.onHold)
    }, [item])

    return (
        <div>
            <ConfirmDialog open={isDeleteConfirmationShow} onClose={(flag)=>deleteItemProcess(flag)} header={'Delete Truck Capacity'} bodyComp={card()} body={'Are you sure you want to delete this truck capacity?'} acceptButtonText='DELETE' />
            <div className={classes.searchCardRow}>
                {!isMobile ?
                    <TruckCapacityCard
                        item={item}
                        handleViewLoads={props.handleViewLoads}
                        availability={availability}
                        notificationsOn={notificationsOn}
                        onHold={onHold}
                        isLoading={apiCalling}
                        setShowEditModal={setShowEditModal}
                        handleToggleNotification={handleToggleNotification}
                        handleToggleOnHold={handleToggleOnHold}
                        toggleVisibility={toggleVisibility}
                        handleDeleteCapacity={handleDeleteCapacity}
                    />
                    :
                    <TruckCapacityCardMobile
                        item={item}
                        handleViewLoads={props.handleViewLoads}
                        availability={availability}
                        notificationsOn={notificationsOn}
                        onHold={onHold}
                        isLoading={apiCalling}
                        setShowEditModal={setShowEditModal}
                        handleToggleNotification={handleToggleNotification}
                        handleToggleOnHold={handleToggleOnHold}
                        toggleVisibility={toggleVisibility}
                        handleDeleteCapacity={handleDeleteCapacity}
                    />
                }
            </div>
            {showEditModal && (
                <AddPreferenceModal selectedLocationId={selectedLocationId} editItem={item} renderedTab={0} onClose={() => { setShowEditModal(false) }} updatePreferencesData={updatePreferencesData} />
            )}
        </div>
    )
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getNearbyLoads: getNearbyLoads,
        updateLoadSearchBar: updateLoadSearchBar,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    isMobile(),
    withRouter
)(TruckCapacityItem)