
export const FOURKITES = 'fourkites';
export const MACROPOINT = 'macropoint';

export const LOADS_REQUESTING = 'LOADS_REQUESTING';
export const LOADS_SUCCESS = 'LOADS_SUCCESS';







