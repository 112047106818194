import * as MESSAGES from '../constants'

const initialState = {
  requesting: false,
  successful: false,
  loadList: false,
  pickupLocation: null,
  countryWideSearch: false,
  lastSearchPayload: null,
  message: null
}

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGES.LOAD_SEARCH_REQ:
      var retValue = {
        ...state,
        requesting: true,
        successful: false
      }
      return retValue
    case MESSAGES.GET_NEARBY_LOADS:
      var retValue = {
        ...state,
        requesting: true,
        successful: false,
        message: action.message,
        pickupLocation: action.city ? action.city + ", " + action.state : action.state ? action.state : action.country,
        countryWideSearch: action.state ? false : true,
        lastSearchPayload: action
      }
      return retValue
    case MESSAGES.GET_LOAD_BY_LOAD_NUMBER:
      var retValue = {
        ...state,
        requesting: true,
        successful: false
      }
      return retValue
    case MESSAGES.CLEAR_LOAD_LIST:
      var retValue = {
        ...state,
        requesting: false,
        successful: true,
        loadList: [],
        lastSearchPayload: null
      }
      return retValue
    case MESSAGES.LOAD_SEARCH_SUC:
      var retValue = {
        ...state,
        requesting: false,
        successful: true,
        message: action.message,
        loadList: action.loadList
      }
      return retValue
    case 'SHOW_LOADING':
      var retValue = {
        ...state,
        requesting: true
      }
      return retValue
    case 'HIDE_LOADING':
      var retValue = {
        ...state,
        requesting: false
      }
      return retValue
    //var newDrivers = data.drivers.map((driver)=>driver)
    //data.push()

    case MESSAGES.LOAD_SEARCH_ERR:
      var retValue = {
        ...state,
        requesting: false,
        successful: false,
        loadList: [],
        message: action.message,
      }
      return retValue
    case MESSAGES.RESET_LOAD_LIST:
      var retValue = {
        ...state,
        requesting: false,
        successful: false,
        loadList: false,
      }
      return retValue
    default:
      return state
  }
}

export default SearchReducer
