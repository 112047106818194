//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'
  import {getCarrierMatches} from '../api/matches'
  import {store} from '../index'
  import {toast} from 'react-toastify'
  import {sortDriverAvailsByDate} from '../components/Sort'
  import { canUseGetNearbyLoadsV3 } from './helpers/permissions';

//MESSAGING
  import * as MESSAGES from './constants'

//APIs
  import {createSearchOrCapacity,editAvailability,publishAvailability,deleteDriverAvailability,postCarrierEmailResponse,importCarriersAvailabilitiesCarrierPortal} from '../api/driverAvailability'
  import {joinBrokerNetworks,getOutofnetworkbroker} from '../api/outofnetworkbroker'
  import {getInnetworkbroker} from '../api/innetworkbroker'
  import {createLoadInterest} from '../api/loads'
  import {FetchNearByLoads, GetLoadDetailsByLoadNumber} from './apis/api-load-search'
//ACTIONS
  import {getCarrierMatches as getCarrierMatchesAction} from './actions'



function validateData(data){
  //console.log(data);
  var finalMockData={}
  var faultyData={loads:[],matches:[]}
  //Validate Loads//
  if(data.loads){
    finalMockData.loads = data.loads.filter((load)=>{

      if(load.stops.length < 2){
        faultyData.loads.push(load.id)
        return false
      }
      return true
    })
  }
  //VALIDATE DRIVERS && AVAILS//
  if(data.drivers){
    finalMockData.drivers = data.drivers.filter((driver)=>{
      //VALIDATE DRIVER//
        //if(driver.name=='Matteo')return false

      //VALIDATE AVAILABILITIES//
        var finalAvailabilities =  driver.driver_availabilities.filter((avail)=>{
          //VALIDATE AVAILABILITY//
            //if(avail.name=='Matteo')return false

          //VALIDATE MATCHES//
            var finalMatches = avail.matches.filter((match)=>{
              if(faultyData.loads.indexOf(match.loadId) >= 0){
                faultyData.matches.push({id:match.id,load:match.loadId})
                return false
              }
              return true
            })
            avail.matches = finalMatches
          return true
        })

      driver.driver_availabilities=finalAvailabilities
      return true

    })
  }
  //console.log(faultyData);
  //console.log(finalMockData);
	return finalMockData
}

function* getCarrierMatchesFlow(action){
  try {
    const {startDate,endDate,callbackAction,callbackFunction} = action

    var response = yield call(getCarrierMatches,startDate,endDate)
    if(response.length){
      response=response[0]
    }
    //console.log(response)
    if(response.drivers || response.loads){
      var finalResponse = validateData(response)
      yield put({type:MESSAGES.CARRIER_MATCHES_SUC,payload:finalResponse})

      if(finalResponse.drivers){
        var sortedAvailabilities = sortDriverAvailsByDate(finalResponse.drivers);

          var actions ={
              type:'UPDATE_FILTERED_DRIVER',
              values:sortedAvailabilities
            }
          yield put(actions)
      }

      if(callbackAction)
        if(typeof callbackAction == 'object')
          yield put(callbackAction)
        else if(typeof callbackAction == 'function'){
          var callbackActionPayload = yield call(callbackAction,finalResponse)
          for(var i=0;i<callbackActionPayload.length;i++){
            yield put(callbackActionPayload[i])
          }
        }
      if(callbackFunction)
        yield call(callbackFunction)
    }else{
      yield put({type:MESSAGES.CARRIER_MATCHES_ERR,message:'Could Not Retrieve Matches'})
    }

  } catch (error) {
    console.log(error)

  }
}

function* updateAvailabilityFlow(action){
  try {
    const response = yield call(editAvailability,action.updateValues)
    if(response.status){
      yield put({type:'CARRIER_MATCHES_REQ'})
      //yield put({type:'UPDATE_COMPONENT',componentName:'sidebar',values:{activeAvailability:response.availabilityId}})
      //yield put({type:MESSAGES.UPDATE_AVAILABILITY_SUC,payload:response,updateValues:action.updateValues})
    }else{
      yield put({type:MESSAGES.UPDATE_AVAILABILITY_ERR,message:'Could Not Retrieve Matches'})
    }

  } catch (error) {
    console.log(error)

  }
}

function* deleteAvailabilityFlow(action){
  try {
    const response = yield call(deleteDriverAvailability,action.id)
    if(response.status){
      yield put({type:'DELETE_AVAILABILITY_SUC',payload:response,updateValues:action.updateValues})
      store.dispatch(getCarrierMatchesAction())
    }else{
      yield put({type:'DELETE_AVAILABILITY_ERR',message:'Could Not Delete Availability'})
    }

  } catch (error) {
    console.log(error)

  }
}

function* publishAvailabilityFlow(action){
  try {
    var response = yield call(publishAvailability,action.id,action.visibility)
    if(response.status){
      yield put({type:'PUBLISH_AVAILABILITY_SUC',response:response,request:action})
      store.dispatch(getCarrierMatchesAction())
      // .then(
      //   yield put({type:'UPDATE_COMPONENT',componentName:'sidebar',values:{activeAvailability:response[0].availabilityId}})
      // )
    }else{
      yield put({type:'PUBLISH_AVAILABILITY_ERR',message:'Could Not Update Load Visibility',response:response})
    }
  }catch(e){
    console.log(e)
  }
}

function* getSearchRequestingFlow(action){
  try {
    if(action.availabilityId){
      action.truckTypeId=(action.truckTypeIds && action.truckTypeIds.length>0)? action.truckTypeIds[0] : null
      var response = yield call(editAvailability,action)
    }
    else
      var response = yield call(createSearchOrCapacity,action)
    if(response.length){
      response=response[0]
    }
    if(response.status && response.availabilityId>0){
      yield put({type:MESSAGES.LOAD_SEARCH_SUC,response:response,request:action})
      yield put({type:'UPDATE_SEARCHBOX',value:'DEFAULT'})
      yield put({type:'UPDATE_LOAD_SEARCH_BAR',value:'DEFAULT'})
      var callbackAction = {type:'UPDATE_COMPONENT',componentName:'sidebar',values:{activeAvailability:response.availabilityId}}
      store.dispatch(getCarrierMatchesAction(false,false,callbackAction))
    }else{
      yield put({type:MESSAGES.LOAD_SEARCH_ERR,message:'Could Not Retrieve Matches',response:response})
      toast(response.message)
    }

  } catch (error) {
    console.log(error)
    yield put({type:MESSAGES.LOAD_SEARCH_ERR,message:'Could Not Retrieve Matches',response:null})
    toast("ERROR !! Please try again later.")
  }
}


function* getBrokersFlow(action){
  try {
    var response = yield call(getInnetworkbroker)
    if(response.status){
      yield put({type:'GET_BROKERS_SUC',response:response,request:action})
    }else{
      yield put({type:'GET_BROKERS_ERR',message:'Could Not Get Brokers',response:response,request:action})
    }
  }catch(e){
    console.log(e)
  }
}
function* joinBrokerNetworkFlow(action){
  try {
    var response = yield call(joinBrokerNetworks,action.brokerIds)
    if(response.status){
      toast('Request sent to broker')
      yield put({type:'JOIN_BROKER_NETWORK_SUC',response:response,request:action})
      yield put({type:'GET_BROKERS_REQ',response:response,request:action})
    }else{
      yield put({type:'JOIN_BROKER_NETWORK_ERR',message:'Could not send broker join network request',response:response,request:action})
    }
  }catch(e){
    console.log(e)
  }
}
function* loadInterestFlow(action){
  try {
    var response = yield call(createLoadInterest,action.loadId,action.matchId)
    if(response.status){
      toast(response.message)
      yield put({type:'LOAD_INTEREST_SUC',response:response,request:action})
    }else{
      yield put({type:'LOAD_INTEREST_ERR',message:'Could not send load interest request',response:response,request:action})
    }
  }catch(e){
    console.log(e)
  }
}
function* postCarrierEmailResponseFlow(action){
  try {
    var response = yield call(postCarrierEmailResponse,action.forwardingParams)
  }catch(e){
    console.log(e)
  }
}

 function* importCapacityCarrierPoratalCall (action) {
	  try {

		//console.log('importCapacityCall')
		yield put({ type:  'SHOW_LOADING'})
		// here until the API async function, is complete!
		const response = yield call(importCarriersAvailabilitiesCarrierPortal,action.importdata)

		if(response.status){
			yield put({ type: MESSAGES.CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_SUCCESS, response })
			yield put({type:MESSAGES.LOAD_SEARCH_SUC,response:response,request:action})
		}
		else
		{
			yield put({ type: MESSAGES.CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_ERROR, response })
			yield put({type:MESSAGES.LOAD_SEARCH_SUC,response:response,request:action})
		}


	  } catch (error) {
		// if the api call fails, it will "put" the LOADS_ERROR
		// into the dispatch along with the error.
		yield put({ type: MESSAGES.CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_ERROR, error })

	  }

 }

 function* getNearbyLoads(action){
  try {
    let resp = yield call(FetchNearByLoads,action)
    if(canUseGetNearbyLoadsV3() ? resp : resp.status){
      yield put({type:MESSAGES.LOAD_SEARCH_SUC,loadList:canUseGetNearbyLoadsV3() ? resp.data : resp.loadList})

      if(resp.savedSearchStatus)
        toast.success("Search Saved")
      if(resp.publishedAvailability)
        toast.success("Availability Published")
    }
    else{

      //use response message otherwise default
      yield put({type:MESSAGES.LOAD_SEARCH_ERR,message:resp.message ? resp.message : 'No Loads Found'})
      //load list is set to empty array in LOAD_SEARCH_ERR action (this triggers the use effects)

      //if not werner
      if (process.env.REACT_APP_ADVANTAGE_ID !== "a98db973")
        toast.error(resp.message ? resp.message : "No Loads Found")
    }


  } catch (error) {

    //use response message otherwise default
    yield put({type:MESSAGES.LOAD_SEARCH_ERR,message:'Could Not Retrieve Loads',response:null})

     //if not werner
    if (process.env.REACT_APP_ADVANTAGE_ID !== "a98db973")
      toast.error("Error - Please try again later.")
  }
}

function* getLoadByLoadNumber(action){
  try {
    let resp = yield call(GetLoadDetailsByLoadNumber,action.loadNumber, action.advantageId)
    if(resp.status){
      yield put({type:MESSAGES.LOAD_SEARCH_SUC,loadList:[resp.load]})
    } else {
      yield put({type:MESSAGES.LOAD_SEARCH_ERR,message:'Could Not Retrieve Loads'})
      toast.error(resp.message? resp.message : "We’re experiencing a problem right now. Please try again later.*")
    }
  } catch (error) {
    yield put({type:MESSAGES.LOAD_SEARCH_ERR,message:'Could Not Retrieve Loads',response:null})
    toast.error("We’re experiencing a problem right now. Please try again later.*")
  }
}


function* clearLoadList(){
  put({type:MESSAGES.CLEAR_LOAD_LIST,loadList:[]})
}


function* templateWatcher () {
  
  yield takeLatest(MESSAGES.GET_LOAD_BY_LOAD_NUMBER, getLoadByLoadNumber)
  yield takeLatest(MESSAGES.GET_NEARBY_LOADS, getNearbyLoads)
  yield takeLatest(MESSAGES.CLEAR_LOAD_LIST, clearLoadList)
  yield takeLatest(MESSAGES.CARRIER_MATCHES_REQ, getCarrierMatchesFlow)
  yield takeLatest(MESSAGES.LOAD_SEARCH_REQ, getSearchRequestingFlow)
  yield takeLatest(MESSAGES.UPDATE_AVAILABILITY_REQ,updateAvailabilityFlow)
  yield takeLatest('PUBLISH_AVAILABILITY_REQ',publishAvailabilityFlow)
  yield takeLatest('DELETE_AVAILABILITY_REQ',deleteAvailabilityFlow)
  yield takeLatest('GET_BROKERS_REQ',getBrokersFlow)
  yield takeLatest('JOIN_BROKER_NETWORK_REQ',joinBrokerNetworkFlow)
  yield takeLatest('LOAD_INTEREST_REQ',loadInterestFlow)
  yield takeLatest(MESSAGES.CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL, importCapacityCarrierPoratalCall)
  yield takeLatest('POST_CARRIER_EMAIL_RESPONSE',postCarrierEmailResponseFlow)
}

export default templateWatcher
