//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'
  import {toast} from 'react-toastify'
//APIs
  import {parseEmail} from './api'

function* parseEmailFlow(action){
  try {

    const response = yield call(parseEmail,action.text,action.signatureStart)
    if(response){
      yield put({type:'PARSE_EMAIL_TEXT_SUC',payload:response})
    }
    else{
      yield put({type:'PARSE_EMAIL_TEXT_ERR'})
    }

  } catch (error) {
      yield put({type:'PARSE_EMAIL_TEXT_ERR'})
      toast.error(error)
    console.log(error)

  }
}

function* templateWatcher () {
  yield takeLatest('PARSE_EMAIL_TEXT_REQ', parseEmailFlow)
}

export default templateWatcher
