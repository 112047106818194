
import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { Col, Row } from "react-bootstrap"

const LoadCardHeader = (props) => {

    const classes = useStyles();

    return (

        <div style={{paddingLeft: 5}}>

            <Row style={{ display: 'flex' }}>

                <Col style={{ width: '11%', display: 'flex' }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <Row>
                                <p className={classes.headerText}>Reference #</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: "15%", display: 'flex' }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <Row>
                                <p className={classes.headerText}>Origin Location</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: "12%", display: 'flex' }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <Row>
                                <p className={classes.headerText}>Pickup Appointment</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: "15%", display: 'flex' }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <Row>
                                <p className={classes.headerText}>Destination Location</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: "12%", display: 'flex' }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <Row>
                                <p className={classes.headerText}>Delivery Appointment</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: "10%", display: 'flex' }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <Row>
                                
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: "13%", display: 'flex' }}>
                    <Row>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left', paddingLeft: 15 }}>
                            <Row>
                                <p className={classes.headerText}>Rate</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Row>

        </div>
    );
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(LoadCardHeader)

const useStyles = makeStyles({
    headerText: {
        // fontFamily: 'Arial !important',
        fontSize: '13px',
        color: 'black',
        margin: 0
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    }
});