import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import List from '@material-ui/core/List';
import muiThemeable from 'material-ui/styles/muiThemeable';
import LoadListItemMobile from './LoadListItemMobile'
import NoLoadFoundCard from '../../invoices/no-load-found-card'

const LoadListMobile = (props) => {

  const {
    filteredLoadList = [],
    availability,
    pushReloads,
    isMyLoadsScreen = false,
    myLoadsRenderedTab = 0,
    isRecentActivity = false,
    isBookedLoads = false,
    reloadsOpen = false,
    onReloadsTab = false,
    removeLoadFromList,
    isHome=false
  } = props

  const daysBackSelectorHeight = useRef(0)

  useEffect(() => {
    if (isMyLoadsScreen && myLoadsRenderedTab === 2)
      daysBackSelectorHeight.current = 60
  }, [])

  return (
    <List style={{ overflow: 'auto', maxHeight: '100%', padding: 10, paddingBottom: 50 }}>

      {filteredLoadList.length > 0 ?
        filteredLoadList.map((load, index) => {
          if (load && load.stops && load.stops.length > 0) {
            return (
              <LoadListItemMobile
                {...props}
                key={load.id + "_" + index}
                load={load}
                availability={availability}
                index={index}
                pushReloads={pushReloads}
                isMyLoadsScreen={isMyLoadsScreen}
                myLoadsRenderedTab={myLoadsRenderedTab}
                isRecentActivity={isRecentActivity}
                reloadsOpen={reloadsOpen}
                removeLoadFromList={removeLoadFromList}
                isBookedLoads={isBookedLoads}
                isHome={isHome}
              />
            )
          }
          else if (props.onInvoicesScreen && load.invoice) {
            return (
              <NoLoadFoundCard load={load} {...props} />
            )
          }
          else
            return null
        })
        :
        <div style={{ paddingTop: 25 }}>
          <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
            No Loads Found
              </p>
        </div>

      }

    </List>
  )

}

export default compose(
  connect(),
  muiThemeable()
)(LoadListMobile)