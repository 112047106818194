
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { toast } from 'react-toastify';
import FA from 'react-fontawesome'
import { Col } from "react-bootstrap"
import moment from "moment"

import RateNegotiationsList from './RateNegotiationsList';
import * as RateNegotiationsApi from '../../../../../api/rateNegotiations'

const RateNegotiations = (props) => {

    const { 
        load, 
        setShowAddQuoteBtn, 
        showAddQuoteBtn, 
        setQuotePage, 
        removeLoadFromList,
        callRefreshCard
    } = props

    const [originalQuoteList, setOriginalQuoteList] = useState([])

    const [isLoading, setIsLoading] = useState(true)



    useEffect(() => {
        fetchAllQuotes(load.id)
    },[])

    const fetchAllQuotes = (loadId, startDate = null, endDate = null) => {
        setIsLoading(true)

        RateNegotiationsApi.FetchAllQuotes(startDate, endDate, loadId).then(response => {
            if (response.status) {
                if (response.results && response.results.quotes.length > 0) {
                    
                    setOriginalQuoteList(sortOfferHistory(response.results.quotes))
                    setShowAddQuoteBtn(false)
                    load.quotes = response.results.quotes
                    
                } else {
                    setShowAddQuoteBtn(true)
                }
                setIsLoading(false)

            } else {
                let errorMessage = "Error: There was a problem fetching offer history."

                setIsLoading(false)
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch(() => {
            let errorMessage = "Error: There was a problem fetching offer history."
            setIsLoading(false)
            toast.error(errorMessage)
        })
    }

    const sortOfferHistory = (offerList) => {
        let sortedOfferHistory = offerList.sort( (a,b) => {
            return moment(b.updated_at).valueOf() - moment(a.updated_at).valueOf()
        })
        return sortedOfferHistory
    }

    return (
        <div style={{height: '100%'}}>
        { !isLoading ?
            <RateNegotiationsList
                originalQuoteList={originalQuoteList}
                isLoading={isLoading}
                load={load}
                showAddQuoteBtn={showAddQuoteBtn}
                setShowAddQuoteBtn={setShowAddQuoteBtn}
                setQuotePage={setQuotePage}
                removeLoadFromList={removeLoadFromList}
                fetchAllQuotes={fetchAllQuotes}
                callRefreshCard={callRefreshCard}
                {...props}
            />
        :
        <Col style={{ width: '100%', paddingTop: 25 }}>

            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                <FA name='spinner' spin />
            </p>

        </Col>
        }
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiations)