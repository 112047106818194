import React, { useState } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';

import {
    setBrandFilter,
    setPositionFilter,
    setModelFilter,
    setSizeFilter,
    setPlyFilter,
    resetAllTireFilters
} from "../../../actions"
import { camelize } from "../../../helpers/services"
import useGtagHook from "../../../../gtag-hook"

import TireFilterMenuDesktop from './tire-filter-menu-desktop';


const TireFilterMenu = (props) => {

    const {
        mobileView = false,
        muiTheme
    } = props

    const [selectedBrands, setSelectedBrands] = useState([])
    const [selectedPositions, setSelectedPositions] = useState([])
    const [selectedModels, setSelectedModels] = useState([])
    const [selectedSizes, setSelectedSizes] = useState([])
    const [selectedPlys, setSelectedPlys] = useState([])

    const [resetCheckBoxStates, setResetCheckBoxStates] = React.useState(false);

    const [sendGtagEvent] = useGtagHook()

    const updateFilters = (type, payload) => {
        switch (type) {
            case "brand":
                props.setBrandFilter(payload)
                break
            case "position":
                props.setPositionFilter(payload)
                break
            case "model":
                props.setModelFilter(payload)
                break
            case "size":
                props.setSizeFilter(payload)
                break
            case "ply":
                props.setPlyFilter(payload)
                break
            default:
                break
        }
    }

    const handleSelectBrand = (isSelected, brand, callRedux = true, shouldLog = true) => {
        let prevVal = selectedBrands

        if (isSelected) {
            prevVal.splice(prevVal.indexOf(brand), 1)
        } else {
            if (prevVal.indexOf(brand) < 0) {
                prevVal.push(brand)
            }
        }
        setSelectedBrands(prevVal)

        if (callRedux)
            updateFilters("brand", prevVal)

        if(shouldLog){
            sendGtagEvent(
                "filter_tires_brand",
                {
                    category: "brand",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }     
    }

    const handleSelectPosition = (isSelected, position, callRedux = true, shouldLog = true) => {
        let prevVal = selectedPositions

        if (isSelected) {
            prevVal.splice(prevVal.indexOf(position), 1)
        } else {
            if (prevVal.indexOf(position) < 0) {
                prevVal.push(position)
            }
        }
        setSelectedPositions(prevVal)

        if (callRedux)
            updateFilters("position", prevVal)

        if(shouldLog){
            sendGtagEvent(
                "filter_tires_position",
                {
                    category: "position",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }     
    }

    const handleSelectModel = (isSelected, model, callRedux = true, shouldLog = true) => {
        let prevVal = selectedModels

        if (isSelected) {
            prevVal.splice(prevVal.indexOf(model), 1)
        } else {
            if (prevVal.indexOf(model) < 0) {
                prevVal.push(model)
            }
        }
        setSelectedModels(prevVal)

        if (callRedux)
            updateFilters("model", prevVal)

        if(shouldLog){
            sendGtagEvent(
                "filter_tires_model",
                {
                    category: "model",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }     
    }

    const handleSelectSize = (isSelected, size, callRedux = true, shouldLog = true) => {
        let prevVal = selectedSizes

        if (isSelected) {
            prevVal.splice(prevVal.indexOf(size), 1)
        } else {
            if (prevVal.indexOf(size) < 0) {
                prevVal.push(size)
            }
        }
        setSelectedSizes(prevVal)

        if (callRedux)
            updateFilters("size", prevVal)

        if(shouldLog){
            sendGtagEvent(
                "filter_tires_size",
                {
                    category: "size",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }     
    }

    const handleSelectPly = (isSelected, ply, callRedux = true, shouldLog = true) => {
        let prevVal = selectedPlys

        if (isSelected) {
            prevVal.splice(prevVal.indexOf(ply), 1)
        } else {
            if (prevVal.indexOf(ply) < 0) {
                prevVal.push(ply)
            }
        }
        setSelectedPlys(prevVal)

        if (callRedux)
            updateFilters("ply", prevVal)

        if(shouldLog){
            sendGtagEvent(
                "filter_tires_ply",
                {
                    category: "ply",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }     
    }

    const resetAllFilters = (shouldLog = true, onMount = false) => {
        props.resetAllTireFilters(onMount)

        props.currentBrandList.forEach(brand => handleSelectBrand(true, brand, false, false))

        props.currentPositionList.forEach(position => handleSelectPosition(true, position, false, false))

        props.currentModelList.forEach(model => handleSelectModel(true, model, false, false))

        props.currentSizeList.forEach(size => handleSelectSize(true, size, false, false))

        props.currentPlyList.forEach(ply => handleSelectPly(true, ply, false, false))

        setResetCheckBoxStates(!resetCheckBoxStates)
        if (shouldLog) {
            sendGtagEvent(
                "filter_tires_reset",
                {
                    category: "ALL"
                }
            )
        }
    }

    const resetFilter = (type, shouldLog = true) => {
        let newValue
        switch (type) {
            case 'brand':
                updateFilters("brand", [])
                props.currentBrandList.forEach(brand => handleSelectBrand(true, brand, null, false))
                newValue = []
                break
            case 'position':
                updateFilters("position", [])
                props.currentPositionList.forEach(position => handleSelectPosition(true, position, null, false))
                newValue = []
                break
            case 'model':
                updateFilters("model", [])
                props.currentModelList.forEach(model => handleSelectModel(true, model, null, false))
                newValue = []
                break
            case 'size':
                updateFilters("size", [])
                props.currentSizeList.forEach(size => handleSelectSize(true, size, null, false))
                newValue = []
                break
            case 'ply':
                updateFilters("ply", [])
                props.currentPlyList.forEach(ply => handleSelectPly(true, ply, null, false))
                newValue = []
                break
            default:
                break
        }
        if (shouldLog) {
            sendGtagEvent(
                "filter_tires_reset",
                {
                    category: type,
                    value: newValue
                }
            )
        }
    }


    const deselectAllCheckboxes = (type, shouldLog = true) => {
        let newValue
        switch (type) {
            case 'brand':
                updateFilters("brand", props.currentBrandList)
                props.currentBrandList.forEach(brand => handleSelectBrand(false, brand))
                newValue = props.currentBrandList
                break
            case 'position':
                updateFilters("position", props.currentPositionList)
                props.currentPositionList.forEach(position => handleSelectPosition(false, position))
                newValue = props.currentPositionList
                break
            case 'model':
                updateFilters("model", props.currentModelList)
                props.currentModelList.forEach(model => handleSelectModel(false, model))
                newValue = props.currentModelList
                break
            case 'size':
                updateFilters("size", props.currentSizeList)
                props.currentSizeList.forEach(size => handleSelectSize(false, size))
                newValue = props.currentSizeList
                break
            case 'ply':
                updateFilters("ply", props.currentPlyList)
                props.currentPlyList.forEach(ply => handleSelectPly(false, ply))
                newValue = props.currentPlyList
                break
            default:
                break
        }
        if (shouldLog) {
            sendGtagEvent(
                "filter_tires_deselect_all_checkboxes",
                {
                    category: type,
                    value: newValue
                }
            )
        }
    }

    return (
        <div style={{ height: "100%" }}>
            {!mobileView ?
                <div style={{ height: "100%" }}>
                    <TireFilterMenuDesktop
                        brandList={props.currentBrandList}
                        positionList={props.currentPositionList}
                        modelList={props.currentModelList}
                        sizeList={props.currentSizeList}
                        plyList={props.currentPlyList}
                        resetFilter={resetFilter}
                        deselectAllCheckboxes={deselectAllCheckboxes}
                        handleSelectBrand={handleSelectBrand}
                        selectedBrands={selectedBrands}
                        handleSelectPosition={handleSelectPosition}
                        selectedPositions={selectedPositions}
                        handleSelectModel={handleSelectModel}
                        selectedModels={selectedModels}
                        handleSelectSize={handleSelectSize}
                        selectedSizes={selectedSizes}
                        handleSelectPly={handleSelectPly}
                        selectedPlys={selectedPlys}
                        resetAllFilters={resetAllFilters}
                        resetCheckBoxStates={resetCheckBoxStates}
                    />
                </div>
                :
                <h3>Mobile Not Supported</h3>
            }
        </div>
    )
}

const mapStateToProps = state => ({

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setBrandFilter: setBrandFilter,
        setPositionFilter: setPositionFilter,
        setModelFilter: setModelFilter,
        setSizeFilter: setSizeFilter,
        setPlyFilter: setPlyFilter,
        resetAllTireFilters: resetAllTireFilters
    }, dispatch)
}

export default compose(
    muiThemeable(),
    connect(mapStateToProps, matchDispatchToProps),
    withProps(props => {
        let brandListTmp = []
        let positionListTmp = []
        let modelListTmp = []
        let sizeListTmp = []
        let plyListTmp = []
        if (props.currentTireList) {
            props.currentTireList.forEach((tireItem) => {
                if (brandListTmp.indexOf(tireItem.brand) < 0) {
                    brandListTmp.push(tireItem.brand)
                }
                if (positionListTmp.indexOf(camelize(tireItem.position)) < 0) {
                    positionListTmp.push(camelize(tireItem.position))
                }
                if (modelListTmp.indexOf(tireItem.model) < 0) {
                    modelListTmp.push(tireItem.model)
                }
                if (sizeListTmp.indexOf(tireItem.size) < 0) {
                    sizeListTmp.push(tireItem.size)
                }
                if (plyListTmp.indexOf(tireItem.ply) < 0) {
                    plyListTmp.push(tireItem.ply)
                }
            })
        }

        const reorderArray = (array) => {
            let index = array.indexOf(null)
            array.splice(index, 1)
            array.push(null)
            return array
        }

        return ({
            ...{
                currentBrandList: brandListTmp.includes(null) ? reorderArray(brandListTmp) : brandListTmp,
                currentPositionList: positionListTmp.includes(null) ? reorderArray(positionListTmp) : positionListTmp,
                currentModelList: modelListTmp.includes(null) ? reorderArray(modelListTmp) : modelListTmp,
                currentSizeList: sizeListTmp.includes(null) ? reorderArray(sizeListTmp) : sizeListTmp,
                currentPlyList: plyListTmp.includes(null) ? reorderArray(plyListTmp) : plyListTmp
            },
            ...props
        })
    }),
)(TireFilterMenu)