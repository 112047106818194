const ALL_ADVANTAGE_IDS = [
    "2zr5ys19", // general
    "a98db973", // werner
    "32m9lbe4", // smart logistics
    "r85xb5y8", // reed tms
    "k6g3f58q", // kingsgate
    "ju9agnj4", // blue grace
    "dd4udt1b", // england
    "c0419cm1", // crst
    "ch7h4g68r", // ch robinson
    "srukfk5e", // ryder ms
    "wesog299" // wwex
]

export const canShowPrevScreenTitle = () => {
    let visible = false;
    let visibilityAdvantageIds = [ "srukfk5e", "r85xb5y8", "dd4udt1b", "k6g3f58q"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canShowUserManagement = () => {
    let visible = false;
    let visibilityAdvantageIds = ["2zr5ys19", "r85xb5y8", "dd4udt1b", "ju9agnj4", "k6g3f58q","c0419cm1"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canShowMyLoads = () => {
    let visible = false;
    let visibilityAdvantageIds = []
    if(process.env.REACT_APP_ENVIRONMENT === "staging"){
        visibilityAdvantageIds.push(...ALL_ADVANTAGE_IDS)
        
        //ryder can't see my loads on staging
        visibilityAdvantageIds.splice(visibilityAdvantageIds.indexOf("srukfk5e"),1)
    } else {
        // Werner, England, Reed Tms, Blue Grace, General(Bookaload) can see my loads on production AAAHHHHHH! PLEASE BUILD
        visibilityAdvantageIds.push("a98db973")
        visibilityAdvantageIds.push("dd4udt1b")
        visibilityAdvantageIds.push("r85xb5y8")
        visibilityAdvantageIds.push("ju9agnj4")
        visibilityAdvantageIds.push("2zr5ys19")
    }
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canShowBookedLoads = () => {
    let visible = true;
    let excludedAdvantageIds = ["dd4udt1b","srukfk5e"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const canShowInvoices = () => {
    let visible = false;
    let visibilityAdvantageIds = ["k6g3f58q", "ju9agnj4"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canSearchByLoadNumber = () => {
    let visible = false;
    let visibilityAdvantageIds = ["k6g3f58q", "r85xb5y8", "dd4udt1b", "ju9agnj4", "srukfk5e"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canShowCommodityId = () => {
    let visible = true;
    let excludedAdvantageIds = ["dd4udt1b"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const canShowFullAddress = () => {
    let visible = true;
    let excludedAdvantageIds = ["dd4udt1b","r85xb5y8", "2zr5ys19","k6g3f58q"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const canShowLoadType = () => {
    let visible = true;
    let excludedAdvantageIds = ["dd4udt1b"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const receiveUserSignupNotification = () => {
    let visible = false;
    let visibilityAdvantageIds = ["r85xb5y8","dd4udt1b"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canShowBinTradeMark = () => {
    let visible = true;
    let excludedAdvantageIds = ["a98db973"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const defaultUsOriginSearch = () => {
    let visible = false;
    let visibilityAdvantageIds = ["a98db973"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const showCondensedTruckList = () => {
    let visible = false;
    let visibilityAdvantageIds = ["a98db973", "dd4udt1b", "2zr5ys19", "32m9lbe4"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const allowSearchForPastDates = () => {
    let visible = false;
    let visibilityAdvantageIds = ["srukfk5e"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canUseGetNearbyLoadsV3 = () => {
    let visible = true;
    let excludedAdvantageIds = ["2zr5ys19"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const canShowEldScreen = () => {
    let visible = false;
    let visibilityAdvantageIds = ["2zr5ys19"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const getBinAutoPopulateFields = () => {
    let FULL_LIST = ["carrier_name", "carrier_mc", "contact_email", "contact_phone"]
    if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
        return ["carrier_name", "carrier_mc"]
    }
    return FULL_LIST
}