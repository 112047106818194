import React from 'react';
import MyLoadsScreenContent from "../components/my-loads/screen-content"
import MyLoadsWernerScreenContent from "../components/my-loads/werner-screen-content"


const MyLoadScreen = (props) => {

    return (
        <div style={{ height: '100%' }}>
            {process.env.REACT_APP_ADVANTAGE_ID == "a98db973" ?
                <MyLoadsWernerScreenContent {...props} />
                :
                <MyLoadsScreenContent isPaginationEnabled={true} {...props} />
            }
        </div>
    )
}

export default MyLoadScreen