import { createStore, combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import client from './client/reducer'
import signup from './signup/reducer'
import login from './login/reducer'
import ulogin from './ulogin/reducer'
//import loads from './loads/reducer'
//import amaps from './availabilitymaps/reducer'
//import salesmap from './salesmap/reducer'
//import brokermap from './brokermap/reducer'
//import aloads from './availableloads/reducer'
//import matches from './matches/reducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
import outOfNetworkBrokerFooter from './outOfNetworkBrokerFooter/reducer'
import inNetworkBrokersReducer from './inNetworkBrokers/reducer'
//import noteModalReducer from './notemodal/reducer'
import profileReducer from './profile/reducer'
import preferencesReducer from './preferences/reducer'
import TMSPartnerReducer from './TMSPartner/reducer'
import contractReducer from './contract/reducer'
import confirmregReducer from './confirmreg/reducer'
import reactUsStatesMap from './components/react-us-state-map/reducer'
import mapReducer from './components/map/reducer'
import addCapacityReducer from './addCapacity/reducer'
import getCapacityReducer from './addCapacity/getCapacityReducer'
import getStateReducer from './addCapacity/getStateReducer'
import CarrierPortalReducer from './CarrierPortal/reducer'
import CarrierMapReducer from './components/CarrierMapModalV2/reducer'
import AvailabilityLoadsReducer from './CarrierPortal/DirectLinking/AvailabilityLoads/reducer'
import CarrierSignup from './CarrierPortal/DirectLinking/Signup/actionReducer'
import ConfirmContactInfo from './components/ConfirmContactInfo/ActionReducer'
import OutOfNetworkLoadsReducer from './CarrierPortal/components/OutOfNetworkLoadsList/reducer'
//import brokerNotificationsReducer from './brokerNotifications/reducer'
import usersReducer from './users/reducer'
import EmailParserReducer from './EmailParser/reducer'
import QuoteReducer from './components/Quote/reducer'
import LaneHistorySearchReducer from './components/CarrieSearchByLaneAndHistory/reducer'
import CarrierSearch from './components/CarrierSearch/reducer'
import CarrierHistory from './components/LaneHistory/reducer'
import LoadListFilterReducer from './components/LoadList/reducer'
import shipperIntegrationReducer from './shipperIntegration/reducer'
import shipperIntegrationLoadReducer from './shipperIntegration/load/reducer'
import shipperIntegrationLoadUpdateReducer from './shipperIntegration/loadupdate/reducer'
import addIntegrationReducer from './shipperIntegration/AddIntegrationModal/reducer'
import editIntegrationReducer from './shipperIntegration/EditIntegrationModal/reducer'
import {reducer as formReducer} from 'redux-form'
import credentialsReducer from './shipperIntegration/Credentials/reducer'
import offeredLoadReducer from './components/OfferedLoads/reducer'

import { persistReducer } from "redux-persist"
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session'


const IndexReducer = (state,action)=>{
	if(action.type==='LOGOUT'){
		state=undefined
	}

	return appReducer(state,action)
}
 const appReducer = combineReducers({
	signup,
	client,
	login,
	ulogin,
  mapReducer,
  	form,
  	//loads,
  	//amaps,
  	//aloads,
  	//matches,
    //brokermap,
  	loadingBar: loadingBarReducer,
    outOfNetworkBrokerFooter,
    inNetworkBrokersReducer,
    //noteModalReducer,
    profileReducer,
		preferencesReducer,
		TMSPartnerReducer,
		contractReducer,
    confirmregReducer,
    reactUsStatesMap,
	//brokerNotificationsReducer,
	addCapacityReducer,
	getCapacityReducer,
	getStateReducer,
	usersReducer,
	CarrierPortal:CarrierPortalReducer,
	CarrierMapReducer:CarrierMapReducer,
	AvailabilityLoads:AvailabilityLoadsReducer,
	ConfirmContactInfo:ConfirmContactInfo,
	OutOfNetworkLoads:OutOfNetworkLoadsReducer,
	CarrierSignup:CarrierSignup,
	EmailParser:EmailParserReducer,
	QuoteReducer:QuoteReducer,
	LaneHistorySearchReducer:LaneHistorySearchReducer,
	CarrierSearch:CarrierSearch,
	CarrierHistory:CarrierHistory,
	LoadListFilterReducer:LoadListFilterReducer,
	shipperIntegrationReducer,	
	shipperIntegrationLoadReducer,
	shipperIntegrationLoadUpdateReducer,
	credentialsReducer,
	offeredLoadReducer,
	form: formReducer.plugin({
		AddIntegrationForm: addIntegrationReducer,
		EditIntegrationForm: editIntegrationReducer,
	}),		
})


const persistConfig = {
	key: "root",
	storage: storageSession,
	whitelist: [
		"CarrierPortal"
	],
	blacklist: [
		"search"
	]
};

const persistedReducer = persistReducer(persistConfig, IndexReducer);


export default persistedReducer
