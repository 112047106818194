import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//COMPONENTS//
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';


const RoleSelect = ({
    //PROPS FROM PARENT//
    handleSetRole,
    existingRole = null,
    //OTHER
    muiTheme,
    mobileView = false
}) => {

    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.08,
            margin: 10
        },
        wrapper: {
            display: 'contents',
            fontSize: '16px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        placeholder: {
            color: '#757575',
        },
        menuItem: {
            fontSize: '16px'
        },
        selectfieldStyle: {
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        icon: {
            position: 'absolute',
            fontSize: '2.5rem',
            textAlign: 'right'
        },
        removePadding: {
            paddingRight: '0 !important'
        }
    });
    const classes = useStyles();

    const roles = [
        { name: "ADMIN", value: "admin", description: "view loads, search for loads, make offers, book loads, submit invoices, manage users" },
        { name: "DISPATCHER", value: "dispatch", description: "view loads, search for loads, make offers, book loads" },
        { name: "LOAD VIEWER", value: "loadviewer", description: "view loads, search for loads" },
        { name: "FINANCE", value: "finance", description: "view loads, search for loads, submit invoices" }
    ]
    const [finalSelectedRole, setFinalSelectedRole] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if ( (existingRole) && roles.some((role) => role.value === existingRole)) {
            setFinalSelectedRole(existingRole)
        }
    }, []);

    function handleChange(event) {
        const { value } = event.target;
        let chosenTruckType = value

        setFinalSelectedRole(chosenTruckType);
        handleSetRole(chosenTruckType)
    };

    function renderSelectedType() {
        if (finalSelectedRole === null) {
            return (
                <div className={classes.wrapper} style={{ fontSize: 14, color: "#aaa" }}>
                    {
                        'Select Role'
                    }
                </div>
            )
        }
        else {
            let selectedRole = roles.find((role) => role.value === finalSelectedRole)
            return (
                <div className={classes.wrapper}>
                    {
                        selectedRole.name
                    }
                </div>
            )
        }
    }

    return (
        <div style={{ flex: 1 }}>
            {roles &&
                <FormControl variant="filled" style={{ width: "100%" }}>
                    {/* <InputLabel id="demo-simple-select-filled-label">Required*</InputLabel> */}
                    <Select
                        className={classes.selectfieldStyle}
                        labelId="select-label"
                        value={finalSelectedRole}
                        onChange={(event) => handleChange(event)}
                        renderValue={(selected) => renderSelectedType(selected)}
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                                root: classes.removePadding
                            },
                        }}
                        MenuProps={{
                            disableScrollLock: true,
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            }
                        }}
                        onOpen={() => setMenuOpen(true)}
                        onClose={() => setMenuOpen(false)}
                    >
                        <MenuItem
                            className={classes.menuItem}
                            style={{ color: "#aaa" }}
                            value=""
                            disabled>
                            Select Role
                        </MenuItem>
                        {roles && roles.map((role) => {
                            return (
                                <MenuItem
                                    key={role.value}
                                    className={classes.menuItem}
                                    value={role.value}
                                    style={{ display: "block" }}
                                >
                                    <p>{role.name}</p>
                                    <p style={{ color: "grey", whiteSpace: 'normal' }}>{role.description}</p>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            }
        </div>
    );
}


export default RoleSelect;