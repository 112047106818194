import * as Constants from './constants'

const initialState = {
  requesting: false,
  successful: false,  
  loads: [],
  total: 0  
}

const reducer = function shipperIntegrationLoadUpdateReducer (state = initialState, action) {
  //console.log('reducer',action.type)
  switch (action.type) {
      case Constants.LOADUPDATES_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Looking up your loads...', time: new Date() }],
        errors: [],
        loadupdates: []
      }

    case Constants.LOADUPDATES_SUCCESS:
      return {
        ...state,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        loadupdates: action.loadupdates,        
        total: action.total
      }

    default:
      return state
  }
}

export default reducer