import React from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import isMobile from '../../../HOC/Mobile.js'

import * as UserManagementApi from '../../apis/api-user-management';
import UserCardMobile from './user-card-mobile';
import UserCardDesktop from './user-card-desktop';


const UserItem = (props) => {
    const classes = useStyles();
    const { item, successCallback, isMobile } = props

    const camelize = (str) => {
        if (str) {
            const wordArray = str.split(" ")
            let returnStr = ""
            wordArray.forEach(word => {
                const firstChar = word.slice(0, 1)
                const endOfWord = word.slice(1, word.length)
                returnStr = returnStr + " " + firstChar.toUpperCase() + endOfWord.toLowerCase()
            });
            return returnStr
        }
        return ""
    }

    const formatPhoneNumber = (phone) => {
        let returnStr = ""
        let number = phone
        var cleaned = ('' + phone).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            number = [match[2], '-', match[3], '-', match[4]].join('');
        }
        returnStr = returnStr + number
        return returnStr
    }

    const getUserRoleDisplayText = (role) => {
        switch (role) {
            case "admin":
                return <p className={classes.mainText}>ADMIN</p>
            case "dispatch":
                return <p className={classes.mainText}>DISPATCHER</p>
            case "loadviewer":
                return <p className={classes.mainText}>LOAD VIEWER</p>
            case "finance":
                return <p className={classes.mainText}>FINANCE</p>
            default:
                return null
        }
    }

    const deleteUser = () => {
        // this method will be called when rejecting pending users as well as when deleting existing users
        UserManagementApi.DeleteCarrierUser(item.membershipId).then(response => {
            // console.log("DELETE USER RESPONSE: ", response)
            if (response.status) {
                // refresh the list and show success message
                toast.success("User successfully removed from carrier.")
                successCallback()
            } else {
                toast.error("Error: " + response.message)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem removing user."
            toast.error(errorMessage)
        })
    }
    const activateUser = () => {
        // this method will be called when approving pending users
        UserManagementApi.ActivateCarrierUser(item.membershipId).then(response => {
            // console.log("ACTIVATE USER RESPONSE: ", response)
            if (response.status) {
                // refresh the list and show success message
                toast.success("User successfully added to carrier.")
                successCallback()
            } else {
                toast.error("Error: " + response.message)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem approving users."
            toast.error(errorMessage)
        })
    }
    // const invalidateUser = () => {
    //     // this method will be called when blocking existing users
    //     UserManagementApi.InvalidateCarrierUser(item.membershipId).then(response => {
    //         // console.log("INVALIDATE USER RESPONSE: ", response)
    //         if (response.status) {
    //             // refresh the list and show success message
    //             toast.success("User successfully blocked from carrier.")
    //             successCallback()
    //         } else {
    //             toast.error("Error: " + response.message)
    //         }
    //     }).catch((error) => {
    //         let errorMessage = "Error: There was a problem blocking user."
    //         toast.error(errorMessage)
    //     })
    // }
    const editUser = (newRole) => {
        UserManagementApi.EditCarrierUser(item.membershipId, newRole).then(response => {
            // console.log("EDIT USER RESPONSE: ", response)
            if (response.status) {
                // refresh the list and show success message
                toast.success("User successfully updated.")
                successCallback()
            } else {
                toast.error("Error: " + response.message)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem updating user."
            toast.error(errorMessage)
        })
    }

    return (
        <div style={{ display: 'contents' }}>
            {isMobile ?

                <UserCardMobile
                    item={item}
                    camelize={camelize}
                    formatPhoneNumber={formatPhoneNumber}
                    deleteUser={deleteUser}
                    activateUser={activateUser}
                    editUser={editUser}
                />
                :
                <UserCardDesktop
                    item={item}
                    camelize={camelize}
                    formatPhoneNumber={formatPhoneNumber}
                    deleteUser={deleteUser}
                    activateUser={activateUser}
                    editUser={editUser}
                />

            }
        </div>

    );
}


export default compose(
    muiThemeable(),
    isMobile()
)(UserItem)

const useStyles = makeStyles({
    root: {
        backgroundColor: "white",
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    col: {
        height: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start"
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        // fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '11px',
        lineHeight: '22px',
        color: 'grey'
    }
});