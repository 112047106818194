import React from 'react'

const LcDataWrap = ( props ) => {

    const {
        clickWidth = '50%',
        children,
    } = props

    return (
        <div style={{cursor: 'default', whiteSpace:'nowrap', width:clickWidth, paddingLeft:5, display: 'contents'}} onMouseDown={(event)=>event.stopPropagation()}>{children}</div>
    )
}

export default LcDataWrap;