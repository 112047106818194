import {BACKEND_URL} from './constants.js'

export function postLanePreferences(laneSelections){
  var requestUrl=BACKEND_URL+'/addLaneZones'
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                            body:JSON.stringify(laneSelections)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function getLanePreferences(){
  var requestUrl=BACKEND_URL+'/getLaneZones'
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            }
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function getZones(){
  var requestUrl=BACKEND_URL+'/api/getZonesAndStates'
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            }
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}