//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'

//MESSAGING
  import * as MESSAGES from './constants'

//APIs
  import {getZones,getLanePreferences} from '../../api/lanePreferences'

function* getZonesFlow(action){
  try {

    const response = yield call(getZones)
    if(response.status){
      yield put({type:MESSAGES.GET_ZONES_SUCCESS,payload:response.results})
    }

  } catch (error) {
    console.log(error)

  }
}
function* getZonePrefsRequestingFlow(action){
  try {

    const response = yield call(getLanePreferences)
    if(response.status){
      yield put({type:MESSAGES.GET_ZONE_PREFS_SUCCESS,payload:response.result})
    }else{
      yield put({type:MESSAGES.GET_ZONE_PREFS_SUCCESS,payload:[]})
    }

  } catch (error) {
    console.log(error)

  }
}

function* templateWatcher () {
  yield takeLatest(MESSAGES.GET_ZONES_REQUESTING, getZonesFlow)
  yield takeLatest(MESSAGES.GET_ZONE_PREFS_REQUESTING, getZonePrefsRequestingFlow)

}

export default templateWatcher