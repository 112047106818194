import * as MESSAGES from '../constants'

const initialState = {
  requesting: false,
  successful: false,
  data:false
}

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'GET_BROKERS_SUC':
        var payload = action.response.brokers
        var retValue={
          ...state,
          requesting:false,
          successful:true
        }
        retValue.data=payload
        return retValue
    default:
      return state
  }
}

export default SearchReducer
