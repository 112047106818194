/*global google*/
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment';
import { handleApiErrors } from '../lib/api-errors'
import {
  CALL_IN_NETWORK_BROKER,
  CALL_IN_NETWORK_BROKER_SUCCESS,
  CALL_IN_NETWORK_BROKER_ERROR,
  CANCEL_BROKER,
  CANCEL_BROKER_SUCCESS,
  CANCEL_BROKER_ERROR,
  INVITE_BROKER_2,
  INVITE_BROKER_SUCCESS_2,
  INVITE_BROKER_ERROR_2,
} from './constants'
import {getInnetworkbroker,cancelbroker} from '../api/innetworkbroker'
import {inviteNewBrokers} from '../api/outofnetworkbroker'




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* getBrokerList (action) {
  try {


    yield put(showLoading())

    // here until the API async function, is complete!
    const response = yield call(getInnetworkbroker)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    
    yield put({ type: CALL_IN_NETWORK_BROKER_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: CALL_IN_NETWORK_BROKER_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* cancelBrokerCall (action) {
  try {

    const {eachBroker }= action; 
    console.log('cancelBrokerCall')
    yield put(showLoading())

    // here until the API async function, is complete!
    const response = yield call(cancelbroker,eachBroker)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    if(response.status){
      yield put({ type: CANCEL_BROKER_SUCCESS, response })
	   action.updateCallback()
    }
    else{
      yield put({ type: CANCEL_BROKER_ERROR, error:response.message })
    }
    
    //yield put({ type: CANCEL_BROKER_SUCCESS, response })
   

  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: CANCEL_BROKER_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}




function* inviteBrokerCall (action) {
  try {

    const {eachBroker }= action; 
    yield put(showLoading())
    console.log('inviteBroker')
    // here until the API async function, is complete!
    const response = yield call(inviteNewBrokers,eachBroker)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    if(response.status){
	  yield put({ type: INVITE_BROKER_SUCCESS_2, response })
      action.updateCallback()
    }
    else{
      yield put({ type: INVITE_BROKER_ERROR_2, error:response.message })
    }

    
    
    //yield put({ type: INVITE_BROKER_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: INVITE_BROKER_ERROR_2, error })

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the LOADS_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* getInBrokerWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(CALL_IN_NETWORK_BROKER, getBrokerList)
  yield takeLatest(CANCEL_BROKER, cancelBrokerCall)
  yield takeLatest(INVITE_BROKER_2, inviteBrokerCall)
}

export default getInBrokerWatcher