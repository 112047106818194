import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'

const SIGNUP_CARRIER_URL = "/api/carrier/brokerAdvantageSignUp"



export function signup(params) {

    const url = BACKEND_URL + SIGNUP_CARRIER_URL

    try {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + SIGNUP_CARRIER_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + SIGNUP_CARRIER_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

