import {BACKEND_URL} from './constants.js'


export function sendMessage(payload){
  var requestUrl=BACKEND_URL+'/guest/contactUs'
  var payload=JSON.stringify(payload)
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Content-Type':'application/json',
                            },
                            body:payload
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}


export function scheduleDemo(payload){
  var requestUrl=BACKEND_URL+'/guest/scheduleDemo'
  var payload=JSON.stringify(payload)
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Content-Type':'application/json',
                            },
                            body:payload
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}