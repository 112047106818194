import {BACKEND_URL} from './constants.js'


export function getInnetworkbroker(){
  
  var requestUrl=BACKEND_URL+'/getAllBrokers' ;
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function cancelbroker(cancelBroker){
  console.log('cancelbroker');
  console.log(cancelBroker);
  
  var requestUrl=BACKEND_URL+'/unsubscribeBroker';

  
  var postBody        =     { brokerId : cancelBroker.id};
  console.log(postBody);
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                            body:JSON.stringify(postBody),


                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
