import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Row } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { Card } from 'material-ui/Card';
import Switch from '@material-ui/core/Switch';
import * as FeatherIcon from 'react-feather';
import {mapZone} from '../../../../LoadSearchBar/zones';
import LinearProgress from '@material-ui/core/LinearProgress';

import { checkValueExists } from "../../../../../../lib/check-value-exists"
import moment from "moment"
import {canShowBrokers} from '../../../../../../CarrierPortal/components/preferences/components/BrokerMultiSelect'
import {getTimeRangeText} from "../../AvailableDateTimePicker"

const TruckCapacityCardMobile = (props) => {

    const classes = useStyles()

    const {
        muiTheme,
        item,
        notificationsOn,
        onHold,
        setShowEditModal,
        handleToggleNotification,
        handleToggleOnHold,
        handleDeleteCapacity,
        viewOnly=false,
        isLoading=false
    } = props

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: muiTheme.secondary,
                    borderColor: muiTheme.secondary,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const getLocUI =(location)=>{
        let ui = null
        if(checkValueExists(location, "locationSelectType")){

            if(checkValueExists(location, "locationDetails") && location.locationSelectType == "SPECIFIC"){
                ui = (<p style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{location.locationDetails.city ? location.locationDetails.city + ", " : ""}{location.locationDetails.state}</p> )
            }
            else if(checkValueExists(location, "locationZone") && location.locationSelectType == "ZONE"){
                let zoneName = mapZone(location.locationZone.abbr)
                if(zoneName && checkValueExists(zoneName,'name')){
                    ui = (<p style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{zoneName.name}</p> )
                }else{
                    ui = (<p></p> )
                }
            }
            else if( location.locationSelectType == "CUSTOM"){
                if(location.locationCustom!==null && Array.isArray(location.locationCustom) && location.locationCustom.length>0){
                    ui = (<p style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{location.locationCustom.join(',')}</p>)
                }else{
                    ui = (<p >{'ANYWHERE'}</p>)
                }
            }
        }
        return ui
    }
    const hasBrokers = ()=>{
        let has = false
        if(checkValueExists(item, "brokers") && Array.isArray(item.brokers) && item.brokers.length>0){
            let firstElemet = item.brokers[0]
            let key = Object.keys(firstElemet).map((key) => key)
            if(Array.isArray(key) && key.length>0)
            {
                has = true
            }
        }
        return has
    }

    const showBrokersName = (broker)=>{
       let names=  Object.keys(broker).map((key) => broker[key])
       let ui = []
       if(Array.isArray(names) && names.length>0){
            ui = names
       }
       
       return ui
    }


    const getTimeRangeUI = ()=>{
        let range = (
            <span>
                {checkValueExists(item.availableAt, "timeFrom") ?
                        item.availableAt.timeFrom : ""
                }
                {checkValueExists(item.availableAt, "timeTo") ?
                    
                        " to "+ item.availableAt.timeTo : ""
                }
            </span>
        )
        let ui = (
            <p >
                {range}
            </p>
            )
        if(checkValueExists(item, "availableAt") && checkValueExists(item.availableAt, "timeFrom") &&  checkValueExists(item.availableAt, "timeTo")){
            let rangeTxt = getTimeRangeText(item.availableAt)
            if(rangeTxt !== ''){
                ui = (
                    <p >
                            {rangeTxt} ({range})
                    </p>
                    )
            }
        }
        return ui
    }

    const details = (
        <div >

                {!viewOnly && (
                    <Row>
                        <p className={classes.mobileLabelText}>
                            Notifications:{" "}
                        </p>
                        {/* <p className={classes.mobileValueText} style={{fontWeight: 'bold',}}>
                            On Hold:{" "}
                        </p> */}
                        <p className={classes.mobileValueText}>
                            <AntSwitch checked={notificationsOn} onChange={handleToggleNotification} />
                        </p>
                    </Row>
                )}
                {/* {!viewOnly && (
                <Row>
                    <p className={classes.mobileLabelText}>
                        <AntSwitch checked={notificationsOn} onChange={handleToggleNotification} />
                    </p>
                    <p className={classes.mobileValueText}>
                        <AntSwitch checked={onHold} onChange={handleToggleOnHold} />
                    </p>
                </Row>
                )} */}

                <Row>
                    <p className={classes.mobileLabelText}>
                        Available Date:{" "}
                    </p>
                    {checkValueExists(item, "availableAt") && checkValueExists(item.availableAt, "date")  && (
                        <p className={classes.mobileValueText} style={{textAlign:'right'}}>
                                <p  style={{ textTransform: "capitalize" ,display:'flex',justifyContent:'flex-end' }}>
                                    {moment(item.availableAt.date).format("ddd")} <span style={{marginLeft:10}} >{moment(item.availableAt.date).format("MM/DD")}</span> 
                                </p>
                                {getTimeRangeUI()}
                        </p>
                    )}
                </Row>


                <Row>
                    <p className={classes.mobileLabelText}>
                        Origin:{" "}
                    </p>
                    <p className={classes.mobileValueText}  style={{textAlign:'right'}}>
                        {checkValueExists(item, "origin") && getLocUI(item.origin)}
                    </p>
                </Row>
                <Row style={{ display: 'flex'  }}>
                    <p className={classes.mobileLabelText}>
                        Destinations:{" "}
                    </p>
                    <p  className={classes.mobileValueText} style={{textAlign:'right'}}>
                    {checkValueExists(item, "destination") && getLocUI(item.destination)}
                    </p>
                </Row>

                <Row style={{ display: 'flex'  }}>
                    <p className={classes.mobileLabelText}>
                        Equipments:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                        {/* { checkValueExists(item, "truckTypes")  && Object.keys(item.truckTypes).map((key,index) => 
                            <span >{key} {index <Object.keys(item.truckTypes).length-1 ?" , ":""} </span>
                        )} */}
                        { checkValueExists(item, "truckTypes") && Array.isArray(item.truckTypes)  && item.truckTypes.map((truckType,index) => 
                            <span>{truckType.name} {index <item.truckTypes.length-1 ?" , ":""} </span>
                        )} 
                    </p>
                </Row>
                
                <Row style={{ display: 'flex'  }}>
                    <p className={classes.mobileLabelText}>
                        Rate:{" "}
                    </p>
                    {checkValueExists(item, "rate") && checkValueExists(item.rate, "value") && item.rate.value !=='' && parseFloat(item.rate.value)>0 && (
                        <p className={classes.mobileValueText}>${parseFloat(item.rate.value).toFixed(2)} {checkValueExists(item.rate, "type") && item.rate.type =="RPM"? "/mile":''}</p>
                    )}
                </Row>
                
                { hasBrokers() &&  canShowBrokers() &&(
                    <Row style={{ flexWrap: 'wrap' }}>
                    <p className={classes.mobileLabelText}>
                        Brokers:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                        { checkValueExists(item, "brokers") && Array.isArray(item.brokers) && item.brokers.length>0 && item.brokers.map((broker,index) => 
                                showBrokersName(broker).map((name,index)=>
                                    <p style={{textAlign:'right'}} >{name} {index <showBrokersName(broker).length-1 ?" ,":""}</p>
                                )
                                
                        )}
                    </p>
                    </Row>
                )}

            </div>
    )



    const fullCard =  (
        <Card style={{ width: "100%", flexDirection: "column", justifyContent: "center", borderRadius: 15 }}>

            <div style={{ padding: 15 }}>
            {details}
            </div>
            {isLoading && (<LinearProgress color="secondary" style={{width:'100%'}} />)}
            <Row style={{
                display: 'flex',
                width: '100%',
                height: '28%',
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: '#F6F6F6',
                margin: 0
            }}>
                <Button
                    style={{ width: '10%', textAlign: 'right' }}
                    onClick={() => handleDeleteCapacity()}
                >
                    <FeatherIcon.Trash2 color={"black"} size={25} />
                </Button>
                <div style={{ width: '80%', textAlign: 'center' }}>
                </div>

                <Button
                    style={{ width: '10%', textAlign: 'right' }}
                    onClick={() => setShowEditModal(true)}
                >
                    <FeatherIcon.Edit color={"black"} size={25} />
                </Button>
            </Row>
            
        </Card>
    )

    if(!viewOnly){
        return fullCard
    }else{
        return details
    }
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(TruckCapacityCardMobile)

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    card: {
        display: "flex",
        height: 'auto',
        flexDirection: "row",
        alignItems: "center",
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height: 100
    },
    margin: {
        margin: 5,
    },
    mobileValueText: {
        width: 'calc(100% - 110px)',
        display: 'inline-block',
        float: 'right',
        color: 'black',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'right'
    },
    mobileLabelText: {
        fontWeight: 'bold',
        display: 'inline-block',
        color: 'black',
        width: 110
    }
}));
