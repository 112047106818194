import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { Card } from 'material-ui/Card';
import * as FeatherIcon from 'react-feather';
import FA from 'react-fontawesome'
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import SimpleLineIcon from 'react-simple-line-icons';
import Popover from '@material-ui/core/Popover';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { mapZone } from '../../../../LoadSearchBar/zones';
import LinearProgress from '@material-ui/core/LinearProgress';

import { checkValueExists } from "../../../../../../lib/check-value-exists"
import moment from "moment"
import useGtagHook from '../../../../../../gtag-hook';
import { canShowBrokers } from '../../../../../../CarrierPortal/components/preferences/components/BrokerMultiSelect'

import { getTimeRangeText } from "../../AvailableDateTimePicker"

const useStyles = makeStyles((style) => ({
    card: {
        display: "flex",
        height: 'auto',
        flexDirection: "row",
        alignItems: "center",
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height: 100

    },
    colType2: {
        display: "flex",
        flexDirection: "row",
        // height: 100
    },
    margin: {
        margin: 5,
    },
    mainText: {
        fontFamily: 'Arial !important',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        margin: 0
    }
}));

const TruckCapacityCard = (props) => {

    const classes = useStyles()

    const {
        muiTheme,
        item,
        handleViewLoads,
        availability,
        notificationsOn,
        onHold,
        setShowEditModal,
        handleToggleNotification,
        handleToggleOnHold,
        handleDeleteCapacity,
        isLoading = false
    } = props
    
    /* --- Popover state control --- */
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [sendGtagEvent] = useGtagHook()

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    /* ----------------------------- */

    const getLocUI =(location)=>{
        let ui = null
        if (checkValueExists(location, "locationSelectType")) {

            if (checkValueExists(location, "locationDetails") && location.locationSelectType == "SPECIFIC") {
                ui = (<p className={classes.mainText}>{location.locationDetails.city ? location.locationDetails.city + ", " : ""}{location.locationDetails.state}</p>)
            }
            else if (checkValueExists(location, "locationZone") && location.locationSelectType == "ZONE") {
                let zoneName = mapZone(location.locationZone.abbr)
                if (zoneName && checkValueExists(zoneName, 'name')) {
                    ui = (<p className={classes.mainText}>{zoneName.name}</p>)
                } else {
                    ui = (<p className={classes.mainText}></p>)
                }
            }
            else if (location.locationSelectType == "CUSTOM") {
                if (location.locationCustom !== null && Array.isArray(location.locationCustom) && location.locationCustom.length > 0) {
                    ui = (<p className={classes.mainText}>{location.locationCustom.join(',')}</p>)
                } else {
                    ui = (<p className={classes.mainText}>{'ANYWHERE'}</p>)
                }
            }
        }
        return ui
    }

    const hasBrokers = () => {
        let has = false
        if (checkValueExists(item, "brokers") && Array.isArray(item.brokers) && item.brokers.length > 0) {
            let firstElemet = item.brokers[0]
            let key = Object.keys(firstElemet).map((key) => key)
            if (Array.isArray(key) && key.length > 0) {
                has = true
            }
        }
        return has
    }

    const showBrokersName = (broker) => {
        let names = Object.keys(broker).map((key) => broker[key])
        if (Array.isArray(names) && names.length > 0) {
            return names.join(' , ')
        }
        else {
            return ''
        }
    }


    const getTimeRangeUI = () => {
        let ui = (
            <span className={classes.mainText} style={{textAlign:'center'}}>
                    {checkValueExists(item, "availableAt") && checkValueExists(item.availableAt, "timeFrom")   && item.availableAt.timeFrom} {checkValueExists(item, "availableAt") && checkValueExists(item.availableAt, "timeTo") && " to "+ item.availableAt.timeTo} 
            </span>
        )
        if (checkValueExists(item, "availableAt") && checkValueExists(item.availableAt, "timeFrom") && checkValueExists(item.availableAt, "timeTo")) {
            let rangeTxt = getTimeRangeText(item.availableAt)
            if (rangeTxt !== '') {
                ui = (
                    <span className={classes.mainText} style={{ textAlign: 'center' }} >
                        {rangeTxt}
                        <br />
                        {checkValueExists(item, "availableAt") && checkValueExists(item.availableAt, "timeFrom") && item.availableAt.timeFrom} {checkValueExists(item, "availableAt") && checkValueExists(item.availableAt, "timeTo") && " to " + item.availableAt.timeTo}
                    </span>
                )
            }
        }
        return ui
    }

    return (
        [
            <Card style={{ width: "100%", flexDirection: "column", justifyContent: "center" }}>
                <Row className={classes.card} style={{ padding: 15, }} >
                    <Col className={classes.col} style={{ width: "5%", alignItems: "center" }}>
                        <Checkbox
                            key="notification_checkbox"
                            style={{ color: muiTheme.secondary, transform: "scale(1.5)" }}
                            color="default"
                            checked={notificationsOn}
                            onClick={handleToggleNotification}
                        />
                    </Col>

                    <Col className={classes.col} style={{ width: "5%", alignItems: "center" }}>
                        {/* <Checkbox
                        key="onHold_checkbox"
                        style={{ color: muiTheme.secondary, transform: "scale(1.5)" }}
                        color="default"
                        checked={onHold}
                        onClick={handleToggleOnHold}
                    /> */}
                    </Col>
                    <Col className={classes.col} style={{ width: "9%", alignItems: "center" }}>
                        {checkValueExists(item, "availableAt") && checkValueExists(item.availableAt, "date") && (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Row>
                                    <p className={classes.mainText} style={{ textTransform: "capitalize" }}>
                                        {moment(item.availableAt.date).format("ddd")}
                                    </p>
                                </Row>
                                <Row>
                                    <p className={classes.mainText}>
                                        {moment(item.availableAt.date).format("MM/DD")}
                                    </p>
                                </Row>
                            </div>
                        )}
                    </Col>
                    <Col className={classes.col} style={{ width: "1%", alignItems: "center" }}>
                    </Col>

                    <Col className={classes.col} style={{ width: "7%", alignItems: "center" }}>
                        <Row style={{ display: 'flex' }} >
                            {getTimeRangeUI()}
                        </Row>
                    </Col>

                    <Col className={classes.col} style={{ width: "6%", alignItems: "center" }}>
                    </Col>
                    <Col className={classes.col} style={{ width: "18%", alignItems: "flex-start" }}>
                        <Row>
                            {checkValueExists(item, "origin") && getLocUI(item.origin)}
                        </Row>
                    </Col>
                    <Col className={classes.col} style={{ width: "3%", alignItems: "center" }}>
                        <FA name='arrow-right' />
                    </Col>
                    <Col className={classes.col} style={{ width: "18%", alignItems: "flex-start" }}>
                        <Row>
                            {checkValueExists(item, "destination") && getLocUI(item.destination)}
                        </Row>
                    </Col>
                    <Col className={classes.col} style={{ width: "1%", alignItems: "center" }}></Col>
                    <Col className={classes.col} style={{ width: "12%", alignItems: "flex-start" }}>
                        <Row>
                            {/* { checkValueExists(item, "truckTypes")  && Object.keys(item.truckTypes).map((key,index) => 
                            <span className={classes.mainText}>{key} {index <Object.keys(item.truckTypes).length-1 ?" , ":""} </span>
                        )}   */}
                        { checkValueExists(item, "truckTypes") && Array.isArray(item.truckTypes)  && item.truckTypes.map((truckType,index) => 
                            <span className={classes.mainText}>{truckType.name} {index <item.truckTypes.length-1 ?" , ":""} </span>
                        )} 
                    </Row>
                </Col>
                
                <Col className={classes.col} style={{ width: "2%", alignItems: "center" }}>
                    
                </Col>
                <Col className={classes.col} style={{ width: "7%", alignItems: "flex-start" }}>
                    {checkValueExists(item, "rate") && checkValueExists(item.rate, "value") && item.rate.value !=='' && parseFloat(item.rate.value)>0 && (
                        <p className={classes.mainText}>${parseFloat(item.rate.value).toFixed(2)} {checkValueExists(item.rate, "type") && item.rate.type =="RPM"? "/mile":''}</p>
                    )}
                </Col>
                
                <Col className={classes.col} style={{ width: "6%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", paddingTop: 15, paddingBottom: 15 }}>
                    <Button
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: muiTheme.actionColor, color: "#fff", fontSize: 14 }}
                        onClick={() => {
                            sendGtagEvent(
                                "preferences_capacity_view_loads",
                                {
                                    id: item.id,
                                    receiveNotifications: item.receiveNotifications,
                                    // visibility: item.visibility,
                                    // onHold: item.onHold,
                                    pickupLocation: JSON.stringify(item.origin),
                                    deliveryLocation: JSON.stringify(item.destination),
                                    equipments: JSON.stringify(item.truckTypes),
                                    rate: item.rate.value,
                                    rateType: item.rate.type,
                                    pickupDate: item.availableAt.date,
                                    pickupWindow: `${item.availableAt.timeFrom} - ${item.availableAt.timeTo}`,
                                    availableAt: JSON.stringify(item.availableAt),
                                    brokers: JSON.stringify(item.brokers)
                                }
                            )
                            handleViewLoads(item)
                        }}
                    >
                        VIEW LOADS
                    </Button>
                    <IconButton aria-describedby={id} onClick={handleClick}>
                        <SimpleLineIcon name={'options'} color={'black'} style={{fontSize: 30}} />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box p={1}>
                            <Button
                                onClick={() => {
                                    setShowEditModal(true)
                                    handleClose()
                                    // useGtagHook(
                                    //     "open_edit_saved_search",
                                    //     {
                                    //         location: item.pickupCity + ", " + item.pickupState,
                                    //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                    //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                    //         pickupTime: item.availableTime,
                                    //         destinations: item.destinations,
                                    //         equipments: item.truckTypes
                                    //     }
                                    // )
                                }}
                                startIcon={<FeatherIcon.Edit color={"black"} size={25} />}
                                style={{width:'100%', display: "flex", justifyContent: "left"}}
                            >
                                <p className={classes.mainText} style={{textTransform: 'none'}}> Edit </p>
                            </Button>
                            <br/>
                            <Button
                                onClick={() => {
                                    handleDeleteCapacity()
                                    handleClose()
                                    // useGtagHook(
                                    //     "delete_saved_search",
                                    //     {
                                    //         location: item.pickupCity + ", " + item.pickupState,
                                    //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                    //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                    //         pickupTime: item.availableTime,
                                    //         destinations: item.destinations,
                                    //         equipments: item.truckTypes
                                    //     }
                                    // )
                                }}
                                startIcon={<FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />}
                                style={{width:'100%', display: "flex", justifyContent: "left"}}
                            >
                                <p className={classes.mainText} style={{textTransform: 'none'}}> Delete </p>
                            </Button>
                        </Box>
                    </Popover>
                </Col>
            </Row>
            {isLoading ? <LinearProgress color="secondary" style={{width:'100%'}} /> : null}
            { hasBrokers() && canShowBrokers() &&(
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Row>
                    <p className={classes.mainText}>Brokers</p>
                    </Row>
                    </AccordionSummary>
                    <AccordionDetails>
                            {  item.brokers.map((broker,index) => 
                                    <p className={classes.mainText}>{showBrokersName(broker)}</p>
                            )}
                        </AccordionDetails>
                    </Accordion>
                )}
            </Card >,
        ]
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(TruckCapacityCard)
