// KINGSGATE LOGISTICS THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#0b3a40 ',
    secondary: '#ee9518',
    actionColor: "#318597",
    lightContrastText: '#fff',
    darkContrastText: '#191919',
    useDarkContrastText: false,
    tagLine:'MOVING LOGISTICS FORWARD',
    menuHighlightColor: "rgba(0, 0, 0, 0.3)",
    useWhiteBackgroundBehindLogo: true,
    customerUniqueId: "k6g3f58q",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Helvetica",
    loginMsg: "You've selected a feature that requires a secure login. Please login or sign up.",
    source: "kingsgatelogistics_trucker_tools"
});
//console.log(theme);
export default theme