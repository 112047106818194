/* global $ */
import React from 'react'
import { connect } from 'react-redux'

import muiThemeable from 'material-ui/styles/muiThemeable';

// default TT login
// import DefaultLoginPage from './login/default-login-page';
import DefaultLoginPage from './login/Auth0-Univeral-Login/default-login-page';

import SmartLogisticsLogin from "../customers/32m9lbe4/authentication/login"
import WernerLogin from "../customers/a98db973/authentication/login"
import RyderLogin from "../customers/srukfk5e/authentication/login"
import BlueGraceLogin from "../customers/ju9agnj4/authentication/login"
import KingsgateLogisticsLogin from "../customers/k6g3f58q/authentication/login"
import CRSTLogin from "../customers/c0419cm1/authentication/login"
import WorldwideExpressLogin from "../customers/wesog299/authentication/login"
import CHRobinsonLogin from "../customers/ch7h4g68r/authentication/login"
import useGtagHook from '../../gtag-hook';
import { isExpired, decodeToken } from "react-jwt";


//HOC//
import Mobile from '../../HOC/Mobile'


// If you were testing, you'd want to export this component
// so that you can test your custom made component and not
// test whether or not Redux and Redux Form are doing their jobs
const LoginScreen = (props) => {

    const [sendGtagEvent] = useGtagHook()


    const submitGtagEvent = () => {
        sendGtagEvent("submit_login_request")

    }

    const getUser = () => {
        // return {userId, role, userType, username, name, carrier { companyName, mc, dot, externalId, carrierId, contact: { email, phone, ext}}
        // get token from local storage
        // pull out fields from token
        // return user object
        let token = localStorage.getItem('token')
        const parsedToken = JSON.parse(token)
        // console.log("TOKEN: ", parsedToken)
        let userObj = null
        if (parsedToken != null) {
            userObj = {
                customerAccountId: parsedToken.customerAccountId,
                userId: parsedToken.userId,
                role: parsedToken.role,
                userType: parsedToken.userType,
                username: parsedToken.email,
                fullName: parsedToken.name,
                carrier: {
                    companyName: parsedToken.companyName,
                    mc: parsedToken.mcNumber,
                    dot: parsedToken.dotNumber,
                    externalId: parsedToken.CarrierExternalId,
                    carrierId: parsedToken.carrierId,
                    contact: {
                        email: parsedToken.contactEmail,
                        phone: parsedToken.contactPhone,
                        // ext:
                    }
                }
            }
        }
        return userObj
    }

    const isAuthenticated = () => {
        // attempt to grab the token from localstorage
        const storedToken = localStorage.getItem('token')

        // if it exists
        if (storedToken) {
            // parse it down into an object
            const token = JSON.parse(storedToken)

            if (token.id) {
                // const myDecodedToken = decodeToken(token.id);
                const isMyTokenExpired = isExpired(token.id);
                // console.log("myDecodedToken: ", myDecodedToken)
                // console.log("isMyTokenExpired: ", isMyTokenExpired)
                if (!isMyTokenExpired) {
                    return true
                }
            }
        }

        return false
    }

    if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
        return (
            <WernerLogin {...props} submitGtagEvent={submitGtagEvent} getUser={getUser} isAuthenticated={isAuthenticated} />
        )
    } else if (process.env.REACT_APP_ADVANTAGE_ID == "wesog299") {
        return (
            <WorldwideExpressLogin {...props} submitGtagEvent={submitGtagEvent} getUser={getUser} isAuthenticated={isAuthenticated} />
        )
    } else if (process.env.REACT_APP_ADVANTAGE_ID == "32m9lbe4") {
        return (
            <SmartLogisticsLogin {...props} submitGtagEvent={submitGtagEvent} getUser={getUser} isAuthenticated={isAuthenticated} />
        )
    } else if (process.env.REACT_APP_ADVANTAGE_ID == "ch7h4g68r") {
        return (
            <CHRobinsonLogin {...props} submitGtagEvent={submitGtagEvent} getUser={getUser} isAuthenticated={isAuthenticated} />
        )
    } else {
        // TT default login
        return (
            <DefaultLoginPage {...props} />
        )
    }
}

// make Redux state piece of `login` and our action `loginRequest`
// available in props within our component
const connected = connect()(LoginScreen)

// Export our well formed login component
export default Mobile()(muiThemeable()(connected))

