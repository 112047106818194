

export const INTEGRATIONS_REQUESTING = 'INTEGRATIONS_REQUESTING';
export const INTEGRATIONS_SUCCESS = 'INTEGRATIONS_SUCCESS';
export const ADD_INTEGRATION_REQUESTING = 'ADD_INTEGRATION_REQUESTING';
export const ADD_INTEGRATION_SUCCESS = 'ADD_INTEGRATION_SUCCESS';
export const EDIT_INTEGRATION_INITIALISE = 'EDIT_INTEGRATION_INITIALISE';
export const EDIT_INTEGRATION_REQUESTING = 'EDIT_INTEGRATION_REQUESTING';
export const EDIT_INTEGRATION_SUCCESS = 'EDIT_INTEGRATION_SUCCESS';


export const Integration_Type = {
    'fourkites': 'fourkites',
    'macropoint': 'macropoint',
    'project44': 'project44',
    'jbhunt': 'jbhunt'
};

export const Status = {
    'created': 'created',
    'emailsent': 'emailsent',
    'completed': 'completed',    
};

export const Identifier_Type = {
    'id': 'id',
    'email': 'email',    
};



