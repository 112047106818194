/*global google*/
import {
  CALL_IN_NETWORK_BROKER,
  CALL_IN_NETWORK_BROKER_SUCCESS,
  CALL_IN_NETWORK_BROKER_ERROR,
  CANCEL_BROKER,
  CANCEL_BROKER_SUCCESS,
  CANCEL_BROKER_ERROR,
  INVITE_BROKER_2,
  INVITE_BROKER_SUCCESS_2,
  INVITE_BROKER_ERROR_2,
} from './constants'

const initialState = {
  requesting: false,
  successful: false,
  brokerList: [],
  cancelResponse: [],
  messages: [],
  errors: [],
  cancelSucessCall: false,
}

const reducer = function inNetworkBrokerReducer (state = initialState, action) {
  switch (action.type) {


    case CALL_IN_NETWORK_BROKER_SUCCESS:
      console.log(action);
      return {
        brokerList: action.response.brokers,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        
      }

    case CANCEL_BROKER_SUCCESS:
      console.log('CANCEL_BROKER_SUCCESS');
      console.log(action);
      return {
        cancelResponse : [],
        errors: [],
        messages: [{body:action.response.message}],
        requesting: false,
        successful: true,
        cancelSucessCall: true,
        
      }

    case INVITE_BROKER_SUCCESS_2:
      console.log(action);
      return {
        inviteResponse : [],
        errors: [],
        messages: [{body:action.response.message}],
        requesting: false,
        successful: true,
        inviteSucessCall: true,
        
      }


    
    
    case INVITE_BROKER_ERROR_2:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
		inviteSucessCall: false,
      }
    
    
    case CANCEL_BROKER_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        cancelSucessCall: false,
      }
    
    case CALL_IN_NETWORK_BROKER_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }
      
      default : return state
    
  }
}

export default reducer