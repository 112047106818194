// Universal Login

import { isExpired, decodeToken } from "react-jwt";

export function BaseAuthService() {
    return {
        isAuthenticated() {
            // attempt to grab the token from localstorage
            const storedToken = localStorage.getItem('token')

            // if it exists
            if (storedToken) {
                const isMyTokenExpired = isExpired(storedToken);
                // console.log("isMyTokenExpired: ", isMyTokenExpired)
                if (!isMyTokenExpired) {
                    return true
                }
            }

            return false
        },
        getUser() {

            let token = localStorage.getItem('token')
            const parsedToken = decodeToken(token)
            if (parsedToken == null) {
                return null
            }

            let selectedAccount = localStorage.getItem("selected_account")
            const parsedSelectedAccount = JSON.parse(selectedAccount)
            if (parsedSelectedAccount == null) {
                return null
            }

            // console.log("TOKEN: ", parsedToken)
            let selectAccountIndex = parsedSelectedAccount["index"]
            let namespace = 'https://' + process.env.REACT_APP_AUTH0_DOMAIN + 'app_metadata'
            let userInfo = parsedToken[namespace]
            // console.log("userInfo: ", userInfo)
            let accountInfo = userInfo["accounts"][selectAccountIndex]
            let userObj = null
            if (parsedToken != null) {
                userObj = {
                    customerAccountId: accountInfo.customerAccountId,
                    userId: userInfo.id,
                    role: accountInfo.roles[0],
                    // userType: accountInfo.userType,
                    username: userInfo.userEmail,
                    fullName: userInfo.firstName + " " + userInfo.lastName,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    carrier: {
                        companyName: accountInfo.companyName,
                        mc: accountInfo.mc,
                        dot: accountInfo.dot,
                        externalId: accountInfo.externalId,
                        services: accountInfo.services,
                        carrierId: accountInfo.carrierId
                        // contact: {
                        //     email: userInfo.userEmail, // TODO ???
                        //     phone: userInfo.phoneNumber, // TODO ???
                        //     // ext:
                        // }
                    }
                }
            }
            // console.log("USER: ", userObj)
            return userObj
        },
        onBeforeApiCall(requestObj) {
            // check if authenticated
            // if yes
            //      add header to requestObj with bearer token

            let headers = requestObj.headers
            let newHeaders = {}
            const selectedAccount = localStorage.getItem('selected_account')
            if (selectedAccount) {
                newHeaders = {
                    ...newHeaders,
                    'selectedAccountIndex': JSON.parse(selectedAccount).index
                }
            }
            if (this.isAuthenticated()) {
                newHeaders = {
                    ...newHeaders,
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
                }
            }

            if (headers !== null && typeof headers !== undefined) {
                newHeaders = {
                    ...headers,
                    ...newHeaders
                }
            }

            requestObj.headers = newHeaders

            return requestObj
        },
        async login(token) {
            if (!token) {
                let storedToken = localStorage.getItem('token')
                if (storedToken) {
                    const parsedToken = JSON.parse(storedToken)
                    let promise = new Promise((resolve, reject) => {
                        localStorage.setItem('token', JSON.stringify(parsedToken));
                        let userObj = this.getUser(JSON.stringify(parsedToken))
                        var event = new CustomEvent('login')
                        window.dispatchEvent(event)
                        resolve(userObj)
                    })
                    let returnPromise = await promise
                    return returnPromise
                }
            } else {
                // console.log("LOGIN", token)
                let promise = new Promise((resolve, reject) => {
                    localStorage.setItem('token', JSON.stringify(token));
                    let userObj = this.getUser(JSON.stringify(token))
                    // console.log("LOGIN userObj", userObj)
                    var event = new CustomEvent('login')
                    window.dispatchEvent(event)
                    resolve(userObj)
                })
                let returnPromise = await promise
                return returnPromise
            }
        },
        logout(callback) {
            localStorage.removeItem('token');
            localStorage.removeItem('selected_account');

            var event = new CustomEvent('logout')
            window.dispatchEvent(event)

            try {
                setTimeout(callback, 10);
            } catch (e) {
                console.log(e);
            }
        },
        getSignupPath() {
            return "https://www.truckertools.com"
        }
    }

}