import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//COMPONENTS//
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import HelpWrapper from '../../HelpWrapper'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TireScreenContext } from '../context';


const TireSearchBarSelect = ({
    //PROPS FROM PARENT//
    handleSelect,
    type,
    items,
    //OTHER
    muiTheme,
    mobileView = false
}) => {

    const useStyles = makeStyles({
        root: {
            minWidth: 200,
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.08,
            margin: 'auto',
            padding: '27px 10px 5px',
            '& .MuiChip-label': {
                paddingLeft: 8,
                paddingRight: 8

            },
            "&:focus": {
                background: "white",
            }
        },
        wrapper: {
            display: 'contents',
            fontSize: '16px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        placeholder: {
            color: '#757575',
        },
        menuItem: {
            fontSize: '16px'
        },
        selectfieldStyle: {
            border: '1px solid #ccc',
            height: '55px',
            width: '100%',
            maxWidth: mobileView ? null : '200px',
            minWidth: '200px',
            margin: '0px',
            overflow: 'visible',
            backgroundColor: 'white',
            // outline: '1px solid black',
            fontSize: '16px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        icon: {
            position: 'absolute',
            fontSize: '2.5rem',
            textAlign: 'right'
        },
        chips: {
            fontSize: '12px',
            height: '22px'
        },
        removePadding: {
            paddingRight: '0 !important'
        }
    });

    const classes = useStyles();

    const context = useContext(TireScreenContext)

    const [finalSelected, setFinalSelected] = useState([]); // Array of selected items
    const [menuOpen, setMenuOpen] = useState(false);
    const selectBox = useRef(null)

    useEffect(() => {
        //todo: exisisting coming as null
        let fieldName = type
        if (type === "product") {
            fieldName = "type"
        }
        if (context.searchSelections[fieldName] && context.searchSelections[fieldName] !== []) {
            setFinalSelected(context.searchSelections[fieldName])
        }
    }, []);

    function handleChange(event) {
        let fieldName = type
        if (type === "product") {
            fieldName = "type"
        }
        const { value } = event.target;
        let chosenVal = value
        // console.log("chosenVal: ", chosenVal)
        if (chosenVal.includes("All")) {
            setFinalSelected(["All"])
            handleSelect(fieldName, ["All"])
        } else {
            setFinalSelected(chosenVal)
            handleSelect(fieldName, chosenVal)
        }
    };

    const handleDelete = (valueToDelete) => () => {
        let modifiedSelected = [...finalSelected]
        modifiedSelected = modifiedSelected.filter((value) => value !== valueToDelete);
        setFinalSelected(modifiedSelected);
        let fieldName = type
        if (type === "product") {
            fieldName = "type"
        }
        handleSelect(fieldName, modifiedSelected);
    };

    function renderSelected(selected) {
        let chipMax = 3
        if (selectBox.current)
            chipMax = selectBox.current.clientWidth <= 300 ? 3 : selectBox.current.clientWidth <= 400 ? 3 : 5

        return (
            selected.map((value, index) => {
                if (mobileView & index >= chipMax)
                    return index === chipMax ? <span style={{ margin: 5, fontSize: 18 }}>&hellip;</span> : null
                return (
                    <Chip
                        variant="outlined"
                        key={value}
                        onMouseDown={(event) => {
                            event.stopPropagation();
                        }}
                        label={value}
                        className={classes.chips}
                        onDelete={handleDelete(value)}
                    />
                )
            })
        )

    }

    return (
        <FormControl variant="filled" style={{ marginBottom: mobileView ? 10 : 0 }}>
            <InputLabel
                shrink
                style={{ fontSize: 18, top: -5 }}
            >
                {`${type[0].toUpperCase()}${type.slice(1)}`}
            </InputLabel>
            <Select
                className={classes.selectfieldStyle} åf
                ref={selectBox}
                MenuProps={{
                    getContentAnchorEl: () => null,
                }}
                labelId="select-label"
                //disableUnderline={true}
                multiple
                value={finalSelected}
                onChange={(event) => handleChange(event)}
                renderValue={(selected) => renderSelected(selected)}
                inputProps={{
                    classes: {
                        root: classes.root
                    }
                }}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
            >
                <MenuItem
                    className={classes.menuItem}
                    value="All"
                >
                    <Checkbox checked={finalSelected.indexOf("All") > -1} />
                    <ListItemText classes={{ primary: classes.menuItem }} primary={"All"} />
                </MenuItem>
                {items && items.map((value) => {
                    return (
                        <MenuItem
                            key={value}
                            className={classes.menuItem}
                            value={value}
                            disabled={finalSelected.includes("All") ? true : false}
                        >
                            <Checkbox checked={finalSelected.indexOf(value) > -1} />
                            <ListItemText classes={{ primary: classes.menuItem }} primary={value} />
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
}


export default TireSearchBarSelect;