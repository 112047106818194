import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
export const text_muiForm = ({
  input,
  label,
  variant,
  meta: { touched, error },
  ...props
}) => {
  return (
  <TextField
    label={label}
    variant={variant}
    //errorText={touched && error}
    {...input}
    {...props}
  />
)}

export default text_muiForm