import * as Constants from './constants'

const initialState = {
  requesting: false,
  successful: false,  
  loads: [],
  total: 0,
}

const reducer = function shipperIntegrationLoadReducer (state = initialState, action) {
  //console.log('reducer',action.type)
  switch (action.type) {
      case Constants.LOADS_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Looking up your loads...', time: new Date() }],
        errors: [],
        loads: []
      }

    case Constants.LOADS_SUCCESS:
      return {
        ...state,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        loads: action.loads,        
        total: action.total,
      }    

    default:
      return state
  }
}

export default reducer