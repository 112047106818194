export const TRUCK_CODES_REQUESTING = 'TRUCK_CODES_REQUESTING';
export const TRUCK_CODES_SUCCESS = 'TRUCK_CODES_SUCCESS';
export const SAVE_OFFERED_LOAD_REQUESTING = 'SAVE_OFFERED_LOAD_REQUESTING';
export const SAVE_OFFERED_LOAD_SUCCESS = 'SAVE_OFFERED_LOAD_SUCCESS';
export const SAVE_OFFERED_LOAD_ERROR = 'SAVE_OFFERED_LOAD_ERROR';
export const CANCEL_OFFERED_LOAD_SUCCESS = 'CANCEL_OFFERED_LOAD_SUCCESS'




