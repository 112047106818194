import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import isMobile from '../../HOC/Mobile.js'
import {withState,compose,lifecycle,withHandlers} from 'recompose'
import {bindActionCreators} from 'redux'



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ConfirmDialog = (
    {
        header = 'Confirmation',
        bodyComp = null,
        body = 'Are You Sure?',
        acceptButtonText= 'PROCEED',
        discardButtonText= 'CANCEL',
        onClose,
        open=false,

        //STATE
       // showDialog,setShowDialog,onVisibilityChange,

        isMobile,
        dispatch,
        muiTheme,
        ...props
    }
) => {
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            width: isMobile ? '100%' : 600,
            height: isMobile ? '100%' : 'unset',
            outline: 'none',
            padding: isMobile ? 5 : theme.spacing(2, 4, 3),
        },
        header: {
            margin: 0
        },
        row: {
            marginBottom: 20
        }
    }));
    const classes = useStyles()

    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
    }, []);


    useEffect(() => {
        setShowDialog(open)
    }, [open]);

    const handleClose = () => {
        setShowDialog(false)
        onClose(false)
    };
    const closeModal = (flag) => {
        handleClose()
        if(onClose){
            onClose(flag)
        }
    };


    return (
        <Modal
            aria-labelledby="add-new-location"
            aria-describedby="add-new-location"
            className={classes.modal}
            open={showDialog}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            // disableBackdropClick
            // disableEscapeKeyDown
        >
            <div className={classes.paper} style={{padding:15}} >
                <h3 id="transition-modal-title" style={{marginBottom:15}} >{header}</h3>
                {bodyComp}
                <h4 id="transition-modal-title" style={{marginTop:15,marginBottom:0}} >{body}</h4>
                <Row  style={{ width: "100%", display: "flex", justifyContent: "flex-end",marginTop:10 }} >
                        <Col >
                            <Button
                                id='cancelConfirm'
                                size="small"
                                variant="link"
                                style={{
                                    color: muiTheme.actionColor,
                                    fontSize: 14,
                                    textTransform:'uppercase',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                                onClick={(event) => { closeModal(false) }}
                            >
                               <span >{discardButtonText}</span>
                            </Button>
                        </Col>
                        <Col >
                            <Button
                                id='acceptConfirm'
                                size="small"
                                variant="contained"
                                style={{
                                    backgroundColor: muiTheme.actionColor,
                                    borderColor: muiTheme.actionColor,
                                    color: "white",
                                    fontSize: 14,
                                    textTransform:'uppercase',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginLeft:10
                                }}
                                onClick={(event) => { closeModal(true) }}
                            >
                               <span style={{width: '100%'}} >{acceptButtonText}</span>
                            </Button>
                        </Col>
                    </Row>
            </div>
        </Modal>
    )
}




    const mapStateToProps = state => ({
    })

    function matchDispatchToProps(dispatch){
        return  bindActionCreators({
        },dispatch)
    }





    export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        muiThemeable(),
        isMobile()
    )(ConfirmDialog)