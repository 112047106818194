import React from 'react';
import { compose } from 'recompose';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

//COMPONENTS
import { Form, Row, Col } from 'react-bootstrap'
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import TireSearchBarSelect from './tire-search-bar-select';



const useStyles = makeStyles({
    margin: {
        margin: 5,
        maxHeight: '40px',
    },
});


const TireSearchMobile = ({
    muiTheme,
    handleSearch,
    searchData,
    handleSelect,
    searchParams,
    setShowTireSearchPopover
}) => {
   
    const classes = useStyles()
    return (
        <Form>
            <Row style={{ padding: 15 }}>
                <Col>
                    <FormControl style={{ margin: 0, width: '100%' }}>
                        <TireSearchBarSelect
                            type={"brand"}
                            items={searchData["brand"]}
                            handleSelect={handleSelect}
                            label={"Brand"} valueTmp={"ALL"}
                            mobileView={true}
                        />

                        <TireSearchBarSelect
                            type={"position"}
                            items={searchData["position"]}
                            handleSelect={handleSelect}
                            label={"Position"}
                            valueTmp={"ALL"}
                            mobileView={true}
                        />
                        <TireSearchBarSelect
                            type={"product"}
                            items={searchData["type"]}
                            handleSelect={handleSelect}
                            label={"Product"}
                            valueTmp={"New Tire"}
                            mobileView={true}
                        />
                        <TireSearchBarSelect
                            type={"ply"}
                            items={searchData["ply"]}
                            handleSelect={handleSelect}
                            label={"Ply"}
                            valueTmp={"ALL"}
                            mobileView={true}
                        />
                    </FormControl>
                </Col>
                <Col style={{ textAlign: 'center' }}>
                    <Button
                        variant={"contained"}
                        size={"small"}
                        className={classes.margin}
                        style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, marginRight: 10 }}
                        onClick={() => { handleSearch(); setShowTireSearchPopover(false) }}
                    >
                        Search
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default compose(
    muiThemeable(),
)(TireSearchMobile)