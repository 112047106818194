//returns value of object at given key if truthy
export function getValueIfExists(obj,key,include=[]){
  //if(obj){console.log(obj.hasOwnProperty(key),obj[key],obj[key]!=undefined)}
  if(
    obj &&
    obj.hasOwnProperty(key) &&
      (
        (
          obj[key] &&
          obj[key]!=undefined
        )
        ||
        (
          include.indexOf(obj.key)>=0
        )
      )
  ){
    return obj[key]
  }else{
    return false
  }
}

//returns the first truthy value of arguments past
export function chooseValue(...args){
  return args.filter((arg)=>arg?arg:false)[0]
}

//returns the first value that is truthy in given object
export function pickValue(obj,...options){
  try{
    if(!obj||obj==undefined){return false}
    for(var i=0;i<options.length;i++){
      var option = options[i]
      var thisValue = getValueIfExists(obj,option)
      if(thisValue){return thisValue}
    }
    return false
  }catch(e){
    console.log(e);
    return false
  }
  return false
}
