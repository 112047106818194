import React, {useState,useEffect} from 'react';
import {compose} from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
//COMPONENTS//
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Row } from 'react-bootstrap';
import '../styles.css'

const useStyles = makeStyles((theme) => ({
  display: {
    display: 'flex', 
    justifyContent: 'flex-start' ,
    marginTop:'18px'
  },
}));

const COMPONENT_NAME = ({
  //PROPS FROM PARENT//
    label='',
    type="phone",
    variant='fill',
    error=false,
    required=true,
  //REDUX//

  //STATE
  //HANDLERS
  //OTHER
    muiTheme,
    ...props
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [valueState, setValueState] = useState('');
  const [countryValue, setCountryValue] = useState('usa');
  const [extValue, setExtValue] = useState(null);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    setValueState(props.value?props.value:'')
    let countryTmp = props.country?props.country:'usa';
    countryTmp = countryTmp.toLowerCase();
    setCountryValue(countryTmp)
    setExtValue(props.ext?props.ext:null)
  }, []);
  
  useEffect(() => {
    setHasError(error)
  }, [error]);



  const usa = {
    'country':'usa',
    'countryCode':'+1',
  }
  const canada = {
    'country':'can',
    'countryCode':'+1',
  }
  const maxico = {
    'country':'mex',
    'countryCode':'+52',
  }

  const countryCodes = {
    usa:usa,
    can:canada,
    mex:maxico
  }

  const getFlag = (value)=>{
    switch (value) {
      case 'usa':
            return "flag/United-States-of-America.png";
            break;
      case 'can':
            return "flag/Canada.png";
            break;
      case 'mex':
            return "flag/Mexico.png";
            break;
      default:
            return "flag/United-States-of-America.png";
            break;
    }
  }


  const openMenu = (event) => {
    setOpen(true)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null)
    setOpen(false)
  };

  const selectCountry = (event)=>{
    if(event){
      setCountryValue(event)
      completeFullPhoneNumber(valueState,event,extValue)
    }

  }

  const numberChange = (e)=>{
    let value = e.target.value
    setValueState(value)
    completeFullPhoneNumber(value,countryValue,extValue);
    if(value && !value.length){setStatus('This field is required')}else{setStatus(false)}
  }

  const numberFieldBlur = (e)=>{
    completeFullPhoneNumber(e.target.value,countryValue,extValue);
    let phone = e.target.value
    if(phone && phone.trim().length!==10 && required){
      setHasError(true)
    }
  }


  const  handleExtChange =(e)=>{
    setExtValue(e.target.value)
    completeFullPhoneNumber(valueState,countryValue,e.target.value);
  }



  const completeFullPhoneNumber = (phone,country,ext)=>{
    let countryData =  countryCodes[country]
    let returnData = {
      countryCode :countryData.countryCode,
      phoneNumber : phone?phone:'',
      extension:ext?ext:'',
      type:type,
      country:countryData.country.toUpperCase()
    }

    if(phone && phone.trim().length==10){
      setHasError(false)
    }
    if(props.onValueChange){
      props.onValueChange(returnData)
    }
  }

  
  return (
    <TextField
      placeholder={label}
      fullWidth
      required={required}
      value={valueState}
      onChange={(e,value)=>numberChange(e)}
      onBlur={(e)=>numberFieldBlur(e)}
      //onClick={(e)=>handlePhoneClick(e.target.value)}
      errorStyle={{color:muiTheme.palette.ttRed}}
      //style={{verticalAlign:'top'}}
      error={hasError}
      label={label}
      variant={variant}
      //errorText={touched && error}

      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Menu
              id="select-country-code"
              keepMounted
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              value={countryValue}
            >
              <MenuItem value={'can'} style={{backgroundColor:countryValue=='can'?'lightgray':'inherit'}} onClick={()=>{handleClose();selectCountry('can')}}><img src={getFlag('can')} alt="canada" id="canada" height="12"  style={{marginRight:10}} /> (+1)</MenuItem>
              <MenuItem value={'usa'} style={{backgroundColor:countryValue=='usa'?'lightgray':'inherit'}}  onClick={()=>{handleClose();selectCountry('usa')}}><img src={getFlag('usa')} alt="usa" id="usa"  height="12" style={{marginRight:10}} /> (+1)</MenuItem>
              <MenuItem value={'mex'} style={{backgroundColor:countryValue=='mex'?'lightgray':'inherit'}}  onClick={()=>{handleClose();selectCountry('mex')}}><img src={getFlag('mex')} alt="mexico"  id="mexico" height="12"  style={{marginRight:10}} /> (+52)</MenuItem>
            </Menu> 
            <img onClick={openMenu} src={getFlag(countryValue)} alt="code" height="12"  style={{cursor:'pointer'}} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Row className={classes.display}>
                <Grid item style={{marginRight: '5px', textAlign: 'center', lineHeight: '25px',fontSize:'16px'}} >
                  X
                </Grid>
                <Grid item>
                  <input
                    id="phoneNumberExt"
                    value={extValue}
                    onChange={handleExtChange}
                    placeholder={'Ext.'}
                    class="phoneNumberExt MuiInputBase-input"
                  />
                </Grid>
            </Row>
            
          </InputAdornment>
        ),
      }}
    />
  )
}


export default compose(
  muiThemeable(),
)(COMPONENT_NAME)
