var sampleQuoteHistory = [
  {
    id:1,quote_value:400,message_type:'quote_b',broker:{company_name:'Trucker Tools Broker'},carrier:{company_name:'Pro Trucking'},user:{first_name:'Joe',last_name:'Shmoe'},created_at:'2018-07-11 08:02:00',message:'<p>Would you take 400 for the load?</p>'
  },
  {
    id:5,quote_value:500,message_type:'quote_b',broker:{company_name:'Trucker Tools Broker'},carrier:{company_name:'Pro Trucking'},user:{first_name:'Bob',last_name:'Wyatt'},created_at:'2018-07-13 08:02:00',message:'<p>Meet in the middle?</p>'
  },
  {
    id:2,quote_value:600,message_type:'quote_c',carrier:{company_name:'Pro Trucking'},broker:{company_name:'Trucker Tools Broker'},user:{first_name:'Randall',last_name:'Wrigley'},created_at:'2018-07-11 08:03:00',message:'<p>Best I can do.</p>'
  },
  {
    id:23,quote_value:500,message_type:'quote_c',carrier:{company_name:'Pro Trucking'},broker:{company_name:'Trucker Tools Broker'},user:{first_name:'Randall',last_name:'Wrigley'},created_at:'2018-07-14 08:02:00',message:'<p>I can do that</p>'
  },
]
const initialState = {
  get:{
    requesting: false,
    successful: false,
    payload:[],
    quotes:[],
    response:[],
    messages: [],
    errors: [],
  },
  post:{
    requesting: false,
    successful: false,
    payload:[],
    messages: [],
    errors: [],
  }
}

const reducer = function loginReducer (state = initialState, action) {
  switch (action.type) {
    case 'GET_QUOTE_REQ':
      var retValue = JSON.parse(JSON.stringify({
        ...state,
      }))
      retValue.get.requesting=true
      retValue.get.successful=false
      return retValue
    case 'GET_QUOTE_SUC':
      var retValue = JSON.parse(JSON.stringify({
        ...state,
      }))
      retValue.get.requesting=false
      retValue.get.successful=true
      retValue.get.payload=action.payload
      retValue.get.quotes=action.payload
      retValue.get.response=action.response
      return retValue
    case 'GET_QUOTE_ERR':
      var retValue = JSON.parse(JSON.stringify({
        ...state,
      }))
      retValue.get.requesting=false
      retValue.get.successful=false
      return retValue

    case 'SAVE_QUOTE_REQ':
      var retValue = JSON.parse(JSON.stringify({
        ...state,
      }))
      retValue.post.requesting=true
      retValue.post.successful=false
      return retValue
    case 'SAVE_QUOTE_SUC':
      var retValue = JSON.parse(JSON.stringify({
        ...state,
      }))
      retValue.post.requesting=false
      retValue.post.successful=true
      return retValue
    case 'SAVE_QUOTE_ERR':
      var retValue = JSON.parse(JSON.stringify({
        ...state,
      }))
      retValue.post.requesting=false
      retValue.post.successful=false
      return retValue
    default:
      return state
  }
}

export default reducer
