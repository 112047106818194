import React, { useState } from 'react';
import { compose } from 'recompose';

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col, Grid } from 'react-bootstrap';
import FA from 'react-fontawesome'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { toast } from 'react-toastify';

import Mobile from '../../../HOC/Mobile'

const UserAccountSelector = (props) => {

    const { isMobile, muiTheme, onBack, accounts = [], login, loading = false } = props

    const useStyles = makeStyles((theme) => ({
        container: {
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: isMobile ? "flex-start" : "center",
            backgroundColor: "white"
        },
        modal: {
            display: 'flex',
            padding: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            width: isMobile ? "auto" : 450,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        colTitle: {
            textAlign: "center",
            color: "black",
            fontSize: 16
        },
        selectField: {
            fontSize: '16px'
        },
        menuItem: {
            fontSize: '16px'
        },
    }));
    const classes = useStyles()
    const rootRef = React.useRef(null);

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleChange = (event) => {
        setSelectedAccount(event.target.value)
        let index = accounts.indexOf(event.target.value)
        setSelectedIndex(index)
    };

    const handleSubmit = () => {
        if (selectedAccount && selectedIndex !== null) {
            login(selectedAccount, selectedIndex)
        } else {
            toast.error('Please Select A Carrier')
        }


    };

    const getBody = () => {
        return (
            <div style={{ width: isMobile ? "auto" : 400 }}>
                <Grid style={{ width: isMobile ? "auto" : 400 }}>
                    <Row style={{ width: isMobile ? "auto" : 400, height: 25 }}>
                        {/* <Button
                            style={{
                                backgroundColor: "transparent",
                                color: muiTheme.actionColor,
                                fontSize: 14,
                                textTransform: 'none',
                                display: 'block'
                            }}
                            onClick={() => onBack()}
                        >
                            <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                            Back
                            </Button> */}
                    </Row>
                    <Row style={{ display: "flex", justifyContent: "center", width: isMobile ? "auto" : 400 }}>
                        <img src={require("../../customers/" + muiTheme.customerUniqueId + "/theme/login_logo.png")} height={45} />
                    </Row>
                    <Row style={{ marginTop: 40, width: isMobile ? "auto" : 400, height: 'auto' }}>
                        <Grid style={{ width: "100%", display: "flex", justifyContent: 'center' }}>
                            <Col style={{ alignItems: "center", width: isMobile ? "auto" : 284, height: "100%" }}>
                                <p className={classes.colTitle}>Select Carrier</p>
                                <p style={{ textAlign: "center", fontSize: 14, lineHeight: "16px", marginTop: 5 }}>Select the carrier you want to log in to</p>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: 30, marginBottom: 15 }}>
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel id="select-filled-label" style={{ fontSize: 12 }}>Carrier</InputLabel>
                                        <Select
                                            className={classes.selectField}
                                            labelId="select-filled-label"
                                            id="select-filled"
                                            value={selectedAccount}
                                            onChange={handleChange}
                                            style={{ minWidth: 200 }}
                                        >
                                            {accounts.map((carrier) => {
                                                return (
                                                    <MenuItem
                                                        key={carrier.customerAccountId}
                                                        value={carrier}
                                                        className={classes.menuItem}
                                                    >
                                                        {carrier.companyName}
                                                    </MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", marginTop: 30, marginBottom: 15 }}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        style={{
                                            color: "white",
                                            backgroundColor: muiTheme.actionColor,
                                            borderColor: muiTheme.actionColor,
                                            fontSize: 14,
                                            textTransform: 'none'
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {loading ?
                                            <div>
                                                <FA name='spinner' spin style={{ color: 'white' }} />
                                            </div>
                                            :
                                            "SIGN IN"
                                        }
                                    </Button>
                                </div>
                            </Col>
                        </Grid>
                    </Row>
                    <Row style={{ display: "flex", justifyContent: "center", marginTop: 15, width: isMobile ? "auto" : 400 }}>
                        <Grid style={{ justifyContent: "center", width: isMobile ? "auto" : 400 }}>
                            <Row style={{ justifyContent: "center", width: isMobile ? "auto" : 400 }}>
                                <p style={{ textAlign: "center" }}>Please contact Trucker Tools</p>
                            </Row>
                            <Row style={{ justifyContent: "center", width: isMobile ? "auto" : 400 }}>
                                <a href={'tel:+7039553560'} style={{ color: muiTheme.actionColor, display: 'block', textAlign: "center" }}>703-955-3560</a>
                            </Row>
                        </Grid>
                    </Row>
                </Grid>
            </div>
        )

    }

    return (
        <div className={classes.container}>
            {!isMobile ? (
                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    disableBackdropClick
                    open={true}
                    className={classes.modal}
                    style={{ backgroundColor: "transparent" }}
                    container={() => rootRef.current}
                >
                    <div className={classes.paper}>
                        {getBody()}
                    </div>
                </Modal>
            ) : (
                <div style={{ backgroundColor: "white", overflowX: "hidden", overflowY: "auto" }}>
                    {getBody()}
                </div>
            )}
        </div>
    )
}


export default compose(
    muiThemeable(),
    Mobile()
)(UserAccountSelector)