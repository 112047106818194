import React from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Paper from '@material-ui/core/Paper';

import {
    isAndroid,
    isIOS,
    isSafari,
    isChrome
} from "react-device-detect"

const MobileShortcutsScreen = (props) => {

    const { muiTheme } = props

    const useStyles = makeStyles({
        container: {
            padding: 10,
            height: '100%',
            width: '100%'
        },
        title: {
            textAlign: "left",
            color: "black"
        },
        instruction: {
            textAlign: "left",
            marginTop: 25,
            color: "black"
        },
        textLink: {
            color: muiTheme.actionColor
        }

    });
    const classes = useStyles()

    const androidBody = (
        <div>
            {!isChrome && (
                <div style={{ marginBottom: 15 }}>
                    <p className={classes.title} style={{ fontSize: 16, color: muiTheme.ttRed }}>For Android devices, you must use the <span style={{ fontWeight: "bold" }}>Chrome</span> browser app to create a shortcut to <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a> on your mobile home screen.</p>
                </div>
            )}
            <p className={classes.title}>Follow these simple steps to bookmark EL Atlas Now to your mobile home screen.</p>
            <p className={classes.instruction}>Step 1. Click on the three vertical dots at the top right of your screen</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/mobile-shortcuts/android-step-2.jpeg")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>Step 2. Select “Add to Home Screen” from the menu options</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/mobile-shortcuts/android-step-3.jpeg")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>Step 3. Choose a caption to be displayed beneath the app icon, or you may choose to keep the default text as “England Logistics”</p>
            <p className={classes.instruction}>Step 4. Click on the blue “Add” link</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/mobile-shortcuts/android-step-4.jpeg")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>Step 5. Either hold and drag the icon to your home screen or click the “Add” link once more and the icon will automatically be added to the next available slot on your home screen</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/mobile-shortcuts/android-step-5.jpeg")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>You’re all done! Navigate back to your home screen and you will see a new app icon that will open directly to <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a></p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15, marginBottom: 40 }}>
                    <img src={require("../../assets/mobile-shortcuts/android-step-6.jpeg")} width={"100%"} />
                </Paper>
            </div>
        </div>
    )

    const iosBody = (
        <div>
            {!isSafari && (
                <div style={{ marginBottom: 15 }}>
                    <p className={classes.title} style={{ fontSize: 16, color: muiTheme.ttRed }}>For iPhone devices, you must use the <span style={{ fontWeight: "bold" }}>Safari</span> app in order to create a shortcut to <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a> on your mobile home screen.</p>
                </div>
            )}
            <p className={classes.title}>Follow these simple steps to bookmark EL Atlas Now to your mobile home screen.</p>
            <p className={classes.instruction}>Step 1. Click on the share icon at the bottom of your screen</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/mobile-shortcuts/iOS-step-2.PNG")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>Step 2. Scroll down to menu option “Add to Home Screen” and select it</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/mobile-shortcuts/iOS-step-3.PNG")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>Step 3. Choose a caption to be displayed beneath the app icon, or you may choose to keep the default text as “England Logistics”</p>
            <p className={classes.instruction}>Step 4. Click on the blue “Add” link at the top of your screen</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/mobile-shortcuts/iOS-step-4-5.PNG")} width={"100%"} />
                </Paper>
            </div>
            <p className={classes.instruction}>You’re all done! Navigate back to your home screen and you will see a new app icon that will open directly to <a target="_blank" href="elatlasnow.com" className={classes.textLink}>elatlasnow.com</a></p>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 40 }}>
                <Paper elevation={3} style={{ width: "85%", marginTop: 15 }}>
                    <img src={require("../../assets/mobile-shortcuts/iOS-step-6.PNG")} width={"100%"} />
                </Paper>
            </div>
        </div>
    )

    return (
        <div className={classes.container}>
            <div style={{ height: '100%', overflowY: "scroll" }}>
                {isAndroid ? androidBody : isIOS ? iosBody : null}
            </div>
        </div>
    )
}

export default compose(
    muiThemeable()
)(MobileShortcutsScreen)