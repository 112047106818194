import {useAuthenticationHook} from '../CarrierPortal/hooks/authentication-hook.js';
import { BACKEND_URL } from './constants.js'


export const GetProfile = () => {

  const myAuthService = useAuthenticationHook()

  var requestUrl = BACKEND_URL + '/getProfile';

  let requestObject = {
    method: 'GET'
  }
  let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

  return fetch(requestUrl, newRequestObject)
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });

  /*
  Example Response : {
          "mc": "MC1234567890",   
          “scac” : null,
          "dotNumber": null,
          "companyName": "Pavan Carrier",
          "address": null,
          "city": "Warwick",
          "state": null,
          "zip": null,
          "email": "pavan@truckertools.com",

          "username": "pavan@truckertools.com",
          "firstName": "Pavan",
          "lastName": "Kumar",
          "phoneNumber": "2064508235",
          "fax": null,
          "role" : "admin"
  }
  */
}

export const UpdateProfile = (data) => {
  // console.log('updateProfile');
  // console.log(data);
  const myAuthService = useAuthenticationHook()

  var requestUrl = BACKEND_URL + '/updateProfile';


  var postBody = {
    username: data.username,
    mc: data.mc,
    scac: data.scac,
    dotNumber: data.dotNumber,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    fax: data.fax,
    companyName: data.companyName,
    address: data.address,
    city: data.city,
    state: data.state,
    zip: data.zip,
  };
  let requestObject = {
    method: 'PUT',
    body: JSON.stringify(postBody),
  }
  let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
  return fetch(requestUrl,newRequestObject)
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}
