import {BACKEND_URL} from './constants.js'
import moment from 'moment'
export function getLoadMatches(loadId){
  var requestUrl=BACKEND_URL+'/load/'+loadId+'/matches'
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function getCarrierMatches(startDate,endDate){
  if(!startDate)
    var startDate = moment().subtract(10,'days').format('MM/DD/YYYY')
  if(!endDate)
    var endDate = moment().add(10,'days').format('MM/DD/YYYY')
  var requestUrl=BACKEND_URL+'/carrierMap?start='+startDate+'&end='+endDate
  return fetch(requestUrl,{
                            method: 'GET',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
