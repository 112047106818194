import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';

//COMPONENTS
import { toast } from 'react-toastify';
import isMobile from '../../../../HOC/Mobile.js'
import muiThemeable from 'material-ui/styles/muiThemeable';
import 'react-datepicker/dist/react-datepicker.css'
// import useGtagHook from '../../../gtag-hook';

import FuelSearchMobileWrapper from './FuelSearchMobileWrapper'
import FuelSearchBarDesktop from './FuelSearchBarDesktop';
import {useAuthenticationHook} from '../../../hooks/authentication-hook';

const FuelSearchBar = ({
    isMobileView,
    isMobile,
    muiTheme,
    mobileView = false,
    onSearch,
    setSearchLocation,
    searchLocation,
    ...props
}) => {

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    const handleSearch = () => {
        // console.log("searchLocation: ", searchLocation)
        if (searchLocation == null || searchLocation.lat == null || searchLocation.lat === 0 || searchLocation.lon == null || searchLocation.lon === 0) {
            toast.error("Please enter a valid search location")
            return false;
        }

        // todo: add user client key
        let searchPayload = {
            lat: searchLocation.lat,
            lng: searchLocation.lon,
            advantageId: muiTheme.customerUniqueId,
            state: searchLocation.state,
            zipCode: searchLocation.zipCode,
            city: searchLocation.city,
            country: searchLocation.country,
        }

        if (user && user.carrier && user.carrier.services &&
            user.carrier.services.fuel && user.carrier.services.fuel.hasService &&
            user.carrier.services.fuel.clientKey) {
            searchPayload.clientDiscount = {
                clientKey: user.carrier.services.fuel.clientKey,
                programKey: muiTheme.customerUniqueId
            }
        }

        // console.log("searchPayload: ", searchPayload)
        // todo make api call 
        onSearch(searchPayload)

        // if (mobileView)
        //     setShowLoadSearchPopover(false)

        // useGtagHook(
        //     "find_loads",
        //     {
        //         location: values.city + ", " + values.state,
        //         pickupDate: moment(values.available_at_start).format('MM/DD/YYYY'),
        //         pickupTime: moment(values.available_at_start).format('HH:mm'),
        //         isSaved: createSavedSearch,
        //         isPublished: publishCapacity ? true : false,
        //         destinations: destinations.length === 0 || (destinations.length === 1 && destinations[0].abbr === 'All') ? null : destinations,
        //         equipments: values.truckTypes
        //     }
        // )

    }

    return (
        //<div style={{backgroundColor: 'white'}}>
        <div>
            {!isMobile &&
                <FuelSearchBarDesktop
                    searchLocation={searchLocation}
                    setSearchLocation={setSearchLocation}
                    handleSearch={handleSearch}
                />
            }

            {isMobile &&
                <FuelSearchMobileWrapper
                    searchLocation={searchLocation}
                    setSearchLocation={setSearchLocation}
                    handleSearch={handleSearch}
                />
            }
        </div>

    )
}

export default compose(
    muiThemeable(),
    isMobile(),
)(FuelSearchBar)