import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose, withProps } from 'recompose'
import isMobile from '../../../../HOC/Mobile.js'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { Col, Row} from 'react-bootstrap';
import { checkValueExists } from "../../../../lib/check-value-exists"


export const defaultDataRate = {
    type:'FLAT',
    value:'',
}

const RateSet = (props) => {




    const { muiTheme, isMobile, initialValues=null, onSetCallBack=null } = props

    const useStyles = makeStyles({
        label: {
            fontSize: '16px'
        }
    });

    const classes = useStyles()


    const [rate, setRate] = useState(defaultDataRate.value);
    const [rateType, setRateType] = useState(defaultDataRate.type);
    const [formPayload, setFormPayload] = useState(defaultDataRate);


    useEffect(() => {
        if (initialValues !== null) {
            let value = ''
            if(checkValueExists(initialValues,'value') && initialValues.value !=='' && parseFloat(initialValues.value)>0){
                value = (initialValues.value)
                // if(isNaN(value)){
                //     value = 0
                // }
                //value = value.toString
            }
            setRate(value)
            setRateType(initialValues.type?initialValues.type:defaultDataRate.type)
            setFormPayload(initialValues)
        }
    }, [initialValues])



   

    

    const handleRateTypeSelection = (event) => {
        let value = event.target.value
        setRateType(value);
        popuateFormPayload({type:value})
    };
    const handleRate = (value) => {
        setRate(value);
        popuateFormPayload({value:value})
    };



    const popuateFormPayload = (incomingData)=>{
        let payload = {...formPayload,...incomingData}
        setFormPayload(payload)
        if(onSetCallBack){
            onSetCallBack(payload)
        }
    }


    const UI = (
        <Row  xs={12} md={6} >
            <Col  xs={4} md={6} >
                <TextField
                    id="filled-basic"
                    label="Rate"
                    variant="filled"
                    style={{ marginRight: 15 }}
                    value={rate}
                    onChange={(event) => handleRate(event.target.value)}
                    InputProps={{
                        style: {
                            fontSize: 16
                        }
                    }}
                />
            </Col>
            <Col  xs={8} md={6} style={{height: 50, display: 'flex',justifyContent:'flex-end'}} >
                <RadioGroup xs={12} md={6} row aria-label="uploadType" name="uploadType1" value={rateType} onChange={handleRateTypeSelection}>
                    <FormControlLabel classes={{label: classes.label}} value="FLAT" control={<Radio style={{ color: muiTheme.secondary }} />} label="Flat Rate" style={{ fontSize: 14 }} />
                    <FormControlLabel classes={{label: classes.label}} value="RPM" control={<Radio style={{ color: muiTheme.secondary }} />} label="Per Mile" style={{ fontSize: 14 }} />
                </RadioGroup>
            </Col>
        </Row>
    )

    return UI
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(RateSet)


/*

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

*/