//MESSAGING
  import * as TYPE from './constants'

const initialState = {
  requesting: false,
  initLoading: false,
  requesting: false,
  successful: false,
  formData:{},
  messages: [],
  errors: [],
  importResponse: [],
  truckTypes: [],
  capacityList: [],
}

const reducer = function getCapacityReducer (state = initialState, action) {

    //alert(JSON.stringify(action))
  switch (action.type) {


    case TYPE.CALL_GET_CARRIER_CAPACITY:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Looking up your loads...', time: new Date() }],
        errors: [],
      }


    case TYPE.CALL_GET_CARRIER_CAPACITY_SUCCESS:

      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        capacityList: action.response.availabilities
      }

    // reset the state but with errors!
    // the error payload returned is actually far
    // more detailed, but we'll just stick with
    // the base message for now
    case TYPE.CALL_GET_CARRIER_CAPACITY_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }

    default:
      return state
  }
}



export default reducer
