import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';

import LoadListDesktop from './desktop'
import LoadListMobile from './mobile'

import { LogActionsActivity } from '../../../../apis/index'
import {useAuthenticationHook} from '../../../../hooks/authentication-hook';

const CarrierLoadList = (props) => {

  const {
    unfilterLoadList = [],
    availability,
    setCurrentLoadListCount = null,
    loadFilters,
    pushReloads,
    reloadsOpen = false,
    isMobile = false,
    onReloadsTab = false,
    myLoadsRenderedTab = 0,
    carrierCommunications,
    muiTheme,
    pageNumber,
    setPageNumber,
    itemsPerPage
  } = props
  const [filteredLoadList, setFilteredLoadList] = useState(unfilterLoadList)

  //these refs will halt the two other useEffects from running on mount
  const isMountedOne = useRef(false);
  const isMountedTwo = useRef(false);

  const myAuthService = useAuthenticationHook()
  const isAuthenticated = myAuthService.isAuthenticated()

  useEffect(() => {
    makeActivityLogCall(0)
  }, [])

  useEffect(() => {
    if (isMountedOne.current && unfilterLoadList.length > 0) {
      filterLoads(unfilterLoadList)
      makeActivityLogCall(0)
    } else {
      isMountedOne.current = true;
    }

  }, [unfilterLoadList])

  useEffect(() => {
    if (isMountedTwo.current && unfilterLoadList.length > 0 && loadFilters.lastAction != null) {
      filterLoads(unfilterLoadList)
      makeActivityLogCall(0)
    } else {
      isMountedTwo.current = true;
    }
  }, [loadFilters])


  const filterLoads = (list) => {
    if (list == []) return

    setFilteredLoadList(list)
  }

  const makeActivityLogCall = (index) => {

    if (!isAuthenticated) return

    let loadIds = []
    if (filteredLoadList && filteredLoadList.length > 0) {
      let startingIndex = index
      if (startingIndex < 0) {
        startingIndex = 0
      }

      let load_view_count = 4
      let finalIndex = startingIndex + load_view_count
      if (finalIndex > filteredLoadList.length) {
        finalIndex = filteredLoadList.length
      }

      for (let i = startingIndex; i < finalIndex; i++) {
        let loadObj = {
          matchIndex: i,
          loadId: filteredLoadList[i].loadId,
          brokerId: filteredLoadList[i].brokerId
        }
        loadIds.push(loadObj)
      }
      let params = {
        loadId: null,
        loadIdArr: loadIds,
        brokerId: null,
        actionName: "LOAD_IMPRESSION",
        callingPhoneNumber: null,
        notes: null,
        rate: null,
        matchIndex: 0,
        matchIndex: null,
        screenName: "LOADS",
      }
      LogActionsActivity(params, muiTheme.customerUniqueId)
    }
  }

  const canShowLoad = (load) => {
    let canShowItem = true
    if (loadFilters.showBinOnly && canShowItem && (load.hasBookItNow == null || load.hasBookItNow === 0 || load.targetPay <= 0 || load.inNetwork == 0)) {
      canShowItem = false
    }

    if (loadFilters.deadheadThresholds.length > 0 && load['deadhead'] != null && canShowItem) {
      if (load['deadhead'] < loadFilters.deadheadThresholds[0] || load['deadhead'] > loadFilters.deadheadThresholds[1]) {
        canShowItem = false
      }
    }
    //dead head is camel cased for reloads
    if (loadFilters.deadheadThresholds.length > 0 && load['deadHead'] != null && canShowItem) {
      if (load['deadHead'] < loadFilters.deadheadThresholds[0] || load['deadHead'] > loadFilters.deadheadThresholds[1]) {
        canShowItem = false
      }
    }

    if (loadFilters.reloadsThresholds.length > 0 && load['reloads'] != null && canShowItem) {
      if (load['reloads'] < loadFilters.reloadsThresholds[0] || load['reloads'] > loadFilters.reloadsThresholds[1]) {
        canShowItem = false
      }
    }

    if (loadFilters.weightThresholds.length > 0 && load['weight'] != null && canShowItem) {
      if (load['weight'] < loadFilters.weightThresholds[0] || load['weight'] > loadFilters.weightThresholds[1]) {
        canShowItem = false
      }
    }

    if (loadFilters.filteredOutDestinations.length > 0 && canShowItem) {
      if (loadFilters.filteredOutDestinations.indexOf(load.stops[load.stops.length - 1].state) > -1) {
        canShowItem = false
      }
    }

    if (loadFilters.filteredOutOrigins.length > 0 && canShowItem) {
      if (loadFilters.filteredOutOrigins.indexOf(load.stops[0].state) > -1) {
        canShowItem = false
      }
    }

    if (loadFilters.filteredOutBrokers.length > 0 && canShowItem) {
      if (loadFilters.filteredOutBrokers.indexOf(load.companyName) > -1) {
        canShowItem = false
      }
    }

    if (loadFilters.filteredOutEquipments.length > 0 && canShowItem) {
      if (loadFilters.filteredOutEquipments.indexOf(load.truckTypes) > -1) {
        canShowItem = false
      }
    }

    if (loadFilters.filteredOutRevenueCodes.length > 0 && canShowItem) {
      if (loadFilters.filteredOutRevenueCodes.indexOf(load.revenueCode) > -1) {
        canShowItem = false
      }
    }
    return canShowItem
  }

  const removeLoadFromList = (loadNumber) => {
    let tmpList = filteredLoadList.filter(load => load.loadId !== loadNumber)
    setFilteredLoadList(tmpList)
  }

  const paginationSlice = (completeLoadList, selectedPageNumber) => {
    return completeLoadList.slice((selectedPageNumber - 1) * itemsPerPage, selectedPageNumber * itemsPerPage)
  }

  return (
    <div style={{height: '100%'}}>
      {
        isMobile ?
          <LoadListMobile
            {...props}
            filteredLoadList={paginationSlice(filteredLoadList, pageNumber)}
            key={'load_list_mobile'}
            availability={availability}
            onReloadsTab={onReloadsTab}
            myLoadsRenderedTab={myLoadsRenderedTab}
            carrierCommunications={carrierCommunications}
          />
          :
          <LoadListDesktop
            {...props}
            filteredLoadList={paginationSlice(filteredLoadList, pageNumber)}
            key={'load_list_desktop'}
            availability={availability}
            pushReloads={pushReloads}
            reloadsOpen={reloadsOpen}
            myLoadsRenderedTab={myLoadsRenderedTab}
            removeLoadFromList={removeLoadFromList}
            carrierCommunications={carrierCommunications}
          />
      }
    </div>
  )

}

const mapStateToProps = state => ({
  loadFilters: state.CarrierPortal.loadFilters
})

export default compose(
  connect(mapStateToProps),
  muiThemeable()
)(CarrierLoadList)