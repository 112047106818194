
import { BACKEND_URL } from "../../../api/constants"
// import {useAuthenticationHook} from "../../hooks/authentication-hook"

export function EnglandServices() {
    // const myAuthService = useAuthenticationHook()

    return {
        async submitProductApplicationForm(payload) {
            let promise = new Promise((resolve, reject) => {
                try {
                    const PRODUCT_FORM_URL = "/api/carrier/serviceRequestToBroker"
                    let url = BACKEND_URL + PRODUCT_FORM_URL
                    let requestObject = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body:JSON.stringify(payload)
                    }
                    fetch(url, requestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("PRODUCT APPLICATION: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("PRODUCT APPLICATION ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("PRODUCT APPLICATION E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        }
    }
}