import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import Button from '@material-ui/core/Button';
import { Image } from "react-bootstrap"
import Popover from '@material-ui/core/Popover';
import FA from 'react-fontawesome'


const BookedActionButton = (props) => {

    const { load, isMobile = false } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [showFrame, setShowFrame] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        try {
            fetch(load.ltStatusLink)
                .then(function (response) {
                    return response.text()
                        .then(function (text) {
                            let imgHtml = text;
                            let imgHtmlSubstring = imgHtml.substring(imgHtml.indexOf('img src="') + 9);
                            let imgLink = 'http://www.truckertools.com' + imgHtmlSubstring.substring(0, imgHtmlSubstring.indexOf('"'));
                            setImageUrl(imgLink)
                            setIsLoading(false)
                        });
                }
                )
        } catch (e) {
            setIsLoading(false)
        }
    }, [])

    const handleClose = () => {
        setAnchorEl(null);
        setShowFrame(false)
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            {isLoading && (
                <span><FA name='spinner' spin /></span>
            )}
            {!isLoading && (imageUrl != null) && (
                <div>
                    <Image
                        src={imageUrl}
                        responsive 
                        style={{ margin: 'auto', cursor: 'pointer' }}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget)
                            setShowFrame(!showFrame)
                        }}
                    />
                    {/* <div style={{ width: 120, height: 50 }}>
                        <iframe
                            src={load.ltStatusLink}
                            // width={120}
                            // height={50}
                            width="100%"
                            height="100%"
                        />
                        <a
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                display: "inline-block",
                                // width: 120,
                                // height: 50,
                                width: "100%",
                                height: "100%",
                                zIndex: 5
                            }}
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget)
                                setShowFrame(!showFrame)
                            }}
                        ></a>
                    </div> */}
                    {showFrame && (
                        <Popover
                            id={"lt-popover"}
                            open={open}
                            anchorEl={anchorEl}
                            PaperProps={{ style: { overflow: 'unset', width: isMobile ? '100%' : 'unset', height: isMobile ? '100%' : 'unset' } }}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setShowFrame(false)
                                }}
                                style={{
                                    float: 'right',
                                    zIndex: 10,
                                    minWidth: 'auto',
                                    padding: '6px 25px'
                                }}
                                disableRipple={true}
                            >

                                <FA name="times" size='2x' />
                            </Button>
                            <div style={{ padding: isMobile ? 0 : 15, width: isMobile ? '100%' : 1200, height: isMobile ? '100%' : 600 }}>
                                <iframe
                                    src={load.ltLink}
                                    width="100%"
                                    height="100%"
                                    id="lt-modal"
                                    allowFullScreen
                                />
                            </div>
                        </Popover>
                    )}
                </div>
            )}
        </div>
    )
}


export default compose(
    muiThemeable(),
)(BookedActionButton)