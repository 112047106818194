import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import * as Constants from './constants'
import {getShipperLoads } from '../../api/shipperIntegration'

// This will be run when the LOADS_REQUESTING
// Action is found by the watcher
function* loadsFlow (action) {
  try {
    yield put(showLoading())
    const { client_id, shipper_identifier, pageNumber, pageSize } = action 
    // here until the API async function, is complete!
    const response = yield call(getShipperLoads, client_id, shipper_identifier, pageNumber, pageSize);
    const { shipperLoads } = response;
    const { data, total } = shipperLoads || {};

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    yield put({ type: Constants.LOADS_SUCCESS, loads: data || [], total: total || 0  })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    //TODO

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the self service action types
// WITH the action we dispatched
function* selfServiceLoadWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(Constants.LOADS_REQUESTING, loadsFlow);  
}

export default selfServiceLoadWatcher