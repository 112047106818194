import S3 from 'aws-sdk/clients/s3';
import { PostRemoteLog } from './api-remote-log'

import { checkValueExists } from "../../lib/check-value-exists"

const ACCESS_KEY_ID = 'AKIAIUP7SITAX6EQKGBQ';
const SECRET_ACCESS_KEY = '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';
const REGION = 'us-east-1';
const BUCKET_NAME = 'assets.truckertools.com';
const s3bucket = () => {
	const s3Client = new S3({
		accessKeyId: ACCESS_KEY_ID,
		secretAccessKey: SECRET_ACCESS_KEY,
		region: REGION,
	});
	return s3Client;
}

export class AWSApi {

	constructor() { }

	async uploadS3(fileParams, callback) {
		let file = null
		let contentType = 'image/jpeg'
		let subDirectory = 'images/'
		let fileNamePrefix = ''
		if (fileParams && checkValueExists(fileParams, "file")) {
			file = fileParams.file
		}
		if (fileParams && checkValueExists(fileParams, "contentType")) {
			contentType = fileParams.contentType
		}
		if (fileParams && checkValueExists(fileParams, "subDirectory")) {
			subDirectory = fileParams.subDirectory
		}
		if (fileParams && checkValueExists(fileParams, "fileNamePrefix")) {
			fileNamePrefix = fileParams.fileNamePrefix
		}
		if (!file) {
			callback('')
		} else {
			try {

				const base64Data = new Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), 'base64');

				const params = {
					Bucket: BUCKET_NAME,
					Key: subDirectory + fileNamePrefix,
					Body: base64Data,
					ContentEncoding: 'base64',
					ContentType: contentType,
				};
				let s3Client = s3bucket();
				s3Client.upload(params, (err, data) => {
						if (err) {
							const payload = {
								source: "aws_s3_doc_upload",
								message: err.toString(),
								data: JSON.stringify(fileParams)
							}
							PostRemoteLog(payload).then(response => {
								// console.log("REMOTE LOG RESPONSE: ", response)
							}).catch((error) => {
							})
							callback('')
						} else {
							let s3Path = data.Location
							callback(s3Path)
						}
					});
			}
			catch (err) {
				const payload = {
					source: "aws_s3_doc_upload",
					message: err.toString(),
					data: JSON.stringify(fileParams)
				}
				PostRemoteLog(payload).then(response => {
					// console.log("REMOTE LOG RESPONSE: ", response)
				}).catch((error) => {
				})
				callback('')
			}
		}
	}

}