import React, {useState, useEffect} from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ReloadsTab from './reloadsTab'
import DetailsTab from './detailsTab'
import QuotesTab from './quotesTab'
import {useAuthenticationHook} from '../../../../../hooks/authentication-hook';

const LoadDetailsTabs = (props) => {
    const {
        muiTheme,
        load,
        pickup,
        delivery,
        availability,
        googleMapsDistance,
        googleMapsDuration,
        isMyLoadsScreen = false,
        myLoadsRenderedTab = 0,
        detailsRenderedTab = 0,
        setDetailsRenderedTab,
        latestQuote = null,
        latestQuoteShowBIN = false,
        callRefreshCard,
        onInvoicesScreen,
        onPreviewScreen
    } = props

    const useStyles = makeStyles(() => ({
        root: {
            height: '100%'
        }
    }));

    const classes = useStyles();
    const [renderedTab, setRenderedTab] = React.useState(detailsRenderedTab);
    const [mapHidden, setMapHidden] = React.useState(false);
    const [quotePage, setQuotePage] = useState("history")

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const user = myAuthService.getUser()

    useEffect(()=>{
        setRenderedTab(detailsRenderedTab)
    },[detailsRenderedTab])

    const handleTabChange = (event, newValue) => {
        setRenderedTab(newValue);
        setDetailsRenderedTab(newValue)
    };

    const TabPanel = (props) => {
        const { children, value, index } = props;

        let isMapHidden = false
        if( !isMyLoadsScreen || ( isMyLoadsScreen && myLoadsRenderedTab === 0))
        {
            setMapHidden(value === 0 ? false : true)
            isMapHidden = value === 0 ? false : true
        }
        else 
        {
            setMapHidden(true)
            isMapHidden = true
        }

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={'details-tabpanel-${index}'}
                style={{ height: isMapHidden ? 'calc(100% - 100px)' : '53%', overflow: 'auto', backgroundColor: muiTheme.ttSecondary }}
            >
                {value === index && (
                    <Box key={"panel_box"} p={3} style={{ padding: 0, marginBottom: index === 0 ? 45 : index === 2 && quotePage === "form" ? 85 : null, height: '100%' }}>
                        <div style={{height: '100%'}}>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={renderedTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    style={{ backgroundColor: muiTheme.primary, paddingTop: mapHidden ? onPreviewScreen ? 15 : 45 : 'unset' }}
                    TabIndicatorProps={{ style: { background: muiTheme.secondary } }}
                >
                    <Tab label="Details" style={{ fontSize: 12 }} />
                    {myLoadsRenderedTab!==2 &&  <Tab label="Reloads" style={{ fontSize: 12, display: onPreviewScreen ? 'none' : 'unset' }} />}
                    {isAuthenticated && !isMyLoadsScreen &&
                    (!user || (user && user.role!==""  && user.role.toLowerCase() !== "finance" && user.role.toLowerCase() !== "loadviewer")) &&
                    <Tab label="Quotes" style={{ fontSize: 12 }} />}

                    {/* <Tab label="Market" style={{fontSize: 12}}/> */}
                </Tabs>
            </AppBar>
            <TabPanel value={renderedTab} index={0}>
                <DetailsTab 
                    load={load} 
                    pickup={pickup} 
                    delivery={delivery} 
                    availability={availability} 
                    googleMapsDistance={googleMapsDistance}
                    googleMapsDuration={googleMapsDuration}
                    handleTabChange={handleTabChange}
                    latestQuote={latestQuote}
                    latestQuoteShowBIN={latestQuoteShowBIN}
                    callRefreshCard={callRefreshCard}
                    isMyLoadsScreen={isMyLoadsScreen}
                    myLoadsRenderedTab={myLoadsRenderedTab}
                    onInvoicesScreen={onInvoicesScreen}
                />
                
            </TabPanel>
            <TabPanel value={renderedTab} index={1}>
                <ReloadsTab 
                    load={load} 
                    pickup={pickup} 
                    delivery={delivery} 
                />
            </TabPanel>
            <TabPanel value={renderedTab} index={2}>
                <QuotesTab 
                    quotePage={quotePage}
                    setQuotePage={setQuotePage}
                    callRefreshCard={callRefreshCard}
                    {...props}
                />
            </TabPanel>

        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
)(LoadDetailsTabs)
