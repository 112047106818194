import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import LoadListItemDesktop from './LoadListItemDesktop'
import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import HistoricalLoadCardHeader from './HistoricalLoadCardHeader'
import ActiveLoadCardHeader from './ActiveLoadCardHeader'
import muiThemeable from 'material-ui/styles/muiThemeable';
// import {useAuthenticationHook} from '../../../hooks/authentication-hook';
import { WernerServices } from '../../../../../customers/a98db973/services';

const LoadListDesktop = (props) => {

  const {
    filteredLoadList = [],
    availability,
    pushReloads,
    myLoadsRenderedTab = 0,
    reloadsOpen = false,
    removeLoadFromList,
  } = props

  const { height, width } = useWindowDimensions();
  const MY_LOADS_SCREEN_HEADER_HEIGHT = 215
  const [pixelsFromTop, setPixelsFromTop] = useState(MY_LOADS_SCREEN_HEADER_HEIGHT)

  const [carrierCommunications, setCarrierCommunications] = useState(null)

  const [isScrollBarPresent, setIsScrollBarPresent] = useState(false);

  const listContainer = useRef(null)

  useEffect(() => {
    if (myLoadsRenderedTab == 0) {
      const apiServices = new WernerServices()
      apiServices.getCommunications()
        .then(response => {
          // console.log("response: ", response)
          if (response) {
            setCarrierCommunications(response)
          }
          // setIsLoading(false)
        })
        .catch(error => {
          // console.log("ERROR 2: ", error)
          // let errorMessage = "Error: There was a problem fetching your booked loads."
          // setIsLoading(false)
          // toast.error(errorMessage)
        })
    }
  }, [])

  useEffect(()=>{
    setTimeout(() => {
      if(listContainer !== null && listContainer.current !== null){
        if(listContainer.current.clientHeight < listContainer.current.scrollHeight){
          setIsScrollBarPresent(true);
        } 
      }
    }, 1000);     
  },[filteredLoadList])
  
  useEffect(() => {
    let heightVal = MY_LOADS_SCREEN_HEADER_HEIGHT
    setPixelsFromTop(heightVal)
  }, [reloadsOpen])
  return (
    <div style={{height: '95%'}}>
      <div style={{height: '100%'}}> 
        <Card style={{ height: 40, backgroundColor: 'transparent', boxShadow: 'transparent', paddingLeft: 15, paddingRight: 10}}>
          <CardContent style={{ paddingBottom: 5, borderBottom: '1px solid lightgrey', marginRight: isScrollBarPresent ? '25px' : '0px' }}>
            {myLoadsRenderedTab == 0 ?
              <ActiveLoadCardHeader />
              :
              <HistoricalLoadCardHeader />
            }
          </CardContent>
        </Card>
        <List ref={listContainer} style={{ height: 'calc(100% - 40px)', maxHeight: '100%', overflowX: 'hidden', overflowY: 'auto', paddingTop: 0, position: 'auto' }}>
          {filteredLoadList.length > 0 ?
            filteredLoadList
              .map((load, index) => {
                return (
                  <LoadListItemDesktop
                    {...props}
                    key={load.id + "_" + index}
                    load={load}
                    availability={availability}
                    index={index}
                    pushReloads={pushReloads}
                    myLoadsRenderedTab={myLoadsRenderedTab}
                    reloadsOpen={reloadsOpen}
                    removeLoadFromList={removeLoadFromList}
                    carrierCommunications={carrierCommunications}
                  />
                )
            })
            :
            <div style={{ paddingTop: 25 }}>
              <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                No Loads Found
                </p>
            </div>
          }
        </List>
      </div>
    </div>
  )

}

export default compose(
  connect(),
  muiThemeable()
)(LoadListDesktop)

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
