import React from 'react';
import {bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import {compose} from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import './style.css'
//HOC//
import Mobile from '../HOC/Mobile'

//ACTIONS//




const COMPONENT_NAME = ({
  //PROPS FROM PARENT//
  
  //REDUX//

  //STATE

  //OTHER
  muiTheme,
  isMobile
}) => {
    return (
      <div className={isMobile?'LoadingOverlay_main_mobile':'LoadingOverlay_main'}>
        Loading...
        <br/>
        <i className="fa fa-spinner fa-5x fa-fw fa-spin" aria-hidden="true"></i>
      </div>
    )
}

COMPONENT_NAME.propTypes={
  //label:PropTypes.string.isRequired
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch){
  return  bindActionCreators({
  },dispatch)
}

export default compose(
  connect(mapStateToProps,matchDispatchToProps),
  muiThemeable(),
  Mobile(),
  //withState('showSearchbox','toggleSearchbox',true)
)(COMPONENT_NAME)
