import React, { useState } from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import * as SignupApi from '../../../../apis/api-carrier-advantage-signup'

const ExistingCarrierForm = (props) => {

    const classes = useStyles()
    const { muiTheme } = props

    const [mcOrDot, setMcOrDot] = useState(null)
    const [radioSelected, setRadioSelected] = useState("MC")
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [nameInvalid, setNameInvalid] = useState(false)
    const [mcOrDotInvalid, setMcOrDotInvalid] = useState(false)


    const handleSubmit = () => {
        let valid = validateForm()
        if(!valid){
            return
        }
        let params = {
            advantageId: muiTheme.customerUniqueId,
            email: email,
            fullName: fullName,
            mc: radioSelected === "MC" ? "MC"+mcOrDot : null, //mc number has "MC" prepended to it
            dotNumber: radioSelected === "DOT" ? mcOrDot : null
        }
        SignupApi.signup(params).then(response => {
            if (response.status) {

                // if(response.message){
                //     toast.success(response.message)
                // } else {
                toast.success("Thank you for signing up to create an account for Werner Carrier’s Edge. You will be receiving a link to create an account through OKTA at the email address you used to sign up.", 
                {autoClose: 10000}
                )
                //}
                
            } else {
                toast.error(
                    response.message ? response.message : "Please use \"Enroll with Werner\" button to complete enrollment. If you believe you already enrolled with Werner and you received this error by mistake, please contact support@truckertools.com",
                    {autoClose: 10000}
                )
            }
        }).catch((error) => {
            toast.error("There was a problem creating your account. Please contact support@truckertools.com")
        })
    }

    const validateForm = () => {
        let valid = true
        valid = validateEmail()
        valid = validateName()
        valid = validMcDot()

        return valid
    }

    const validateEmail = () => {
        if(email === "" || email == null){
            toast.error("Email is required")
            setEmailInvalid(true)
            return false
        }
        //validates standard email format plus ones containing more than one period
        let emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = emailRegEx.test(email)
        if(!valid){
            toast.error("Please enter a valid email")
            setEmailInvalid(true)
        }
        return valid
    }

    const validateName = () => {
        let valid = false
        if(fullName === "" || fullName == null){
            toast.error("Full Name is required")
            setNameInvalid(true)
            return false
        }
        //dont allow special chars and also allow unicode letters
        let nameRegEx = /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
        valid = nameRegEx.test(fullName)
        if(!valid){
            toast.error("Please enter a valid name.")
            setNameInvalid(true)
        }
        return valid
    }

    const validMcDot = () => {
        let valid = false

        if(mcOrDot === "" || mcOrDot == null){
            toast.error("MC/DOT is required")
            setMcOrDotInvalid(true)
            return false
        }

        //only allow numeric values
        let numbersRegEx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
        valid = numbersRegEx.test(mcOrDot)
        if(!valid){
            toast.error("Only numeric values are allowed for MC/DOT")
            setMcOrDotInvalid(true)
        }
        return valid
    }

    

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <RadioGroup row aria-label="mc_dot_select" name="mc_dot_select" defaultValue="MC" onChange={(event) => setRadioSelected(event.target.value)} style={{justifyContent: 'space-evenly'}}>
                <FormControlLabel
                    label={<Typography className={classes.formControlLabel}>MC</Typography>}
                    value="MC"
                    control={<Radio color="primary" />}
                    labelPlacement="right"
                />
                <FormControlLabel
                    label={<Typography className={classes.formControlLabel}>DOT</Typography>}
                    value="DOT"
                    control={<Radio color="primary" />}
                    labelPlacement="right"                    
                />
            </RadioGroup>
            <div className={classes.textFieldDivWrapper}>
                <TextField 
                    id="dot-mc-input" 
                    label="DOT or MC Number" 
                    variant="filled" 
                    InputProps={{
                        classes: {
                            input: classes.resize,
                        },
                    }}
                    InputLabelProps={{style: {fontSize: 14}}}
                    style={{ width: 270, fontSize: 14 }} 
                    onChange={(event) => {setMcOrDot(event.target.value);setMcOrDotInvalid(false)} }
                    error={mcOrDotInvalid}
                />
            </div>
            <div className={classes.textFieldDivWrapper}>
                <TextField 
                    id="full-name-input" 
                    label="Full Name" 
                    variant="filled" 
                    InputProps={{
                        classes: {
                            input: classes.resize,
                            inputHiddenLabel: classes.resize,
                            root: classes.resize
                        },
                    }}
                    InputLabelProps={{style: {fontSize: 14}}}
                    style={{ width: 270 }} 
                    onChange={(event) => {setFullName(event.target.value); setNameInvalid(false)}}
                    error={nameInvalid}
                />
            </div>
            <div className={classes.textFieldDivWrapper}>
                <TextField 
                    id="email-input" 
                    label="Email Address" 
                    variant="filled" 
                    InputProps={{
                        classes: {
                            input: classes.resize,
                        },
                    }}
                    InputLabelProps={{style: {fontSize: 14}}}
                    style={{ width: 270 }} 
                    onChange={(event) => {setEmail(event.target.value); setEmailInvalid(false)}} 
                    placeholder="example@test.com"
                    error={emailInvalid}
                />
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Button
                    variant="contained"
                    size="small"
                    style={{
                        color: "white",
                        backgroundColor: muiTheme.actionColor,
                        borderColor: muiTheme.actionColor,
                        fontSize: 14,
                        textTransform: 'none'
                    }}
                    onClick={handleSubmit}
                >ADD</Button>
            </div>
        </form>
    )
}

export default compose(
    muiThemeable()
)(ExistingCarrierForm)

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    resize:{
        fontSize:14
    },
    formControlLabel: { 
        fontSize: 14, 
        '& label': { fontSize: 14 } 
    },
    textFieldDivWrapper:{
        display:'flex',
        justifyContent:'center'
    }
}));