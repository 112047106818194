import { PostRemoteLog } from './api-remote-log'
import { POI_PROD_URL, API_PROD_URL } from '../../api/constants'

const SEARCH_URL = "/poi/tiresales/search"
const FIELDS_URL = "/poi/tiresales/fields"

export async function getSearchFields() {

    const url = API_PROD_URL + FIELDS_URL

    let promise = new Promise((resolve, reject) => {
        try {
            let requestObject = {
                method: 'GET',
                headers: {
                    'x-api-key': `${process.env.REACT_APP_POI_API_KEY}`
                }
            }

            // console.log("getSearchFields: ", url, requestObject)
            return fetch(url, requestObject)
                .then(res => {
                    if (!res.ok) {
                        const error = new Error(res.statusText);
                        error.res = res;
                        throw error;
                    }
                    res.json().then(jsonData => {
                        // console.log("fields jsonData: ", jsonData)
                        // const data = [];
                        // if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
                        //     data.push(...jsonData);
                        // }
                        // resolve(data.filter(item => item.metadata.distance < 100))
                        resolve(jsonData)
                    })
                })
                .catch(error => {
                    reject(error)
                    let payload = {
                        source: "URL: " + FIELDS_URL,
                        message: {
                            error: JSON.stringify(error)
                        },
                        // data: JSON.stringify(dynamicParams)
                    }
                    PostRemoteLog(payload).then(response => {
                        // console.log("REMOTE LOG RESPONSE: ", response)
                    }).catch((error) => {
                    })
                })
        } catch (error) {
            reject(error)
            let payload = {
                source: "URL: " + FIELDS_URL,
                message: {
                    error: JSON.stringify(error)
                },
                // data: JSON.stringify(dynamicParams)
            }
            PostRemoteLog(payload).then(response => {
                // console.log("REMOTE LOG RESPONSE: ", response)
            }).catch((error) => {
            })
        }
    })

    let returnPromise = await promise
    return returnPromise
}

export async function getTires(dynamicParams) {

    const url = API_PROD_URL + SEARCH_URL

    let promise = new Promise((resolve, reject) => {
        try {
            let requestObject = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${process.env.REACT_APP_POI_API_KEY}`
                },
                body: JSON.stringify(dynamicParams)
            }

            // console.log("getTires: ", requestObject)
            return fetch(url, requestObject)
                .then(res => {
                    if (!res.ok) {
                        const error = new Error(res.statusText);
                        error.res = res;
                        throw error;
                    }
                    res.json().then(jsonData => {
                        // console.log("search jsonData: ", jsonData)
                        // const data = [];
                        // if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
                        //     data.push(...jsonData);
                        // }
                        // resolve(data.filter(item => item.metadata.distance < 100))
                        resolve(jsonData)
                    })
                })
                .catch(error => {
                    reject(error)
                    let payload = {
                        source: "URL: " + SEARCH_URL,
                        message: {
                            error: JSON.stringify(error)
                        },
                        data: JSON.stringify(dynamicParams)
                    }
                    PostRemoteLog(payload).then(response => {
                        // console.log("REMOTE LOG RESPONSE: ", response)
                    }).catch((error) => {
                    })
                })
        } catch (error) {
            reject(error)
            let payload = {
                source: "URL: " + SEARCH_URL,
                message: {
                    error: JSON.stringify(error)
                },
                data: JSON.stringify(dynamicParams)
            }
            PostRemoteLog(payload).then(response => {
                // console.log("REMOTE LOG RESPONSE: ", response)
            }).catch((error) => {
            })
        }
    })

    let returnPromise = await promise
    return returnPromise
}