import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../HOC/Mobile.js'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

import InvoiceForm from './invoice-form'

const InvoiceModal = (props) => {

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const {
        load,
        refreshInvoices,
        openModal,
        handleCloseModal,
        currentStatus,
        statusFormatted,
        statusMessage,
        allowEdit,
        allowSubmit,
        muiTheme
    } = props

    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);


    const confirmClose = () => {
        if (allowEdit)
            setShowCloseConfirmation(true)
        else
            handleCloseModal()
    }

    const confirmationDialog = (

        <div style={{ width: '100%', display: 'inline-flex' }}>
            <Col style={{ width: '65%' }}>
                <p style={{ fontSize: 16, fontWeight: 'bold', paddingTop: 7 }}>Are you sure you want to continue? All unsaved changes will be lost.</p>
            </Col>
            <div style={{ width: '35%', textAlign: 'center' }}>
                {/* <Button
                    aria-describedby={"invoice_save"}
                    variant={"text"}
                    onClick={() => setShowCloseConfirmation(false)}
                    style={{
                        fontSize: 14,
                        color: muiTheme.actionColor,
                        backgroundColor: "white",
                        borderColor: muiTheme.actionColor,
                        border: '1px solid',
                        margin: 5
                    }}
                    size={"medium"}
                    disableRipple={false}
                >

                    Cancel
                </Button> */}
                <Button
                    aria-describedby={"invoice_submit"}
                    variant={"text"}
                    onClick={() => handleCloseModal()}
                    style={{
                        fontSize: 14,
                        color: "white",
                        backgroundColor: muiTheme.actionColor,
                        borderColor: muiTheme.actionColor,
                        margin: 5,
                        float: 'right'
                    }}
                    size={"medium"}
                    disableRipple={false}
                >

                    Close
                </Button>
            </div>
        </div>
    )

    const header = (
        <div>
            <Row style={{ width: '100%', backgroundColor: muiTheme.primary, margin: 0, padding: 10, display: 'flex', flexDirection: 'column' }}>
                <Row style={{ width: '100%', textAlign: 'left', display: 'inline-flex' }}>



                    <Col style={{ whiteSpace: 'nowrap', width: '100%' }}>
                        {load.invoice && load.invoice.invoice_number &&
                            <p className={classes.mainText} style={{ fontSize: 18 }}>
                                Invoice #: {load.invoice.invoice_number}
                            </p>
                        }
                    </Col>

                    <Col style={{ width: "4%", textAlign: 'right' }}>
                        <span onClick={() => confirmClose()}><FA id={'close-modal-icon'} name='times' style={{ fontSize: 20, cursor: 'pointer', color: 'white' }} /></span>
                    </Col>
                </Row>

                <Row style={{ width: '100%', textAlign: 'right', display: 'inline-flex' }}>
                    <Col style={{ display: 'inline-grid', width: '50%', textAlign: 'left', paddingTop: 15 }}>
                        <p className={classes.mainText} style={{ fontSize: 18, fontWeight: 'bold' }}>
                            Status: {statusFormatted}
                        </p>
                        {statusMessage &&
                            <p className={classes.secondaryText}>
                                Comments: {statusMessage}
                            </p>
                        }
                    </Col>
                    <Col style={{ width: "50%" }}>
                        <Row style={{ width: '100%', textAlign: 'right', marginTop: 15 }}>
                            <Col style={{ whiteSpace: 'nowrap' }}>
                                {load.loadHash ?
                                    <p className={classes.mainText} style={{ fontSize: 18 }}>
                                        Load #: {load.loadHash}
                                    </p>
                                    :
                                    <p className={classes.mainText} style={{ fontSize: 18 }}>
                                        Load #: {load.invoice.load_number ? load.invoice.load_number : "Unknown"}
                                    </p>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Row>
            <Collapse in={showCloseConfirmation}>
                <Alert
                    severity="warning"
                    classes={{
                        message: classes.alertMsg,
                        icon: classes.alertIcon,
                        action: classes.alertAction
                    }}
                    // classes={{ message: { width: '100%' } }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="medium"
                            onClick={() => {
                                setShowCloseConfirmation(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {confirmationDialog}
                </Alert>
            </Collapse>
        </div>
    );




    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="invoice-modal"
            aria-describedby="simple-modal-description"
            disableBackdropClick
            style={{ backdropFilter: 'blur(5px)' }}
        >

            <div style={modalStyle} className={classes.paper}>
                {header}
                <InvoiceForm
                    load={load}
                    handleCloseModal={handleCloseModal}
                    allowEdit={allowEdit}
                    allowSubmit={allowSubmit}
                    currentStatus={currentStatus}
                    refreshInvoices={props.refreshInvoices}
                />
            </div>

        </Modal>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(InvoiceModal)

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '70%',
        maxWidth: 950,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5]
    },
    mainText: {
        fontSize: '15px',
        lineHeight: '22px',
        color: 'white',
        // fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'white'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    alertMsg: {
        width: '100%',
        padding: 0
    },
    alertIcon: {
        fontSize: 21
    },
    alertAction: {
        padding: 0
    }
}));