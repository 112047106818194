import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux';

import muiThemeable from 'material-ui/styles/muiThemeable';

import List from '@material-ui/core/List';
import LanePreferencesItem from './lane-preferences-card/LanePreferencesItem';
import LanePreferencesListHeader from './lane-preferences-list-header';

import { getNearbyLoads } from '../../../../actions';
import { updateLoadSearchBar } from '../../../../../CarrierPortal/ActionReducers/components';
import isMobile from '../../../../../HOC/Mobile.js'


const LanePreferencesList = (props) => {

  const {
    lanePreferencesList,
    muiTheme,
    updatePreferencesData,
    isMobile,
    isLoading=false
  } = props

  return (
    <div style={{ height: '100%' }}>
      {lanePreferencesList.length > 0 && !isMobile && (
        <div style={{ height: '90px', marginBottom: 10, paddingLeft: 15, paddingRight: 30 }}>
          <p style={{ color: 'black', fontSize: 26, fontWeight: 'bold', whiteSpace: 'nowrap' }}>{lanePreferencesList.length} Preferences</p>
            <LanePreferencesListHeader />
        </div>
      )}
      <List style={{ height: !isMobile ? 'calc(98% - 90px)' : '100%', maxHeight: !isMobile ? 'calc(98% - 90px)' : '100%', overflow: 'auto', paddingTop: 0, paddingBottom: isMobile ? 50 : 'unset' }}>

        {lanePreferencesList.length > 0 ?
          lanePreferencesList.map((item, index) => {

            return (
              <LanePreferencesItem
                item={item}
                key={index + "_lane_pref"}
                itemIndex={index}
                updatePreferencesData={updatePreferencesData}
                handleViewLoads={props.handleViewLoads}
              />
            )

          })
          :
          <div style={{ paddingTop: 55 }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
            {isLoading ?<h3></h3> : <h3>There are no lane preferences saved.</h3>}
            </div>
          </div>
        }
      </List>
    </div>
  )

}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    getNearbyLoads: getNearbyLoads,
    updateLoadSearchBar: updateLoadSearchBar,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  muiThemeable(),
  isMobile()
)(LanePreferencesList)