import { setClient, unsetClient } from '../client/actions'
import {logout} from '../login/actions'
import {store} from '../index'



export const isAuthenticated = () => {
  // attempt to grab the token from localstorage
  const storedToken = localStorage.getItem('token')

  // if it exists
  if (storedToken) {
    // parse it down into an object
    const token = JSON.parse(storedToken)

    // this just all works to compare the total seconds of the created
    // time of the token vs the ttl (time to live) seconds
    //const createdDate = new Date(token.created)
    //const created = Math.round(createdDate.getTime() / 1000)
    //const ttl = parseInt(token.ttl)
    const ttl = (token.ttl)

    //const expiry = created + ttl
    const expiry = parseInt(token.createdTimestampSec) + parseInt(ttl)*60;

    const now = Math.round(new Date().getTime() / 1000)



    //console.log("ttl->"+ttl);
    //console.log("created->"+created);
    //console.log("now    ->"+now);

    //console.log("expiry ->"+expiry);

    // if the token has expired return false
    if (now > expiry) return false

    // otherwise, dispatch the token to our setClient action
    // which will update our redux state with the token and return true
    token.created = new Date();
    token.createdTimestampSec = now;
    store.dispatch(setClient(token))
    localStorage.setItem('token', JSON.stringify(token));

    return true
  }

  return false
}

export const signout = (callback) => {

  store.dispatch(unsetClient());

  localStorage.removeItem('token');

  store.dispatch(logout());

  try{
    setTimeout(callback, 10);
  }catch(e){
    console.log(e);
  }

}
