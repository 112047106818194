import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row, Grid } from "react-bootstrap"
import CardContent from '@material-ui/core/CardContent';
import { Card } from 'material-ui/Card';
import { checkValueExists } from "../../../../../../../lib/check-value-exists"
import muiThemeable from 'material-ui/styles/muiThemeable';
import moment from 'moment'
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import {useAuthenticationHook} from '../../../../../../hooks/authentication-hook';
import { toast } from 'react-toastify'
import Weather from '../../weather';
import Restaurants from '../../restaurants';

const useStyles = makeStyles({
    card: {
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    },
    mainText: {
        fontSize: '14px',
        lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold',
        wordBreak: 'break-word'
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '21px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '16px',
        color: 'grey'
    },
    valueText: {
        fontSize: '16px',
        color: 'black'
    }
});

const FuelStopsTab = (props) => {

    const {
        item,
        muiTheme,
    } = props

    const classes = useStyles();
    const TT_LOGO_ASSET_URL = 'http://assets.truckertools.com/images/logos/'

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()

    const formatPhoneNumberWithHyphens = (phone) => {
        var s2 = ("" + phone).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
    }

    const getImgSrc = () => {
        switch (item.franchise_name) {
            case "TA":
                return require("../../../../fuel-stop-icons/ta-map-pin.svg") 
            case "Loves":
                return require("../../../../fuel-stop-icons/loves-map-pin.svg") 
            default:
                return require("../../../../fuel-stop-icons/default-map-pin.svg") 
        }
    }

    return (
        <div style={{ padding: 10, height: '100%' }}>
            <Col style={{ display: 'flex', height: '100%', width: '100%', padding: 5 }}>
                <Col style={{ justifyContent: 'space-around', heigth: '100%', width: '100%', position: 'relative', paddingBottom: 20 }}>
                    {checkValueExists(item, "metadata") && checkValueExists(item.metadata, "distance") && (
                        <Row style={{ marginBottom: 5 }}>
                            <p style={{ whiteSpace: 'nowrap', fontSize: 11, textAlign: "center" }}>
                                Approx {item.metadata.distance} mi from search
                            </p>
                        </Row>
                    )}
                    <Row style={{ width: '100%', padding: '2%', display: 'inline-flex' }}>
                        <Col style={{ width: '15%', alignItems: 'center' }}>
                            <img src={getImgSrc()} height={50} />
                        </Col>
                        <Col style={{ width: '70%', alignItems: 'center', textAlign: 'center' }}>
                            <Row>
                                <p numberOfLines={1} ellipsizeMode='middle' style={{ fontWeight: 'bold', fontSize: 15 }} >
                                    {checkValueExists(item, "name") && item.name}
                                </p>
                            </Row>
                            <Row>
                                <p numberOfLines={1} ellipsizeMode='middle' type={'body'} style={{ fontSize: 14 }}>
                                    {checkValueExists(item, "city") && item.city}, {checkValueExists(item, "state") && item.state}
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ width: '100%', marginTop: '1%', marginBottom: '1%', display: 'grid' }}>
                        <Row style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ fontWeight: 'bold', fontSize: 14, textDecoration: 'underline' }}>Diesel</p>
                        </Row>
                        <Row style={{ display: 'inline-flex', justifyContent: 'space-between', marginTop: 5 }}>
                            {item.metadata.price.diesel && item.metadata.price.diesel > 0 &&
                                <p style={{ fontSize: 14, lineHeight: "16px" }}>
                                    Retail Price: <span>${parseFloat(item.metadata.price.diesel).toFixed(2).toString()}</span>
                                </p>
                            }
                            {isAuthenticated && checkValueExists(item, "discountedFuelPrice") &&
                                <p style={{ fontSize: 14, lineHeight: "16px" }}>
                                    EL Price: <span style={{ color: 'green', fontSize: 16 }}>${parseFloat(item.discountedFuelPrice).toFixed(2).toString()}</span>
                                </p>
                            }
                        </Row>
                        <Row style={{ display: 'inline-flex', justifyContent: 'space-between'}}>
                            <p className={classes.secondaryText} style={{fontSize: 12, fontStyle:'italic'}}>
                                <span style={{fontWeight:'bold'}}>Last Updated:</span> { (item.metadata.price.price_date && item.metadata.price.price_date != "") ? moment(item.metadata.price.price_date).format('ll HH:mm') : 'N/A'}
                            </p>
                        </Row>
                    </Row>

                    {checkValueExists(item, "contactPhone") && item.contactPhone !== '' && (
                        <Row
                            style={{
                                width: '100%',
                                marginTop: 10,
                            }}
                        >
                            <Col style={{ width: '100%', textAlign: 'center' }}>
                                {checkValueExists(item, "contactPhone") && item.contactPhone !== '' && (
                                    <span style={{ fontSize: 14 }}>
                                        <FA name="phone" style={{ fontSize: '1.1em', color: muiTheme.actionColor }} />
                                        <a href={'tel:'+item.contactPhone} className={classes.valueText} style={{ fontSize: '1.1em', color: muiTheme.actionColor, display: 'inline-block', paddingLeft: 5 }}>{formatPhoneNumberWithHyphens(item.contactPhone)}</a>
                                    </span>
                                )}
                            </Col>
                        </Row>
                    )}
                    <Row
                        style={{
                            width: '100%',
                            marginTop: 15,
                        }}
                    >
                        <Weather isMobile={true} item={item} />
                    </Row>
                    <Row
                        style={{
                            width: '100%',
                            paddingTop: 20,
                            paddingBottom: 20
                        }}
                    >
                        <Restaurants isMobile={true} item={item} />
                    </Row>
                </Col>
            </Col>
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
)(FuelStopsTab)
