
function gtag() { window.dataLayer.push(arguments); }
const GA_MEASUREMENT_ID = 'G-T99V8CFHKM'

const useGtagHook = () => {
    
     const sendGtagEvent = async (eventName, eventParams = null) => {

        let sendParams = { send_to: GA_MEASUREMENT_ID, ...eventParams }
    
        let measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID // getMeasurementId()
        sendParams.send_to = measurementId
    
        // console.log("EVENT: ", eventName, sendParams)
    
        gtag('event', eventName, sendParams)
    }

    return [sendGtagEvent]
}

export default useGtagHook