import {BACKEND_URL} from '../../../api/constants.js'
export function signupCarrier(firstName,lastName,companyName,mc,dot,email,password,favouriteBrokers){
    var requestUrl = BACKEND_URL+'/api/carrierSignUp'
    var payload = {
      firstName:firstName,
      lastName:lastName,
      companyName:companyName,
      mc:mc,
      dotNumber:dot,
      email:email,
      password:password,
      favourites:favouriteBrokers,
    }
   return fetch(requestUrl,{
                            method: 'Post',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body:JSON.stringify(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
