import React from 'react';
import { compose } from 'recompose';

import { Col, Row } from "react-bootstrap";
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { camelize } from "../../../helpers/services"


const TireCardDesktop = (props) => {

    const classes = useStyles();
    const {
        item,
        index,
        imageUrl
    } = props


    return (
        <div style={{ width: "100%" }}>
            <Row style={{ display: 'flex' }}>
                <Col md={2} style={{ display: 'flex', justifyContent: 'left', alignItems: "center" }}>
                    <p>
                        {item.name}
                    </p>
                </Col>
                <Col md={2} style={{ display: 'flex', justifyContent: "left", alignItems: "center" }}>
                    <p>
                        {item.model}
                    </p>
                </Col>
                <Col md={2} style={{ display: 'flex', justifyContent: "left", alignItems: "center" }}>
                    {/* <p>
                        {item.brand}
                    </p> */}
                    <img src={imageUrl} alt={item.brand + " logo"} style={{ width: "80%" }} />
                </Col>
                <Col md={2} style={{ display: 'flex', justifyContent: "left", alignItems: "center" }}>
                    <p>
                        {item.size}
                    </p>
                </Col>
                <Col md={2} style={{ display: 'flex', justifyContent: "left", alignItems: "center" }}>
                    <p>
                        {camelize(item.position)}
                    </p>
                </Col>
                <Col md={2} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                    <p>
                        {item.ply}
                    </p>
                </Col>
            </Row>
        </div>
    );
}

export default compose(
    muiThemeable()
)(TireCardDesktop)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '18px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '18px',
        color: 'grey',
        margin: 0
    },
    discountText: {
        fontSize: '18px',
        lineHeight: '18px',
        color: 'green',
        fontWeight: 'bold',
        margin: 0
    },
});