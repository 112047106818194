import * as CONST from '../constants'

const initialState = {
    filteredOutBrands: [],
    filteredOutPositions: [],
    filteredOutModels: [],
    filteredOutSizes: [],
    filteredOutPlys: [],
    lastAction: null
}

const TireFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONST.SET_BRAND_FILTER:
            return { ...state, ...{ filteredOutBrands: action.payload, lastAction: action.type } }
        case CONST.SET_POSITION_FILTER:
            return { ...state, ...{ filteredOutPositions: action.payload, lastAction: action.type } }
        case CONST.SET_MODEL_FILTER:
            return { ...state, ...{ filteredOutModels: action.payload, lastAction: action.type } }
        case CONST.SET_SIZE_FILTER:
            return { ...state, ...{ filteredOutSizes: action.payload, lastAction: action.type } }
        case CONST.SET_PLY_FILTER:
            return { ...state, ...{ filteredOutPlys: action.payload, lastAction: action.type } }            
        case CONST.RESET_ALL_TIRE_FILTERS:
            return { ...initialState, ...{ lastAction: action.payload } }
        default:
            return state
    }
}

export default TireFilterReducer
