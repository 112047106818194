import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Button from '@material-ui/core/Button';
import { Row, Col } from 'react-bootstrap';

import LocationSelector from '../components/LocationSelector';
import TruckCapacity from '../components/truck-capacity';
import LanePreferences from '../components/lane-preferences';
import AddPreferenceModal from "../components/general-add-edit-modal"



const WernerPreferencesDesktopUI = (props) => {
    const {
        muiTheme,
        locationsList,
        selectedLocationId,
        setSelectedLocationId,
        isLoading,
        lanePreferencesList,
        updatePreferencesData,
        truckCapacityList,
        renderedTab,
        handlePageChange,
        showAddModal,
        setShowAddModal,
        PAGES
    } = props

    const { height, width } = useWindowDimensions();

    const useStyles = makeStyles({
        container: {
            padding: 5,
            height: height,
            width: '100%'
        },
        header: {
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderRadius: 0,
            textTransform: "none",
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        headerText: {
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 16,
            fontWeight: 'bold',
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        }
    });

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Row style={{ height: '8%', width: "100%", display: "flex", justifyContent: "center" }}>
                <Col style={{ width: "33%", display: "flex", justifyContent: "center" }} />
                <Col style={{ width: "33%", display: "flex", justifyContent: "center", paddingTop: 25 }}>
                    <Button
                        className={classes.header}
                        style={{ borderBottomColor: renderedTab == PAGES["capacity"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                        onClick={() => handlePageChange("capacity")}
                    >
                        <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["capacity"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>CAPACITY</p>
                    </Button>
                    <Button
                        className={classes.header}
                        style={{ borderBottomColor: renderedTab == PAGES["preferences"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                        onClick={() => handlePageChange("preferences")}
                    >
                        <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["preferences"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>LANE PREFERENCES</p>
                    </Button>
                </Col>
                <Col style={{ width: "33%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: 100 }}>
                    <Button
                        size="small"
                        style={{
                            backgroundColor: muiTheme.actionColor,
                            height: 35
                        }}
                        onClick={() => { setShowAddModal(true) }}
                    >
                        <p className={classes.headerText} style={{ textTransform: "uppercase", color: "white" }}>Add</p>
                    </Button>
                </Col>
            </Row>

            {/* <Row style={{ height: '10%', marginTop: "1%" }}>
                <LocationSelector
                    locationsList={locationsList}
                    selectedLocationId={selectedLocationId}
                    setSelectedLocationId={setSelectedLocationId}
                />
            </Row> */}

            <Row style={{ height: '90%' }}>
                {renderedTab == PAGES["capacity"] &&
                    <TruckCapacity truckCapacityList={truckCapacityList} selectedLocationId={selectedLocationId} updatePreferencesData={updatePreferencesData} isLoading={isLoading} handleViewLoads={props.handleViewLoads} />
                }
                {renderedTab == PAGES["preferences"] &&
                    <LanePreferences lanePreferencesList={lanePreferencesList} selectedLocationId={selectedLocationId} updatePreferencesData={updatePreferencesData} isLoading={isLoading} handleViewLoads={props.handleViewLoads} />
                }
                {showAddModal && (
                    <AddPreferenceModal selectedLocationId={selectedLocationId} renderedTab={renderedTab} onClose={() => { setShowAddModal(false) }} updatePreferencesData={updatePreferencesData} />
                )}
            </Row>
        </div>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(WernerPreferencesDesktopUI)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}