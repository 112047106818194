import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import FA from 'react-fontawesome'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Popover from '@material-ui/core/Popover';

import CurrencyInput from '../../../../../components/Quote/CurrencyInput'

const ChargeItemMobile = (props) => {
    const classes = useStyles();
    const {
        editMode,
        allowEdit,
        allowedChargeTypes,
        stops,
        item,
        itemIndex,
        isLastItem,
        removeLineItem,
        onFileUpload,
        editModeFlag,
        associatedStop,
        chargeAmount,
        selectedChargeType,

        //handlers
        handleChargeTypeSelect,
        handleAssociateStop,
        handleChargeSubmit,
        onValueChange,

        //ui helpers
        cancelEdit,
        startEditMode,
        formatWord,
        getLineItemTotal,
        numberWithCommas,
        muiTheme
    } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'charge-actions-popover' : undefined;

    const openPopover = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (

        <Row style={{ display: 'flex', flexDirection: editMode ? 'column' : 'row' }}>
            <Col style={{ width: editMode ? '100%' : '55%', textAlign: editMode ? 'center' : 'unset', overflow: 'hidden', display: 'inline-flex', whiteSpace: 'nowrap' }}>
                {editMode ?
                    <div style={{ display: 'block', width: '100%', justifyContent: 'space-between' }}>
                        <div>
                            <FormControl className={classes.formControl}>
                                <InputLabel style={{ fontSize: 16, paddingLeft: 10 }} id="charge-type-select-label">Charge Type</InputLabel>
                                <Select
                                    className={classes.selectfieldStyleOutline}
                                    labelId="select-label"
                                    id="charge-type-select"
                                    value={selectedChargeType}
                                    onChange={handleChargeTypeSelect}
                                >
                                    {allowedChargeTypes.map(type => {
                                        return (
                                            <MenuItem style={{ fontSize: 16 }} value={type}>{formatWord(type)}</MenuItem>
                                        )
                                    })

                                    }
                                </Select>
                            </FormControl>
                        </div>
                        {stops && stops.length > 0 &&
                            <div>
                                <FormControl className={classes.formControl}>
                                    <InputLabel style={{ fontSize: 16, paddingLeft: 10 }} id="stop-select-label">Stop (optional)</InputLabel>
                                    <Select
                                        className={classes.selectfieldStyleOutline}
                                        labelId="stop-select-label"
                                        id="stop-select"
                                        value={associatedStop}
                                        onChange={handleAssociateStop}
                                    >
                                        <MenuItem style={{ fontSize: 16 }} value={0}>None</MenuItem>
                                        {stops.map(stop => {
                                            return (
                                                <MenuItem style={{ fontSize: 16 }} value={stop.sequence}>{"Stop " + stop.sequence}</MenuItem>
                                            )
                                        })

                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        }

                    </div>
                    :
                    <p className={classes.secondaryText} style={{ width: 'min-content', whiteSpace: 'nowrap', overflow: 'visible', marginTop: 16 }}>
                        {item.line_item_type ? formatWord(item.line_item_type) : "Unknown"}
                        <span> {item.stop && item.stop.sequence ? `(Stop ${item.stop.sequence})` : null} </span>
                    </p>

                }

                <hr style={{ width: editMode ? 'calc(100% - 150px)' : '100%', borderTop: '1px dotted grey', marginTop: 32, display: editMode ? 'none' : 'unset' }}
                />
            </Col>

            <Col style={{ width: editMode ? 200 : 100, alignSelf: editMode ? 'center' : 'unset' ,marginTop: 16 }}>
                {editMode ?

                    <CurrencyInput onValueChange={onValueChange} value={chargeAmount} invoiceForm />
                    :
                    <p className={classes.secondaryText}>
                        ${numberWithCommas(parseFloat(item.amount).toFixed(2))}
                    </p>
                }

                {isLastItem && !editMode && 
                    <p className={classes.mainText} style={{ marginTop: 20, borderTop: '1px solid black', fontSize: 22 }}>
                        ${getLineItemTotal()}
                    </p>
                }
            </Col>
            {allowEdit &&
                <div style={{ display: 'contents' }}>
                    {editMode ?
                        <div style={{ display: 'contents' }}>
                            <Col key={"edit_mode_actions"} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 25 }}>
                                <span onClick={() => { cancelEdit(); handleClose()} }><FA name="times" style={{ fontSize: 24, cursor: 'pointer', color: muiTheme.ttRed }} /></span>
                                <span onClick={() => { handleChargeSubmit(); handleClose() } }><FA name="check" style={{ fontSize: 24, cursor: 'pointer', color: 'green' }} /></span>
                            </Col>
                        </div>
                        :
                        <div style={{ display: 'contents' }}>
                            {!editModeFlag &&
                                <div style={{ display: 'contents' }}>

                                    <span onClick={openPopover} style={{ paddingLeft: 5, paddingTop: 15 }}><FA name="ellipsis-h" style={{ fontSize: 18, cursor: 'pointer', color: muiTheme.actionColor }} /></span>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <div style={{ padding: 15 }}>
                                            <Col key={"file_upload_action"} style={{ display: 'inline-flex' }}>
                                                <label for={"upload-photo-" + itemIndex} id="upload-photo-label" className={classes.fileUploadLabel} style={{ backgroundColor: muiTheme.actionColor, color: muiTheme.lightContrastText }}>Upload Document</label>
                                                <input
                                                    type="file"
                                                    name="photo"
                                                    id={"upload-photo-" + itemIndex}
                                                    className={classes.fileUpload}
                                                    onChange={(event) => onFileUpload(event, itemIndex, item)}
                                                />
                                            </Col>
                                            <Col key={"display_actions"} style={{display: 'flex', justifyContent: 'space-around', marginTop: 15}}>
                                                {/* <AddEditChargeButton editCharge updateLineItem={updateLineItem} index={itemIndex} amount={item.amount * 100} chargeType={item.line_item_type} /> */}
                                                <span onClick={() => removeLineItem(itemIndex)}><FA name="trash" style={{ fontSize: 24, cursor: 'pointer', color: muiTheme.ttRed }} /></span>
                                                <span onClick={() => startEditMode()} ><FA name="edit" style={{ fontSize: 24, cursor: 'pointer', color: muiTheme.actionColor }} /></span>
                                            </Col>
                                        </div>
                                    </Popover>
                                </div>
                            }
                        </div>
                    }
                </div>
            }



        </Row>

    )
}

export default compose(
    connect(),
    muiThemeable(),
)(ChargeItemMobile)

const useStyles = makeStyles((theme) => ({
    stopCol: {
        display: 'inline-block',
        width: '50%',
        paddingLeft: 15
    },
    timeCol: {
        display: 'inline-block',
        width: '50%',

    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        margin: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: -5,
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    fileUpload: {
        opacity: 0,
        position: 'absolute',
        zIndex: - 1
    },
    fileUploadLabel: {
        cursor: 'pointer',
        height: 'min-content',
        padding: 5,
        borderRadius: 5,
    },
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    menuItem: {
        fontSize: '16px',
        textAlign: 'center'
    },
    selectfieldStyleOutline: {
        // backgroundColor: 'white',
        border: '1px solid #ccc',
        height: 35,
        width: '200px',
        // maxWidth: '180px',
        // margin: '0px',
        // backgroundColor: 'white',
        paddingRight: '0px',
        paddingBottom: '0px',
        fontSize: '16px',
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'center'
    }
}));