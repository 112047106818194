export function updateAvailabilityLoads(availabilityId){
	return{
		type: 'UPDATE_AVAILABILITY_LOADS_REQ',
		availabilityId
	}
}

export function sendInterestEmail(availabilityId,content,loadIds,contactInfo){
	return{
		type:'SEND_INTEREST_EMAIL_REQ',
		availabilityId,
		content,
		loadIds,
		contactInfo,
	}
}

export function resetEmailSendReducer(){
	return{
		type:'REST_EMAIL_SEND_REDUCER'
	}
}

export function confirmAvailability(availabilityId){
	return{
		type:'CONFIRM_AVAILABILITY',
		availabilityId,
	}
}

export function addCarrierLoadView(loadIds){
	return{
		type:'CARRIER_LOAD_VIEW_REQ',
		loadIds
	}
}

export function updateAvailabilityLoadsV2(availabilityId){
	return{
		type: 'UPDATE_AVAILABILITY_LOADS_REQ_V2',
		availabilityId
	}
}
