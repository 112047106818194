import * as CONST from '../constants'

const initialState = {
    showBinOnly: false,
    filteredOutEquipments: [],
    filteredOutBrokers: [],
    filteredOutDestinations: [],
    filteredOutRevenueCodes:[],
    filteredOutOrigins: [],
    deadheadThresholds: [],
    reloadsThresholds: [],
    weightThresholds: [],
    lastAction: null
}

const LoadFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONST.SET_BOOK_IT_NOW_FILTER:
            return { ...state, ...{ showBinOnly: action.payload, lastAction: action.type } }
        case CONST.SET_EQUIPMENT_FILTER:
            return { ...state, ...{ filteredOutEquipments: action.payload, lastAction: action.type } }
        case CONST.SET_BROKER_FILTER:
            return { ...state, ...{ filteredOutBrokers: action.payload, lastAction: action.type } }
        case CONST.SET_DESTINATION_FILTER:
            return { ...state, ...{ filteredOutDestinations: action.payload, lastAction: action.type } }
            case CONST.SET_REVENUE_CODE_FILTER:
                return { ...state, ...{ filteredOutRevenueCodes: action.payload, lastAction: action.type } }
        case CONST.SET_ORIGIN_FILTER:
            return { ...state, ...{ filteredOutOrigins: action.payload, lastAction: action.type } }
        case CONST.SET_DEADHEAD_FILTER:
            return { ...state, ...{ deadheadThresholds: action.payload, lastAction: action.type } }
        case CONST.SET_RELOADS_FILTER:
            return { ...state, ...{ reloadsThresholds: action.payload, lastAction: action.type } }
        case CONST.SET_WEIGHT_FILTER:
            return { ...state, ...{ weightThresholds: action.payload, lastAction: action.type } }
        case CONST.RESET_ALL_FILTERS:
            return { ...initialState, ...{ lastAction: action.payload } }
        default:
            return state
    }
}

export default LoadFilterReducer
