import React, { useState } from 'react';
import { compose } from 'recompose'
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col, Grid } from 'react-bootstrap';
import { Card } from 'material-ui/Card';
import TextField from '@material-ui/core/TextField';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import { newUserSignupSubmit } from "../../apis/api-signup"
import { receiveUserSignupNotification } from "../../helpers/permissions"


const SignupModalContent = (props) => {

    const { muiTheme, onBack, userEmail = "", onProfileScreen = false, existingData = {} } = props

    const { loginWithRedirect, } = useAuth0();

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        row: {
            display: "flex",
            marginBottom: 25
        },
        colLeft: {
            width: "45%",
            marginRight: "5%"
        },
        colRight: {
            width: "45%",
        },
        mainText: {
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
            fontWeight: 'bold'
        },
        secondaryText: {
            fontSize: '14px',
            lineHeight: '21px',
            color: 'grey',
        },
    }));

    const classes = useStyles()

    const [companyName, setCompanyName] = useState("")
    const [MC, setMC] = useState("")
    const [DOT, setDOT] = useState("")
    const [contactEmail, setContactEmail] = useState(userEmail)
    const [contactPhone, setContactPhone] = useState((onProfileScreen && existingData.phoneNumber) ? existingData.phoneNumber : "")
    const [firstName, setFirstName] = useState((onProfileScreen && existingData.firstName) ? existingData.firstName : "")
    const [lastName, setLastName] = useState((onProfileScreen && existingData.lastName) ? existingData.lastName : "")
    const [numOfTrucks, setNumOfTrucks] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const clearForm = () => {
        setCompanyName("")
        setMC("")
        setDOT("")
        setContactEmail(userEmail)
        setContactPhone((onProfileScreen && existingData.phoneNumber) ? existingData.phoneNumber : "")
        setFirstName((onProfileScreen && existingData.firstName) ? existingData.firstName : "")
        setLastName((onProfileScreen && existingData.lastName) ? existingData.lastName : "")
        setNumOfTrucks("")
        setPassword("")
    }

    const submitForm = () => {
        setIsLoading(true)
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (((MC == null || MC === "") || isNaN(MC)) && (DOT == null || DOT === "") || isNaN(DOT)) {
            toast.error("A Valid MC or DOT Number is required.")
            setIsLoading(false)
            return
        }
        else if (companyName == null || companyName === "") {
            toast.error("Company Name is required.")
            setIsLoading(false)
            return
        }
        else if (contactEmail == null || contactEmail === "" || !validateEmail(contactEmail)) {
            toast.error("Please enter a valid email address")
            setIsLoading(false)
            return
        }
        else if (!onProfileScreen && (password == null || password === "")) {
            toast.error("Password is required.")
            setIsLoading(false)
            return
        }
        else if (!onProfileScreen && (!password.match(strongRegex))) {
            toast.error("Password is too weak.")
            setIsLoading(false)
            return
        }
        let payload = {
            source: process.env.REACT_APP_ADVANTAGE_ID,
            // source: "testDispatcher",
            carrier: {
                companyName: companyName,
                truckCount: numOfTrucks === "" ? null : numOfTrucks,
            },
            user: {
                email: contactEmail,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: contactPhone,
                password: password
            }
        }

        if (MC !== "") {
            payload.carrier.mcNumber = "MC" + MC
        }
        if (DOT !== "") {
            payload.carrier.dotNumber = DOT
        }

        if (receiveUserSignupNotification()) {
            payload.advantageId = process.env.REACT_APP_ADVANTAGE_ID
        }

        // console.log("payload: ", payload)
        newUserSignupSubmit(payload).then(response => {
            // console.log("response: ", response)
            if (response.status) {
                // show toast and redirect to login
                if (!onProfileScreen) {
                    toast.success(response.message + "Please remain on the page while we redirect you to login.")
                    setTimeout(() => {
                        if (process.env.REACT_APP_USE_AUTH0 === "true") {
                            loginWithRedirect()
                            setIsLoading(false)
                        } else {
                            window.location = "/login"
                            setIsLoading(false)
                        }
                    }, 6000);
                } else {
                    toast.success(response.message + "Please log out then log back in and select new carrier.")
                    onBack()
                    setIsLoading(false)
                }

            } else {
                toast.error("Error: " + response.message)
                setIsLoading(false)
            }
        }).catch((error) => {
            // console.log("Error: ", error)
            toast.error("Error: ", error)
            setIsLoading(false)
        })
        //todo: json.stringify the payload before sending
        //todo
        //email the payload to JV, Jess, Murali, TT Support & Raymond from Reed
        // toast.success("You can expect an email containing your login credentials.")

    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    return (
        <Grid style={{ width: '100%', display: "flex", justifyContent: "center" }}>
            <Col style={{ width: "100%" }}>
                <Row style={{ width: "100%", height: 25, marginBottom: 15 }}>
                    <Button
                        style={{
                            backgroundColor: "transparent",
                            color: muiTheme.actionColor,
                            fontSize: 14,
                            textTransform: 'none',
                            display: 'block'
                        }}
                        onClick={() => onBack()}
                    >
                        <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                        Back
                    </Button>
                </Row>
                <h3 style={{ marginBottom: 30 }}>Sign up or speak to one of our dedicated support team members to learn more.</h3>
                <Row style={{ display: "flex", marginBottom: 30 }}>
                    <Col style={{ width: "100%" }}>
                        <form className={classes.root}>
                            <Row className={classes.row}>
                                <div className={classes.colLeft}>
                                    <p className={classes.secondaryText}>First Name</p>
                                    <TextField
                                        inputProps={{ style: { fontSize: 16 } }}
                                        fullWidth
                                        value={firstName}
                                        // placeholder={"Company Name"}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        variant={"filled"}
                                    />
                                </div>
                                <div className={classes.colRight}>
                                    <p className={classes.secondaryText}>Last Name</p>
                                    <TextField
                                        inputProps={{ style: { fontSize: 16 } }}
                                        fullWidth
                                        value={lastName}
                                        // placeholder={"12223334444"}
                                        onChange={(e) => setLastName(e.target.value)}
                                        variant={"filled"}
                                    />
                                </div>

                            </Row>
                            <Row className={classes.row}>
                                <div className={classes.colLeft}>
                                    <p className={classes.secondaryText} style={{ color: contactEmail == "" ? muiTheme.ttRed : "grey" }}>Email <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                    <TextField
                                        inputProps={{ style: { fontSize: 16 } }}
                                        fullWidth
                                        value={contactEmail}
                                        onChange={(e) => setContactEmail(e.target.value)}
                                        disabled={onProfileScreen}
                                        variant={"filled"}
                                    />
                                </div>
                                <div className={classes.colRight}>
                                    <p className={classes.secondaryText}>Phone</p>
                                    <TextField
                                        inputProps={{ style: { fontSize: 16 } }}
                                        fullWidth
                                        value={contactPhone}
                                        // placeholder={"123456"}
                                        onChange={(e) => setContactPhone(e.target.value)}
                                        variant={"filled"}
                                    />
                                </div>
                            </Row>


                            <Row className={classes.row}>

                                <div style={{ width: '95%' }}>
                                    <p className={classes.secondaryText} style={{ color: companyName == "" ? muiTheme.ttRed : "grey" }}>Company Name <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                    <TextField
                                        inputProps={{ style: { fontSize: 16 } }}
                                        fullWidth
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        variant={"filled"}
                                    />
                                </div>

                            </Row>

                            <Row className={classes.row}>
                                <div className={classes.colLeft}>
                                    <p className={classes.secondaryText} style={{ color: (MC == "" && DOT == "") ? muiTheme.ttRed : "grey" }}>MC Number</p>
                                    <TextField
                                        inputProps={{ style: { fontSize: 16 } }}
                                        fullWidth
                                        value={MC}
                                        onChange={(e) => setMC(e.target.value)}
                                        variant={"filled"}
                                    />
                                </div>
                                <div className={classes.colRight}>
                                    <p className={classes.secondaryText} style={{ color: (MC == "" && DOT == "") ? muiTheme.ttRed : "grey" }}>DOT Number</p>
                                    <TextField
                                        inputProps={{ style: { fontSize: 16 } }}
                                        fullWidth
                                        value={DOT}
                                        onChange={(e) => setDOT(e.target.value)}
                                        variant={"filled"}
                                    />
                                </div>
                            </Row>
                            <Row className={classes.row}>
                                {!onProfileScreen && (
                                    <div className={classes.colLeft}>
                                        <p className={classes.secondaryText} style={{ color: password == "" ? muiTheme.ttRed : "grey" }}>Password <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={password}
                                            type="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            FormHelperTextProps={{ style: { fontSize: 11 } }}
                                            helperText={"Password must contain one lower case letter (a-z), " +
                                                "one upper case letter (A-Z), " +
                                                "one number (0-9), " +
                                                "one special character (!@#$%^&*.) " +
                                                "and must be at least 8 characters in length."}
                                            variant={"filled"}
                                        />
                                    </div>
                                )}
                                <div className={classes.colRight}>
                                    <p className={classes.secondaryText}>Number of Trucks</p>
                                    <TextField
                                        inputProps={{ style: { fontSize: 16 } }}
                                        fullWidth
                                        value={numOfTrucks}
                                        // placeholder={"5"}
                                        onChange={(e) => setNumOfTrucks(e.target.value)}
                                        variant={"filled"}
                                    />
                                </div>
                            </Row>
                        </form>
                    </Col>
                </Row>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: 'inline-block', marginRight: 10 }}>
                        <Button
                            variant={"outlined"}
                            size={"small"}
                            style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "white", borderColor: muiTheme.actionColor }}
                            onClick={() => clearForm()}
                        >
                            Clear
                        </Button>
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 10 }}>
                        <Button
                            variant={"contained"}
                            size={"small"}
                            style={{ fontSize: 14, color: "white", backgroundColor: isLoading ? muiTheme.ttGrey : muiTheme.actionColor, borderColor: isLoading ? muiTheme.ttGrey : muiTheme.actionColor }}
                            onClick={() => submitForm()}
                            disabled={isLoading}
                        >
                            Submit Form
                        </Button>
                    </div>
                </Row>
                <Row style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                    <Grid style={{ justifyContent: "center" }}>
                        <Row style={{ justifyContent: "center" }}>
                            <p style={{ textAlign: "center" }}>Please contact Trucker Tools</p>
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <a href={'mailto:support@truckertools.com'} style={{ color: muiTheme.actionColor, display: 'block', textAlign: "center" }}>support@truckertools.com</a>
                        </Row>
                    </Grid>
                </Row>
            </Col>
        </Grid>
    )

}


export default compose(
    muiThemeable(),
)(SignupModalContent)