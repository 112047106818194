import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../HOC/Mobile.js'
import moment from 'moment'
import { toast } from 'react-toastify';

import InvoiceFormDesktop from './invoice-form-desktop';
import InvoiceFormMobile from './invoice-form-mobile';
import * as InvoiceAPI from '../../../apis/api-invoice'
import { AWSApi } from "../../../apis/aws"


const InvoiceForm = (props) => {

    const {
        load,
        refreshInvoices,
        handleCloseModal,
        allowEdit,
        allowSubmit,
        currentStatus,
        statusFormatted,
        statusMessage,
        muiTheme,
        isMobile
    } = props

    const [invoicePayload, updateInvoicePayload] = useState(null);
    const [arrivalTime, updateArrivalTime] = useState(null);
    const [departureTime, updateDepartureTime] = useState(null);
    const [lineItems, updateLineItems] = useState([]);

    const [contactEmail, setContactEmail] = useState(null);
    const [contactPhone, setContactPhone] = useState(null);

    const [editModeFlag, setEditModeFlag] = useState(false) //cannot submit while editing
    const [hasDocuments, setHasDocuments] = useState(false);

    //UI state vars
    const [refresh, callRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (load.invoice == null)
            updateInvoicePayload(getInvoiceTemplate())
        else
            updateInvoicePayload(JSON.parse(JSON.stringify(load.invoice)))

        if (load.invoice && load.invoice.line_items) {
            load.invoice.line_items.forEach(item => item['invoice_line_item_id'] = item.id)
            updateLineItems(JSON.parse(JSON.stringify(load.invoice.line_items)))
        }

    }, [])

    useEffect(() => {
        checkForDocuments()
    }, [lineItems, refresh])

    const getInvoiceTemplate = () => {
        return (
            {
                "amount": 0,
                "status": [],
                "notes": [],
                "payment": [],
                "line_items": []
            }
        )
    }

    const submitInvoice = (type) => {
        const value = buildPayload(type)

        if (editModeFlag)
            toast.error("Unable to submit while charge items are being edited. Please confirm all changes and try again.")
        else if (type !== 'save' && contactPhone == null && contactEmail == null)
            toast.error("Unable to submit without a contact email or phone")
        else if (allowSubmit)
            callSubmitAPI(value.payload)



    }

    const buildPayload = (status) => {
        const returnVal = {
            payload: {
                advantageId: muiTheme.customerUniqueId,
                invoice: {
                    invoice_id: invoicePayload.id ? invoicePayload.id : null,
                    load_id: load.loadId ? load.loadId : null,
                    ext_id: load.externalId ? load.externalId : null,
                    load_number: load.loadHash ? load.loadHash : null,
                    invoice_number: invoicePayload.invoice_number ? invoicePayload.invoice_number : null,
                    amount: invoicePayload.amount,
                    line_items: lineItems,
                    contact_phone: contactPhone,
                    contact_email: contactEmail,
                    status: {
                        type: status,
                        date: moment(),
                    }
                }
            }
        }

        return returnVal

    }

    const addLineItem = (chargeType, associatedStop, amount) => {
        let newLineItem = {
            line_item_type: chargeType,
            amount: amount,
            stop: associatedStop !== 0 && load.stops && load.stops[associatedStop - 1] ? load.stops[associatedStop - 1] : null
        }
        invoicePayload.amount = invoicePayload.amount + newLineItem.amount
        let tmpLineItems = [...lineItems, newLineItem]
        updateLineItems(tmpLineItems)
    }

    const updateLineItem = (index, chargeType, associatedStop, amount) => {
        let tmpLineItems = lineItems
        let item = tmpLineItems[index]

        if (item.amount > amount)
            invoicePayload.amount = invoicePayload.amount - (item.amount - amount)
        else
            invoicePayload.amount = invoicePayload.amount + (amount - item.amount)

        item.amount = amount

        item.line_item_type = chargeType
        item.stop = associatedStop !== 0 && load.stops && load.stops[associatedStop - 1] ? load.stops[associatedStop - 1] : null
        updateLineItems(tmpLineItems)
        // callRefresh(!refresh)
    }

    const removeLineItem = (index) => {
        let tmpLineItems = lineItems

        invoicePayload.amount = invoicePayload.amount - lineItems[index].amount

        lineItems[index]['isDeleted'] = true
       
        if (tmpLineItems[index].documents && tmpLineItems[index].documents.length > 0) { 
            tmpLineItems[index].documents.forEach( (doc, docIndex) => {
                removeLineItemDocument(index, docIndex)
            })
        }

        updateLineItems(tmpLineItems)
        callRefresh(!refresh)
    }

    const removeLineItemDocument = (itemIndex, docIndex) => {
        let tmpLineItems = lineItems
        if (tmpLineItems[itemIndex].documents && tmpLineItems[itemIndex].documents[docIndex]) {
            // tmpLineItems[itemIndex].documents.splice(docIndex, 1)
            tmpLineItems[itemIndex].documents[docIndex]['isDeleted'] = true
        }
        updateLineItems(tmpLineItems)
        callRefresh(!refresh)

    }

    const onFileUpload = (event, index, item) => {

        let file = event.target.files[0]
        file['timestamp'] = moment()

        let tmpLineItems = lineItems
        if (tmpLineItems[index].documents == null) {
            tmpLineItems[index]['documents'] = []
            tmpLineItems[index].documents.push(file)
        } else {
            tmpLineItems[index].documents.push(file)
        }
        updateLineItems(tmpLineItems)
        callRefresh(!refresh)

    }

    const checkForDocuments = () => {
        let tmpHasDoc = false
        lineItems.forEach((item, index) => {
            if (item.documents && item.documents.length > 0) {
                let hasValidDoc = false
                item.documents.forEach(doc => {
                    if (!doc.isDeleted) {
                        hasValidDoc = true
                        return
                    }
                })
                if (hasValidDoc) {
                    setHasDocuments(true)
                    tmpHasDoc = true
                }
            } else if (index === lineItems.length - 1 && !tmpHasDoc) {
                setHasDocuments(false)
            }
        })
    }

    const callSubmitAPI = async (payload) => {
        setIsLoading(true)
        try {
            await uploadAllFiles(payload.invoice.line_items)
        } catch (err) {
            toast.error("There was an error uploading your invoice.")
        }

        InvoiceAPI.PostInvoice(payload).then(response => {
            if (response.status) {
                toast.success(response.message)
                if (!isMobile) refreshInvoices()

                handleCloseModal(true)
            } else {
                toast.error(response.message)
            }
            setIsLoading(false)

        }).catch((error) => {
            setIsLoading(false)
            let errorMessage = "Error: There was a problem submitting your invoice."
            toast.error(errorMessage)
        })

    }

    const uploadAllFiles = (line_items) =>
        new Promise((resolve) => {

            let totalNumOfDocs = 0
            let docsCompletedProcessing = 0

            //get the total number of docs
            line_items.forEach(item => {
                if (item.documents && item.documents.length > 0)
                    item.documents.forEach(() => totalNumOfDocs++)
            })

            if(totalNumOfDocs <= 0 )
                resolve()

            //run through list of docs and compress all those that don't have a url. URL means it's been processed already
            //pass these new docs to AWS S3 and assign them the url that was returned.
            line_items.forEach(item => {
                if (item.documents && item.documents.length > 0) {
                    item.documents.forEach(async doc => {
                        if (!doc.url && doc instanceof File) {
                            await toBase64(doc).then(async resp => {
                                //send the resp to S3 to get the url
                                //the url will be attached to the document object within line item
                                await uploadFileInS3(doc, resp).then(s3URL => {
                                    doc.document_url = s3URL

                                })
                            }).catch(error => {
                                toast.error("There was an error processing your attached files.")
                            })
                        }
                        docsCompletedProcessing++
                        if (docsCompletedProcessing === totalNumOfDocs)
                            resolve()
                    })
                }
            })

        });


        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    const uploadFileInS3 = (doc, resizedImage) =>
        new Promise((resolve) => {
            const awsApiObj = new AWSApi();

            let randomNamePrefix = Math.random().toString(36).substring(8);

            let s3Params = {
                file: resizedImage,
                contentType: 'image/jpeg',
                subDirectory: "invoices/",
                fileNamePrefix: randomNamePrefix + '-' + doc.name + '-',
            }

            awsApiObj.uploadS3(s3Params, (s3Url) => {
                if (s3Url == '') {
                    toast.error('Failed to upload image.')
                } else {
                    resolve(s3Url);
                }
            },
                function (err) {
                })
        });

    return (
        <div style={{ display: 'contents' }}>
            {invoicePayload &&
                <div style={{ display: 'contents' }}>
                    {isMobile ?
                        <InvoiceFormMobile
                            refresh={refresh}
                            allowEdit={allowEdit}
                            allowSubmit={allowSubmit}
                            load={load}
                            invoice={invoicePayload}
                            submitInvoice={submitInvoice}
                            arrivalTime={arrivalTime}
                            updateArrivalTime={updateArrivalTime}
                            departureTime={departureTime}
                            updateDepartureTime={updateDepartureTime}
                            lineItems={lineItems}
                            addLineItem={addLineItem}
                            updateLineItem={updateLineItem}
                            removeLineItem={removeLineItem}
                            onFileUpload={onFileUpload}
                            removeLineItemDocument={removeLineItemDocument}
                            editModeFlag={editModeFlag}
                            setEditModeFlag={setEditModeFlag}
                            hasDocuments={hasDocuments}
                            currentStatus={currentStatus}
                            statusFormatted={statusFormatted}
                            statusMessage={statusMessage}
                            handleCloseModal={handleCloseModal}
                            contactEmail={contactEmail}
                            setContactEmail={setContactEmail}
                            contactPhone={contactPhone}
                            setContactPhone={setContactPhone}
                            isLoading={isLoading}

                        />
                        :
                        <InvoiceFormDesktop
                            refresh={refresh}
                            allowEdit={allowEdit}
                            allowSubmit={allowSubmit}
                            load={load}
                            invoice={invoicePayload}
                            submitInvoice={submitInvoice}
                            arrivalTime={arrivalTime}
                            updateArrivalTime={updateArrivalTime}
                            departureTime={departureTime}
                            updateDepartureTime={updateDepartureTime}
                            lineItems={lineItems}
                            addLineItem={addLineItem}
                            updateLineItem={updateLineItem}
                            removeLineItem={removeLineItem}
                            onFileUpload={onFileUpload}
                            removeLineItemDocument={removeLineItemDocument}
                            editModeFlag={editModeFlag}
                            setEditModeFlag={setEditModeFlag}
                            hasDocuments={hasDocuments}
                            currentStatus={currentStatus}
                            contactEmail={contactEmail}
                            setContactEmail={setContactEmail}
                            contactPhone={contactPhone}
                            setContactPhone={setContactPhone}
                            isLoading={isLoading}
                        />
                    }
                </div>
            }
        </div>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(InvoiceForm)