
import React, { useState, useEffect }  from 'react';
import { compose, withProps } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, Grid } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import AddNewLocationModal from './add-new-location-modal'

const AddNewLocation = (props) => {

    const defaultInitValues = {name:'',addressL1:'',addressL2:'',locationType:''}
    const { onComplete=null,initialValues=null} = props
    const [showModal, setShowModal] = useState(false);
    const [initData, setInitData] = useState(defaultInitValues);

    const useStyles = makeStyles({
        headerText: {
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 16,
            fontWeight: 'bold',
            "&hover": {
                cursor: "grab",
                color: props.muiTheme.actionColor
            }
        }
    });

    const classes = useStyles()

    useEffect(() => {
        if(initialValues){
            setInitData(initialValues)
        }
    }, [initialValues]);

    const openAddNewLocationDialog = ()=>{
        setShowModal(true)
    }
    const closeAddNewLocationDialog = (flag)=>{
        setShowModal(false)
        if(onComplete){
            onComplete(flag)
        }
    }
    return (

            <Row style={{display: 'flex', justifyContent: 'center' }} >
                {showModal && (<AddNewLocationModal onClose={(event)=>closeAddNewLocationDialog(event)} initialValues={initData} />)}
                <Button
                    id='addNewLocationButton'
                    size="small"
                    style={{
                        backgroundColor: props.muiTheme.actionColor,
                        height: 40,
                        padding: 10
                    }}
                    onClick={(event) => { openAddNewLocationDialog(event) }}
                    startIcon={<FeatherIcon.Plus color='white' />}
                >
                    <p className={classes.headerText} style={{ textTransform: "uppercase", color: "white", fontSize: 16 }}>Add New Location</p>
                </Button>
            </Row>
        
    );
}

export default compose(
    muiThemeable(),
    withProps(props => {

        return ({
            ...props
        })
    })
)(AddNewLocation)