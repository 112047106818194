import React, { Component } from 'react'
import {getBrokerRequest,inviteBroker} from './actions.js'
import { connect } from 'react-redux'
import { Col, Row, Grid, Glyphicon } from "react-bootstrap"
import { AutoSizer, List } from 'react-virtualized'
import {FormGroup, Button, Checkbox} from 'react-bootstrap';

export  function isCarrierAdmin() {
	var result;
    var localStoreToken   = localStorage.getItem('token');
    var jsonToken = JSON.parse(localStoreToken);
    var role         =   ''
    role = (jsonToken.role)?jsonToken.role:'';
    var userType = (jsonToken.userType) ? jsonToken.userType : '';
    if(role === 'admin' && userType.toLowerCase()==='carrier'){
        result = true;
    }

    return result;

}

class OutOfNetworkBroker extends React.Component {


	constructor() {
    super();
    this.state = {
      
      list: {},
      invitingResponse: {},

      
    };
    this.checkBoxChecked = this.checkBoxChecked.bind(this);
	this.hidingBrokerId  = 0;
    this.clientHeight = this.clientHeight.bind(this);
    this.fetchBrokers = this.fetchBrokers.bind(this);

	}


	clientHeight() {
    	
  	}


	componentDidMount() {
     this.fetchBrokers();
     //
  	}
  	checkBoxChecked(eachBroker){
  		
  		this.props.inviteBroker(eachBroker,this.fetchBrokers);
  		this.state.hidingBrokerId  = eachBroker.id;
  	}
  	setRefHeight(el) {
	    
	   // console.log('outOfHeight 1 = > '+el.clientHeight);
	   
	}

	fetchBrokers = () => {
	    
	    this.props.getBrokerRequest();
  	}

	render() {
		//console.log('render_start');

	    
		if(this.props.brokers.invitingResponse){
	        this.state.invitingResponse  = this.props.brokers.invitingResponse;
	        
	        if(this.state.invitingResponse.status == true){

	    		
	    		var   deletingId	= this.state.hidingBrokerId;
	        	var indexX =-1;
			    this.state.list.brokers.forEach(function(eachBroker, index) {
			      if(deletingId === eachBroker.id)
			      {
			      	indexX = index;
			      }
			    })
			    if(indexX > -1 ){
			    var tmp 	= 		this.state.list.brokers;
		       	tmp.splice(indexX,1);
		        this.state.list.brokers 		=		tmp;
	    		console.log(this.state.list.brokers);
		        this.props.brokers.brokerList  	=	this.state.list;
	    		this.state.hidingBrokerId  =   0;
	    		}
	        }
	        
	    }


		if(this.props.brokers.brokerList){
			this.state.list  = this.props.brokers.brokerList;
		}
        
        var brokerList		=	[];
        var height 		=	30;
        var brokerCount  = 0;
        
        var brokerRowCount  = 1;

        
	    var footerHeight    = 70
	    var minVidHeight    = 25
	    var maxDivHeight =       70 - 4

	    var lineCount		=	1;

        if(this.state.list.brokers){
        	brokerCount			=		this.state.list.brokers.length;

        	lineCount			=	(brokerCount/4)

        	brokerList 			=	this.state.list.brokers;
        }


        if(brokerCount>0){

		    if(lineCount <= 1){
		    	footerHeight   = 34
		    }
		    else if(lineCount <= 2){
		    	footerHeight   = 52
		    }
		    else{
		    	footerHeight	=		75
		    }
		}
		else{
			footerHeight	=		0
		}

	    this.props.clientHeight(footerHeight);

        // console.log('footerHeight =>'+lineCount);

        return (
		        <div className=" "  ref={this.setRefHeight} >
		        { brokerCount >0 && isCarrierAdmin() &&
		            <div className="custom-panel-body">
		            <Row className="show-grid">
			            <Col xs={12} md={2}>
			            	<span><strong className="small">I want to see loads from : </strong></span>
			            </Col>
			            <Col xs={12} md={10}>
							<div style={{ overflow :'auto' , minHeight : minVidHeight+'px', maxHeight : maxDivHeight+'px'}}>
							<FormGroup>
				         	{
				         		brokerList.map((eachBroker)=>{
		                              return (
		                              			<Col xs={12} md={3} key={eachBroker.id} >
		                              				<div style={{textAlign : 'left',paddingRight : '0px'}}>
		                              				<Checkbox inline onChange={() => this.checkBoxChecked(eachBroker)} >

				                              				<span className="small">{eachBroker.companyNumber}</span>

		                              				</Checkbox>
		                              				</div>
		                              			</Col>
		                              	)
		                        })
		                      }
		                    </FormGroup>
		                    </div>
			            </Col>
		            </Row>
		        	</div>
		        }
		        </div>
		   	)
    	}

	}


const mapStateToProps = state => ({  
  brokers: state.outOfNetworkBrokerFooter,
})

const connected = connect(mapStateToProps, { getBrokerRequest,inviteBroker,})(OutOfNetworkBroker)

export default connected
