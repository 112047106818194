import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { handleApiErrors } from '../lib/api-errors'
import {toast} from 'react-toastify'
import * as Constants from './constants'
import {getIntegrations, addIntegration, editIntegration} from '../api/shipperIntegration'

// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* integrationsFlow (action) {
  try {
    yield put(showLoading())
    const {brokerId, pageNumber, pageSize} = action;
    // here until the API async function, is complete!
    const response = yield call(getIntegrations, brokerId, pageNumber, pageSize);    
    const { integrations } = response;    
    // when the above api call has completed it will "put",
    // or dispatch, an action of type INTEGRATIONS_SUCCESS with
    // the successful response.
    yield put({ type: Constants.INTEGRATIONS_SUCCESS, integrations: integrations })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    //TODO

  }
  finally{
    yield put(hideLoading())
  }
}

// This will be run when the ADD_INTEGRATION_REQUESTING
// Action is found by the watcher
function* addIntegrationFlow (action) {
  try {
    yield put(showLoading())
    const {formValues} = action;
    // here until the API async function, is complete!
    const response = yield call(addIntegration, formValues);    
    const { id } = response;
    // when the above api call has completed it will "put",
    // or dispatch, an action of type INTEGRATIONS_SUCCESS with
    // the successful response
    yield put({ type: Constants.ADD_INTEGRATION_SUCCESS, id: id || null, formValues: {...formValues, id: id} });
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    //TODO
  }
  finally{
    yield put(hideLoading())
  }
}

// This will be run when the ADD_INTEGRATION_REQUESTING
// Action is found by the watcher
function* editIntegrationFlow (action) {
  try {
    yield put(showLoading())
    const {formValues, rowIndex } = action;
    // here until the API async function, is complete!
    const response = yield call(editIntegration, formValues);    
    
    // when the above api call has completed it will "put",
    // or dispatch, an action of type INTEGRATIONS_SUCCESS with
    // the successful response
    yield put({ type: Constants.EDIT_INTEGRATION_SUCCESS, response, formValues: formValues, rowIndex: rowIndex });
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    //TODO
  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the self service action types
// WITH the action we dispatched
function* shipperIntegrationWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(Constants.INTEGRATIONS_REQUESTING, integrationsFlow);  
  yield takeLatest(Constants.ADD_INTEGRATION_REQUESTING, addIntegrationFlow);
  yield takeLatest(Constants.EDIT_INTEGRATION_REQUESTING, editIntegrationFlow);
}

export default shipperIntegrationWatcher