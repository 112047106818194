//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'
  import { showLoading, hideLoading } from 'react-redux-loading-bar'

//MESSAGING
  import * as TYPE from './constants'

  //APIs

  import {addUser,getUser} from '../api/users'


 function* addUserCall (action) {
  try {
  const {postData}= action;
  yield put(showLoading())
  //console.log('importCapacityCall')

  // here until the API async function, is complete!
  const response = yield call(addUser,postData)


  if(response.status){
    action.successCallback()
  }
  else{
     action.failureCallback()
  }
  /**/

  yield put({ type: TYPE.CALL_ADD_USER_SUCCESS, response })


  } catch (error) {
  // if the api call fails, it will "put" the LOADS_ERROR
  // into the dispatch along with the error.
  yield put({ type: TYPE.CALL_ADD_USER_ERROR, error })

  }
  finally{
  yield put(hideLoading())
  }
}



function* getUsersCall(action){
  try {

    yield put(showLoading())
    const response = yield call(getUser)

    yield put({type:TYPE.CALL_GET_USERS_SUCCESS,response})

  } catch (error) {
      console.log(error)
      yield put({type:TYPE.CALL_GET_USERS_ERROR ,  error:error })
  }
  finally{
    yield put(hideLoading())
  }
}


function* addUserWatcher () {
  yield takeLatest(TYPE.CALL_ADD_USER, addUserCall)
  yield takeLatest(TYPE.CALL_GET_USERS, getUsersCall)
}

export default addUserWatcher
