import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../HOC/Mobile.js'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'

const PaymentInformation = (props) => {
    const classes = useStyles();
    const {
        paymentHistory,
        muiTheme
    } = props



    const formatWord = (param) => {
        if (param && typeof param === "string") {
            param = param.replace("_", " ")

            let words = param.split(" ");

            param = words.map((word) => {
                return word[0].toUpperCase() + word.substring(1);
            }).join(" ");

            return param
        }
        return null
    }

    return (


        <Row key={"payment-history"} style={{ paddingLeft: 25 }}>
            <Row style={{ display: 'flex' }}>
                <h3>Payment History</h3>
            </Row>
            {paymentHistory && paymentHistory.length > 0 &&
                <Row>
                    <Row style={{ display: 'flex' }}>
                        <Col style={{ width: '20%' }}>
                            <p className={classes.mainText}>
                                Method
                            </p>
                        </Col>
                        <Col style={{ width: '20%' }}>
                            <p className={classes.mainText}>
                                Amount
                            </p>
                        </Col>
                        <Col style={{ width: '20%' }}>
                            <p className={classes.mainText}>
                                Message
                            </p>
                        </Col>
                        <Col style={{ width: '20%' }}>
                            <p className={classes.mainText}>
                                Status
                            </p>
                        </Col>
                        <Col style={{ width: '20%' }}>
                            <p className={classes.mainText}>
                                Date
                            </p>
                        </Col>
                    </Row>
                    {paymentHistory.map((item, index) =>
                        <Row>
                            <Row style={{ display: 'flex' }}>
                                <Col style={{ width: '20%', paddingRight: 15 }}>
                                    <p className={classes.secondaryText}>
                                        {item.method}
                                    </p>
                                </Col>
                                <Col style={{ width: '20%', paddingRight: 15 }}>
                                    <p className={classes.secondaryText}>
                                        {parseFloat(item.amount).toFixed(2)}
                                    </p>
                                </Col>
                                <Col style={{ width: '20%', paddingRight: 15 }}>
                                    <p className={classes.secondaryText}>
                                        {item.message}
                                    </p>
                                </Col>
                                <Col style={{ width: '20%', paddingRight: 15 }}>
                                    <p className={classes.secondaryText}>
                                        {formatWord(item.status)}
                                    </p>
                                </Col>

                                <Col style={{ width: '20%', paddingRight: 15 }}>
                                    <p className={classes.secondaryText}>
                                        {moment(item.payment_date).format('ll HH:mm')}
                                    </p>
                                </Col>
                            </Row>
                            {index !== paymentHistory.length - 1 &&
                                <Row style={{ width: '100%' }}>
                                    <hr style={{ width: '95%', borderTop: '1px dotted grey' }} />
                                </Row>
                            }

                        </Row>
                    )}
                </Row>
            }

        </Row>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(PaymentInformation)

const useStyles = makeStyles((theme) => ({

    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        margin: 0,
        marginBottom: -5,
    }


}));