import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import LoadDetailsMobile from '../components/load-list/mobile/loadDetailsMobile';

// import localStorage from 'redux-persist/es/storage';


const LoadDetailsScreen = (props) => {

    const { height, width } = useWindowDimensions();
    const { muiTheme } = props

    const useStyles = makeStyles({
        container: {
            height: height,
            width: '100%'
        }
    });
    // console.log(props.loadDetailsRenderedTab)
    const classes = useStyles()

        return (
            <div className={classes.container}>
                <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                    <LoadDetailsMobile {...props} />
                </div>
            </div>
        )
}


export default compose(
    muiThemeable(),
)(LoadDetailsScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}