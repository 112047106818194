import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../HOC/Mobile.js'

import Button from '@material-ui/core/Button';

const InvoiceActionButton = (props) => {

    const {
        buttonText,
        handleOpenForm,
        muiTheme
    } = props

    return (
        <Button
            aria-describedby={"invoice_open_button"}
            variant={"contained"}
            onClick={handleOpenForm}
            style={{
                fontSize: 14,
                color: "white",
                backgroundColor: muiTheme.actionColor,
                borderColor: muiTheme.actionColor,
                margin: 5
            }}
            size={"medium"}
            disableRipple={false}
        >
            {buttonText}
        </Button>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(InvoiceActionButton)