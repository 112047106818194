import React from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { Row } from 'react-bootstrap';

import isMobile from '../../../HOC/Mobile.js'


const WernerTutorialModal = (props) => {
    const { muiTheme, isMobile, onClose } = props
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            width: isMobile ? '100%' : 460,
            height: isMobile ? '100%' : 'unset',
            outline: 'none',
            padding: isMobile ? 5 : theme.spacing(2, 4, 3),
        },
        header: {
            margin: 0
        },
        linkHeader: {
            marginBottom: 5,
            marginTop: 15
        },
        link: {
            color: muiTheme.actionColor,
            "&visited": {
                color: muiTheme.ttUnselectGrey
            },
            "&hover": {
                color: muiTheme.secondary
            },
        }
    }));
    const classes = useStyles()

    const handleClose = () => {
        onClose()
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={true}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableEscapeKeyDown
        >
            <div className={classes.paper}>
                <Row style={{ width: "100%", height: 25, display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        style={{
                            backgroundColor: "transparent",
                            color: muiTheme.actionColor,
                            fontSize: 14,
                            textTransform: 'none',
                            right: -16
                        }}
                        onClick={() => handleClose()}
                    >
                        {/* <FeatherIcon.X color={muiTheme.actionColor} size={20} /> */}
                       Close
                    </Button>
                </Row>
                <h2 id="transition-modal-title">Help Links</h2>
                <h4 className={classes.linkHeader}>Access from Werner.com</h4>
                <a className={classes.link} href="https://vimeo.com/497981208/a7ba8828cf" target="_blank">https://vimeo.com/497981208/a7ba8828cf</a>
                <h4 className={classes.linkHeader}>How to Login</h4>
                <a className={classes.link} href="https://vimeo.com/497984048/d6c9979ac9" target="_blank">https://vimeo.com/497984048/d6c9979ac9</a>
                <h4 className={classes.linkHeader}>Saved Searches</h4>
                <a className={classes.link} href="https://vimeo.com/497984509/dbedb8af96" target="_blank">https://vimeo.com/497984509/dbedb8af96</a>
                <h4 className={classes.linkHeader}>Book-It-Now</h4>
                <a className={classes.link} href="https://vimeo.com/497981720/9542c7f7c1" target="_blank">https://vimeo.com/497981720/9542c7f7c1</a>
            </div>
        </Modal>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(WernerTutorialModal)