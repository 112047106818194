import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'

const USER_SIGNUP_URL = "/membership/carrier"
const GET_USER_BY_EMAIL_URL = "/user/carriers/usersByEmail"

export function newUserSignupSubmit(dynamicParams) {

    const url = BACKEND_URL + USER_SIGNUP_URL
    // const requestParameters = {
    //     // screenName: "LOADS",
    //     matchId: null,
    //     matchContext: null,
    //     unavailableReason: null,
    //     unavailableText: null,
    //     fromApp: false,
    // }

    // const params = { ...dynamicParams, ...requestParameters }
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dynamicParams)
        }
        return fetch(url, requestObject)
            .then(response => {
                console.log("signup response: ", response)
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            }).catch(error => {
                console.log("signup error: ", error)
                let payload = {
                    source: "URL: " + USER_SIGNUP_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(dynamicParams)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        console.log("catch error: ", error)
        let payload = {
            source: "URL: " + USER_SIGNUP_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(dynamicParams)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

export function getUserByEmail(email) {

    const API_PARAMS = "?email="+email
    const url = BACKEND_URL + GET_USER_BY_EMAIL_URL+API_PARAMS

    try {
        let requestObject = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }
        return fetch(url, requestObject)
            .then(response => {
                console.log("getUserByEmail response: ", response)
                // if (!response.ok) {
                //     const error = new Error(response.statusText);
                //     error.response = response;
                //     throw error;
                // }
                return response.json();
            }).catch(error => {
                console.log("getUserByEmail error: ", error)
                let payload = {
                    source: "URL: " + GET_USER_BY_EMAIL_URL+API_PARAMS,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        console.log("getUserByEmail catch error: ", error)
        let payload = {
            source: "URL: " + GET_USER_BY_EMAIL_URL+API_PARAMS,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}