
import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row, Grid } from "react-bootstrap"
import moment from 'moment'
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify'
import { checkValueExists } from '../../../../../lib/check-value-exists'
import { titleCaseFormatter } from "../../../../../lib/title-case-formatter"

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
    faBiohazard
);

const HistoricalLoadCardMobile = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        muiTheme,
        key,
        carrierCommunications
    } = props

    const [isHazmat, setIsHazmat] = useState(false)

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                setIsHazmat(true)
        }
    }, [])

    const formatPhoneNumberWithHyphens = (phone) => {
        var s2 = ("" + phone).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
    }

    const getExpenseRate = (breakDown) => {
        if (breakDown.length === 1) {
            return breakDown[0].ledgerAmount
        } else {
            let total = 0;
            breakDown.forEach(element => {
                total += element.ledgerAmount
            });
            return total
        }
    }

    return (
        <div style={{ height: '100%' }}>
            {/* // <Animatable.div key={load.id} style={[CustomStyleSheet.card, rowStyle]} animation={animation} duration={duration} > */}
            {/* <Link
                style={{ color: 'black' }}
                to={{
                    pathname: '/loadDetails',
                    state: {
                        load: load,
                        pickup: pickup,
                        delivery: delivery,
                        availability: availability,
                        isMyLoadsScreen: true,
                        myLoadsRenderedTab: 2, //zero for active loads
                        carrierCommunications: carrierCommunications,
                    }
                }}
            > */}
                <Col style={{ display: 'flex', width: '100%', padding: 5 }} >
                    <Row style={{ display: 'flex', width: '100%', height: '100%', margin: 0 }}>

                        <Col style={{ display: 'flex', width: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '63%', paddingTop: 8 }} >
                                <div style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: '#A4A4AC' }} >
                                </div>
                                <div style={{ height: '70%', width: 2, backgroundColor: 'lightgrey', marginVertical: 5 }} >
                                </div>
                                <div style={{ width: 10, height: 10, backgroundColor: 'lightgrey' }} >
                                </div>
                            </div>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                            <Row style={{ margin: 0 }}>
                                <div>                   
                                    <Row>
                                        <p className={classes.mainText} style={{ display: 'inline-block' }}>
                                            {titleCaseFormatter(load.originCity) + ', ' + titleCaseFormatter(load.originState)}
                                        </p>
                                        {isHazmat && <FontAwesomeIcon icon={['fas', 'biohazard']} style={{ color: '#000', display: 'inline-block', marginLeft: 5 }} />}
                                    </Row>                                    
                                    {load.pickupDate && (
                                        <Row style={{ margin: 0 }}>
                                            <p className={classes.secondaryText}>
                                                {moment(load.pickupDate).format('ll')}
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(load.pickupTime, "hh:mm a").format('HH:mm')}
                                            </p>
                                        </Row>
                                    )}
                                </div>
                            </Row>

                            <Row style={{ margin: 0 }}>
                                <div>                        
                                    <Row style={{ height: '40%' }}>
                                        <p className={classes.mainText}>
                                            {titleCaseFormatter(load.destinationCity) + ', ' + titleCaseFormatter(load.destinationState)}
                                        </p>
                                    </Row>
                           
                                    {load.deliveryDate && (
                                        <Row style={{ margin: 0, height: '40%' }}>
                                            <p className={classes.secondaryText}>
                                                {moment(load.deliveryDate).format('ll')}
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(load.deliveryTime, "hh:mm a").format('HH:mm')}
                                            </p>
                                        </Row>
                                    )}
                                </div>
                            </Row>
                        </Col>
                        <Col style={{ width: '50%' }}>
                            <div className={classes.flexDataContainer}>
                                <div className={classes.flexItemDataTag}>
                                    <p className={classes.secondaryText}>Ref #</p>
                                </div>
                                <div className={classes.flexItemDataDetails}>
                                    <p className={classes.mainText}>{load.loadHash}</p>
                                </div>
                            </div>
                            {/* <div className={classes.flexDataContainer}>
                                <div className={classes.flexItemDataTag}>
                                    <p className={classes.secondaryText}>Rate</p>
                                </div>
                                <div className={classes.flexItemDataDetails}>
                                    <p className={classes.mainText}>${load.carrierTotal}</p>
                                </div>
                            </div> */}
                            {/* Temporarily hiding rate until my-loads is at the user/driver level */}
                        </Col>
                        {/* <Col style={{ width: '35%' }}>
                            {checkValueExists(load, "loadNumber") &&
                                <Row style={{ alignItems: 'center' }}>
                                    <div style={{ float: 'right' }}>
                                        <Row style={{ display: "flex" }}>
                                            <p className={classes.mainText}>Ref #</p><span className={classes.secondaryText} style={{ display: "flex", alignItems: 'center', color:'black', fontSize: 14 }}> {load.loadNumber}</span>
                                        </Row>
                                    </div>
                                </Row>
                            }
                            {load.expenseLedgerBreakDown && load.expenseLedgerBreakDown.length > 0 &&
                                <Row style={{ alignItems: 'center' }}>
                                    <div style={{ float: 'right' }}>
                                        <Row style={{ display: "flex" }}>
                                            <p className={classes.mainText} >Rate:</p><span className={classes.secondaryText} style={{ display: "flex", alignItems: 'center', color:'black', fontSize: 14 }}>${(getExpenseRate(load.expenseLedgerBreakDown)).toFixed(2)}</span>
                                        </Row>
                                    </div>
                                </Row>
                            }
                        </Col> */}
                    </Row>
                </Col>
            {/* </Link> */}
            <Row style={{
                display: 'flex',
                width: '100%',
                height: '28%',
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: '#F6F6F6',
                margin: 0,
                justifyContent: 'space-between'
            }}>
                {/* <div>
                    <Row key={key + "booked_action_row"} >
                        {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trackingStatus") && (
                            <p className={classes.mainText} style={{ textAlign: 'center' }}>{load.carrierTrackingInfo.trackingStatus}</p>
                        )}
                    </Row>
                </div> */}
            </Row>
        </div>

    );
}


export default compose(
    connect(),
    muiThemeable()
)(HistoricalLoadCardMobile)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        // lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '12px',
        // lineHeight: '21px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    },
    flexDataContainer:{
        display: 'flex',
        flexWrap: 'nowrap',
        maxWidth: 150,
        paddingTop: 1,
        paddingBottom: 1,
        alignItems:'center'
    },
    flexItemDataTag:{
        width: 50,
        paddingRight: 2
    },
    flexItemDataDetails:{
        // width: 100
    },
});