import {BACKEND_URL} from './constants.js'
export function getStates(){
  var requestUrl=BACKEND_URL+'/app/getZonesAndStates'
  return fetch(requestUrl,{
                            method: 'GET'
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
