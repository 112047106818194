import React, { useEffect, useState } from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../../HOC/Mobile.js'

import { Row, Col, } from 'react-bootstrap';
import TruckTypeMultiSelect from '../TruckTypeMultiSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { checkValueExists } from '../../../../../lib/check-value-exists';
import Checkbox from '@material-ui/core/Checkbox';
import AddressPicker,{defaultDataLoc} from '../AddressPicker';
import RateSet ,{defaultDataRate} from '../RateSet';
import AvailableDateTimePicker,{defaultDataDate} from "../AvailableDateTimePicker"
import {resetInitValue} from "../AvailableDateTimePicker"
import BrokerMultiSelect from '../BrokerMultiSelect';
import {canShowBrokers} from '../../../../../CarrierPortal/components/preferences/components/BrokerMultiSelect'

const LanePreferenceFormUI = (props) => {

    const { muiTheme,  initialValues = null,   isMobile = false,onFormComplete=null} = props

   
    let defaultData = {
                        originLocation:defaultDataLoc,
                        destinationLocation:defaultDataLoc,
                        rate:defaultDataRate,
                        date:defaultDataDate(),
                        equipmentType:null,
                        publishAvailability:false
                    }


    const isEditing = initialValues !== null ? true : false
    const [truckType, setTruckType] = useState(null);
    

    const [marginBottomValue, setMarginBottomValue] = useState(10);
    const [marginTopValue, setMarginTopValue] = useState(0);
    const [leftPaddingValue, setLeftPaddingValue] = useState(0);
    const [spacingPadding, setSpacingPadding] = useState(5);
    const [divPadding, setDivPadding] = useState('');
    const [publishAvailability, setPublishAvailability] = useState(defaultData.publishAvailability);
    const [isOriginAnywhere, setIsOriginAnywhere] = useState(false);
    const [isDestinationAnywhere, setIsDestinationinAnywhere] = useState(false);
    
    const [formPayload, setFormPayload] = useState(defaultData);
    
    useEffect(() => {
        setMarginBottomValue(isMobile?0:10)
        setMarginTopValue(isMobile?10:0)
        setSpacingPadding(isMobile?0:5)
        setLeftPaddingValue(5)
        setDivPadding(isMobile?'0px 0px':'0px 5px')
        
    }, [isMobile])

    useEffect(() => {
        if (initialValues !== null) {
            initialValues.equipmentType = initialValues.truckTypes
            initialValues.originLocation = initialValues.origin
            initialValues.destinationLocation = initialValues.destination
            initialValues.publishAvailability=initialValues.visibility
            
            let date = initialValues.availableAt;
            date = resetInitValue(date)
            initialValues.date= date
            setFormPayload(initialValues)
            setTruckType(initialValues.truckTypes)
            setPublishAvailability(initialValues.visibility==1?true:false)
            if(onFormComplete){
                onFormComplete(initialValues)
            }
            
        }
    }, [initialValues])

    
 

    const handleOriginLocationPick = (value) => {
        popuateFormPayload({originLocation:value})
        if(value.locationSelectType=='CUSTOM' && checkValueExists(value,'locationCustom') && value.locationCustom.length>0){
            let locationCustom = value.locationCustom
            if(locationCustom.includes('ANYWHERE')){
                setIsOriginAnywhere(true)
            }else{
                setIsOriginAnywhere(false)
            }
        }
        else if(value.locationSelectType=='ZONE' && checkValueExists(value,'locationZone')){
            let locationZone = value.locationZone
            if(locationZone.abbr == 'ANYWHERE'){
                setIsOriginAnywhere(true)
            }else{
                setIsOriginAnywhere(false)
            }
        }
        else{
            setIsOriginAnywhere(false)
        }
    };

    const handleDestinationLocationPick = (value) => {
        popuateFormPayload({destinationLocation:value})
        if(value.locationSelectType=='CUSTOM' && checkValueExists(value,'locationCustom') && value.locationCustom.length>0){
            let locationCustom = value.locationCustom
            if(locationCustom.includes('ANYWHERE')){
                setIsDestinationinAnywhere(true)
            }else{
                setIsDestinationinAnywhere(false)
            }
        }
        else if(value.locationSelectType=='ZONE' && checkValueExists(value,'locationZone')){
            let locationZone = value.locationZone
            if(locationZone.abbr == 'ANYWHERE'){
                setIsDestinationinAnywhere(true)
            }else{
                setIsDestinationinAnywhere(false)
            }
        }
        else{
            setIsDestinationinAnywhere(false)
        }
    };

    const handleSetTruckType = (value) => {
        setTruckType(value);
        popuateFormPayload({equipmentType:value})
    };


    const handlePublishAvailability = (event) => {
        let checked = event.target.checked
        setPublishAvailability(checked)
        popuateFormPayload({publishAvailability:checked})
    }

    
    const handleRate = (value) => {
        popuateFormPayload({rate:value})
    };
    
    const handleDate = (value) => {
        popuateFormPayload({date:value})
    };


    const handleSetBrokers = (value) => {
        popuateFormPayload({brokerList:value})
    };
    

    const popuateFormPayload = (incomingData)=>{
        let payload = {...formPayload,...incomingData}
        setFormPayload(payload)

        if(onFormComplete){
            onFormComplete(payload)
        }
    }

    const originUI =(
            <Col style={{paddingRight:spacingPadding,paddingLeft:leftPaddingValue}} xs={12} md={6}  >
                    <AddressPicker hasAny={!isDestinationAnywhere} locationType={'Origin'} initialValues={formPayload.origin} showTypeSelectOptions={false} onSetCallBack={handleOriginLocationPick} />
            </Col>
        )


    const destinationUI =(
        <Col style={{paddingRight:spacingPadding,paddingLeft:leftPaddingValue}} xs={12} md={6}  >
                <AddressPicker hasAny={!isOriginAnywhere} locationType={'Destination'} initialValues={formPayload.destination} onSetCallBack={handleDestinationLocationPick}  locationSelectTypeAttr="ZONE" showSpecific={false} />
        </Col>
    )


    const truckSelectUI = (
        <Col xs={12} md={6} style={{paddingRight:spacingPadding,marginTop:marginTopValue,paddingLeft:leftPaddingValue}}    >
            <TruckTypeMultiSelect handleSetTruckType={handleSetTruckType} existingTruckTypes={truckType} />
        </Col>
    )

    const rateUI = (
        <Col xs={12} md={6} style={{marginTop:marginTopValue,paddingLeft:!isMobile?spacingPadding:leftPaddingValue }}    >
            <RateSet initialValues={formPayload.rate} onSetCallBack={handleRate} />
        </Col>
    )


    const brokerSelectUI = ()=>{
        let ui = <Col xs={12} md={6} style={{paddingRight:spacingPadding,marginTop:marginTopValue,paddingLeft:leftPaddingValue}}    >
                </Col>
        if(canShowBrokers()){
            ui = (
                    <Col xs={12} md={6} style={{paddingRight:spacingPadding,marginTop:marginTopValue,paddingLeft:leftPaddingValue}}    >
                        <BrokerMultiSelect handleSetBroker={handleSetBrokers} existingBrokers={formPayload.brokerList} />
                    </Col>
            )
        }
        return ui
    }


    return (
        <Col style={{padding: divPadding}} >
            <Row style={{height:90,marginBottom:marginBottomValue,width:'100%',display:isMobile?'block':'flex',alignItems:'flex-end'}} >
                {originUI}
                {destinationUI}
            </Row>
            <Row style={{height:50,marginBottom:marginBottomValue,width:'100%'}}  >
                {truckSelectUI}
                {rateUI}
            </Row>
            <AvailableDateTimePicker  initialValues={formPayload.date} onSetCallBack={handleDate} type={"LANE"}  />
            
            <Row style={{height:50,width:'100%', marginTop:10}}  >
                {brokerSelectUI()}
                <Col style={{margin:0,justifyContent:'flex-end',alignSelf:'flex-end',height: 50, display: 'flex',alignItems:'flex-end'}} md={6} xs={12} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                    key="notification_checkbox"
                                    style={{ color: muiTheme.secondary, transform: "scale(1.5)" }}
                                    color="default"
                                    onChange={handlePublishAvailability}  
                                    checked={publishAvailability} 
                                />
                        }
                        label={<span style={{fontSize:16}}>Publish Availability</span>}
                        labelPlacement="right"
                        style={{margin:0,justifyContent:'flex-end'}}
                    />
                </Col>
            </Row>
        </Col>
    )
}


export default compose(
    muiThemeable(),
    isMobile()
)(LanePreferenceFormUI)