import * as CONST from '../constants'

const initialState = {
    windowLocation: null,
}

const LoginSignupReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONST.SET_WINDOW_LOCATION:
            return { ...state, ...{ windowLocation: action.payload } }
        default:
            return state
    }
}

export default LoginSignupReducer
