import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
// import { LogActionsActivity } from '../../../apis/index'
import muiThemeable from 'material-ui/styles/muiThemeable';
// import {useAuthenticationHook} from '../../../hooks/authentication-hook';
import LanePreferencesList from './LanePreferencesList';


const LanePreferences = (props) => {

  const {
    muiTheme,
    lanePreferencesList,
    selectedLocation,
    updatePreferencesData,
    setIsLoading
  } = props

  
  // const [filteredPreferencesList, setFilteredPreferencesList] = useState(lanePreferencesList)

  // useEffect(() => {

  //   buildFilteredPreferencesList()
      
  // }, [selectedLocation, lanePreferencesList])


  // const buildFilteredPreferencesList = () => {

  //   if(selectedLocation == null || selectedLocation === "Show All") {
  //     //show all of capacity list
  //     setFilteredPreferencesList(lanePreferencesList)

  //   } else { //filter the list based on location
  //     let tmpList = []
  //     lanePreferencesList.forEach( item => {
  //       if(selectedLocation === item.origin.city) //todo: based on mock data 
  //         tmpList.push(item)
  //     });
  //     setFilteredPreferencesList(tmpList)
  //   }
      
  // }


  return (
    <div style={{height: '100%'}}>
        <LanePreferencesList lanePreferencesList={lanePreferencesList} updatePreferencesData={updatePreferencesData} setIsLoading = {setIsLoading} handleViewLoads={props.handleViewLoads}/>
    </div>
  )

}

export default compose(
  connect(),
  muiThemeable()
)(LanePreferences)