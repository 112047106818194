import {BACKEND_URL} from './constants.js'

export function sendCarrierInterestEmail(availabilityId,content,loadIds,contactInfo){
  var requestUrl=BACKEND_URL+'/carrier/showLoadInterest'
  return fetch(requestUrl,{
                            method: 'POST',
                            headers: {
                              'Authorization': 'bearer '+ JSON.parse(localStorage.getItem('token')).id,
                            },
                            body:JSON.stringify({availabilityId:availabilityId,content:content,loadIds:loadIds,contactInfo:contactInfo})
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}

export function sendCarrierInterestEmail_SPOOF(){
  const promise1 = function(){
    return new Promise(function(resolve, reject) {
      setTimeout(resolve, 3000, 'foo');
    });
  }
  let promise = promise1()
  return promise.then(response=>({status:true,payload:false}))
}
