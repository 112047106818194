import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../../HOC/Mobile.js'
import { toast } from 'react-toastify';

import ChargeItemDesktop from './charge-item-desktop'
import ChargeItemMobile from './charge-item-mobile'


const ChargeItem = (props) => {
    const {
        allowEdit,
        allowedChargeTypes,
        stops,
        lineItems,
        item,
        itemIndex,
        isLastItem,
        addLineItem,
        updateLineItem,
        removeLineItem,
        onFileUpload,
        editModeFlag,
        setEditModeFlag,
        cancelAddNew,
        isMobile
    } = props

    const [selectedChargeType, setSelectedChargeType] = useState(item.line_item_type)
    const [chargeAmount, setChargeAmount] = useState(item.amount * 100)
    const [editMode, setEditMode] = useState(false)
    const [associatedStop, setAssociatedStop] = useState(item.stop && item.stop.sequence ? item.stop.sequence : null)


    useEffect(() => {
        if (item.addNew) {
            setEditMode(true)
            setEditModeFlag(true)
        }
    }, [])

    const handleChargeTypeSelect = (event) => {
        setSelectedChargeType(event.target.value)
    }

    const handleAssociateStop = (event) => {
        setAssociatedStop(event.target.value)
    }

    const onValueChange = (value) => {
        setChargeAmount(value);
    }

    const handleChargeSubmit = () => {
        if (selectedChargeType == null)
            toast.error('Please select a charge type')
        else if (chargeAmount == null || chargeAmount <= 0)
            toast.error('Please enter a charge amount greater than zero')
        else {
            if (item.addNew)
                addLineItem(selectedChargeType, associatedStop, chargeAmount / 100)
            else
                updateLineItem(itemIndex, selectedChargeType, associatedStop, chargeAmount / 100)

            setEditMode(false)
            setEditModeFlag(false)
        }

    }

    const formatWord = (param) => {
        if (param && typeof param === "string") {
            param = param.replace("_", " ")

            let words = param.split(" ");

            param = words.map((word) => {
                return word[0].toUpperCase() + word.substring(1);
            }).join(" ");

            return param
        }
        return null
    }

    const getLineItemTotal = () => {
        let totalRate = 0
        lineItems.forEach(item => {
            if (item.amount && item.amount > 0 && !item.isDeleted) {
                totalRate = totalRate + item.amount
            }
        });
        return numberWithCommas(parseFloat(totalRate).toFixed(2))
    }

    const cancelEdit = () => {
        setEditMode(false)
        setEditModeFlag(false)
        if (item.addNew) {
            cancelAddNew(itemIndex)
        } else {
            setChargeAmount(item.amount * 100)
            setSelectedChargeType(item.line_item_type)
        }


    }

    const startEditMode = () => {
        setEditMode(true)
        setEditModeFlag(true)
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }



    return (
        <div>
            {isMobile ?
                <ChargeItemMobile
                    editMode={editMode}
                    allowEdit={allowEdit}
                    allowedChargeTypes={allowedChargeTypes}
                    stops={stops}
                    item={item}
                    itemIndex={itemIndex }
                    isLastItem={isLastItem}
                    removeLineItem={removeLineItem}
                    onFileUpload={onFileUpload}
                    editModeFlag={editModeFlag}
                    associatedStop={associatedStop}
                    selectedChargeType={selectedChargeType}
                    chargeAmount={chargeAmount}
                    handleChargeTypeSelect={handleChargeTypeSelect}
                    handleAssociateStop={handleAssociateStop}
                    handleChargeSubmit={handleChargeSubmit}
                    onValueChange={onValueChange}
                    cancelEdit={cancelEdit}
                    startEditMode={startEditMode}
                    formatWord={formatWord}
                    getLineItemTotal={getLineItemTotal}
                    numberWithCommas={numberWithCommas}
                />
                :
                <ChargeItemDesktop
                    editMode={editMode}
                    allowEdit={allowEdit}
                    allowedChargeTypes={allowedChargeTypes}
                    stops={stops}
                    item={item}
                    itemIndex={itemIndex }
                    isLastItem={isLastItem}
                    removeLineItem={removeLineItem}
                    onFileUpload={onFileUpload}
                    editModeFlag={editModeFlag}
                    associatedStop={associatedStop}
                    selectedChargeType={selectedChargeType}
                    chargeAmount={chargeAmount}
                    handleChargeTypeSelect={handleChargeTypeSelect}
                    handleAssociateStop={handleAssociateStop}
                    handleChargeSubmit={handleChargeSubmit}
                    onValueChange={onValueChange}
                    cancelEdit={cancelEdit}
                    startEditMode={startEditMode}
                    formatWord={formatWord}
                    getLineItemTotal={getLineItemTotal}
                    numberWithCommas={numberWithCommas}
                />
            }
        </div>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(ChargeItem)