import React, { useContext } from 'react';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';

import { Form, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import TireSearchBarSelect from './tire-search-bar-select';
import RequestQuotesButton from '../request-quotes-button'


const TireSearchBarDesktop = (props) => {

    const {
        muiTheme,
        searchData,
        handleSelect,
        onSearch
    } = props

    const classes = useStyles()

    return (
        <Form>
            <div style={{ display: 'inline-flex', columnGap: '10px' }}>
                <Col style={{ margin: 'auto', display: "flex", justifyContent: "center" }}>
                    <TireSearchBarSelect type={"brand"} items={searchData["brand"]} handleSelect={handleSelect} />
                </Col>
                <Col style={{ margin: 'auto', display: "flex", justifyContent: "center" }}>
                    <TireSearchBarSelect type={"position"} items={searchData["position"]} handleSelect={handleSelect} />
                </Col>
                <Col style={{ margin: 'auto', display: "flex", justifyContent: "center" }}>
                    <TireSearchBarSelect type={"product"} items={searchData["type"]} handleSelect={handleSelect} />
                </Col>
                <Col style={{ margin: 'auto', display: "flex", justifyContent: "center" }}>
                    <TireSearchBarSelect type={"ply"} items={searchData["ply"]} handleSelect={handleSelect} />
                </Col>
                <Col style={{ margin: 'auto', display: "flex", justifyContent: "center" }}>
                    <Button
                        style={{ fontSize: 16, color: "white", backgroundColor: muiTheme.actionColor }}
                        variant={"contained"}
                        size="small"
                        onClick={() => onSearch()}
                    // disabled={open}
                    >
                        Search
                    </Button>
                </Col>
                <Col style={{ margin: 'auto', display: "flex", justifyContent: "center" }}>
                    <RequestQuotesButton btnStyle={{ fontSize: 16, color: "white", backgroundColor: muiTheme.actionColor }} isMobile={false}/>
                </Col>
            </div>
        </Form>
    )
}

export default muiThemeable()(TireSearchBarDesktop)

const useStyles = makeStyles({
    loadSearchButton: {
        backgroundColor: 'white',
        // primaryColor:primary.main,
        justifyContent: 'center',
        // outline: '1px solid black',
        minHeight: '45px',
        maxHeight: '45px',
        minWidth: '45px',
        maxWidth: '45px',
        left: '10%',
        top: '10%',
        border: '1px solid #ccc',
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.1,
    },
    cartContainer: {
        // display: "flex",
        marginBottom: 30,
        padding: 10,
        borderStyle: "solid",
        borderWidth: 0.5,
        maxHeight: 160,
        overflowY: "scroll"
    }
});
