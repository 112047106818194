import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import isMobile from '../../../../../../HOC/Mobile.js';
import { toast } from "react-toastify";

import LanePreferencesCard from './LanePreferencesCard';
import LanePreferencesCardMobile from './LanePreferencesCardMobile';
import AddPreferenceModal from "../../werner-add-edit-modal";

import ConfirmDialog from '../../../../ConfirmDialog';

import { WernerServices } from '../../../../../customers/a98db973/services';
import {useAuthenticationHook} from '../../../../../hooks/authentication-hook';
import { Col , Row} from 'react-bootstrap';
import FA from 'react-fontawesome'
import { checkValueExists } from "../../../../../../lib/check-value-exists"

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    mainText: {
        fontFamily: 'Arial !important',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        margin: 0
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height: 100
    },
}));

const LanePreferencesItem = (props) => {

    const classes = useStyles()

    const { muiTheme, item, itemIndex, handleViewLoads, isMobile = false, updatePreferencesData, setIsLoading} = props

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    const [showEditModal, setShowEditModal] = useState(false)
    const [availability, setAvailability] = useState(item.visibility === 1 ? true : false);
    const [notificationsOn, setNotificationsOn] = useState(false);

    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false);


    useEffect(() => {
        setNotificationsOn(item.receiveNotifications)
    }, [])

    const handleToggleNotification = (event) => {
        setNotificationsOn(event.target.checked)

        //todo: call api

        // useGtagHook(
        //     "toggle_notifications",
        //     {
        //         action: event.target.checked ? "TOGGLE_ON" : "TOGGLE_OFF",
        //         location: item.pickupCity + ", " + item.pickupState,
        //         isRecurring: checkValueExists(item, "pickupWeekDay"),
        //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
        //         pickupTime: item.availableTime,
        //         destinations: item.destinations,
        //         equipments: item.truckTypes
        //     }
        // )
    };

    const handleDeleteLanePreference = () => {
        setIsDeleteConfirmationShow(true)
    }

    const deleteItemProcess = (flag) => {

        setIsDeleteConfirmationShow(false)

        if(flag){ 

            const apiServices = new WernerServices()

            let params = {
                ...item,
                ...{
                    ecpCarrierIdentifier: user.carrier.externalId,
                    user: item.userName,
                    source: item.sourceSystem,
                    lanePrefernceId: item.lanePreferenceId // TODO: await werner to fix typo
                }
            }

            //TODO: add status response for checking status of api call
            setIsLoading(true);
            apiServices.deleteLanePreference(params)
                .then(response => {
                    //updatePreferencesData has a setIsLoading(false)
                    updatePreferencesData()
                    toast.success("Lane preference deleted successfully. The page has been refreshed.")
                })
                .catch(error => {
                    setIsLoading(false)
                    toast.error("Fail to perform this action.")
                })
        // TODO: add then and catch for toasts and refresh list
        }

        // if(flag){
        //     const apiServices = new GeneralServices()
        //     let params = {
        //     }
        //     apiServices.deleteTruckCapacity(params,item.id)
        //                 .then(response => {
        //                     if (response && response.status) {
        //                         updatePreferencesData()
        //                     }else{
        //                         toast.error("Fail to perform this action.")
        //                     }
        //                 })
        //                 .catch(error => {
        //                     toast.error("Fail to perform this action.")
        //                 })
        // }
    }
    const handleCaps = (textInput) => {
        if(textInput)
            return textInput.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toUpperCase());
    }
         
    const getDaysOfWeek = (selectedDays) => {
        let returnArray = []
        if (selectedDays.length === 1 && selectedDays[0] === "ALL") {
            return selectedDays[0]
        }

        selectedDays.forEach(day => {
            switch (day) {
                case "SUN":
                    returnArray.push("SUN")
                    break
                case "MON":
                    returnArray.push("MON")
                    break
                case "TUE":
                    returnArray.push("TUE")
                    break
                case "WED":
                    returnArray.push("WED")
                    break
                case "THU":
                    returnArray.push("THU")
                    break
                case "FRI":
                    returnArray.push("FRI")
                    break
                case "SAT":
                    returnArray.push("SAT")
                    break
            }

        })

        return returnArray.join(", ")

    }

    const card = ()=>{
        let ui = (
            <Col style={{width:'100%',padding:10, paddingLeft: 5}} >
                <Row>
                    <p className={classes.mainText} style={{marginBottom:20}}>
                    Are you sure you would like to delete this lane?
                    </p>
                </Row>
                <Row style={{width:'100%',display:'flex'}} >
                        <Col className={classes.col} style={{  }}>
                            <b><p className={classes.mainText}>{item.origin.city ? handleCaps(item.origin.city) + ", " : ""}{item.origin.state}</p></b>
                        </Col>
                        <Col className={classes.col} style={{marginLeft:25, width: 40, alignSelf: 'center', justifyContent: 'center', display: 'flex' }}>
                            <FA name='arrow-right' />
                        </Col>
                        <Col className={classes.col} style={{  }}>
                            <b><p className={classes.mainText}>{item.destination.city ? handleCaps(item.destination.city) + ", " : ""}{item.destination.state}</p></b>
                        </Col>
                </Row>
                <Row style={{width:'100%',display:'flex'}}>
                            <p className={classes.mainText}>
                                Days: {getDaysOfWeek(item.daysOfWeek)}
                            </p>
                </Row>
                
                {checkValueExists(item, "rate") && (
                    <Row style={{width:'100%',display:'flex'}}>
                         <p className={classes.mainText}>Rate: ${item.rate}{checkValueExists(item, "rateType") && item.rateType == "RPM" ? "/mile" : ""}</p>
                    </Row>
                )}
            </Col> 
        )
        return ui
    }

    return (
        <div>
            <ConfirmDialog open={isDeleteConfirmationShow} onClose={(flag)=>deleteItemProcess(flag)} header={'Delete Lane Preference'} bodyComp={card()} body={''} acceptButtonText='DELETE' />
            <div className={classes.searchCardRow}>
                {!isMobile ?
                    <LanePreferencesCard
                        item={item}
                        handleViewLoads={handleViewLoads}
                        availability={availability}
                        notificationsOn={notificationsOn}
                        setShowEditModal={setShowEditModal}
                        handleToggleNotification={handleToggleNotification}
                        handleDeleteLanePreference={handleDeleteLanePreference}
                    />
                    :
                    <LanePreferencesCardMobile 
                        item={item}
                        handleViewLoads={handleViewLoads}
                        availability={availability}
                        notificationsOn={notificationsOn}
                        setShowEditModal={setShowEditModal}
                        handleToggleNotification={handleToggleNotification}
                        handleDeleteLanePreference={handleDeleteLanePreference}
                    />
                }
            </div>
            {showEditModal && (
                <AddPreferenceModal selectedLocationId={item.addressId} renderedTab={1} onClose={() => { setShowEditModal(false) }} editItem={item} preferenceType={"lanePref"} updatePreferencesData={updatePreferencesData} />
            )}
        </div>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile()
)(LanePreferencesItem)