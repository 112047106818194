import React, { useState } from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col, Grid, Button } from 'react-bootstrap';
import { Card } from 'material-ui/Card';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import isMobile from '../../../HOC/Mobile.js'

import { EnglandServices } from '../../customers/dd4udt1b/services';
import {useAuthenticationHook} from '../../hooks/authentication-hook'
import useGtagHook from '../../../gtag-hook';

const FactoringForm = (props) => {

    const { muiTheme, isMobile } = props

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            height: 'unset',
            outline: 'none',
            padding: theme.spacing(2, 4, 3),
        },
        row: {
            display: "flex",
            marginBottom: 20
        },
        colLeft: {
            width: "45%",
            marginRight: "5%"
        },
        colRight: {
            width: "45%",
        },
        mainText: {
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
            fontWeight: 'bold'
        },
        secondaryText: {
            fontSize: '14px',
            lineHeight: '21px',
            color: 'grey',
        },
    }));

    const classes = useStyles()

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    const [sendGtagEvent] = useGtagHook()

    const [firstName, setFirstName] = useState((user && user.firstName) ? user.firstName : "");
    const [lastName, setLastName] = useState((user && user.lastName) ? user.lastName : "");
    const [email, setEmail] = useState((user && user.username) ? user.username : "");
    const [companyName, setCompanyName] = useState((user && user.carrier && user.carrier.companyName) ? user.carrier.companyName : "")
    const [mc, setMc] = useState((user && user.carrier && user.carrier.mc) ? String(user.carrier.mc).substring(2) : "");
    const [dot, setDot] = useState((user && user.carrier && user.carrier.dot) ? user.carrier.dot : "");
    const [phone, setPhone] = useState("")
    const [numTrucks, setNumTrucks] = useState("")

    const [responseMsg, setResponseMsg] = useState(null)
    const [isError, setIsError] = useState(false)

    const englandApiServices = new EnglandServices()

    // Email to: ecsfactoringleads@englandlogistics.com

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const clearForm = () => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setCompanyName("")
        setMc("")
        setDot("")
        setPhone("")
        setNumTrucks("")
    }

    const submitForm = () => {
        if (((mc == null || mc === "" || (mc.split(" ").join("") === "")) || isNaN(mc)) && (dot == null || dot === "" || (dot.split(" ").join("") === "")) || isNaN(dot)) {
            toast.error("A Valid MC or DOT Number is required.")
            return
        }
        else if (email == null || email === "" || (email.split(" ").join("") === "") || !validateEmail(email)) {
            toast.error("A valid email address is required.")
            return
        }
        else if (phone == null || phone === "" || (phone.split(" ").join("") === "") || !Number.isInteger(Number(phone)) || phone.length < 10) {
            toast.error("A valid phone number is required. Please enter digits only and exclude all special characters.")
            return
        }
        else if ((firstName == null || firstName === "" || (firstName.split(" ").join("") === "")) ||
            (lastName == null || lastName === "" || (lastName.split(" ").join("") === "")) ||
            (companyName == null || companyName === "" || (companyName.split(" ").join("") === "")) ||
            (numTrucks == null || numTrucks === "" || (numTrucks.split(" ").join("") === ""))
        ) {
            toast.error("All fields are required.")
            return
        }
        else if (isNaN(numTrucks) || !Number.isInteger(Number(numTrucks))) {
            toast.error("Please enter a valid number of trucks. This field only takes integer values.")
            return
        }

        let params = {
            "advantageId": process.env.REACT_APP_ADVANTAGE_ID,
            "firstName": firstName,
            "lastName": lastName,
            "companyName": companyName,
            "emailAddress": email,
            "phoneNumber": phone,
            "numberOfTrucks": Number(numTrucks),
            "requestType": "FACTOR",
            "mcNumber": mc,
            "dotNumber": dot
        }

        // console.log(params)
        englandApiServices.submitProductApplicationForm(params).then(response => {
            // console.log("response", response)
            sendGtagEvent("submit_factor_application_form", {
                firstName: firstName,
                lastName: lastName,
                carrierCompanyName: companyName,
                emailAddress: email,
                phoneNumber: phone,
                numberOfTrucks: Number(numTrucks),
                carrierMcNumber: mc,
                carrierDotNumber: dot,
                apiDidError: !response.status
            })
            if (response.status) {
                setResponseMsg(response.message)
                clearForm()
            } else {
                setResponseMsg("Error: " + response.message)
                setIsError(true)
            }
        }).catch(error => {
            // console.log("error", error)
            setResponseMsg("Error: " + error)
            setIsError(true)
        })
    }

    return (
        <div style={{ height: "100%", width: '100%', display: "flex", justifyContent: "center" }}>
            {responseMsg && (
                <Card className={classes.paper} style={{ width: isMobile ? "100%" : "60%", marginBottom: 30, display: "flex", justifyContent: "center" }}>
                    <Row style={{ marginBottom: 15 }}>
                        <h3 style={{ margin: 0, textAlign: "center" }}>{responseMsg}</h3>
                    </Row>
                    {isError && (
                        <Button
                            variant={"contained"}
                            size={"small"}
                            style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, borderColor: muiTheme.actionColor }}
                            onClick={() => setResponseMsg(null)}
                        >
                            Return to Form
                        </Button>
                    )}
                </Card>
            )}
            {!responseMsg && (
                <Card style={{ width: isMobile ? "100%" : "60%" }} className={classes.paper}>
                    <Row style={{ marginBottom: 10 }}>
                        <h3 style={{ margin: 0 }}>Submit form or call us direct at <a href={'tel:8008487810'} style={{color: muiTheme.actionColor}}>800-848-7810</a> for more information</h3>
                        {/* <p style={{ color: muiTheme.ttRed }}>All fields are required.</p> */}
                    </Row>
                    <Row style={{ display: "flex", marginBottom: 10 }}>
                        <Col style={{ width: "100%" }}>
                            <form className={classes.root}>
                                <Row className={classes.row}>
                                    <div className={classes.colLeft}>
                                        <p className={classes.secondaryText} style={{ color: firstName == "" ? muiTheme.ttRed : "grey" }}>First Name <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={firstName}
                                            // placeholder={"Jess"}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </div>
                                    <div className={classes.colRight}>
                                        <p className={classes.secondaryText} style={{ color: lastName == "" ? muiTheme.ttRed : "grey" }}>Last Name <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={lastName}
                                            // placeholder={"Quint"}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                </Row>
                                <Row className={classes.row}>
                                    <div className={classes.colLeft}>
                                        <p className={classes.secondaryText} style={{ color: email == "" ? muiTheme.ttRed : "grey" }}>Email Address <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={email}
                                            // placeholder={"jess@truckertools.com"}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className={classes.colRight}>
                                        <p className={classes.secondaryText} style={{ color: phone == "" ? muiTheme.ttRed : "grey" }}>Phone Number <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={phone}
                                            // placeholder={"12223334444"}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </div>
                                </Row>
                                <Row className={classes.row}>
                                    <div className={classes.colLeft}>
                                        <p className={classes.secondaryText} style={{ color: companyName == "" ? muiTheme.ttRed : "grey" }}>Company Name <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={companyName}
                                            // placeholder={"Trucker Tools Carrier"}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                    </div>
                                    <div className={classes.colRight}>
                                        <p className={classes.secondaryText} style={{ color: numTrucks == "" ? muiTheme.ttRed : "grey" }}>Number of Trucks <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={numTrucks}
                                            // placeholder={"5"}
                                            onChange={(e) => setNumTrucks(e.target.value)}
                                        />
                                    </div>
                                </Row>
                                <Row className={classes.row}>
                                    <div className={classes.colLeft}>
                                        <p className={classes.secondaryText} style={{ color: (mc == "" && dot == "") ? muiTheme.ttRed : "grey" }}>MC Number</p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={mc}
                                            // placeholder={"MC11223344"}
                                            onChange={(e) => setMc(e.target.value)}
                                        />
                                    </div>
                                    <div className={classes.colRight}>
                                        <p className={classes.secondaryText} style={{ color: (mc == "" && dot == "") ? muiTheme.ttRed : "grey" }}>DOT Number</p>
                                        <TextField
                                            inputProps={{ style: { fontSize: 16 } }}
                                            fullWidth
                                            value={dot}
                                            // placeholder={"MC11223344"}
                                            onChange={(e) => setDot(e.target.value)}
                                        />
                                    </div>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ display: 'inline-block', marginRight: 10 }}>
                            <Button
                                variant={"outlined"}
                                size={"small"}
                                style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "white", borderColor: muiTheme.actionColor }}
                                onClick={() => clearForm()}
                            >
                                Clear
                    </Button>
                        </div>
                        <div style={{ display: 'inline-block', marginLeft: 10 }}>
                            <Button
                                variant={"contained"}
                                size={"small"}
                                style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, borderColor: muiTheme.actionColor }}
                                onClick={() => submitForm()}
                            >
                                Submit Form
                        </Button>
                        </div>
                    </Row>
                </Card>
            )}
        </div>
    )

}


export default compose(
    muiThemeable(),
    isMobile()
)(FactoringForm)