import * as GeoLocationApi from '../../src/CarrierPortal/apis/api-geo-location';

export const GetLatitudeLongitudes = (stop) => {
    let params = {
        city: stop.city,
        state: stop.state,
        zipcode: stop.zip
    }
    let resp = GeoLocationApi.GetLatitudeLongitudes(params).then(response => {
        if (response.status) {
            if (response.latitude && response.longitude) {
                return response
            }
        }
        return null
    })
    return resp
}