import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'

const POST_INVOICE = "/api/carrier/postInvoice"
const GET_ALL_COVERED_LOADS = "/api/carrier/getAllCoveredLoadsWithInvoices"
const FETCH_LINE_ITEMS = "/api/carrier/getInvoiceLineItemTypes"


export const PostInvoice = (payload) => {

    const myAuthService = useAuthenticationHook()
    const url = BACKEND_URL + POST_INVOICE

    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject) 
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + POST_INVOICE,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data:  JSON.stringify(payload)
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + POST_INVOICE,
            message: {
                error: JSON.stringify(error)
            },
            data:  JSON.stringify(payload)
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const GetAllCoveredLoads = (advantageId) => {

    const myAuthService = useAuthenticationHook()
    const url = BACKEND_URL + GET_ALL_COVERED_LOADS

    try {
        let requestObject = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(advantageId)
          }

        let newRequestObject = myAuthService.onBeforeApiCall(requestObject) //todo: This may not work with form data type
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + GET_ALL_COVERED_LOADS,
                    message: {
                        error: JSON.stringify(error)
                    }
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + GET_ALL_COVERED_LOADS,
            message: {
                error: JSON.stringify(error)
            }
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const FetchLineItems = (advantageId) => {

    const myAuthService = useAuthenticationHook()
    const url = BACKEND_URL + FETCH_LINE_ITEMS

    try {
        let requestObject = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(advantageId)
          }

        let newRequestObject = myAuthService.onBeforeApiCall(requestObject) //todo: This may not work with form data type
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + FETCH_LINE_ITEMS,
                    message: {
                        error: JSON.stringify(error)
                    }
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + FETCH_LINE_ITEMS,
            message: {
                error: JSON.stringify(error)
            }
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}
