import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import '../style.css';
import { Modal } from 'react-bootstrap';

export class FormFields extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDoc: false,
            docSrc: null
        }

        this.testConnBox = this.testConnBox.bind(this)
        this.inputBox = this.inputBox.bind(this)
        this.renderDoc = this.renderDoc.bind(this)
        this.hideDoc = this.hideDoc.bind(this)

          // The first commit of Material-UI
        //const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const id = target.id.indexOf('radio') > -1? 'asset_type' : target.id
        
        this.props.onChange(value, id);
        
    }

    handleTestConnection = () => {

        this.props.handleTestConnection();
    }

    testConnBox = (id, key) => {
        return (
            <div className={(this.props.paramsCount && this.props.items[key].hideForNonLoggedIn) || (!this.props.paramsCount && this.props.items[key].hideForLoggedIn)?'hideForNonLoggedIn':''} style={{ width: '50%', float: 'left', marginBottom: '10px' }}>
                <label htmlFor={id}><span style={{ verticalAlign: 'middle' }}>{this.props.items[key].displayName}:</span>
                <span style={{ color: '#e04562', fontSize: '17px', paddingLeft: '.2em', verticalAlign: 'top'}}>{this.props.items[key].required?'*':''}</span> 
                {this.props.items[key].instructionTxt && <Tooltip title={
                    <React.Fragment>
                        <Typography style={{ color: '#fff', fontSize: '12px' }} color="inherit">
                            {this.props.items[key].instructionTxt}
                        </Typography>
                    </React.Fragment>} placement="right" arrow>
                    <img src="/icon-help-circled.png" className="help-circled"/>
                </Tooltip>}
                </label>
                <span className="moreDetails">{this.createMoreDetails(this.props.items[key].moreDetails)}</span>
                <input
                    type={this.props.items[key].type}
                    id={id}
                    name={id}
                    value={this.props.payload[id] || ''}
                    onChange={this.handleInputChange}
                    readOnly={!this.props.items[key].editable}
                    required={this.props.items[key].required}
                    spellCheck="false"
                    //title={this.props.items[key].instructionTxt}
                />
            </div>
        )
    }

    radioButton = (id, key) => {

        return (
            <div className={(this.props.paramsCount && this.props.items[key].hideForNonLoggedIn) || (!this.props.paramsCount && this.props.items[key].hideForLoggedIn)?'hideForNonLoggedIn':''} style={{ width: '50%', float: 'left', marginBottom: '22px'}}>
                <label htmlFor={id}><span style={{ verticalAlign: 'middle' }}>{this.props.items[key].displayName}:</span>
                {/* {this.props.items[key].required && <span style={{ color: 'red', fontSize: '17px', paddingLeft: '.2em', verticalAlign: 'top' }}>*</span>} */}
                {this.props.items[key].instructionTxt && <Tooltip title={
                    <React.Fragment>
                        <Typography style={{ color: '#fff', fontSize: '12px' }} color="inherit">
                            {this.props.items[key].instructionTxt}
                        </Typography>
                    </React.Fragment>} placement="right" arrow>
                    <img src="/icon-help-circled.png" className="help-circled"/>
                </Tooltip>}
                </label> 
                <span className="moreDetails">{this.props.items[key].moreDetails}</span>
                <RadioButtonGroup
                    id={id}
                    name={id}
                    value={this.props.payload[id] || ''}
                    onChange={this.handleInputChange}
                    required={this.props.items[key].required}
                    defaultSelected='tractor'
                    style={{ display: 'inline-flex'}}>

                    <RadioButton
                        id="radioBtnTractor"
                        value="tractor"
                        label="Tractor"
                        style={{ display: 'inline-flex', marginTop: '10px'}}
                        //title={this.props.items[key].instructionTxt}
                    />
                    <RadioButton
                        id="radioBtnTrailer"
                        value="trailer"
                        label="Trailer"
                        style={{ display: 'inline-flex', marginTop: '10px'}}
                        //title={this.props.items[key].instructionTxt}
                    />
                </RadioButtonGroup>
            </div>
        )
    }

    inputBox = (id, key) => {
        return (
            <div className={(this.props.paramsCount && this.props.items[key].hideForNonLoggedIn)?'hideForNonLoggedIn':''} style={{ width: '50%', float: 'left', marginBottom: '10px' }}>
                <label htmlFor={id}><span style={{ verticalAlign: 'middle' }}>{this.props.items[key].displayName}:</span>
                <span style={{ color: '#e04562', fontSize: '17px', paddingLeft: '.2em', verticalAlign: 'top'}}>{this.props.items[key].required?'*':''}</span>               
                {this.props.items[key].instructionTxt && <Tooltip title={
                    <React.Fragment>
                        <Typography style={{ color: '#fff', fontSize: '12px'}} color="inherit">
                            {this.props.items[key].instructionTxt}
                        </Typography>
                    </React.Fragment>} placement="right" arrow>
                    <img src="/icon-help-circled.png" className="help-circled"/>
                </Tooltip>}
                </label>
                <span className="moreDetails">{this.createMoreDetails(this.props.items[key].moreDetails)}</span>
                <input
                    type={this.props.items[key].type}
                    id={id}
                    name={id}
                    value={this.props.payload[id] || ''}
                    onChange={this.handleInputChange}
                    readOnly={!this.props.items[key].editable}
                    required={this.props.items[key].required}
                    spellCheck="false"
                    //title={this.props.items[key].instructionTxt}
                />
            </div>
        )
    }

    switchDisplay = (id, key) => {
        let element = null
        if ((!this.props.hasCurrentProvider && id !== 'instruction') || (this.props.hasCurrentProvider && this.props.payload.client === 'addNewProvider')) {
            switch (id) {
                case 'vehicle_identity':
                    element = !this.props.emailClients.includes(this.props.payload.client) && this.testConnBox(id, key)
                    break
                case 'asset_type':
                    element = this.radioButton(id, key)
                    break
                default:
                    element = this.inputBox(id, key)
                    break
            }
        } else if (this.props.hasCurrentProvider) {
            /*if(id === 'vehicle_identity')
                element = this.testConnBox(id, key)
            if(this.props.payload.client === 'Samsara'){
                switch (id) {
                    case 'vehicle_identity':
                        element = !this.props.emailClients.includes(this.props.payload.client) && this.testConnBox(id, key)
                        break
                    case 'asset_type':
                        element = this.radioButton(id, key)
                        break
                }
            }*/    
        } 
        return element
    }

    createMoreDetails = (moreDetails) => {
        if(moreDetails){
            var link = <a href="#" name="instructionsLink" onClick={this.renderDoc}>video</a>
            var res = moreDetails.split("[video]")
            return <div>{res[0]}{link}{res[1]}</div>;
        }
        return null;
    }

    renderDoc(event) {
        event.preventDefault()
        const target = event.target

        let docSrc = this.props.links[this.props.payload.client]['instruction'].path
        if (docSrc !== (null || undefined)) {
            this.setState({
            docSrc: docSrc,
            showDoc: true
            })
        }
    }

    hideDoc(event) {
        this.setState({
            showDoc: false,
            showEmailModal: false
        })
    }

    render() {

        return (

            <div>
            <Modal
            show={this.state.showDoc}
            onHide={this.hideDoc}
            bsSize="lg"
            backdrop={false}
            centered>

            <Modal.Header closeButton />


            <Modal.Body style={{ padding: '0px', height: '85vh' }}>
                <iframe src={this.state.docSrc}
                width="100%"
                height="100%"
                id="link-modal"
                allowFullScreen></iframe>
            </Modal.Body>
            </Modal>
            
            <div className="form-fields">
                {Object.keys(this.props.items).map((key, index) => {
                    const id = `${this.props.items[key].apiName}`;
                    return (
                        <div key={index}>
                            {this.props.items[key].show && <div key={index}>

                                {this.switchDisplay(id, key)}

                            </div>}
                        </div>
                    )
                })}
            </div>

            </div>

        );
    }
}