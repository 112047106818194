import React, { useRef, useState, useEffect } from 'react';
import { compose } from 'recompose'

import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import muiThemeable from 'material-ui/styles/muiThemeable';

import * as FeatherIcon from 'react-feather';

import isMobile from '../../../HOC/Mobile.js'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";

// import './date-picker.css'

// import DatePicker from 'rsuite/lib/DatePicker';
// import 'rsuite/dist/styles/rsuite-default.css'; // or 'rsuite/dist/styles/rsuite-default.css'

import moment from "moment"

const DaysBackSelector = (props) => {

    const { muiTheme, isMobile, historicalDaysBack, setHistoricalDaysBack } = props

    const useStyles = makeStyles({
        formControl: {
            margin: 1,
            maxWidth: isMobile ? 250 : 500
        },
        menuItem: {
            fontSize: '16px',
            textAlign: 'center',
            cursor: "pointer",
            width: '100%'
        },
        dateMenuItem:{
            fontSize: '16px',
            cursor: "pointer"
        },
        selectfieldStyleOutline: {
            backgroundColor: 'white',
            // border: '1px solid #ccc',
            height: '60px',
            width: '180px',
            maxWidth: '180px',
            // margin: '0px',
            // backgroundColor: 'white',
            // paddingLeft: '10px',
            paddingRight: '0px',
            paddingBottom: '0px',
            fontSize: '16px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        popoverContainer: {
            padding: 15
        },
        textField: {
            border: '1px solid #ccc',
            height: '45px',
            width: isMobile ? null : 230,
            margin: '0px',
            overflow: 'visible',
            backgroundColor: 'white',
            paddingLeft: '10px',
            paddingRight: '10px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            '& p': {
                fontSize: '14px !important'
            },
        },
        MuiCalendarPopover: {
            '& .MuiPaper-root':{
                '& .MuiTypography-body1':{
                    fontSize:16
                },
                '& .MuiTypography-body2':{
                    fontSize:16
                },
                '& .MuiPickersCalendarHeader-dayLabel':{
                    fontSize:16
                },
                '& .MuiSvgIcon-root':{
                    width: 30,
                    height: 30
                },
                '& .MuiButton-textPrimary':{
                    fontSize: 16
                }
            }
        },
        calendarIcon: {
            '& svg': {
                height: '2em',
                width: '2em',
                position: 'absolute',
                top: -12,
                right: 15
            },
        },
        inputLabel: {
            fontSize: 20,
            cursor: 'pointer'
        }
    });

    const classes = useStyles()

    const [customDaysBack, setCustomDaysBack] = useState({ label: "From " + String(moment().subtract(historicalDaysBack,'d').format('MM/DD/YYYY')), value: historicalDaysBack })
    const [selectedDate, setSelectedDate] = useState(moment());

    //-- Updated calendar stuff
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const handleDateChangeEvent = (event) => {
        event.preventDefault();
        let dateMoment;
        dateMoment = moment(event.target.value, "YYYY-MM-DD");
        setSelectedDate(dateMoment);
        calculateCustomDaysBack(moment(dateMoment))
    };

    const HandleDateChange = (date) => {
        setSelectedDate(date);
        calculateCustomDaysBack(moment(date))
    };

    const handleSelectClose = () => {
        if(!isCalendarOpen){
            setIsSelectOpen(false)
        }
    };

    const calculateCustomDaysBack = (startDate) => {
        let diff = moment().diff(startDate, "days")
        setCustomDaysBack({ label: "From " + String(startDate.format('MM/DD/YYYY')), value: diff })
        setHistoricalDaysBack(diff)
    }

    const handleDaysBackChange = (event) => {
        event.preventDefault()
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (event.target.value === '' || re.test(event.target.value)) {
            setHistoricalDaysBack(event.target.value)
        }
    }

    return (
        <div>
            <FormControl variant="filled" className={classes.formControl}>
            <InputLabel shrink id="outlined-number" className={classes.inputLabel}>Date Range</InputLabel>
                <Select
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        },
                        getContentAnchorEl: null
                    }}
                    open={isSelectOpen}
                    onOpen={()=>setIsSelectOpen(true)}
                    onClose={()=>handleSelectClose()}
                    className={classes.selectfieldStyleOutline}
                    id="outlined-number"
                    labelId="outlined-number"
                    value={historicalDaysBack}
                    renderValue={ 
                        value => { 
                            return (value!=45 && value!=90 ? (customDaysBack.label) : (`Last ${value} Days`))
                        }
                    }
                    onChange={(event) => handleDaysBackChange(event)}
                >
                        {/* <DatePicker
                            oneTap
                            cleanable={false}
                            onChange={(date)=>handleDateChange(date)}
                            onClose={()=>setIsCalendarOpen(false)}
                            value={ selectedDate }
                            onClick={(e) => {setIsCalendarOpen(true); e.stopPropagation(); console.log("BG LOGS - DatePicker Click event detected!")}}
                            placeholder="mm/dd/yyyy"
                        /> */}  
                        { !isMobile ? 
                        (
                            <MenuItem 
                                className={classes.menuItem} 
                            >
                                <KeyboardDatePicker
                                disableToolbar
                                    open={isCalendarOpen}
                                    onOpen={() => setIsCalendarOpen(true)}
                                    onClose={() => setIsCalendarOpen(false)}
                                    margin={'none'}
                                    style={{zIndex:2, paddingLeft:14}}
                                    variant={'dialog'}
                                    label={'From Custom Date'}
                                    format="MM/DD/YYYY"
                                    value={selectedDate}
                                    onChange={HandleDateChange}
                                    className={classes.menuItem}
                                    KeyboardButtonProps={{
                                        className: classes.calendarIcon,
                                        'aria-label': 'change date',
                                    }}
                                    InputLabelProps = {{
                                        style: {paddingLeft:14, fontSize: 16}
                                    }}
                                    InputProps={{
                                            classes: { input: classes.dateMenuItem }, 
                                            readOnly:true,
                                            disableUnderline: true
                                    }}
                                    TextFieldComponent={(props) => (
                                        <TextField 
                                            {...props} onClick={(e) => {setIsCalendarOpen(true) ; e.stopPropagation()} } 
                                        />
                                    )}
                                    DialogProps={{
                                        className: classes.MuiCalendarPopover,          
                                    }}
                                />
                            </MenuItem>
                        )
                        :
                        (
                            <form noValidate>
                                <TextField
                                    id="date"
                                    style={{zIndex:2, paddingLeft:14}}
                                    label={<span style={{paddingLeft:14}}>From Custom Date</span>}
                                    type="date"
                                    onChange={handleDateChangeEvent}
                                    onClick={(e) => {e.stopPropagation()}}
                                    onKeyDown={(e) => { e.preventDefault()}}
                                    value={ selectedDate ? selectedDate.format('YYYY-MM-DD') : null}
                                    className={classes.menuItem}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            fontSize: 16,
                                        },
                                        required: false
                                    }}
                                    InputProps={{
                                        classes: { 
                                            input: classes.dateMenuItem
                                        }, 
                                        disableUnderline: true,
                                        style: {
                                            fontSize: 16
                                        }
                                    }}
                                />
                             </form>
                         )}
                    <MenuItem className={classes.menuItem} style={{zIndex:2}} value={45}>Last 45 Days</MenuItem>
                    <MenuItem className={classes.menuItem} style={{zIndex:2}} value={90}>Last 90 Days</MenuItem>
                </Select>
            </FormControl>
        </div>
    )

}



export default compose(
    muiThemeable(),
    isMobile(),
)(DaysBackSelector)