/*global google*/
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment';
import { handleApiErrors } from '../lib/api-errors'
import {toast} from 'react-toastify'
import {
  CALL_TMSPARTNER_DATA,
  CALL_TMSPARTNER_DATA_SUCCESS,
  CALL_TMSPARTNER_DATA_ERROR,
  UPDATE_TMSPARTNER_DATA,
  UPDATE_TMSPARTNER_DATA_SUCCESS,
  UPDATE_TMSPARTNER_DATA_ERROR,

} from './constants'

import {LOGIN_REQUESTING,} from '../login/constants'
import {getTMSPartner,updateTMSPartner} from '../api/tmspartnerdata'


// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* getTMSPartnerDataFlow (action) {
  try {
    yield put(showLoading())
    // here until the API async function, is complete!
    const response = yield call(getTMSPartner,action.integrationId, action.mcBroker)
    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
  //  console.log("line 62/... TMS sagas....", response)

    if(response.original && response.original.membership){
        let email = response.original.membership.email;
        let password = response.original.membership.hint;
        yield put({type: LOGIN_REQUESTING,  email, password})
    }
    yield put({ type: CALL_TMSPARTNER_DATA_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: CALL_TMSPARTNER_DATA_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* updateTMSPartnerDataFlow (action) {
  try {
    const {data}= action;
    yield put(showLoading())

    // here until the API async function, is complete!

    //console.log('....in line 63 in Sagas', data);
    const response = yield call(updateTMSPartner,data)
    //console.log("line 65/... TMS sagas....", response)
    if(response.original && response.original.membership){
        let email = response.original.membership.email;
        let password = response.original.membership.hint;
        yield put({type: LOGIN_REQUESTING,  email, password})
    }
    if(response && response.membership){

        let email = data.contact_email;
        let password = data.contact_password;
        yield put({type: LOGIN_REQUESTING,  email, password})
    }

    if(response.status){
        toast.success(response.message)
    }


    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.

    //yield put({ type: UPDATE_TMSPARTNER_DATA_SUCCESS, response })

    action.updateCallback()
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: UPDATE_TMSPARTNER_DATA_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the LOADS_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* getTMSPartnerWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(CALL_TMSPARTNER_DATA, getTMSPartnerDataFlow)
  yield takeLatest(UPDATE_TMSPARTNER_DATA, updateTMSPartnerDataFlow)

}

export default getTMSPartnerWatcher
