import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import HelpWrapper from '../../HelpWrapper'

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  
const MultiStateSelect = ({
    muiTheme,
    inputTransparentStyle,
    handleSelectedStates,
    existingStates,
    mobileView = false,
    placeholderMsg = "Select State(s)",
    isEditing = false,
    hasAnyLocation=false,
    allowMultiSelect = true,
    ...props }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            fontSize: '16px',
        },
        wrapper: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            lineHeight: '24px'
        },
        menuItem: {
            fontSize: '16px'
        },
        icon: {
            position: 'absolute',
            fontSize: '2.5rem',
            textAlign: 'right'
        },
        chips: {
            fontSize: '12px'
          },
        removePadding: {
            padding: '0 !important'
        }
    }));
    const classes = useStyles();

    // let outlined = inputOutlineStyle; // this variable controls the styling of the state select true outlines the input field and adjusts sizing

    let zonesData = [
        { id: 2, name: "Alabama", abbr: "AL", isSelected: false },
        { id: 3, name: "Alaska", abbr: "AK", isSelected: false },
        { id: 4, name: "Arizona", abbr: "AZ", isSelected: false },
        { id: 5, name: "Arkansas", abbr: "AR", isSelected: false },
        { id: 6, name: "California", abbr: "CA", isSelected: false },
        { id: 7, name: "Colorado", abbr: "CO", isSelected: false },
        { id: 8, name: "Connecticut", abbr: "CT", isSelected: false },
        { id: 9, name: "Delaware", abbr: "DE", isSelected: false },
        { id: 10, name: "District Of Columbia", abbr: "DC", isSelected: false },
        { id: 11, name: "Florida", abbr: "FL", isSelected: false },
        { id: 12, name: "Georgia", abbr: "GA", isSelected: false },
        { id: 13, name: "Hawaii", abbr: "HI", isSelected: false },
        { id: 14, name: "Idaho", abbr: "ID", isSelected: false },
        { id: 15, name: "Illinois", abbr: "IL", isSelected: false },
        { id: 16, name: "Indiana", abbr: "IN", isSelected: false },
        { id: 17, name: "Iowa", abbr: "IA", isSelected: false },
        { id: 18, name: "Kansas", abbr: "KS", isSelected: false },
        { id: 19, name: "Kentucky", abbr: "KY", isSelected: false },
        { id: 20, name: "Louisiana", abbr: "LA", isSelected: false },
        { id: 21, name: "Maine", abbr: "ME", isSelected: false },
        { id: 22, name: "Maryland", abbr: "MD", isSelected: false },
        { id: 23, name: "Massachusetts", abbr: "MA", isSelected: false },
        { id: 24, name: "Michigan", abbr: "MI", isSelected: false },
        { id: 25, name: "Minnesota", abbr: "MN", isSelected: false },
        { id: 26, name: "Mississippi", abbr: "MS", isSelected: false },
        { id: 27, name: "Missouri", abbr: "MO", isSelected: false },
        { id: 28, name: "Montana", abbr: "MT", isSelected: false },
        { id: 29, name: "Nebraska", abbr: "NE", isSelected: false },
        { id: 30, name: "Nevada", abbr: "NV", isSelected: false },
        { id: 31, name: "New Hampshire", abbr: "NH", isSelected: false },
        { id: 32, name: "New Jersey", abbr: "NJ", isSelected: false },
        { id: 33, name: "New Mexico", abbr: "NM", isSelected: false },
        { id: 34, name: "New York", abbr: "NY", isSelected: false },
        { id: 35, name: "North Carolina", abbr: "NC", isSelected: false },
        { id: 36, name: "North Dakota", abbr: "ND", isSelected: false },
        { id: 37, name: "Ohio", abbr: "OH", isSelected: false },
        { id: 38, name: "Oklahoma", abbr: "OK", isSelected: false },
        { id: 39, name: "Oregon", abbr: "OR", isSelected: false },
        { id: 40, name: "Pennsylvania", abbr: "PA", isSelected: false },
        { id: 41, name: "Rhode Island", abbr: "RI", isSelected: false },
        { id: 42, name: "South Carolina", abbr: "SC", isSelected: false },
        { id: 43, name: "South Dakota", abbr: "SD", isSelected: false },
        { id: 44, name: "Tennessee", abbr: "TN", isSelected: false },
        { id: 45, name: "Texas", abbr: "TX", isSelected: false },
        { id: 46, name: "Utah", abbr: "UT", isSelected: false },
        { id: 47, name: "Vermont", abbr: "VT", isSelected: false },
        { id: 48, name: "Virginia", abbr: "VA", isSelected: false },
        { id: 49, name: "Washington", abbr: "WA", isSelected: false },
        { id: 50, name: "West Virginia", abbr: "WV", isSelected: false },
        { id: 51, name: "Wisconsin", abbr: "WI", isSelected: false },
        { id: 52, name: "Wyoming", abbr: "WY", isSelected: false }
    ];

    // const [hasError, setErrors] = useState(false);
    const [states, setStates] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const selectBox = useRef(null)



    useEffect(() => {

        setStates(zonesData);

        if (existingStates) {
            setSelectedStates(existingStates)
        }


        return () => {
            setStates([]);
            setSelectedStates([]);
        }
    }, []);

    function handleChange(event) {
        // 'value' here represents the truck type ids selected from dropdown as in the value of the event target
        const { value } = event.target;
        let chosenStates = value
        if(!Array.isArray(value))
            chosenStates = [value]
            
        if(value.includes('ANYWHERE') && value.indexOf('ANYWHERE')>0  ){
            chosenStates = ['ANYWHERE']
        }
        if(selectedStates.length>0 && value.includes('ANYWHERE') && value.indexOf('ANYWHERE')== 0  ){
            chosenStates.splice(0, 1)
        }
        setSelectedStates(chosenStates);
        handleSelectedStates(chosenStates)
    };

    const handleDelete = (stateToDelete) => () => {
        let modifiedStates = [...selectedStates]
        modifiedStates = modifiedStates.filter((state) => state !== stateToDelete);
        setSelectedStates(modifiedStates);
        handleSelectedStates(modifiedStates);
      };

    function renderSelectedStates(selected) {
        let chipMax = 3
        if(selectBox.current){
          chipMax = selectBox.current.clientWidth <= 300 ? 3 : selectBox.current.clientWidth <= 400 ? 4 : selectBox.current.clientWidth <= 600 ? 6 : 8
        }

        if (!isEditing && allowMultiSelect) {
            let valuesArr = selectedStates ? selectedStates : [];
            // let values = selectedStates ? selectedStates.join() : ""; 
            let values = selected.map((value, index) => {
                if(mobileView & index >= chipMax)
                  return index === chipMax ? <span style={{margin: 5, fontSize: 18}}>&hellip;</span> : null
                return(
                    <Chip 
                        variant="outlined" 
                        key={value} 
                        label={value} 
                        className={classes.chips} 
                        onDelete={handleDelete(value)}
                        onMouseDown={(event) => {
                            event.stopPropagation();
                        }}
                    />
                )
            }) 

            if (valuesArr.length === 0) {
                return (
                    <div className={classes.wrapper} style={{ fontSize: 14, color: "#aaa" }}>
                        {placeholderMsg}
                    </div>
                )
            } else {
    
                return (
                    <div className={classes.wrapper} style={{fontSize: 16}}>
                        {
                            values
                        }
                    </div>
                )
            }
        } else {
            if (selectedStates === null) {
                return (
                  <div className={classes.wrapper} style={{ fontSize: 14, color: "#aaa" }}>
                    {
                      'Select State'
                    }
                  </div>
                )
              }
              else {
                return (
                  <div className={classes.wrapper} style={{fontSize: 16}}>
                    {
                      selectedStates
                      /* <Chip variant="outlined" key={selectedStates} label={selectedStates} className={classes.chips} /> */
                    }
                  </div>
                )
              }
        }
    }

    return (
        <HelpWrapper text='Select the destination states you are interested in finding loads toward.(Click anywhere outside once finished)' location={mobileView ? 'bottom' : 'bottom'} zIndex={1350} enabled={!menuOpen} isMultiSelect={allowMultiSelect}>
            <FormControl variant="filled" size='small' className={classes.formControl} fullWidth required >
                <InputLabel style={{fontSize:16}} id="location">{placeholderMsg}</InputLabel>
                <Select
                    ref={selectBox}
                    // className={classes.selectfieldStyleOutline}
                    // Menu props in place to avoid menu swashing around on each update
                    MenuProps={{
                        getContentAnchorEl: () => null,
                    }}
                    // Will need to play around with this 'multiple' prop for when we introduce the different options 
                    multiple={allowMultiSelect}
                    labelId="select-label"
                    //disableUnderline={true}
                    //autoWidth={true}
                    //displayEmpty
                    value={selectedStates}
                    onChange={(event) => handleChange(event)}
                    renderValue={(selected) => renderSelectedStates(selected)}
                    // style={{ minWidth: mobileView ? '100%' : 180 }}
                    // inputProps={{
                    //     classes: {
                    //         icon: classes.icon,
                    //         root: classes.removePadding
                    //     },
                    // }}
                    // MenuProps={{
                    //     disableScrollLock: true,
                    //     getContentAnchorEl: null,
                    //     anchorOrigin: {
                    //         vertical: "bottom",
                    //         horizontal: "left",
                    //     }
                    // }}
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}
                >
                    {/* <MenuItem
                        className={classes.menuItem}
                        style={{ color: "#aaa" }}
                        value=""
                        disabled>
                        {placeholderMsg}
                    </MenuItem> */}
                    {hasAnyLocation && (
                        <MenuItem
                            className={classes.menuItem}
                            value="ANYWHERE"
                        >
                            <b>{'ANYWHERE'}</b>
                        </MenuItem>
                    )}
                    {states.map((state) => {
                        return (
                            <MenuItem
                                key={state.id}
                                value={state.abbr}
                                className={classes.menuItem}
                            >
                                {allowMultiSelect &&
                                    <Checkbox checked={selectedStates.indexOf(state.abbr) > -1} />
                                }
                                <p className={classes.menuItem}> {state.name} </p>
                            </MenuItem>)
                    })}
                </Select>
                </FormControl>
        </HelpWrapper>
    )
}

export default MultiStateSelect;