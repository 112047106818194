
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import { Col, Row } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';
import { toast } from 'react-toastify'

import moment from 'moment'
import { checkValueExists } from "../../../../../../lib/check-value-exists"

import { titleCaseFormatter } from "../../../../../../lib/title-case-formatter"

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
import { get } from 'jquery';
// import {useAuthenticationHook} from '../../../../hooks/authentication-hook';


library.add(
    faBiohazard
);

const MyLoadCard = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        isExpanded,
        muiTheme,
        key,
        pushReloads,
        reloadsOpen,
    } = props

    const [isHazmat, setIsHazmat] = useState(false)

    // const myAuthService = useAuthenticationHook()
    // const isAuthenticated = myAuthService.isAuthenticated()
    // const user = myAuthService.getUser()

    const [anchorEl, setAnchorEl] = useState(null);
    const openRateBreakdown = Boolean(anchorEl);
    const id = openRateBreakdown ? 'rate-info-popover' : undefined;

    const handleRateOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleRateClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                setIsHazmat(true)
        }
    }, [])

    const getExpenseRate = (breakDown) => {
        if(breakDown.length === 1){
            return breakDown[0].ledgerAmount
        }else{
            let total = 0;
            breakDown.forEach(element => {
                total += element.ledgerAmount
            });
            return total
        }
    }

    return (

        <div>

            <Row style={{ display: 'flex' }}>

                <Col style={{ display: 'flex', width: '11%' }}>
                    {checkValueExists(load, "loadNumber") && (

                        <Col className={classes.centerColContent}>
                            <p className={classes.mainText}>{load.loadNumber}</p>
                        </Col>

                    )}
                </Col>

                <Col style={{ display: 'flex', width: "15%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>


                        <p className={classes.mainText} >
                            {reloadsOpen ?
                                titleCaseFormatter(pickup.city) + ', ' + pickup.state
                                :
                                titleCaseFormatter(load.origin.city) + ', ' + load.origin.state
                            }
                        </p>

                    </Col>
                </Col>

                <Col style={{ width: "12%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <Row>
                            <p className={classes.mainText}>
                                {moment(load.pickupDate).format('ll')}
                            </p>
                            <p className={classes.mainText}>
                                {moment(load.pickupDate, "hh:mm a").format('HH:mm')}
                            </p>
                        </Row>
                    </Col>
                </Col>


                <Col style={{ width: "15%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <p className={classes.mainText} >
                            {reloadsOpen ?
                                titleCaseFormatter(delivery.city) + ', ' + delivery.state
                                :
                                titleCaseFormatter(load.destination.city) + ', ' + load.destination.state
                            }
                        </p>
                    </Col>
                </Col>

                <Col style={{ width: "12%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        {load.deliveryDate /*&& load.deliveryTime*/ && (
                            <Row>
                                <p className={classes.mainText}>
                                    {moment(load.deliveryDate).format('ll')}
                                </p>
                                <p className={classes.mainText}>
                                    {moment(load.deliveryDate, "hh:mm a").format('HH:mm')}
                                </p>
                            </Row>
                        )}
                    </Col>
                </Col>


                <Col style={{ width: "10%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>

                    </Col>
                </Col>

                <Col style={{ width: "13%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'flex-start', paddingLeft: 15 }}>

                        <Row style={{ display: 'inline-flex' }}>
                            {load.expenseLedgerBreakDown && load.expenseLedgerBreakDown.length > 0 &&
                                <div>
                                    <p className={classes.mainText} >
                                        ${getExpenseRate(load.expenseLedgerBreakDown).toFixed(2)}
                                        <FeatherIcon.Info
                                            style={{position:'absolute', marginLeft:'5px'}}
                                            color={"black"}
                                            size={20}
                                            onMouseEnter={handleRateOpen}
                                            onMouseLeave={handleRateClose}
                                        />
                                    </p>

                                    <Popover
                                        id={id}
                                        open={openRateBreakdown}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                        style={{ pointerEvents: 'none' }}
                                        disableRestoreFocus
                                    >
                                        <div style={{ padding: 15, width: 250, maxHeight: 300 }}>
                                            <Row style={{ width: '100%', marginBottom: 5 }}>
                                                <Col style={{ width: '100%' }}>
                                                    <p style={{ fontSize: 16, fontWeight: 'bold' }}>
                                                        Rate Breakdown
                                                        </p>
                                                </Col>
                                            </Row>
                                            {Object.keys(load.expenseLedgerBreakDown).map((key, index) => {
                                                return (
                                                    <div key={index}>
                                                        {load.expenseLedgerBreakDown[key] &&
                                                            <div key={index} >

                                                                <Row style={{ display: 'inline-block', width: '100%' }}>
                                                                    <Col style={{ display: 'inline-block' }}>
                                                                        <p style={{}}>
                                                                            {load.expenseLedgerBreakDown[key].ledgerType}
                                                                        </p>
                                                                    </Col>
                                                                    <Col style={{ display: 'inline-block', float: 'right' }}>
                                                                        <p style={{}}>
                                                                            ${parseFloat(load.expenseLedgerBreakDown[key].ledgerAmount).toFixed(2)}
                                                                        </p>
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Popover>
                                </div>

                            }

                        </Row>
                    </Col>
                </Col>

                {/* <Col style={{ width: "12%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <div>
                            <Row key={key + "booked_action_row"} >
                                <Button
                                    aria-describedby={"upload_document"}
                                    // variant={variantOutlined ? "outlined" : variantContained ? "contained" : null}
                                    onClick={() => toast.info("UNDER CONTRUCTION")}
                                    style={{
                                        fontSize: 14,
                                        color: "white",
                                        backgroundColor: muiTheme.actionColor,
                                        borderColor: muiTheme.actionColor,
                                        margin: 5
                                    }}
                                    size={"small"}
                                    disabled={false}
                                    disableRipple={false}
                                >
                                    Upload Document
                                    </Button>
                            </Row>
                        </div>
                    </Col>
                </Col> */}

            </Row>

        </div>

    );
}


export default compose(
    connect(),
    muiThemeable()
)(MyLoadCard)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '16px',
        lineHeight: '22px',
        color: 'black',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    }
});