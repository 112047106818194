import React, { useRef, useEffect, useState } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import LoadMatchesList from '../../CarrierPortal/DirectLinking/AvailabilityLoads/indexV2'


const FindAndBookLoadsScreen = (props) => {


    return (
        
            <LoadMatchesList
                {...props}
                // availabilityId={this.state.selectedAvailability}
                hideDashLink
                // showEditAvailabilityButton={true}
                // onEditButtonClick={(availability, driver) => { this.showEditSearchbox() }}
                isPaginationEnabled={true}
                containerStyle={{ paddingLeft: 4, height: '100%' }}
            />
     
    )
}

const mapStateToProps = state => ({
    
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(FindAndBookLoadsScreen)