import $ from 'jquery'
export function parseEmail(text,signatureStart){
    var requestUrl = 'http://capacity-parser-prod.pziay3yp42.us-east-1.elasticbeanstalk.com/availabilities'
    var payload={
      email_body:text
    }
    if(signatureStart){
      payload.signature_start=signatureStart
    }

   return fetch(requestUrl,{
                            method: 'Post',
                            headers: {
                              'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            body:$.param(payload)
                          })
          .then(response => {
            if (!response.ok) {
              const error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
            return response.json();
          });
}
