import {
			CALL_PREFERENCES_DATA,
			UPDATE_PREFERENCES_DATA,
		} from './constants'

export function getPreferencesDetail (email) {
	//console.log('...',email);
  return {
    type: CALL_PREFERENCES_DATA,
		email
  }
}
export function updatePreferences (data,updateCallback) {
  return {
    type: UPDATE_PREFERENCES_DATA,
    data,
    updateCallback:updateCallback,
  }
}
