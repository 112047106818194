
import React, { useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Col, Row } from "react-bootstrap"
import FA from 'react-fontawesome'
import useGtagHook from "../../../../../../gtag-hook"
import Button from '@material-ui/core/Button';
import RateNegotiationsListItem from './RateNegotiationsListItem';

const RateNegotiationsListMobile = (props) => {

    const {
        quoteList,
        load,
        notInterested,
        setQuotePage,
        showAddQuoteBtn,
        fetchAllQuotes,
        buildingList,
        isLoading,
        userProfile,
        muiTheme,
        callRefreshCard
    } = props

    const classes = useStyles();
    const [sendGtagEvent] = useGtagHook()

    useEffect(()=>{
        if(quoteList.length <= 0){
            setQuotePage("form");
            sendGtagEvent("open_quote", { loadId: load.loadId, brokerId: load.brokerId })
        }
    },[])

    return (
        <Col style={{ height: '100%' }}>

            <Col style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                <Col style={{ height: showAddQuoteBtn ? 'auto' : '15%', width: '100%', paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
                    <Col style={{ width: '70%', display: 'inline-flex' }}>
                        <p className={classes.mainText} style={{ width: '100%', fontSize: 16 }}>Offer History - Load #{load.externalId}</p>
                    </Col>
                    {/* {!showAddQuoteBtn && quoteList.length > 0 &&
                        <Col style={{ width: '30%', display: 'inline-flex', flexDirection: 'column' }}>
                            <Button
                                variant="contained"
                                style={{
                                    color: muiTheme.actionColor,
                                    backgroundColor: 'transparent',
                                    borderColor: muiTheme.actionColor,
                                    border: '1px solid',
                                    fontSize: 14
                                }}
                                onClick={() => {
                                    notInterested()
                                    //todo: add GA event log
                                }}
                            >Not Interested
                            </Button>
                        </Col>
                    } */}
                </Col>
                {/* {showAddQuoteBtn &&
                    <Col style={{ height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 25 }}>
                        <Button
                            variant="contained"
                            style={{
                                color: "white",
                                backgroundColor: muiTheme.actionColor,
                                borderColor: muiTheme.actionColor,
                                fontSize: 14
                            }}
                            onClick={() => {
                                setQuotePage("form");
                                sendGtagEvent("open_quote", { loadId: load.loadId, brokerId: load.brokerId })
                            }}
                        >
                        { load.isPreviewLoad && quoteList.length <= 0 ? "Show Interest":"Add A Quote"}
                        </Button>
                    </Col>
                } */}
                {!isLoading && !buildingList ?
                    <Col style={{ height: '85%', }}>
                        {quoteList.length > 0 &&
                            <Col style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden', maxHeight: '85%', paddingBottom: 100 }}>

                                {
                                    quoteList.map((quote, index) => {
                                        if (quote.quote_value != null) {
                                            
                                            return (

                                                <Row key={index+"_rate_item"} style={{ display: 'flex', borderBottom: index !== quoteList.length - 1 ? '1px dashed black' : 'none', paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}>
                                                    <RateNegotiationsListItem
                                                        quote={quote}
                                                        load={load}
                                                        userProfile={userProfile}
                                                        latestOffer={index === 0 ? true : false}
                                                        fetchAllQuotes={fetchAllQuotes}
                                                        callRefreshCard={callRefreshCard}
                                                        {...props}
                                                    />
                                                </Row>
                                            )
                                        } else
                                            return null

                                    })
                                }

                            </Col>

                        }
                    </Col>
                    :
                    <Col style={{ width: '100%', paddingTop: 25 }}>

                        <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                            <FA name='spinner' spin />
                        </p>

                    </Col>
                }

            </Col>

        </Col>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiationsListMobile)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    }
});