const initialState = {
  requesting: false,
  successful: false,
  availabilities:[],
  sourcingAvails:[],
  carriers:[],
  carrierHistory:[],
  messages: [],
  errors: [],
}

const reducer = function loginReducer (state = initialState, action) {
  switch (action.type) {
    case "REMOVE_AVAIL_REQ":
      var retValue = JSON.parse(JSON.stringify(state))
      retValue.availabilities = retValue.availabilities.filter((avail)=>avail.id!=action.targetAvailId)
      return retValue
    case "SEARCH_AVAILS_REQ":
      var retValue = {
        ...state,
        requesting:true,
        availabilities:[],
        carriers:[],
        carrierHistory:[],
        successful:false
      }
      return retValue

    case "SEARCH_AVAILS_SUC":
      var retValue = {
        ...state,
        requesting:false,
        successful:true,
      }
      if(action.availabilities){retValue.availabilities=action.availabilities}
      if(action.carrierHistory){retValue.carrierHistory=action.carrierHistory}
      return retValue

    case "SEARCH_AVAILS_ERR":
      var retValue = {
        ...state,
        requesting:false,
        successful:false
      }
      return retValue

    case "CARRIER_SOURCING_REQ":
      var retValue = JSON.parse(JSON.stringify(state))
      retValue.requesting=action.silent?false:true
      retValue.successful=false
      return retValue;
    case "CARRIER_SOURCING_SUC":
      var retValue =JSON.parse(JSON.stringify(state))
      retValue.requesting=false
      retValue.successful=true
      retValue.sourcingAvails = action.availabilities
      retValue.carriers = action.carriers
      return retValue;
    case "CARRIER_SOURCING_ERR":
      var retValue = JSON.parse(JSON.stringify(state))
      retValue.requesting=false
      retValue.successful=false
      return retValue;

    default:
      return state
  }
}

export default reducer
