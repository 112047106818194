/*global google*/
import React from 'react';
import {bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import GooglePlaceAutocomplete from 'material-ui-places';
import {withState,compose} from 'recompose'
import PropTypes from 'prop-types';
import {updateComponent} from '../../../CarrierPortal/ActionReducers/components'

const AddressAutocomplete = ({searchText,updateSearchText,onChange,value,status,updateComponent,inputFloatingLabelText='Truck Type',...props}) => {
    const styles={
      width:'100%'
    }
    function handleAddressSelect(selectedAddress){
      if(!selectedAddress){
        var payload ={
            address:false,
            city:false,
            state:false,
            zipCode:false,
            formattedAddress:searchText,
            lat:null,
            lon:null,
          }
          onChange(payload)
        
        return false;
      }
      let placeId = selectedAddress.place_id;
      var geocoder = new google.maps.Geocoder
      geocoder.geocode(
        {'placeId': placeId},
        (location)=>{
          console.log(location,location[0].geometry.location.lat(),location[0].geometry.location.lng())


          var city = location[0].address_components.filter(
              (component)=>{
                if(component.types.indexOf("locality")>=0)
                  return component.short_name
              }
            );

          var state = location[0].address_components.filter(
              (component)=>{
                if(component.types.indexOf("administrative_area_level_1")>=0)
                  return component.short_name
              }
            );

          var payload = {
            city:city.length>0?city[0].short_name:false,
            state:state.length>0?state[0].short_name:false,
            lat:location[0].geometry.location.lat()?location[0].geometry.location.lat():null,
            lon:location[0].geometry.location.lng()?location[0].geometry.location.lng():null,
            formattedAddress:location[0].formatted_address?location[0].formatted_address:false
          }
          var zipCodeAddressComponent =
            location[0].address_components.filter(
              (component)=>{
                if(component.types.indexOf("postal_code")>=0)
                  return component.short_name
              }
            )
          if(zipCodeAddressComponent.length)
            payload.zipCode = zipCodeAddressComponent[0].short_name

          var streetNumberAddressComponent =
              location[0].address_components.filter(
                (component)=>{
                  if(component.types.indexOf("street_number")>=0)
                    return component.short_name
                }
              )
          var streetNameAddressComponent =
              location[0].address_components.filter(
                (component)=>{
                  if(component.types.indexOf("route")>=0)
                    return component.short_name
                }
              )
          var address=''
          if(streetNumberAddressComponent.length)
            address+=streetNumberAddressComponent[0].short_name+" "
          if(streetNameAddressComponent.length)
            address+=streetNameAddressComponent[0].short_name
          if(address=='')
            address=null
          payload.address=address
          onChange(payload)
        }
      );
    }
    function getSearchText(){
      if(searchText===null || searchText===undefined || searchText===false){
        return value
      }else{
        if(searchText=='' && !status.showError){
          var payload ={
            address:false,
            city:false,
            state:false,
            zipCode:false,
            formattedAddress:false,
            lat:null,
            lon:null,
          }
          onChange(payload)
          updateComponent('searchBoxSettings',{error:{address:{showError:true}}})
        }
          return searchText
      }
    }
    return (
        <GooglePlaceAutocomplete
            floatingLabelText={"Pickup Location (Optional)"}
            label='Pickup Location'
            //onPlaceSelected={(place)=>{console.log(place)}}
            types={['geocode']}
            //onKeyPress={e => {if (e.key === 'Enter') e.preventDefault();}}
            searchText={getSearchText()}
            restrictions={{
                country:['US','MX','CA']
            }}
            onChange={(e)=>{
                console.log(e);
                var value = e.target.value
                updateSearchText(value);
                updateComponent('searchBoxSettings',{error:{address:{showError:false}}});
            }}
            onNewRequest={(selectedAddress)=>handleAddressSelect(selectedAddress)}
            name={'location'}
            fullWidth={true}
            errorText={false}
            maxSearchResults={1}
        />
    )
}

AddressAutocomplete.propTypes={
  onChange:PropTypes.func//Returns Selected Object of Autocomplete
}

const mapStateToProps = state => ({
  status:state.CarrierPortal.components.searchBoxSettings.error.address,
})

function matchDispatchToProps(dispatch){
  return  bindActionCreators({
    updateComponent:updateComponent,
  },dispatch)
}

export default compose(
  connect(mapStateToProps,matchDispatchToProps),
  withState('searchText', 'updateSearchText', ({value})=>value?value:null)
)(AddressAutocomplete)
