import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'

// const BACKEND_URL = "http://scapi-dev.truckertools.com/api" //TODO: connect to prod
const POST_ACTION_URL = "/api/carrier/actionsActivityLog"

export const LogActionsActivity = (dynamicParams) => {

    let myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + POST_ACTION_URL
    const requestParameters = {
        // screenName: "LOADS",
        matchId: null,
        matchContext: null,
        unavailableReason: null,
        unavailableText: null,
        fromApp: false,
    }

    const params = { ...dynamicParams, ...requestParameters }
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            }).catch(error => {
                let payload = {
                    source: "URL: " + POST_ACTION_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + POST_ACTION_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

