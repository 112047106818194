import React, { useEffect, useState } from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../../HOC/Mobile.js'

import { Row, Col, } from 'react-bootstrap';
import TruckTypeMultiSelect from '../TruckTypeMultiSelect';
import AddressPicker,{defaultDataLoc} from '../AddressPicker';
import RateSet ,{defaultDataRate} from '../RateSet';
import AvailableDateTimePicker,{defaultDataDate} from "../AvailableDateTimePicker"
import { checkValueExists } from '../../../../../lib/check-value-exists';
import {resetInitValue} from "../AvailableDateTimePicker"
import BrokerMultiSelect from '../BrokerMultiSelect';
import {canShowBrokers} from '../../../../../CarrierPortal/components/preferences/components/BrokerMultiSelect'

const CapacityFormUI = (props) => {

    const { muiTheme,  initialValues = null,    isMobile = false,onFormComplete=null} = props

   
    let defaultData = {
                        originLocation:defaultDataLoc,
                        destinationLocation:defaultDataLoc,
                        rate:defaultDataRate,
                        date:defaultDataDate(),
                        equipmentType:null,
                    }


    const isEditing = initialValues !== null ? true : false
    
    const [truckType, setTruckType] = useState(null);
    const [isOriginAnywhere, setIsOriginAnywhere] = useState(false);
    const [isDestinationAnywhere, setIsDestinationinAnywhere] = useState(false);
    

    const [marginBottomValue, setMarginBottomValue] = useState(10);
    const [marginTopValue, setMarginTopValue] = useState(0);
    const [leftPaddingValue, setLeftPaddingValue] = useState(0);
    const [spacingPadding, setSpacingPadding] = useState(5);
    const [divPadding, setDivPadding] = useState('');

    const [formPayload, setFormPayload] = useState(defaultData);
    
    useEffect(() => {
        setMarginBottomValue(isMobile?0:10)
        setMarginTopValue(isMobile?10:0)
        setSpacingPadding(isMobile?0:5)
        setLeftPaddingValue(5)
        setDivPadding(isMobile?'0px 0px':'0px 5px')
        
    }, [isMobile])

    useEffect(() => {
        if (initialValues !== null) {
                initialValues.equipmentType = initialValues.truckTypes
                initialValues.originLocation = initialValues.origin
                initialValues.destinationLocation = initialValues.destination
                initialValues.equipmentType = initialValues.truckTypes
                

                let date = initialValues.availableAt;
                date = resetInitValue(date)
                initialValues.date= date
                setFormPayload(initialValues)
                setTruckType(initialValues.truckTypes)
                if(onFormComplete){
                    onFormComplete(initialValues)
                }

        }
    }, [initialValues])

    
 

    const handleOriginLocationPick = (value) => {
        popuateFormPayload({originLocation:value})
        if(value.locationSelectType=='CUSTOM' && checkValueExists(value,'locationCustom') && value.locationCustom.length>0){
            let locationCustom = value.locationCustom
            if(locationCustom.includes('ANYWHERE')){
                setIsOriginAnywhere(true)
            }else{
                setIsOriginAnywhere(false)
            }
        }
        else if(value.locationSelectType=='ZONE' && checkValueExists(value,'locationZone')){
            let locationZone = value.locationZone
            if(locationZone.abbr == 'ANYWHERE'){
                setIsOriginAnywhere(true)
            }else{
                setIsOriginAnywhere(false)
            }
        }
        else{
            setIsOriginAnywhere(false)
        }
    };

    const handleDestinationLocationPick = (value) => {
        popuateFormPayload({destinationLocation:value})
        if(value.locationSelectType=='CUSTOM' && checkValueExists(value,'locationCustom') && value.locationCustom.length>0){
            let locationCustom = value.locationCustom
            if(locationCustom.includes('ANYWHERE')){
                setIsDestinationinAnywhere(true)
            }else{
                setIsDestinationinAnywhere(false)
            }
        }
        else if(value.locationSelectType=='ZONE' && checkValueExists(value,'locationZone')){
            let locationZone = value.locationZone
            if(locationZone.abbr == 'ANYWHERE'){
                setIsDestinationinAnywhere(true)
            }else{
                setIsDestinationinAnywhere(false)
            }
        }
        else{
            setIsDestinationinAnywhere(false)
        }
    };

    const handleSetTruckType = (value) => {
        setTruckType(value);
        popuateFormPayload({equipmentType:value})
    };



    
    const handleRate = (value) => {
        popuateFormPayload({rate:value})
    };
    
    const handleDate = (value) => {
        popuateFormPayload({date:value})
    };

    
    const handleSetBrokers = (value) => {
        popuateFormPayload({brokerList:value})
    };
    
    

    const popuateFormPayload = (incomingData)=>{
        let payload = {...formPayload,...incomingData}
        setFormPayload(payload)

        if(onFormComplete){
            onFormComplete(payload)
        }
    }


    const originUI =(
            <Col style={{paddingRight:spacingPadding,paddingLeft:leftPaddingValue}} xs={12} md={6}  >
                    <AddressPicker hasAny={!isDestinationAnywhere} locationType={'Origin'} initialValues={formPayload.origin} showTypeSelectOptions={false} onSetCallBack={handleOriginLocationPick} />
            </Col>
        )


    const destinationUI =(
        <Col style={{paddingRight:spacingPadding,paddingLeft:leftPaddingValue}} xs={12} md={6}  >
                <AddressPicker hasAny={!isOriginAnywhere} locationType={'Destination'} initialValues={formPayload.destination} onSetCallBack={handleDestinationLocationPick} locationSelectTypeAttr="ZONE" showSpecific={false}  />
        </Col>
    )


    const truckSelectUI = (
        <Col xs={12} md={6} style={{paddingRight:spacingPadding,marginTop:marginTopValue,paddingLeft:leftPaddingValue}}    >
            <TruckTypeMultiSelect handleSetTruckType={handleSetTruckType} existingTruckTypes={formPayload.equipmentType} />
        </Col>
    )

    const rateUI = (
        <Col xs={12} md={6} style={{marginTop:marginTopValue,paddingLeft:!isMobile?spacingPadding:leftPaddingValue }}    >
            <RateSet initialValues={formPayload.rate} onSetCallBack={handleRate} />
        </Col>
    )


    const brokerSelectUI = ()=>{
        let ui = null
        if(canShowBrokers()){
            ui = (
                <Row style={{height:50,marginTop:marginBottomValue,width:'100%'}}  >
                    <Col xs={12} md={6} style={{paddingRight:spacingPadding,marginTop:marginTopValue,paddingLeft:leftPaddingValue}}    >
                        <BrokerMultiSelect handleSetBroker={handleSetBrokers} existingBrokers={formPayload.brokerList} />
                    </Col>
                </Row>
            )
        }
        return ui
    }

    return (
        <Col style={{padding: divPadding}} >
            <Row style={{height:90,marginBottom:marginBottomValue,width:'100%',display:isMobile?'block':'flex',alignItems:'flex-end'}} >
                {originUI}
                {destinationUI}
            </Row>
            <Row style={{height:50,marginBottom:marginBottomValue,width:'100%'}}  >
                {truckSelectUI}
                {rateUI}
            </Row>
            <AvailableDateTimePicker  initialValues={formPayload.date} onSetCallBack={handleDate}  />
            {brokerSelectUI()}
        </Col>
    )
}


export default compose(
    muiThemeable(),
    isMobile()
)(CapacityFormUI)