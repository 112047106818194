
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import GoogleMapReact from 'google-map-react';
import MapRoute from '../../../maproute'
import numeral from 'numeral'
import LoadDetailsTabs from './loadDetailsTabs'
import * as GeoLocationApi from '../../../.././../apis/api-geo-location';

const LoadDetailsMobileContent = (props) => {

    const {
        load,
        pickup,
        delivery,
        availability,
        isMyLoadsScreen = false,
        myLoadsRenderedTab = 0,
        loadDetailsRenderedTab = 0,
        latestQuote = null,
        latestQuoteShowBIN = false,
        callRefreshCard,
        onInvoicesScreen,
        onPreviewScreen
    } = props

    const [mapLoaded, setMapLoaded] = useState(false)
    const [map, updateMap] = useState(null)
    const [maps, updateMaps] = useState(null)
    const [processOnProgress, updateProcessOnProgress] = useState(false)
    const [notRendered, updateNotRendered] = useState(true)
    const [delay, updateForceDelay] = useState(false)
    const [googleMapsDuration, updateGoogleMapsDuration] = useState('')
    const [googleMapsDistance, updateGoogleMapsDistance] = useState('')
    const [loadingLaneAnalysis, setLoadingLaneAnalysis] = useState(true)
    const [showMapRouteError, setShowMapRouteError] = useState(false)
    const [showMap, setShowMap] = useState(true)
    const [detailsRenderedTab, setDetailsRenderedTab] = useState(loadDetailsRenderedTab)
    const [stops, setStops] = useState(load.stops ? load.stops : null)

    useEffect(() => {
        if (load.stops == null && load.originStop && load.destStop) {
            let originLatLon = null
            let destLatLon = null

            getLatitudeLongitudes(load.originStop).then(resp => {
                originLatLon = resp

                getLatitudeLongitudes(load.destStop).then(resp => {
                    destLatLon = resp
                }).then(() => {
                    if (originLatLon && destLatLon) {
                        let tmpStops = [
                            {
                                lat: parseFloat(originLatLon.latitude),
                                lon: parseFloat(originLatLon.longitude),
                                city: load.originStop.city,
                                state: load.originStop.state,
                                label: 'stop0'
                            },
                            {
                                lat: parseFloat(destLatLon.latitude),
                                lon: parseFloat(destLatLon.longitude),
                                city: load.destStop.city,
                                state: load.destStop.state,
                                label: 'stop1'
                            }
                        ]
                        setStops(tmpStops)
                    }
                })


            })
        }
    }, [])

    useEffect(() => {
        if (!isMyLoadsScreen && detailsRenderedTab === 0){
            setShowMap(true)
        }else if (isMyLoadsScreen && detailsRenderedTab === 1){
            setShowMap(false)    
        }else if (isMyLoadsScreen && myLoadsRenderedTab === 0){
            setShowMap(true)
        }else{
            setShowMap(false)
        }
    }, [detailsRenderedTab])

    useEffect(() => {
        setMapLoaded(false)
        updateProcessOnProgress(false)
        updateNotRendered(true)
        setDetailsRenderedTab(loadDetailsRenderedTab)
    }, [load.id])

    const getLatitudeLongitudes = (stop) => {
        let params = {
            city: stop.city,
            state: stop.state,
            zipcode: stop.zip
        }
        let resp = GeoLocationApi.GetLatitudeLongitudes(params).then(response => {
            if (response.status) {
                if (response.latitude && response.longitude) {
                    return response
                }
            }
            return null
        })
        return resp
    }

    const showGoogleMapsInfo = (totalDistance, totalDuration) => {
        totalDistance = totalDistance * 0.000621371192;
        totalDistance = Math.round(totalDistance * 100) / 100
        var d = totalDuration
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        totalDuration = hDisplay + mDisplay //+ sDisplay;

        /*console.log('Directions totalDistance',totalDistance);
        console.log('Directions totalDuration',totalDuration);*/

        updateGoogleMapsDistance(totalDistance)
        updateGoogleMapsDuration(totalDuration)
        updateNotRendered(false)
        updateForceDelay(false)
        setShowMapRouteError(false)
    }

    const removeImportedFont = () => {
        var elements = document.querySelectorAll('link[href*="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700"]')

        if (elements.length > 0)
            elements[0].parentNode.removeChild(elements[0]);
    }

    function contains(selector, text) {
        var elements = document.querySelectorAll(selector);
        return [].filter.call(elements, function (element) {
            return RegExp(text).test(element.innerHTML);
        });
    }

    const removeUnwantedSpan = () => {
        setTimeout(
            () => {
                let spanArray = contains('span', 'BESbswy')
                if (spanArray && spanArray.length > 0)
                    spanArray[0].parentNode.removeChild(spanArray[0]);
            }
            , 3000)
    }

    return (
        <div key={"load_detail_content_" + load.id} style={{ height: '100%' }}>
            <div style={{ position: 'relative', height: '40%', width: "100%", border: '1px solid grey', display: showMap ? 'block' : 'none' }}>
                {stops && stops.length > 0 &&
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                        center={{ lat: stops[0].lat, lng: stops[0].lon }}
                        defaultZoom={8}
                        onGoogleApiLoaded={({ map, maps }) => {
                            updateMap(map);
                            updateMaps(maps);
                            setMapLoaded(true);
                        }}
                        yesIWantToUseGoogleMapApiInternals={true}
                        options={{ fullscreenControl: false, zoomControl: false }}
                    >
                    </GoogleMapReact>
                }

                {mapLoaded && !processOnProgress && notRendered &&
                    <MapRoute
                        delay={delay ? delay : false}
                        processStart={() => updateProcessOnProgress(true)}
                        processEnd={() => { updateProcessOnProgress(false); updateForceDelay(false); removeImportedFont(); removeUnwantedSpan()}}
                        showMapsInfo={(distance, duration) => showGoogleMapsInfo(distance, duration)}
                        map={map}
                        maps={maps}
                        origin={stops[0]}
                        destination={stops[stops.length - 1]}
                        stops={stops}
                        setShowMapRouteError={setShowMapRouteError}
                    />
                }


                {/* <div style={{ position: 'absolute', top: 1, right: 1, backgroundfontSize: 12, backgroundColor: '#FFF', fontWeight: 200, textAlign: 'right' }} >
                    {!load.distance && googleMapsDistance && googleMapsDistance !== '' && (<div style={{ padding: 1 }} > {googleMapsDistance} mi</div>)}
                    {!load.distance && googleMapsDuration && googleMapsDuration !== '' && (<div style={{ padding: 1 }} > {googleMapsDuration} </div>)}
                    {processOnProgress && (<div style={{ padding: 1 }} > looking for direction ... </div>)}
                </div> */}
                <div style={{ position: 'absolute', top: 1, right: 1, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#FFF', opacity: '75%', color: 'red' }} >
                    {showMapRouteError && (<div style={{ padding: 1 }} >Failed to find the route for this load</div>)}
                </div>
            </div>

            <LoadDetailsTabs
                load={load}
                pickup={pickup}
                delivery={delivery}
                availability={availability}
                isMyLoadsScreen={isMyLoadsScreen}
                myLoadsRenderedTab={myLoadsRenderedTab}
                detailsRenderedTab={detailsRenderedTab}
                setDetailsRenderedTab={setDetailsRenderedTab}
                removeLoadFromList={props.removeLoadFromList}
                latestQuote={latestQuote}
                latestQuoteShowBIN={latestQuoteShowBIN}
                callRefreshCard={callRefreshCard}
                onInvoicesScreen={onInvoicesScreen}
                onPreviewScreen={onPreviewScreen}
                googleMapsDistance={googleMapsDistance}
                googleMapsDuration={googleMapsDuration}
            />

        </div>
    );
}


export default compose(
    connect(),
    muiThemeable(),
)(LoadDetailsMobileContent)
