import { PureComponent } from 'react';
import numeral from 'numeral'
const google = window.google;
class MapRoute extends PureComponent {



  componentWillUpdate() {
    this.line && this.line.setMap(null)
  }

  componentWillUnmount() {
    this.line && this.line.setMap(null)
  }

  render() {
    const { origin, destination, stops, setShowMapRouteError, showMapsInfo, processEnd, isFuelStopsScreen = false } = this.props

    const directionsService = new this.props.maps.DirectionsService;
    // const directionsLeg = new this.props.maps.directionsLeg
    const directionsDisplay = new this.props.maps.DirectionsRenderer({ map: this.props.map, suppressMarkers: isFuelStopsScreen ? true : false, suppressInfoWindows: true });
    //.filter((stop)=>stop.lat&&stop.lng&&stop.lat!="0"&&stop.lng!="0")
    var waypts = stops.map((stop, index) => {
      var location = false
      if (stop.city && stop.state) {
        location = stop.city + " " + stop.state
      } else {
        location = new google.maps.LatLng(numeral(stop.lat).format('0'), numeral(stop.lng).format('0'))
      }
      return ({
        location: location,
        stopover: true
      })
    }).filter((stop) => stop.location);
    waypts.pop()
    waypts.shift()


    directionsDisplay.setMap(this.props.map);
    directionsService.route({
      origin: { lat: Number(origin.lat), lng: Number(origin.lon) },
      waypoints: waypts,
      destination: { lat: Number(destination.lat), lng: Number(destination.lon) },
      travelMode: 'DRIVING'
    }, function (response, status) {
      // Route the directions and pass the response to a function to create
      // markers for each step.
      var totalDistance = null;
      var totalDuration = null;
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
        if(showMapsInfo){
          var legs = response.routes[0].legs;
          for(var i=0; i<legs.length; ++i) {
              totalDistance += legs[i].distance.value;
              totalDuration += legs[i].duration.value;
          }

          showMapsInfo(totalDistance,totalDuration)
        }
      } else {
        setShowMapRouteError(true)
        directionsDisplay.setDirections({
          geocoded_waypoints: [],
          request: {
            origin: { lat: Number(origin.lat), lng: Number(origin.lon) },
            waypoints: waypts,
            destination: { lat: Number(destination.lat), lng: Number(destination.lon) },
            travelMode: 'DRIVING'
          },
          routes: [
            {
              "bounds": {
                "south": 33.40775,
                "west": -89.26032000000001,
                "north": 41.867740000000005,
                "east": -86.76361000000001
              },
              "copyrights": "Map data ©2020 Google",
              "legs": [
                {
                  "distance": {
                    "text": "",
                    "value": null
                  },
                  "duration": {
                    "text": "",
                    "value": null
                  },
                  "end_address": destination.address,
                  "end_location": {
                    "lat": Number(destination.lat),
                    "lng": Number(destination.lon)
                  },
                  "start_address": origin.address,
                  "start_location": {
                    "lat": Number(origin.lat),
                    "lng": Number(origin.lon)
                  },
                  steps: [],
                  "traffic_speed_entry": [],
                  "via_waypoint": [],
                  "via_waypoints": []
                }
              ],

              "warnings": [],
              "waypoint_order": [],
              "overview_path": []
            }
          ]
        })

      }

      if(processEnd){
        processEnd() 
       }

    });

    return null
  }


}



export default MapRoute;
