import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { Card } from 'material-ui/Card';
import Switch from '@material-ui/core/Switch';
import * as FeatherIcon from 'react-feather';

import { checkValueExists } from "../../../../../../lib/check-value-exists"

const TruckCapacityCardMobile = (props) => {

    const classes = useStyles()

    const {
        muiTheme,
        item,
        notificationsOn,
        setShowEditModal,
        handleToggleNotification,
    } = props

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: muiTheme.secondary,
                    borderColor: muiTheme.secondary,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const handleCaps = (textInput) => {
        if(textInput)
            return textInput.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    return (
        <Card style={{ width: "100%", flexDirection: "column", justifyContent: "center", borderRadius: 15 }}>

            <div style={{ padding: 15 }}>

                {/* <Row>
                    <p className={classes.mobileLabelText}>
                        Notifications:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                        <AntSwitch checked={notificationsOn} onChange={handleToggleNotification} />
                    </p>
                </Row> */}


                <Row>
                    <p className={classes.mobileLabelText}>
                        Pickup location:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                        {item.origin.city ? handleCaps(item.origin.city) + ", " : ""}{item.origin.state}
                    </p>
                </Row>

                <Row style={{ display: 'flex' }}>
                    <p className={classes.mobileLabelText}>
                        Equipment:{" "}
                    </p>
                    {checkValueExists(item, "equipmentType") && (
                        <p className={classes.mobileValueText}>{item.equipmentType}</p>
                    )}
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p className={classes.mobileLabelText}>
                        Preferred destinations:{" "}
                    </p>
                    <p className={classes.mobileValueText}>
                    {item.destination.city ? handleCaps(item.destination.city) + ", " : ""} {item.destination.state === "ANY" ? "ANYWHERE" : item.destination.zone ? item.destination.zone : item.destination.state}
                    </p>
                </Row>
                <Row style={{ display: 'flex' }}>
                    <p className={classes.mobileLabelText}>
                        Truck Num:{" "}
                    </p>
                    {checkValueExists(item, "numberOfTrucks") && (
                        <p className={classes.mobileValueText}>{item.numberOfTrucks}</p>
                    )}
                </Row>
                <Row style={{ display: 'flex' }}>
                {/* Temporarily removing rate */}
                    {/* <p className={classes.mobileLabelText}>
                        Rate:{" "}
                    </p>
                    {checkValueExists(item, "rate") && (
                        <p className={classes.mobileValueText}>${item.rate}</p>
                    )} */}
                </Row>

            </div>



            <Row style={{
                display: 'flex',
                width: '100%',
                height: '28%',
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: '#F6F6F6',
                margin: 0
            }}>
                <Button
                    style={{ width: '100%', textAlign: 'center' }}
                    onClick={() => setShowEditModal(true)}
                >
                    <FeatherIcon.Edit color={"black"} size={25} />
                </Button>
            </Row>
        </Card>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(TruckCapacityCardMobile)

const useStyles = makeStyles((style) => ({
    searchCardRow: {
        width: "100%",
        height: 'auto',
        marginTop: 3,
        marginBottom: 10,
        padding: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    card: {
        display: "flex",
        height: 'auto',
        flexDirection: "row",
        alignItems: "center",
    },
    col: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height: 100
    },
    margin: {
        margin: 5,
    },
    mobileValueText: {
        width: 'calc(100% - 165px)',
        display: 'inline-block',
        float: 'right',
        color: 'black',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'right'
    },
    mobileLabelText: {
        fontWeight: 'bold',
        display: 'inline-block',
        color: 'black',
        width: 165,
    }
}));
