import moment from 'moment'
import React from 'react';
import $ from 'jquery'
/*global google*/
import * as CONSTANTS from './constants'

const initialState = {
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
  laneData: [],
  historyData: [],
  historyPayLoad :{
      pickupFullAddress:"",
      pickupState:"",
      pickupCity:"",
      pickupRadius:"150",
      equipmentTypeId:[],
      noOfPosting:5,
      rangeDate:6
  },
  lanePayLoad :{
                  pickupFullAddress:"",
                  pickupState:"",
                  pickupCity:"",
                  pickupRadius:"150",
                  equipmentTypeId:[],
                  destinationFullAddress:"",
                  destinationState:"",
                  destinationCity:"",
                  destinationRadius:"150",
              }
}

const reducer = function LaneHistorySearchReducer (state = initialState, action) {
    var localStoreToken   = localStorage.getItem('token');
    var jsonToken = JSON.parse(localStoreToken);
    var showPublishToCarriers=false;
    if(jsonToken){
        showPublishToCarriers   = jsonToken.showPublishToCarriers ? true : false;

    }
  switch (action.type) {
    case CONSTANTS.SEARCH_CARRIER_BY_LANE_ERR:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Error..', time: new Date() }],
        errors: [],
      }

    case CONSTANTS.SEARCH_CARRIER_BY_LANE_SUCC:
    console.log(action)
      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        laneData:action.response,
        lanePayLoad:action.response.payload
      }
    case CONSTANTS.SEARCH_CARRIER_BY_HISTORY_SUCC:
      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        historyData:action.response,
        historyPayLoad:action.response.payload
      }


    default:
      return state
  }
}

export default reducer
