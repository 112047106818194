import moment from 'moment'

export default function isHighConfidence(driver){
	var createSourceId = getKeyValue(driver,['create_source_id','createSourceId'])
	var confirmedAt = getKeyValue(driver,['confirmed_at','confirmedAt'])
	var updatedAt = getKeyValue(driver,['updated_at','updatedAt'])
	var highConfidence = false;

  if(moment(updatedAt).add(6,'hours')> moment()){
    highConfidence=true
  }
	if(!confirmedAt){return true}
	if(confirmedAt&&moment(confirmedAt).add(24,'hours') > moment()){
    highConfidence=true
  }
  return highConfidence
}

export function filterByHighConfidence(avails){
	var highConfidenceAvails = [];
	var lowConfidenceAvails = [];
	avails.map((avail)=>{
		if(isHighConfidence(avail)){
			highConfidenceAvails.push(avail)
		}else{
			lowConfidenceAvails.push(avail)
		}
	})
	console.log(lowConfidenceAvails.length+"  as they were considered not high confidence", lowConfidenceAvails);
	return highConfidenceAvails
}

function getKeyValue(obj,keys){
	var objKeys = Object.keys(obj);
	for(var i=0;i<keys.length;i++){
		var keyName = keys[i]
		if(obj[keyName]==null || obj[keyName]==false || obj[keyName]==undefined){
			//continue...
		}else{
			return obj[keyName]
		}
	}
	return false
}
