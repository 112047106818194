import React, {Component } from 'react';
class PhoneNumberFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone : this.props.phone
        }
    };

    
	formatPhone = (phone,e) => {
	  var s2 = (""+phone).replace(/\D/g, '');
	  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
	  return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
	}
    render() {  

        var phoneNumber = ''
		
		if(this.state.phone!=null && this.state.phone!='')
		var phoneNumber = this.formatPhone(this.state.phone);

        return (<span>{phoneNumber}</span>);
    }
}
export default PhoneNumberFormatter