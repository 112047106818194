import React, { useState, useRef, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import InputLabel from '@material-ui/core/InputLabel';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import GoogleAddressAutoComplete from './GoogleAddressAutoCompleteUIV2';
import PropTypes from 'prop-types';
import { updateComponent } from '../../../CarrierPortal/ActionReducers/components'
import {INPUT_BACKGOUND_COLOR,INPUT_BORDER_COLOR} from '../../../CarrierPortal/palette'

const AddressAutocomplete = ({
  // searchText, 
  // updateSearchText, 
  onChange,
  value,
  clearData,
  helperText = "Location",
  status,
  updateComponent,
  existingLocation = null,
  error=false,
  // updateSearchTextHepler, 
  // needsearchText, 
  searchBox,
  placeholder="",
  muiTheme,
  needsRedux = true,
  handleSetPickupLocation,
  mobileView = false,
  hardLoad=false,
  ...props }) => {

  const useStyles = makeStyles({
    root: {
      border: '0.5px solid '+INPUT_BORDER_COLOR,
      backgroundColor: INPUT_BACKGOUND_COLOR,
      height: '50px',
      width: mobileView ? '100%' : '100%',
      //backgroundColor: 'white',
      paddingLeft: '10px',
      paddingRight: '10px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize:'1rem',
      borderTop:'0px',
      borderLeft:'0px',
      borderRight:'0px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    }
  });

  const classes = useStyles();

  // TODO update to React Hooks
  const [searchText, setSearchText] = useState('');
  const [needSearchText, setNeedSearchText] = useState(false);
  const isMounted = useRef(false);
  const useExistingLocation = useRef(false);


  useEffect(() => {
    if (existingLocation != null) {
      if (existingLocation.city && existingLocation.state)
        setSearchText(existingLocation.city + ", " + existingLocation.state)
      else if (typeof existingLocation === 'string')
        setSearchText(existingLocation)

      useExistingLocation.current = true
    }
  }, [])



  useEffect(() => {
    if (existingLocation != null && hardLoad) {
      if (existingLocation.city && existingLocation.state)
        setSearchText(existingLocation.city + ", " + existingLocation.state)
      else if (typeof existingLocation === 'string')
        setSearchText(existingLocation)
    }
  }, [existingLocation])

  useEffect(() => {
    // if (isMounted.current) {
    //   onChangeCallBack(false)
    //   onChange({
    //     address: false,
    //     city: false,
    //     state: false,
    //     zipCode: false,
    //     country: false,
    //     formattedAddress: false,
    //     lat: null,
    //     lon: null,
    //   })
    //   setSearchText('');
    //   setNeedSearchText(false);
    // } else {
    //   isMounted.current = true;
    // }
  }, [clearData])

  function getSearchText() {
    
    if ((searchText == null || searchText == '') && existingLocation != null && useExistingLocation.current) {

      if (existingLocation.city && existingLocation.state) {
        let returnVal = existingLocation.city + ", " + existingLocation.state
        return returnVal
      } else if (typeof existingLocation === 'string') {
        return existingLocation
      }
      else {
        return ""
      }

    } else if (searchText == null) {
      return value
    } else {
      if (searchText.trim() === '' && !status.showError) {
        if (needSearchText) {
          var payload = {
            address: false,
            city: false,
            state: false,
            stateFulll:false,
            zipCode: false,
            country: false,
            countryFull: false,
            formattedAddress: false,
            lat: null,
            lon: null,
          }

          updateComponent('searchBoxSettings', { error: { address: { showError: true } } })

          !needsRedux ? handleSetPickupLocation(payload) : (onChange && onChange(payload));

          setNeedSearchText(false);
        }

      } else {
        if (status.showError) {
          updateComponent('searchBoxSettings', { error: { address: { showError: false } } });
        }
      }

      useExistingLocation.current = false
      return searchText
    }
  }


  function selectOrPredictCallBack(address) {
    //console.log('GoogleAddressAutoComplete selectOrPredictCallBack',address);
    //updateSearchText(address.formattedAddress);
    setSearchText(address.formattedAddress);

    if (address.formattedAddress !== '') {
      // updateComponent('searchBoxSettings',{error:{address:{showError:false}}});
      if(!address.isTyping && !needsRedux)
        handleSetPickupLocation(address)
      else if(!address.isTyping && onChange)
        onChange(address)
    }
  }

  function onChangeCallBack(address) {
    if (!address) {
      setSearchText('');
      setNeedSearchText(true);
      return false;
    }
    if (address.trim() !== '') {
      setSearchText(address);
      var payload = {
        formattedAddress: address,
        isTyping: true,
      }

      !needsRedux ? handleSetPickupLocation(payload) : (onChange && onChange(payload));
    }
    else if (address.trim() === '' && searchText.trim() !== '') {
      setSearchText('');
      setNeedSearchText(true);
    }
  }


  function showError() {
    if (searchText && searchText !== null && searchText.trim() === '') {
      return 'Update your availability location'
    }
    else if (status.showError) {
      return status.text
    }
    return false
  }

  const fontTheme = [
    muiTheme.primaryFontFamily,
    'Helvetica',
    'Arial',
    'sans-serif'
  ].join(',')
  return (
    <div className={classes.root} style={{borderColor:error?'#f44336':'inrerit'}} >
      {searchText!='' &&(<InputLabel style={{position:'absolute',marginTop:10,fontSize:'1rem'}} htmlFor="component-simple">{placeholder?placeholder:""}</InputLabel>)} 
      <GoogleAddressAutoComplete
        style={{width:'100% !important',marginTop:'4px',}} 
        value={getSearchText()}
        helpText={helperText}
        helpTextLocation="bottom"
        showHelpText={true}
        maxSearchResults={5}
        restrictions={{
          country: ['US', 'MX', 'CA']
        }}
        types={['geocode']}
        fullWidth={true}
        onBlurPredict={true}
        name={'location'}
        error={error}
        errorText={showError()}
        onSelectCallback={selectOrPredictCallBack}
        onPredictCallBack={selectOrPredictCallBack}
        onChangeCallBack={onChangeCallBack}
        placeholder={placeholder?placeholder:""}
        onClear={clearData}
        textFieldStyle={{ paddingBottom: 6, fontFamily: fontTheme }}
        style={{with:'100% !important'}}
      />
    </div>
  )
}

AddressAutocomplete.propTypes = {
  onChange: PropTypes.func//Returns Selected Object of Autocomplete
}

const mapStateToProps = state => ({
  status: state.CarrierPortal.components.searchBoxSettings.error.address,
  value: state.CarrierPortal.components.loadSearchBar.formattedAddress,
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    updateComponent: updateComponent,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  // withState('searchText', 'updateSearchText', ({ value }) => value ? value : null),
  // withState('needsearchText', 'updateSearchTextHepler', true),
  muiThemeable(),
)(AddressAutocomplete)