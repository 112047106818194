import React, { useState } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import GoogleAddressAutoComplete from './GoogleAddressAutoCompleteLoadSearch';
import PropTypes from 'prop-types';

const AddressAutocomplete = ({
  onChange,
  value,
  muiTheme,
  handleSetSearchLocation,
  mobileView = false,
  ...props }) => {

  const useStyles = makeStyles({
    root: {
      border: '0.5px solid #ccc',
      height: '45px',
      width: mobileView ? '100%' : '360px',
      backgroundColor: 'white',
      paddingLeft: '10px',
      paddingRight: '10px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  });

  const classes = useStyles();

  // TODO update to React Hooks
  const [searchText, setSearchText] = useState('');
  const [needSearchText, setNeedSearchText] = useState(false);

  function getSearchText() {
    if (searchText == null) {
      return value
    } else {
      // if (searchText.trim() === '' && !status.showError) {
      if (searchText.trim() === '') {
        if (needSearchText) {
          var payload = {
            address: false,
            city: false,
            state: false,
            zipCode: false,
            country: false,
            formattedAddress: false,
            lat: null,
            lon: null,
          }

          handleSetSearchLocation(payload)

          setNeedSearchText(false);
        }

      }

      return searchText
    }
  }


  function selectOrPredictCallBack(address) {
    //console.log('GoogleAddressAutoComplete selectOrPredictCallBack',address);
    setSearchText(address.formattedAddress);

    if (address.formattedAddress !== '' && !address.isTyping) 
      handleSetSearchLocation(address)
    
  }

  function onChangeCallBack(address) {
    if (!address) {
      setSearchText('');
      setNeedSearchText(true);
      return false;
    }
    if (address.trim() !== '') {
      setSearchText(address);
      var payload = {
        formattedAddress: address,
        isTyping: true,
      }

      handleSetSearchLocation(payload)
    }
    else if (address.trim() === '' && searchText.trim() !== '') {
      setSearchText('');
      setNeedSearchText(true);
    }
  }


  function showError() {
    if (searchText && searchText !== null && searchText.trim() === '') {
      return 'Update your availability location'
    }
    // else if (status.showError) {
    //   return status.text
    // }
    return false
  }

  const fontTheme = [
    muiTheme.primaryFontFamily,
    'Helvetica',
    'Arial',
    'sans-serif'
  ].join(',')

  return (
    <div className={classes.root}>
      <GoogleAddressAutoComplete
        value={getSearchText()}
        helpText="Enter where you would like to search for fuel discounts"
        helpTextLocation="bottom"
        showHelpText={true}
        maxSearchResults={5}
        restrictions={{
          country: ['US', 'MX', 'CA']
        }}
        types={['geocode']}
        fullWidth={true}
        onBlurPredict={true}
        name={'location'}
        errorText={showError()}
        onSelectCallback={selectOrPredictCallBack}
        onPredictCallBack={selectOrPredictCallBack}
        onChangeCallBack={onChangeCallBack}
        placeholder={"Enter Location"}
        textFieldStyle={{ paddingBottom: 6, fontFamily: fontTheme }}
      />
    </div>
  )
}

AddressAutocomplete.propTypes = {
  onChange: PropTypes.func//Returns Selected Object of Autocomplete
}

export default compose(
  muiThemeable(),
)(AddressAutocomplete)