//MESSAGING
  import * as CONSTANTS from './constants'

const basicFilterFields = {
  pickupCity:'',
  pickupState:'',
  pickupFullAddress:'',
  pickupTime:'',

  deliveryCity:'',
  deliveryState:'',
  deliveryFullAddress:'',
  deliveryTime:'',

  truckTypes:[],
  favBrokers:[],
}


const initialState = {
  requesting: false,
  initLoading: false,
  requesting: false,
  successful: false,
  initialFilterValues:{
    innetwork:basicFilterFields,
    premium:basicFilterFields,
    outoffnetwork:basicFilterFields,
  },
  filteredFilterValues:{
    innetwork:null,
    premium:null,
    outoffnetwork:null,
  },
}

const reducer = function LoadListFilterReducer (state = initialState, action) {

  switch (action.type) {

    case CONSTANTS.CARRIER_PORTAL_AVAIL_MATCH_LOAD_FILTER_SAVE_SUCC:
      var filterType    =    action.filterType;
      var filterData    =    action.filterData;

      var  filteredFilterValuesTmp  = state.filteredFilterValues
      if(filterType == 0){
        filteredFilterValuesTmp.outoffnetwork = filterData
      }
      if(filterType == 1){
        filteredFilterValuesTmp.innetwork = filterData
      }
      if(filterType == 2){
        filteredFilterValuesTmp.premium = filterData
      }

      var retValue = {
          ...state,
          filteredFilterValues:filteredFilterValuesTmp
      }
      return retValue

    case CONSTANTS.CARRIER_PORTAL_AVAIL_MATCH_LOAD_FILTER_CLEAR_SUCC:
      var filterType    =    action.filterType;
      var filterData    =    action.filterData;

      var  filteredFilterValuesTmp  = state.filteredFilterValues
      if(filterType == 0){
        filteredFilterValuesTmp.outoffnetwork = basicFilterFields
      }
      if(filterType == 1){
        filteredFilterValuesTmp.innetwork = basicFilterFields
      }
      if(filterType == 2){
        filteredFilterValuesTmp.premium = basicFilterFields
      }


      return {
        ...state,
          filteredFilterValues:filteredFilterValuesTmp
      }

    default:
      return state
  }
}



export default reducer
