import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import isMobile from '../../../../HOC/Mobile.js'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {reduxForm,Field,Form,change,untouch,reset} from 'redux-form'
import {compose} from 'recompose'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types';
import { checkValueExists } from "../../../../lib/check-value-exists"
import { WernerServices } from '../../../customers/a98db973/services';
import AddressAutocomplete from '../../LoadSearchBar/AddressAutocompleteLocationAdd';
import LoadingOverlay from '../../LoadingOverlay'
import CustomTextFieldInput from '../../../../components/CustomTextFieldInput'


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



const AddNewLocationModal = (
    {
        onClose,
        isEdit=false,
        isMobile,
        handleSubmit,
        dispatch,
        muiTheme,
        ...props
    }
) => {
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiInputBase-root' : {
                fontSize: 16
            },
            '& .MuiFormLabel-root' : {
                fontSize: 16
            }
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            width: isMobile ? '100%' : 460,
            height: isMobile ? '100%' : 'unset',
            outline: 'none',
            padding: isMobile ? 5 : theme.spacing(2, 4, 3),
        },
        header: {
            margin: 0
        },
        row: {
            marginBottom: 20
        }
    }));
    const classes = useStyles()

    const {initialValues={}}=props


    const [isApiCalling, setIsApiCalling] = useState(false);
    const [isNameFieldInValid, setIsNameFieldInValid] = useState(false);
    const [nameField, setNameField] = useState(checkValueExists(initialValues,'name')?initialValues.name:'');

    const [isLocationTypeFieldInValid, setIsLocationTypeFieldInValid] = useState(false);
    const [locationTypeField, setLocationTypeField] = useState(checkValueExists(initialValues,'addressType') ?initialValues.addressType:'' );

    const [isAddressLine1FieldInValid, setIsAddressLine1FieldInValid] = useState(false);
    const [addressLine1Field, setAddressLine1Field] = useState(checkValueExists(initialValues,'addressL1')?initialValues.addressL1:'' );

    const [isAddressLine2FieldInValid, setIsAddressLine2FieldInValid] = useState(false);
    const [addressLine2Field, setAddressLine2Field] = useState(checkValueExists(initialValues,'addressL2')?initialValues.addressL2:'' );

    const [addressField, setAddressField] = useState( checkValueExists(initialValues,'address1')?initialValues.address1:'' );
    const [cityField, setCityField] = useState( checkValueExists(initialValues,'city')?initialValues.city:'' );
    const [stateField, setStateField] = useState( checkValueExists(initialValues,'state')?initialValues.state:'' );
    const [zipField, setZipField] = useState( checkValueExists(initialValues,'zip')?initialValues.zip:'' );
    const [countryField, setCountryField] = useState( checkValueExists(initialValues,'country')?initialValues.country:'' );
    const [existingLoc, setExistingLoc] = useState( null );



    useEffect(() => {
    }, []);

    useEffect(() => {
        if(initialValues){
        
            // let tmpExistingLoc = {
            //     street:initialValues.street,
            //     city:initialValues.city,
            //     state:initialValues.state,
            //     zip:initialValues.zip,
            //     country:initialValues.country,
            // }
            let tmpExistingLoc = ''

            if(checkValueExists(initialValues,'addressL1')){
                setAddressLine1Field(initialValues.addressL1)
                tmpExistingLoc = initialValues.addressL1
            }
            if(checkValueExists(initialValues,'address1')){
                setAddressField(initialValues.address1)
                tmpExistingLoc = initialValues.addressL1
            }

            if(checkValueExists(initialValues,'city')){
                setCityField(initialValues.city)
            }
            if(checkValueExists(initialValues,'state')){
                setStateField(initialValues.state)
            }
            if(checkValueExists(initialValues,'zip')){
                setZipField(initialValues.zip)
            }
            if(checkValueExists(initialValues,'country')){
                setCountryField(initialValues.country)
            }


            setExistingLoc(tmpExistingLoc)
        }
    }, [initialValues]);

    const handleClose = () => {
        onClose(true)
    };

    const closeModal = () => {
        onClose(false)
    };

    const onLocationTypeSelect = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setLocationTypeField(value)
            setIsLocationTypeFieldInValid(false)
        }else{
            setIsLocationTypeFieldInValid(true)
            setLocationTypeField('')
        }
    }



    const onNameChange = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setNameField(value)
            setIsNameFieldInValid(false)
        }else{
            setIsNameFieldInValid(true)
            setNameField('')
        }
    }


    const onAddress1Change = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setAddressLine1Field(value)
            setIsAddressLine1FieldInValid(false)
        }else{
            setIsAddressLine1FieldInValid(true)
            setAddressLine1Field('')
        }
    }


    const onAddress2Change = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setAddressLine2Field(value)
            setIsAddressLine2FieldInValid(false)
        }else{
            setIsAddressLine2FieldInValid(true)
            setAddressLine2Field('')
        }
    }


    const locationAddSubmit =(formValue)=>{
        let type = locationTypeField.trim() 
        let name = ''
        let addressL1 = ''
        let addressL2 = ''
        

        if(type ==''){
            setIsLocationTypeFieldInValid(true)
            return false
        }else{
            setIsLocationTypeFieldInValid(false)
            formValue.addressType = type
        }

        if(checkValueExists(formValue,'name') ){
            name = formValue.name
            name = name.trim()
            if(name ==''){
                setIsNameFieldInValid(true)
                return false
            }else{
                setIsNameFieldInValid(false)
            }
        }else{
            setIsNameFieldInValid(true)
            return false
        }
        if(existingLoc==null){
            setIsAddressLine1FieldInValid(true)
            return false
        }

        if(addressField ){
            addressL1 = addressField
            addressL1 = addressL1.trim()
            if(addressL1 ==''){
                setIsAddressLine1FieldInValid(true)
                return false
            }else{
                setIsAddressLine1FieldInValid(false)
            }
        }else{
            setIsAddressLine1FieldInValid(true)
            return false
        }


        if(checkValueExists(formValue,'address2') ){
            addressL2 = formValue.address2
            addressL2 = addressL2.trim()
            // if(addressL2 ==''){
            //     setIsAddressLine2FieldInValid(true)
            //     return false
            // }else{
            //     setIsAddressLine2FieldInValid(false)
            // }
        }else{
            //setIsAddressLine2FieldInValid(true)
            //return false
        }

        let country = 'USA'
        if(countryField){
            switch (countryField) {
                case 'United States':
                    country = 'USA'
                    break;
            
                case 'Canada':
                    country = 'CA'
                    break;
            
                case 'Maxico':
                    country = 'MA'
                    break;
            
                default:
                    break;
            }
        }

        let params ={
            addressType:type,
            name:name,

            address1:addressL1,
            address2:addressL2,

            city:cityField,
            state:stateField,
            zip:zipField,
            country:country,
            serviceTypeList:['CORP'],
        }
        if(isEdit){
            params.addressId = initialValues.addressId
            callEditApi(params)
        }else{
            callAddApi(params)
        }
    }

    const callEditApi = (params)=>{
        const apiServices = new WernerServices()
        setIsApiCalling(true)
        apiServices.editLocation(params)
            .then(response => {
                setIsApiCalling(false)
                if (response  ) {
                    handleClose()
                }
                // setIsLoading(false)
            })
            .catch(error => {
                setIsApiCalling(false)
                // console.log("ERROR 2: ", error)
                // let errorMessage = "Error: There was a problem fetching your booked loads."
                // setIsLoading(false)
                // toast.error(errorMessage)
            })
    }

    const callAddApi = (params)=>{
        const apiServices = new WernerServices()
        setIsApiCalling(true)
        apiServices.addLocation(params)
            .then(response => {
                setIsApiCalling(false)
                if (response  ) {
                    handleClose()
                }
                // setIsLoading(false)
            })
            .catch(error => {
                setIsApiCalling(false)
                // console.log("ERROR 2: ", error)
                // let errorMessage = "Error: There was a problem fetching your booked loads."
                // setIsLoading(false)
                // toast.error(errorMessage)
            })
    }

    function clearData(){

        setIsAddressLine1FieldInValid(true)
        setAddressLine1Field('')
        setAddressField('')
        setCityField('')
        setStateField('')
        setZipField('')
        setCountryField('')
        setExistingLoc(null)
    }

    function updateAddress (event){
        if(!event.isTyping && event.formattedAddress){
            setIsAddressLine1FieldInValid(false)
            setAddressLine1Field(event.formattedAddress)
            setAddressField(event.address?event.address:event.formattedAddress)
            setCityField(event.city)
            setStateField(event.state)
            setZipField(event.zipCode)
            setCountryField(event.country)
            setExistingLoc(event)
        }else if(event.isTyping) {

        }
    }

    return (
        <Modal
            aria-labelledby="add-new-location"
            aria-describedby="add-new-location"
            className={classes.modal}
            open={true}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div className={classes.paper} style={{padding:10}} >
                {isApiCalling && <LoadingOverlay />}
                <h3 id="transition-modal-title" style={{marginBottom:15}} >{isEdit?'EDIT':'ADD'} LOCATION</h3>
                <Form  noValidate className={classes.root}  onSubmit={handleSubmit(locationAddSubmit)}>
                    <Row style={{display: 'flex', justifyContent: 'space-between' }} >
                        <Col  style={{marginTop:0 , width:'30%'}} >
                            <FormControl variant="filled" className={classes.formControl} fullWidth required >
                                <InputLabel id="location">Location Type</InputLabel>
                                <Select
                                    labelId="locationLbl"
                                    id="locationSelect"
                                    value={locationTypeField}
                                    fullWidth
                                    required
                                    error={isLocationTypeFieldInValid}
                                    onChange={(event)=>onLocationTypeSelect(event)}
                                    >
                                    {/* <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem> */}
                                    <MenuItem value={'MAIN'}>MAIN</MenuItem>
                                    <MenuItem value={'DROPYARD'}>DROPYARD</MenuItem>
                                    <MenuItem value={'TERMINAL'}>TERMINAL</MenuItem>
                                    <MenuItem value={'BILLING'}>BILLING</MenuItem>
                                    <MenuItem value={'RAMP'}>RAMP</MenuItem>
                                    <MenuItem value={'METRO'}>METRO</MenuItem>
                                    <MenuItem value={'BRANCH'}>BRANCH</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col style={{marginTop:0,width:'68%',marginLeft:'2%'}} >
                            <Field 
                                id="name" 
                                label="Name" 
                                variant="filled" 
                                fullWidth 
                                required
                                key={"name"}
                                name={"name"}
                                type="text"
                                component={CustomTextFieldInput}
                                onChange={ (event)=>onNameChange(event) }
                                error={isNameFieldInValid}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop:10}} >
                        <Col > 
                            <AddressAutocomplete 
                                onChange={(event)=>updateAddress(event)} 
                                value={addressLine1Field} 
                                clearData={()=>clearData()} 
                                existingLocation={existingLoc} 
                                placeholder="Addres Line 1 *" 
                                variant="filled" 
                                fullWidth 
                                required
                                name={"addressL1"}
                                error={isAddressLine1FieldInValid}
                            />
                            {/* <Field 
                                id="addressL1" 
                                label="Addres Line 1" 
                                variant="filled" 
                                fullWidth 
                                required
                                key={"addressL1"}
                                name={"addressL1"}
                                type="text"
                                component={CustomTextFieldInput}
                                value={addressLine1Field}
                                onChange={(event) => {(event)=>onAddress1Change(event)} }
                            /> */}
                        </Col>
                    </Row>
                    <Row style={{marginTop:10}} >
                        <Col >
                            <Field 
                                id="addressL2" 
                                label="Addres Line 2 (Optional)" 
                                variant="filled" 
                                fullWidth 
                                required
                                key={"addressL2"}
                                name={"addressL2"}
                                type="text"
                                component={CustomTextFieldInput}
                                value={addressLine2Field}
                                onChange={(event)=>onAddress2Change(event) }
                                //error={isAddressLine2FieldInValid}
                            />
                        </Col>
                    </Row>
                    <Row  style={{ width: "100%", display: "flex", justifyContent: "center",marginTop:10 }} >
                        <Col >
                            <Button
                                id='cancelSavingNewLocation'
                                size="small"
                                variant="link"
                                style={{
                                    color: muiTheme.actionColor,
                                    fontSize: 14,
                                    textTransform:'uppercase',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                                onClick={(event) => { closeModal(event) }}
                                disabled={isApiCalling}
                            >
                               <span style={{lineHeight: '25px',marginLeft:5}} >Cancel</span>
                            </Button>
                        </Col>
                        <Col >
                            <Button
                                id='saveNewLocation'
                                size="small"
                                variant="contained"
                                style={{
                                    backgroundColor: muiTheme.actionColor,
                                    borderColor: muiTheme.actionColor,
                                    color: "white",
                                    fontSize: 14,
                                    textTransform:'uppercase',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginLeft:10
                                }}
                                type="submit"
                                disabled={isApiCalling}
                            >
                               <span style={{lineHeight: '25px',marginLeft:5}} >SAVE</span>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}




    const mapStateToProps = state => ({
    })

    function matchDispatchToProps(dispatch){
        return  bindActionCreators({
            //inviteCarrierAction:inviteCarrierAction
        },dispatch)
    }


    AddNewLocationModal.propTypes={
        //label:PropTypes.string.isRequired
          handleSubmit: PropTypes.func,
  
    }

    const formed = reduxForm({
        form: 'addLocationForm',
        enableReinitialize: true,
        destroyOnUnmount:true
    })(AddNewLocationModal)


    export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        muiThemeable(),
        isMobile()
    )(formed)