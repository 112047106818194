
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import FuelDetailsMobileContent from './mobile-content'
import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles({
    paper: {
        maxWidth: 'unset !important',
        maxHeight: 'unset !important',
    }
});

const FuelDetailsMobile = (props) => {

    const {
        item,
        setOpenDetails,
        searchLocation,
        muiTheme,
    } = props

    const classes = useStyles();

    return (


        <Drawer width={"100%"} open={true} PaperProps={{ style: { overflow: 'hidden', width: '100%', height: '100%' } }}>

            <Button
                onClick={() => {
                    setOpenDetails(false)
                }}
                style={{
                    position: 'absolute',
                    top: 8,
                    left: 15,
                    zIndex: 10,
                    backgroundColor: muiTheme.actionColor,
                    minWidth: 'auto',
                    padding: '6px 16px'
                }}
                disableRipple={true}
            >

                <FA name="caret-left" size='2x' style={{ color: 'white' }} />
            </Button>

            <FuelDetailsMobileContent
                item={item}
                searchLocation={searchLocation}
            />
        </Drawer>


    );
}


export default compose(
    connect(),
    muiThemeable(),
)(FuelDetailsMobile)