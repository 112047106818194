import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import isMobile from '../../HOC/Mobile.js'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import FactoringForm from '../components/factoring/factoring-form';
import useGtagHook from '../../gtag-hook';


const FactoringScreen = (props) => {

    const { height, width } = useWindowDimensions();
    const { muiTheme, isMobile } = props

    const useStyles = makeStyles({
        container: {
            height: height,
            width: '100%'
        }
    });

    const classes = useStyles()

    const [sendGtagEvent] = useGtagHook()

    const handleBannerClick = () => {
        sendGtagEvent("banner_click", {
            bannerType: "factoring"
        })
    }

    // let user_services = localStorage.getItem("accountServices")

    // if (user_services.factor) {
    //     return <h3>Factor Carrier</h3>
    // } else {
    return (
        <div className={classes.container}>
            <div style={{ height: "100%", width: "100%" }}>
                <div style={{ height: "95%" }}>
                    <div style={{ height: isMobile ? "10%" : "25%" }}>
                        <a href="https://www.englandlogistics.com/factoring/" target="_blank" onClick={() => handleBannerClick()}>
                            <img src="https://assets.truckertools.com/website/banners/england-logistics/TT-Factoring-Banner-BLUE_936_120.gif" alt="England Logistics Factoring Banner" style={{ width: '100%' }} />
                        </a>
                    </div>
                    <div style={{ maxHeight: isMobile ? "90%" : "75%", overflowX: "hidden", overflowY: "auto", marginRight: isMobile ? 0 : 50, marginTop: isMobile ? 0 : 30 }}>
                        <FactoringForm />
                    </div>
                </div>
            </div>
        </div>
    )
    // }
}


export default compose(
    muiThemeable(),
    isMobile(),
)(FactoringScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}