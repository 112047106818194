//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'

//MESSAGING
  import * as MESSAGES from './constants'

//APIs
  import {postLanePreferences} from '../../api/lanePreferences'

function* createZonePrefsFlow(action){
  try {

    const response = yield call(postLanePreferences,action.lanePrefs)
    if(response.status){
      //yield put({type:MESSAGES.GET_BILLABLE_MATCHES_SUCCESS,payload:response.payload})
    }

  } catch (error) {
    console.log(error)

  }
}

function* templateWatcher () {
  yield takeLatest(MESSAGES.CREATE_ZONE_PREFS_REQUESTING, createZonePrefsFlow)
}

export default templateWatcher