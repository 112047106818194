import { call, put, takeLatest } from 'redux-saga/effects'
import { handleApiErrors } from '../lib/api-errors'
import {
  ULOGIN_REQUESTING,
  ULOGIN_SUCCESS,
  ULOGIN_ERROR,
} from './constants'

// So that we can modify our Client piece of state
import {  
  setClient,
  unsetClient,
} from '../client/actions'

import {  
  CLIENT_UNSET,
} from '../client/constants'

// The url derived from our .env file
const uloginUrl = `${process.env.REACT_APP_SC_URL}/user/login`

function uloginApi (uniqueId, integrationId) {
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  

  return fetch(uloginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uniqueId , integrationId}),
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .catch((error) => { throw error })
}

// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* uloginFlow (action) {
  let token
  try {
    const { uniqueId,integrationId } = action

     // try to call to our loginApi() function.  Redux Saga
    // will pause here until we either are successful or
    // receive an error
    token = yield call(uloginApi, uniqueId, integrationId )

    // inform Redux to set our client token, this is non blocking so...
    yield put(setClient(token))

    // set a stringified version of our token to localstorage on our domain
    localStorage.setItem('token', JSON.stringify(token))

    // .. also inform redux that our login was successful
    yield put({ type: ULOGIN_SUCCESS })
  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    yield put({ type: ULOGIN_ERROR, error })
  }
}

// Watches for the SIGNUP_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* uloginWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(ULOGIN_REQUESTING, uloginFlow)
}

export default uloginWatcher