import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'

//COMPONENTS
import TireSearchBarMobile from './tire-search-bar-mobile';


const TireSearchMobileWrapper = (props) => {

    const {
        muiTheme,
        searchData,
        handleSelect,
        onSearch,
        searchParams
    } = props

    const [showTireSearchPopover, setShowTireSearchPopover] = useState(false)
    const [searchAnchorEL, setSearchAnchorEl] = useState(null)

    return (
        <div>
            <Button
                onClick={(anchor) => {
                    setShowTireSearchPopover(true)
                    setSearchAnchorEl(anchor)
                }}
                style={{ paddingLeft: 4 }}
                disableRipple={false}
            >

                <FA name="search" size='2x' style={{ color: 'black' }} />
                <p style={{ fontSize: 14, paddingLeft: 5, color: 'black' }}>Search</p>
            </Button>
            <Popover
                id="search_popover"
                PaperProps={{ style: { width: '90%' } }}
                open={showTireSearchPopover}
                anchorEl={searchAnchorEL}
                onClose={() => { setSearchAnchorEl(null); setShowTireSearchPopover(false) }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <TireSearchBarMobile
                    handleSearch={onSearch}
                    searchData={searchData}
                    handleSelect={handleSelect}
                    searchParams={searchParams}
                    setShowTireSearchPopover={setShowTireSearchPopover}
                />
            </Popover>
        </div>
    )
}

export default TireSearchMobileWrapper
