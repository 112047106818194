import React from 'react';
import FA from 'react-fontawesome'

const Marker = (props) => {
    const { color, name, id } = props;
    return (
      <FA name='map-marker-alt' size='3x' style={{color:'#AB232A'}}/>
    );
  };

  export default Marker;