import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../HOC/Mobile.js'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';

import StopInformationMobile from './stop-information-mobile';
import LineItemCharges from './line-item-charges'
import PaymentInformation from './payment-information-mobile'
import './styles.css';


const InvoiceFormMobile = (props) => {
    const useStyles = makeStyles((theme) => ({
        mainText: {
            fontSize: '14px',
            lineHeight: '22px',
            color: 'black',
            fontWeight: 'bold',
            margin: 0
        },
        secondaryText: {
            fontSize: '14px',
            lineHeight: '22px',
            color: 'black',
            margin: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            marginBottom: -5,
        },
        actionContainer: {
            position: 'absolute',
            height: 45,
            width: '100%',
            bottom: 0,
            textAlign: 'center',
            margin: 0,
        },
        MuiAccordionroot: {
            "&.MuiAccordion-root:before": {
                backgroundColor: "white"
            }
        },
        inputRoot: {
            fontSize: 16,
        },
        labelRoot: {
            fontSize: 16,
            // color: props.muiTheme.actionColor,
            "&$labelFocused": {
                //   color: props.muiTheme.actionColor
            }
        },
        labelFocused: {},

    }));
    const classes = useStyles();
    const {
        allowEdit, //tpdo
        allowSubmit,//tpdo
        load,
        invoice,
        arrivalTime,
        departureTime,
        updateDepartureTime,
        updateArrivalTime,
        lineItems,
        addLineItem,
        updateLineItem,
        removeLineItem,
        onFileUpload,
        removeLineItemDocument,
        submitInvoice,//tpdo
        muiTheme,
        editModeFlag,
        setEditModeFlag,
        hasDocuments = false,
        currentStatus,
        statusFormatted,
        statusMessage,
        contactEmail,
        contactPhone,
        setContactEmail,
        setContactPhone,
        refresh,
        handleCloseModal
    } = props


    const [showContactInfo, setShowContactInfo] = useState(false)
    const statusAccordianRef = useRef(null)
    const [rerender, setRerender] = useState(false)


    const formatWord = (param) => {
        if (param && typeof param === "string") {
            param = param.replace("_", " ")

            let words = param.split(" ");

            param = words.map((word) => {
                return word[0].toUpperCase() + word.substring(1);
            }).join(" ");

            return param
        }
        return null
    }

    const hasValidDocs = (docs) => {
        let returnVal = false
        docs.forEach(doc => {
            if (!doc.isDeleted && !returnVal)
                returnVal = true
        })
        return returnVal
    }

    return (

        <div style={{ height: '100%', width: '100%' }}>


            <Row style={{ height: 75, width: '100%', padding: 15, display: 'inline-block' }}>
                <Button
                    onClick={() => {
                        handleCloseModal()
                    }}
                    style={{



                        backgroundColor: muiTheme.actionColor,
                        minWidth: 'auto',
                        padding: '6px 16px'
                    }}
                    disableRipple={true}
                >
                    <FA name="times" style={{ fontSize: 18, cursor: 'pointer', color: "white" }} />
                </Button>

                {invoice.id &&
                    <p className={classes.mainText} style={{ fontSize: 16, float: 'right' }}>
                        Invoice # {invoice.id}
                    </p>
                }

            </Row>



            {statusMessage && statusMessage !== "" ?
                <Accordion
                    ref={statusAccordianRef}
                    onChange={(e, expanded) => {
                        setTimeout(() => setRerender(!rerender), 300)
                    }}
                    elevation={0}
                    classes={{ root: classes.MuiAccordionroot }}
                    style={{ boxShadow: "none", backgroundColor: 'white', overflow: 'hidden', marginLeft: 15, marginRight: 15, borderRadius: 5, marginBottom: 25 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ fontSize: 28 }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.mainText} style={{ height: '100%', width: '100%', fontSize: 16, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            Status: {statusFormatted}
                        </p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className={classes.secondaryText} style={{ display: 'inline', height: '100%', fontSize: 16, flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold' }}>Notes: </span>{statusMessage}
                        </p>
                    </AccordionDetails>
                </Accordion>
                :
                <Row style={{ height: 55, backgroundColor: 'white', overflowY: 'auto', overflowX: 'hidden', marginLeft: 15, marginRight: 15, borderRadius: 5, marginBottom: 25 }}>

                    <p className={classes.mainText} style={{ height: '100%', fontSize: 16, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                        Status: {statusFormatted}
                    </p>

                </Row>
            }









            <div key={"form_container_" + rerender} style={{ height: `calc(100% - 160px - ${statusAccordianRef.current ? statusAccordianRef.current.clientHeight : '55'}px)`, backgroundColor: 'white', overflowY: 'auto', overflowX: 'hidden', marginLeft: 15, marginRight: 15, borderRadius: 5 }}>

                {showContactInfo ?
                    <div style={{ width: '100%', padding: 25 }}>
                        <h3 style={{ textAlign: 'center' }}>Contact Info</h3>
                        <Col style={{ justifyContent: 'center' }}>
                            <Row>
                                <TextField
                                    id="email_input"
                                    label="Contact Email"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                        }
                                    }}
                                    margin="normal"
                                    fullWidth
                                    variant="outlined"
                                    value={contactEmail}
                                    onChange={(e) => setContactEmail(e.target.value)}
                                />
                            </Row>
                            <Row>
                                <TextField
                                    id="phone_input"
                                    label="Contact Phone"
                                    InputProps={{ classes: { root: classes.inputRoot } }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                        }
                                    }}
                                    margin="normal"
                                    fullWidth
                                    variant="outlined"
                                    value={contactPhone}
                                    onChange={(e) => setContactPhone(e.target.value)}
                                />
                            </Row>
                        </Col>
                    </div>
                    :
                    <div style={{ display: 'contents' }}>
                        {load.stops && load.stops.length > 0 &&
                            <div style={{ display: 'contents' }}>
                                <Accordion elevation={0} classes={{ root: classes.MuiAccordionroot }} style={{ boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ fontSize: 28 }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <h3 style={{ margin: 0 }}>Load Information</h3>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <StopInformationMobile
                                            load={load}
                                            arrivalTime={arrivalTime}
                                            updateArrivalTime={updateArrivalTime}
                                            departureTime={departureTime}
                                            updateDepartureTime={updateDepartureTime}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                <hr />
                            </div>
                        }



                        <LineItemCharges
                            stops={load.stops}
                            lineItems={lineItems}
                            addLineItem={addLineItem}
                            updateLineItem={updateLineItem}
                            removeLineItem={removeLineItem}
                            onFileUpload={onFileUpload}
                            refresh={refresh}
                            editModeFlag={editModeFlag}
                            setEditModeFlag={setEditModeFlag}
                            allowEdit={allowEdit}
                        />




                        <hr />

                        <Row key={"line_item_docs_" + refresh} style={{ paddingLeft: 25 }}>
                            <Row style={{ display: 'flex' }}>
                                <h3 style={{ margin: 0 }}>Documents</h3>
                            </Row>
                            {lineItems && lineItems.length > 0 && hasDocuments ?
                                <Row style={{ marginTop: 15 }}>
                                    {lineItems.map((item, itemIndex) =>
                                        <Row style={{ display: 'flex', flexDirection: 'column' }}>
                                            {item.documents && item.documents.length > 0 && hasValidDocs(item.documents) &&
                                                <p className={classes.mainText} style={{ fontSize: 14 }}>{formatWord(item.line_item_type)}
                                                    <span> {item.stop && item.stop.sequence ? `(Stop ${item.stop.sequence})` : null} </span>
                                                </p>
                                            }
                                            { item.documents && item.documents.length > 0 && hasValidDocs(item.documents) &&
                                                <div>
                                                    <ul className="docList">
                                                        {item.documents && item.documents.length > 0 && item.documents.map((doc, docIndex) => {
                                                            if (doc.isDeleted)
                                                                return null
                                                            else {
                                                                return (
                                                                    <li style={{ display: 'inline-flex', width: '100%', paddingTop: 10 }}>

                                                                        <Col style={{ width: '80%' }}>
                                                                            <Row style={{ width: 200, textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-flex', whiteSpace: 'nowrap' }}>
                                                                                <a
                                                                                    className={classes.secondaryText}
                                                                                    style={{ color: doc.document_url ? muiTheme.actionColor : 'black' }}
                                                                                    href={doc.document_url ? doc.document_url : doc.path}
                                                                                    target="_blank">
                                                                                    {formatWord(doc.document_name ? doc.document_name : doc.name)}
                                                                                </a>
                                                                            </Row>
                                                                            <Row>
                                                                                <p className={classes.secondaryText} style={{ paddingLeft: 30 }}>
                                                                                    {moment(doc.updated_at ? doc.updated_at : doc.timestamp).format('ll HH:mm')}
                                                                                </p>


                                                                            </Row>
                                                                        </Col>
                                                                        <Col>
                                                                            {allowEdit &&
                                                                                <span onClick={() => removeLineItemDocument(itemIndex, docIndex)} style={{ paddingLeft: 15, display: 'inline-block' }}><FA name="trash" style={{ fontSize: 18, cursor: 'pointer', color: muiTheme.ttRed }} /></span>
                                                                            }
                                                                        </Col>

                                                                    </li>
                                                                )
                                                            }
                                                        }

                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                        </Row>
                                    )}
                                </Row>
                                :
                                <div>
                                    <p style={{ width: '100%', textAlign: 'left', fontSize: 14, color: 'grey', fontStyle: 'italic', paddingRight: 15, marginTop: 15 }}>
                                        Document images can be submitted in the Charges section above.
                                    </p>
                                </div>
                            }
                        </Row>

                        {invoice && invoice.payments && invoice.payments.length > 0 &&
                            <div>
                                <hr />
                                <PaymentInformation paymentHistory={invoice.payments} />
                            </div>
                        }
                    </div>
                }


            </div>

            <Row className={classes.actionContainer} >
                <Col style={{ width: '100%', display: 'inline-flex', justifyContent: 'center' }}>

                    <Button
                        aria-describedby={"invoice_save"}
                        variant={"outlined"}
                        onClick={() => { showContactInfo ? setShowContactInfo(false) : submitInvoice("save") }}
                        style={{
                            display: allowSubmit && allowEdit ? "block" : "none",
                            fontSize: 14,
                            color: muiTheme.actionColor,
                            backgroundColor: "white",
                            borderColor: muiTheme.actionColor,
                            border: '1px solid',
                            margin: 5
                        }}
                        size={"medium"}
                        disableRipple={false}
                        disabled={!allowEdit || props.isLoading}
                    >

                        {showContactInfo ? "Go Back" : "Save for Later"}
                    </Button>
                    <Button
                        aria-describedby={"invoice_submit"}
                        variant={"contained"}
                        onClick={() => { showContactInfo ? submitInvoice("submitted") : setShowContactInfo(true) }}
                        style={{
                            display: allowSubmit && allowEdit ? "block" : "none",
                            fontSize: 14,
                            color: "white",
                            backgroundColor: muiTheme.actionColor,
                            borderColor: muiTheme.actionColor,
                            margin: 5
                        }}
                        size={"medium"}
                        disableRipple={false}
                        disabled={!allowSubmit || props.isLoading}
                    >

                        {props.isLoading ? <FA name='spinner' spin /> : showContactInfo ? "Submit" : "Next"}
                    </Button>
                </Col>
            </Row>

        </div>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(InvoiceFormMobile)

