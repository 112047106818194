import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'

// const BACKEND_URL = "http://scapi-dev.truckertools.com/api" //TODO: remove this line and uncomment import above when moving to prod.
const FETCH_NOTIFICATION_LIST_URL = '/api/carrier/fetchNotificationList'
const DELETE_NOTIFICATION_URL = '/api/carrier/deleteNotification'
const READ_NOTIFICATION_URL = '/api/carrier/readNotification'
const GET_UNREAD_NOTIFICATION_URL = '/api/carrier/getUnreadNotification'



export const FetchNotifications = () => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + FETCH_NOTIFICATION_LIST_URL
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + FETCH_NOTIFICATION_LIST_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + FETCH_NOTIFICATION_LIST_URL,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const DeleteNotification = (params) => {
    const myAuthService = useAuthenticationHook()
    const url = BACKEND_URL + DELETE_NOTIFICATION_URL
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + DELETE_NOTIFICATION_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + DELETE_NOTIFICATION_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const PostNotificationSeen = (params) => {
    const myAuthService = useAuthenticationHook()
    const url = BACKEND_URL + READ_NOTIFICATION_URL
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + READ_NOTIFICATION_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + READ_NOTIFICATION_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const FetchUNreadNotification = () => {
    const myAuthService = useAuthenticationHook()
    const url = BACKEND_URL + GET_UNREAD_NOTIFICATION_URL
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + GET_UNREAD_NOTIFICATION_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + GET_UNREAD_NOTIFICATION_URL,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}
