const initialState = {
  requesting: false,
  successful: false,
  payload:{loads:[],availability:{}},
  messages: [],
  errors: [],
}

const reducer = (state = initialState, action)=>{
  switch (action.type) {
    case 'UPDATE_OUT_OF_NETWORK_LOADS_REQ':
      var retValue = {
        ...state,
        requesting:true,
        successful:false,
        payload:{loads:[],availability:{}},
      }
      return retValue

    case 'UPDATE_OUT_OF_NETWORK_LOADS_SUC':
      var retValue = {
        ...state,
        requesting:false,
        successful:true,
        payload:action.payload,
      }
      return retValue

    case 'UPDATE_OUT_OF_NETWORK_LOADS_ERR':
      var retValue = {
        ...state,
        requesting:false,
        successful:false,
      }
      return retValue
    default:
      return state
  }
}

export default reducer
