import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose, withProps } from 'recompose'
import { Row, Col } from 'react-bootstrap';
import FA from 'react-fontawesome'
import LoadSearchBar from "../components/LoadSearchBar"
import * as BestMatchedLoadsApi from '../components/best-matched-loads/api-best-matched-loads'
import moment from 'moment'
import CarrierLoadList from '../components/load-list'
import FilterMenu from "../components/filter-menu/index"
import LoadingOverlay from '../../components/LoadingOverlay'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { toast } from 'react-toastify';
import useGtagHook from '../../gtag-hook';
import isMobile from '../../HOC/Mobile.js'
import LoadSearchMobileWrapper from '../components/LoadSearchBar/LoadSearchMobileWrapper'


const HomeScreen = ({ muiTheme, availability, isMobile }) => {

    // const myAuthService = useAuthenticationHook()

    const { height, width } = useWindowDimensions();

    const useStyles = makeStyles({
        container: {
            height: height,
            width: '100%'
        },
        flexContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        flexItem: {
            paddingTop: 5,
            paddingBottom: 5
        },
        badge: {
            height: 20,
            width: 20,
            borderRadius: 50,
            display: "flex",
            justifyContent: "center",
            backgroundColor: muiTheme.secondary
        },
        breadcrumbLink: {
            cursor: 'pointer',
            marginRight: 15,
            color: "black", // TODO: change??
            "&:hover": {
                color: muiTheme.actionColor,
                textDecoration: "underline"
            }
        },
        activeBreadcrumbLink: {
            color: muiTheme.primary,
            fontWeight: 'bold',
            textDecoration: "underline",
            "&:hover": {
                color: muiTheme.primary,
            }
        },
        secondaryText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            color: 'grey'
        },
        sortSelectItem: {
            border: '1px solid #ccc',
            backgroundColor: 'white',
            fontSize: 14,
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            paddingLeft: '10px',
        },
        icon: {
            position: 'unset',
            fontSize: '2.5rem',
            textAlign: 'right'
        },
        refreshButton: {
            fontSize: 16,
            color: "black",
            marginLeft: 5,
            paddingLeft: 5,
            paddingRight: 5,
            cursor: 'pointer',
            borderRadius: 10,
            '&:hover': {
                boxShadow: '0px 2px 10px #999999',
            }
        }
    });
    const classes = useStyles()

    const [originalLoadList, setOriginalLoadList] = useState([])
    const [loadsStack, setLoadsStack] = useState([])
    const [currentLoadList, setCurrentLoadList] = useState([])
    const [reloadCrumbs, setReloadCrumbs] = useState([])
    const [reloadsOpen, setReloadsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [currentLoadListCount, setCurrentLoadListCount] = useState(0) //this will be updated by the load list when a filter is applied

    const sortSelection = { "BestMatch": 0, "Deadhead": 1, "Pickup": 2 }
    const [selectedSort, setSelectedSort] = useState(0)
    const [refreshList, setRefreshList] = useState(false)

    const [displayTime, setDisplayTime] = useState(null)
    const [listLastFetch, setListLastFetched] = useState()

    const FILTER_PADDING_TOP = 177
    const [sendGtagEvent] = useGtagHook()



    useEffect(() => {
        fetchBestMatchedLoads()
    }, [])

    useEffect(() => {
        setDisplayTime(moment().fromNow())
        const timer = setInterval(() => {
            setDisplayTime(moment(listLastFetch).fromNow())
        }, 60000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    }, [listLastFetch]);

    const fetchBestMatchedLoads = () => {
        setIsLoading(true)
        let params = {
            advantageId: (muiTheme.customerUniqueId === "c0419cm1" || muiTheme.customerUniqueId === "ch7h4g68r") ? "32m9lbe4" : muiTheme.customerUniqueId,
            limit: 10
        }
        // make api call to fetch recent activity
        BestMatchedLoadsApi.GetBestMatchedLoads(params).then(response => {
            if (response.status) {
                if (response.loads && response.loads.length > 0) {
                    setLoadsStack([response.loads])
                    setCurrentLoadList(response.loads)
                    setOriginalLoadList(JSON.parse(JSON.stringify(response.loads)))
                }
                setListLastFetched(moment())
                setIsLoading(false)
                sendGtagEvent(
                    "search_results_returned",
                    {
                        screen: "home",
                        listLength: response.loads ? response.loads.length : 0,
                        type: "loads",
                    }
                )
            } else {
                let errorMessage = "Error: There was a problem fetching your best matched loads."
                setIsLoading(false)
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem fetching your best matched loads."

            setIsLoading(false)
            toast.error(errorMessage)
        })
    }

    useEffect(() => {
        setCurrentLoadListCount(currentLoadList.length)
    }, [currentLoadList])

    useEffect(() => {
        if (loadsStack.length > 0) {
            setCurrentLoadList(loadsStack[loadsStack.length - 1])
            setOriginalLoadList(JSON.parse(JSON.stringify((loadsStack[loadsStack.length - 1]))))
            if (selectedSort !== null && selectedSort !== 0) {
                onSelectSort(selectedSort, loadsStack[loadsStack.length - 1])
            }
        }
    }, [loadsStack])

    useEffect(() => {
        if (reloadCrumbs.length <= 0)
            setReloadsOpen(false)
        else
            setReloadsOpen(true)
    }, [reloadCrumbs])


    const pushReloads = (parentLoad, reloads) => {
        //todo: push another param for date besides relying on scheduledAt to be in stops
        let tmpLoadOrigin = {
            ...parentLoad.stops[0],
            date: parentLoad.pickupDateTimeLatest ? parentLoad.pickupDateTimeLatest : parentLoad.pickupDateTime
        }
        let tmpLoadDestination = {
            ...parentLoad.stops[parentLoad.stops.length - 1],
            date: parentLoad.deliveryDateTime
        }
        if (reloadCrumbs.length <= 0) {
            setReloadCrumbs([
                tmpLoadOrigin,
                tmpLoadDestination
            ])
        } else {
            setReloadCrumbs([
                ...reloadCrumbs,
                tmpLoadDestination
            ])
        }
        setLoadsStack([
            ...loadsStack,
            reloads
        ])
        sendGtagEvent(
            "search_results_returned",
            {
                screen: "home",
                listLength: reloads.length,
                type: "reloads"
            }
        )
    }

    const popToIndex = (index) => {
        let newLoadsStack = loadsStack.slice(0, index + 1)
        let newReloadCrumbs = reloadCrumbs.slice(0, index + 1)
        setLoadsStack(newLoadsStack)
        if (index === 0)
            setReloadCrumbs([])
        else
            setReloadCrumbs(newReloadCrumbs)
    }

    const onSelectSort = (value, loadList) => {
        let tempLoads = loadList
        if (value === sortSelection.BestMatch && originalLoadList && originalLoadList.length > 0) {
            setSelectedSort(sortSelection.BestMatch)
            tempLoads = JSON.parse(JSON.stringify(originalLoadList))
        } else if (value === sortSelection.Deadhead) {
            setSelectedSort(sortSelection.Deadhead)
            tempLoads = loadList.sort((a, b) => {
                return a.deadHead - b.deadHead
            })
        } else if (value === sortSelection.Pickup) {
            setSelectedSort(sortSelection.Pickup)
            tempLoads = loadList.sort((a, b) => {
                return moment(a.pickupDateTime, "MM/DD/YYYY HH:mm A").valueOf() - moment(b.pickupDateTime, "MM/DD/YYYY HH:mm A").valueOf()
            })
        }
        setCurrentLoadList(tempLoads)
        setTimeout(() => setRefreshList(!refreshList), 0)

    }

    const getCurrentPickupLocation = () => {

        if (!reloadsOpen) {
            return currentLoadListCount > 1 ? "best matched loads found" : "best matched load found"
        } else if (reloadCrumbs[reloadCrumbs.length - 2]) {
            let parentLoad = reloadCrumbs[reloadCrumbs.length - 2]
            return (currentLoadListCount > 1 ? "reloads" : "reload") + " found near " + parentLoad.city + ", " + parentLoad.state
        }
    }

    const handleRefetchList = () => {
        fetchBestMatchedLoads()
    }

    return (
        <div style={{ height: '100%' }}>

            {isLoading && <LoadingOverlay />}

            {!isMobile &&
                <div className={classes.container}>

                    <Row style={{ height: '100%' }}>
                        {currentLoadList.length > 0 &&
                            <Col
                                md={2}
                                style={{
                                    paddingTop: FILTER_PADDING_TOP,
                                    backgroundColor: 'white',
                                    height: '100%'
                                }}
                            >
                                <FilterMenu loadList={currentLoadList} paddingTop={FILTER_PADDING_TOP} />
                            </Col>
                        }

                        <Col md={currentLoadList.length > 0 ? 10 : 12} className={classes.flexContainer} style={{height:'100%'}} >
                            {/* {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && (
                                <p>Show Services</p>
                            )} */}
                            <div className={classes.flexItem} style={{paddingLeft:10}}>
                                <LoadSearchBar onHomePage={true} />
                            </div>
                            {currentLoadList && currentLoadList.length > 0 &&
                                <div className={classes.flexItem}>

                                    <Col style={{ height: "100%", width: "75%", display: "inline-flex", paddingLeft: 15, alignItems: 'flex-end', margin: 0 }}>
                                        <p style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginRight: 15, marginBottom: 0, paddingBottom: 0, whiteSpace: 'nowrap', color: 'black', fontWeight: 'bold', fontSize: 25, lineHeight: '25px' }}>
                                            {currentLoadListCount} {getCurrentPickupLocation()}
                                        </p>
                                        {!reloadsOpen &&
                                            <div style={{ display: 'flex' }}>
                                                <p style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', fontSize: 16, lineHeight: '20px' }}>
                                                    List last refreshed {displayTime}
                                                </p>
                                                <a className={classes.refreshButton} onClick={() => handleRefetchList()}>
                                                    <FA name='sync' style={{ fontSize: 16, color: muiTheme.actionColor, padding: 0 }} />
                                                </a>
                                            </div>
                                        }
                                    </Col>

                                    <Col style={{ height: "100%", width: "25%", display: "inline-block", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: 35, position: 'relative' }}>
                                        <div style={{ position: 'absolute', bottom: 0, right: 15 }}>
                                            {/* <p style={{ display: 'inline-block', paddingRight: 15, paddingBottom: 7, verticalAlign: 'bottom', color: 'black', fontWeight: 'bold' }}>Sort By:</p> */}
                                            <FormControl>
                                                <FormHelperText style={{ fontSize: 12 }}>{selectedSort == 1 ? "lowest to highest" : selectedSort == 2 ? "earliest to latest" : "sorted by"}</FormHelperText>
                                                <Select
                                                    className={classes.sortSelectItem}
                                                    onChange={(event) => { onSelectSort(event.target.value, currentLoadList) }}
                                                    displayEmpty
                                                    disableUnderline={true}
                                                    style={{ minWidth: 215 }}
                                                    value={selectedSort}
                                                    defaultValue={sortSelection.BestMatch}
                                                    inputProps={{
                                                        classes: {
                                                            icon: classes.icon
                                                        },
                                                        ariaLabel: selectedSort == 1 ? "lowest to highest" : selectedSort == 2 ? "earliest to latest" : "sorted by"
                                                    }}
                                                >
                                                    <MenuItem value={0} style={{ fontSize: 14 }}>Best Match</MenuItem>
                                                    {/* <MenuItem value={1} style={{ fontSize: 14 }}>Distance from search location</MenuItem> */}
                                                    <MenuItem value={2} style={{ fontSize: 14 }}>Pickup date {"&"} time</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Col>
                                </div>
                            }
                            {reloadsOpen &&
                                <div className={classes.flexItem}>
                                    <div style={{paddingLeft: 15}}>
                                        {
                                            reloadCrumbs.map((crumb, index) => {
                                                return (
                                                    <span key={'reloads_crumb_' + index}>

                                                        {(index < reloadCrumbs.length - 1) &&
                                                            <span>
                                                                <div style={{ display: 'inline-block' }}>
                                                                    <span className={classes.breadcrumbLink} onClick={() => popToIndex(index)}>
                                                                        {crumb.city + ', ' + crumb.state}
                                                                    </span>
                                                                    <p className={classes.secondaryText}>
                                                                        {moment(crumb.date).format('MM/DD')}
                                                                    </p>
                                                                </div>
                                                                <FA name='chevron-right' size="lg" color={"black"} style={{ marginRight: 15, verticalAlign: 'top' }} />

                                                            </span>
                                                        }

                                                        {index === reloadCrumbs.length - 1 &&

                                                            <div style={{ display: 'inline-block' }}>
                                                                <span className={classes.activeBreadcrumbLink} onClick={() => popToIndex(index)}>
                                                                    {crumb.city + ', ' + crumb.state}
                                                                </span>
                                                                <p className={classes.secondaryText}>
                                                                    {moment(crumb.date).format('MM/DD')}
                                                                </p>
                                                            </div>


                                                        }
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }           
                            {(!isLoading && currentLoadList && currentLoadList.length > 0) ?
                                <div className={classes.flexItem} style={{maxHeight:'100%'}}>
                                    <CarrierLoadList
                                        key={refreshList}
                                        unfilterLoadList={currentLoadList}
                                        availability={availability}
                                        isInNetwork={true}
                                        pushReloads={pushReloads}
                                        reloadsOpen={reloadsOpen}
                                        setCurrentLoadListCount={setCurrentLoadListCount}
                                        isHome={true}
                                    />
                                </div>
                                :
                                <div className={classes.flexItem} style={{paddingTop:50}}>
                                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                        No Loads Found
                                    </p>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
            }

            {isMobile &&
                <div style={{ height: '100%' }}>


                    {!isLoading && currentLoadList.length > 0 &&
                        <Row style={{ height: 120, marginLeft: 10, marginRight: 10 }}>
                            <Row>
                                <Col style={{ display: 'inline-block', float: 'left' }}>
                                    <LoadSearchMobileWrapper onHomePage={true} />
                                </Col>
                                <Col style={{ display: 'inline-block', float: 'right' }}>
                                    <FilterMenu loadList={currentLoadList} paddingTop={FILTER_PADDING_TOP} mobileView={true} />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 8 }}>
                                <Col style={{ width: "35%", height: "100%", position: 'relative', display: "inline-block", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-start", paddingLeft: 0 }}>
                                    <p style={{ position: 'absolute', top: 10, marginBottom: 0, paddingBottom: 0, color: 'black', fontSize: 14, fontWeight: 'bold' }}>{currentLoadListCount} best matched loads found</p>
                                </Col>
                                <Col style={{ display: 'inline-block', float: 'right', justifyContent: "flex-end" }}>
                                    <div style={{ flex: 1, flexDirection: 'column', alignSelf: 'center', marginTop: 25 }}>
                                        <p style={{ textAlign: 'right', paddingRight: 5 }}>
                                            <p style={{ fontSize: 12, color: 'grey' }}>{selectedSort == 1 ? "lowest to highest" : selectedSort == 2 ? "earliest to latest" : "sorted by"} </p>
                                        </p>
                                        <div style={{ flex: 1, flexDirection: 'row', alignSelf: 'flex-end', display: 'inline-block' }}>
                                            <p
                                                onClick={() => onSelectSort(0, currentLoadList)}
                                                style={{
                                                    fontSize: 16,
                                                    padding: 5,
                                                    color: (selectedSort === 0 || selectedSort === null) ? muiTheme.primary : muiTheme.actionColor,
                                                    textDecorationLine: (selectedSort === 0 || selectedSort === null) ? "underline" : "none",
                                                    display: 'inline-block'
                                                }}>
                                                Best
                                            </p>
                                            {/* <p style={{ fontSize: 16, paddingTop: 5, paddingBottom: 5, display: 'inline-block' }}>|</p>
                                            <p
                                                onClick={() => onSelectSort(1, currentLoadList)}
                                                style={{
                                                    fontSize: 16,
                                                    padding: 5,
                                                    color: selectedSort === 1 ? muiTheme.primary : muiTheme.actionColor,
                                                    textDecorationLine: selectedSort === 1 ? "underline" : "none",
                                                    display: 'inline-block'
                                                }}>
                                                Deadhead
                                            </p> */}
                                            <p style={{ fontSize: 16, paddingTop: 5, paddingBottom: 5, display: 'inline-block' }}>|</p>
                                            <p
                                                onClick={() => onSelectSort(2, currentLoadList)}
                                                style={{
                                                    fontSize: 16,
                                                    padding: 5,
                                                    color: selectedSort === 2 ? muiTheme.primary : muiTheme.actionColor,
                                                    textDecorationLine: selectedSort === 2 ? "underline" : "none",
                                                    display: 'inline-block'
                                                }}>
                                                Pickup
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Row>
                    }
                    {!isLoading &&
                        <div style={{ display: 'contents' }}>
                            {(currentLoadList && currentLoadList.length > 0) ?
                                <Row style={{ height: 'calc(100% - 120px)' }} >
                                    <CarrierLoadList
                                        key={refreshList}
                                        unfilterLoadList={currentLoadList}
                                        availability={availability} //todo: may not need this
                                        pushReloads={pushReloads}
                                        reloadsOpen={reloadsOpen}
                                        setCurrentLoadListCount={setCurrentLoadListCount} //used for when a filter is applied
                                        isHome={true}
                                        isMobile={true}
                                    />
                                </Row>
                                :
                                <div style={{ paddingTop: 50 }}>
                                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                        No Loads Found
                                    </p>
                                    <Col style={{ textAlign: 'center' }}>
                                        <LoadSearchMobileWrapper buttonColor={muiTheme.actionColor} onHomePage={true} />
                                    </Col>
                                </div>
                            }
                        </div>
                    }
                </div>
            }


        </div>
    )
}

const mapStateToProps = state => ({
    availability: state.AvailabilityLoads.payload.availability,

})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(HomeScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}