import React, { useState } from 'react';
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import isMobile from '../../../../../HOC/Mobile.js'

import { Col, Row } from "react-bootstrap"
import FlatButton from 'material-ui/FlatButton';
import { saveQuoteAction } from '../../../../../components/Quote/actions'
import TextField from '@material-ui/core/TextField';
import useGtagHook from "../../../../../gtag-hook"

import CurrencyInput from '../../../../../components/Quote/CurrencyInput'

import moment from 'moment'


const CounterOfferForm = (props) => {
    const classes = useStyles();
    const {
        load,
        muiTheme,
        submitQuoteNegotiation = () => { },
        pickup,
        delivery,
        isSaving,
        quoteValue=null,
        isMobile
    } = props


    const [counterOfferValue, updateCounterOfferValue] = useState(0)
    const [quoteMessage, updateQuoteMessage] = useState("")
    const [sendGtagEvent] = useGtagHook()

    function onValueChange(value) {
        updateCounterOfferValue(value);
    }
    const getIcon = (market, type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type == "origin") {
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        }

        // if (checkValueExists(market, key)) {
        //     if (market[key] >= 80) {
        //         return (<FA name='fire' color='#9A1F25' />)
        //     } else if (market[key] >= 65 && market[key] < 80) {
        //         return (<FA name='fire' color='#D96C1E' />)
        //     } else if (market[key] >= 50 && market[key] < 65) {
        //         return (<FA name='fire' color='#FFB400' />)
        //     } else if (market[key] >= 35 && market[key] < 50) {
        //         return (<FA name='snowflake' color='#5DBFFF' />)
        //     } else if (market[key] > 20 && market[key] < 35) {
        //         return (<FA name='snowflake' color='#0086E1' />)
        //     } else if (market[key] <= 20) {
        //         return (<FA name="snowflake" color={'#002F81'} />)
        //     }
        // }

        return icon
    }


    return (
        <div>
            <div style={{ width: '100%', padding: 10, backgroundColor: "white" }} key={'quoteInputFor' + load.id}>
                <div style={{marginBottom: 20}}>
                    <p style={{ display: 'inline-flex', fontFamily: 'Arial !important', fontSize: 24, color: 'black', fontWeight: 'bold', lineHeight: '24px' }}>Counter Offer</p>
                    <p style={{ display: isMobile ? 'none' : 'inline-flex', float: 'right', fontFamily: 'Arial !important', fontSize: 16, lineHeight: '24px', paddingRight: 10 }}>Last Offer ${quoteValue}</p>
                </div>
                <div style={{ display: "flex", marginBottom: 25 }}>
                    <Col style={{ width: "3%", marginRight: "2%", flexDirection: "col", alignItems: "center", }}>
                        <Row className={classes.centerRowContent} style={{ padding: '5px', alignItems: 'center' }}>
                            <Row>
                                {getIcon(load, "origin")}
                            </Row>
                        </Row>
                        <Row className={classes.centerRowContent} style={{ alignItems: 'center' }}>
                            <div style={{ width: 1, height: 55, backgroundColor: "black" }} />
                        </Row>
                        <Row className={classes.centerRowContent} style={{ padding: '5px', alignItems: 'center' }}>
                            <Row>
                                {getIcon(load, "destination")}
                            </Row>
                        </Row>
                    </Col>
                    <Col style={{ width: "52%", marginRight: "3%" }}>
                        <Row className={classes.row}>
                            <p className={classes.mainText} >
                                {pickup.city}, {pickup.state}
                            </p>
                        </Row>
                        <Row className={classes.row} style={{ marginBottom: 10 }}>
                            <p className={classes.secondaryText}>
                                {moment(pickup.scheduledAt).format('ll')}
                            </p>
                            <p className={classes.secondaryText}>
                                {moment(pickup.scheduledAt).format('HH:mm')}
                            </p>
                        </Row>
                        <Row className={classes.row}>
                            <p className={classes.mainText} >
                                {delivery.city}, {delivery.state}
                            </p>
                        </Row>
                        <Row className={classes.row}>
                            <p className={classes.secondaryText}>
                                {moment(delivery.scheduledAt).format('ll')}
                            </p>
                            <p className={classes.secondaryText}>
                                {moment(delivery.scheduledAt).format('HH:mm')}
                            </p>
                        </Row>
                    </Col>
                    <Col style={{ width: "35%" }}>
                        <p className={classes.secondaryText}>Counter Offer</p>
                        <CurrencyInput onValueChange={onValueChange} value={counterOfferValue} />
                    </Col>
                </div>
                {muiTheme.customerUniqueId != "a98db973" && (
                    <div style={{ width: '100%', padding: 10, paddingBottom: 25, backgroundColor: "white" }} key={'quoteMessageInputFor' + load.id}>
                        <p className={classes.mainText}>Additional Message (Optional)</p>
                        <p className={classes.secondaryText}>Message</p>
                        <TextField
                            inputProps={{ style: { fontSize: 16, padding: 5, lineHeight: 'normal' } }}
                            key="quote_message_input"
                            fullWidth={true}
                            value={quoteMessage}
                            multiline
                            rows={4}
                            variant="outlined"
                            onChange={(event) => updateQuoteMessage(event.target.value)}
                        />
                    </div>
                )}
            </div>
            <div style={{ width: '100%', display: 'inline-block', backgroundColor: "white", padding: 10, paddingBottom: 15 }} key={'ActionsFor' + load.id}>
                <div style={{ width: '33%', display: 'inline-block', backgroundColor: "white" }}>
                    <FlatButton
                        fullWidth={true}
                        label={"Close"}
                        disableTouchRipple={true}
                        disableFocusRipple={true}
                        primary={true}
                        style={{ marginRight: 12, color: muiTheme.actionColor, borderColor: muiTheme.actionColor }}
                        onClick={() => {
                            props._onClose()
                        }}
                    />
                </div>
                {/* <div style={{ width: showSaveButton ? '62%' : '100%', display: 'inline-block', marginRight: showSaveButton ? '5%' : 0, backgroundColor: "white" }}> */}
                <div style={{ width: '62%', display: 'inline-block', marginRight: 0, backgroundColor: "white" }}>
                    <FlatButton
                        fullWidth={true}
                        disabled={isSaving || !counterOfferValue}
                        label={"Send"}
                        disableTouchRipple={true}
                        disableFocusRipple={true}
                        secondary={true}
                        style={{ marginRight: 12, backgroundColor: muiTheme.actionColor, color: "white" }}
                        onClick={() => {
                            submitQuoteNegotiation( (counterOfferValue/100).toFixed(2), quoteMessage)
                            props._onClose()
                            sendGtagEvent("submit_quote_counter", {loadId: load.loadId, brokerId: load.brokerId}) //todo add to GA & import
                        }}                    
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        saveQuoteAction: saveQuoteAction,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable(),
    isMobile()
)(CounterOfferForm)

const useStyles = makeStyles({
    mainText: {
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold'
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '21px',
        color: 'grey'
    },
    row: {
        padding: 0,
        margin: 0
    },
    centerRowContent: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    }
});