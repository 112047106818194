import React, { useState } from 'react';
import { compose } from 'recompose';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

//COMPONENTS
import { Form, Row, Col } from 'react-bootstrap'
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import AddressAutocomplete from './AddressAutocompleteV2FuelSearch'


const useStyles = makeStyles({
    margin: {
        margin: 5,
        maxHeight: '40px',
    },
});


const FuelSearchBarDesktop = ({
    searchLocation,
    setSearchLocation,
    muiTheme,
    handleSearch
}) => {

    const classes = useStyles()

    const handleSetSearchLocation = (value) => {
        setSearchLocation(value);
    };

    return (
        <Form>
            <Row style={{ display: 'inline-flex', columnGap: '10px' }}>
                <Col>
                    <FormControl style={{ margin: 0 }}>
                        <AddressAutocomplete
                            onChange={() => { }}
                            value={searchLocation}
                            handleSetSearchLocation={handleSetSearchLocation}
                        />
                    </FormControl>
                </Col>
                <Col style={{}}>
                    <Button
                        variant={"contained"}
                        size={"small"}
                        className={classes.margin}
                        style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, marginRight: 10 }}
                        onClick={() => handleSearch()}
                    >
                        Search
                </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default compose(
    muiThemeable(),
)(FuelSearchBarDesktop)