//////////////////////////////////////////////////////
////////ACTIONS///////////////////////////////////////
//////////////////////////////////////////////////////

export function updateAvailContact(availabilityId,phone,email){
	return{
		type: 'UPDATE_AVAIL_CONTACT_REQ',
		availabilityId,
    phone,
    email,
	}
}


//////////////////////////////////////////////////////
////////REDUCER///////////////////////////////////////
//////////////////////////////////////////////////////

const initialState = {
  requesting: false,
  successful: false,
  payload:[],
  messages: [],
  errors: [],
}

const reducer = function loginReducer (state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_AVAIL_CONTACT_REQ':
      var retValue = {
        ...state,
        requesting:true,
      }
      return retValue;

    case 'UPDATE_AVAIL_CONTACT_SUC':
      var retValue = {
        ...state,
        requesting:false,
        successful:true,
      }
      return retValue;

    case 'UPDATE_AVAIL_CONTACT_ERR':
      var retValue = {
        ...state,
        requesting:true,
        successful:false,
      }
      return retValue;

    default:
      return state
  }
}

export default reducer
