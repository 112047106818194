//MESSAGING
  import * as MESSAGES from './constants'

const initialState = {  
  requesting: false,
  successful: false,
  requesting_zonePrefs: false,
  successful_zonePrefs: false,
  payload:[],
  zonePrefs:[],
  messages: [],
  errors: [],
}

const reducer = function lanePrefs (state = initialState, action) {  
  switch (action.type) {
    case MESSAGES.GET_ZONES_SUCCESS:
      var retValue = {
        ...state,
        payload:action.payload,
        requesting:false,
        successful:true
      }
      return retValue
    case MESSAGES.GET_ZONES_REQUESTING:
      var retValue = {
        ...state,
        requesting:true,
        successful:false
      }
      return retValue
    case MESSAGES.GET_ZONE_PREFS_REQUESTING:
      var retValue = {
        ...state,
        requesting_zonePrefs:true,
        successful_zonePrefs:false
      }
      return retValue
    case MESSAGES.GET_ZONE_PREFS_SUCCESS:
      var retValue = {
        ...state,
        requesting_zonePrefs:false,
        successful_zonePrefs:true,
        zonePrefs:action.payload
      }
      return retValue

    default:
      return state
  }
}

export default reducer  