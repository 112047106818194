import { object } from 'prop-types';
import React, { useState } from 'react';
export const TireScreenContext = React.createContext();

/**
 *  Globalized functions and state variables that hosts the logic for the application. 
 */

export const Provider = (props) => {

    /* React Application State Data*/
    const [originalData, setOriginalData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [canUseFieldMap, setCanUseFieldMap] = useState(false)
    const [fieldMap, setFieldMap] = useState({
        "brand": [],
        "model": [],
        "ply": [],
        "position": [],
        "size": [],
        "type": [],
        "vendor": []
    })
    const [searchSelections, setSearchSelections] = useState({
        "brand": [],
        "model": [],
        "ply": [],
        "position": [],
        "size": [],
        "type": [],
        "vendor": []
    })
    const [refreshFlag, setRefreshFlag] = useState(false);

    /* React Life Cycle Use Effects - On load we set the state data */
    // CurrentLoadListData is what gets displayed. OriginalLoadListData is saved off for filter options to avoid over-writing data.
    // useEffect(() => {
    // setOriginalData([]);
    // }, []);

    /* React Application Functions */
    const handleNewData = (data) => {
        setOriginalData(data)
        setFilteredData(data)
    }

    const handleFilteredData = (data) => {
        setFilteredData(data)
    }

    const handleClearCart = () => {
        setCartData([])
    }

    const handleSelectItem = (item) => {
        if (cartData.length > 0) {
            let tmpCart = cartData
            let foundItems = tmpCart.filter((obj) => obj.serial === item.serial)
            const foundItem = foundItems[0]
            const foundIndex = cartData.indexOf(foundItem)
            if (foundIndex > -1) {
                tmpCart[foundIndex]["quantity"] = tmpCart[foundIndex]["quantity"] + 1
                setCartData(tmpCart)
            } else {
                setCartData([...cartData, item])
            }
        } else {
            setCartData([...cartData, item])
        }
    }

    const handleDeleteItem = (item) => {
        let tmp = cartData
        let foundItems = tmp.filter((obj) => obj.serial === item.serial)
        const foundItem = foundItems[0]
        // console.log("foundItem: ", foundItem)
        tmp.splice(tmp.indexOf(foundItem), 1)
        setCartData(tmp)
        setRefreshFlag(!refreshFlag)
    }

    const handleQuantityUpdate = (item, quantity) => {
        if (cartData.length > 0) {
            let tmpCart = cartData
            let foundItems = tmpCart.filter((obj) => obj.serial === item.serial)
            const foundItem = foundItems[0]
            const foundIndex = cartData.indexOf(foundItem)
            if (foundIndex > -1) {
                tmpCart[foundIndex] = { ...item, ...{ quantity: quantity } }
                setCartData(tmpCart)
            }
        }
    }

    const handleNewFields = (dataMap) => {
        let tmpMap = fieldMap
        Object.keys(dataMap).forEach((field) => {
            tmpMap[field] = dataMap[field]
        })
        setFieldMap(tmpMap)
        setCanUseFieldMap(true)
    }

    const handleMarkFieldMapComplete = (flag) => {
        setCanUseFieldMap(flag)
    }

    const handleSearchSelectionsUpdate = (dataMap) => {
        setSearchSelections(dataMap)
    }

    /* --------------------------------------------------*/
    return (
        <TireScreenContext.Provider value={{
            originalData,
            filteredData,
            cartData,
            fieldMap,
            canUseFieldMap,
            searchSelections,
            functions: {
                handleNewData: handleNewData,
                handleFilteredData: handleFilteredData,
                handleClearCart: handleClearCart,
                handleSelectItem: handleSelectItem,
                handleDeleteItem: handleDeleteItem,
                handleQuantityUpdate: handleQuantityUpdate,
                handleNewFields: handleNewFields,
                handleMarkFieldMapComplete: handleMarkFieldMapComplete,
                handleSearchSelectionsUpdate: handleSearchSelectionsUpdate
            }
        }}>
            {props.children}
        </TireScreenContext.Provider>
    );
}