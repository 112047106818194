import React from 'react';

import muiThemeable from 'material-ui/styles/muiThemeable';

import List from '@material-ui/core/List';

import TireItem from './tire-cards/tire-item';

const TireList = (props) => {

    const {
        muiTheme,
        currentTireList,
        isCartVersion = false
    } = props

    return (
        <div style={{ height: '100%' }}>
            <List style={{ height: '100%', width: '100%', maxHeight: '100%', overflowX: 'hidden', overflowY: "scroll", paddingTop: 0 }}>
                {currentTireList.length > 0 ?
                    currentTireList.map((item, index) => {
                        return (
                            <TireItem
                                key={item.serial}
                                item={item}
                                itemIndex={index}
                                isCartVersion={isCartVersion}
                            />
                        )

                    })
                    :
                    <div style={{ paddingTop: !isCartVersion ? 25 : 5 }}>
                        <div style={{ width: '100%', textAlign: !isCartVersion ? 'center' : 'left' }}>
                            {isCartVersion ? (
                                <p style={{ fontSize: 16, fontWeight: "bold", color: muiTheme.ttRed }}>No tires selected. If you wish to receive a quote, please use the add button on items you are interested in.</p>
                            ) : (
                                <h3>No Tires Found</h3>
                            )}
                        </div>
                    </div>
                }
            </List>
        </div>
    )

}

export default muiThemeable()(TireList)