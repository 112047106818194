import * as Constants from './constants'
import {Integration_Type} from '../constants'


const initialState = {
  requesting: false,
  successful: false,  
  fourkites: Constants.FOURKITES_INITIAL,
  macropoint: Constants.MACROPOINT_INITIAL,
  jbhunt: Constants.JBHUNT_INITIAL, 
  id: 0, 
  initialValues: null,
}

const reducer = function credentialsReducer (state = initialState, action) {
  //console.log('reducer',action.type)
  switch (action.type) {
      case Constants.CREDENTIALS_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Looking up your credentials...', time: new Date() }],
        errors: [],        
      }
    case Constants.CREDENTIALS_SUCCESS: 
      const { fourkites, macropoint, jbhunt } = processCredentialsResponse(action.response);         
      return {
        ...state,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        fourkites: fourkites,
        macropoint: macropoint,
        jbhunt: jbhunt,  
      }
      case Constants.CREDENTIALS_SAVE_REQUESTING:
        return {
          ...state,
          requesting: true,
          successful: false,
          messages: [{ body: 'Saving your credentials...', time: new Date() }],
          errors: [],        
        }
      case Constants.CREDENTIALS_SAVE_SUCCESS:
        const { f, m, j } = setValues(state, action.lt_customer_id, action.integrationType, action.isAdd);         
        return {
          ...state,
          errors: [],
          messages: [],
          requesting: false,
          successful: true,
          fourkites: f,
          macropoint: m,
          jbhunt: j,  
        }      
    default:
      return state
  }
}

function processCredentialsResponse(response) {
  const { status, integrationCredentials } = response || {}
  const credentials = {
    fourkites: Constants.FOURKITES_INITIAL,
    macropoint: Constants.MACROPOINT_INITIAL,
    jbhunt: Constants.JBHUNT_INITIAL
  }  

  if (status) {
    const {fourkites, macropoint, jbhunt} = integrationCredentials || {};
    if (fourkites && fourkites.length > 0){
      credentials.fourkites = fourkites[0];      
    }
    if (macropoint && macropoint.length > 0){
      credentials.macropoint = macropoint[0];      
    }
    if (jbhunt && jbhunt.length > 0){
      credentials.jbhunt = jbhunt[0];      
    }
  }
  return credentials;
}

function setValues(state, lt_customer_id, integrationType, isAdd) {
  const fourkites = state.fourkites;
  const macropoint = state.macropoint;
  const jbhunt = state.jbhunt;
  if (isAdd) {
    if (integrationType === Integration_Type.fourkites) {
      fourkites.lt_customer_id = lt_customer_id;    
    }
    if (integrationType === Integration_Type.macropoint) {
      macropoint.lt_customer_id = lt_customer_id;    
    }
    if (integrationType === Integration_Type.jbhunt) {
      jbhunt.lt_customer_id = lt_customer_id;    
    }
  }
  return {
    f: fourkites,
    m: macropoint,
    j: jbhunt
  };
}

export default reducer