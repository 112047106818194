
export const FOURKITES = 'fourkites';
export const MACROPOINT = 'macropoint';

export const CREDENTIALS_REQUESTING = 'CREDENTIALS_REQUESTING';
export const CREDENTIALS_SUCCESS = 'CREDENTIALS_SUCCESS';

export const CREDENTIALS_SAVE_REQUESTING = 'CREDENTIALS_SAVE_REQUESTING';
export const CREDENTIALS_SAVE_SUCCESS = 'CREDENTIALS_SAVE_SUCCESS';

export const FOURKITES_INITIAL =  {    
    lt_customer_id: 0,                                                
    identifier: 'customer_id',
    client_id: null,
    secret: null,
    scac: null
};                
export const MACROPOINT_INITIAL = {    
    lt_customer_id: 0,    
    identifier: 'customer_id',
    login: null,
    password: null,
};                
export const JBHUNT_INITIAL  = {
    lt_customer_id: 0,    
    identifier: null,
    client_id: null,
    client_secret: null,
};








