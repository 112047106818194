import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col } from 'react-bootstrap';
import FA from 'react-fontawesome'
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';

import CarrierLoadList from '../../components/load-list'
import FilterMenu from "../../components/filter-menu/index"
import DaysBackSelector from "../../components/my-loads/days-back-selector"

import * as MyLoadsApi from '../../apis/api-booked-loads'
import LoadingOverlay from '../../../components/LoadingOverlay'
import useGtagHook from '../../../gtag-hook';
import isMobile from '../../../HOC/Mobile.js'

import { mockData } from './mock-booked-loads'
import ListPagination from '../ListPagination';

const MyLoadScreen = ({ loads, availability, muiTheme, isMobile, isPaginationEnabled }) => {
    const { height, width } = useWindowDimensions();

    const useStyles = makeStyles({
        container: {
            padding: 5,
            height: height,
            width: '100%'
        },
        badge: {
            height: 20,
            width: 20,
            borderRadius: 50,
            display: "flex",
            justifyContent: "center",
            backgroundColor: muiTheme.secondary
        },
        breadcrumbLink: {
            cursor: 'pointer',
            marginRight: 15,
            color: "black", // TODO: change??
            "&:hover": {
                color: muiTheme.actionColor,
                textDecoration: "underline"
            }
        },
        activeBreadcrumbLink: {
            color: muiTheme.primary,
            fontWeight: 'bold',
            textDecoration: "underline",
            "&:hover": {
                color: muiTheme.primary,
            }
        },
        secondaryText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            color: 'grey'
        },
        header: {
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderRadius: 0,
            textTransform: "none",
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        headerText: {
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 16,
            fontWeight: 'bold',
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        formControl: {
            minWidth: isMobile ? 'unset' : 200,
        },
        menuItem: {
            fontSize: '16px',
            textAlign: 'center'
        },
        removePadding: {
            paddingBottom: 0
        },
        selectfieldStyleOutline: {
            backgroundColor: 'white',
            // border: '1px solid #ccc',
            height: '45px',
            width: '180px',
            maxWidth: '180px',
            // margin: '0px',
            // backgroundColor: 'white',
            paddingLeft: '10px',
            paddingRight: '0px',
            paddingBottom: '0px',
            fontSize: '16px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign: 'center'
        },
        flexContainer : {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        },
        flexItem : {
            paddingTop:5,
            paddingBottom:5
        },
    });

    const classes = useStyles()

    const PAGES = {
        activeLoadsTab: 0,
        quotedLoadsTab: 1,
        historyTab: 2,
    }

    const [sendGtagEvent] = useGtagHook()

    const [renderedTab, setRenderedTab] = useState(PAGES["activeLoadsTab"])

    const [loadsStack, setLoadsStack] = useState([])
    const [reloadCrumbs, setReloadCrumbs] = useState([])
    const [reloadsOpen, setReloadsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const [currentLoadList, setCurrentLoadList] = useState([])
    const [currentLoadListCount, setCurrentLoadListCount] = useState(0) //this will be updated by the load list when a filter is applied
    const [numOfReloadsPaginationPages, setNumOfReloadsPaginationPages] = useState(1)
    const [currentReloadsPageNumber, setCurrentReloadsPageNumber] = useState(1)
    

    const [currentActiveLoadList, setCurrentActiveLoadList] = useState([])
    const [currentActiveLoadListCount, setCurrentActiveLoadListCount] = useState([])
    const [numOfActivePaginationPages, setNumOfActivePaginationPages] = useState(1)
    const [currentActiveLoadPageNumber, setCurrentActiveLoadPageNumber] = useState(1)

    const [currentHistoricalLoadList, setCurrentHistoricalLoadList] = useState([])
    const [currentHistoricalLoadListCount, setCurrentHistoricalLoadListCount] = useState([])
    const [currentHistoricalLoadPageNumber, setCurrentHistoricalLoadPageNumber] = useState(1)
    const [numOfHistoricalPaginationPages, setNumOfHistoricalPaginationPages] = useState(1)
    const [historicalDaysBack, setHistoricalDaysBack] = useState(30)

    


    const ITEMS_PER_PAGE = 10
    const FILTER_PADDING_TOP = 83
    
    useEffect(() => {
        fetchActiveLoads()
    }, [])

    useEffect(() => {
        fetchHistoricalLoads()
    }, [historicalDaysBack])

    useEffect(() => {
        setCurrentLoadListCount(0)
        setCurrentLoadList([])
        setLoadsStack([])
        setReloadCrumbs([])
        setReloadsOpen(false)
    }, [renderedTab])

    useEffect(() => {
        setNumOfActivePaginationPages(Math.ceil(currentActiveLoadListCount / ITEMS_PER_PAGE))
        setNumOfHistoricalPaginationPages(Math.ceil(currentHistoricalLoadListCount / ITEMS_PER_PAGE))
        setNumOfReloadsPaginationPages(Math.ceil(currentLoadListCount / ITEMS_PER_PAGE)) 
    }, [currentActiveLoadListCount, currentHistoricalLoadListCount, currentLoadListCount])

    useEffect(() => {
        if (reloadCrumbs.length <= 0)
            setReloadsOpen(false)
        else
            setReloadsOpen(true)
    }, [reloadCrumbs])

    useEffect(() => {
        if (loadsStack.length > 0) {
            setCurrentLoadList(loadsStack[loadsStack.length - 1])
            setCurrentLoadListCount(loadsStack[loadsStack.length - 1].length)
        }
    }, [loadsStack])

    const fetchActiveLoads = (index) => {
        setIsLoading(true)
        let params = {
            advantageId: muiTheme.customerUniqueId
        }

        // make api call to fetch recent activity
        MyLoadsApi.FetchActiveLoads(params).then(response => {
            if (response.status) {
                if (response.loads && response.loads.length > 0) {
                    setLoadsStack([response.loads])
                    setCurrentActiveLoadList(response.loads)
                    setCurrentActiveLoadListCount(response.loads.length)
                } 
                setIsLoading(false)
                sendGtagEvent(
                    "search_results_returned",
                    {
                        screen: "booked_loads",
                        listLength: response.loads.length,
                        type: "loads",
                    }
                )
            } else {
                let errorMessage = "Error: There was a problem fetching your booked loads."
                // setLoadsStack([])
                // setCurrentLoadList([])
                setIsLoading(false)
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem fetching your booked loads."
            // setLoadsStack([])
            // setCurrentLoadList([])
            setIsLoading(false)
            toast.error(errorMessage)
        })
    }

    const fetchHistoricalLoads = () => {
        setIsLoading(true)
        let params = {
            advantageId: muiTheme.customerUniqueId
        }

        // if (muiTheme.customerUniqueId === "a98db973") { //todo: 45 days(default) 90 days and custom
            let todaysDateCalc = moment().subtract(historicalDaysBack, 'days')
            params['startDate'] = todaysDateCalc.format('YYYY-MM-DD')
            let endDateCalc = moment().subtract(3, 'days')
            params['endDate'] = endDateCalc.format('YYYY-MM-DD')

        // }
        // else {
        //     toast.info("Historical Tab under development.")
        //     let loadList = sortMostRecentBooked(mockData.loads)
        //     setLoadsStack([loadList])
        //     setCurrentLoadList(loadList)
        //     setIsLoading(false)
        //     return
        // }

        // make api call to fetch recent activity
        MyLoadsApi.FetchHistoricalLoads(params).then(response => {
            if (response.status) {
                if (response.loads && response.loads.length > 0) {
                    setCurrentHistoricalLoadList(response.loads)
                    setCurrentHistoricalLoadListCount(response.loads.length)
                } 
                setIsLoading(false)
                sendGtagEvent(
                    "search_results_returned",
                    {
                        screen: "historical_loads",
                        listLength: response.loads.length,
                        type: "loads",
                    }
                )
            } else {
                let errorMessage = "Error: There was a problem fetching your historical loads."
                // setLoadsStack([])
                // setCurrentLoadList([])
                setIsLoading(false)
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch((error) => {
            console.log(error)
            let errorMessage = "Error: There was a problem fetching your historical loads."
            // setLoadsStack([])
            // setCurrentLoadList([])
            setIsLoading(false)
            toast.error(errorMessage)
        })
    }

    const retrieveCurrentTabData = () => {

        if(reloadsOpen){
            return {
                numPages: numOfReloadsPaginationPages, 
                currentPage: currentReloadsPageNumber, 
                setCurrentPage: setCurrentReloadsPageNumber,
                currentList: currentLoadList,
                currentListCount: currentLoadListCount,
                setCurrentListCount: setCurrentLoadListCount
            }
        }

        if (renderedTab == PAGES["activeLoadsTab"]){
            return {
                numPages: numOfActivePaginationPages, 
                currentPage: currentActiveLoadPageNumber, 
                setCurrentPage: setCurrentActiveLoadPageNumber,
                currentList: currentActiveLoadList,
                currentListCount: currentActiveLoadListCount,
                setCurrentListCount: setCurrentActiveLoadListCount
            }
        }else{
            return {
                numPages: numOfHistoricalPaginationPages, 
                currentPage: currentHistoricalLoadPageNumber, 
                setCurrentPage: setCurrentHistoricalLoadPageNumber,
                currentList: currentHistoricalLoadList,
                currentListCount: currentHistoricalLoadListCount,
                setCurrentListCount: setCurrentHistoricalLoadListCount
            }
        }
    }

    const pushReloads = (parentLoad, reloads) => {
        //todo: push another param for date besides relying on scheduledAt to be in stops
        let tmpLoadOrigin = {
            ...parentLoad.stops[0],
            date: parentLoad.pickupDateTimeLatest ? parentLoad.pickupDateTimeLatest : parentLoad.pickupDateTime
        }
        let tmpLoadDestination = {
            ...parentLoad.stops[parentLoad.stops.length - 1],
            date: parentLoad.deliveryDateTime
        }
        if (reloadCrumbs.length <= 0) {
            setReloadCrumbs([
                tmpLoadOrigin,
                tmpLoadDestination
            ])
        } else {
            setReloadCrumbs([
                ...reloadCrumbs,
                tmpLoadDestination
            ])
        }
        setLoadsStack([
            ...loadsStack,
            reloads
        ])
        sendGtagEvent(
            "search_results_returned",
            {
                screen: "booked_loads",
                listLength: reloads.length,
                type: "reloads"
            }
        )
    }

    const popToIndex = (index) => {
        let newLoadsStack = loadsStack.slice(0, index + 1)
        let newReloadCrumbs = reloadCrumbs.slice(0, index + 1)
        setLoadsStack(newLoadsStack)
        if (index === 0)
            setReloadCrumbs([])
        else
            setReloadCrumbs(newReloadCrumbs)
    }

    const sortMostRecentBooked = (loadList = []) => {
        loadList.sort((a, b) => {
            let aTime = moment(a.activities[0].created_at).valueOf()
            let bTime = moment(b.activities[0].created_at).valueOf()
            if (aTime < bTime)
                return 1
            else if (aTime > bTime)
                return -1
            else
                return 0
        })
        return loadList
    }

    const getHeaderText = () => {
        switch (renderedTab) {
            case PAGES["activeLoadsTab"]:
                return "Booked Loads"
            case PAGES["quotedLoadsTab"]:
                return "Quoted Loads"
            case PAGES["historyTab"]:
                return "Completed Loads"
        }
    }

    return (
        <div style={{ height: '100%' }}>
            {isLoading && <LoadingOverlay />}
            { !isMobile &&
                <div className={classes.flexContainer}>
                    <div className={classes.flexItem} style={{alignSelf:'center', width:'33%'}}>
                        <Col style={{display: "flex", justifyContent: "center", height: 75 }}>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["activeLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => setRenderedTab(PAGES["activeLoadsTab"])}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["activeLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Active Loads</p>
                            </Button>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["historyTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => setRenderedTab(PAGES["historyTab"])}
                            >
                                <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["historyTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>History</p>
                            </Button>
                        </Col>
                    </div>

                    <div className={classes.flexItem} style={{height:65}}>
                        <Row style={{ display: "flex", width: "100%"}}>
                            <Col style={{ width: '25%' }}>
                                <h2 style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 15, }}>{ renderedTab == PAGES["activeLoadsTab"] ? `Active Loads` : `History` }</h2>
                            </Col>
                            <Col style={{ width: '50%', display: "flex", justifyContent: "center" }}>
                                {renderedTab == PAGES["historyTab"] &&
                                    <DaysBackSelector historicalDaysBack={historicalDaysBack} setHistoricalDaysBack={setHistoricalDaysBack} />
                                }
                            </Col>
                            <Col style={{ width: '25%' }} />
                        </Row>
                    </div>


                <Row className={classes.flexItem} style={{height:'100%'}}> 
                    {reloadsOpen &&
                        <Col md={2} style={{backgroundColor: 'white', height: '100%' }}>
                            <FilterMenu loadList={currentLoadList} paddingTop={FILTER_PADDING_TOP} />
                        </Col>
                    }
                    <Col md={reloadsOpen ? 10 : 12} style={{height: 'calc(100% - 250px)'}}>
                        {reloadsOpen &&
                            <Row>
                                <div style={{ paddingLeft: 15 }}>
                                    {
                                        reloadCrumbs.map((crumb, index) => {
                                            return (
                                                <span>

                                                    { (index < reloadCrumbs.length - 1) &&
                                                        <span>
                                                            <div style={{ display: 'inline-block' }}>
                                                                <span className={classes.breadcrumbLink} onClick={() => popToIndex(index)}>
                                                                    {crumb.city + ', ' + crumb.state}
                                                                </span>
                                                                <p className={classes.secondaryText}>
                                                                    {moment(crumb.date).format('MM/DD')}
                                                                </p>
                                                            </div>
                                                            <FA name='chevron-right' size="lg" color={"black"} style={{ marginRight: 15, verticalAlign: 'top' }} />

                                                        </span>
                                                    }

                                                    { index === reloadCrumbs.length - 1 &&

                                                        <div style={{ display: 'inline-block' }}>
                                                            <span className={classes.activeBreadcrumbLink} onClick={() => popToIndex(index)}>
                                                                {crumb.city + ', ' + crumb.state}
                                                            </span>
                                                            <p className={classes.secondaryText}>
                                                                {moment(crumb.date).format('MM/DD')}
                                                            </p>
                                                        </div>


                                                    }
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </Row>
                        }
                        {!isLoading && retrieveCurrentTabData().currentList && retrieveCurrentTabData().currentList.length > 0 ?
                            <CarrierLoadList
                                unfilterLoadList={retrieveCurrentTabData().currentList}
                                setCurrentLoadListCount={retrieveCurrentTabData().setCurrentListCount}
                                availability={availability}
                                pushReloads={pushReloads}
                                reloadsOpen={reloadsOpen}
                                isMyLoadsScreen
                                myLoadsRenderedTab={renderedTab}
                                isPaginationEnabled={isPaginationEnabled}
                                setPageNumber={retrieveCurrentTabData().setCurrentPage}
                                pageNumber={retrieveCurrentTabData().currentPage}
                                itemsPerPage={ITEMS_PER_PAGE}
                                autoHeight
                            />
                          
                            :
                            <div style={{ paddingTop: 50 }}>
                                <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                    No {getHeaderText()} Found
                            </p>
                            </div>
                        }
                        {!isLoading && retrieveCurrentTabData().currentList && retrieveCurrentTabData().currentList.length > 0 && retrieveCurrentTabData().numPages > 1 && (
                        <div style={{paddingBottom:10, paddingTop:20, paddingLeft: 5}}>                        
                            <ListPagination
                                numberOfPages={retrieveCurrentTabData().numPages}
                                pageNumber={retrieveCurrentTabData().currentPage}
                                setPageNumber={retrieveCurrentTabData().setCurrentPage}
                            />
                        </div>              
                        )}
                    </Col>
                    </Row> 
                </div>
                
            }


            {isMobile &&
                <div className={classes.flexContainer}>
                    {!isLoading &&
                        <div classname={classes.flexItem} style={{width: "100%", marginBottom: 0, display: "flex", justifyContent: "center" }}>
                            <Col style={{ width: "90%", display: "flex", justifyContent: "center", paddingTop: 15 }}>
                                <Button
                                    className={classes.header}
                                    style={{ borderBottomColor: renderedTab == PAGES["activeLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                    onClick={() => setRenderedTab(PAGES["activeLoadsTab"])}
                                >
                                    <p className={classes.headerText} style={{ color: renderedTab == PAGES["activeLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Active Loads</p>
                                </Button>
                                {/* <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["quotedLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => setRenderedTab(PAGES["quotedLoadsTab"])}
                            >
                                <p className={classes.headerText} style={{ color: renderedTab == PAGES["quotedLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Quoted Loads</p>
                            </Button> */}
                                <Button
                                    className={classes.header}
                                    style={{ borderBottomColor: renderedTab == PAGES["historyTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                    onClick={() => setRenderedTab(PAGES["historyTab"])}
                                >
                                    <p className={classes.headerText} style={{ color: renderedTab == PAGES["historyTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>History</p>
                                </Button>
                            </Col>
                        </div>


                    }

                    {renderedTab == PAGES["historyTab"] && !isLoading &&
                        <div className={classes.flexItem} style={{width: "100%", marginBottom: 0, display: "flex", justifyContent: "center"}}>
                            <Col>
                                <DaysBackSelector historicalDaysBack={historicalDaysBack} setHistoricalDaysBack={setHistoricalDaysBack} />
                            </Col>
                        </div>
                    }


                    {!isLoading && retrieveCurrentTabData().currentList && retrieveCurrentTabData().currentList.length > 0 ?
                        <div className={classes.flexItem} style={{ maxHeight: '100%', overflow: 'hidden', paddingBottom: retrieveCurrentTabData().numPages > 1 ? 5 : 45 }}>
                            <CarrierLoadList
                                unfilterLoadList={retrieveCurrentTabData().currentList}
                                setCurrentLoadListCount={retrieveCurrentTabData().setCurrentListCount}
                                availability={availability}
                                pushReloads={pushReloads}
                                reloadsOpen={reloadsOpen}
                                isMyLoadsScreen
                                isMobile
                                myLoadsRenderedTab={renderedTab}
                                isPaginationEnabled={isPaginationEnabled}
                                setPageNumber={retrieveCurrentTabData().setCurrentPage}
                                pageNumber={retrieveCurrentTabData().currentPage}
                                itemsPerPage={ITEMS_PER_PAGE}
                            />
                        </div>
                        :
                        <div className={classes.flexItem} style={{ paddingTop: 50 }}>
                            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                No {getHeaderText()} Found
                            </p>
                        </div>
                    }
                    <div className={classes.flexItem} style={{paddingBottom:10, paddingTop:20, paddingLeft: 5}}>                        
                        <ListPagination
                            numberOfPages={retrieveCurrentTabData().numPages}
                            pageNumber={retrieveCurrentTabData().currentPage}
                            setPageNumber={retrieveCurrentTabData().setCurrentPage}
                            isMobile
                            screenWidth={width}
                        />
                    </div>
                </div>
            }
                
        </div>
    )
}

const mapStateToProps = state => ({
    loads: state.AvailabilityLoads.payload.loads,
    availability: state.AvailabilityLoads.payload.availability,
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(MyLoadScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}