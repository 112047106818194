const initialState = {
  requesting: false,
  successful: false,
  payload:[],
  messages: [],
  errors: [],
}

const reducer = function loginReducer (state = initialState, action) {
  switch (action.type) {
    case "CARRIER_HISTORY_REQ":
      var retValue = JSON.parse(JSON.stringify(state))
     retValue.requesting = true
     retValue.successful = false
      return retValue

    case "CARRIER_HISTORY_SUC":
      var retValue = JSON.parse(JSON.stringify(state))
      retValue.requesting = false
      retValue.successful = true
      retValue.payload = action.payload
      return retValue

    case "CARRIER_HISTORY_SUC":
      var retValue = JSON.parse(JSON.stringify(state))
      retValue.requesting = false
      retValue.successful = false
      return retValue


        return retValue

    default:
      return state
  }
}

export default reducer
