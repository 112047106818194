export const TRUCK_TYPES_REQUESTING = 'TRUCK_TYPES_REQUESTING'
export const TRUCK_TYPES_SUCCESS = 'TRUCK_TYPES_SUCCESS'
export const TRUCK_TYPES_ERROR = 'TRUCK_TYPES_ERROR'


export const CALL_IMPORT_CARRIER_CAPACITY = 'CALL_IMPORT_CARRIER_CAPACITY'
export const CALL_IMPORT_CARRIER_CAPACITY_SUCCESS = 'CALL_IMPORT_CARRIER_CAPACITY_SUCCESS'
export const CALL_IMPORT_CARRIER_CAPACITY_ERROR = 'CALL_IMPORT_CARRIER_CAPACITY_ERROR'

export const CALL_ADD_CARRIER_CAPACITY = 'CALL_ADD_CARRIER_CAPACITY'
export const CALL_ADD_CARRIER_CAPACITY_SUCCESS = 'CALL_ADD_CARRIER_CAPACITY_SUCCESS'
export const CALL_ADD_CARRIER_CAPACITY_ERROR = 'CALL_ADD_CARRIER_CAPACITY_ERROR'


export const CALL_GET_CARRIER_CAPACITY = 'CALL_GET_CARRIER_CAPACITY'
export const CALL_GET_CARRIER_CAPACITY_SUCCESS = 'CALL_GET_CARRIER_CAPACITY_SUCCESS'
export const CALL_GET_CARRIER_CAPACITY_ERROR = 'CALL_GET_CARRIER_CAPACITY_ERROR'


export const CALL_STATE_LIST = 'CALL_STATE_LIST'
export const CALL_STATE_LIST_SUCCESS = 'CALL_STATE_LIST_SUCCESS'
export const CALL_STATE_LIST_ERROR = 'CALL_STATE_LIST_ERROR'


export const CALL_DELETE_CAPACITY = 'CALL_DELETE_CAPACITY'
export const CALL_DELETE_CAPACITY_SUCCESS = 'CALL_DELETE_CAPACITY_SUCCESS'
export const CALL_DELETE_CAPACITY_ERROR = 'CALL_DELETE_CAPACITY_ERROR'

export const CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL = 'CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL'
export const CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_SUCCESS = 'CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_SUCCESS'
export const CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_ERROR = 'CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL_ERROR'