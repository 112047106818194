//SYSTEM
/* eslint-disable */
  import { call, put, takeLatest } from 'redux-saga/effects'
  import {toast} from 'react-toastify';
//APIs
  import {getAvailabilityLoads,getAvailabilityLoadsV2} from '../../../api/availabilityLoads'
  import {sendCarrierInterestEmail} from '../../../api/communication'
  import {postCarrierEmailResponse} from '../../../api/driverAvailability'

function* getAvailabilityLoadsFlow(action){
  try {
    const response = yield call(getAvailabilityLoads,action.availabilityId)
    if(response.status){
      yield put({type:'UPDATE_AVAILABILITY_LOADS_SUC',payload:response})
    }else {
      yield put({type:'UPDATE_AVAILABILITY_LOADS_ERR',payload:response})
    }

  } catch (error) {
    yield put({type:'UPDATE_AVAILABILITY_LOADS_ERR',error:error})

  }
}

function* sendInterestEmailFlow(action){
  try {
    const {availabilityId,content,loadIds,contactInfo} = action;
    const response = yield call(sendCarrierInterestEmail,availabilityId,content,loadIds,contactInfo)
    var errors = getErrorEmails(response);
    if(response.length > errors.length){ //If there were some success
      errors.map((error)=>toast('error.message'))
      yield put({type:'SEND_INTEREST_EMAIL_SUC',payload:response.payload})
    }else{ //If All Failed
      toast('We had a problem sending interest for these loads. Error: AvailabilityLoads0001. Contact support@truckertools.com.')
      yield put({type:'SEND_INTEREST_EMAIL_ERR',payload:response.payload})
    }
  } catch (error) {
    console.log(error)

  }
}

function* confirmAvailabilityFlow(action){
  try{
    const {availabilityId} = action;
    const response = yield call(postCarrierEmailResponse,availabilityId)
  }catch (error) {
    console.log(error)
  }
}



function* getAvailabilityLoadsFlowV2(action){
  try {
    const response = yield call(getAvailabilityLoadsV2,action.availabilityId)
    if(response.status){
      yield put({type:'UPDATE_AVAILABILITY_LOADS_SUC',payload:response})
    }else {
      yield put({type:'UPDATE_AVAILABILITY_LOADS_ERR',payload:response})
    }

  } catch (error) {
    yield put({type:'UPDATE_AVAILABILITY_LOADS_ERR',error:error})

  }
}


function* templateWatcher () {
  yield takeLatest('UPDATE_AVAILABILITY_LOADS_REQ', getAvailabilityLoadsFlow),
  yield takeLatest('UPDATE_AVAILABILITY_LOADS_REQ_V2', getAvailabilityLoadsFlowV2),
  yield takeLatest('SEND_INTEREST_EMAIL_REQ',sendInterestEmailFlow),
  yield takeLatest('CONFIRM_AVAILABILITY',confirmAvailabilityFlow)
}

export default templateWatcher

function getErrorEmails(response){
  var errors = response.filter((resp)=>!resp.status)
  return errors
}
