// SMART LOGISTICS MANAGED SERVICES THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#3A3A3A',
    secondary: '#FFBE4A',
    actionColor: "#FF6347",
    lightContrastText: '#fff',
    darkContrastText: '#000',
    useDarkContrastText: false,
    tagLine:'',
    menuHighlightColor: "rgba(255, 255, 255, 0.3)",
    useWhiteBackgroundBehindLogo: true,
    customerUniqueId: "32m9lbe4",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Roboto",
    loginMsg: "You've selected a feature that requires a secure login. Please login or sign up.",
    source: "smart_logistics"
});
//console.log(theme);
export default theme