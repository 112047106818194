import React, { useContext } from 'react';

//COMPONENTS
import isMobile from '../../../../HOC/Mobile.js'
import 'react-datepicker/dist/react-datepicker.css'

import TireSearchBarMobileWrapper from './tire-search-bar-mobile-wrapper'
import TireSearchBarDesktop from './tire-search-bar-desktop';

const TireSearchBar = (props) => {

    const { isMobile, searchData, onSearch, searchParams, updateSearchParams } = props

    const handleSelect = (field, selected) => {
        updateSearchParams(field, selected)
    }

    return (
        <div>
            {!isMobile &&
                <TireSearchBarDesktop searchData={searchData} handleSelect={handleSelect} onSearch={onSearch} />
            }

            {isMobile &&
                <TireSearchBarMobileWrapper searchParams={searchParams} searchData={searchData} handleSelect={handleSelect} onSearch={onSearch} />
            }
        </div>

    )
}

export default isMobile()(TireSearchBar)