import React, { Component }  from 'react';
export const clientFields = {

    testRestricted: ['omnitrac'],

    emailClients: ['RandMcNally', 'peopleNet', 'keller', 'omnitrac', 'm2m', 'FTSGPS', '3MD', 'TeletracNavman', 'ELDMandate', 'FlexFleet', 'Vistracks', 'ThinAir', 'Isaac', 'Anytrek', 'TrackEnsure', 'ALS', 'Akal', 'Rigbot', 'GPSTrackit', 'Hutch', 'ELDSolutions', 'TrackYourTruck', 'Pedigree', 'ArionTech', 'PowerFleet', '2BroELD', 'TXT', 'Geowiz', 'TRACKSMART', 'CyntrX', 'Coretex', 'EasiTrack', 'Rhino', 'TopTracking' ,'TitanGPS', 'AirIQ'],

    autoSubmitConfirmation : ['RandMcNally', 'keller', 'm2m', 'FTSGPS', '3MD', 'TeletracNavman', 'ELDMandate', 'FlexFleet', 'ThinAir', 'Anytrek', 'peopleNet', 'TrackEnsure', 'ALS', 'Akal', 'Rigbot', 'GPSTrackit', 'Hutch', 'ELDSolutions', 'TrackYourTruck', 'Pedigree', 'ArionTech', 'PowerFleet', '2BroELD' ,'TXT', 'Geowiz', 'TRACKSMART', 'CyntrX', 'Coretex', 'EasiTrack', 'Rhino', 'TopTracking', 'TitanGPS', 'AirIQ'],

    omnitrac: { // NEW MODIFICATION DONE
        fullName: 'Omnitracs',
        instruction: [
            "Omnitracs requires specific information in order to set up the ELD integration. Please follow the steps below to authorize Trucker Tools to access your ELD location information.",
            "Watch the !instruction! to walk you through the steps on how to authorize Trucker Tools access to your ELD location data on your VLV (Virtual Load View) dashboard.",
            "Step 1: Click this link: !eldTargetHref! to direct you to Omnitracs’ VLV data share agreement.",
            "Step 2: Once you accept the data sharing agreement, go to your Omnitracs account to authorize Trucker Tools to pull your ELD location data.",
            "Step 3: Once you authorize Trucker Tools on your VLV dashboard, Omnitracs will send an email to us and you with information we require to complete the setup. We will then notify you when the integration is complete.",
            "Note: You can deactivate this at any time using Omnitracs’ VLV portal."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will direct you to Omnitrac's VLV(Virtual Load View) Portal to authorize Trucker Tools to pull your ELD's location data.",
            "Once you complete these steps our support team will be automatically notified.",
            "Our support team will notify you at the email you've provided once setup is complete.",
            "You can come back here to test an existing connection at any time.",
            "You can deactivate this at anytime using Omnitrac's VLV portal."*/
        ],
        // carrierEmail: { displayName: "Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email' },
        url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://vlv.omnitracs.com/", required: false, type: 'text' }
    },

    KeepTrucking: {
        fullName: 'Keep Truckin',
        confiramtionText: '',
        instruction: [
            "In order to connect to your Keep Truckin ELDs, we require you to complete the below steps",
            "Step 1: Please enter your USDOT number below.",
            "Step 2: Once you submit your DOT number, you will be redirected to Keep Truckin’s login page. Please enter your Keep Truckin username and password. If you are unsure of this login information, please contact your Keep Truckin Administrator.",
            "Step 3: After logging in, click “Install”. This will take you back to Trucker Tool’s ELD set up page and you will receive confirmation when the integration is complete."
            //"In order to connect to your Keep Truckin ELDs, we require you to complete the below steps."
            //"Fill out the form and click submit which will send an email to Keep Truckin to create an API key to allow Trucker Tools to have access to your ELD location.",
            //"Keep Truckin may reach out to you to request additional authorization by you to create the API key.",
            //"Once they get your authorization, they will create the API key and send you instructions on how to access it.",
            //"Once you have your API key, click the “Already have API Key” button and click submit."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of generating your API Key within the Keep Truckin Portal.",
            "Once you have your API Key, come back here and paste it in the API Key box then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at the carrier company', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://api.keeptruckin.com/v1/", required: false, type: 'text' }
    },

    KeepTruckingAPIForm: {
        fullName: 'Keep Truckin',
        instruction: [
            //"If you already know your Keep Truckin API key, enter it here and click submit."
        ],
        password: { displayName: "Keep Truckin API Key", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API key that was sent to you by Keep Truckin' }
    },

    KeepTruckingFinal: {
        fullName: 'Keep Truckin',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,     
    },

    bigRoad: { // NEW MODIFICATION DONE
        fullName: 'Big Road',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and Person ID for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Big Road requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
            //"Link to BigRoad login page: !eldTargetHref!",
            //"Once you have your API Key, paste it in the API Key box then click 'Submit'.",
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through generating your API Key within the Big Road Portal.",
            "Once you have your API Key, come back here and paste it in the API Key box then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        token: { displayName: "Big Road API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API key you generated through the  “Big Road” portal', moreDetails: 'For steps on how to access your API key, watch the [video]' },
        vehicle_identity: { displayName: "Person ID", apiName: "vehicle_identity", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '25', instructionTxt: 'To access the Person ID, navigate to your Big Road portal > Click Menu > Click Drivers > Open one of the driver`s details > copy the Person ID listed in the URL link > paste the number below', moreDetails: 'For steps on how to access your person ID, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    Samsara: {
        fullName: 'Samsara',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Samsara requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration. "
            //"Watch the !instructions! to walk you through the process of generating your Samsara API Token within the Samsara Dashboard.",
            //"Link to Samsara login page:  https://cloud.samsara.com/signin",
            //"Once you have your API Token, paste it in the Samsara API Token field  then click 'Submit'."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of generating your API Token within the Samsara Dashboard.",
            "Once you have your API Token, come back here and paste it in the API Token box then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        password: { displayName: "Samsara API Token", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your Samsara portal', moreDetails: 'For steps on how to access your API key, watch the [video]' },
        asset_type: { displayName: "Track via asset type", apiName: "asset_type", editable: true, show: true, defaultValue: "tractor", required: true, type: 'radio', instructionTxt: 'Select which asset type you would like to be tracked via ELD on' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true},
        // url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://api.samsara.com/v1/", required: false, type: 'text' }
    },

    RandMcNally: { // NEW MODIFICATION DONE
        fullName: 'Rand McNally',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>In order to set up your Rand Mcnally ELDs with Trucker Tools, you’ll need to sign a Data Share Agreement (DSA). The Trucker Tools team will reach out to you shortly to the carrier contact email provided and will send you this form to sign via Docusign. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a>.</span>,
        instruction: [
            "In order to set up your Rand McNally ELDs with Trucker Tools, you’ll need to sign a Data Share Agreement (DSA). The Trucker Tools team will reach out to you shortly to the carrier contact email provided and will send you this form to sign via Docusign. If you have any questions, please contact eldsetup@truckertools.com"
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
    },

    peopleNet: {
        fullName: 'PeopleNet',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>In order to set up your PeopleNet ELDs with Trucker Tools, you’ll need to sign a Data Share Agreement (DSA). The Trucker Tools team will reach out to you shortly to the carrier contact email provided and will send you this form to sign via Docusign. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a>.</span>,
        instruction: [
            "In order to set up your PeopleNet ELDs with Trucker Tools, you’ll need to sign a Data Share Agreement (DSA). The Trucker Tools team will reach out to you shortly to the carrier contact email provided and will send you this form to sign via Docusign. If you have any questions, please contact eldsetup@truckertools.com"
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
    },

    keller: { // NEW MODIFICATION DONE
        fullName: 'JJ Keller',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>An email has been sent to JJ Keller to initiate your integration process. JJ Keller will reach out to the email address provided for you to docusign a DSA form. Once completed, JJ Keller will provide us with the necessary API credentials and we will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your company name,  MC number, contact name, and contact email. Then click “Submit”",
            "JJ Keller will reach out to you, via the email you provide in this form, to sign a DSA(Data Sharing Agreement).",
            "Our support team will notify you at the email you provide once setup is complete."
            /*"Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'",
            "We will send an email to JJ Keller with this information.",
            "JJ Keller will reach out to you, via the email you've provided, to sign a DSA(Data Sharing Agreement).",
            "Once you've sent the DSA back to JJ Keller, they will send us the information we need to begin using your ELD device.",
            "Our support team will notify you at the email you've provided once setup is complete.",
            "You can come back here to test an existing connection at any time."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number' },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company' },
        carrierEmail: { displayName: "Carrier Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address' },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "tsimplementation@jjkeller.com", required: false, type: 'email'},
        url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://www.kellerencompass.com/api/", required: false }

    },

    geotab: { // NEW MODIFICATION DONE
        fullName: 'Geotab',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Geotab requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Watch the !instructions! to walk you through the process of setting up your Geotab ELD with Trucker Tools.",
            //"Link to Geotab login page:  https://my.geotab.com/",
            //"Once you enter the information below, click “Submit” and your integration will be complete."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your Database, User Name and Password from Geotab.",
            "Once you have your Geotab information come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        companyCode: { displayName: "Geotab Database ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'This is the ID that is provided in the upper right corner of your Geotab portal next to “Notifications”. Enter the entire ID including letters and numbers if applicable', moreDetails: 'For steps on how to access your Database ID, watch the [video]' },
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your Geotab portal. Create a new user profile using eldsetup@truckertools.com as the username' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using TruckerToolsELD' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    

    TeletracNavman: {
        fullName: 'Teletrac Navman',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>An email has been sent to Teletrac Navman to obtain the credentials necessary to integrate your ELDs. Either Trucker Tools or Teletrac Navman will reach out to the email you provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number, and Carrier Contact Email. Then click “Submit”",
            "Our support team will notify you at the email you provide once the setup is complete."
            /*"To obtain your Teletrac API credentials and password you must send an email to <us.customersupport@teletracnavman.com> requesting API credentials and password to be set up for Trucker Tools. Please click !instructions! to open the instructions document.",
            "Click !email! to have Trucker Tools send the email on your behalf.",
            "Once Teletrac replies with your credentials come back to this page and enter them in then click 'Submit'",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
            
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number'  },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address'  },
        
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "us.customersupport@teletracnavman.com", required: false, type: 'email'}
        //login: { displayName: "User Name", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text' },
        //password: { displayName: "Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text' },
        //vehicle_identity: { displayName: "Vehicle#", apiName: "vehicle_identity", editable: true, show: true, defaultValue: "", required: false, type: 'text', maxLength: '25' },
    },

    DialogELD: { // NEW MODIFICATION DONE
        fullName: 'DialogELD',
        instruction: [
            "Watch the !instructions! to walk you through the process of getting your USDOT Number and Dialog ELD API token.",
            "Link to Dialog ELD login page: https://app.dialogeld.com/"
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from Dialog ELD.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number'},
        token: { displayName: "Dialog ELD API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Dialog ELD portal. Watch the short video listed above for the step by step directions to grab the API key' }
    },

    MasterELD: {
        fullName: 'MasterELD',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Master ELD requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //Watch the !instructions! to walk you through the process of getting your USDOT Number and Master ELD token.",
            //"Link to Master ELD login page:  https://app.mastereld.com/"
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from Master ELD.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        //companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        token: { displayName: "Master ELD API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Master ELD portal', moreDetails: 'For steps on how to access your API key, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true} 
    },    

    GPSTab: { // NEW MODIFICATION DONE
        fullName: 'GPSTab',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "GPStab requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Watch the !instructions! to walk you through the process of getting your USDOT number and GPSTab API token.",
            //"Link to GPSTab login page:  https://app.gpstab.com/"
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from GPSTab.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        //companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' }, 
        token: { displayName: "GPSTab API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your GPSTab portal', moreDetails: 'For steps on how to access your API key, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },  

    ELDOne: { // NEW MODIFICATION DONE
        fullName: 'ELD ONE',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "ELD One requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Watch the !instructions! to walk you through the process of getting your USDOT Number and ELD One API token.",
            //"Link to ELD One login page: !eldTargetHref!"
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from ELD One.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        //companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        token: { displayName: "ELD One API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your ELD One portal', moreDetails: 'For steps on how to access your API key, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },  

    RealELD: { // NEW MODIFICATION DONE
        fullName: 'Real ELD',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Real ELD requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Watch the !instructions! to walk you through the process of getting your USDOT Number and Real ELD token.",
            //"Link to Real ELD login page:  https://app.realeld.com/",
            //"Once you have the information, enter them in the required fields then click 'Submit'."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from Real ELD.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        //companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        token: { displayName: "Real ELD API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Real ELD portal', moreDetails: 'For steps on how to access your API key, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },  

    Roadstar: {
        fullName: 'RoadStar',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Roadstar requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Watch the !instructions! to walk you through the process of getting your USDOT Number and Roadstar API token.",
            //"Link to Roadstar login page:  https://app.brightroadstar.com/",
            //"Once you have the information, enter them in the required fields then click 'Submit'."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from Roadstar.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        //companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt:'Enter your USDOT number that was assigned to your carrier company' },
        token: { displayName: "Roadstar API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Roadstar portal', moreDetails: 'For steps on how to access your API key, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },  

    RightTrucking: { // NEW MODIFICATION DONE
        fullName: 'Right trucking',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Right Trucking requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Watch the !instructions! to walk you through the process of getting your USDOT Number and Right Trucking API token.",
            //"Link to Right Trucking login page:  https://app.righttruckdeal.com/",
            //"Once you have the information, enter them in the required fields then click 'Submit'."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from Right Trucking.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        //companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt:'Enter your USDOT number that was assigned to your carrier company' },
        token: { displayName: "Right Trucking API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Right Trucking portal', moreDetails: 'For steps on how to access your API key, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },  

    TFMELD: { // NEW MODIFICATION DONE
        fullName: 'TFM ELD',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "TFM ELD requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Watch the !instructions! to walk you through the process of getting your USDOT Number and TFM ELD API token.",
            //"Link to TFM ELD login page:  https://app.tfmeld.com/",
            //"Once you have the information, enter them in the required fields then click 'Submit'."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from TFM ELD.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        //companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        token: { displayName: "TFM ELD API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your TFM ELD portal', moreDetails: 'For steps on how to access your API key, watch the [video]'},
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    m2m: { // NEW MODIFICATION DONE
        fullName: 'M2M',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to M2M to obtain the API token necessary to integrate your ELDs. Either Trucker Tools or M2M will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number, and Carrier Contact Email. Then click “Submit”.",
            "The Trucker Tools support team will notify you at the email you provide once the setup is complete."
            /*"Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'.",
            "Trucker Tools Support will contact M2M to get the required information.",
            "Once we reveive the information from M2M, we will enter into our system.",
            "Our support team will notify you at the email you've provided once setup is complete."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number' },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address' },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "Ilijana@m2minmotion.com", required: false, type: 'email'}
    },

    FTSGPS: { // NEW MODIFICATION DONE
        fullName: 'FTSGPS',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to FTSGPS to obtain the credentials necessary to integrate your ELDs. Either Trucker Tools or FTSGPS will reach out to the email you provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'.",
            "Our support team will notify you at the email you provide once the setup is complete."
            /*"Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'.",
            "Trucker Tools Support will contact FTSGPS to get the required information.",
            "Once we reveive the information from FTSGPS, we will enter into our system.",
            "Our support team will notify you at the email you've provided once setup is complete."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your unique carrier MC number' },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address' },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "reed.spurgeon@ftsgps.com,support@ftsgps.com", required: false, type: 'email'}
    },

    '3MD': { // NEW MODIFICATION DONE
        fullName: '3MD Solutions',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to 3MD Solutions to obtain the necessary credentials to complete your ELD integration. Trucker Tools or 3MD Solutions will reach out to the email you provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete. ",
            /*"Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'.",
            "Trucker Tools Support will contact 3MD Solutions to get the required information.",
            "Once we reveive the information from 3MD Solutions, we will enter into our system.",
            "Our support team will notify you at the email you've provided once setup is complete."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number' },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address' },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@3mdsolutions.com", required: false, type: 'email'}
    },

    addNewProvider: { // NEW MODIFICATION DONE
        fullName: 'New Provider',
        instruction: [
            "If you don't see your ELD Provider in the drop down list, fill out this form and our support team will assess how to best integrate your provider with our system. We will update you on the process via the email you provide."
            /*"If you don't see your ELD Provider in the drop down list you can request we add it here.",
            "Please enter a contact email and phone number.",
            "Enter in the new providers name and contact information, then click 'Submit'.",
            "Our support team will assess how to best integrate your provider with our system.",
            "We will update you on the proccess via the email you've provided."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        carrierPhone: { displayName: "Carrier Contact Phone Number", apiName: "carrierPhone", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '10', instructionTxt: 'Enter your phone number', hideForNonLoggedIn: true },
        providerName: { displayName: "ELD Provider Name", apiName: "providerName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the ELD provider you would like us to integrate with' },
        providerContactName: { displayName: "ELD Provider Contact Name", apiName: "providerContactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact at the ELD provider' },
        providerContactEmail: { displayName: "ELD Provider Contact Email", apiName: "providerContactEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter the email of the contact person at the ELD provider' },
        providerContactPhone: { displayName: "ELD Provider Contact Phone Number", apiName: "providerContactPhone", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '25', instructionTxt: 'Enter the phone number for the contact person at the ELD provider' },
    },

    ELDRider: {
        fullName: 'ELD Rider',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "ELD Rider requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Fill out the form and click submit which will send an email to Keep Truckin to create an API key to allow Trucker Tools to have access to your ELD location.",
            //"Keep Truckin may reach out to you to request additional authorization by you to create the API key.",
            //"Once they get your authorization, they will create the API key and send you instructions on how to access it.",
            //"Once you have your API key, click the “Already have API Key” button and click submit."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of generating your API Key within the Keep Truckin Portal.",
            "Once you have your API Key, come back here and paste it in the API Key box then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },

        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        token: { displayName: "ELD Rider API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your ELD Rider portal. Watch the short video listed above for the step by step directions to grab the API key', moreDetails: 'For steps on how to access your ELD Rider API Key, watch the [video]' },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "eldrider@lioneight.com;bpopovic@lioneight.com;narsic@lioneight.com;zmilojevic@lioneight.com", required: false, type: 'email'}
    },

    ELDRiderAPIForm: {
        fullName: 'ELD Rider',
        instruction: [
        ],
        token: { displayName: "ELD Rider API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API key that was sent to you by ELD Rider' }
    },

    ELDRiderFinal: {
        fullName: 'ELD Rider',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,     
    },

    ELDMandate: { // NEW MODIFICATION DONE
        fullName: 'ELD Mandate',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to ELD Mandate to obtain the credentials necessary to integrate your ELDs. Either Trucker Tools or ELD Mandate will reach out to the email you provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "supportdesk@eldmandate.biz", required: false, type: 'email'}
    },

    FlexFleet: { // NEW MODIFICATION DONE
        fullName: 'Flex Fleet',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Flex Fleet to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Flex Fleet will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "jd@flexfleetgps.com", required: false, type: 'email'}
    },

    Vistracks: { // NEW MODIFICATION DONE
        fullName: 'Vistracks',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Vistracks to initiate your integration process. Trucker Tools or Vistracks will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Vistracks requires specific information in order to set up the ELD integration. Please complete the short form below with the required information.",
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "styler@omnitracs.com", required: false, type: 'email'}
    },

    ThinAir: { // NEW MODIFICATION DONE
        fullName: 'ThinAir Telematics',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to ThinAir Telematics to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or ThinAir Telematics will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "eld-support@thinair.com", required: false, type: 'email'}
    },

    GPSInsight: { // NEW MODIFICATION DONE
        fullName: 'GPS Insight',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "GPS Insight/Monarch requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
            //"Watch the !instructions! to walk you through the process of setting up your Geotab ELD with Trucker Tools.",
            //"Link to Geotab login page:  https://my.geotab.com/",
            //"Once you enter the information below, click “Submit” and your integration will be complete."
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your Database, User Name and Password from Geotab.",
            "Once you have your Geotab information come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        //companyCode: { displayName: "Geotab Database ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'This is the ID that is provided in the upper right corner of your Geotab portal next to “Notifications”. Enter the entire ID including letters and numbers if applicable', moreDetails: 'For steps on how to access your Database ID, watch the [video]' },
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to provide Trucker Tools with 3rd party access to one of your GPS Insight/Monarch admin users within your GPS Insight/Monarch account', moreDetails: 'For steps on how to access username, watch the [video]' },
        password: { displayName: "API Token", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API token you’ve generated in your GPS Insight/Monarch portal. Watch the short video listed above for the step by step directions to grab the API key', moreDetails: 'For steps on how to access your API token, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Gorilla: { // NEW MODIFICATION DONE
        fullName: 'Gorilla Safety',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>A request has been sent to you through your Gorilla Safety platform to authorize this request. Once you send Gorilla Safety your authorization, either Trucker Tools or Gorilla Safety will confirm when this integration is finalized.</span>,
        instruction: [
            "Gorilla Safety requires specific information in order to set up the ELD integration. Please complete the short form below with the required information."
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true }
    },

    Isaac: { // NEW MODIFICATION DONE
        fullName: 'ISAAC Instruments',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to your ISAAC Instruments Project Manager to initiate your integration process. Your ISAAC Project Manager will reach out to the carrier contact email provided for further information and provide you with the API account information. Once you have received this information, you can send it to <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a>. They will then confirm with you once the setup is complete.</span>,
        instruction: [
            "Isaac requires specific information in order to set up the ELD integration. Please complete the short form below with the required information."
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        providerContactName: { displayName: "ISAAC Instruments Project Manager Name", apiName: "providerContactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter your ISAAC Instruments Project Manager’s contact first and last name' },
        providerContactEmail: { displayName: "ISAAC Instruments Project Manager Email", apiName: "providerContactEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your ISAAC Instruments Project Manager’s contact email address' }
    },

    Anytrek: { // NEW MODIFICATION DONE
        fullName: 'Anytrek',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Anytrek to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Anytrek will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "help@anytrek.com", required: false, type: 'email'}
    },

    Nextraq: { // NEW MODIFICATION DONE
        fullName: 'Nextraq',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "NexTraq requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
        ],
        login: { displayName: "WebService Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the WebService username. If you are unsure, please contact your Nextraq account administrator' },
        password: { displayName: "WebService Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the WebService username. If you are unsure, please contact your Nextraq account administrator' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Verizon: { // NEW MODIFICATION DONE
        fullName: 'Verizon',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Verizon and your Verizon Account Manager to initiate your integration process. Your Verizon Account Manager will reach out to the carrier contact email provided for further information and provide you with the REST credentials. Once you have the REST credentials, email it to <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> and we will finalize your eld integration. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Verizon Connect requires specific information in order to set up the ELD integration. Please follow the steps below to authorize Trucker Tools to access your ELD location information.",
            "Step 1: Contact your Verizon Connect Account Manager to obtain REST credentials (username and password) with webservice/API access.",
            "Step 2: Once your Verizon Connect Account Manager has provided you with the credentials, please email the Trucker Tools ELD team at eldsetup@truckertools.com with the credentials and your MC number.",
            "Step 3: Trucker Tools will notify you once the integration is complete and you are connected via ELD for load tracking."
        ],
        /*companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        providerContactEmail: { displayName: "Verizon Account Manager Contact Email", apiName: "providerContactEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your Verizon account manager’s contact email address' },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "reveal.supportusa@srv.verizonconnect.com", required: false, type: 'email'}*/
    },

    VerizonAPIForm: {
        fullName: 'Verizon',
        instruction: [
            //"If you already know your Verizon Username and Password, enter it here and click submit."
        ],
        login: { displayName: "Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, maxLength: '50', instructionTxt: 'Enter the REST username Verizon provided you with' },
        password: { displayName: "Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the REST password Verizon provided you with' }
    },

    VerizonFinal: {
        fullName: 'Verizon',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,     
    },

    TrackEnsure: { // NEW MODIFICATION DONE
        fullName: 'TrackEnsure',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to TrackEnsure to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or TrackEnsure will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "mitjusha23@gmail.com", required: false, type: 'email'}
    },

    ALS: { // NEW MODIFICATION DONE
        fullName: 'ALS',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to ALS to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or ALS will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "sukhwinder@arethos.com;b.singh@arethos.com", required: false, type: 'email'}
    },

    Akal: { // NEW MODIFICATION DONE
        fullName: 'Akal',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Akal ELD to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Akal ELD will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "info@akaleld.com", required: false, type: 'email'}
    },

    Rigbot: { // NEW MODIFICATION DONE
        fullName: 'Rigbot',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Rigbot to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Rigbot will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "contactus@rigbot.com", required: false, type: 'email'}
    },

    GPSTrackit: { // NEW MODIFICATION DONE
        fullName: 'GPS Trackit',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to GPS Trackit to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or GPS Trackit  will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "jcraven@gpstrackit.com", required: false, type: 'email'}
    },

    Hutch: { // NEW MODIFICATION DONE
        fullName: 'Hutch Systems',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Hutch Systems to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Hutch Systems will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@hutchsystems.com", required: false, type: 'email'}
    },

    ELDSolutions: { // NEW MODIFICATION DONE
        fullName: 'ELD Solutions',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to ELD Solutions to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Hutch Systems will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@eldsolutions.com;llint@eldsolutions.com", required: false, type: 'email'}
    },

    TrackYourTruck: { // NEW MODIFICATION DONE
        fullName: 'Track Your Truck',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Track Your Truck to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Track Your Truck will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@trackyourtruck.com", required: false, type: 'email'}
    },

    LinxUp: { // NEW MODIFICATION DONE
        fullName: 'LinxUp',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Linxup requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
        ],
        token: { displayName: "API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '250', instructionTxt: 'Enter the API Token listed in your Linxup portal. Watch the short video listed above for the step by step directions to grab the External key', moreDetails: 'For steps on how to access your Linxup API Token, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    BIT: { // NEW MODIFICATION DONE
        fullName: 'Blue Ink Tech',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Blue Ink Tech requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
        ],
        token: { displayName: "Blue Ink Tech API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your BIT portal. Watch the short video listed above for the step by step directions to grab the API key', moreDetails: 'For steps on how to access your BIT API token, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    ERoad: { // NEW MODIFICATION DONE
        fullName: 'ERoad',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "ERoad requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
        ],
        token: { displayName: "ERoad API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your Eroad portal. Watch the short video listed above for the step by step directions to grab the API key', moreDetails: 'For steps on how to access your ERoad API token, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    Azuga: { // NEW MODIFICATION DONE
        fullName: 'Azuga',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Azuga requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
        ],
        token: { displayName: "Azuga API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Key you’ve generated in your Azuga portal. Watch the short video listed above for the step by step directions to grab the API key', moreDetails: 'For steps on how to access your Azuga API token, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    EZLogz: { // NEW MODIFICATION DONE
        fullName: 'EZLogz',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "EzLogz requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
        ],
        token: { displayName: "EzLogz External Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the External Key listed in your Ezlogz portal. Watch the short video listed above for the step by step directions to grab the External key', moreDetails: 'For steps on how to access your EzLogz External Key, watch the [video]' },
        companyCode: { displayName: "EzLogz External App ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'EzLogz External Key', moreDetails: 'For steps on how to access your EzLogz External App ID, watch the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    TruckX: { // NEW MODIFICATION DONE
        fullName: 'TruckX',
        instruction: [
            "Watch the !instruction! to walk you through the process of generating your Truckx API key within the Truckx Dashboard.",
            "Once you have shared the API key, an email will be sent to our ELD team and they will notify you at the email address you provided once it's complete.",
        ],
        // carrierEmail: { displayName: "Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email' },
        url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://vlv.omnitracs.com/", required: false, type: 'text' }
    },

    Pedigree: { // NEW MODIFICATION DONE
        fullName: 'Pedigree Technologies',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Pedigree Technologies to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that Pedigree Technologies can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "jennifer.marx@pedigreetechnologies.com;professionalservices@pedigreetechnologies.com", required: false, type: 'email'}
    },

    Zonar: { // NEW MODIFICATION DONE
        fullName: 'Zonar',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Zonar requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true},
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your Zonar portal. Create a new user profile using “eldsetup” as the username', moreDetails: 'For steps on how to access your new username, watch the [video]' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using “TruckerToolsELD”' },
        companyCode: { displayName: "Carrier Company Code", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your company code displayed in the top right corner of your Zonar portal' }
    },

    ArionTech: { // NEW MODIFICATION DONE
        fullName: 'ArionTech',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Ariontech to obtain the API credentials necessary to integrate your ELDs. Either Trucker Tools or Ariontech will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@ariontech.ca", required: false, type: 'email'}
    },

    PowerFleet: { // NEW MODIFICATION DONE
        fullName: 'PowerFleet',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to PowerFleet to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that PowerFleet can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "logisticssupport@powerfleet.com;jscalfani@powerfleet.com", required: false, type: 'email'}
    },

    '2BroELD': { // NEW MODIFICATION DONE
        fullName: '2 Bro ELD',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to 2 Bro ELD to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or 2 Bro ELD will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@2broitsolutions.com", required: false, type: 'email'}
    },

    TXT: { // NEW MODIFICATION DONE
        fullName: 'TXT',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to TXT to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that TXT can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@txtesolutions.com;accounts@txtesolutions.com", required: false, type: 'email'}
    },

    Geowiz: { // NEW MODIFICATION DONE
        fullName: 'Geowiz',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>In order to set up your Geowiz ELDs with Trucker Tools, Trucker Tools will need to submit a ticket with the Geowiz support team. Trucker Tools will reach out to you shortly with an update via the contact email provided. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a>.</span>,
        instruction: ['In order to set up your Geowiz ELDs with Trucker Tools, Trucker Tools will need to submit a ticket with the Geowiz support team. Trucker Tools will reach out to you shortly with an update via the contact email provided. If you have any questions, please contact eldsetup@truckertools.com'],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
    },

    TRACKSMART: { // NEW MODIFICATION DONE
        fullName: 'TrackSmart',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to TrackSmart to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or TrackSmart will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@tracksmart.ca", required: false, type: 'email'}
    },

    AwareGPS: { // NEW MODIFICATION DONE
        fullName: 'Aware GPS',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Aware GPS requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true},
        token: { displayName: "API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '250', instructionTxt: 'Enter the API Token listed in your Aware GPS portal. Watch the short video listed above for the step by step directions to grab the External key', moreDetails: 'For steps on how to access Aware GPS API Token, watch the [video]' },
    },

    InTouchGPS: { // NEW MODIFICATION DONE
        fullName: 'InTouch GPS',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "InTouch requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration.",
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true},
        login: { displayName: "InTouch Email Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your InTouch portal. Create a new user profile using “eldsetup@truckertools.com” as the email', moreDetails: 'For steps on how to create InTouch Username, watch the [video]' },
        password: { displayName: "InTouch Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using “TruckerToolsELD”', moreDetails: 'For steps on how to create InTouch Password, watch the [video]' }
    },

    CyntrX: { // NEW MODIFICATION DONE
        fullName: 'CyntrX',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to CyntrX to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or CyntrX will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "care@cyntrx.com", required: false, type: 'email'}
    },

    ClutchELD: { // NEW MODIFICATION DONE
        fullName: 'ClutchELD',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>A request has been sent to you through your ClutchELD platform to authorize this request. Once you send ClutchELD your authorization, either Trucker Tools or ClutchELD will confirm when this integration is finalized.</span>,
        instruction: [
            "ClutchELD requires specific information in order to set up the ELD integration. Please complete the short form below with the required information."
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true }
    },

    Transflo: { // NEW MODIFICATION DONE
        fullName: 'Transflo',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Transflo requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
        ],
        companyCode: { displayName: "Spireon Database ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'This is the ID that is provided in the upper right corner of your Transflo portal next to “Notifications”. Enter the entire ID including letters and numbers if applicable', moreDetails: 'For steps on how to access your Database ID, watch the [video]' },
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your Transflo portal. Create a new user profile using eldsetup@truckertools.com as the username' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using TruckerToolsELD' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Spireon: { // NEW MODIFICATION DONE
        fullName: 'Spireon',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Spireon requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
        ],
        companyCode: { displayName: "Spireon Database ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'This is the ID that is provided in the upper right corner of your Spireon portal next to “Notifications”. Enter the entire ID including letters and numbers if applicable', moreDetails: 'For steps on how to access your Database ID, watch the [video]' },
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your Spireon portal. Create a new user profile using eldsetup@truckertools.com as the username' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using TruckerToolsELD' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Coretex: { // NEW MODIFICATION DONE
        fullName: 'Coretex',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Coretex to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Coretex  will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "david.blackwell@coretex.com;jerry.hsiung@coretex.com", required: false, type: 'email'}
    },

    EasiTrack: { // NEW MODIFICATION DONE
        fullName: 'EasiTrack',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to EasiTrack to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or EasiTrack will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@easitrack.com;matthew@easitrack.com", required: false, type: 'email'}
    },

    Rhino: { // NEW MODIFICATION DONE
        fullName: 'Rhino Fleet Tracking',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Rhino Fleet Tracking to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Rhino Fleet Tracking will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "customerservice@rhinofleettracking.com", required: false, type: 'email'}
    },

    TopTracking: { // NEW MODIFICATION DONE
        fullName: 'Top Tracking',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to  Top Tracking to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that Top Tracking can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@toptrackingsystem.com", required: false, type: 'email'}
    },

    TitanGPS: { // NEW MODIFICATION DONE
        fullName: 'Titan GPS',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to  Titan GPS to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that Titan GPS can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "Autumn.D@ctstracking.com;emma.w@ctstracking.com", required: false, type: 'email'}
    },

    AirIQ: { // NEW MODIFICATION DONE
        fullName: 'AirIQ GPS',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to AirIQ to obtain the API credentials necessary to integrate your ELDs. Either Trucker Tools or AirIQ will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "kwilson@airiq.com", required: false, type: 'email'}
    },

    links: {
        omnitrac: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/omnitracs-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://customer.omnitracs.com/legal/vlv"
            }
        },
        KeepTrucking: {
            instruction: {
                path: null
            }
        },
        bigRoad: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/big-road-eld-guide.mp4"
            },
            eldTargetHref: {
                path: "https://app.bigroad.com/sign-in"
            }
        },
        Samsara: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/ELD+-+Samsara+Carrier+Steps+(1).pdf"
            },
            eldTargetHref: {
                path: "https://cloud.samsara.com/signin"
            }
        },
        RandMcNally: {
            instruction: {
                path: null
            },
            eldTargetHref: {
                path: "https://driverconnect.randmcnally.com/DriverConnect"
            }
        },
        peopleNet: {
            instruction: {
                path: null
            },
            dataShareAgreement: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Trucker+Tools+Data+Share+Agreement+PeopleNet.pdf"
            }
        },
        keller: {
            instruction: {
                path: null
            }
        },
        geotab: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/geotab-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://my.geotab.com"
            }
        },
        mercer: {
            instruction: {
                path: null
            }
        },
        TeletracNavman: {
            showEmail: true,
            instruction: {
                path: null
            }            
        },
        DialogELD: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/dialog-eld.mp4"
            }
        },    
        MasterELD: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/mastereld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://app.mastereld.com"
            }
        },       
        GPSTab: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/gps-tab.mp4"
            },
            eldTargetHref: {
                path: "https://app.gpstab.com"
            }
        },          
        ELDOne: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/eld-one-carrier.mp4"
            },
            eldTargetHref: {
                path: "https://app.eldone.us"
            }
        },       
        RealELD: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/real-eld.mp4"
            },
            eldTargetHref: {
                path: "https://app.realeld.com"
            }
        },           
        Roadstar: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/roadstareld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://app.brightroadstar.com"
            }
        },     
        RightTrucking: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/Right%20Trucking-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://app.righttruckdeal.com"
            }
        },     
        TFMELD: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/tfm-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://app.tfmeld.com"
            }
        },  
        addNewProvider: {

        },   
        m2m: {

        },  
        FTSGPS: {

        }, 
        '3MD': {

        },
        ELDRider: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/lioneight-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://eldrider.com/#/auth/login"
            }
        }, 
        ELDMandate: {

        }, 
        FlexFleet: {

        }, 
        Vistracks: {

        }, 
        ThinAir: {

        }, 
        GPSInsight: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/gps-insight-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://portal.gpsinsight.com/d/login.php"
            }
        }, 
        Gorilla: {

        }, 
        Isaac: {

        }, 
        Anytrek: {

        }, 
        Nextraq: {

        }, 
        Verizon: {

        }, 
        TrackEnsure: {

        }, 
        ALS: {

        },
        Akal: {

        },
        Rigbot: {

        },
        GPSTrackit: {

        },
        Hutch: {

        },
        ELDSolutions: {

        },
        TrackYourTruck: {

        },
        LinxUp: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/linxup-eld-document.mp4"
            },
            eldTargetHref: {
                path: "https://www.linxupgps.com/login.html"
            }
        },

        BIT: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/blueink-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://blueinktech.com/login.php"
            }
        },

        ERoad: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/eroad-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://depot.eroad.com/Portal/login.jsp"
            }
        },

        Azuga: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/azuga-eld-document.mp4"
            },
            eldTargetHref: {
                path: "https://apps.azuga.com/azuga/"
            }
        },

        EZLogz: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/ezlogz-eld-document.mp4"
            },
            eldTargetHref: {
                path: "https://ezlogz.com/"
            }
        },
        
        TruckX: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/truckx-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://web.truckx.com/#/login?redirectTo=/app/log-book"
            }
        },
        Pedigree: {

        }, 
        Zonar: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/zonar-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://zonar-login.auth0.com/login?state=g6Fo2SBQbTRMeXdwcWVZaU80b1poS00zc3EzVHdnTGw5aTlTZqN0aWTZIGQxbjdGdV9ObGlHaEZKdDdUOTBRS3VIcDlYZENCTXBPo2NpZNkgMExrVFVzSHFwZzFwSlJ6MGc0VFhXeEdNRnRQd2FvYTk&client=0LkTUsHqpg1pJRz0g4TXWxGMFtPwaoa9&protocol=oauth2&scope=openid%20profile%20email&audience=https%3A%2F%2Fzonar-login.auth0.com%2Fuserinfo&response_mode=query&response_type=code&redirect_uri=https%3A%2F%2Fgroundtrafficcontrol.zonarsystems.net%2Fcallback&nonce=a212e1edc7a29d28b0e550b7e45049de"
            }
        }, 
        ArionTech: {

        },
        PowerFleet: {

        },
        '2BroELD': {

        },
        TXT: {

        },
        Geowiz: {

        },
        TRACKSMART: {

        },
        AwareGPS: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/aware-gps-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://www.awaregps.com/login/index.html"
            }
        },
        InTouchGPS: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/intouch-gps-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://login.intouchgps.com/users/sign_in"
            }
        },
        CyntrX: {

        },
        ClutchELD: {

        }, 
        Transflo: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/geotab-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://www.transflo.com/wp-login.php"
            }
        },
        Spireon: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/geotab-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://www.spireon.com"
            }
        },
        Coretex: {

        },
        EasiTrack: {

        },
        Rhino: {

        },
        TopTracking: {

        },
        TitanGPS: {

        },
        AirIQ: {

        },
    }

}