import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import muiThemeable from 'material-ui/styles/muiThemeable';

import Button from '@material-ui/core/Button';


const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();

  const { btnStyle = {}, handleClick = () => { }, variant = "contained", size = "small", btnText = "Login", useATag = false } = props

  const getBody = () => {
    if (useATag) {
      return <a style={{...btnStyle, ...{ cursor: "pointer" }}} onClick={() => { handleClick(); loginWithRedirect() }}>{btnText}</a>
    } else {
      return <Button variant={variant} size={size} style={btnStyle} onClick={() => { handleClick(); loginWithRedirect() }}>{btnText}</Button>
    }
  }
  return getBody()
};

export default muiThemeable()(LoginButton);
