import * as Constants from '../constants'

const reducer =  (state, action) => { 
      switch(action.type) {        
        case Constants.ADD_INTEGRATION_SUCCESS:
          return undefined;       
        default:
          return state;
      }    
}

export default reducer