import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'


export const FetchCarrierUsers = (carrierId) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + "/membership/carrier/" + carrierId
    try {
        let requestObject = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "Get memberships carrierId: " + carrierId,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "Get memberships carrierId: " + carrierId,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const EditCarrierUser = (membershipId, newRole) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + "/membership/" + membershipId
    try {
        let data = { role: newRole }
        let requestObject = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "Edit carrier membership- membershipId: " + membershipId,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "Edit carrier membership- membershipId: " + membershipId,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const ActivateCarrierUser = (membershipId) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + "/membership/" + membershipId + "/activate"
    try {
        let requestObject = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "Activate carrier membership- membershipId: " + membershipId,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "Activate carrier membership- membershipId: " + membershipId,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const InvalidateCarrierUser = (membershipId) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + "/membership/" + membershipId + "/invalidate"
    try {
        let requestObject = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "Invalidate carrier membership- membershipId: " + membershipId,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "Invalidate carrier membership- membershipId: " + membershipId,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const DeleteCarrierUser = (membershipId) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + "/membership/" + membershipId + "/deactivate"
    try {
        let requestObject = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "Delete carrier membership- membershipId: " + membershipId,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "Delete carrier membership- membershipId: " + membershipId,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const SendCarrierInvite = (params) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + "/user/carriers/inviteByCarrier"
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: url,
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: url,
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}