//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'

//MESSAGING
  import * as CONSTANTS from './constants'

//ACTIONS


 function* saveFilterFlow (action) {
    try {
      var filterData = action.filterData
      var filterType = action.filterType
      yield put({ type: CONSTANTS.CARRIER_PORTAL_AVAIL_MATCH_LOAD_FILTER_SAVE_SUCC,  filterData,filterType})
      action.updateCallback()

    } catch (error) {
      console.log(error)
    }
 }

 function* clearFilterFlow (action) {
	  try {
      var filterData = action.filterData
      var filterType = action.filterType
      yield put({ type: CONSTANTS.CARRIER_PORTAL_AVAIL_MATCH_LOAD_FILTER_CLEAR_SUCC,  filterData,filterType})
      action.updateCallback()

	  } catch (error) {
      console.log(error)
	  }
 }



// Watches for the LOADS_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* loadListFilterWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(CONSTANTS.CARRIER_PORTAL_AVAIL_MATCH_LOAD_FILTER_SAVE_REQ, saveFilterFlow)
  yield takeLatest(CONSTANTS.CARRIER_PORTAL_AVAIL_MATCH_LOAD_FILTER_CLEAR_REQ, clearFilterFlow)
}

export default loadListFilterWatcher
