import React, {Component } from 'react';
export default function formatPhone(phone){
    return FormatPhoneNumber(phone)
}

function FormatPhoneNumber(num) {
    if(!num)return null
    var str = num.toString();

    var matched = str.match(/\d+\.?\d*/g);
    if(!matched) return null
    // 10 digit
    if (matched.length === 3) {
        return '(' + matched[0] + ') ' + matched[1] + '-' + matched[2];
    // 7 digit
    } else if (matched.length === 2) {
        return matched[0] + '-' + matched[1];
    }
    // no formatting attempted only found integers (i.e. 1234567890)
    else if (matched.length === 1) {
        // 10 digit
        if (matched[0].length === 10) {
            return '(' + matched[0].substr(0, 3) + ') ' + matched[0].substr(3, 3) + '-' + matched[0].substr(6);
        }
        // 7 digit
        if (matched[0].length === 7) {
            return matched[0].substr(0, 3) + '-' + matched[0].substr(3);
        }
    }

    // Format failed, return number back
    return num;
}

export function formatPhoneNumberWithHyphens(phone){
  var s2 = (""+phone).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
}
