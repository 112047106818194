import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import isMobile from '../../../../HOC/Mobile.js'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {reduxForm,Field,Form,change,untouch,reset} from 'redux-form'
import {compose} from 'recompose'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types';
import { checkValueExists } from "../../../../lib/check-value-exists"
import { WernerServices } from '../../../customers/a98db973/services';
import LoadingOverlay from '../../LoadingOverlay'

import CustomTextFieldInput from '../../../../components/CustomTextFieldInput'
import PhoneNumberInput from '../../PhoneNumberInput'


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



const AddNewContactModal = (
    {
        onClose,
        isEdit = false,
        isMobile,
        handleSubmit,
        dispatch,
        muiTheme,
        ...props
    }
) => {
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiInputBase-root' : {
                fontSize: 16
            },
            '& .MuiFormLabel-root' : {
                fontSize: 16
            }
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            width: isMobile ? '100%' : 700,
            height: isMobile ? '100%' : 'unset',
            outline: 'none',
            padding: isMobile ? 5 : theme.spacing(2, 4, 3),
            overflowY: 'auto'
        },
        header: {
            margin: 0
        },
        row: {
            marginBottom: 20
        },
        menueItemFont: {
            fontSize: 16
        }
    }));
    const classes = useStyles()

    const {initialValues={},locationsList=[]}=props

    const [isApiCalling, setIsApiCalling] = useState(false);

    const [isAddressIdFieldInvalid, setIsAddressIdFieldInvalid] = useState(false);
    const [addressIdField, setAddressIdField] = useState(checkValueExists(initialValues,'addressId') ?initialValues.addressId:'' );


    const [isContactTypeFieldInvalid, setIsContactTypeFieldInvalid] = useState(false);
    const [contactTypeField, setContactTypeField] = useState(checkValueExists(initialValues,'contactType') ?initialValues.contactType:'' );


    const [isNameFieldInvalid, setIsNameFieldInvalid] = useState(false);
    const [nameField, setNameField] = useState(checkValueExists(initialValues,'name') ?initialValues.name:'' );


    const [isTitleFieldInvalid, setIsTitleFieldInvalid] = useState(false);
    const [titleField, setTitleField] = useState(checkValueExists(initialValues,'title') ?initialValues.title:'' );


    const [isRoleFieldInvalid, setIsRoleFieldInvalid] = useState(false);
    const [roleField, setRoleField] = useState(checkValueExists(initialValues,'role') ?initialValues.role:'' );


    const [isPhoneMainFieldInvalid, setIsPhoneMainFieldInvalid] = useState(false);
    const [phoneMainField, setPhoneMainField] = useState(checkValueExists(initialValues,'phone') ?initialValues.phone:'' );
    const [phoneMainFullField, setPhoneMainFullField] = useState(checkValueExists(initialValues,'phoneFull') ?initialValues.phoneFull:null );
    

    const [phoneSecondaryField, setPhoneSecondaryField] = useState(checkValueExists(initialValues,'phoneSecondary') ?initialValues.phoneSecondary:'' );
    const [phoneCellField, setPhoneCellField] = useState(checkValueExists(initialValues,'phoneCell') ?initialValues.phoneCell:'' );

    const [phoneSecondaryFullField, setPhoneSecondaryFullField] = useState(checkValueExists(initialValues,'phoneSecondaryFull') ?initialValues.phoneSecondaryFull:null );
    const [phoneCellFullField, setPhoneCellFullField] = useState(checkValueExists(initialValues,'phoneCellFull') ?initialValues.phoneCellFull:null );


    const [isFaxFieldInvalid, setIsFaxFieldInvalid] = useState(false);
    const [faxField, setFaxField] = useState(checkValueExists(initialValues,'fax') ?initialValues.fax:'' );
    const [faxFullField, setFaxFullField] = useState(checkValueExists(initialValues,'faxFull') ?initialValues.faxFull:null );


    const [isEmailFieldInvalid, setIsEmailFieldInvalid] = useState(false);
    const [emailField, setEmailField] = useState(checkValueExists(initialValues,'email') ?initialValues.email:'' );


    const [emailSecondaryField, setEmailSecondaryField] = useState(checkValueExists(initialValues,'secondaryEmail') ?initialValues.secondaryEmail:'' );
    const [emailOtherField, setEmailOtherField] = useState(checkValueExists(initialValues,'otherEmail') ?initialValues.otherEmail:'' );



    useEffect(() => {
    }, []);



    useEffect(() => {
        if(initialValues){
            let phoneMainTmp = null
            let phoneSecondaryTmp = null
            let cellTmp = null
            
            if(checkValueExists(initialValues,'phoneList')){
                initialValues.phoneList.forEach((each)=>{
                    if(checkValueExists(each,'type')){
                        switch (each.type) {
                            case "MAIN":
                                setPhoneMainFullField(each)
                                setPhoneMainField(each.phoneNumber)
                                break;
                            case "SECONDARY":
                                phoneSecondaryFullField(each)
                                phoneSecondaryField(each.phoneNumber)
                                break;
                            case "CELL":
                                phoneCellFullField(each)
                                phoneCellField(each.phoneNumber)
                                break;
                            default:
                                break;
                        }
                    }
                })
            }


            if(checkValueExists(initialValues,'faxFull')){
                setFaxFullField(initialValues.faxFull)
                setFaxField(initialValues.faxFull.phoneNumber)
            }
        }
    }, [initialValues]);



    const handleClose = () => {
        onClose(true)
    };

    const closeModal = () => {
        onClose(false)
    };

    const onAddressIdSelect = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setAddressIdField(value)
            setIsAddressIdFieldInvalid(false)
        }else{
            setIsAddressIdFieldInvalid(true)
            setAddressIdField('')
        }
    }


    const onContactTypeSelect = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setContactTypeField(value)
            setIsContactTypeFieldInvalid(false)
        }else{
            setIsContactTypeFieldInvalid(true)
            setContactTypeField('')
        }
    }




    const onNameChange = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setNameField(value)
            setIsNameFieldInvalid(false)
        }else{
            setIsNameFieldInvalid(true)
            setNameField('')
        }
    }

    const onTitleChange = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setTitleField(value)
            setIsTitleFieldInvalid(false)
        }else{
            setIsTitleFieldInvalid(true)
            setTitleField('')
        }
    }

    const onRoleChange = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setRoleField(value)
            setIsRoleFieldInvalid(false)
        }else{
            setIsRoleFieldInvalid(true)
            setRoleField('')
        }
    }

    const onPhoneMainChange = (data)=>{
        let value = data.phoneNumber
        if(value && value.trim()!=='' && value.trim().length ==10 ){
            setPhoneMainField(value)
            setPhoneMainFullField(data)
            setIsPhoneMainFieldInvalid(false)
        }else{
            setIsPhoneMainFieldInvalid(true)
            setPhoneMainField('')
            setPhoneMainFullField(null)
        }
    }

    const onPhoneSecondaryChange = (data)=>{
        let value = data.phoneNumber
        if(value && value.trim()!=='' && value.trim().length ==10){
            setPhoneSecondaryField(value)
            setPhoneSecondaryFullField(data)
        }else{
            setPhoneSecondaryField('')
            setPhoneSecondaryFullField(null)
        }
    }

    

    const onPhoneCellChange = (data)=>{
        let value = data.phoneNumber
        if(value && value.trim()!=='' && value.trim().length ==10){
            setPhoneCellField(value)
            setPhoneCellFullField(data)
        }else{
            setPhoneCellField('')
            setPhoneCellFullField(null)
        }
    }




    const onFaxChange = (data)=>{
        let value = data.phoneNumber
        if(value && value.trim()!=='' && value.trim().length ==10){
            setFaxField(value)
            setFaxFullField(data)
            setIsFaxFieldInvalid(false)
        }else{
            setIsFaxFieldInvalid(true)
            setFaxFullField(null)
            setFaxField('')
        }
    }

    

    const onEmailChange = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setEmailField(value)
            setIsEmailFieldInvalid(false)
        }else{
            setIsEmailFieldInvalid(true)
            setEmailField('')
        }
    }
    

    const onEmailSecondaryChange = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setEmailSecondaryField(value)
        }else{
            setEmailSecondaryField('')
        }
    }
    

    const onEmailOtherChange = (event)=>{
        let value = event.target.value
        if(value && value.trim()!==''){
            setEmailOtherField(value)
        }else{
            setEmailOtherField('')
        }
    }


    const contactAddSubmit =(formValue)=>{
        let addressId = addressIdField.trim() 
        let contactType = contactTypeField.trim() 
        let name = ''
        let title = ''
        let role = roleField.trim() 
        let phone = phoneMainField.trim() 
        let phoneSecondary = phoneSecondaryField.trim() 
        let phoneCell = phoneCellField.trim() 
        let fax = faxField.trim() 
        let email = ''
        let secondaryEmail = ''
        let otherEmail = ''
        

        if(addressId ==''){
            setIsAddressIdFieldInvalid(true)
            return false
        }else{
            setIsAddressIdFieldInvalid(false)
        }

        if(contactType ==''){
            setIsContactTypeFieldInvalid(true)
            return false
        }else{
            setIsContactTypeFieldInvalid(false)
        }


        if(checkValueExists(formValue,'name') ){
            name = formValue.name
            name = name.trim()
            if(name ==''){
                setIsNameFieldInvalid(true)
                return false
            }else{
                setIsNameFieldInvalid(false)
            }
        }else{
            setIsNameFieldInvalid(true)
            return false
        }

        if(checkValueExists(formValue,'title') ){
            title = formValue.title
            title = title.trim()
            // if(title ==''){
            //     setIsTitleFieldInvalid(true)
            //     return false
            // }else{
            //     setIsTitleFieldInvalid(false)
            // }
        }else{
            //setIsTitleFieldInvalid(true)
            //return false
        }



        // if(role ==''){
        //     setIsRoleFieldInvalid(true)
        //     return false
        // }else{
        //     setIsRoleFieldInvalid(false)
        // }
        



        if(phone ==''){
            setIsPhoneMainFieldInvalid(true)
            return false
        }else{
            setIsPhoneMainFieldInvalid(false)
        }
        


        if(fax ==''){
            setIsFaxFieldInvalid(true)
            return false
        }else{
            setIsFaxFieldInvalid(false)
        }
        


        if(checkValueExists(formValue,'email') ){
            email = formValue.email
            email = email.trim()
            if(email ==''){
                setIsEmailFieldInvalid(true)
                return false
            }else{
                setIsEmailFieldInvalid(false)
            }
        }else{
            setIsEmailFieldInvalid(true)
            return false
        }




        if(checkValueExists(formValue,'secondaryEmail') ){
            secondaryEmail = formValue.secondaryEmail
            secondaryEmail = secondaryEmail.trim()
            // if(title ==''){
            //     setIsTitleFieldInvalid(true)
            //     return false
            // }else{
            //     setIsTitleFieldInvalid(false)
            // }
        }else{
            //setIsTitleFieldInvalid(true)
            //return false
        }


        if(checkValueExists(formValue,'otherEmail') ){
            otherEmail = formValue.otherEmail
            otherEmail = otherEmail.trim()
            // if(title ==''){
            //     setIsTitleFieldInvalid(true)
            //     return false
            // }else{
            //     setIsTitleFieldInvalid(false)
            // }
        }else{
            //setIsTitleFieldInvalid(true)
            //return false
        }


        let phoneList= []

        let ext = '';
        let countryCode = ''
        if(phoneMainFullField  && checkValueExists(phoneMainFullField,'phoneNumber') && phoneMainFullField.phoneNumber.trim()!=='' ){
            phoneMainFullField.phoneNumber = parseInt(phoneMainFullField.phoneNumber)
            phoneList.push(phoneMainFullField)
            ext = phoneMainFullField.extension
            countryCode = phoneMainFullField.countryCode
            countryCode = countryCode.replace("+", "");
            if(ext && ext.trim()!==''){
                ext = 'x'+ext
            }else{
                ext = ''
            }
            phone = countryCode +'-'+phone+ext
        }


        ext = '';
        countryCode = ''
        if(phoneSecondaryFullField && checkValueExists(phoneSecondaryFullField,'phoneNumber') && phoneSecondaryFullField.phoneNumber.trim()!=='' ){
            phoneSecondaryFullField.phoneNumber = parseInt(phoneSecondaryFullField.phoneNumber)
            phoneList.push(phoneSecondaryFullField)
            ext = phoneSecondaryFullField.extension
            countryCode = phoneSecondaryFullField.countryCode
            countryCode = countryCode.replace("+", "");
            if(ext && ext.trim()!==''){
                ext = 'x'+ext
            }else{
                ext = ''
            }
            phoneSecondary = countryCode +'-'+phoneSecondary+ext
        }


        ext = '';
        countryCode = ''
        if(phoneCellFullField && checkValueExists(phoneCellFullField,'phoneNumber') && phoneCellFullField.phoneNumber.trim()!=='' ){
            phoneCellFullField.phoneNumber = parseInt(phoneCellFullField.phoneNumber)
            phoneList.push(phoneCellFullField)
            ext = phoneCellFullField.extension
            countryCode = phoneCellFullField.countryCode
            countryCode = countryCode.replace("+", "");
            if(ext && ext.trim()!==''){
                ext = 'x'+ext
            }else{
                ext = ''
            }
            phoneCell = countryCode +'-'+phoneCell+ext
        }


        ext = '';
        countryCode = ''
        if(faxFullField && checkValueExists(faxFullField,'phoneNumber') && faxFullField.phoneNumber.trim()!=='' ){
            faxFullField.phoneNumber = parseInt(faxFullField.phoneNumber)
            ext = faxFullField.extension
            countryCode = faxFullField.countryCode
            countryCode = countryCode.replace("+", "");
            if(ext && ext.trim()!==''){
                ext = 'x'+ext
            }else{
                ext = ''
            }
            fax = countryCode +'-'+fax+ext
        }
        

        let params ={
            addressId:addressId,
            contactType:contactType.toUpperCase(),

            name:name,
            title:title,
            role:role,

            cell:phoneCell,
            fax:fax,
            phone:phone,
            secondaryPhone:phoneSecondary,
            email:email,
            secondaryEmail:secondaryEmail,
            otherEmail:otherEmail,

            phoneList:phoneList,
            faxVo:faxFullField
        }
        if(isEdit){
            params.addressId = initialValues.addressId
            params.contactId = initialValues.contactId
            callEditApi(params)
        }else{
            callAddApi(params)
        }
    }

    const callAddApi = (params)=>{
        const apiServices = new WernerServices()
        setIsApiCalling(true)
        apiServices.addContact(params)
            .then(response => {
                setIsApiCalling(false)
                if (response ) {
                    handleClose()
                }
                // setIsLoading(false)
            })
            .catch(error => {
                setIsApiCalling(false)
                // console.log("ERROR 2: ", error)
                // let errorMessage = "Error: There was a problem fetching your booked loads."
                // setIsLoading(false)
                // toast.error(errorMessage)
            })
    }

    const callEditApi = (params)=>{
        const apiServices = new WernerServices()
        setIsApiCalling(true)
        apiServices.editContact(params)
            .then(response => {
                setIsApiCalling(false)
                if (response ) {
                    handleClose()
                }
                // setIsLoading(false)
            })
            .catch(error => {
                setIsApiCalling(false)
                // console.log("ERROR 2: ", error)
                // let errorMessage = "Error: There was a problem fetching your booked loads."
                // setIsLoading(false)
                // toast.error(errorMessage)
            })
    }


    return (
            <div>
                {!isMobile  && (
                    <Modal
                        aria-labelledby="add-new-location"
                        aria-describedby="add-new-location"
                        className={classes.modal}
                        open={true}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        // disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <div className={classes.paper} style={{padding:15}} >
                            {isApiCalling && <LoadingOverlay />}
                            <h3 id="transition-modal-title" style={{marginBottom:15}} >{isEdit?'EDIT':'ADD'}  CONTACT</h3>
                            <Form  noValidate onSubmit={handleSubmit(contactAddSubmit)} >
                                <Row style={{display: 'flex', justifyContent: 'flex-start' }} >
                                    <Col  style={{marginTop:0 , width:'49.5%'}} >
                                        <FormControl variant="filled" className={classes.formControl} fullWidth required >
                                            <InputLabel id="addressId">Location</InputLabel>
                                            <Select
                                                labelId="addressIdLbl"
                                                id="addressIdSelect"
                                                value={addressIdField}
                                                fullWidth
                                                required
                                                error={isAddressIdFieldInvalid}
                                                onChange={(event)=>onAddressIdSelect(event)}
                                                disabled={true}
                                                >
                                                {locationsList.map((loc) => {
                                                    return (
                                                        <MenuItem className={classes.menueItemFont} value={loc.addressId}>{loc.addressType} - {loc.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col style={{marginTop:0,width:'49.5%',marginLeft:'1%'}} >
                                        <FormControl variant="filled" className={classes.formControl} fullWidth  >
                                            <InputLabel id="role">Role</InputLabel>
                                            <Select
                                                labelId="roleLblId"
                                                id="roleSelectId"
                                                value={roleField}
                                                fullWidth
                                                required
                                                error={isRoleFieldInvalid}
                                                onChange={(event)=>onRoleChange(event)}
                                                >
                                                
                                                <MenuItem className={classes.menueItemFont} value={'ADMIN'}>ADMIN</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'DISPATCHER'}>DISPATCHER</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'LOADVIEW'}>LOAD VIEWER</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'FINANCE'}>FINANCE</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* <FormControl variant="filled" className={classes.formControl} fullWidth required >
                                            <InputLabel id="contactType">Contact Type</InputLabel>
                                            <Select
                                                labelId="contactTypeLbl"
                                                id="contactTypeSelect"
                                                value={contactTypeField}
                                                fullWidth
                                                required
                                                error={isContactTypeFieldInvalid}
                                                onChange={(event)=>onContactTypeSelect(event)}
                                                >
                                                
                                                <MenuItem className={classes.menueItemFont} value={'ACCOUNTS PAYABLE'}>Accounts Payable</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'AFTER HOURS'}>After Hours</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'CLAIMS'}>Claims</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'CORPORATE'}>Corporate</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'DISPATCH'}>Dispatch</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'OTHER'}>Other</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'PRIMARY'}>Primary</MenuItem> 
                                                <MenuItem className={classes.menueItemFont} value={'SALES'}>Sales</MenuItem> 
                                                <MenuItem className={classes.menueItemFont} value={'TRACKINF'}>Tracking</MenuItem> 
                                                <MenuItem className={classes.menueItemFont} value={'ANYOTHERCONTACTTYPE'}>Any other contact type</MenuItem> 
                                                
                                            </Select>
                                        </FormControl> */}
                                    </Col>
                                </Row>
                                <Row style={{display: 'flex', justifyContent: 'flex-start'  ,marginTop:10}}>
                                    <Col style={{marginTop:0,width:'49.5%'}} >
                                        <Field 
                                            id="name" 
                                            label="Full name" 
                                            variant="filled" 
                                            fullWidth 
                                            required
                                            key={"name"}
                                            name={"name"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={nameField}
                                            onChange={ (event)=>onNameChange(event) }
                                            error={isNameFieldInvalid}
                                        />
                                    </Col>
                                    <Col style={{marginTop:0,width:'49.5%',marginLeft:'1%'}} >
                                        <Field 
                                            id="title" 
                                            label="Title" 
                                            variant="filled" 
                                            fullWidth 
                                            //required
                                            key={"title"}
                                            name={"title"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={titleField}
                                            onChange={ (event)=>onTitleChange(event) }
                                            error={isTitleFieldInvalid}
                                        />
                                    </Col>
                                </Row>
                                
                                <h4 id="transition-modal-title" style={{marginTop:15,marginBottom:0}} >Phone</h4>
                                <Row  style={{display: 'flex', justifyContent: 'flex-start'  ,marginTop:10}}>
                                    <Col style={{marginTop:0 , width:'49.5%'}}>
                                        {/* <Field 
                                            id="phone" 
                                            label="Main" 
                                            variant="filled" 
                                            fullWidth 
                                            required
                                            key={"phone"}
                                            name={"phone"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={phoneMainField}
                                            onChange={(event) => {(event)=>onPhoneMainChange(event)} }
                                            error={isPhoneMainFieldInvalid}
                                        /> */}
                                        <PhoneNumberInput 
                                            label="Main" 
                                            variant="filled" 
                                            fullWidth
                                            required={true}
                                            key={"phone"}
                                            name={"phone"}
                                            type={'MAIN'}
                                            country={phoneMainFullField   &&  checkValueExists(phoneMainFullField,'country') ? phoneMainFullField.country.toLowerCase():null}
                                            ext={phoneMainFullField   &&  checkValueExists(phoneMainFullField,'extension') ? phoneMainFullField.extension:null}
                                            value={phoneMainField}
                                            onValueChange={(event) => onPhoneMainChange(event) }
                                            error={isPhoneMainFieldInvalid}
                                        />
                                    </Col>
                                    <Col style={{marginTop:0 , width:'49.5%',marginLeft:'1%'}}>
                                        <PhoneNumberInput 
                                            label="Other (Optional)" 
                                            variant="filled" 
                                            fullWidth
                                            required={false}
                                            key={"phoneSecondary"}
                                            name={"phoneSecondary"}
                                            type={'SECONDARY'}
                                            value={phoneSecondaryField}
                                            country={phoneSecondaryFullField   &&  checkValueExists(phoneSecondaryFullField,'country') ? phoneSecondaryFullField.country.toLowerCase():null}
                                            ext={phoneSecondaryFullField   &&  checkValueExists(phoneSecondaryFullField,'extension') ? phoneSecondaryFullField.extension:null}
                                            onValueChange={(event) => onPhoneSecondaryChange(event) }
                                        />
                                    </Col>
                                </Row>
                                <Row  style={{display: 'flex', justifyContent: 'flex-start'  ,marginTop:10}}>
                                    <Col style={{marginTop:0 , width:'49.5%'}}>
                                        <PhoneNumberInput 
                                            label="Mobile (Optional)" 
                                            variant="filled" 
                                            fullWidth
                                            required={false}
                                            key={"phoneCell"}
                                            name={"phoneCell"}
                                            type={'CELL'}
                                            value={phoneCellField}
                                            country={phoneCellFullField   &&  checkValueExists(phoneCellFullField,'country') ? phoneCellFullField.country.toLowerCase():null}
                                            ext={phoneCellFullField   &&  checkValueExists(phoneCellFullField,'extension') ? phoneCellFullField.extension:null}
                                            onValueChange={(event) => onPhoneCellChange(event) }
                                        />
                                    </Col>
                                    <Col style={{marginTop:0 , width:'49.5%',marginLeft:'1%'}}>
                                        {/* <Field 
                                            id="fax" 
                                            label="Fax" 
                                            variant="filled" 
                                            fullWidth 
                                            required
                                            key={"fax"}
                                            name={"fax"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={faxField}
                                            onChange={(event) => {(event)=>onFaxChange(event)} }
                                            error={isFaxFieldInvalid}
                                        /> */}

                                        <PhoneNumberInput 
                                            label="Fax (Optional)" 
                                            variant="filled" 
                                            fullWidth
                                            required={false}
                                            key={"fax"}
                                            name={"fax"}
                                            type={'FAX'}
                                            value={faxField}
                                            country={faxFullField   &&  checkValueExists(faxFullField,'country') ? faxFullField.country.toLowerCase():null}
                                            ext={faxFullField   &&  checkValueExists(faxFullField,'extension') ? faxFullField.extension:null}
                                            onValueChange={(event) => onFaxChange(event) }
                                            error={isFaxFieldInvalid}
                                        />
                                    </Col>
                                </Row>

                                <h4 id="transition-modal-title" style={{marginTop:15,marginBottom:0}} >Email</h4>
                                <Row  style={{display: 'flex', justifyContent: 'flex-start'  ,marginTop:10}}>
                                    <Col style={{marginTop:0 , width:'49.5%'}}>
                                        <Field 
                                            id="email" 
                                            label="Main" 
                                            variant="filled" 
                                            fullWidth 
                                            required={true}
                                            key={"email"}
                                            name={"email"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={emailField}
                                            onChange={ (event)=>onEmailChange(event) }
                                            error={isEmailFieldInvalid}
                                        />
                                    </Col>
                                    {/* <Col style={{marginTop:0 , width:'32%',marginLeft:'1%'}}>
                                        <Field 
                                            id="secondaryEmail" 
                                            label="Email 2 (Optional)" 
                                            variant="filled" 
                                            fullWidth 
                                            //required
                                            key={"secondaryEmail"}
                                            name={"secondaryEmail"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={emailSecondaryField}
                                            onChange={ (event)=>onEmailSecondaryChange(event) }
                                            //error={isAddressLine2FieldInValid}
                                        />
                                    </Col>
                                    <Col style={{marginTop:0 , width:'32%',marginLeft:'1%'}}>
                                        <Field 
                                            id="otherEmail" 
                                            label="Email 3 (Optional)" 
                                            variant="filled" 
                                            fullWidth 
                                            //required
                                            key={"otherEmail"}
                                            name={"otherEmail"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={emailOtherField}
                                            onChange={ (event)=>onEmailOtherChange(event) }
                                            //error={isAddressLine2FieldInValid}
                                        />
                                    </Col> */}
                                </Row>
                                
                                <Row  style={{ width: "100%", display: "flex", justifyContent: "center",marginTop:10 }} >
                                    <Col >
                                        <Button
                                            id='cancelSavingNewLocation'
                                            size="small"
                                            variant="link"
                                            style={{
                                                color: muiTheme.actionColor,
                                                fontSize: 14,
                                                textTransform:'uppercase',
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                            onClick={(event) => { closeModal(event) }}
                                            disabled={isApiCalling}
                                        >
                                        <span style={{lineHeight: '25px',marginLeft:5}} >Cancel</span>
                                        </Button>
                                    </Col>
                                    <Col >
                                        <Button
                                            id='saveNewLocation'
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: muiTheme.actionColor,
                                                borderColor: muiTheme.actionColor,
                                                color: "white",
                                                fontSize: 14,
                                                textTransform:'uppercase',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginLeft:10
                                            }}
                                            type="submit"
                                            disabled={isApiCalling}
                                        >
                                        <span style={{lineHeight: '25px',marginLeft:5}} >SAVE</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                </Modal>
            )}

            {isMobile  && (
                    <Modal
                        aria-labelledby="add-new-location"
                        aria-describedby="add-new-location"
                        className={classes.modal}
                        open={true}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        // disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <div className={classes.paper} style={{padding:15}} >
                            {isApiCalling && <LoadingOverlay />}
                            <h3 id="transition-modal-title" style={{marginBottom:15}} >{isEdit?'EDIT':'ADD'}  CONTACT</h3>
                            <Form  noValidate onSubmit={handleSubmit(contactAddSubmit)} >
                                <Row style={{display: 'flex', justifyContent: 'flex-start' }} >
                                    <Col  style={{marginTop:0 , width:'100%'}} >
                                        <FormControl variant="filled" className={classes.formControl} fullWidth required >
                                            <InputLabel id="addressId">Location</InputLabel>
                                            <Select
                                                labelId="addressIdLbl"
                                                id="addressIdSelect"
                                                value={addressIdField}
                                                fullWidth
                                                required
                                                error={isAddressIdFieldInvalid}
                                                onChange={(event)=>onAddressIdSelect(event)}
                                                disabled={true}
                                                >
                                                {locationsList.map((loc) => {
                                                    return (
                                                        <MenuItem className={classes.menueItemFont} value={loc.addressId}>{loc.addressType} - {loc.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                            
                                </Row>
                                <Row style={{display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
                                    <Col style={{marginTop:0, width:'100%'}} >
                                        <FormControl variant="filled" className={classes.formControl} fullWidth  >
                                            <InputLabel id="role">Role</InputLabel>
                                            <Select
                                                labelId="roleLblId"
                                                id="roleSelectId"
                                                value={roleField}
                                                fullWidth
                                                required
                                                error={isRoleFieldInvalid}
                                                onChange={(event)=>onRoleChange(event)}
                                                >
                                                
                                                <MenuItem className={classes.menueItemFont} value={'ADMIN'}>ADMIN</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'DISPATCHER'}>DISPATCHER</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'LOADVIEW'}>LOAD VIEWER</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'FINANCE'}>FINANCE</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* <FormControl variant="filled" className={classes.formControl} fullWidth required >
                                            <InputLabel id="contactType">Contact Type</InputLabel>
                                            <Select
                                                labelId="contactTypeLbl"
                                                id="contactTypeSelect"
                                                value={contactTypeField}
                                                fullWidth
                                                required
                                                error={isContactTypeFieldInvalid}
                                                onChange={(event)=>onContactTypeSelect(event)}
                                                >
                                                
                                                <MenuItem className={classes.menueItemFont} value={'ACCOUNTS PAYABLE'}>Accounts Payable</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'AFTER HOURS'}>After Hours</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'CLAIMS'}>Claims</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'CORPORATE'}>Corporate</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'DISPATCH'}>Dispatch</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'OTHER'}>Other</MenuItem>
                                                <MenuItem className={classes.menueItemFont} value={'PRIMARY'}>Primary</MenuItem> 
                                                <MenuItem className={classes.menueItemFont} value={'SALES'}>Sales</MenuItem> 
                                                <MenuItem className={classes.menueItemFont} value={'TRACKINF'}>Tracking</MenuItem> 
                                                <MenuItem className={classes.menueItemFont} value={'ANYOTHERCONTACTTYPE'}>Any other contact type</MenuItem> 
                                                
                                            </Select>
                                        </FormControl> */}
                                    </Col>
                                </Row>
                                <Row style={{display: 'flex', justifyContent: 'flex-start', marginTop:10}}>
                                    <Col style={{marginTop:0,width:'100%'}} >
                                        <Field 
                                            id="name" 
                                            label="Full name" 
                                            variant="filled" 
                                            fullWidth 
                                            required
                                            key={"name"}
                                            name={"name"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={nameField}
                                            onChange={ (event)=>onNameChange(event) }
                                            error={isNameFieldInvalid}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{display: 'flex', justifyContent: 'flex-start', marginTop:10}}>
                                    <Col style={{marginTop:0,width:'100%'}} >
                                        <Field 
                                            id="title" 
                                            label="Title" 
                                            variant="filled" 
                                            fullWidth 
                                            //required
                                            key={"title"}
                                            name={"title"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={titleField}
                                            onChange={ (event)=>onTitleChange(event) }
                                            error={isTitleFieldInvalid}
                                        />
                                    </Col>
                                </Row>
                                
                                <h4 id="transition-modal-title" style={{marginTop:15,marginBottom:0}} >Phone</h4>
                                <Row  style={{display: 'flex', justifyContent: 'flex-start', marginTop:10}}>
                                    <Col style={{marginTop:0 , width:'100%'}}>
                                        {/* <Field 
                                            id="phone" 
                                            label="Main" 
                                            variant="filled" 
                                            fullWidth 
                                            required
                                            key={"phone"}
                                            name={"phone"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={phoneMainField}
                                            onChange={(event) => {(event)=>onPhoneMainChange(event)} }
                                            error={isPhoneMainFieldInvalid}
                                        /> */}
                                        <PhoneNumberInput 
                                            label="Main" 
                                            variant="filled" 
                                            fullWidth
                                            required={true}
                                            key={"phone"}
                                            name={"phone"}
                                            type={'MAIN'}
                                            country={phoneMainFullField   &&  checkValueExists(phoneMainFullField,'country') ? phoneMainFullField.country.toLowerCase():null}
                                            ext={phoneMainFullField   &&  checkValueExists(phoneMainFullField,'extension') ? phoneMainFullField.extension:null}
                                            value={phoneMainField}
                                            onValueChange={(event) => onPhoneMainChange(event) }
                                            error={isPhoneMainFieldInvalid}
                                        />
                                    </Col>
                                </Row>
                                <Row  style={{display: 'flex', justifyContent: 'flex-start', marginTop:10}}>
                                    <Col style={{marginTop:0 , width:'100%'}}>
                                        <PhoneNumberInput 
                                            label="Other (Optional)" 
                                            variant="filled" 
                                            fullWidth
                                            required={false}
                                            key={"phoneSecondary"}
                                            name={"phoneSecondary"}
                                            type={'SECONDARY'}
                                            value={phoneSecondaryField}
                                            country={phoneSecondaryFullField   &&  checkValueExists(phoneSecondaryFullField,'country') ? phoneSecondaryFullField.country.toLowerCase():null}
                                            ext={phoneSecondaryFullField   &&  checkValueExists(phoneSecondaryFullField,'extension') ? phoneSecondaryFullField.extension:null}
                                            onValueChange={(event) => onPhoneSecondaryChange(event) }
                                        />
                                    </Col>
                                </Row>
                                <Row  style={{display: 'flex', justifyContent: 'flex-start'  ,marginTop:10}}>
                                    <Col style={{marginTop:0 , width:'100%'}}>
                                        <PhoneNumberInput 
                                            label="Mobile (Optional)" 
                                            variant="filled" 
                                            fullWidth
                                            required={false}
                                            key={"phoneCell"}
                                            name={"phoneCell"}
                                            type={'CELL'}
                                            value={phoneCellField}
                                            country={phoneCellFullField   &&  checkValueExists(phoneCellFullField,'country') ? phoneCellFullField.country.toLowerCase():null}
                                            ext={phoneCellFullField   &&  checkValueExists(phoneCellFullField,'extension') ? phoneCellFullField.extension:null}
                                            onValueChange={(event) => onPhoneCellChange(event) }
                                        />
                                    </Col>
                                </Row>
                                <Row  style={{display: 'flex', justifyContent: 'flex-start'  ,marginTop:10}}>
                                    <Col style={{marginTop:0 , width:'100%'}}>
                                        {/* <Field 
                                            id="fax" 
                                            label="Fax" 
                                            variant="filled" 
                                            fullWidth 
                                            required
                                            key={"fax"}
                                            name={"fax"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={faxField}
                                            onChange={(event) => {(event)=>onFaxChange(event)} }
                                            error={isFaxFieldInvalid}
                                        /> */}

                                        <PhoneNumberInput 
                                            label="Fax (Optional)" 
                                            variant="filled" 
                                            fullWidth
                                            required={false}
                                            key={"fax"}
                                            name={"fax"}
                                            type={'FAX'}
                                            value={faxField}
                                            country={faxFullField   &&  checkValueExists(faxFullField,'country') ? faxFullField.country.toLowerCase():null}
                                            ext={faxFullField   &&  checkValueExists(faxFullField,'extension') ? faxFullField.extension:null}
                                            onValueChange={(event) => onFaxChange(event) }
                                            error={isFaxFieldInvalid}
                                        />
                                    </Col>
                                </Row>

                                <h4 id="transition-modal-title" style={{marginTop:15,marginBottom:0}} >Email</h4>
                                <Row  style={{display: 'flex', justifyContent: 'flex-start', marginTop:10}}>
                                    <Col style={{marginTop:0 , width:'100%'}}>
                                        <Field 
                                            id="email" 
                                            label="Main" 
                                            variant="filled" 
                                            fullWidth 
                                            required={true}
                                            key={"email"}
                                            name={"email"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={emailField}
                                            onChange={ (event)=>onEmailChange(event) }
                                            error={isEmailFieldInvalid}
                                        />
                                    </Col>
                                    {/* <Col style={{marginTop:0 , width:'32%',marginLeft:'1%'}}>
                                        <Field 
                                            id="secondaryEmail" 
                                            label="Email 2 (Optional)" 
                                            variant="filled" 
                                            fullWidth 
                                            //required
                                            key={"secondaryEmail"}
                                            name={"secondaryEmail"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={emailSecondaryField}
                                            onChange={ (event)=>onEmailSecondaryChange(event) }
                                            //error={isAddressLine2FieldInValid}
                                        />
                                    </Col>
                                    <Col style={{marginTop:0 , width:'32%',marginLeft:'1%'}}>
                                        <Field 
                                            id="otherEmail" 
                                            label="Email 3 (Optional)" 
                                            variant="filled" 
                                            fullWidth 
                                            //required
                                            key={"otherEmail"}
                                            name={"otherEmail"}
                                            type="text"
                                            component={CustomTextFieldInput}
                                            value={emailOtherField}
                                            onChange={ (event)=>onEmailOtherChange(event) }
                                            //error={isAddressLine2FieldInValid}
                                        />
                                    </Col> */}
                                </Row>
                                
                                <Row  style={{ width: "100%", display: "flex", justifyContent: "center",marginTop:10 }} >
                                    <Col >
                                        <Button
                                            id='cancelSavingNewLocation'
                                            size="small"
                                            variant="link"
                                            style={{
                                                color: muiTheme.actionColor,
                                                fontSize: 14,
                                                textTransform:'uppercase',
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                            onClick={(event) => { closeModal(event) }}
                                            disabled={isApiCalling}
                                        >
                                        <span style={{lineHeight: '25px',marginLeft:5}} >Cancel</span>
                                        </Button>
                                    </Col>
                                    <Col >
                                        <Button
                                            id='saveNewLocation'
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: muiTheme.actionColor,
                                                borderColor: muiTheme.actionColor,
                                                color: "white",
                                                fontSize: 14,
                                                textTransform:'uppercase',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginLeft:10
                                            }}
                                            type="submit"
                                            disabled={isApiCalling}
                                        >
                                        <span style={{lineHeight: '25px',marginLeft:5}} >SAVE</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                </Modal>
            )}
        </div>
    )
}




    const mapStateToProps = state => ({
    })

    function matchDispatchToProps(dispatch){
        return  bindActionCreators({
            //inviteCarrierAction:inviteCarrierAction
        },dispatch)
    }


    AddNewContactModal.propTypes={
        //label:PropTypes.string.isRequired
          handleSubmit: PropTypes.func,
  
    }

    const formed = reduxForm({
        form: 'addLocationForm',
        enableReinitialize: true,
        destroyOnUnmount:true
    })(AddNewContactModal)


    export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        muiThemeable(),
        isMobile()
    )(formed)