import { PostRemoteLog } from '../CarrierPortal/apis/api-remote-log'
import {useAuthenticationHook} from '../CarrierPortal/hooks/authentication-hook'
import { BACKEND_URL } from './constants'

const FETCH_ALL_QUOTES_URL = "/getAllQuotes"
const SUBMIT_QUOTE_NEGOTIATION_URL = "/quoteNegotiation"


export const FetchAllQuotes = (startDate, endDate, loadId = null) => {
    const myAuthService = useAuthenticationHook()

    let url = BACKEND_URL + FETCH_ALL_QUOTES_URL

    if(loadId)
        url = url + "?loadid=" + loadId
    else
        url = url + "?start=" + startDate + "&end=" + endDate

    try {
        let requestObject = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + FETCH_ALL_QUOTES_URL,
                    message: {
                        error: JSON.stringify(error)
                    }
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + FETCH_ALL_QUOTES_URL,
            message: {
                error: JSON.stringify(error)
            }
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

export const SubmitQuoteNegotiation = (params) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + SUBMIT_QUOTE_NEGOTIATION_URL

    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + SUBMIT_QUOTE_NEGOTIATION_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + SUBMIT_QUOTE_NEGOTIATION_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

