// RYDER MANAGED SERVICES THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#000',
    secondary: '#ce1126',
    actionColor: "#ce1126",
    lightContrastText: '#fff',
    darkContrastText: '#000',
    useDarkContrastText: false,
    menuHighlightColor: "rgba(255, 255, 255, 0.3)",
    useWhiteBackgroundBehindLogo: false,
    tagLine:'Ever better.',
    customerUniqueId: "srukfk5e",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    loginMsg: "Please login in order to use this feature",
    primaryFontFamily: "Roboto",
    source: "ryder_managed_services"
});
//console.log(theme);
export default theme
