import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'

const GET_LAT_LON = "/api/getLatitudeLongitudes"

export const GetLatitudeLongitudes = async(params) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + GET_LAT_LON
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + GET_LAT_LON,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + GET_LAT_LON,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }
}