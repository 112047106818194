import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
// import moment from 'moment'
// import { LogActionsActivity } from '../../../apis/index'
import muiThemeable from 'material-ui/styles/muiThemeable';
import TruckCapacityList from './TruckCapacityList';

const TruckCapacity = (props) => {

  const {
    muiTheme,
    truckCapacityList,
    selectedLocationId = null,
    updatePreferencesData
  } = props
  

  // const [filteredCapacityList, setFilteredCapacityList] = useState(truckCapacityList)


  // useEffect(() => {

  //   buildFilteredCapacityList()
      
  // }, [selectedLocation, truckCapacityList])


  // const buildFilteredCapacityList = () => {

  //   if(selectedLocation == null || selectedLocation === "Show All") {
  //     //show all of capacity list
  //     setFilteredCapacityList(truckCapacityList)

  //   } else { //filter the list based on location
  //     let tmpList = []
  //     truckCapacityList.forEach( item => {
  //       if(selectedLocation === item.pickupCity) //todo: based on mock data 
  //         tmpList.push(item)
  //     });
  //     setFilteredCapacityList(tmpList)
  //   }
      
  // }


  return (
    <div style={{height: '100%'}}>
        <TruckCapacityList truckCapacityList={truckCapacityList} updatePreferencesData={updatePreferencesData} selectedLocationId={selectedLocationId} handleViewLoads={props.handleViewLoads}/>
    </div>
  )

}

export default compose(
  connect(),
  muiThemeable()
)(TruckCapacity)