import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";

import Slider from '@material-ui/core/Slider';
import FilterCheckbox from './filter-checkbox';

import useGtagHook from '../../../../gtag-hook';

const FilterUI = (props) => {

    const {
        muiTheme,
        paddingTop,
        updateFuelStopFilters,
        mobileView,

        //default values
        allFranchises,
        allAmenities,
        deadHeadMin,
        deadHeadMax,
        dieselMin,
        dieselMax,

        //current states
        deadheadRange,
        dieselRange,
        selectedFranchises,
        selectedAmenities,

        //change handlers
        resetFilter,
        deselectAllCheckboxes,
        handleSelectFranchise,
        handleSelectAmenity,
        resetAllFilters,
        handleDieselChange,
        handleDeadheadChange,

    } = props

    const { height, width } = useWindowDimensions();
    const useStyles = makeStyles((theme) => ({
        filterHeader: {
            fontSize: 14,
            fontWeight: "bold",
            marginBottom: 10,
            display: 'inline-block',
            color: 'black'
        },
        filterLabel: {
            fontSize: 14,
            lineHeight: "24px",
        },
        filterSection: {
            display: "flex",
            marginBottom: 10
        },
        resetFilter: {
            fontSize: 13,
            float: "right",
            // textDecoration: 'underline',
            textAlign: "right",
            color: muiTheme.actionColor,
            padding: 0,
            cursor: 'pointer',
            textTransform: "none",
            '&:hover': {
                color: muiTheme.actionColor,
                textDecoration: 'underline !important',
            }
        },
        selectDeselectAll: {
            fontSize: 13,
            textAlign: "right",
            color: muiTheme.actionColor,
            padding: 0,
            cursor: 'pointer',
            '&:hover': {
                color: muiTheme.actionColor,
                textDecoration: 'underline !important',
            }
            // textTransform: "none"
        }
    }));
    const classes = useStyles();
    const FILTER_HEADER_HEIGHT = 75

    const [resetFranchise, setResetFranchise] = useState(false)
    const [resetAmenity, setResetAmenity] = useState(false)

    const [deselectFranchise, setDeselectFranchise] = useState(false)
    const [deselectAmenity, setDeselectAmenity] = useState(false)

    const [sendGtagEvent] = useGtagHook()

    const onSliderChangeCommit = (type) => {
        let newValue
        switch (type) {
            case 'diesel':
                updateFuelStopFilters("diesel", dieselRange)
                newValue = dieselRange
                break
            case 'deadhead':
                updateFuelStopFilters("deadhead", deadheadRange)
                newValue = deadheadRange
                break
        }
        sendGtagEvent(
            "filter_loads_" + type,
            {
                category: type,
                type: "range",
                value: newValue
            }
        )
    }


    const BrandedSlider = createMuiTheme({
        overrides: {
            MuiSlider: {
                root: {
                    color: muiTheme.secondary,
                },
                rail: {
                    backgroundColor: "#53565A"
                },
            }
        }
    });

    const _onCheckboxSelect = (type, isSelected, item) => {
        switch (type) {
            case "franchise":
                handleSelectFranchise(isSelected, item)
                break
            case "amenity":
                handleSelectAmenity(isSelected, item)
                break
            default:
                break
        }
    }


    // TODO: allow slider values to be entered in text boxes
    // TODO: figure out how to increase font size on slider thumb

    return (
        <div style={{ width: "100%", paddingLeft: 10, paddingRight: 10 }}>

            <div style={{ paddingTop: mobileView ? null : 15, paddingBottom: 0, borderBottom: '1px solid lightgrey', position: "relative" }}>
                <p style={{ display: 'inline-block', marginBottom: 10, color: 'black', fontSize: 16, fontWeight: "bold" }}>Filters</p>
                <a className={classes.resetFilter} style={{ position: "absolute", bottom: 13, right: 0 }} onClick={() => { resetAllFilters(); setResetFranchise(!resetFranchise); setResetAmenity(!resetAmenity) }}>Reset All</a>
            </div>

            <div style={{ paddingTop: 20, paddingBottom: 20, paddingRight: 10, overflowX: "hidden", overflowY: 'auto', maxHeight: height - paddingTop - FILTER_HEADER_HEIGHT + (mobileView ? 100 : 0) }}>
                {(allFranchises.length > 1 || (allFranchises.length == 1 && allFranchises[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Franchise</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('franchise'); setDeselectFranchise(!deselectFranchise) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('franchise'); setResetFranchise(!resetFranchise) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {allFranchises.map(franchise => (
                                <FilterCheckbox
                                    key={franchise}
                                    checked={selectedFranchises.indexOf(franchise) > -1 ? false : true}
                                    type={"franchise"}
                                    item={franchise}
                                    reset={resetFranchise}
                                    deselect={deselectFranchise}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {(allAmenities.length > 1 || (allAmenities.length == 1 && allAmenities[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Amenities</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('amenity'); setDeselectAmenity(!deselectAmenity) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('amenity'); setResetAmenity(!resetAmenity) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {allAmenities.map(amenity => (
                                <FilterCheckbox
                                    key={amenity}
                                    checked={selectedAmenities.indexOf(amenity) > -1 ? false : true}
                                    type={"amenity"}
                                    item={amenity}
                                    reset={resetAmenity}
                                    deselect={deselectAmenity}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {deadHeadMin !== deadHeadMax &&
                    <div>
                        <p className={classes.filterHeader} >Distance From Search</p>
                        <a varient='contained' className={classes.resetFilter} onClick={() => resetFilter('deadhead')}>Reset</a>
                        <p style={{ paddingLeft: 10, fontSize: 14 }}>{deadheadRange[0]} - {deadheadRange[1]} mi</p>
                        <div className={classes.filterSection} style={{ paddingLeft: 15, paddingRight: 10 }}>
                            <ThemeProvider theme={BrandedSlider}>
                                <Slider
                                    value={deadheadRange}
                                    min={Math.floor(deadHeadMin)}
                                    max={Math.ceil(deadHeadMax)}
                                    onChange={handleDeadheadChange}
                                    onChangeCommitted={() => onSliderChangeCommit('deadhead')}
                                    aria-labelledby="range-slider"
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                }
                {dieselMin !== dieselMax &&
                    <div>
                        <p className={classes.filterHeader} >Diesel Price</p>
                        <a varient='contained' className={classes.resetFilter} onClick={() => resetFilter('diesel')}>Reset</a>
                        <p key={"weight_slider_header_" + dieselMin + "_to_" + dieselMax} style={{ paddingLeft: 10, fontSize: 14 }}>$ {parseFloat(dieselRange[0]/1000).toFixed(2)} - {parseFloat(dieselRange[1]/1000).toFixed(2)}</p>
                        <div className={classes.filterSection} style={{ paddingLeft: 15, paddingRight: 10 }}>
                            <ThemeProvider theme={BrandedSlider}>
                                <Slider
                                    key={"weight_slider_" + dieselMin + "_to_" + dieselMax}
                                    value={dieselRange}
                                    min={dieselMin}
                                    max={dieselMax}
                                    onChange={handleDieselChange}
                                    onChangeCommitted={() => onSliderChangeCommit('diesel')}
                                    aria-labelledby="range-slider"
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

export default compose(
    muiThemeable()
)(FilterUI)


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}