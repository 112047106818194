import React, { useState } from 'react';
import {
    Link
} from 'react-router-dom';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import isMobile from '../../HOC/Mobile.js'
import Drawer from '@material-ui/core/Drawer';

import muiThemeable from 'material-ui/styles/muiThemeable';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

import WernerAuthPopoverContent from "../customers/a98db973/authentication/auth-popover-content"
import WernerSignupModalContent from "../customers/a98db973/authentication/signup/signup-modal-content"
import LoginButton from "./login/Auth0-Univeral-Login/login-button"

import { setWindowLocation } from "../actions"
import Checkbox from '@material-ui/core/Checkbox';

const ProtectedCheckbox = (props) => {

    const {
        muiTheme,
        buttonStyle,
        popoverHeaderText = "",
        popoverBodyText = "",
        isDisabled = false,
        variantContained = true,
        disableRipple = false,
        display = "block",
        variantOutlined = false,
        anchorPopoverRight = false,
        setWindowLocation,
        isMobile,
        redirectObj = null
    } = props


    const [anchorEl, setAnchorEl] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const [showSignupForm, setShowSignupForm] = useState(false);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowPopup(open)
    }

    const handleClick = () => {
       
        setShowPopup(true)
        let element = document.getElementById('popover-anchor');
        setAnchorEl(element)

    };

    const handleClose = () => {
        setWindowLocation(null)
        setAnchorEl(null);
        setShowPopup(false)
    };

    const handleBack = (close = false) => {
        setShowSignupForm(false);
        if(close){
            handleClose()
        }
    }

    const getFromPath = () => {
       
        let stateInfo = {
            pathname: "/loads"
        }
        setWindowLocation(JSON.stringify(stateInfo))
        return JSON.stringify(stateInfo)
    }

    const getPopoverContent = (id, popOpen) => {
        
        if(!popOpen)
            return

        if (muiTheme.customerUniqueId == "a98db973") {
            return (
                <div>
                    {showSignupForm ?
                        <WernerSignupModalContent showCloseButton onBack={(close) => handleBack(close)}/>
                        :
                        <WernerAuthPopoverContent {...props} _handleClose={handleClose} id={id} fromPath={getFromPath()} setShowSignupForm={setShowSignupForm} />
                    }
                </div>
            )
        }
        else if (process.env.REACT_APP_USE_AUTH0 == "true") {
            return (
                <div style={{ padding: 15, width: isMobile ? '100%' : 464, textAlign: isMobile ? 'center' : 'unset' }}>
                    <h1>{popoverHeaderText}</h1>
                    <h3>{popoverBodyText}</h3>
                    <LoginButton
                        useBtn={true}
                        variant={"contained"}
                        size={"small"}
                        btnStyle={{ backgroundColor: muiTheme.actionColor, marginRight: 10, color: "#fff", fontSize: 14 }}
                        btnText={"LOGIN"}
                        handleClick={() => {
                            getFromPath()
                        }}
                    />
                    <Link
                        to={{
                            pathname: "/signup",
                            advantageId: muiTheme.customerUniqueId,
                            state: getFromPath()
                        }}
                    >
                        <Button
                            aria-describedby={id}
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: muiTheme.actionColor, marginRight: !isMobile ? 10 : 0, color: "#fff", color: "#fff", fontSize: 14 }}
                        >
                            SIGN UP
                        </Button>
                    </Link>
                </div>
            )
        }
        else {
            return (
                <div style={{ padding: 15, width: isMobile ? '100%' : 464, textAlign: isMobile ? 'center' : 'unset' }}>
                    <h1>{popoverHeaderText}</h1>
                    <h3>{popoverBodyText}</h3>
                    <Link
                        to={{
                            pathname: "/login",
                            advantageId: muiTheme.customerUniqueId,
                            state: getFromPath()
                        }}
                    >
                        <Button
                            aria-describedby={id}
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: muiTheme.actionColor, marginRight: 10, color: "#fff", fontSize: 14 }}
                        >
                            LOGIN
                        </Button>
                    </Link>
                    
                </div>
            )
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'protected-auth-popover' : undefined;

    return (
        <div style={{ display: display }}>

            <div 
                id='popover-anchor'
                style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                    key="bin_filter_checkbox"
                    style={{ color: muiTheme.secondary, transform: "scale(1.5)" }}
                    color="default"
                    checked={false}
                    onClick={e=>{e.stopPropagation(); handleClick(e)}}
                />
                <p style={{fontSize: 14, lineHeight: '24px' }}>{props.buttonText}</p>
            </div>
           
            {isMobile ?
                <Drawer width={"100%"} anchor={"bottom"} open={showPopup} onClose={toggleDrawer(anchorEl, false)}>
                    {getPopoverContent(id, showPopup)}
                </Drawer>
                :
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right' ,
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    {getPopoverContent(id, open)}
                </Popover>
            }
        </div>
    );
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setWindowLocation: setWindowLocation
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable(),
    isMobile()
)(ProtectedCheckbox)