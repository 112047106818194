import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {useAuthenticationHook} from '../CarrierPortal/hooks/authentication-hook.js';


const authUrl = `${process.env.REACT_APP_SC_URL}/broadcasting/auth`;

export const PusherConnect = () => {
    
    const myAuthService = useAuthenticationHook()
    let requestObject = { headers: null }
    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

    if (!window.Echo) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_PUSHER_APP_KEY,
            cluster: "mt1",
            encrypted: true,
            authEndpoint : authUrl,
            auth: {
                headers: requestObject.headers,
            },
          });
    }  
  }

  export function pusherDisconnect() {
      if (window.Echo) {
          window.Echo.disconnect();
          window.Echo = null;
      }
  }

  export function pusherSubscribe(channelName, eventName, callback) {
    if (window.Echo) {
        window.Echo.private(channelName)
        .listen(eventName, callback)
    }
  }

  export function pusherUnsubscribe(channelName) {
    if (window.Echo) {
        window.Echo.leave(channelName);
    }
  }

  export default PusherConnect;