import React from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Button from '@material-ui/core/Button';
import { Row, Col } from 'react-bootstrap';

import * as FeatherIcon from 'react-feather';
import LocationSelector from '../components/LocationSelector';
import TruckCapacity from '../components/werner-truck-capacity';
import LanePreferences from '../components/werner-lane-preferences';
import AddPreferenceModal from "../components/werner-add-edit-modal"

// import useGtagHook from '../../gtag-hook';

const WernerPreferencesDesktopUI = (props) => {
    const {
        muiTheme,
        locationsList,
        selectedLocationId,
        setSelectedLocationId,
        isLoading,
        setIsLoading,
        lanePreferencesList,
        updatePreferencesData,
        truckCapacityList,
        renderedTab,
        handlePageChange,
        showAddModal,
        setShowAddModal,
        PAGES
    } = props

    const useStyles = makeStyles({
        container: {
            height: '100%',
            width: '100%'
        },
        header: {
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderRadius: 0,
            textTransform: "none",
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        headerText: {
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 16,
            fontWeight: 'bold',
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        }
    });

    const classes = useStyles()

    return (
        <div className={classes.container}>

            <Row style={{ height: '8%', marginTop: "1%" }}>
                <LocationSelector
                    locationsList={locationsList}
                    selectedLocationId={selectedLocationId}
                    setSelectedLocationId={setSelectedLocationId}
                />
            </Row>

            <Row style={{ height: '5%', width: "100%", display: "flex", justifyContent: "center", marginTop: 25 }}>
                <Col style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                    <Button
                        className={classes.header}
                        style={{ borderBottomColor: renderedTab == PAGES["capacity"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                        onClick={() => handlePageChange("capacity")}
                    >
                        <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["capacity"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>CAPACITY</p>
                    </Button>
                    <Button
                        className={classes.header}
                        style={{ borderBottomColor: renderedTab == PAGES["preferences"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                        onClick={() => handlePageChange("preferences")}
                    >
                        <p className={classes.headerText} style={{ marginBottom: -15, color: renderedTab == PAGES["preferences"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>LANE PREFERENCES</p>
                    </Button>
                </Col>
            </Row>

            <Row style={{ marginTop: '3%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                    size="small"
                    style={{
                        backgroundColor: muiTheme.actionColor,
                        height: 40,
                        padding: 10
                    }}
                    onClick={() => { setShowAddModal(true) }}
                    startIcon={<FeatherIcon.Plus color='white' />}
                >
                    <p className={classes.headerText} style={{ textTransform: "uppercase", color: "white", fontSize: 16 }}>
                        {renderedTab == PAGES["capacity"] ? 'ADD NEW CAPACITY' : 'ADD LANE PREFERENCE'}
                    </p>
                </Button>
            </Row>

            <Row style={{ height: '72%' }}>
                {renderedTab == PAGES["capacity"] && !isLoading && selectedLocationId &&
                    <TruckCapacity truckCapacityList={truckCapacityList} selectedLocationId={selectedLocationId} updatePreferencesData={updatePreferencesData} handleViewLoads={props.handleViewLoads} />
                }
                {renderedTab == PAGES["preferences"] && !isLoading && selectedLocationId &&
                    <LanePreferences lanePreferencesList={lanePreferencesList} selectedLocationId={selectedLocationId} updatePreferencesData={updatePreferencesData} setIsLoading={setIsLoading} handleViewLoads={props.handleViewLoads} />
                }
                {showAddModal && (
                    <AddPreferenceModal selectedLocationId={selectedLocationId} renderedTab={renderedTab} onClose={() => { setShowAddModal(false) }} updatePreferencesData={updatePreferencesData} />
                )}
            </Row>

        </div>
    )
}


export default compose(
    muiThemeable()
)(WernerPreferencesDesktopUI)