//MESSAGING
  import * as TYPE from './constants'

const initialState = {
  requesting: false,
  initLoading: false,
  requesting: false,
  successful: false,
  importmessages: [],
  importerrors: [],
}

const reducer = function addCapacityReducer (state = initialState, action) {
  switch (action.type) {


    case TYPE.CALL_GET_USERS:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Looking up users...', time: new Date() }],
        errors: [],
      }


    case TYPE.CALL_GET_USERS_SUCCESS:

      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        usersList: action.response.results
      }

    // reset the state but with errors!
    // the error payload returned is actually far
    // more detailed, but we'll just stick with
    // the base message for now
    case TYPE.CALL_GET_USERS_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }

    default:
      return state
  }
}



export default reducer
