import { 	
			CALL_OUT_OF_NETWORK_BROKER, 
			INVITE_BROKER, 
		} from './constants'

export function getBrokerRequest () {
  return {
    type: CALL_OUT_OF_NETWORK_BROKER
  }
}
export function inviteBroker (eachBroker,updateCallback) {
  return {
    type: INVITE_BROKER,
    eachBroker,
    updateCallback:updateCallback
  }
}