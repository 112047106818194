//SYSTEM
  import React from 'react';
  import { call, put, takeLatest } from 'redux-saga/effects'
  import {toast} from 'react-toastify';
//APIs
  import {signupCarrier} from './api'

function* carrierSignupReq(action){
  try {
    const {firstName,lastName,companyName,mc,dot,email,password,favouriteBrokers} = action;
    const response = yield call(signupCarrier,firstName,lastName,companyName,mc,dot,email,password,favouriteBrokers)
    if(response.uniqueId){ //If there were some success
      yield put({type:'CARRIER_SIGNUP_SUC',payload:response.payload})
      window.location.replace("/carrier?uniqueId="+response.uniqueId);
    }else{ //If All Failed
      toast('We had a problem signing you up. '+response.message +'. Contact support@truckertools.com.')
      yield put({type:'CARRIER_SIGNUP_ERR',payload:response.payload})
    }
  } catch (error) {
    toast('We had a problem signing you up. Error: CarrierSignup0002. Contact support@truckertools.com.')
    console.log(error)

  }
}

function* templateWatcher () {
  yield takeLatest('CARRIER_SIGNUP_REQ', carrierSignupReq)
}

export default templateWatcher
