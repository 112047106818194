// export function saveQuote (loadId, carriers, quoteValue, message, sendEmail, callback, brokers) {
//   return {
//     type: 'SAVE_QUOTE_REQ',
//     loadId,
//     carriers,
//     quoteValue,
//     message,
//     sendEmail,
//     callback,
//     brokers
//   }
// }

export function saveQuoteAction (loadId, carriers, quoteValue, message, sendEmail, callback, brokers, availabilities, source) {
  return {
    type: 'SAVE_QUOTE_REQ',
    loadId,
    carriers,
    quoteValue,
    message,
    sendEmail,
    callback,
    brokers,
    availabilities,
    source
  }
}

export function getQuoteHistory(loadIds, carriers, ids, brokers, availabilities){
  return{
    type:'GET_QUOTE_REQ',
    loadIds,
    carriers,
    ids,
    brokers,
    availabilities
  }
}
