import React from 'react';
import { compose } from 'recompose';
import {
    Link
} from 'react-router-dom';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Row, Col, Grid } from 'react-bootstrap';
import * as FeatherIcon from 'react-feather';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import isMobile from '../../../../HOC/Mobile.js'

const PopoverContent = (props) => {

    const classes = useStyles();

    const { muiTheme, id, fromPath, isMobile, setShowSignupForm = () => { } } = props

    const imageSrcReturn = () => {
        if(muiTheme.customerUniqueId === "a98db973"){
            return <img src={require("../theme/logo_dark.svg")} height={70} />
        }else{
            return <img src={require("../theme/logo_dark.png")} height={40} />
        }
    }

    return (
        <Grid style={{ width: isMobile ? 'unset' : 400 }}>
            <Row style={{ width: isMobile ? 'unset' : 400, height: 25, display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                    className={classes.closeIconButton} 
                    aria-label="close" 
                    onClick={()=>props._handleClose()}
                >
                    <CloseIcon />
                </IconButton>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center", width: isMobile ? 'unset' : 400 }}>
                {imageSrcReturn()}
            </Row>
            <Row style={{ marginTop: 20, width: isMobile ? 'unset' : 400, height: 80 }}>
                <Grid style={{ width: "100%", display: "flex" }}>
                    <Col style={{ alignItems: "center", width: 184, height: "100%" }}>
                        <p className={classes.colTitle}>Existing Users</p>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
                            <Link
                                to={{
                                    pathname: "/login",
                                    advantageId: muiTheme.customerUniqueId,
                                    state: fromPath
                                }}
                            >
                                <Button
                                    aria-describedby={id}
                                    variant="contained"
                                    size="small"
                                    style={{ backgroundColor: muiTheme.actionColor, marginRight: 10, color: "#fff", fontSize: 14 }}
                                >
                                    LOGIN
                        </Button>
                            </Link>
                        </div>
                    </Col>
                    <div style={{ width: 1, height: 80, marginLeft: 15, marginRight: 15, backgroundColor: muiTheme.ttUnselectGrey }} />
                    <Col style={{ alignItems: "center", width: 184, height: "100%" }}>
                        <p className={classes.colTitle}>New Users</p>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
                            <Button
                                onClick={() => setShowSignupForm(true)}
                                aria-describedby={id}
                                variant="contained"
                                size="small"
                                style={{ backgroundColor: muiTheme.actionColor, color: "#fff", fontSize: 14 }}
                            >
                                SIGN UP
                                </Button>
                        </div>
                    </Col>
                </Grid>
            </Row>
        </Grid>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(PopoverContent)


const useStyles = makeStyles((theme) => ({
    colTitle: {
        textAlign: "center",
        color: "black",
        fontSize: 16
    },
    closeIconButton: {
        marginBottom: '-20px',
        float: 'right',
        '& .MuiSvgIcon-root':{
            fontSize: '2.5rem'
        }     
    },
}));