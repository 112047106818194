import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import muiThemeable from 'material-ui/styles/muiThemeable';

import Button from '@material-ui/core/Button';
import { useAuthenticationHook } from "../../../hooks/authentication-hook";


const LogoutButton = (props) => {
  const { logout } = useAuth0();

  const { muiTheme, btnStyle = {}, handleClick = () => { }, variant = "contained", size = "small", btnText = "Log out", useATag = false } = props

  const myAuthService = useAuthenticationHook()

  const onSubmit = () => {
    myAuthService.logout()
    logout({ returnTo: window.location.origin })
  }

  const getBody = () => {
    if (useATag) {
      return <a style={btnStyle} onClick={() => { onSubmit(); handleClick(); }}>{btnText}</a>
    } else {
      return <Button variant={variant} size={size} style={btnStyle} onClick={() => { onSubmit(); handleClick(); }}>{btnText}</Button>
    }
  }
  return getBody()
};

export default muiThemeable()(LogoutButton);